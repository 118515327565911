import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  CreateTrainerOrganizationMutationHookResult,
  Scalars,
  UpdateTrainerOrganizationMutationHookResult,
  useCreateTrainerOrganizationMutation,
  useOrganizationsTrainersBySportQuery,
  useUpdateTrainerOrganizationMutation,
} from '../../../../api';
import React, { FC, Fragment, useState } from 'react';

import AddBoxIcon from '@material-ui/icons/AddBox';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FireTrainer from '../../../FireBox/FireTrainer';
import TrainersForm from './TrainersForm';
import _ from 'lodash';
import getMessage from '../../../../messages';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles({
  row: {
    display: 'flex',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  tinyIcon: {
    fontSize: 18,
  },
  icon: {
    fontSize: 20,
  },
  tableHead: {
    fontWeight: 'bold',
  },
  header: {
    fontWeight: 600,
    fontSize: 15,
    letterSpacing: 1.2,
  },
});

interface formState {
  trainer?: { label: string; value: any };
  clsPosition?: { label: any; value: Scalars['UUID'] };
  employmentDate?: Date;
  dismissalDate?: Date;
  orgId?: Scalars['UUID'];
}

const TrainersAccordion: FC<{
  id?: string;
  readonly?: boolean;
  regionId?: string;
  sportId: string;
}> = ({ id, readonly, regionId, sportId }) => {
  const classes = useStyles();

  const [action, setAction] = useState('');
  const [orgId, setOrgId] = useState('');
  const [oldPosition, setOldPosition] = useState('');
  const [modalFire, setModalFire] = useState(false);
  const [defaultFormValue, setDefaultFormValue] = useState<any>(null);

  const { refetch, data } = useOrganizationsTrainersBySportQuery({
    fetchPolicy: 'no-cache',
    variables: {
      sportID: sportId,
      id,
    },
  });

  const sortBy = (a: any, b: any) => {
    if (!_.get(b, 'sports[0].organizations[0].dismissalDate')) {
      return 1;
    } else if (!_.get(a, 'sports[0].organizations[0].dismissalDate')) {
      return -1;
    } else {
      return (
        _.get(b, 'sports[0].organizations[0].dismissalDate') - _.get(a, 'sports[0].organizations[0].dismissalDate')
      );
    }
  };

  const [add]: CreateTrainerOrganizationMutationHookResult = useCreateTrainerOrganizationMutation();
  const [update]: UpdateTrainerOrganizationMutationHookResult = useUpdateTrainerOrganizationMutation();

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const updateDefaultFormValue = (org: any) =>
    setDefaultFormValue({
      clsPosition: {
        ...org?.clsPosition,
      },
      organization: {
        ...org?.dirOrganization,
      },
      trainer: {
        value: org?.trainer?.id,
        label: `${org?.trainer?.dirPerson.lastname} ${org?.trainer?.dirPerson.firstname} ${
          org?.trainer?.dirPerson.patronymic ? org?.trainer?.dirPerson.patronymic : ''
        } ${moment(org?.trainer?.dirPerson.birthday).format('DD.MM.YYYY')}`,
        ...org?.trainer,
      },
      employmentDate: org?.employmentDate,
      dismissalDate: org?.dismissalDate,
    });

  const onAdd = async (values: formState) => {
    const { trainer, clsPosition, employmentDate } = values;
    const sportID = _.get(trainer, 'roleData.sports', []).find((s: any) => s?.dirSport?.id === sportId)?.id;

    try {
      await add({
        variables: {
          id: sportID,
          idDirOrganization: id,
          employmentDate,
          clsPosition: clsPosition?.value,
        },
      });
      handleSnackBar('success', 'Тренер успешно добавлен');
      refetch();
      setAction('');
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const onEdit = async (values: formState) => {
    const { trainer, clsPosition, employmentDate, dismissalDate } = values;
    const sportID = _.get(trainer, 'sports[0].id', '');
    const orgID = _.get(trainer, 'sports[0].organizations[0].id', '');

    try {
      await update({
        variables: {
          id: orgID,
          idDirOrganization: id,
          clsPosition: oldPosition,
          employmentDate,
          dismissalDate,
        },
      });
      if (action === 'transfer') {
        await add({
          variables: {
            id: sportID,
            idDirOrganization: id,
            employmentDate: dismissalDate,
            clsPosition: clsPosition?.value,
          },
        });
      }
      handleSnackBar('success', 'информация успешно обновлено');
      refetch();
      setOrgId('');
      setAction('');
      setOldPosition('');
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const onFireTrainer = async () => {
    setModalFire(false);
    enqueueSnackbar(`Тренер  успешно уволен`, { variant: 'success' });
    refetch();
  };

  return (
    <div style={{ marginTop: 20 }}>
      <Accordion style={{ width: '100%' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`Trainer-content`} id={`Trainer-header`}>
          <Typography className={classes.header} color="primary">
            Тренеры
          </Typography>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: 'column' }}>
          {action && (
            <Paper style={{ padding: 15, marginBottom: 15 }}>
              <TrainersForm
                defaultValue={defaultFormValue}
                regionsId={[regionId || '']}
                sport={sportId}
                action={action}
                onAdd={(values: formState) => onAdd(values)}
                onEdit={(values: formState) => onEdit(values)}
                onCancel={() => {
                  setAction('');
                  setDefaultFormValue(null);
                }}
              />
            </Paper>
          )}

          <TableContainer component={Paper}>
            <Table aria-label={`Trainer table`}>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHead}>
                    {!action && !readonly && (
                      <Tooltip title="добавить" placement="top-start">
                        <IconButton className={classes.btn} color="primary" onClick={() => setAction('add')}>
                          <AddBoxIcon className={classes.icon} />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell className={classes.tableHead}>ФИО</TableCell>
                  <TableCell className={classes.tableHead}>Должность</TableCell>
                  <TableCell className={classes.tableHead}>Дата найма</TableCell>
                  <TableCell className={classes.tableHead}>Дата увольнения</TableCell>
                  <TableCell className={classes.tableHead}>Действия</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.dirTrainers
                  ?.sort((a, b) => sortBy(a, b))
                  ?.map((trainer, index) =>
                    _.get(trainer, 'sports[0].organizations', []).map((org: any, i: number) => (
                      <TableRow key={org.dirOrganization && org.dirOrganization.value + index}>
                        <TableCell component="th" scope="row" align="center">
                          {i === 0 ? index + 1 : ''}
                        </TableCell>
                        <TableCell>
                          {i === 0 && (
                            <Link
                              style={{ fontWeight: 500, cursor: 'pointer' }}
                              target="_blank"
                              href={`/trainer/${trainer?.id}`}
                            >
                              {[
                                trainer?.dirPerson?.lastname || '',
                                trainer?.dirPerson?.firstname || '',
                                trainer?.dirPerson?.patronymic || '',
                              ].join(' ')}
                            </Link>
                          )}
                        </TableCell>

                        <TableCell>{org.clsPosition.label && org.clsPosition.label}</TableCell>

                        <TableCell>{org.employmentDate && moment(org.employmentDate).format('DD.MM.YYYY')}</TableCell>
                        <TableCell>{org.dismissalDate && moment(org.dismissalDate).format('DD.MM.YYYY')}</TableCell>

                        <TableCell>
                          {!readonly
                            ? !org?.dismissalDate &&
                              !action && (
                                <div className={classes.row}>
                                  <Fragment>
                                    <Button
                                      size="small"
                                      color="primary"
                                      variant="contained"
                                      style={{ marginRight: 10 }}
                                      onClick={() => {
                                        updateDefaultFormValue({ ...org, trainer });
                                        setAction('transfer');
                                        setOrgId(org.id);
                                        setOldPosition(org.clsPosition.value);
                                      }}
                                    >
                                      Перевод
                                    </Button>
                                    <Tooltip title="удалить" placement="top-start">
                                      <Button
                                        className={classes.btn}
                                        color="secondary"
                                        variant="contained"
                                        style={{ marginRight: 10 }}
                                        onClick={() => {
                                          setOrgId(org.id);
                                          setModalFire(true);
                                        }}
                                      >
                                        <ClearIcon className={classes.icon} />
                                      </Button>
                                    </Tooltip>
                                  </Fragment>

                                  {modalFire && (
                                    <FireTrainer
                                      sportOrgId={orgId}
                                      orgId={org.dirOrganization.value}
                                      onSubmit={onFireTrainer}
                                      trainerId={id as string}
                                      idSport={sportId}
                                      onClose={() => setModalFire(false)}
                                    />
                                  )}

                                  <Tooltip title="изменить" placement="top-start">
                                    <Button
                                      className={classes.btn}
                                      color="primary"
                                      variant="contained"
                                      onClick={() => {
                                        updateDefaultFormValue({ ...org, trainer });
                                        setOrgId(org.id);
                                        setOldPosition(org.clsPosition.value);
                                        setAction('edit');
                                      }}
                                    >
                                      <EditIcon className={classes.icon} />
                                    </Button>
                                  </Tooltip>
                                </div>
                              )
                            : null}
                        </TableCell>
                      </TableRow>
                    )),
                  )}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default TrainersAccordion;
