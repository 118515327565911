import React, { FC } from 'react';
import { useRegionsListQuery } from '../api';
import { Select } from '../components/Inputs';
import { ControllerProps } from 'react-hook-form/dist/types/props';
import userRights from '../config/userRights';

interface Regions extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  error: boolean | undefined;
  disabled?: boolean;
  regionList?: Array<string>;
  enablePortal?: boolean;
  multiple?: boolean;
  filter?: any;
  useLabelElement?: boolean;
  onSuperChange?: any;
}

const Regions: FC<Regions> = ({
  label,
  error,
  control,
  name,
  rules,
  disabled,
  enablePortal,
  multiple,
  filter,
  regionList,
  useLabelElement,
  onSuperChange,
}) => {
  const { formAdditionalFilter } = userRights();
  const { data } = useRegionsListQuery({
    variables: {
      filter: {
        ...filter,
        ...((formAdditionalFilter.dirRegions && formAdditionalFilter.dirRegions) || {}),
        archive: null,
      },
    },
  });
  const regions = data?.dirRegions;
  return (
    <Select
      label={label}
      data={regions || []}
      control={control}
      useLabelElement={useLabelElement}
      existElements={regionList}
      error={error}
      name={name}
      rules={rules}
      enablePortal={enablePortal}
      disabled={disabled}
      multiple={multiple}
      onSuperChange={onSuperChange}
    />
  );
};

export default Regions;
