export const availableTags = {
  isImportant: {
    label: 'Особо значимое',
    value: 'isImportant',
  },
  isOlympicGamesPreparation: {
    label: 'Подготовка к ОИ',
    value: 'isOlympicGamesPreparation',
  },
  includingTrainingActivities: {
    label: 'Включая Тренировочные мероприятия',
    value: 'includingTrainingActivities',
  },
  federalBudgetFinancing: {
    label: 'Финансирование за счет федерального бюджета',
    value: 'federalBudgetFinancing',
  },
};
