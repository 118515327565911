import React, { FC } from 'react';

import { Select } from '../components/Inputs';
import { ControllerProps } from 'react-hook-form/dist/types/props';

interface NationalTeams extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  error: boolean | undefined;
  disabled?: boolean;
  regionList?: Array<string>;
  enablePortal?: boolean;
  multiple?: boolean;
  filter?: any;
  onSuperChange?: any;
  data: any;
}

const NationalTeams: FC<NationalTeams> = ({
  label,
  error,
  control,
  name,
  rules,
  disabled,
  enablePortal,
  multiple = true,
  filter,
  regionList,
  onSuperChange,
  data,
}) => {
  // @ts-ignore
  const NationalTeams: any = data?.dirNationalTeams?.reduce((acc, team) => {
    return [...acc, { value: team.id, label: team?.dirRegion?.label }];
  }, []);
  console.log(NationalTeams, data);

  return (
    <Select
      label={label}
      data={NationalTeams || []}
      control={control}
      error={error}
      name={name}
      rules={rules}
      enablePortal={enablePortal}
      disabled={disabled}
      multiple={multiple}
      onSuperChange={onSuperChange}
    />
  );
};

export default NationalTeams;
