import { useAthletesMedCertQuery } from '../../../api';
import React, { FC, useEffect } from 'react';
import moment from 'moment';
import {
  Card,
  Grid,
  Link,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { shuffleMedicValidDate } from './helpers';
import _ from 'lodash';

const Medic: FC<{ id: string; readonly?: boolean }> = ({ id, readonly }) => {
  const { data } = useAthletesMedCertQuery({
    variables: { id },
    fetchPolicy: 'no-cache',
  });

  if (data?.dirAthleteMedCerts?.length === 0) return null;

  const urlHead = 'https://qr-ecds.sportfmba.ru/admissions/verify?id=';

  return (
    <div style={{ width: '100%' }}>
      {data?.dirAthleteMedCerts?.map((item, index) => {
        return (
          !(item.deletedAt && moment().isSameOrAfter(item.deletedAt)) && (
            <Accordion key={item.id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${item.id}-content`}
                id={`${item.id}-header`}
              >
                <Typography color="primary">
                  Срок действия с {(item.issueDate && moment(item.issueDate).format('DD.MM.YYYY')) || '~'}&nbsp; по{' '}
                  {item.validUntil ? (
                    <span
                      style={{
                        color: `${
                          item.validUntil && moment().isAfter(item.validUntil) ? 'red' : 'rgba(0, 0, 0, 0.87)'
                        }`,
                      }}
                    >
                      {moment(item.validUntil).format('DD.MM.YYYY')}
                    </span>
                  ) : (
                    '~'
                  )}
                  ; Вид спорта:{' '}
                  {_.map(item.sports, (item) => item.dirSport && item.dirSport.fullName).join(', ') || '~'};&nbsp;
                  <strong style={{ color: shuffleMedicValidDate(item)?.color }}>
                    {shuffleMedicValidDate(item)?.text}
                  </strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid item container xs={12}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">GUID</TableCell>
                        <TableCell align="center">Номер допуска:</TableCell>
                        <TableCell align="center">Дата осмотра:</TableCell>
                        <TableCell align="center">Дата выдачи:</TableCell>
                        <TableCell align="center">Дата действия:</TableCell>
                        <TableCell align="center">Организация, выдавшая допуск:</TableCell>
                        <TableCell align="center">Контактные данные организации:</TableCell>
                        <TableCell align="center">Вид спорта:</TableCell>
                        <TableCell align="center">Группа дисциплин:</TableCell>
                        <TableCell align="center">Этап подготовки:</TableCell>
                        <TableCell align="center">Cтатус справки в ЕЦДС:</TableCell>
                        <TableCell align="center">Статус:</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key={item.id}>
                        <TableCell component="th" scope="row">
                          {item.id}
                        </TableCell>
                        <TableCell align="center">{item.displayNumber}</TableCell>
                        <TableCell align="center">
                          {(item.physicalExaminationDate &&
                            moment(item.physicalExaminationDate).format('DD.MM.YYYY')) ||
                            '~'}
                        </TableCell>
                        <TableCell align="center">
                          {(item.issueDate && moment(item.issueDate).format('DD.MM.YYYY')) || '~'}
                        </TableCell>
                        <TableCell align="center">
                          {item.validUntil ? (
                            <span
                              style={{
                                color: `${
                                  item.validUntil && moment().isAfter(item.validUntil) ? 'red' : 'rgba(0, 0, 0, 0.87)'
                                }`,
                              }}
                            >
                              {moment(item.validUntil).format('DD.MM.YYYY')}
                            </span>
                          ) : (
                            '~'
                          )}
                        </TableCell>
                        <TableCell align="center">{item.organizationName || '~'}</TableCell>
                        <TableCell align="center">
                          {[item.organizationAddress, item.organizationPhone, item.organizationEmail]
                            .filter((item) => item && item !== '')
                            .join('/')}
                        </TableCell>
                        <TableCell align="center">
                          {_.map(item.sports, (item) => item.dirSport && item.dirSport.fullName).join(', ') || '~'}
                        </TableCell>
                        <TableCell align="center">
                          {_.map(item.sports, (item) => (
                            <span>
                              {item.dirSport &&
                                item.dirSport.disciplineGroups &&
                                item.dirSport.disciplineGroups.length > 0 &&
                                item.dirSport.disciplineGroups.map((item) => item.name).join(', ')}
                            </span>
                          ))}
                        </TableCell>
                        <TableCell align="center">
                          {_.map(
                            item.sports,
                            (item) =>
                              item.clsTrainingStage &&
                              item.clsTrainingStage.shortName &&
                              item.clsTrainingStage.shortName,
                          ).join(', ')}
                        </TableCell>
                        <TableCell align="center">
                          <Link href={urlHead + item.id} target="_blank">
                            Просмотр статуса справки в ЕЦДС
                          </Link>
                        </TableCell>
                        <TableCell align="center">
                          <strong style={{ color: shuffleMedicValidDate(item)?.color }}>
                            {shuffleMedicValidDate(item)?.text}
                          </strong>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Grid>
              </AccordionDetails>
            </Accordion>
          )
        );
      })}
    </div>
  );
};

export default Medic;
