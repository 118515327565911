import { SportingEventFragment } from './../../../../api/index';
type T = { label: string; value: string } | null;
export const autoFillRules = (event: SportingEventFragment) => {
  let maleAge = null,
    femaleAge = null,
    maleAgeGroup: T = null,
    femaleAgeGroup: T = null,
    minManRang = null,
    minWomanRang = null;

  if (event.ageGroupMale) {
    maleAge = { min: event.ageGroupMale.minAge, max: event.ageGroupMale.maxAge };
    maleAgeGroup = event.ageGroupMale.clsAgeGroup;
  }

  if (event.ageGroupFemale) {
    femaleAge = { min: event.ageGroupFemale.minAge, max: event.ageGroupFemale.maxAge };
    femaleAgeGroup = event.ageGroupFemale.clsAgeGroup;
  }

  if (event.sports && event.sports.length > 0 && event.dirCalendar && event.dirCalendar.clsCalendarCategory) {
    const sport = event.sports[0];

    if (sport.dirSport.ageGroups && maleAgeGroup && femaleAgeGroup) {
      const manAgeGroup = sport.dirSport.ageGroups.find((group) => group.clsAgeGroup.label === maleAgeGroup?.label);
      const womanAgeGroup = sport.dirSport.ageGroups.find((group) => group.clsAgeGroup.label === femaleAgeGroup?.label);
      if (manAgeGroup) {
        minManRang =
          manAgeGroup.participationRequirements?.find(
            (r) => r.clsCalendarCategory?.fullName === event.dirCalendar.clsCalendarCategory?.fullName,
          )?.clsRanksAndAwards || null;
      }

      if (womanAgeGroup) {
        minWomanRang =
          womanAgeGroup.participationRequirements?.find(
            (r) => r.clsCalendarCategory?.fullName === event.dirCalendar.clsCalendarCategory?.fullName,
          )?.clsRanksAndAwards || null;
      }
    }
  }
  return {
    minWomanRang,
    minManRang,
    maleAge,
    femaleAge,
    bool: maleAge !== null && femaleAge !== null && (minWomanRang !== null || minManRang !== null),
  };
};

export const getValue = (value: any, data: any) => (value ? data : {});
