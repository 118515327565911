import { Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Link, Tooltip } from '@material-ui/core';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Scalars,
  UpdateSportingEventMutationHookResult,
  useSportEventRelativesQuery,
  useUpdateSportingEventMutation,
} from '../../../../api';

import AddBoxIcon from '@material-ui/icons/AddBox';
import ConfirmDialog from '../../../ConfirmDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { Input } from '../../../Inputs';
import SaveIcon from '@material-ui/icons/Save';
import SportEvents from '../../../../module/SportEvents';
import _ from 'lodash';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import userRights from '../../../../config/userRights';

interface LightEvent {
  value: Scalars['UUID'];
  label: string;
  registryNumber: number;
}

interface FormEventInterface {
  dirSportingEvent?: LightEvent;
}

interface CommentValueInterface {
  comment: string;
}

interface EventInfo {
  classes?: any;
  commentUpdated: Function;
  parentAdded: Function;
  parentRemoved: Function;
  childAdded: Function;
  childRemoved: Function;
  onError: Function;
  dirSportingEvent?: any;
  readOnly?: boolean;
}

const Structure: FC<EventInfo> = ({
  classes,
  dirSportingEvent,
  parentAdded,
  parentRemoved,
  childAdded,
  childRemoved,
  commentUpdated,
  onError,
  readOnly,
}) => {
  const { id } = dirSportingEvent;
  const { hasAdditionalFilter } = userRights();

  const {
    loading,
    data: { children, eventParent, dChildren } = { children: [], eventParent: [], dChildren: [] },
    refetch,
  } = useSportEventRelativesQuery({
    variables: {
      id,
      parentFilter: { ...hasAdditionalFilter.dirSportingEvents },
      childrenFilter: { ...hasAdditionalFilter.dirSportingEvents, parent: { id } },
      dChildrenFilter: { NOT: [{ ...hasAdditionalFilter.dirSportingEvents }], parent: { id } },
    },
  });
  const parent = useMemo(
    () => (_.get(eventParent, '[0].parent', null) ? _.get(eventParent, '[0].parent', null) : dirSportingEvent?.parent),
    [dirSportingEvent, eventParent],
  );

  const parentExist = useMemo(() => _.get(eventParent, '[0].parent', null) !== null, [eventParent]);

  const [besideArray, setBesideArray] = useState<Array<Scalars['UUID']>>([]);
  const [deleteItem, setDeleteItem] = useState<any>(null);
  const [deleteAddition, setDeleteAddition] = useState<any>(null);

  const { handleSubmit, control, errors, reset, formState } = useForm({
    defaultValues: {
      comment: dirSportingEvent?.comment,
    } as {
      comment: string;
    },
  });

  const {
    handleSubmit: parentFormHandleSubmit,
    control: parentFormControl,
    errors: parentFormError,
    reset: parentFormReset,
    formState: parentFormState,
  } = useForm({
    defaultValues: {
      dirSportingEvent: undefined,
    },
  });

  const {
    handleSubmit: childFormHandleSubmit,
    control: childFormControl,
    errors: childFormError,
    reset: childFormReset,
    formState: childFormState,
  } = useForm({
    defaultValues: {
      dirSportingEvent: undefined,
    },
  });

  const [open, setOpen] = useState('');

  const [updateSportingEvent]: UpdateSportingEventMutationHookResult = useUpdateSportingEventMutation();

  const handleClickOpen = (name: string) => {
    setOpen(name);
  };

  const handleClose = () => {
    setOpen('');
  };

  const onSubmit = async (values: CommentValueInterface) => {
    try {
      await updateSportingEvent({
        variables: {
          id,
          data: {
            comment: values.comment,
          },
        },
      });
      commentUpdated();
    } catch (error) {
      onError(error);
    }
  };

  const updateBesideArray = useCallback(
    (data: any) => {
      let availableChildArray = [id];

      if (data && data.parent) {
        availableChildArray = [...availableChildArray, data.parent.value];
      }
      if (data && data.children && data.children.length > 0) {
        availableChildArray = [...availableChildArray, ...data.children.map((child: LightEvent) => child.value)];
      }
      setBesideArray(_.sortedUniq(availableChildArray));
    },
    [id],
  );

  const handleUpdateParentEvent = async (values: FormEventInterface) => {
    try {
      updateSportingEvent({
        variables: {
          id,
          data: {
            parent: { connect: { id: values?.dirSportingEvent?.value } },
          },
        },
      }).then((resp: any) => {
        const { updateDirSportingEvent } = resp.data;
        updateBesideArray(updateDirSportingEvent);
        setOpen('');
        parentAdded();
      });
    } catch (error) {
      onError(error);
    }
  };

  const handleRemoveParentEvent = () => {
    try {
      updateSportingEvent({
        variables: {
          id,
          data: {
            parent: { disconnect: true },
          },
        },
      }).then((resp: any) => {
        const { updateDirSportingEvent } = resp.data;
        updateBesideArray(updateDirSportingEvent);
        parentRemoved();
      });
    } catch (error) {
      onError(error);
    }
  };

  const handleAddChildEvent = async (values: FormEventInterface) => {
    try {
      updateSportingEvent({
        variables: {
          id,
          data: {
            children: { connect: [{ id: values?.dirSportingEvent?.value }] },
          },
        },
      }).then((resp: any) => {
        const { updateDirSportingEvent } = resp.data;
        updateBesideArray(updateDirSportingEvent);
        setOpen('');
        childAdded();
      });
    } catch (error) {
      onError(error);
    }
  };

  const handleRemoveChildren = async (childID: Scalars['UUID']) => {
    try {
      updateSportingEvent({
        variables: {
          id,
          data: {
            children: { disconnect: [{ id: childID }] },
          },
        },
      }).then((resp: any) => {
        const { updateDirSportingEvent } = resp.data;
        updateBesideArray(updateDirSportingEvent);
        childRemoved();
      });
    } catch (error) {
      onError(error);
    }
  };

  useEffect(() => {
    updateBesideArray({ parent, children });
  }, [parent, children, updateBesideArray]);

  const valuesComma = [
    parent?.registryNumber,
    `${moment(parent?.startDate).format('DD.MM.YYYY')}-${moment(parent?.endDate).format('DD.MM.YYYY')}`,
  ];
  parent?.sports?.forEach((sport: any) => {
    valuesComma.push(sport?.dirSport?.label);

    sport?.disciplineGroups?.forEach((item: any) => {
      valuesComma.push(item.label);
    });
  });

  return (
    <React.Fragment>
      <Grid container className={classes.verticalSpace}>
        {parent && Object.keys(parent).length > 0 ? (
          <Grid item xs={12}>
            <span className={classes.title}>Основное мероприятие:&nbsp;</span>
            {parentExist ? (
              <Link target="_blank" href={`/sporting_event/${parent?.value}`}>
                {parent.label}
              </Link>
            ) : (
              <span>{parent.label}</span>
            )}
            <span className={classes.title} style={{ margin: '0 1rem' }}>
              {valuesComma.join(', ')}
            </span>
            {!readOnly && (
              <Tooltip title="изменить" placement="top-start">
                <IconButton className={classes.btn} color="primary" onClick={() => handleClickOpen('parentDialog')}>
                  <EditIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
            )}
            {!readOnly && (
              <Tooltip title="удалить" placement="top-start">
                <IconButton className={classes.btn} color="secondary" onClick={() => setDeleteItem(true)}>
                  <DeleteIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
            )}
            <ConfirmDialog
              title={`Удаление основного мероприятия`}
              text={`Вы уверены что хотите удалить это мероприятие: ${parent.label}`}
              btnVariant="outlined"
              open={!!deleteItem}
              saveBtnText="удалить"
              cancelBtnText="отмена"
              saveBtnColor="secondary"
              cancelBtnColor="primary"
              onCancel={() => setDeleteItem(null)}
              onSave={() => {
                handleRemoveParentEvent();
                setDeleteItem(null);
              }}
            />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <span className={classes.title}>Основное мероприятие:&nbsp;</span>

            {!readOnly && (
              <Tooltip title="добавить" placement="top-start">
                <IconButton className={classes.btn} color="primary" onClick={() => handleClickOpen('parentDialog')}>
                  <AddBoxIcon className={classes.icon} />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        )}

        <Grid item>
          <span className={classes.title}>Зависимые мероприятия:&nbsp;</span>
          {!readOnly && (
            <Tooltip title="добавить" placement="top-start">
              <IconButton className={classes.btn} color="primary" onClick={() => handleClickOpen('childrenDialog')}>
                <AddBoxIcon className={classes.icon} />
              </IconButton>
            </Tooltip>
          )}
        </Grid>

        {children?.map((child: any) => {
          const valuesComma = [
            child.registryNumber,
            `${moment(child.startDate).format('DD.MM.YYYY')}-${moment(child.endDate).format('DD.MM.YYYY')}`,
          ];
          child?.sports?.forEach((sport: any) => {
            valuesComma.push(sport?.dirSport?.label);

            sport?.disciplineGroups?.forEach((item: any) => {
              valuesComma.push(item.label);
            });
          });

          return (
            <Grid key={child.value} item xs={12}>
              <Link target="_blank" href={`/sporting_event/${child?.value}`}>
                {child.label}
              </Link>
              &nbsp;
              <span className={classes.label} style={{ margin: '0 1rem' }}>
                {valuesComma.join(', ')}
              </span>
              &nbsp;
              {!readOnly && (
                <Tooltip title="удалить" placement="top-start">
                  <IconButton className={classes.btn} color="secondary" onClick={() => setDeleteAddition(child.value)}>
                    <DeleteIcon className={classes.icon} />
                  </IconButton>
                </Tooltip>
              )}
              <ConfirmDialog
                title={`Удаление зависимого мероприятия`}
                text={`Вы уверены что хотите удалить это мероприятие: ${deleteItem?.label || child.label} `}
                btnVariant="outlined"
                open={deleteAddition === child.value}
                saveBtnText="удалить"
                cancelBtnText="отмена"
                saveBtnColor="secondary"
                cancelBtnColor="primary"
                onCancel={() => setDeleteAddition(null)}
                onSave={() => {
                  handleRemoveChildren(child.value).then(() => setDeleteAddition(null));
                }}
              />
            </Grid>
          );
        })}
        {dChildren?.map((child: any) => {
          const valuesComma = [
            child.registryNumber,
            `${moment(child.startDate).format('DD.MM.YYYY')}-${moment(child.endDate).format('DD.MM.YYYY')}`,
          ];
          child?.sports?.forEach((sport: any) => {
            valuesComma.push(sport?.dirSport?.label);

            sport?.disciplineGroups?.forEach((item: any) => {
              valuesComma.push(item.label);
            });
          });

          return (
            <Grid key={child.value} item xs={12}>
              <span>{child.label}</span>
              &nbsp;
              <span className={classes.label} style={{ margin: '0 1rem' }}>
                {valuesComma.join(', ')}
              </span>
              &nbsp;
            </Grid>
          );
        })}
      </Grid>
      <Grid container justify="space-between" alignItems="flex-end" className={classes.verticalSpace}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
          <Grid item xs={12} className={classes.verticalSpace}>
            <Input
              label="Комментарий"
              control={control}
              error={!!errors['comment']}
              name="comment"
              disabled={readOnly}
            />
          </Grid>
          <Grid item container justify="flex-end">
            <Button
              variant="outlined"
              color="primary"
              size="small"
              type="submit"
              startIcon={<SaveIcon />}
              disabled={!formState.isDirty}
            >
              Сохранить
            </Button>
          </Grid>
        </form>
      </Grid>
      <Dialog
        open={open === 'parentDialog'}
        onClose={handleClose}
        aria-labelledby="parent-dialog-title"
        aria-describedby="parent-dialog-description"
      >
        <DialogTitle id="parent-dialog-title">Выбирать Мероприятие</DialogTitle>
        <DialogContent style={{ padding: '1rem' }}>
          <form onSubmit={parentFormHandleSubmit(handleUpdateParentEvent)} style={{ width: '40ch' }}>
            <SportEvents
              label="мероприятие"
              name="dirSportingEvent"
              filter={{ id_not_in: besideArray }}
              control={parentFormControl}
              error={!!parentFormError['dirSportingEvent']}
              rules={{ required: true }}
            />
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              style={{ width: '100%', marginTop: '1rem' }}
              disabled={!parentFormState.isDirty}
            >
              Сохранить
            </Button>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog
        open={open === 'childrenDialog'}
        onClose={handleClose}
        aria-labelledby="children-dialog-title"
        aria-describedby="children-dialog-description"
      >
        <DialogTitle id="children-dialog-title">Выбирать Мероприятие</DialogTitle>
        <DialogContent style={{ padding: '1rem' }}>
          <form onSubmit={childFormHandleSubmit(handleAddChildEvent)} style={{ width: '40ch' }}>
            <SportEvents
              label="мероприятие"
              name="dirSportingEvent"
              filter={{ id_not_in: besideArray }}
              control={childFormControl}
              error={!!childFormError['dirSportingEvent']}
              rules={{ required: true }}
            />
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              style={{ width: '100%', marginTop: '1rem' }}
              disabled={!childFormState.isDirty}
            >
              Сохранить
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default Structure;
