import { Grid, Tooltip } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import React, { FC, useCallback, useState } from 'react';
import { getSettingsUserFilter, getSettingsUserFilterFormValue } from '../../store/selectors/settings';
import { useDispatch, useSelector } from 'react-redux';
import { useNationalTeamsLazyQuery, useNationalTeamsQuery } from '../../api';

import { AppStore } from '../../store/reducers';
import { DataTable } from '../../components/DataTable/NewTable';
import { DictionaryWrapper } from '../../components/DictionaryWrapper';
import { MUIDataTableColumn } from 'mui-datatables';
import { MainTemplate } from '../../components/layouts';
import { NationalTeamsFilter } from '../../components/Filters';
import { SET_USER_SETTINGS_FILTER } from '../../store/constants';
import _ from 'lodash';
import moment from 'moment';
import { revealTeams } from '../../utils/revealTeams';
import { usePermission } from '../../hooks/usePermission';
import userRights from '../../config/userRights';

const Page: FC<{}> = () => {
  const { hasAdditionalFilter } = userRights();

  const dispatch = useDispatch();
  const filter: any = useSelector((state: AppStore) => getSettingsUserFilter(state, 'national_teams'), _.isEqual);
  const formValue: any = useSelector(
    (state: AppStore) => getSettingsUserFilterFormValue(state, 'national_teams'),
    _.isEqual,
  );

  const [first, setFirst] = useState(0);

  const { push } = useHistory();
  const access = usePermission('directory', 'national_teams');

  const setFilter = useCallback(
    (filter, form) => {
      dispatch({
        type: SET_USER_SETTINGS_FILTER,
        payload: {
          name: 'national_teams',
          filter: filter,
          form,
        },
      });
    },
    [dispatch],
  );

  const columns: MUIDataTableColumn[] = [
    {
      name: 'info',
      label: 'Регион',
      options: {
        filter: false,
        viewColumns: false,
        sort: false,
        customBodyRender: (info: any, tableMeta: any) => {
          const { rowData } = tableMeta;
          const region = rowData[1];
          const { id } = info || {};
          return (
            <React.Fragment>
              <Tooltip title="Перейти в карточку" placement="top-start">
                <Link
                  style={{
                    fontWeight: 500,
                    cursor: 'pointer',
                    textDecoration: 'none',
                  }}
                  to={`/national_team/${id}`}
                  target={'_blank'}
                >
                  {region?.fullName}
                </Link>
              </Tooltip>
            </React.Fragment>
          );
        },
      },
    },
    {
      name: 'dirRegion',
      label: 'Регион',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'sportType',
      label: 'Вид спорта',
      options: {
        filter: false,
        display: true,
        sort: false,
      },
    },
    {
      name: 'registryNumberEvent',
      label: 'Реестровый номер мероприятия',
      options: {
        filter: false,
        display: true,
        sort: false,
        customBodyRender: (event, { rowData }) => rowData[7].registryNumber,
      },
    },
    {
      name: 'name',
      label: 'Название мероприятия',
      options: {
        filter: false,
        display: true,
        sort: false,
        customBodyRender: (event, { rowData }) => rowData[7].name,
      },
    },
    {
      name: 'period',
      label: 'период проведения',
      options: {
        filter: false,
        display: true,
        sort: false,
        customBodyRender: (event, { rowData }) => {
          return `${rowData[7].startDate && moment(rowData[7].startDate).format('DD.MM.YY')} - ${
            rowData[7].endDate && moment(rowData[7].endDate).format('DD.MM.YY')
          }`;
        },
      },
    },
    {
      name: 'statuses',
      label: 'статус согласования',
      options: {
        filter: false,
        display: true,

        sort: false,
        customBodyRender: (statuses) => _.get(statuses[0], 'value.name', ''),
      },
    },
    {
      name: 'dirSportingEvent',
      label: ' ',
      options: {
        filter: false,
        display: false,
        sort: false,
        viewColumns: false,

        customBodyRender: (value: any, meta: any) => {
          return '';
        },
      },
    },
    {
      name: 'info',
      label: 'Реестровый номер',
      options: {
        filter: false,
        display: true,
        sort: false,

        customBodyRender: (value: any) => value?.registryNumber,
      },
    },
  ];

  return (
    <MainTemplate pageName="Справочник сборных команд" url="" title="Сборные команды">
      <NationalTeamsFilter
        onSubmit={(filters: any, form: any) => setFilter({ ...filters }, form)}
        value={formValue}
        defaultFilter={{ ...hasAdditionalFilter?.national_teams }}
        first={first}
      />
      <DictionaryWrapper>
        <DataTable
          tableName="national_teams"
          title={
            <Grid container spacing={2} alignItems={'center'}>
              <Grid item>
                <h2>Справочник сборных команд</h2>
              </Grid>
              {/*{access.includes(2) && <Grid item>{<BtnNew onClick={() => push('/national_team/new')} />}</Grid>}*/}
            </Grid>
          }
          columns={columns}
          lazyQuery={useNationalTeamsLazyQuery}
          filter={{ ...filter, ...hasAdditionalFilter?.national_teams }}
          revealFunctionName={'teams'}
        />
      </DictionaryWrapper>
    </MainTemplate>
  );
};

export default Page;
