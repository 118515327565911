import React, { useState, useCallback, FC } from 'react';
import {
  Grid,
  Tooltip,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  TextField,
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  MenuItem,
  InputAdornment,
} from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import AddIcon from '@material-ui/icons/AddBox';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import * as Validation from '../../../utils/validation';
import {
  AddEmailToOrganizationMutationHookResult,
  AddPhoneToOrganizationMutationHookResult,
  DeleteEmailFromOrganizationMutationHookResult,
  DeletePhoneFromOrganizationMutationHookResult,
  UpdateOrganizationEmailMutationHookResult,
  UpdateOrganizationPhoneMutationHookResult,
  UpdateOrganizationSitesMutationHookResult,
  useAddEmailToOrganizationMutation,
  useAddPhoneToOrganizationMutation,
  useClsTypeContactQuery,
  useDeleteEmailFromOrganizationMutation,
  useDeletePhoneFromOrganizationMutation,
  useOrganizationByIdQuery,
  useUpdateOrganizationEmailMutation,
  useUpdateOrganizationPhoneMutation,
  useUpdateOrganizationSitesMutation,
  Scalars,
} from '../../../api';
import ConfirmDialog from '../../ConfirmDialog';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  link: {
    cursor: 'pointer',
  },
  icon: {
    fontSize: 15,
  },
});

const ContactInfo: FC<{ readonly?: boolean; id: Scalars['UUID'] }> = ({ id, readonly }) => {
  const classes = useStyles();
  const defaultPhoneState: {
    id: Scalars['UUID'];
    number: string;
    type: string;
    operatorCode: string;
    countryCode: string;
    additionalNumber: string;
  } = {
    id: '',
    number: '',
    type: '',
    operatorCode: '',
    countryCode: '7',
    additionalNumber: '',
  };
  const defaultEmailState: {
    id: Scalars['UUID'];
    email: string;
    type: Scalars['UUID'];
  } = {
    id: '',
    email: '',
    type: '',
  };
  const [currentRequest, setCurrentRequest] = useState('');
  const [isAddingNewPhone, setIsAddingNewPhone] = useState(false);
  const [isAddingNewEmail, setIsAddingNewEmail] = useState(false);
  // const [isEditingSites, setIsEditingSites] = useState(false);
  // const [sites, setSites] = useState('');
  const [isEditingPhone, setIsEditingPhone] = useState('');
  const [isEditingEmail, setIsEditingEmail] = useState('');
  const [emailValue, setEmailValue] = useState(defaultEmailState);
  const [phone, setPhone] = useState(defaultPhoneState);
  const [deleteItem, setDeleteItem] = useState<any>(null);

  const handleChangePhone = useCallback(
    (event: any) => setPhone({ ...phone, [event.target.name]: event.target.value }),
    [phone],
  );

  const numerizeIt = useCallback(
    (e) => Validation.onlyNumber(e.currentTarget.value) === undefined && handleChangePhone(e),
    [handleChangePhone],
  );

  const { data: { classifierValues: clsContactsTypes = [] } = {} } = useClsTypeContactQuery();

  const { loading, refetch, data } = useOrganizationByIdQuery({
    fetchPolicy: 'no-cache',
    variables: {
      id,
    },
  });

  const dirOrganization = data?.dirOrganization;

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (
    variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined,
    message: string,
  ) => {
    enqueueSnackbar(message, { variant });
  };

  const [addEmails]: AddEmailToOrganizationMutationHookResult = useAddEmailToOrganizationMutation();
  const [updateEmails]: UpdateOrganizationEmailMutationHookResult = useUpdateOrganizationEmailMutation();
  const [deleteEmails]: DeleteEmailFromOrganizationMutationHookResult = useDeleteEmailFromOrganizationMutation();
  // const [updateSite]: UpdateOrganizationSitesMutationHookResult = useUpdateOrganizationSitesMutation();
  const [addPhone]: AddPhoneToOrganizationMutationHookResult = useAddPhoneToOrganizationMutation();
  const [updatePhone]: UpdateOrganizationPhoneMutationHookResult = useUpdateOrganizationPhoneMutation();
  const [deletePhones]: DeletePhoneFromOrganizationMutationHookResult = useDeletePhoneFromOrganizationMutation();

  const updateEmail = async () => {
    if (Validation.email(emailValue.email) === undefined && emailValue.type) {
      if (currentRequest === 'update' && emailValue.id) {
        await updateEmails({
          variables: {
            emailId: emailValue.id,
            type: emailValue.type,
            email: emailValue.email,
            id,
          },
        }).then(() => {
          setEmailValue({
            id: '',
            email: '',
            type: '',
          });
          handleSnackBar('success', 'Электронная почта успешно обновлена');
          if (isEditingEmail) {
            setIsEditingEmail('');
          }
          refetch();
        });
        return;
      }
      addEmails({
        variables: {
          email: emailValue.email,
          type: emailValue.type,
          id,
        },
      }).then(() => {
        setEmailValue({
          id: '',
          email: '',
          type: '',
        });
        handleSnackBar('success', 'Электронная почта успешно добавлена');
        if (isEditingEmail) {
          setIsEditingEmail('');
        }
        refetch();
      });
      return;
    }
  };
  const deleteEmail = (emailId: Scalars['UUID']) => {
    deleteEmails({
      variables: {
        emailId: emailId,
        id,
      },
    }).then((resp) => {
      setIsEditingEmail('');
      handleSnackBar('success', 'Электронная почта успешно удаленна');
      refetch();
    });
  };
  //
  // const updateSites = () => {
  //   if (Validation.website(sites) === undefined) {
  //     updateSite({
  //       variables: {
  //         sites: (dirOrganization?.websites && [...dirOrganization?.websites, sites]) || [sites],
  //         id,
  //       },
  //     }).then((resp) => {
  //       setIsEditingSites(false);
  //       handleSnackBar('success', 'Сайты успешно обновлены');
  //       refetch();
  //     });
  //   }
  //   setIsEditingSites(false);
  // };
  // const deleteSites = (site: string) => {
  //   const currentWebSites = dirOrganization?.websites.filter((s: string) => s !== site);
  //
  //   updateSite({
  //     variables: {
  //       sites: currentWebSites,
  //       id,
  //     },
  //   }).then((resp) => {
  //     setIsEditingSites(false);
  //     handleSnackBar('success', 'Сайты успешно обновлены');
  //     refetch();
  //   });
  // };

  const addNewPhone = () => {
    const { countryCode, number, type, operatorCode, additionalNumber } = phone;
    if (
      phone.id &&
      isEditingPhone === phone.id &&
      countryCode &&
      number &&
      type &&
      Validation.number(operatorCode) === undefined &&
      Validation.number(number) === undefined &&
      Validation.number(countryCode) === undefined
    ) {
      updatePhone({
        variables: {
          phoneId: phone.id,
          countryCode,
          number,
          operatorCode,
          additionalNumber,
          type,
          id,
        },
      }).then((resp) => {
        setIsEditingPhone('');
        setPhone(defaultPhoneState);
        handleSnackBar('success', 'телефон успешно обновлено');
        refetch();
      });
    } else if (
      countryCode &&
      number &&
      type &&
      Validation.number(operatorCode) === undefined &&
      Validation.number(number) === undefined &&
      Validation.number(countryCode) === undefined
    ) {
      addPhone({
        variables: {
          countryCode,
          operatorCode,
          number,
          additionalNumber,
          type,
          id,
        },
      }).then((resp) => {
        setIsAddingNewPhone(false);
        setPhone(defaultPhoneState);
        handleSnackBar('success', 'новый телефон успешно добавлено');
        refetch();
      });
    }
  };
  const deletePhone = (phoneId: Scalars['UUID']) => {
    deletePhones({
      variables: {
        phoneId: phoneId,
        id,
      },
    }).then((resp) => {
      setIsAddingNewPhone(false);
      setPhone(defaultPhoneState);
      handleSnackBar('success', 'новый телефон успешно удаленно');
      refetch();
    });
  };

  if (loading) return <LinearProgress />;

  const PhoneForm = (
    <Grid container spacing={1} alignItems="center" style={{ margin: '1rem 0' }}>
      <Grid item lg={2}>
        <TextField
          id="countryCode"
          fullWidth
          InputProps={{
            startAdornment: <InputAdornment position="start">+</InputAdornment>,
          }}
          helperText={(phone.countryCode && Validation.number(phone.countryCode)) || ''}
          label="Код страны"
          name="countryCode"
          size="small"
          value={phone.countryCode}
          onChange={numerizeIt}
        />
      </Grid>
      <Grid item>
        <TextField
          id="operatorCode"
          fullWidth
          helperText={(phone.operatorCode && Validation.number(phone.operatorCode)) || ''}
          label="Код оператора/ региона"
          name="operatorCode"
          size="small"
          value={phone.operatorCode}
          onChange={numerizeIt}
        />
      </Grid>
      <Grid item>
        <TextField
          id="place"
          fullWidth
          label="номер"
          name="number"
          size="small"
          value={phone.number}
          onChange={numerizeIt}
        />
      </Grid>

      <Grid item>
        <TextField
          id="additionalNumber"
          fullWidth
          helperText={(phone.additionalNumber && Validation.number(phone.additionalNumber)) || ''}
          label="Добавочный"
          name="additionalNumber"
          size="small"
          value={phone.additionalNumber}
          onChange={numerizeIt}
        />
      </Grid>

      <Grid item>
        <FormControl size="small" style={{ minWidth: '20ch' }}>
          <InputLabel id="phone-type-label">Тип контакта</InputLabel>
          <Select
            labelId="phone-type-label"
            label="Тип контакта"
            name="type"
            value={phone.type}
            onChange={handleChangePhone}
          >
            {clsContactsTypes.length > 0 &&
              clsContactsTypes.map((p) => (
                <MenuItem key={p.value} value={p.value}>
                  {p.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      {!readonly && (
        <React.Fragment>
          <Grid item>
            <Tooltip title="сохранить" placement="top-start">
              <IconButton
                color={
                  (phone.number && phone.type && phone.operatorCode && phone.countryCode && 'primary') || 'default'
                }
                style={{ marginRight: '0.5rem' }}
                onClick={addNewPhone}
              >
                <SaveIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="отменить" placement="top-start">
              <IconButton
                color="secondary"
                onClick={() => {
                  setIsAddingNewPhone(false);
                  setPhone(defaultPhoneState);
                }}
              >
                <CancelIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  );
  const EmailForm = (
    <Grid container spacing={1} alignItems="center" style={{ margin: '1rem 0' }}>
      <Grid item>
        <TextField
          id="place"
          style={{ width: '100%' }}
          label="Email"
          helperText={(emailValue.email && Validation.email(emailValue.email)) || ''}
          error={!!(emailValue?.email && Validation.email(emailValue.email)) || false}
          size="small"
          required
          value={emailValue.email}
          onChange={(event) => setEmailValue({ ...emailValue, email: event.target.value })}
        />
      </Grid>
      <Grid item>
        <FormControl size="small" style={{ minWidth: '20ch' }} required>
          <InputLabel id="email-type-label">Тип</InputLabel>
          <Select
            labelId="email-type-label"
            label="Тип"
            name="type"
            value={emailValue.type}
            required
            onChange={(event) => setEmailValue({ ...emailValue, type: event.target.value })}
          >
            {clsContactsTypes.length > 0 &&
              clsContactsTypes.map((p) => (
                <MenuItem key={p.value} value={p.value}>
                  {p.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      {!readonly && (
        <React.Fragment>
          <Grid item>
            <Tooltip title="сохранить" placement="top-start">
              <IconButton
                color={
                  (emailValue.email && !Validation.email(emailValue.email) && emailValue.type && 'primary') || 'default'
                }
                style={{ marginRight: '0.5rem' }}
                onClick={updateEmail}
              >
                <SaveIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="отменить" placement="top-start">
              <IconButton
                color="secondary"
                onClick={() => {
                  setIsAddingNewEmail(false);
                  setEmailValue(defaultEmailState);
                }}
              >
                <CancelIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  );

  return (
    <React.Fragment>
      <div>
        <div>
          <div>
            <span style={{ fontSize: '15px' }}>Телефоны</span>
            {!isAddingNewPhone && !readonly && (
              <Tooltip title="Добавить" placement="top-start">
                <IconButton
                  color="primary"
                  onClick={() => {
                    setIsEditingPhone('');
                    setPhone(defaultPhoneState);
                    setIsAddingNewPhone(true);
                  }}
                >
                  <AddIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
          {isAddingNewPhone && <React.Fragment>{PhoneForm}</React.Fragment>}

          {dirOrganization?.phones && dirOrganization?.phones.length > 0 && (
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="phone table">
                <TableHead>
                  <TableRow>
                    <TableCell>номер</TableCell>
                    <TableCell>доб</TableCell>
                    <TableCell>тип</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dirOrganization?.phones?.map((p) =>
                    isEditingPhone === p.id ? (
                      <TableRow key={p.id}>
                        <TableCell>
                          <TextField
                            style={{ width: '100%' }}
                            label="номер"
                            name="number"
                            helperText={(phone.number && Validation.number(phone.number)) || ''}
                            size="small"
                            value={phone.number}
                            onChange={numerizeIt}
                          />
                        </TableCell>

                        <TableCell>
                          <TextField
                            style={{ width: '24ch' }}
                            helperText={(phone.operatorCode && Validation.number(phone.operatorCode)) || ''}
                            label="Код оператора/ региона"
                            name="operatorCode"
                            size="small"
                            value={phone.operatorCode}
                            onChange={numerizeIt}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            style={{ width: '20ch' }}
                            InputProps={{
                              startAdornment: <InputAdornment position="start">+</InputAdornment>,
                            }}
                            helperText={(phone.countryCode && Validation.number(phone.countryCode)) || ''}
                            label="Код страны"
                            name="countryCode"
                            size="small"
                            value={phone.countryCode}
                            onChange={numerizeIt}
                          />
                        </TableCell>
                        <TableCell>
                          <TextField
                            style={{ width: '20ch' }}
                            helperText={(phone.additionalNumber && Validation.number(phone.additionalNumber)) || ''}
                            label="Добавочный"
                            name="additionalNumber"
                            size="small"
                            value={phone.additionalNumber}
                            onChange={numerizeIt}
                          />
                        </TableCell>

                        <TableCell>
                          <FormControl size="small" style={{ minWidth: '20ch' }}>
                            <InputLabel id="phone-type-label">Тип контакта</InputLabel>
                            <Select
                              labelId="phone-type-label"
                              label="Тип контакта"
                              name="type"
                              value={phone.type}
                              onChange={handleChangePhone}
                            >
                              {clsContactsTypes.length > 0 &&
                                clsContactsTypes.map((p) => (
                                  <MenuItem key={p.value} value={p.value}>
                                    {p.label}
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          {!readonly && (
                            <React.Fragment>
                              <Tooltip title="сохранить" placement="top-start">
                                <IconButton
                                  className={classes.btn}
                                  color="primary"
                                  style={{ marginRight: '0.5rem' }}
                                  onClick={addNewPhone}
                                >
                                  <SaveIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="отменить" placement="top-start">
                                <IconButton
                                  color="secondary"
                                  onClick={() => {
                                    setIsAddingNewPhone(false);
                                    setIsEditingPhone('');
                                    setPhone(defaultPhoneState);
                                  }}
                                >
                                  <CancelIcon />
                                </IconButton>
                              </Tooltip>
                            </React.Fragment>
                          )}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow key={p.id}>
                        <TableCell>
                          +{p.countryCode} ({p.operatorCode}){p.number}
                        </TableCell>
                        <TableCell>{p.additionalNumber}</TableCell>
                        <TableCell>{p.clsContactType.fullName}</TableCell>
                        <TableCell align="right">
                          {!readonly && (
                            <React.Fragment>
                              <Tooltip title="изменить" placement="top-start">
                                <IconButton
                                  className={classes.btn}
                                  color="primary"
                                  style={{ marginRight: '0.5rem' }}
                                  onClick={() => {
                                    setPhone({
                                      id: p.id,
                                      number: p.number,
                                      operatorCode: p.operatorCode,
                                      countryCode: p.countryCode,
                                      additionalNumber: p.additionalNumber || '',
                                      type: p.clsContactType.id,
                                    });
                                    setIsEditingPhone(p.id);
                                    setIsAddingNewPhone(false);
                                  }}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="удалить" placement="top-start">
                                <IconButton
                                  className={classes.btn}
                                  color="secondary"
                                  onClick={() => setDeleteItem(p.id)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>
                              <ConfirmDialog
                                title={`Удаление телефона`}
                                text={`Вы уверены что хотите удалить телефон?`}
                                btnVariant="outlined"
                                open={deleteItem === p.id}
                                saveBtnText="удалить"
                                cancelBtnText="отмена"
                                saveBtnColor="secondary"
                                cancelBtnColor="primary"
                                onCancel={() => setDeleteItem(null)}
                                onSave={() => {
                                  deletePhone(p.id);
                                  setDeleteItem(null);
                                }}
                              />
                            </React.Fragment>
                          )}
                        </TableCell>
                      </TableRow>
                    ),
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
        <div style={{ marginTop: '1rem' }}>
          <Grid container alignItems="center">
            <Grid item style={{ marginRight: '0.5rem' }}>
              <span style={{ fontSize: '15px' }}>Электронная почта</span>
            </Grid>
            <Grid item>
              {!isAddingNewEmail && !readonly && (
                <Tooltip title="Добавить" placement="top-start">
                  <IconButton
                    color="primary"
                    onClick={() => {
                      setIsEditingEmail('');
                      setEmailValue(defaultEmailState);
                      setIsAddingNewEmail(true);
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              )}
              {isAddingNewEmail && <React.Fragment>{EmailForm}</React.Fragment>}
            </Grid>
          </Grid>

          {dirOrganization?.emails && dirOrganization?.emails.length > 0 && (
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="phone table">
                <TableHead>
                  <TableRow>
                    <TableCell>адрес</TableCell>
                    <TableCell>тип</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dirOrganization?.emails &&
                    dirOrganization?.emails?.map((e) =>
                      isEditingEmail === e.id ? (
                        <TableRow key={e.id}>
                          <TableCell>
                            <TextField
                              style={{ width: '100%' }}
                              label="Email"
                              helperText={(emailValue.email && Validation.email(emailValue.email)) || ''}
                              error={!!(emailValue && Validation.email(emailValue.email)) || false}
                              size="small"
                              value={emailValue.email}
                              onChange={(event) => setEmailValue({ ...emailValue, email: event.target.value })}
                            />
                          </TableCell>

                          <TableCell>
                            <FormControl size="small" style={{ minWidth: '20ch' }}>
                              <InputLabel id="email-type-label">Тип</InputLabel>
                              <Select
                                labelId="email-type-label"
                                label="Тип"
                                name="type"
                                value={emailValue.type}
                                onChange={(event) => setEmailValue({ ...emailValue, type: event.target.value })}
                              >
                                {clsContactsTypes.length > 0 &&
                                  clsContactsTypes.map((p) => (
                                    <MenuItem key={p.value} value={p.value}>
                                      {p.label}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </TableCell>
                          <TableCell>
                            {!readonly && (
                              <React.Fragment>
                                <Tooltip title="сохранить" placement="top-start">
                                  <IconButton color="primary" style={{ marginRight: '0.5rem' }} onClick={updateEmail}>
                                    <SaveIcon />
                                  </IconButton>
                                </Tooltip>

                                <Tooltip title="отменить" placement="top-start">
                                  <IconButton
                                    color="secondary"
                                    onClick={() => {
                                      setIsAddingNewEmail(false);
                                      setIsEditingEmail('');
                                      setEmailValue(defaultEmailState);
                                    }}
                                  >
                                    <CancelIcon />
                                  </IconButton>
                                </Tooltip>
                              </React.Fragment>
                            )}
                          </TableCell>
                        </TableRow>
                      ) : (
                        <TableRow key={e.id}>
                          <TableCell>{e.email}</TableCell>

                          <TableCell>{e.clsContactType.fullName}</TableCell>
                          <TableCell align="right">
                            {!readonly && (
                              <React.Fragment>
                                <Tooltip title="изменить" placement="top-start">
                                  <IconButton
                                    color="primary"
                                    style={{ marginRight: '0.5rem' }}
                                    onClick={() => {
                                      setEmailValue({ ...e, type: e.clsContactType.id });
                                      setCurrentRequest('update');
                                      setIsEditingEmail(e.id);
                                      setIsAddingNewEmail(false);
                                    }}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="удалить" placement="top-start">
                                  <IconButton color="secondary" onClick={() => setDeleteItem(e?.id)}>
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              </React.Fragment>
                            )}
                            <ConfirmDialog
                              title={`Удаление эл. почты`}
                              text={`Вы уверены что хотите удалить? ${e.email} `}
                              btnVariant="outlined"
                              open={deleteItem === e.id}
                              saveBtnText="удалить"
                              cancelBtnText="отмена"
                              saveBtnColor="secondary"
                              cancelBtnColor="primary"
                              onCancel={() => setDeleteItem(null)}
                              onSave={() => {
                                deleteEmail(e?.id);
                                setDeleteItem(null);
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      ),
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ContactInfo;
