import React, { useState } from 'react';
import { Button, Grid, IconButton, Typography } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import { DropzoneDialog } from 'material-ui-dropzone';
import {
  DeleteRefereeAgreementsDocMutationHookResult,
  UploadFileMutationHookResult,
  UploadRefereeAgreementsDocMutationHookResult,
  useDeleteRefereeAgreementsDocMutation,
  useGetListRefereeAgreementsDocsQuery,
  useUploadFileMutation,
  useUploadRefereeAgreementsDocMutation,
} from '../../../api';
import { useSnackbar } from 'notistack';
import DeleteIcon from '@material-ui/icons/Delete';

const RefereesDocuments = ({ id }: { id: string }) => {
  const [open, setOpen] = useState(false);
  const [uploadDocumentFile]: UploadFileMutationHookResult = useUploadFileMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { data, refetch } = useGetListRefereeAgreementsDocsQuery({ variables: { id } });
  const [addFile]: UploadRefereeAgreementsDocMutationHookResult = useUploadRefereeAgreementsDocMutation();
  const [deleteFile]: DeleteRefereeAgreementsDocMutationHookResult = useDeleteRefereeAgreementsDocMutation();

  const onAddNewDocument = async (files: File[]) => {
    if (files.length > 0) {
      await Promise.all(
        files.map(async (item) => {
          const fileResp = await uploadDocumentFile({
            variables: {
              file: item,
            },
          });
          if (fileResp?.data?.uploadFile?.id) {
            // @ts-ignore
            await addFile({
              variables: {
                id,
                idFile: fileResp?.data?.uploadFile?.id,
              },
            });
          }
        }),
      );
      refetch();
      setOpen(false);
      enqueueSnackbar(`Файл(ы) успешно добавлен(ы)`, { variant: 'success' });
      return;
    }
  };

  return (
    <div>
      <br />
      <br />
      <Typography style={{ fontWeight: 'bold' }}>Шаблоны согласий:</Typography>
      <Grid container direction="row">
        <Grid item container xs={12} alignItems="center">
          <a
            download="Согласие на обработку персональных данных.docx"
            href={`/79e80ffe-c0ce-4b3a-acd2-35e213715f63.docx`}
          >
            Согласие на обработку персональных данных.docx
          </a>
          <DownloadIcon style={{ marginLeft: 16 }} />
        </Grid>
        <Grid item container xs={12} alignItems="center">
          <a
            download="Согласие на обработку персональных данных, разрешенных для распространения.docx"
            href={`/7053409a-88b2-4590-9b33-b71034f08633.docx`}
          >
            Согласие на обработку персональных данных, разрешенных для распространения.docx
          </a>
          <DownloadIcon style={{ marginLeft: 16 }} />
        </Grid>
      </Grid>
      <br />
      <br />
      <Typography variant="h4" style={{ fontWeight: 'bold' }}>
        Согласия судей: &nbsp;&nbsp;
        <Button variant="contained" size="small" color="primary" onClick={() => setOpen(true)}>
          Загрузить файлы
        </Button>
      </Typography>
      <DropzoneDialog
        showFileNames
        open={open}
        alertSnackbarProps={{
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }}
        showAlerts={['error']}
        getFileAddedMessage={(fileName: string) => `Файл ${fileName} успешно загружен`}
        getDropRejectMessage={(file: File) =>
          `Файл ${file.name} отклонен. Тип файла не поддерживается. Файл слишком большой. Максимальный размер - 10 мегабайт.`
        }
        getFileLimitExceedMessage={(filesLimit: number) =>
          `За один раз возможно загрузить максимально ${filesLimit} файлов`
        }
        cancelButtonText={'закрыть'}
        submitButtonText={'Загрузить'}
        dialogTitle={'Загрузить файл'}
        onClose={() => setOpen(false)}
        filesLimit={10}
        maxFileSize={10485760}
        previewText={'Предпросмотр: '}
        clearOnUnmount
        initialFiles={[]}
        onSave={(files) => {
          return onAddNewDocument(files);
        }}
        dropzoneText="Максимальное количество файлов за раз: 10"
      />
      <Grid container style={{ marginTop: 16 }} spacing={1}>
        {data?.dirSportingEvent?.refereePersonalDataAgreements?.map((file, index) => {
          return (
            <Grid item container alignItems="center" spacing={1}>
              <Grid item>
                {index + 1}.&nbsp;
                <a href={file.path} target="_blank">
                  {file.name}
                </a>
              </Grid>
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <a style={{ height: '24px', color: 'black' }} href={file.path} target="_blank">
                  <DownloadIcon />
                </a>
              </Grid>
              <Grid item>
                <IconButton
                  style={{ padding: 0 }}
                  edge="end"
                  aria-label="delete"
                  onClick={async () => {
                    await deleteFile({ variables: { id, idFile: file.id } });
                    enqueueSnackbar(`Файл успешно удален`, { variant: 'success' });
                    refetch();
                  }}
                >
                  <DeleteIcon color="error" />
                </IconButton>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default RefereesDocuments;
