import React, { FC, useCallback, useState } from 'react';
import { DataTable } from '../../components/DataTable/NewTable';
import { MainTemplate } from '../../components/layouts';
import { DictionaryWrapper } from '../../components/DictionaryWrapper';
import {
  PersonFrFragment,
  useRemoveUserMutation,
  UsersDocument,
  UsersQuery,
  useUsersQuery,
  useUsersLazyQuery,
} from '../../api';
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import { MUIDataTableColumn } from 'mui-datatables';
import { usePermission } from '../../hooks/usePermission';
import { Link, useHistory } from 'react-router-dom';
import { BtnNew } from '../../components/BtnNew';
import DeleteIcon from '@material-ui/icons/Delete';
import getMessage from '../../messages';
import { useSnackbar } from 'notistack';
import { UsersFilters } from '../../components/Filters';
import { checkRolesAndReturnDefaultFilter } from './helpers';
import { useDispatch, useSelector } from 'react-redux';
import { AppStore } from '../../store/reducers';
import { getSettingsUserFilter, getSettingsUserFilterFormValue } from '../../store/selectors/settings';
import _ from 'lodash';
import { SET_USER_SETTINGS_FILTER } from '../../store/constants';

const Page: FC<{}> = () => {
  const additionalFilter = checkRolesAndReturnDefaultFilter();
  const defaultFilter = {
    role: { name_not: 'PERSON', AND: [additionalFilter] },
  };
  const dispatch = useDispatch();
  const filter: any = useSelector((state: AppStore) => getSettingsUserFilter(state, 'users'), _.isEqual);
  const formValue: any = useSelector((state: AppStore) => getSettingsUserFilterFormValue(state, 'users'), _.isEqual);

  const { push } = useHistory();
  const access = usePermission('users');

  const { enqueueSnackbar } = useSnackbar();

  const [remove] = useRemoveUserMutation({
    update: (proxy, { data }) => {
      const dataProxy: UsersQuery | null = proxy.readQuery({ query: UsersDocument });
      proxy.writeQuery({
        query: UsersDocument,
        data: dataProxy?.users.filter((item) => item.id !== data?.deleteUser?.id),
      });
    },
  });

  const onRemove = async (id: string, title: string) => {
    try {
      await remove({ variables: { id } });
      enqueueSnackbar(`Пользователь ${title} успешно удален`, { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  const setFilter = useCallback(
    (filter, form) => {
      dispatch({
        type: SET_USER_SETTINGS_FILTER,
        payload: {
          name: 'users',
          filter: filter,
          form,
        },
      });
    },
    [dispatch],
  );

  const columns: MUIDataTableColumn[] = [
    {
      name: 'email',
      label: 'Email',
      options: {
        filter: false,
        sort: false,
        display: true,
        viewColumns: false,
        customBodyRender: (value: Pick<PersonFrFragment, 'id'>, meta: any) => {
          const { rowIndex } = meta;
          return (
            <React.Fragment>
              <Tooltip title="Перейти в карточку" placement="top-start">
                <Link
                  style={{
                    fontWeight: 500,
                    cursor: 'pointer',
                    textDecoration: 'none',
                  }}
                  to={`/user/${meta.rowData[1]}`}
                  target={'_blank'}
                >
                  {value}
                </Link>
              </Tooltip>
            </React.Fragment>
          );
        },
      },
    },
    {
      name: 'id',
      label: 'ID',
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'name',
      label: 'Учетная запись',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'regular',
      label: 'ФИО',
      options: {
        filter: false,
        sort: false,
        display: true,

        customBodyRender: (value, meta) => {
          return `${value?.lastname || ''} ${value?.firstname || ''} ${value?.patronymic || ''}`;
        },
      },
    },
    {
      name: 'role',
      label: 'Роль',
      options: {
        filter: false,
        display: true,
        sort: false,
        customBodyRender: (val) => val.label,
      },
    },
    {
      name: 'isActive',
      label: 'Статус',
      options: {
        filter: false,
        display: true,
        sort: false,
        customBodyRender: (val) => (val ? 'Активный' : 'Заблокирован'),
      },
    },
    // {
    //   name: 'id',
    //   label: ' ',
    //   options: {
    //     filter: false,
    //     sort: false,
    //     viewColumns: false,
    //     customBodyRender: (value: string, meta) => {
    //       return (
    //         <Tooltip title="Удалить пользователя" placement="top-start">
    //           <IconButton
    //             style={{ padding: 0 }}
    //             edge="end"
    //             aria-label="delete"
    //             onClick={() => onRemove(value, meta.rowData[1])}
    //           >
    //             <DeleteIcon color="error" />
    //           </IconButton>
    //         </Tooltip>
    //       );
    //     },
    //   },
    // },
  ];

  return (
    <MainTemplate pageName="Администрирование пользователей" url="" title="Администрирование пользователей">
      <UsersFilters onSubmit={(filters: any, form: any) => setFilter(filters, form)} value={formValue} />
      <DictionaryWrapper>
        <DataTable
          tableName="users"
          title={
            <React.Fragment>
              <Grid container spacing={2} alignItems={'center'}>
                <Grid item>
                  <h2>Администрирование пользователей</h2>
                </Grid>
                {access.includes(2) && <Grid item>{<BtnNew onClick={() => push('/user/new')} />}</Grid>}
              </Grid>
            </React.Fragment>
          }
          lazyQuery={useUsersLazyQuery}
          filter={{ ...filter, ...defaultFilter }}
          columns={columns}
        />
      </DictionaryWrapper>
    </MainTemplate>
  );
};

export default Page;
