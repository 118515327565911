import { Button, Grid, IconButton, LinearProgress, Link, Tooltip } from '@material-ui/core';
import React, { FC, useMemo, useState } from 'react';
import {
  CalendarDocument,
  Scalars,
  UpdateCalendarMutationHookResult,
  UploadFileMutationHookResult,
  useCalendarQuery,
  useUpdateCalendarMutation,
  useUploadFileMutation,
} from '../../../../api';

import ConfirmDialog from '../../../ConfirmDialog';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import { DropzoneDialog } from 'material-ui-dropzone';
import RemoveIcon from '@material-ui/icons/Delete';
import getMessage from '../../../../messages';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles({
  title: {
    fontWeight: 500,
  },
  link: {
    cursor: 'pointer',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  icon: {
    fontSize: 20,
  },
  verticalSpaceMarge: {
    marginBottom: 35,
    padding: 0,
  },
  dropZone: {
    minHeight: 30,
    border: 0,
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    borderRadius: 0,

    '& > .MuiDropzonePreviewList-root': {
      margin: '0 !important',
      width: '100%',
      '& > .MuiDropzonePreviewList-imageContainer': {
        flexBasis: 'unset',
        width: '100%',
        maxWidth: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 15,
        '& > svg': {
          height: '40px',
        },
        '& > p': {
          marginLeft: 15,
          marginRight: 15,
        },
        '& > button': {
          top: 'unset',
          right: 0,
          position: 'unset',
          marginLeft: 30,

          color: '#d32f2f',
          boxShadow: 'unset',
        },
      },
    },
    '& > .MuiDropzoneArea-textContainer': {
      display: 'flex',

      '& > .MuiDropzoneArea-text': {
        margin: 0,
        fontSize: 15,
      },
      '& > .MuiDropzoneArea-icon': {
        width: 20,
        height: 20,
        marginLeft: 'auto',
      },
    },
    '&:focus': {
      outline: 0,
      border: 0,
      borderBotom: '2px solid #3f51b5',
    },
  },
});

const CalendarDocuments: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const DOWNLOAD_LINK = process.env.REACT_APP_BACKEND_URI?.replace('/graphql', '');

  const { loading, data, refetch } = useCalendarQuery({ returnPartialData: true, variables: { id } });

  const [update]: UpdateCalendarMutationHookResult = useUpdateCalendarMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: CalendarDocument,
        data: {
          ...data?.updateDirCalendar,
        },
      });
    },
  });

  const [uploadDocumentFile]: UploadFileMutationHookResult = useUploadFileMutation();
  const [deleteItem, setDeleteItem] = useState<any>(null);

  const regulationChanges = useMemo(() => data?.dirCalendar?.regulationChanges || [], [data]);

  const [open, setOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const onAddNewDocument = async (files: File[]) => {
    try {
      if (files?.length > 0) {
        setOpen(false);
        await Promise.all(
          files.map(async (file) => {
            const resp = await uploadDocumentFile({
              variables: {
                file,
              },
            });
            if (resp?.data?.uploadFile?.id) {
              const {
                uploadFile: { id: fileId },
              } = resp.data;
              await update({
                variables: {
                  data: {
                    regulationChanges: {
                      connect: [{ id: fileId }],
                    },
                  },
                  id,
                },
              });
            }
          }),
        );
        handleSnackBar('success', 'документы успешно добавлены');
        refetch();
      }
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const deleteDocument = async (documentId: Scalars['UUID']) => {
    try {
      await update({
        variables: {
          data: {
            regulationChanges: {
              disconnect: [
                {
                  id: documentId,
                },
              ],
            },
          },
          id,
        },
      });
      handleSnackBar('success', 'документ успешно удален');
      refetch();
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const classes = useStyles();

  if (loading) return <LinearProgress color="primary" variant="query" />;

  return (
    <div>
      <Grid container className={classes.verticalSpaceMarge}>
        <Grid style={{ marginBottom: 15 }} item container>
          <Grid item>
            <span style={{ fontSize: 16, fontWeight: 'bold' }}>Изменение к регламенту:&nbsp;</span>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              component="span"
              size="small"
              onClick={() => {
                setOpen(true);
              }}
            >
              загрузить файлы
            </Button>
          </Grid>
        </Grid>
        {regulationChanges?.map((item: any, index: number) => (
          <Grid item container alignItems="center" spacing={2} key={item.id}>
            <Grid item container justify="space-between" md={3} xs={8}>
              <Grid item>
                {index + 1}.&nbsp;
                <Link href={`${DOWNLOAD_LINK}${item.path}`} className={classes.link}>
                  {item.name}
                </Link>
              </Grid>
              <Grid item>
                <DownloadIcon onClick={() => {}} />
              </Grid>
            </Grid>
            {!readonly && (
              <Grid item>
                <Tooltip title="удалить" placement="top-start">
                  <IconButton className={classes.btn} color="secondary" onClick={() => setDeleteItem(item.id)}>
                    <RemoveIcon className={classes.icon} />
                  </IconButton>
                </Tooltip>
                <ConfirmDialog
                  title={`Удаление протокола хода соревнований`}
                  text={`Вы уверены что хотите удалить документ от :  
                      ${moment(item.date).format('DD.MM.YYYY')}`}
                  btnVariant="outlined"
                  open={deleteItem === item.id}
                  saveBtnText="удалить"
                  cancelBtnText="отмена"
                  saveBtnColor="secondary"
                  cancelBtnColor="primary"
                  onCancel={() => setDeleteItem(null)}
                  onSave={() => {
                    deleteDocument(item.id).then(() => setDeleteItem(null));
                  }}
                />
              </Grid>
            )}
          </Grid>
        ))}
      </Grid>
      <DropzoneDialog
        showFileNames
        open={open}
        alertSnackbarProps={{
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }}
        showAlerts={['error']}
        getFileAddedMessage={(fileName: string) => `Файл ${fileName} успешно загружен`}
        getDropRejectMessage={(file: File) =>
          `Файл ${file.name} отклонен. Тип файла не поддерживается. Файл слишком большой. Максимальный размер - 10 мегабайт.`
        }
        getFileLimitExceedMessage={(filesLimit: number) =>
          `За один раз возможно загрузить максимально ${filesLimit} файлов`
        }
        cancelButtonText={'закрыть'}
        submitButtonText={'Загрузить'}
        dialogTitle={'Загрузить файл'}
        onClose={() => setOpen(false)}
        filesLimit={10}
        maxFileSize={10485760}
        previewText={'Предпросмотр: '}
        clearOnUnmount
        initialFiles={[]}
        onSave={(files) => {
          return onAddNewDocument(files);
        }}
        dropzoneText="Максимальное количество файлов за раз: 10"
      />
    </div>
  );
};

export default CalendarDocuments;
