import React, { FC } from 'react';

import { Controller } from 'react-hook-form';
import { ControllerProps } from 'react-hook-form/dist/types/props';
import { TextField } from '@material-ui/core';

interface ReactHookFormInput extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules' | 'type'> {
  label: string;
  errorMessage?: string;
  multiline?: boolean;
  rows?: number;
  error: boolean | undefined;
  disabled?: boolean;
  defaultValue?: string;
  style?: any;
  positive?: boolean;
}

const Input: FC<ReactHookFormInput> = ({
  name,
  error,
  multiline,
  rows,
  type = 'text',
  label,
  errorMessage = '',
  control,
  rules,
  disabled,
  defaultValue = '',
  style,
  positive,
}) => {
  return (
    <Controller
      as={
        <TextField
          size="small"
          multiline={multiline}
          rows={rows}
          type={type}
          label={
            <>
              {label}
              {rules?.required === true && <span style={{ color: 'red' }}>*</span>}
            </>
          }
          fullWidth
          inputProps={positive ? { min: 0 } : {}}
          error={error}
          helperText={errorMessage}
          disabled={disabled}
          defaultValue={defaultValue}
          {...(style && { style })}
        />
      }
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
    />
  );
};

export default Input;
