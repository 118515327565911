import moment from 'moment';
import { SportingEventFragment } from '../../../../api';
import _ from 'lodash';

export const revealEvents = (array: Array<{ __typename?: 'DirSportingEvent' } & SportingEventFragment>) => {
  const unifiedArray = array.map((activity) => {
    const { id, registryNumber, startDate, endDate, sports, dirNationalTeams } = activity;
    const sportString = sports && sports.map((s) => s.dirSport && s.dirSport.label).join(', ');
    let disciplinesString = _.flattenDeep(sports?.map((sport) => sport.disciplineGroups?.map((d) => d.label))).join(
      ', ',
    );

    return {
      info: { id, registryNumber },
      period: `${startDate && moment(startDate).format('DD.MM.YY')} - ${endDate && moment(endDate).format('DD.MM.YY')}`,
      sportTypendDisciplines: `${sportString} ${disciplinesString && `${'/ ' + disciplinesString}`}`,
      athletesCount: dirNationalTeams?.reduce(
        (acc, curr) =>
          acc +
          (curr.athleteGroups
            ? curr.athleteGroups.reduce(
                (accGroup, currGroup) => accGroup + (currGroup.athletes ? currGroup.athletes.length : 0),
                0,
              )
            : 0),
        0,
      ),
      athletesCompany: dirNationalTeams?.reduce(
        (acc, curr) =>
          acc +
          _.get(curr, 'specialists.length', 0) +
          _.get(curr, 'medics.length', 0) +
          _.get(curr, 'trainers.length', 0),
        0,
      ),
      ...activity,
    };
  });
  return unifiedArray || [];
};

export const revealEventMultiple = (data: any) => revealEvents(data?.map((event: any) => event?.sportingEvent) || []);
