import {
  AddTypeSportsToAthleteMutationHookResult,
  AthleteDocument,
  TypeSportAthleteArchiveMutationHookResult,
  TypeSportAthleteUnArchiveMutationHookResult,
  useAddTypeSportsToAthleteMutation,
  useAthleteSportsQuery,
  useTypeSportAthleteArchiveMutation,
  useTypeSportAthleteUnArchiveMutation,
} from '../../../api';
import { Grid, IconButton, LinearProgress } from '@material-ui/core';
import React, { FC, useEffect } from 'react';

import AddIcon from '@material-ui/icons/Add';
import SportAccordion from './components/SportAccordion';
import TypeSportModule from '../../../module/TypeSports';
import getMessage from '../../../messages';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

const TypeSports: FC<{ readonly?: boolean; regionsId: Array<string>; id: string }> = ({ readonly, regionsId, id }) => {
  const { data, loading, refetch } = useAthleteSportsQuery({ fetchPolicy: 'no-cache', variables: { id } });
  const { handleSubmit, control, errors, reset } = useForm({
    defaultValues: { typesport: null },
  });
  const { enqueueSnackbar } = useSnackbar();
  const [create]: AddTypeSportsToAthleteMutationHookResult = useAddTypeSportsToAthleteMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: AthleteDocument,
        data: {
          ...data?.updateDirAthlete,
        },
      });
    },
  });

  const [archive]: TypeSportAthleteArchiveMutationHookResult = useTypeSportAthleteArchiveMutation();
  const [unarchive]: TypeSportAthleteUnArchiveMutationHookResult = useTypeSportAthleteUnArchiveMutation();

  const onSubmit = handleSubmit(async (values?: { typesport?: { label: string; value: any } | null }) => {
    if (!values?.typesport) return;
    try {
      await create({
        variables: {
          id,
          sportId: values?.typesport?.value,
        },
      });
      reset({ typesport: null });
      enqueueSnackbar(`Вид спорта "${values?.typesport?.label}" успешно добавлен`, {
        variant: 'success',
      });
      refetch();
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  });

  if (loading) return <LinearProgress />;

  return (
    <form onSubmit={onSubmit} style={{ width: '100%' }}>
      <Grid container direction="row" spacing={1}>
        {!readonly && (
          <>
            <Grid item md={6}>
              <TypeSportModule
                usedValue={data?.dirAthlete?.sports?.map((sport: any) => sport.dirSport.value)}
                label="Вид спорта"
                error={!!errors['typesport']}
                name="typesport"
                control={control}
              />
            </Grid>
            <Grid item md={1}>
              <IconButton edge="end" aria-label="add" type="submit" style={{ display: 'inline', marginLeft: 16 }}>
                <AddIcon color="primary" />
              </IconButton>
            </Grid>
          </>
        )}
        <Grid md={12} style={{ marginTop: 16 }}>
          {data?.dirAthlete?.sports?.map((item) => (
            <SportAccordion
              key={item?.id}
              sport={item}
              regionsId={regionsId}
              onUpdate={() => refetch()}
              athleteId={id}
              readonly={readonly}
            />
          ))}
        </Grid>
      </Grid>
    </form>
  );
};

export default TypeSports;
