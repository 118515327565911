import { saveAs } from 'file-saver';
import JSZipUtils from 'jszip-utils';

function loadFile(url) {
  return new Promise(function (resolve, reject) {
    JSZipUtils.getBinaryContent(url, function (err, data) {
      if (err) reject(err);
      resolve(data);
    });
  });
}
const DOWNLOAD_LINK = process.env.REACT_APP_DOCSERVICE_API_URI;
const D_LINK = (process.env.REACT_APP_BACKEND_URI || '').replace('/graphql', '') + '/files/common/';
const apiUri = `${DOWNLOAD_LINK}/merge_word_docs`;
export const generateDoc = async (paths: string[]) => {
  const response = await fetch(apiUri, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      files: paths,
      d_link: D_LINK,
    }),
  });
  const content = await response.blob();
  saveAs(content, 'Итоговый регламент.docx');
};
