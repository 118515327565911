import { Grid, Tooltip } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import React, { FC, Fragment, useCallback, useState } from 'react';
import { getSettingsUserFilter, getSettingsUserFilterFormValue } from '../../store/selectors/settings';
import { useAthletesLazyQuery, useAthletesSimilarLazyQuery } from '../../api';
import { useDispatch, useSelector } from 'react-redux';

import { AppStore } from '../../store/reducers';
import { AthletesFilter } from '../../components/Filters';
import { BtnNew } from '../../components/BtnNew';
import { DataTable } from '../../components/DataTable/NewTable';
import { DictionaryWrapper } from '../../components/DictionaryWrapper';
import { MUIDataTableColumn } from 'mui-datatables';
import { MainTemplate } from '../../components/layouts';
import { SET_USER_SETTINGS_FILTER } from '../../store/constants';
import UniBox from '../../components/DupliBox';
import _ from 'lodash';
import moment from 'moment';

import { filterKey } from '../../utils/filterKey';
import { usePermission } from '../../hooks/usePermission';
import userRights from '../../config/userRights';

const Page: FC<{}> = () => {
  const { hasAdditionalFilter } = userRights();

  // TODO: Для сохранения состояния фильтров
  const dispatch = useDispatch();
  const filter: any = useSelector((state: AppStore) => getSettingsUserFilter(state, 'athletes'), _.isEqual);
  const formValue: any = useSelector((state: AppStore) => getSettingsUserFilterFormValue(state, 'athletes'), _.isEqual);

  const [first, setFirst] = useState(0);
  const { push } = useHistory();
  const access = usePermission('directory', 'athletes');

  // TODO: Для сохранения состояния фильтров
  const setFilter = useCallback(
    (filter, form) => {
      dispatch({
        type: SET_USER_SETTINGS_FILTER,
        payload: {
          name: 'athletes',
          filter: filter,
          form,
        },
      });
    },
    [dispatch],
  );

  const columns: MUIDataTableColumn[] = [
    {
      name: 'info',
      label: 'ФИО',
      options: {
        filter: false,
        viewColumns: false,
        sort: false,
        customBodyRender: (info: any, tableMeta: any) => {
          const { rowData } = tableMeta;
          const fio = rowData[1] || '';
          const { id } = info || {};

          return (
            <React.Fragment>
              <Tooltip title="Перейти в карточку" placement="top-start">
                <Link
                  style={{
                    fontWeight: 500,
                    cursor: 'pointer',
                    textDecoration: 'none',
                  }}
                  to={`/athlete/${id}`}
                  target={'_blank'}
                >
                  {fio}
                </Link>
              </Tooltip>
            </React.Fragment>
          );
        },
      },
    },
    {
      name: 'fio',
      label: 'Фамилия Имя Отчество',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'birthday',
      label: 'Дата рождения',
      options: {
        filter: false,
        display: true,

        customBodyRender: (birthday) => moment(birthday).format('DD.MM.YYYY'),
      },
    },
    {
      name: 'isMale',
      label: 'Пол',
      options: {
        filter: false,
        display: true,

        customBodyRender: (isMale) => {
          return isMale ? 'Муж' : 'Жен';
        },
      },
    },
    {
      name: 'region',
      label: 'Регион основной',
      options: {
        filter: false,
        display: true,
        sort: false,
      },
    },
    {
      name: 'sport',
      label: 'Вид спорта',
      options: {
        filter: false,
        display: true,

        sort: false,
        customBodyRender: (sport) => (
          <Fragment>
            <p style={{ margin: 0 }}>{sport?.label}</p>
            {sport?.ranks?.length > 0 && (
              <p>
                <strong>Разряд/ Звание:</strong> {sport?.ranks.join(' , ')}
              </p>
            )}
          </Fragment>
        ),
      },
    },
    {
      name: 'organization',
      label: 'Организация',
      options: {
        filter: false,
        display: true,

        sort: false,
      },
    },
    {
      name: 'trainingStages',
      label: 'Этапы подготовки',
      options: {
        filter: false,
        display: true,

        sort: false,
      },
    },
    {
      name: 'trainers',
      label: 'Тренеры',
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: 'info',
      label: 'Реестровый номер',
      options: {
        filter: false,
        display: true,
        sort: false,
        customBodyRender: (info) => info?.registryNumber,
      },
    },
    {
      name: 'dirPerson',
      label: ' ',
      options: {
        filter: false,
        display: true,
        viewColumns: false,

        sort: false,
        customBodyRender: (value: any, meta: any) => {
          return value?.archive ? null : (
            <Fragment>
              <UniBox fio={meta.rowData[1]} idPerson={value?.id} />
            </Fragment>
          );
        },
      },
    },
  ];

  return (
    <MainTemplate pageName="Спортсмены" url="" title="Спортсмены / АИС ФЦПСР">
      <AthletesFilter
        onSubmit={(filters: any, form: any) => setFilter({ ...filters }, form)}
        value={formValue}
        first={20}
        defaultFilter={{ ...hasAdditionalFilter?.athletes }}
      />
      <DictionaryWrapper>
        <DataTable
          title={
            <React.Fragment>
              <Grid container spacing={2} alignItems={'center'}>
                <Grid item>
                  <h2>Справочник спортсменов</h2>
                </Grid>
                {access.includes(2) && <Grid item>{<BtnNew onClick={() => push('/athlete/new')} />}</Grid>}
              </Grid>
            </React.Fragment>
          }
          tableName="athletes"
          columns={columns}
          revealFunctionName="athletes"
          lazyQuery={filter?.name ? useAthletesSimilarLazyQuery : useAthletesLazyQuery}
          filter={{ ...filter, ...hasAdditionalFilter?.athletes }}
          hasDoubleQuery
          // onPerPageNeeded={(perPage:number) => setFirst(perPage)}
        />
      </DictionaryWrapper>
    </MainTemplate>
  );
};

export default Page;
