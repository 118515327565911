import React, { FC } from 'react';

import { ControllerProps } from 'react-hook-form/dist/types/props';
import { Select } from '../components/Inputs';
import { useUniversityOrganizationsQuery } from '../api';

interface IUniversitiesByRegion extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  error: boolean | undefined;
  disabled?: boolean;
  regionId: string;
  multiple?: boolean;
  useLabelElement?: boolean;
  universityList?: string[];
}

const UniversitiesByRegion: FC<IUniversitiesByRegion> = ({
  label,
  error,
  control,
  name,
  rules,
  disabled,
  regionId,
  multiple,
  useLabelElement,
  universityList,
}) => {
  const { data } = useUniversityOrganizationsQuery({
    skip: !regionId,
    variables: {
      regionId,
    },
    fetchPolicy: 'no-cache',
  });

  return (
    <Select
      label={label}
      data={(data && data?.dirOrganizations) || []}
      control={control}
      error={error}
      name={name}
      useLabelElement={useLabelElement}
      existElements={universityList}
      rules={rules}
      multiple={multiple}
      disabled={disabled}
    />
  );
};

export default UniversitiesByRegion;
