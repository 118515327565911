import exceljs from 'exceljs';
import { CalendarReportEventFragment } from '../../../api';
import { saveAs } from 'file-saver';

export type TRegionL = {
  label: string;
  value: string;
  __typename?: string;
};

export type FetchCalendarReport = {
  data: Data;
  reportNumber?: number;
  lastTableColumnLetter?: string;
  CalendarNamePosition?: string;
  filter?: {
    service: {
      regions?: Array<TRegionL>;
    };
  };
  mergeCellsPosition?: Array<[string, string]>;
  blancUrl?: string;
  fileName: string;
  mergeStart?: number;
  fullReport?: boolean;
  calendarName?: string;
};

export type TRightDataResult = {
  [key: string]: {
    events: Data;
  };
};

export type Data = Array<CalendarReportEventFragment>;

const getRightValue = (data: Data, regionsArray: Array<TRegionL>) => {
  const result: TRightDataResult = {};
  for (const item of regionsArray) {
    const regionEvents = data.filter((event) => event.dirRegion?.id === item.value);
    if (regionEvents.length > 0) {
      result[item.label] = {
        events: regionEvents,
      };
    }
  }
  return result;
};

export const fetchCalendarReport: (props: FetchCalendarReport) => void = async (props) => {
  try {
    const {
      data = [],
      reportNumber = 1,
      lastTableColumnLetter = 'F',
      CalendarNamePosition = 'A7:F7',
      mergeCellsPosition = [['B', 'B']],
      filter = { service: {} },
      blancUrl = '/blancReport4.xlsx',
      fileName,
      mergeStart = 11,
      calendarName = '',
    } = props;
    const {
      service: { regions: regionsArray = [] },
    } = filter;

    const workbook = new exceljs.Workbook();

    const saveFile = async () => {
      const buffer = await workbook.xlsx.writeBuffer({
        useStyles: true,
      });
      const blob = new Blob([buffer], { type: 'applicationi/xlsx' });
      saveAs(blob, fileName);
    };
    const blanc = await (await fetch(blancUrl)).arrayBuffer();
    await workbook.xlsx.load(blanc);
    const sheet = workbook.worksheets[0];

    const rightData = getRightValue(data, regionsArray);

    const setCalendarName = () => {
      sheet.getCell(CalendarNamePosition).value = calendarName;
    };

    const mergeCells = (cellsPosition: string) => {
      sheet.unMergeCells(cellsPosition);
      sheet.mergeCells(cellsPosition);
    };

    const drawLastColumnBorder = () => {
      for (let index = 0; index < tableTrack.length; index++) {
        const cellName = `${lastTableColumnLetter}${index + mergeStart}`;
        const cell = sheet.getCell(cellName);

        sheet.getCell(cellName).style = {
          ...cell.style,
          border: {
            ...cell.style.border,
            right: {
              style: 'thick',
              color: {
                argb: '000000',
              },
            },
          },
        };
      }
    };

    const drawCurrentLastRowBorder = (regionTrack: number[]) => {
      const lastTableRow = sheet.getRow(regionTrack.length + tableTrack.length + mergeStart - 1);

      lastTableRow.eachCell((cell) => {
        cell.style = {
          ...cell.style,
          border: {
            bottom: {
              style: 'thick',
              color: {
                argb: '000000',
              },
            },
          },
          alignment: {
            horizontal: 'center',
            wrapText: true,
            shrinkToFit: true,
          },
        };
      });
    };

    const tableTrack = [];

    for (const key in rightData) {
      const regionTrack = [];
      const { events } = rightData[key];

      const sportLength = new Set(
        events
          .map((event) => event.sports)
          .flat()
          .map((s) => s?.dirSport.value),
      ).size;

      for (const event of events) {
        if (event.sports && event.sports.length > 0) {
          for (let index = 0; index < event.sports.length; index++) {
            const programTypes = event.programTypes;
            regionTrack.push(regionTrack.length + 1);

            const sport = event.sports[index].dirSport;

            sheet.insertRow(
              mergeStart + tableTrack.length + regionTrack.length - 1,
              [
                regionTrack.length + tableTrack.length,
                (regionTrack.length === 1 && key) || '',
                ...(reportNumber === 2 ? [sportLength] : []),
                sport.label +
                  (programTypes && programTypes.length > 0
                    ? ` (${event.programTypes
                        ?.map((p: any) => p.clsAgeGroups?.map((a: any) => a.label).join(', '))
                        .join(', ')})`
                    : ''),
                event.name,
                event.ekp || '',
                Number(event.membersQuota || ''),
              ],
              'o',
            );
          }
        }
      }
      for (const position of mergeCellsPosition) {
        mergeCells(
          `${position[0]}${mergeStart + tableTrack.length}:${position[1]}${
            tableTrack.length + regionTrack.length + mergeStart - 1
          }`,
        );
      }
      drawCurrentLastRowBorder(regionTrack);
      tableTrack.push(...regionTrack);
      drawLastColumnBorder();
    }

    setCalendarName();

    await saveFile();
  } catch (error) {
    console.log('error', error);
  }
};

export const fetchCalendarUniverciadReport: (props: FetchCalendarReport) => void = async (props) => {
  try {
    const {
      data = [],
      reportNumber = 1,
      lastTableColumnLetter = 'F',
      CalendarNamePosition = 'A7:F7',
      mergeCellsPosition = [['B', 'B']],
      filter = { service: {} },
      blancUrl = '/blancReport4.xlsx',
      fileName,
      mergeStart = 11,
    } = props;
    const {
      service: { regions: regionsArray = [] },
    } = filter;
    const calendarName = data[0]?.dirCalendar?.fullName || '';
    const workbook = new exceljs.Workbook();

    const saveFile = async () => {
      const buffer = await workbook.xlsx.writeBuffer({
        useStyles: true,
      });
      const blob = new Blob([buffer], { type: 'applicationi/xlsx' });
      saveAs(blob, fileName);
    };
    const blanc = await (await fetch(blancUrl)).arrayBuffer();
    await workbook.xlsx.load(blanc);
    const sheet = workbook.worksheets[0];

    const rightData = getRightValue(data, regionsArray);

    const setCalendarName = () => {
      sheet.getCell(CalendarNamePosition).value = calendarName;
    };

    const mergeCells = (cellsPosition: string) => {
      sheet.unMergeCells(cellsPosition);
      sheet.mergeCells(cellsPosition);
    };

    const drawLastColumnBorder = () => {
      for (let index = 0; index < tableTrack.length; index++) {
        const cellName = `${lastTableColumnLetter}${index + mergeStart}`;
        const cell = sheet.getCell(cellName);

        sheet.getCell(cellName).style = {
          ...cell.style,
          border: {
            ...cell.style.border,
            right: {
              style: 'thick',
              color: {
                argb: '000000',
              },
            },
          },
        };
      }
    };

    const drawCurrentLastRowBorder = (regionTrack: number[]) => {
      const lastTableRow = sheet.getRow(regionTrack.length + tableTrack.length + mergeStart - 1);

      lastTableRow.eachCell((cell) => {
        cell.style = {
          ...cell.style,
          border: {
            bottom: {
              style: 'thick',
              color: {
                argb: '000000',
              },
            },
          },
          alignment: {
            horizontal: 'center',
            wrapText: true,
            shrinkToFit: true,
          },
        };
      });
    };

    const tableTrack = [];

    for (const key in rightData) {
      const { events } = rightData[key];

      const sportLength = new Set(
        events
          .map((event) => event.sports)
          .flat()
          .map((s) => s?.dirSport.value),
      ).size;

      for (const event of events) {
        if (event.sports && event.sports.length > 0) {
          for (let index = 0; index < event.sports.length; index++) {
            const programTypes = event.programTypes;

            const sport = event.sports[index].dirSport;

            sheet.insertRow(
              mergeStart + tableTrack.length,
              [
                tableTrack.length,
                key || '',
                ...(reportNumber === 2 ? [sportLength] : []),
                sport.label +
                  (programTypes && programTypes.length > 0
                    ? ` (${event.programTypes
                        ?.map((p: any) => p.clsAgeGroups?.map((a: any) => a.label).join(', '))
                        .join(', ')})`
                    : ''),
                event.name,
                event.ekp || '',
                Number(event.membersQuota || ''),
              ],
              'o',
            );
          }
        }
      }
      for (const position of mergeCellsPosition) {
        mergeCells(
          `${position[0]}${mergeStart + tableTrack.length}:${position[1]}${tableTrack.length + mergeStart - 1}`,
        );
      }
      // tableTrack.push(...regionTrack);
      drawLastColumnBorder();
    }

    setCalendarName();

    await saveFile();
  } catch (error) {
    console.log('error', error);
  }
};
