import React, { FC, useMemo } from 'react';
import { LinearProgress } from '@material-ui/core';

import SportingEventRequestAccordion from './components/SportingEventRequestAccordion';
import { useSportingEventRequestQuery } from '../../../api';
import _ from 'lodash';
import moment from 'moment';

const SportingEventRequests: FC<{
  readonly?: boolean;
  id?: string;
  requestId?: string;
  isRequestPage?: boolean;
  onStatusUpdated: Function;
}> = ({ readonly, id, isRequestPage, requestId, onStatusUpdated }) => {
  const filter = isRequestPage
    ? { id: requestId }
    : {
        event: {
          id,
        },
      };

  const { loading, data, refetch } = useSportingEventRequestQuery({
    fetchPolicy: 'no-cache',
    variables: { filter },
  });

  const requestArray: Array<any> = useMemo(() => {
    const changes = data?.dirSportingEventRequests?.length
      ? data?.dirSportingEventRequests[0]?.event?.changes?.find((c) => c?.requests?.find((r) => r?.type?.value === 2))
      : undefined;
    const changeR = changes?.requests?.find((r) => r?.type?.value === 2)
      ? { ...changes?.requests?.find((r) => r?.type?.value === 2), event: changes }
      : undefined;
    return _([data?.dirSportingEventRequests, changeR])
      .flattenDeep()
      .remove((a: any) => a)
      .sort((a: any, b: any) => moment(b?.createdAt).diff(moment(a?.createdAt)))
      .value();
  }, [data]);

  if (loading) return <LinearProgress />;

  return (
    <div style={{ width: '100%' }}>
      {requestArray.map((sportingEventRequest: any) => (
        <SportingEventRequestAccordion
          key={sportingEventRequest?.id}
          isRequestPage={isRequestPage}
          sportingEventRequest={sportingEventRequest}
          readonly={readonly}
          onUpdate={() => {
            refetch();
            onStatusUpdated();
          }}
        />
      ))}
    </div>
  );
};

export default SportingEventRequests;
