import React, { FC, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useAthleteQuery } from '../../../../api';
import { Typography, Grid } from '@material-ui/core';
import { shuffleMedicValidDate } from '../../Athletes/helpers';
import moment from 'moment';
import _ from 'lodash';

const StatusDop: FC<{}> = () => {
  const {
    params: { id },
  } = useRouteMatch();
  const { loading, data } = useAthleteQuery({ fetchPolicy: 'no-cache', variables: { filter: { id } } });

  const medCerts = data?.dirAthlete?.medCerts;

  const status = _.maxBy(
    medCerts?.filter((med) => med.isValid && !med.deletedAt),
    function (o: any) {
      return o?.validUntil;
    },
  );

  const noCerts = useMemo(
    () => medCerts?.filter((med) => med.isValid).length === 0 || medCerts?.filter((med) => !med.deletedAt).length === 0,
    [medCerts],
  );

  return (
    <div style={{ margin: '20px 0px' }}>
      <Grid container spacing={2}>
        <Grid item>
          <Typography>
            <strong>Статус допуска:</strong>
          </Typography>
        </Grid>
        <Grid item>
          {(medCerts?.length === 0 && <Typography>отсутствует информация</Typography>) ||
            (noCerts && <Typography>Не допущен</Typography>) ||
            (status && (
              <Typography style={{ color: shuffleMedicValidDate(status)?.color }}>
                {shuffleMedicValidDate(status, true)?.text}
              </Typography>
            ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default StatusDop;
