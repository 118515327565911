import React, { FC, useState, useEffect } from 'react';
import { Grid, Tooltip, IconButton, Button, Dialog, DialogTitle, DialogContent, Link, Paper } from '@material-ui/core';
import SportEvents from '../../../../module/SportEvents';
import { Input } from '../../../Inputs';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddBoxIcon from '@material-ui/icons/AddBox';
import SaveIcon from '@material-ui/icons/Save';
import EditLocationIcon from '@material-ui/icons/EditLocation';
import { useForm } from 'react-hook-form';

import { Scalars, UpdateSportingEventMutationHookResult, useUpdateSportingEventMutation } from '../../../../api';
import _ from 'lodash';
import moment from 'moment';

interface LightEvent {
  value: Scalars['UUID'];
  label: string;
  registryNumber: number;
}

interface FormEventInterface {
  dirSportingEvent?: LightEvent;
}

interface CommentValueInterface {
  comment: string;
}

interface EventInfo {
  classes?: any;
  commentUpdated: Function;
  parentAdded: Function;
  parentRemoved: Function;
  childAdded: Function;
  childRemoved: Function;
  onError: Function;
  dirSportingEvent?: any;
  readOnly?: boolean;
  dChanges?: string[];
}

const Structure: FC<EventInfo> = ({
  classes,
  dirSportingEvent,
  parentAdded,
  parentRemoved,
  childAdded,
  childRemoved,
  commentUpdated,
  onError,
  readOnly,
  dChanges,
}) => {
  const { id, parent, children } = dirSportingEvent;

  const [besideArray, setBesideArray] = useState<Array<Scalars['UUID']>>([]);

  const { handleSubmit, control, errors, formState } = useForm({
    defaultValues: {
      comment: dirSportingEvent?.comment,
    } as {
      comment: string;
    },
  });

  const {
    handleSubmit: parentFormHandleSubmit,
    control: parentFormControl,
    errors: parentFormError,
    formState: parentFormState,
  } = useForm({
    defaultValues: {
      dirSportingEvent: undefined,
    },
  });

  const {
    handleSubmit: childFormHandleSubmit,
    control: childFormControl,
    errors: childFormError,
    formState: childFormState,
  } = useForm({
    defaultValues: {
      dirSportingEvent: undefined,
    },
  });

  const [open, setOpen] = useState('');

  const [updateSportingEvent]: UpdateSportingEventMutationHookResult = useUpdateSportingEventMutation();

  const handleClickOpen = (name: string) => {
    setOpen(name);
  };

  const handleClose = () => {
    setOpen('');
  };

  const onSubmit = async (values: CommentValueInterface) => {
    try {
      await updateSportingEvent({
        variables: {
          id,
          data: {
            comment: values.comment,
          },
        },
      });
      commentUpdated();
    } catch (error) {
      onError(error);
    }
  };

  const updateBesideArray = (data: any) => {
    let availableChildArray = [id];

    if (data && data.parent) {
      availableChildArray = [...availableChildArray, data.parent.value];
    }
    if (data && data.children && data.children.length > 0) {
      availableChildArray = [...availableChildArray, ...data.children.map((child: LightEvent) => child.value)];
    }
    setBesideArray(_.sortedUniq(availableChildArray));
  };

  const handleUpdateParentEvent = async (values: FormEventInterface) => {
    try {
      updateSportingEvent({
        variables: {
          id,
          data: {
            parent: { connect: { id: values?.dirSportingEvent?.value } },
          },
        },
      }).then((resp: any) => {
        const { updateDirSportingEvent } = resp.data;
        updateBesideArray(updateDirSportingEvent);
        setOpen('');
        parentAdded();
      });
    } catch (error) {
      onError(error);
    }
  };

  const handleRemoveParentEvent = () => {
    try {
      updateSportingEvent({
        variables: {
          id,
          data: {
            parent: { disconnect: true },
          },
        },
      }).then((resp: any) => {
        const { updateDirSportingEvent } = resp.data;
        updateBesideArray(updateDirSportingEvent);
        parentRemoved();
      });
    } catch (error) {
      onError(error);
    }
  };

  const handleAddChildEvent = async (values: FormEventInterface) => {
    try {
      updateSportingEvent({
        variables: {
          id,
          data: {
            children: { connect: [{ id: values?.dirSportingEvent?.value }] },
          },
        },
      }).then((resp: any) => {
        const { updateDirSportingEvent } = resp.data;
        updateBesideArray(updateDirSportingEvent);
        setOpen('');
        childAdded();
      });
    } catch (error) {
      onError(error);
    }
  };

  // const handleRemoveChildren = async (childID: Scalars['UUID']) => {
  //   try {
  //     updateSportingEvent({
  //       variables: {
  //         id,
  //         data: {
  //           children: { disconnect: [{ id: childID }] },
  //         },
  //       },
  //     }).then((resp: any) => {
  //       const { updateDirSportingEvent } = resp.data;
  //       updateBesideArray(updateDirSportingEvent);
  //       childRemoved();
  //     });
  //   } catch (error) {
  //     onError(error);
  //   }
  // };

  useEffect(() => {
    updateBesideArray({ parent, children });
  }, [parent, children]);
  const valuesComma = [
    parent?.registryNumber,
    `${moment(parent?.startDate).format('DD.MM.YYYY')}-${moment(parent?.endDate).format('DD.MM.YYYY')}`,
  ];
  parent?.sports?.forEach((sport: any) => {
    valuesComma.push(sport?.dirSport?.label);

    sport?.disciplineGroups?.forEach((item: any) => {
      valuesComma.push(item.label);
    });
  });
  return (
    <React.Fragment>
      <Paper elevation={3} style={{ width: '100%', padding: 15, margin: '10px 0' }}>
        <Grid container className={classes.verticalSpace}>
          {parent && Object.keys(parent).length > 0 ? (
            <Grid item container alignItems="center">
              <span className={classes.title}>Основное мероприятие:&nbsp;</span>
              <Link target="_blank" href={`/sporting_event/${parent?.value}`}>
                {parent.label}
              </Link>
              <span className={classes.title} style={{ margin: '0 1rem' }}>
                {valuesComma.join(', ')}
              </span>
              {!readOnly && (
                <Tooltip title="изменить" placement="top-start">
                  <IconButton className={classes.btn} color="primary" onClick={() => handleClickOpen('parentDialog')}>
                    <EditIcon className={classes.icon} />
                  </IconButton>
                </Tooltip>
              )}
              {!readOnly && (
                <Tooltip title="удалить" placement="top-start">
                  <IconButton className={classes.btn} color="secondary" onClick={handleRemoveParentEvent}>
                    <DeleteIcon className={classes.icon} />
                  </IconButton>
                </Tooltip>
              )}
              {dChanges?.includes('parent') && <EditLocationIcon color="secondary" />}
            </Grid>
          ) : (
            <Grid item container alignItems="center">
              <span className={classes.title}>Основное мероприятие:&nbsp;</span>

              {!readOnly && (
                <Tooltip title="добавить" placement="top-start">
                  <IconButton className={classes.btn} color="primary" onClick={() => handleClickOpen('parentDialog')}>
                    <AddBoxIcon className={classes.icon} />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          )}

          <Grid item container alignItems="center">
            <Grid item>
              <span className={classes.title}>Зависимые мероприятия:&nbsp;</span>
            </Grid>

            {!readOnly && (
              <Grid item>
                <Tooltip title="добавить" placement="top-start">
                  <IconButton className={classes.btn} color="primary" onClick={() => handleClickOpen('childrenDialog')}>
                    <AddBoxIcon className={classes.icon} />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
            {dChanges?.includes('children') && (
              <Grid item>
                <EditLocationIcon color="secondary" />
              </Grid>
            )}
          </Grid>

          {children?.map((child: any) => {
            const valuesComma = [
              child.registryNumber,
              `${moment(child.startDate).format('DD.MM.YYYY')}-${moment(child.endDate).format('DD.MM.YYYY')}`,
            ];
            child?.sports?.forEach((sport: any) => {
              valuesComma.push(sport?.dirSport?.label);

              sport?.disciplineGroups?.forEach((item: any) => {
                valuesComma.push(item.label);
              });
            });
            return (
              <Grid key={child.value} item xs={12}>
                <Link target="_blank" href={`/sporting_event/${child?.value}`}>
                  {child.label}
                </Link>
                &nbsp;
                <span className={classes.label} style={{ margin: '0 1rem' }}>
                  {valuesComma.join(', ')}
                </span>
                &nbsp;
                {!readOnly && 1}
              </Grid>
            );
          })}
        </Grid>
        <Grid container justify="space-between" alignItems="flex-end" className={classes.verticalSpace}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <Grid item container alignItems="flex-end" className={classes.verticalSpace}>
              <Grid item xs>
                <Input
                  label="Комментарий"
                  control={control}
                  error={!!errors['comment']}
                  name="comment"
                  rules={{ required: true }}
                  disabled={readOnly}
                />
              </Grid>
              {dChanges?.includes('comment') && (
                <Grid item>
                  <EditLocationIcon color="secondary" />
                </Grid>
              )}
            </Grid>
            <Grid item container justify="flex-end">
              <Button
                variant="outlined"
                color="primary"
                size="small"
                type="submit"
                startIcon={<SaveIcon />}
                disabled={!formState.isDirty}
              >
                Сохранить
              </Button>
            </Grid>
          </form>
        </Grid>
      </Paper>
      <Dialog
        open={open === 'parentDialog'}
        onClose={handleClose}
        aria-labelledby="parent-dialog-title"
        aria-describedby="parent-dialog-description"
      >
        <DialogTitle id="parent-dialog-title">Выбирать Мероприятие</DialogTitle>
        <DialogContent style={{ padding: '1rem' }}>
          <form onSubmit={parentFormHandleSubmit(handleUpdateParentEvent)} style={{ width: '40ch' }}>
            <SportEvents
              label="мероприятие"
              name="dirSportingEvent"
              filter={{ id_not_in: besideArray }}
              control={parentFormControl}
              error={!!parentFormError['dirSportingEvent']}
              rules={{ required: true }}
            />
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              style={{ width: '100%', marginTop: '1rem' }}
              disabled={!parentFormState.isDirty}
            >
              Сохранить
            </Button>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog
        open={open === 'childrenDialog'}
        onClose={handleClose}
        aria-labelledby="children-dialog-title"
        aria-describedby="children-dialog-description"
      >
        <DialogTitle id="children-dialog-title">Выбирать Мероприятие</DialogTitle>
        <DialogContent style={{ padding: '1rem' }}>
          <form onSubmit={childFormHandleSubmit(handleAddChildEvent)} style={{ width: '40ch' }}>
            <SportEvents
              label="мероприятие"
              name="dirSportingEvent"
              filter={{ id_not_in: besideArray }}
              control={childFormControl}
              error={!!childFormError['dirSportingEvent']}
              rules={{ required: true }}
            />
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              style={{ width: '100%', marginTop: '1rem' }}
              disabled={!childFormState.isDirty}
            >
              Сохранить
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default Structure;
