import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import { Countries, GenderSelect, GroupDisciplines, RanksAndTitle, StagePreparing } from '../../module';
import { ControllerProps } from 'react-hook-form/dist/types/props';
import { DatePickerRange, Input, Select } from '../Inputs';
import Organizations from '../../module/Organizations';
import Regions from '../../module/Regions';
import StatusConfirm from '../../module/StatusConfirm';
import PersonsModule from '../../module/Persons';
import ForeignCities from '../../module/ForeignCities';
import { FieldError } from 'react-hook-form';
import TypeSportsSelect from '../../module/TypeSportsSelect';

interface AthletesAdditionalProps extends Pick<ControllerProps<'input'>, 'control'> {
  errors: { [key: string]: FieldError };
  watch: any;
}

const AthletesAdditional: FC<AthletesAdditionalProps> = ({ watch, errors, control }) => {
  const watchIsRussia: { label: string; value: string } | undefined = watch('isRussia');
  const watchCountry: { label: string; value: string } | undefined = watch('dirCountry');
  const watchDirSport: { label: string; value: string } | undefined = watch('dirSport');
  return (
    <>
      <Grid item xs={3}>
        <Input
          label="Предыдущая фамилия"
          control={control}
          error={!!errors['previousLastname']}
          name="previousLastname"
        />
      </Grid>
      <Grid item xs={4}>
        <DatePickerRange
          start={{ label: 'Дата рождения с', control, error: !!errors['birthday_gte'], name: 'birthday_gte' }}
          end={{ label: 'по', control, error: !!errors['birthday_lte'], name: 'birthday_lte' }}
        />
      </Grid>
      <Grid item xs={2}>
        <GenderSelect label="Пол" control={control} error={!!errors['isMale']} name="isMale" />
      </Grid>
      <Grid item xs={3}>
        <Input label="Email" control={control} error={!!errors['email']} name="email" />
      </Grid>
      <Grid item xs={3}>
        <Select
          label="Россия/Зарубеж"
          data={[
            { value: '', label: ' ' },
            { value: true, label: 'Россия' },
            { value: false, label: 'Зарубеж' },
          ]}
          control={control}
          error={!!errors['isRussia']}
          name="isRussia"
        />
      </Grid>
      {watchIsRussia?.value && (
        <Grid item xs={4}>
          <Regions label="Регион РФ" control={control} error={!!errors['dirRegion']} name="dirRegion" />
        </Grid>
      )}
      {watchIsRussia && !watchIsRussia?.value && (
        <>
          <Grid item xs={4}>
            <Countries label="Название страны" control={control} error={!!errors['dirCountry']} name="dirCountry" />
          </Grid>
          {watchCountry?.value && (
            <Grid item xs={4}>
              <ForeignCities
                label="Название города"
                control={control}
                filter={{ dirCountry: { id: watchCountry?.value } }}
                error={!!errors['dirForeignCity']}
                name="dirForeignCity"
              />
            </Grid>
          )}
        </>
      )}
      <Grid item xs={3}>
        <StatusConfirm
          control={control}
          label="Статус персоны"
          error={!!errors['clsStatusPerson']}
          name="clsStatusPerson"
        />
      </Grid>
      <Grid item xs={3}>
        <Select
          label="В архиве"
          data={[
            { value: '', label: ' ' },
            { value: false, label: 'Нет' },
            { value: true, label: 'Да' },
          ]}
          control={control}
          error={!!errors['archive']}
          name="archive"
          defaultValue={{ value: false, label: 'Нет' }}
        />
      </Grid>
      <Grid item xs={3}>
        <Select
          label="Является дубликатом"
          data={[
            { value: '', label: ' ' },
            { value: false, label: 'Нет' },
            { value: true, label: 'Да' },
          ]}
          control={control}
          error={!!errors['isDuplicated']}
          name="isDuplicated"
        />
      </Grid>
      <Grid item xs={3}>
        <TypeSportsSelect control={control} label="Вид спорта" error={!!errors['dirSport']} name="dirSport" />
      </Grid>
      {watchDirSport?.value && (
        <Grid item xs={3}>
          <GroupDisciplines
            control={control}
            filter={{ dirSport: { id: watchDirSport?.value } }}
            label="Группы дисциплин"
            error={!!errors['disciplineGroups']}
            name="disciplineGroups"
          />
        </Grid>
      )}

      <Grid item xs={3}>
        <RanksAndTitle
          control={control}
          label="Разряды и звания"
          error={!!errors['clsRanksAndAwards']}
          name="clsRanksAndAwards"
        />
      </Grid>
      <Grid item xs={4}>
        <PersonsModule
          label="Тренер"
          error={!!errors['trainer']}
          name="trainer"
          control={control}
          getterValue={(val: any) => {
            return val?.trainerProfile?.id;
          }}
          filter={{
            trainerProfile: {
              id_not: null,
            },
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <Organizations
          control={control}
          label="Организация"
          error={!!errors['dirOrganization']}
          name="dirOrganization"
        />
      </Grid>
      <Grid item xs={3}>
        <Select
          label="Самоподготовка"
          data={[
            { value: '', label: ' ' },
            { value: false, label: 'Нет' },
            { value: true, label: 'Да' },
          ]}
          control={control}
          error={!!errors['isSelfTraining']}
          name="isSelfTraining"
        />
      </Grid>
      <Grid item xs={3}>
        <StagePreparing
          control={control}
          label="Этапы подготовки"
          error={!!errors['clsStagePreparing']}
          name="clsStagePreparing"
        />
      </Grid>
      <Grid item xs={3}>
        <Input label="GUID мед.допуска" control={control} error={!!errors['guid']} name="guid" />
      </Grid>
      <Grid item xs={3}>
        <Input label="Номер допуска" control={control} error={!!errors['displayNumber']} name="displayNumber" />
      </Grid>
      <Grid item xs={5}>
        <DatePickerRange
          start={{
            label: 'Срок окончания допуска с',
            control,
            error: !!errors['validUntil_gte'],
            name: 'validUntil_gte',
          }}
          end={{ label: 'по', control, error: !!errors['validUntil_lte'], name: 'validUntil_lte' }}
        />
      </Grid>
      <Grid item xs={3}>
        <Select
          label="Допущен"
          data={[
            { value: '', label: ' ' },
            { value: false, label: 'Нет' },
            { value: true, label: 'Да' },
          ]}
          control={control}
          error={!!errors['isValid']}
          name="isValid"
        />
      </Grid>
    </>
  );
};

export default AthletesAdditional;
