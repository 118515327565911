import _ from 'lodash';

export const groupProgramTypesAndNationalTeams = (types: any, teams: any) =>
  _.map(types, (type) => ({
    ...type,
    teamsArray: _.map(teams, (team) => ({
      dirRegion: team.dirRegion,
      athleteGroups: _.map(team.athleteGroups, (group) => ({
        athletesArray: _.filter(
          group.athletes,
          (athlete) =>
            _.filter(
              athlete.dirAthlete.competetionResult,
              (result) =>
                result.sportingEventProgramType.id === type.id &&
                _.filter(
                  result.sportingEventProgramType.clsAgeGroups,
                  (ageGroup) => type.clsAgeGroups && ageGroup.value === type.clsAgeGroups[0].value,
                ).length,
            ).length,
        ).sort((a: any, b: any) => a.dirAthlete.competetionResult[0].point - b.dirAthlete.competetionResult[0].point),
      })),
    })),
  }));
