import { INIT_TABLE_SETTINGS, SET_USER_SETTINGS_COLUMN_VISIBLE, UPDATE_ORDER_COLUMN } from '../../store/constants';
import MUIDataTable, { MUIDataTableColumn, MUIDataTableColumnDef } from 'mui-datatables';
import React, { FC, memo, useEffect, useState, useRef, useLayoutEffect, useCallback } from 'react';
import { getSettingsUserColumnHidden, getSettingsUserColumnOrder, getTableState } from '../../store/selectors/settings';
import { useDispatch, useSelector } from 'react-redux';

import { AppStore } from '../../store/reducers';
import TABLE_OPTIONS from '../../config/tables';
import _ from 'lodash';
import Progress from './Progress';
import { TDirectory } from './services';
import { getRidOfDuplicates } from './services/functions/getRidOfDuplicates';
import { sortDataOut } from './services/functions/sortDataOut';
import { setQueryOptions } from './services/functions/setQueryOptions';
import { arePropsFilterEqual } from './services/functions/arePropsFilterEqual';
import { filterKey } from '../../utils/filterKey';
import { Button } from '@material-ui/core';
import LoadingWall from '../LoadingWall/LoadingWall';

export type TDataTable = {
  title: React.ReactElement;
  columns: MUIDataTableColumn[];
  tableName: string;
  lazyQuery: any;
  revealFunctionName?: TDirectory;
  filter?: any;
  isEvent?: boolean;
  hasDoubleQuery?: boolean;
};

export const TableComponent: FC<TDataTable> = ({
  tableName,
  title,
  columns,
  filter,
  lazyQuery,
  revealFunctionName,
  isEvent,
  hasDoubleQuery,
}) => {
  const table = useRef();
  const [tableColumns, setTableColumns] = useState<MUIDataTableColumnDef[] | []>(columns || []);
  const [items, setItems] = useState<any[]>([]);
  const [isBottom, setIsBottom] = useState(false);
  const [page, setPage] = useState(1);
  const [orderBy, setOrderBy] = useState(null);
  const [canFetchMore, setCanFetchMore] = useState(false);

  const stateTable = useSelector((state: AppStore) => getTableState(state, tableName));
  const columnVisible = useSelector((state: AppStore) => getSettingsUserColumnHidden(state, tableName), _.isEqual);
  const columnOrder = useSelector((state: AppStore) => getSettingsUserColumnOrder(state, tableName), _.isEqual);

  const dispatch = useDispatch();

  const [fetch, { loading, data, error }] = lazyQuery(
    setQueryOptions(
      {
        skip: items.length,
        first: page === 1 ? (isEvent ? 10 : 20) : 10,
        orderBy: orderBy,
      },
      filter,
      isEvent,
      hasDoubleQuery,
    ),
  );

  console.log(data);
  console.log('FileName: NewTable.tsx - 69 line');

  useEffect(() => {
    if (!stateTable) {
      dispatch({ type: INIT_TABLE_SETTINGS, payload: { name: tableName } });
    }
  }, [stateTable]);

  const onFetch = useCallback(
    (calledOrderBy?: boolean) => {
      if (canFetchMore) {
        fetch(
          setQueryOptions(
            {
              skip: calledOrderBy ? 0 : items.length,
              first: page === 1 ? (isEvent ? 10 : 20) : 10,
              orderBy: orderBy,
            },
            filter,
            isEvent,
            hasDoubleQuery,
          ),
        );
      } else {
        setItems([]);
        fetch(
          setQueryOptions(
            {
              skip: 0,
              first: page === 1 ? (isEvent ? 10 : 20) : 10,
              orderBy: orderBy,
            },
            filter,
            isEvent,
            hasDoubleQuery,
          ),
        );
      }
    },
    [page, canFetchMore, orderBy],
  );

  const onScroll = (e: React.UIEvent<HTMLElement>) => {
    e.stopPropagation();
    if (loading) return;

    setIsBottom(
      () =>
        _.get(e, 'currentTarget.scrollHeight', 0) -
          _.get(e, 'currentTarget.scrollTop', 0) -
          _.get(e, 'currentTarget.clientHeight', 0) <
        1,
    );
  };

  const initializeScroll = () => {
    const tableBodyWrapper = _.get(table, 'current.tableContent.current.children[2]', null);
    tableBodyWrapper && tableBodyWrapper.addEventListener('scroll', onScroll);
  };

  useEffect(() => {
    setTableColumns([
      ...columns.reduce((acc: MUIDataTableColumn[], item: MUIDataTableColumn) => {
        acc.push({
          ...item,
          options: {
            ...item.options,
            display: columnVisible?.[item.name] === undefined ? item.options?.display : columnVisible?.[item.name],
          },
        });
        return acc;
      }, []),
    ]);
  }, [columnVisible]);

  useEffect(() => {
    if (isBottom && canFetchMore) {
      setPage((currentState) => currentState + 1);
    }
  }, [isBottom]);

  useEffect(() => {
    page > 1 && onFetch(false);
  }, [page]);

  useEffect(() => {
    if (orderBy) {
      setItems([]);
      onFetch(true);
    }
    // orderBy && onFetch();
  }, [orderBy]);

  // useLayoutEffect(() => {
  //   if (table && table.current) {
  //     initializeScroll();
  //   }
  // }, [table]);

  useEffect(() => {
    if (!loading && data) {
      const length = _.get(data, _.keys(data)[0], []).length;
      if (length > 0) {
        setItems((currentState) => currentState.concat(sortDataOut(data, filter, revealFunctionName)));
        const currentOffset = page === 1 ? (isEvent ? 10 : 20) : 10;
        if (!(length < currentOffset)) {
          setCanFetchMore(true);
        }
      } else {
        setCanFetchMore(() => false);
      }
    }
  }, [loading, data]);

  useEffect(() => {
    items.length === 0 && onFetch(false);
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, height: isEvent ? '750px' : '100%' }}>
      {loading && <LoadingWall />}
      <MUIDataTable
        title={title}
        data={getRidOfDuplicates(items)}
        columns={tableColumns}
        innerRef={table}
        options={
          {
            ...TABLE_OPTIONS,

            draggableColumns: {
              enabled: true,
            },
            columnOrder: columnOrder,
            onTableChange: (event: any, { columnOrder }: any) => {
              if (event === 'columnOrderChange') {
                dispatch({
                  type: UPDATE_ORDER_COLUMN,
                  payload: {
                    name: tableName,
                    order: columnOrder,
                  },
                });
              }
            },
            onViewColumnsChange: (changedColumn: any, action: any) => {
              dispatch({
                type: SET_USER_SETTINGS_COLUMN_VISIBLE,
                payload: {
                  name: tableName,
                  columnName: changedColumn,
                  status: action,
                },
              });
            },
            onColumnSortChange: (changedColumn: string, direction: string) => {
              setOrderBy(changedColumn + '_' + direction.toUpperCase());
            },
          } as any
        }
      />
      {canFetchMore && (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: 10 }}>
          <Button color="primary" variant="contained" onClick={() => setPage((currentState) => currentState + 1)}>
            Загрузить еще
          </Button>
        </div>
      )}
    </div>
  );
};

export const DataTableWithKeygen = (props: TDataTable) => <TableComponent {...props} key={filterKey(props.filter)} />;

export const DataTable = memo(DataTableWithKeygen, arePropsFilterEqual);
