import { Grid, Link as MuiLink, Tooltip } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import React, { FC, useCallback, useState } from 'react';
import { getSettingsUserFilter, getSettingsUserFilterFormValue } from '../../store/selectors/settings';
import { useDispatch, useSelector } from 'react-redux';
import { useSportingEvents2ndLazyQuery, useSportingEventsLazyQuery } from '../../api';
import { AppStore } from '../../store/reducers';
import { BtnNew } from '../../components/BtnNew';
import { DataTable } from '../../components/DataTable/NewTable';
import { DictionaryWrapper } from '../../components/DictionaryWrapper';
import { MUIDataTableColumn } from 'mui-datatables';
import { MainTemplate } from '../../components/layouts';
import { MinsportsFilter } from '../../components/Filters';
import { SET_USER_SETTINGS_FILTER } from '../../store/constants';
import _ from 'lodash';
import { usePermission } from '../../hooks/usePermission';
import userRights from '../../config/userRights';

const Page: FC<{}> = () => {
  const dispatch = useDispatch();
  const { hasAdditionalFilter } = userRights();
  const filter: any = useSelector((state: AppStore) => getSettingsUserFilter(state, 'minsports_table'), _.isEqual);
  const formValue: any = useSelector(
    (state: AppStore) => getSettingsUserFilterFormValue(state, 'minsports_table'),
    _.isEqual,
  );
  const { push } = useHistory();
  const [first, setFirst] = useState(0);
  const access = usePermission('directory', 'minsports');

  const columns: MUIDataTableColumn[] = [
    {
      name: 'info',
      label: 'Название мероприятия',
      options: {
        filter: false,
        display: true,
        viewColumns: false,
        sort: false,
        customBodyRender: (info: any, tableMeta: any) => {
          const { rowData } = tableMeta;
          const name = rowData[2] || '';
          const { id } = info || {};
          return (
            <React.Fragment>
              <Tooltip title="Перейти в карточку" placement="top-start">
                <Link
                  style={{
                    fontWeight: 500,
                    cursor: 'pointer',
                    textDecoration: 'none',
                  }}
                  to={`/minsports/${id}`}
                  target={'_blank'}
                >
                  {name}
                </Link>
              </Tooltip>
            </React.Fragment>
          );
        },
      },
    },
    {
      name: 'oldRegistryNumber',
      label: 'Старый реестровый номер',
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: 'name',
      label: 'Название мероприятия',
      options: {
        filter: false,
        display: false,
        sort: false,
      },
    },
    {
      name: 'venue',
      label: 'Место проведения',
      options: {
        filter: false,
        display: true,
        sort: false,
      },
    },
    {
      name: 'sportsString',
      label: 'Вид спорта',
      options: {
        filter: false,
        display: true,

        sort: false,
      },
    },
    {
      name: 'disciplinesString',
      label: 'Дисциплины',
      options: {
        filter: false,
        display: true,

        sort: false,
      },
    },
    {
      name: 'ageGroups',
      label: 'Пол / возраст',
      options: {
        filter: false,
        display: true,
        sort: false,
      },
    },
    {
      name: 'organizers',
      label: 'Организатор',
      options: {
        filter: false,
        display: true,
        sort: false,
        customBodyRender: (organizers) => (
          <React.Fragment>
            <Grid container spacing={1}>
              {organizers?.map((organizer: any, index: number) => (
                <Grid item key={organizer?.value}>
                  <MuiLink href={`/organization/${organizer?.value}`} target={'_blank'}>
                    {organizer?.label}
                  </MuiLink>
                  {index < organizers.length - 1 && `, `}
                </Grid>
              ))}
            </Grid>
          </React.Fragment>
        ),
      },
    },
    {
      name: 'period',
      label: 'период проведения',
      options: {
        filter: false,
        display: true,

        sort: false,
      },
    },
    {
      name: 'dirCalendar',
      label: 'Календарь',
      options: {
        filter: false,
        display: true,
        sort: false,
        customBodyRender: (dirCalendar) => <React.Fragment>{dirCalendar}</React.Fragment>,
      },
    },
    {
      name: 'status',
      label: 'Статус мероприятия',
      options: {
        filter: false,
        display: true,

        sort: false,
      },
    },
    {
      name: 'request',
      label: 'Статус заявки',
      options: {
        filter: false,
        display: true,
        sort: false,
      },
    },
    {
      name: 'info',
      label: 'Реестровый номер',
      options: {
        filter: false,
        sort: false,
        display: true,
        customBodyRender: (value) => value?.registryNumber,
      },
    },
  ];

  const setFilter = useCallback(
    (filter, form) => {
      dispatch({
        type: SET_USER_SETTINGS_FILTER,
        payload: {
          name: 'minsports_table',
          filter: filter,
          form,
        },
      });
    },
    [dispatch],
  );

  return (
    <MainTemplate pageName="ЕКП Минспорт" url="" title="ЕКП Минспорт">
      <MinsportsFilter
        onSubmit={(filters: any, form: any) => setFilter({ ...filters, proxy: null }, form)}
        first={first}
        defaultFilter={{
          AND: [{ dirCalendar: { type: { id: 1 } } }, { ...hasAdditionalFilter?.minsports }],
        }}
        value={formValue}
      />
      <DictionaryWrapper>
        <DataTable
          tableName="minsports_table"
          title={
            <React.Fragment>
              <Grid container spacing={2} alignItems={'center'}>
                <Grid item>
                  <h2>ЕКП Минспорт</h2>
                </Grid>
                {access.includes(2) && <Grid item>{<BtnNew onClick={() => push('/minsports/new')} />}</Grid>}
              </Grid>
            </React.Fragment>
          }
          isEvent
          columns={columns}
          lazyQuery={filter?.name_contains ? useSportingEvents2ndLazyQuery : useSportingEventsLazyQuery}
          filter={{ ...filter, AND: [{ dirCalendar: { type: { id: 1 } } }, { ...hasAdditionalFilter?.minsports }] }}
          revealFunctionName={filter?.name_contains ? 'minSportMultiple' : 'minSport'}
        />
      </DictionaryWrapper>
    </MainTemplate>
  );
};

export default Page;
