import { AgeGroups, Countries, GroupDisciplines, Levels, SportCategories, TeamStatus, TypeSports } from '../../module';
import { Box, Button, Grid } from '@material-ui/core';
import { DatePickerRange, Input, Select } from '../Inputs';
import React, { FC, useEffect, useState } from 'react';
import {
  useAthletesCountLazyQuery,
  useGenerateSportingEventsXlsFileLazyQuery,
  useSportingEventsCountLazyQuery,
} from '../../api';

import Calendars from '../../module/Calendars';
import ClearIcon from '@material-ui/icons/Clear';
import ContentWrapper from '../layouts/ContentWrapper/ContentWrapper';
import { Count } from '../Count';
import GetAppIcon from '@material-ui/icons/GetApp';
import { MinsportsFilterAdditional } from './index';
import SearchIcon from '@material-ui/icons/Search';
import TuneIcon from '@material-ui/icons/Tune';
import TypeSportsSelect from '../../module/TypeSportsSelect';
import _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { onSetMinsportFilter } from './helpers';
import { useForm } from 'react-hook-form';
import useGenerateXlsx from '../../hooks/useGenerateXlsx';

interface SportingEvent {
  registryNumber?: number;
  name_contains?: string;
  sports_some?: {
    dirSport?: {
      id: string;
    };
  };
}

interface Filter {
  registryNumber?: number;
  oldRegistryNumber?: number;
  sports_some?: {
    dirSport?: {
      id: string;
    };
    event?: {
      id: string;
    };
  };
  name_contains?: string;
  venue_contains?: string;
  isImportant?: boolean;
  isOlympicGamesPreparation?: boolean;
  federalBudgetFinancing?: boolean;
  dirSportingEvent: SportingEvent;
  status: {
    id?: string;
  };
  dirRegions_some?: {
    id?: string;
  };
  dirCountry?: {
    id?: string;
  };
  dirForeignCity?: {
    id?: string;
  };
  dirCalendar?: {
    id?: string;
  };
  organizers_some?: {
    id?: string;
  };
  startDate_gt?: Date;
  endDate_lt?: Date;
  updatedAt_gt?: Date;
  updatedAt_lt?: Date;
  requests?: {
    statuses?: {
      id: string;
    };
  };
  requests_some?: {
    statuses_some?: {
      isActive: boolean;
      value?: {
        id: string;
      };
    };
  };
  NOT?: any;
  archive?: null;
  ageGroups_some?: any;
}
interface Value {
  registryNumber?: number;
  oldRegistryNumber?: number;
  venue?: string;
  dirRegion?: {
    label: string;
    value: string;
  };
  dirCountry?: {
    label: string;
    value: string;
  };
  dirForeignCity?: {
    label: string;
    value: string;
  };
  dirCalendar?: {
    label: string;
    value: string;
  };
  dirOrganization?: {
    label: string;
    value: string;
  };
  sportType?: {
    label: string;
    value: string;
  };
  sportStatusEkp?: {
    label: string;
    value: string;
  };
  activityName?: string;
  isImportant: boolean;
  isOlympicGamesPreparation: boolean;
  federalBudgetFinancing: boolean;
  activityRegistryNumber?: number;
  status?: {
    label: string;
    value: string;
  };
  startDate?: Date;
  endDate?: Date;
  startChangeDate?: Date;
  endChangeDate?: Date;
  request?: {
    label: string;
    value: string;
  };
  archive?: {
    label: string;
    value: boolean | string;
  };
  structure?: { label: string; value: string };
}

const useStyle = makeStyles((theme) => ({
  btn: {
    marginRight: theme.spacing(2),
  },
}));

const Minsports: FC<{
  onSubmit: (T: any, E: any) => void;
  value?: any;
  first: number;
  defaultFilter?: any;
}> = ({ onSubmit, value = {}, defaultFilter = {}, first }) => {
  const style = useStyle();
  const [additional, setAdditional] = useState<boolean>(false);
  const [filter, setFilter] = useState<any>(defaultFilter);
  const { handleSubmit, control, errors, reset, watch, formState, setValue, getValues } = useForm({
    defaultValues: value,
  });
  const watchIsRussia: { label: string; value: string } | undefined = watch('isRussia');

  const { onDownloadFile } = useGenerateXlsx({
    lazyQuery: useGenerateSportingEventsXlsFileLazyQuery,
    getValues,
    setFilter: onSetMinsportFilter,
    fileName: 'ekp_events',
    first,
  });
  const handleSearchBox = (value: Value) => {
    const filters: Filter = onSetMinsportFilter(value);
    setFilter(filters);
    onSubmit(filters, getValues());
  };

  return (
    <ContentWrapper>
      <form onSubmit={handleSubmit(handleSearchBox)}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <Input label="Название" control={control} error={!!errors['activityName']} name="activityName" />
          </Grid>
          <Grid item xs={3}>
            <Calendars
              label="Календарь"
              error={!!errors['dirCalendar']}
              control={control}
              name="dirCalendar"
              notInitialized
              isEkp
            />
          </Grid>
          <Grid item xs={3}>
            <TypeSportsSelect label="Вид спорта" error={!!errors['sportType']} control={control} name="sportType" />
          </Grid>
          <Grid item xs={3}>
            <SportCategories
              label="Статус вида спорта"
              error={!!errors['activityName']}
              control={control}
              name="sportStatusEkp"
            />
          </Grid>
          {additional && <MinsportsFilterAdditional watch={watch} control={control} errors={errors} />}

          <Grid item container alignItems={'center'}>
            <Button
              variant="outlined"
              className={style.btn}
              color="primary"
              type="button"
              onClick={() => {
                onSubmit(
                  { archive: null },
                  { isImportant: false, isOlympicGamesPreparation: false, federalBudgetFinancing: false },
                );
                reset({ isImportant: false, isOlympicGamesPreparation: false, federalBudgetFinancing: false });
                setFilter({});
              }}
              size="small"
              startIcon={<ClearIcon />}
            >
              Сброс
            </Button>
            <Button
              variant="outlined"
              color="primary"
              type="submit"
              size="small"
              className={style.btn}
              startIcon={<SearchIcon />}
              disabled={!formState.isDirty}
            >
              Поиск
            </Button>
            <Button
              onClick={() => setAdditional(!additional)}
              type="button"
              className={style.btn}
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<TuneIcon />}
            >
              Фильтр
            </Button>
            <Box pl={4}>
              <Button
                variant="outlined"
                color="primary"
                disabled={!formState.isDirty}
                onClick={onDownloadFile}
                size={'small'}
                startIcon={<GetAppIcon />}
              >
                XLS
              </Button>
              <Count key={JSON.stringify(filter)} query={useSportingEventsCountLazyQuery} filter={filter} />
            </Box>
          </Grid>
        </Grid>
      </form>
    </ContentWrapper>
  );
};

export default Minsports;
