import React, { FC, useState } from 'react';
import {
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import PersonIcon from '@material-ui/icons/Person';
import { Link } from 'react-router-dom';
import moment from 'moment';
import TimelineRoundedIcon from '@material-ui/icons/TimelineRounded';
import SportsHandballRoundedIcon from '@material-ui/icons/SportsHandballRounded';
import GavelIcon from '@material-ui/icons/Gavel';
import { useOpenMergeTaskMutation, useFindSimiliarPersonQuery, OpenMergeTaskMutationHookResult } from '../../api';
import { useSnackbar } from 'notistack';
import getMessage from '../../messages';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 15,
  },
}))(TableCell);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const UniBox: FC<{ fio: string; idPerson: string }> = ({ fio, idPerson }) => {
  const classes = useStyles();
  const [view, setView] = useState('btn');
  const { data, loading, refetch } = useFindSimiliarPersonQuery({
    skip: view === 'btn',
    variables: { search: fio, filter: { archive: null } },
    fetchPolicy: 'no-cache',
  });
  const { enqueueSnackbar } = useSnackbar();

  const [openMergeTask]: OpenMergeTaskMutationHookResult = useOpenMergeTaskMutation();

  const handleOpenMergeTask = async (primaryId: string, duplicateId: string) => {
    try {
      await openMergeTask({
        variables: {
          primaryId,
          duplicateId,
        },
      });
      enqueueSnackbar(`Задание успешно создано`, { variant: 'success' });
      setView('btn');
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  if (view === 'btn')
    return (
      <Button onClick={() => setView('popup')} color={'primary'} variant={'outlined'} size={'small'}>
        поиск дубликатов
      </Button>
    );

  return (
    <Dialog open={view === 'popup'} onClose={() => setView('btn')} maxWidth={'lg'}>
      <DialogTitle>
        <Typography style={{ fontSize: 18, fontWeight: 600, letterSpacing: 1.2 }}>
          <u>найденные варианты:</u>{' '}
          {data?.dirPersonsSimilar?.filter((element: any) => element?.person?.id !== idPerson).length}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Grid container justify={'center'} alignItems={'center'} style={{ width: 500, height: 500 }}>
            <Grid item>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          <Grid container>
            {data?.dirPersonsSimilar &&
              data?.dirPersonsSimilar.filter((element: any) => element?.person?.id !== idPerson).length > 0 && (
                <TableContainer component={Paper} style={{ maxHeight: '80vh' }}>
                  <Table className={classes.table} stickyHeader aria-label="similar-table">
                    <TableHead>
                      <TableRow>
                        <StyledTableCell>Реестровый номер</StyledTableCell>
                        <StyledTableCell>ФИО</StyledTableCell>
                        <StyledTableCell>ДР</StyledTableCell>
                        <StyledTableCell>Пол</StyledTableCell>
                        <StyledTableCell>Регион</StyledTableCell>
                        <StyledTableCell>Дата создания</StyledTableCell>
                        <StyledTableCell>Дата последнего обновления</StyledTableCell>
                        <StyledTableCell align={'center'}>Роли</StyledTableCell>
                        <StyledTableCell align={'center'}>Действия</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {_.sortBy(data?.dirPersonsSimilar, [
                        function (o) {
                          return !o.similarity;
                        },
                      ])
                        ?.filter((element: any) => element?.person?.id !== idPerson)
                        .map(({ person }: { person: any }) => (
                          <TableRow key={person.id}>
                            <StyledTableCell component="th" scope="row">
                              {person.registryNumber}
                            </StyledTableCell>
                            <StyledTableCell>{`${person.lastname} ${person.firstname} ${
                              person.patronymic ? person.patronymic : ''
                            }`}</StyledTableCell>
                            <StyledTableCell>
                              {' '}
                              {(person?.birthday && moment(person?.birthday).format('DD.MM.YYYY')) || ''}
                            </StyledTableCell>
                            <StyledTableCell>{person?.isMale ? 'Муж' : 'Жен'}</StyledTableCell>
                            <StyledTableCell>{`${
                              person?.dirRegion?.fullName || person?.dirForeignCity?.fullName || ''
                            }`}</StyledTableCell>
                            <StyledTableCell>
                              {' '}
                              {(person?.birthday && moment(person?.createdAt).format('DD.MM.YYYY')) || ''}
                            </StyledTableCell>
                            <StyledTableCell>
                              {' '}
                              {(person?.birthday && moment(person?.updatedAt).format('DD.MM.YYYY')) || ''}
                            </StyledTableCell>
                            <StyledTableCell>
                              <Grid container spacing={1}>
                                {person?.id && (
                                  <Grid item xs={12}>
                                    <Link
                                      style={{
                                        fontWeight: 500,
                                        cursor: 'pointer',
                                        textDecoration: 'none',
                                      }}
                                      target="_blank"
                                      to={`/person/${person.id}`}
                                    >
                                      <Button
                                        startIcon={<PersonIcon />}
                                        variant={'outlined'}
                                        color={'primary'}
                                        size={'small'}
                                        fullWidth
                                      >
                                        Персона
                                      </Button>
                                    </Link>
                                  </Grid>
                                )}
                                {person.trainerProfile?.id && (
                                  <Grid item xs={12}>
                                    <Link
                                      style={{
                                        fontWeight: 500,
                                        cursor: 'pointer',
                                        textDecoration: 'none',
                                      }}
                                      target="_blank"
                                      to={`/trainer/${person.trainerProfile?.id}`}
                                    >
                                      <Button
                                        startIcon={<TimelineRoundedIcon />}
                                        variant={'outlined'}
                                        color={'primary'}
                                        size={'small'}
                                        fullWidth
                                      >
                                        Тренер
                                      </Button>
                                    </Link>
                                  </Grid>
                                )}
                                {person.athleteProfile?.id && (
                                  <Grid item xs={12}>
                                    <Link
                                      style={{
                                        fontWeight: 500,
                                        cursor: 'pointer',
                                        textDecoration: 'none',
                                      }}
                                      target="_blank"
                                      to={`/athlete/${person.athleteProfile?.id}`}
                                    >
                                      <Button
                                        startIcon={<SportsHandballRoundedIcon />}
                                        variant={'outlined'}
                                        color={'primary'}
                                        size={'small'}
                                        fullWidth
                                      >
                                        Атлет
                                      </Button>
                                    </Link>
                                  </Grid>
                                )}
                                {person.refereeProfile?.id && (
                                  <Grid item xs={12}>
                                    <Link
                                      style={{
                                        fontWeight: 500,
                                        cursor: 'pointer',
                                        textDecoration: 'none',
                                      }}
                                      target="_blank"
                                      to={`/referee/${person.refereeProfile?.id}`}
                                    >
                                      <Button
                                        startIcon={<GavelIcon />}
                                        variant={'outlined'}
                                        color={'primary'}
                                        size={'small'}
                                        fullWidth
                                      >
                                        Судья
                                      </Button>
                                    </Link>
                                  </Grid>
                                )}
                              </Grid>
                            </StyledTableCell>
                            <StyledTableCell>
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  {' '}
                                  <Button
                                    variant={'outlined'}
                                    color={'primary'}
                                    size={'small'}
                                    fullWidth
                                    onClick={() => handleOpenMergeTask(idPerson, person.id)}
                                  >
                                    Это дубликат, объединить
                                  </Button>
                                </Grid>
                                <Grid item xs={12}>
                                  <Button
                                    variant={'outlined'}
                                    color={'primary'}
                                    size={'small'}
                                    fullWidth
                                    onClick={() => handleOpenMergeTask(person.id, idPerson)}
                                  >
                                    Это основная Персона, объединить
                                  </Button>
                                </Grid>
                              </Grid>
                            </StyledTableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
          </Grid>
        )}
      </DialogContent>
      <DialogActions style={{ paddingRight: 22 }}>
        <Button size={'small'} color={'primary'} variant={'outlined'} onClick={() => setView('btn')}>
          Завершить
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UniBox;
