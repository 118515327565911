import React from 'react';
import { Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

export default ({
  isLoading,
  children,
  maxHeight,
}: {
  isLoading: boolean;
  children: React.ReactNode;
  maxHeight?: number;
}) => {
  if (isLoading)
    return (
      <Grid container justify="center" alignItems="center">
        <CircularProgress />;
      </Grid>
    );
  else
    return (
      <div style={(maxHeight && { maxHeight: maxHeight, overflowY: 'auto', scrollBehavior: 'smooth' }) || {}}>
        {children}
      </div>
    );
};
