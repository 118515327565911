/*
1 - Read
2 - Edit
3 - Archive
 */

import { UserRole } from '../types/types';
import userRights from './userRights';

const { regionalSchoolProfileHasEkp } = userRights();

const role: UserRole = {
  ADMIN: {
    users: {
      all: [1, 2, 3, 4, 5],
    },
    classifiers: {
      all: [1, 2, 3, 4, 5],
    },
    directory: {
      all: [1, 2, 3, 4, 5, 'passport', 'bank'],
    },
  },
  REGIONAL_ADMINISTRATOR: {
    users: {
      all: [1, 2, 3, 4, 5],
    },
    directory: {
      athletes: [1, 2, 3, 5],
      trainers: [1, 2, 3, 5],
      organizations: [1, 2, 3, 5],
      type_sports: [1],
      calendars: [1],
      sporting_events: [1],
      national_teams: [1, 2, 3, 5],
      referees: [1, 2, 3, 5],
    },
  },
  CLASSIFIER_OPERATOR: {
    classifiers: {
      all: [1, 2, 3, 5],
    },
  },
  DIRECTORY_OPERATOR: {
    directory: {
      athletes: [1, 2, 3, 5],
      trainers: [1, 2, 3, 5],
      person: [1, 2, 3, 5],
      organizations: [1, 2, 3, 5],
      type_sports: [1, 2, 3, 5],
    },
  },
  MINSPORT: {
    directory: {
      athletes: [1],
      trainers: [1],
      minsports: [1, 2, 3, 4, 5],
    },
  },
  REGIONAL_MINSPORT: {
    directory: {
      athletes: [1],
      trainers: [1],
      person: [1],
      organizations: [1],
    },
  },
  REGIONAL_SCHOOL: {
    directory: {
      athletes: [1, 2, 3, 4, 5],
      trainers: [1, 2, 3, 4, 5],
      organizations: [1, 2, 3, 4, 5],
      calendars: [1],
      sporting_events: [1],
      ...(regionalSchoolProfileHasEkp && { minsports: [1, 2, 3, 4, 5] }),
    },
  },
  PERSON: {
    directory: {
      athletes: [1],
    },
  },
  FCPSR_ADMINISTRATOR: {
    users: {
      all: [1, 2, 3, 4, 5],
    },
    directory: {
      person: [1, 2, 3, 5],
      classifiers: [1, 2, 3, 4, 5],
      athletes: [1, 2, 3, 5],
      trainers: [1, 2, 3, 5],
      organizations: [1, 2, 3, 5],
      type_sports: [1, 2, 3, 5],
      region: [1, 2, 3, 5],
      calendars: [1, 2, 3, 5],
      referees: [1, 2, 3, 5, 'passport', 'bank'],
      documents: [1, 2, 3, 5],
      sporting_events: [1, 2, 3, 4, 5],
      national_teams: [1, 2, 3, 4, 5],
      unificationtasks: [1, 2, 3, 4, 5],
      foreign_cities: [1, 2, 3, 4, 5],
      federal_district: [1, 2, 3, 4, 5],
    },
  },
  FCPSR_SPECIALIST: {
    users: {
      all: [1],
    },
    classifiers: {
      all: [1],
    },
    directory: {
      person: [1, 2, 3, 5],
      athletes: [1, 2, 3, 5],
      trainers: [1, 2, 3, 5],
      organizations: [1, 2, 3, 5],
      type_sports: [1],
      region: [1],
      calendars: [1, 2, 3, 4, 5],
      referees: [1, 2, 3, 5, 'bank'],
      documents: [1, 2, 3, 5],
      sporting_events: [1, 2, 3, 4, 5],
      national_teams: [1, 2, 3, 4, 5],
      unificationtasks: [1],
      foreign_cities: [1],
      federal_district: [1],
    },
  },
  FCPSR_ROIV_SPECIALIST: {
    directory: {
      person: [1, 2, 3, 5],
      athletes: [1, 2, 3, 5],
      trainers: [1, 2, 3, 5],
      organizations: [1, 2, 3, 5],
      referees: [1],
      calendars: [1],
      sporting_events: [1],
      national_teams: [1, 2, 3, 4, 5],
    },
  },
  FCPSR_SPORTING_EVENT_OPERATOR: {
    users: {
      all: [1, 2, 3, 4, 5],
    },
    directory: {
      referees: [1, 2, 3, 5, 'passport', 'bank'],
      sporting_events: [1],
      national_teams: [1],
      calendars: [1],
    },
  },
  ROIV_SPECIALIST: {
    users: {
      all: [1, 2, 3, 4, 5],
    },
    directory: {
      athletes: [1],
      trainers: [1],
      organizations: [1, 2, 3, 5],
      type_sports: [1],
      region: [1],
      calendars: [1],
      referees: [1, 2, 3, 5],
      documents: [1],
      sporting_events: [1],
      national_teams: [1, 2, 3, 4, 5],
      minsports: [1],
    },
  },
  GSK_REFEREE: {
    athletes: [1],
    trainers: [1],
    referees: [1],
    sporting_events: [1, 2, 3, 4, 5],
  },
};

export default role;
