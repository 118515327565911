import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  AddAddressToOrganizationMutationHookResult,
  CreatePersonAddressMutationHookResult,
  DeleteAddressFromOrganizationMutationHookResult,
  DeletePersonAddressMutationHookResult,
  UpdateOrganizationAddressMutationHookResult,
  UpdatePersonAddressMutationHookResult,
  useAddAddressToOrganizationMutation,
  useCreatePersonAddressMutation,
  useDeleteAddressFromOrganizationMutation,
  useDeletePersonAddressMutation,
  useOrganizationAddressesQuery,
  usePersonAddressesQuery,
  useUpdateOrganizationAddressMutation,
  useUpdatePersonAddressMutation,
} from '../../../api';
import { MaskedInput } from '../../Inputs';
import React, { FC, useEffect, useMemo, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import AddressTypes from '../../../module/AddressTypes';
import AddressesModule from '../../../module/AddressesModule';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SaveIcon from '@material-ui/icons/Save';
import _ from 'lodash';
import getMessage from '../../../messages';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

interface formState {
  place?: string;
  street?: string;
  house?: string;
  // number?: string;
  address?: {
    label?: string;
    value?: {
      value: string | null;
      unrestrictedValue: string | null;
      postcode: string | null;
      country: string | null;
      region: string | null;
      city: string | null;
      area: string | null;
      settlement: string | null;
      street: string | null;
      house: string | null;
      number: string | null;
    };
  };
  type?: { value: Number; label: string };
}

const useStyles = makeStyles({
  addressAccordion: {
    margin: '15px 0',
    '&:before': {
      backgroundColor: 'unset',
    },
  },
  row: {
    display: 'flex',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  tinyIcon: {
    fontSize: 18,
  },
  icon: {
    fontSize: 20,
  },
  tableHead: {
    fontWeight: 'bold',
  },
  header: {
    fontWeight: 600,
    fontSize: 15,
    letterSpacing: 1.2,
  },
  container: {
    maxHeight: 440,
    marginTop: 10,
  },
});

const columns = [
  {
    id: '8e5a744d-793d',
    label: 'Город',
    name: 'place',
    rowId: '47816853-3aee',
  },
  {
    id: 'f5850721-96d5',
    label: 'Улица',
    name: 'street',
    rowId: '7af223a0-a7d8',
  },
  {
    id: '3eb9f440-c6b4',
    label: 'Дом',
    name: 'house',
    rowId: 'fac6dee2-a833',
  },
  // {
  //   id: '84035385-b6b4',
  //   label: 'Корпус',
  //   name: 'number',
  //   rowId: '473ddd9c-26b0',
  // },
  {
    id: '0a8eaa02-8d6b',
    label: 'Тип',
    name: 'type',
    rowId: '77dc578c-3059',
  },
];

const AddressBox: FC<{
  readonly?: boolean;
  id: string;
  person?: boolean;
  organization?: boolean;
  isAddressArray?: boolean;
}> = ({ readonly, id, person, organization, isAddressArray }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [mode, setMode] = useState('');
  const [deleteItem, setDeleteItem] = useState('');

  const { data: personAddress, refetch: refetchPersonAddress } = usePersonAddressesQuery({
    skip: !person,
    variables: {
      filter: {
        dirPerson: {
          id,
        },
      },
    },
  });
  const { data: orgAddress, refetch: refetchOrgAddress } = useOrganizationAddressesQuery({
    skip: !organization,
    variables: {
      filter: { dirOrganization: { id } },
    },
  });

  const [createPersonAddress]: CreatePersonAddressMutationHookResult = useCreatePersonAddressMutation();
  const [createOrgAddress]: AddAddressToOrganizationMutationHookResult = useAddAddressToOrganizationMutation();
  const [deletePersonAddress]: DeletePersonAddressMutationHookResult = useDeletePersonAddressMutation();
  const [
    deleteOrgAddress,
  ]: DeleteAddressFromOrganizationMutationHookResult = useDeleteAddressFromOrganizationMutation();
  const [updatePersonAddress]: UpdatePersonAddressMutationHookResult = useUpdatePersonAddressMutation();
  const [updateOrgAddress]: UpdateOrganizationAddressMutationHookResult = useUpdateOrganizationAddressMutation();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const defaultState: formState = {
    address: undefined,
  };

  const { handleSubmit, control, errors, reset, watch, setValue, formState } = useForm({
    defaultValues: defaultState,
  });

  const addressState = watch('address');

  const onSubmit = async (values: formState) => {
    const { address, place, street, house, type } = values;

    try {
      if (mode === 'add') {
        if (person) {
          await createPersonAddress({
            variables: {
              id,
              place: place || '',
              street: street || '',
              house: house || '',
              type: Number(type?.value),
            },
          });
        } else if (organization) {
          await createOrgAddress({
            variables: {
              id,
              place: place || '',
              street: street || '',
              house: house || '',
              type: Number(type?.value),
            },
          });
        }
        setMode('');
        reset();
        person && (await refetchPersonAddress());
        organization && (await refetchOrgAddress());
        handleSnackBar('success', 'адрес успешно добавлен');
      } else if (mode) {
        if (person) {
          await updatePersonAddress({
            variables: {
              id,
              addressId: mode,
              place: place || '',
              street: street || '',
              house: house || '',
              type: Number(type?.value),
            },
          });
        } else if (organization) {
          await updateOrgAddress({
            variables: {
              id,
              addressId: mode,
              place: place || '',
              street: street || '',
              house: house || '',
              type: Number(type?.value),
            },
          });
        }
        setMode('');
        reset();
        person && (await refetchPersonAddress());
        organization && (await refetchOrgAddress());
        handleSnackBar('success', 'адрес успешно обновлен');
      }
    } catch (e) {
      handleSnackBar('error', getMessage(e.message));
    }
  };

  const onDeleteAddress = async (addressId: any) => {
    try {
      if (person) {
        await deletePersonAddress({
          variables: {
            id,
            addressId,
          },
        });
      } else if (organization) {
        await deleteOrgAddress({
          variables: {
            id,
            addressId,
          },
        });
      }
      setDeleteItem('');
      person && (await refetchPersonAddress());
      organization && (await refetchOrgAddress());
      handleSnackBar('success', 'адрес успешно удален');
    } catch (e) {
      handleSnackBar('error', getMessage(e.message));
    }
  };

  const addressArray: any = useMemo(
    () => (person ? personAddress?.dirPersonAddresses : orgAddress?.dirOrganizationAddresses),
    [personAddress, orgAddress, person],
  );

  useEffect(() => {
    const state = addressState?.value;
    if (addressState) {
      setValue(
        'place',
        [state?.city || undefined, state?.area || undefined, state?.settlement || undefined].filter(Boolean).join(','),
        {
          shouldDirty: true,
        },
      );
      setValue('street', state?.street || undefined, { shouldDirty: true });
      setValue('house', [state?.number || '', state?.house || undefined].filter(Boolean).join(','), {
        shouldDirty: true,
      });
    }
  }, [addressState, setValue]);

  return (
    <Accordion className={classes.addressAccordion}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="adress-content" id="address-header">
        <Typography className={classes.header} color="primary">
          Адреса
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ display: 'block' }}>
        {mode ? (
          <form onSubmit={handleSubmit((values) => onSubmit(values))} style={{ width: '100%' }}>
            <Paper elevation={3} style={{ padding: 10, margin: '15px 0' }}>
              <Grid container style={{ marginBottom: 20 }} alignItems="flex-end" spacing={2}>
                <Grid item container>
                  <AddressesModule name="address" label="Поиск адреса" control={control} error={!!errors['address']} />
                </Grid>
              </Grid>

              <Grid container spacing={2} alignItems={'flex-end'} style={{ marginBottom: 20 }}>
                <Grid item md={4} xs={12}>
                  <MaskedInput
                    name="place"
                    label="Страна,Город"
                    control={control}
                    error={!!errors['place']}
                    isNotTextMask
                    mask={'onlyRussianbet'}
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <MaskedInput
                    name="street"
                    label="Улица"
                    control={control}
                    error={!!errors['street']}
                    isNotTextMask
                    mask={'onlyRussianStreet'}
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <MaskedInput
                    name="house"
                    label="Дом"
                    control={control}
                    error={!!errors['house']}
                    isNotTextMask
                    mask={'onlyRussianHouse'}
                    rules={{ required: true }}
                  />
                </Grid>
                {/* <Grid item md={4} xs={12}>
                  <MaskedInput
                    name="number"
                    label="Корпус"
                    control={control}
                    error={!!errors['number']}
                    isNotTextMask
                    mask={'onlyRussianHouseNumber'}
                  />
                </Grid> */}
                <Grid item md={4} xs={12}>
                  <AddressTypes
                    name="type"
                    label="Тип"
                    control={control}
                    error={!!errors['type']}
                    rules={{ required: true }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} justify={'flex-end'}>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    startIcon={<ClearIcon />}
                    type="button"
                    onClick={() => {
                      reset({});
                      setMode('');
                    }}
                  >
                    Отменить
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    startIcon={<SaveIcon />}
                    type="submit"
                    disabled={!formState.isDirty}
                  >
                    Сохранить
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </form>
        ) : (
          <Button color="primary" variant="contained" size="small" onClick={() => setMode('add')} disabled={readonly}>
            <AddIcon />
          </Button>
        )}
        {addressArray?.length > 0 && (
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="address table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell key={column.id} className={classes.tableHead}>
                      {column.label}
                    </TableCell>
                  ))}
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {addressArray?.map((item: any) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={item.id}>
                      {columns.map((column) => {
                        const value = _.get(item, column.name, '');
                        return (
                          <TableCell key={column.rowId}>{column.name === 'type' ? value?.label : value}</TableCell>
                        );
                      })}
                      <TableCell>
                        {mode !== item.id && !readonly && (
                          <>
                            <IconButton
                              onClick={() => {
                                setMode(item.id);
                                reset(item);
                              }}
                            >
                              <EditIcon color="primary" />
                            </IconButton>
                            <IconButton onClick={() => setDeleteItem(item.id)}>
                              <DeleteIcon color="secondary" />
                            </IconButton>{' '}
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Dialog open={deleteItem !== ''} onClose={() => setDeleteItem('')}>
          <DialogTitle>Вы уверенно хотите удалить этого адреса ?</DialogTitle>
          <DialogActions>
            <Grid container justify="space-between">
              <Grid item>
                <Button variant="contained" color="primary" onClick={() => setDeleteItem('')}>
                  Отменить
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="secondary" onClick={() => onDeleteAddress(deleteItem)}>
                  Удалить
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </AccordionDetails>
    </Accordion>
  );
};

export default AddressBox;
