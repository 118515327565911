import { jsPDF } from 'jspdf';
import { CalendarReportEventFragment } from '../../../api';
import moment from 'moment';
import autoTable from 'jspdf-autotable';
import { font } from './assets/OpenSans-Regular-normal';
import { buildTreeByYear } from './helpers/buildTreeByYear';
import _ from 'lodash';

type Data = Array<CalendarReportEventFragment>;

const headers = ['№ п/п', 'Наименование мероприятия', 'Этап', 'Вид спорта', 'Сроки проведения', 'Место проведения'];

type TProps = {
  data: Data;
  fileName: string;
};

export const fetchCalendarGeneralReport = async (props: any) => {
  const { data, fileName }: TProps = props;
  const pdf = new jsPDF({
    orientation: 'p',
    unit: 'pt',
    format: 'a4',
  });

  pdf.addFileToVFS('OpenSans-Regular-normal.ttf', font);
  pdf.addFont('OpenSans-Regular-normal.ttf', 'OpenSans-Regular', 'normal');

  pdf.setFont('OpenSans-Regular', 'normal');

  const buildPage = (header: string, items: Data, newPage: boolean) => {
    if (newPage) {
      pdf.addPage('a4', 'portrait');
    }
    pdf.text(header, 280, 30, {
      align: 'center',
      isOutputVisual: true,
    });
    autoTable(pdf, {
      head: [headers],
      body: [
        ...Object.values(items).map((event, index) => [
          String(index + 1),
          event.name,
          event.clsEventStages?.map((c) => c.fullName).join(', ') || '',
          event.sports?.map((s) => s?.dirSport.label).join(', ') || '',
          `${moment(event.startDate).format('DD.MM.YYYY')} - ${moment(event.endDate).format('DD.MM.YYYY')}`,
          event.venue || '',
        ]),
      ],
      styles: {
        font: 'OpenSans-Regular',
        halign: 'center',
        valign: 'middle',
      },
    });
  };

  const result = buildTreeByYear(data);
  const showYear = Object.keys(result).length > 1;

  let i = 0;
  for (const year in result) {
    for (const month in result[year]) {
      const events = result[year][month];
      buildPage(`${_.capitalize(month)}${showYear ? ` ${year}` : ''}`, events, i > 0);
      i++;
    }
  }

  pdf.save(fileName);
};
