import React, { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { DatePicker } from '../../../../Inputs';
import SaveIcon from '@material-ui/icons/Save';
import { useForm } from 'react-hook-form';
import {
  UpdateDateQuoteRequestApplicationMutationHookResult,
  useUpdateDateQuoteRequestApplicationMutation,
} from '../../../../../api';

export const RequestApplicationModalDate = ({ idCalendar, open, date, onClose, onUpdate }) => {
  const { handleSubmit, control, errors, reset } = useForm({
    defaultValues: { dateAcceptQuotasAndFilesStageTwo: date },
  });

  const [update]: UpdateDateQuoteRequestApplicationMutationHookResult = useUpdateDateQuoteRequestApplicationMutation();

  useEffect(() => {
    reset({
      dateAcceptQuotasAndFilesStageTwo: date,
    });
  }, [date, reset]);

  const handleClose = () => {
    onClose?.();
    return;
  };
  const onSubmit = async ({ dateAcceptQuotasAndFilesStageTwo }) => {
    await update({
      variables: {
        id: idCalendar,
        date: dateAcceptQuotasAndFilesStageTwo,
      },
    });
    onUpdate?.();
    onClose?.();
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Обновление даты приема квот и файлов </DialogTitle>
      <DialogContent style={{ width: 400 }}>
        <Grid container direction="column" spacing={1}>
          <Grid item container>
            <DatePicker
              label="Прием квот и файлов до: "
              control={control}
              error={!!errors['dateAcceptQuotasAndFilesStageTwo']}
              name="dateAcceptQuotasAndFilesStageTwo"
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={handleClose}>
          Отменить
        </Button>
        <Button
          size="small"
          variant="outlined"
          onClick={handleSubmit(onSubmit)}
          color="primary"
          startIcon={<SaveIcon />}
        >
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  );
};
