import React, { FC, useEffect, useState } from 'react';

import { ControllerProps } from 'react-hook-form/dist/types/props';
import { Select } from '../components/Inputs';
import _ from 'lodash';
import useDebouncedSearch from '../hooks/useDebouncedSearch';
import { useSuggestAddressesLazyQuery } from '../api';

interface AddressesModuleProps extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  error: boolean | undefined;
}

const AddressesModule: FC<AddressesModuleProps> = ({ label, error, control, rules, name }) => {
  const [fetch, { data, loading }] = useSuggestAddressesLazyQuery({ variables: { by: '' } });
  const [parsed, setParsed] = useState<any>([]);

  const refetchData = async (value: string) => {
    if (value?.length > 2) {
      await fetch({ variables: { by: value } });
    }
  };
  const useSearch = () => useDebouncedSearch((text) => refetchData(text));

  const { setInputValue: setValue } = useSearch();

  useEffect(() => {
    if (!loading) {
      const values: Array<{ value?: string }> = _.uniqWith(
        _.flattenDeep(
          data?.suggestAddresses.map((t: any) => [
            { value: { ...t }, label: t.value },
            { value: { ...t }, label: t.unrestrictedValue },
          ]),
        ),
        _.isEqual,
      );

      setParsed(values || []);
    }
  }, [data, loading]);

  return (
    <Select
      label={label}
      data={parsed}
      control={control}
      error={error}
      name={name}
      rules={rules}
      onChangeInput={setValue}
      enablePortal
      disableFilterOptions
      loading={loading}
    />
  );
};

export default AddressesModule;
