import { Box, Button, Grid } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';

import ClearIcon from '@material-ui/icons/Clear';
import ContentWrapper from '../layouts/ContentWrapper/ContentWrapper';
import { FederalDistricts } from '../../module';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Input, Select } from '../Inputs';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import { onSetRegionsFilter } from './helpers';
import { useForm } from 'react-hook-form';
import { useGenerateRegionXlsFileLazyQuery } from '../../api';
import useGenerateXlsx from '../../hooks/useGenerateXlsx';

const useStyle = makeStyles((theme) => ({
  btn: {
    marginRight: theme.spacing(2),
  },
}));

const Regions: FC<{
  onSubmit?: (T: any, E: any) => void;
  value?: any;
  first: number;
}> = ({ onSubmit = () => {}, value, first }) => {
  const style = useStyle();
  const { handleSubmit, control, errors, reset, formState, getValues } = useForm({
    defaultValues: value,
  });

  const { onDownloadFile } = useGenerateXlsx({
    lazyQuery: useGenerateRegionXlsFileLazyQuery,
    getValues,
    setFilter: onSetRegionsFilter,
    fileName: 'regions',
    first,
  });

  const handleSearchBox = (value: any) => {
    const filters: any = onSetRegionsFilter(value);

    onSubmit(filters, getValues());
  };

  return (
    <ContentWrapper>
      <form onSubmit={handleSubmit(handleSearchBox)}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Input label="Название" control={control} error={!!errors['fullName']} name="fullName" />
          </Grid>
          <Grid item xs={3}>
            <Input label="Краткое название" control={control} error={!!errors['shortName']} name="shortName" />
          </Grid>
          <Grid item xs={3}>
            <FederalDistricts
              label="Федеральный округ"
              control={control}
              error={!!errors['dirFederalDistrict']}
              name="dirFederalDistrict"
            />
          </Grid>
          <Grid item xs={3}>
            <Select
              label="В архиве"
              data={[
                { value: '', label: ' ' },
                { value: false, label: 'Нет' },
                { value: true, label: 'Да' },
              ]}
              control={control}
              error={!!errors['archive']}
              name="archive"
              defaultValue={{ value: false, label: 'Нет' }}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              variant="outlined"
              className={style.btn}
              color="primary"
              type="button"
              onClick={() => {
                onSubmit({}, {});
                reset({
                  shortName: '',
                  fullName: '',
                  dirFederalDistrict: null,
                });
              }}
              size="small"
              startIcon={<ClearIcon />}
            >
              Сброс
            </Button>
            <Button
              variant="outlined"
              color="primary"
              type="submit"
              size="small"
              className={style.btn}
              startIcon={<SearchIcon />}
              disabled={!formState.isDirty}
            >
              Поиск
            </Button>
            <Button
              variant="outlined"
              color="primary"
              disabled={!formState.isDirty}
              onClick={onDownloadFile}
              size={'small'}
              startIcon={<GetAppIcon />}
            >
              XLS
            </Button>
          </Grid>
        </Grid>
      </form>
    </ContentWrapper>
  );
};

export default Regions;
