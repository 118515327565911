import React, { FC } from 'react';
import { useClsSportCategoriesQuery } from '../api';
import { Select } from '../components/Inputs';
import { ControllerProps } from 'react-hook-form/dist/types/props';

interface SportCategories extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  error: boolean | undefined;
}

const SportCategories: FC<SportCategories> = ({ label, error, control, name, rules }) => {
  const { data } = useClsSportCategoriesQuery();
  var multiple = true;
  if (name == 'sportStatusEkp' || name == 'clsSportCategory') {
    multiple = false;
  }
  return (
    <Select
      multiple={multiple}
      label={label}
      data={(data && data.classifierValues) || []}
      control={control}
      error={error}
      name={name}
      rules={rules}
    />
  );
};

export default SportCategories;
