import {
  useCalendarRegionSummaryInformationOnParticipantNumberLazyQuery,
  useGetReqPartFromStageTwoesQuery,
  useRegionsListQuery,
} from '../../../../../api';
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useEffect, useState } from 'react';
import { RequestApplicationRegionDetail } from './RequestApplicationRegionDetail';
import userRights from '../../../../../config/userRights';
import { fetchRegionSummaryInformationOnTheNumberOfParticipantsReport } from '../../../../BtnReport/services/fetchRegionSummaryInformationOnTheNumberOfParticipantsReport';

export const RequestApplicationRegionList = ({
  listSports,
  id,
  date,
  listSportsValue,
  calendarName = '',
  canDownloadReport,
}: any) => {
  const { isRoiv, userRegion } = userRights();
  const { data: dataRegionRoiv } = useGetReqPartFromStageTwoesQuery({ variables: { where: { dirCalendar: { id } } } });

  const [parsedRegion, setParsedRegion] = useState({});

  const regionFilters = isRoiv
    ? {
        id: userRegion,
      }
    : undefined;
  const { data: regionList } = useRegionsListQuery({
    variables: { filter: { archive: null, ...regionFilters } },
  });
  const [expand, setExpand] = useState(null);
  useEffect(() => {
    if (dataRegionRoiv?.reqPartFromStageTwoes?.length) {
      setParsedRegion(_.keyBy(dataRegionRoiv?.reqPartFromStageTwoes || [], 'region.id'));
    }
  }, [dataRegionRoiv]);

  const [
    fetchRegionSummaryInformation,
    { data: fetchRegionSummaryInformationData, loading: fetchRegionSummaryInformationLoading },
  ] = useCalendarRegionSummaryInformationOnParticipantNumberLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const handleSummaryInfomation = (evt: any, regionId: string) => {
    evt?.stopPropagation();
    regionId &&
      id &&
      fetchRegionSummaryInformation({
        variables: {
          id,
          regionId,
        },
      });
  };

  useEffect(() => {
    if (!fetchRegionSummaryInformationLoading && fetchRegionSummaryInformationData) {
      fetchRegionSummaryInformationOnTheNumberOfParticipantsReport({
        data: fetchRegionSummaryInformationData?.quantyRequestStageTwoes,
        calendarName,
        fileName: 'Приложение №7 Подтверждение участия во II этапе',
      });
    }
  }, [calendarName, fetchRegionSummaryInformationData, fetchRegionSummaryInformationLoading]);

  if (!regionList?.dirRegions?.length) {
    return null;
  }
  return regionList?.dirRegions?.map((region) => {
    const s = parsedRegion[region?.value as never] as any;
    const u = s?.roivSpecialistsForNotification?.[0] || undefined;
    return (
      // <div>{region.label}</div>
      <Accordion
        key={region?.value}
        expanded={expand === region?.value}
        onChange={(e, status) => setExpand(status ? region.value : null)}
        style={{ width: '100%' }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id={`sport1-header`}>
          <Grid container justify="space-between">
            <Grid item>
              <Typography color="primary">
                {region?.label}
                {u &&
                  `  |  ${u.regular?.lastname || ''} ${u.regular?.firstname || ''} ${u.regular?.patronymic || ''} | ${
                    u.email || ''
                  }`}
              </Typography>
            </Grid>
            <Grid item>
              {canDownloadReport && (
                <Button
                  onClick={(_) => handleSummaryInfomation(_, region?.value)}
                  variant="outlined"
                  color="primary"
                  size="small"
                >
                  Приложение №7 (заявка на участие)
                </Button>
              )}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: 'column' }}>
          {expand === region?.value && (
            <RequestApplicationRegionDetail
              date={date}
              listSports={listSports}
              listSportsValue={listSportsValue}
              id={id}
              idRegion={region?.value}
            />
          )}
        </AccordionDetails>
      </Accordion>
    );
  });
};
