import { revealAthletes } from './functions/revealAthletes';
import { revealCalendar } from './functions/revealCalendar';
import { revealDocuments } from './functions/revealDocuments';
import { revealEventsMinsports, revealEventsMinsportsMultiple } from './functions/revealEventsMinSports';
import { revealTeams } from './functions/revealTeams';
import { revealOrganization } from './functions/revealOrganization';
import { revealPersons } from './functions/revealPersons';
import { revealReferee } from './functions/revealReferee.';
import { revealEvents, revealEventMultiple } from './functions/revealEvents';
import { revealTrainers } from './functions/revealTrainer';

const reveal = {
  athletes: revealAthletes,
  calendars: revealCalendar,
  documents: revealDocuments,
  minSport: revealEventsMinsports,
  minSportMultiple: revealEventsMinsportsMultiple,
  teams: revealTeams,
  organizations: revealOrganization,
  persons: revealPersons,
  referees: revealReferee,
  events: revealEvents,
  eventsMultiple: revealEventMultiple,
  trainers: revealTrainers,
};

export type TDirectory =
  | 'athletes'
  | 'calendars'
  | 'documents'
  | 'minSport'
  | 'minSportMultiple'
  | 'teams'
  | 'organizations'
  | 'persons'
  | 'referees'
  | 'events'
  | 'eventsMultiple'
  | 'trainers';

export const Reveal = (data: any, filter: any, directory?: TDirectory) =>
  directory ? reveal[directory](data, filter) : data;
