import React, { FC, useState } from 'react';
import {
  Link,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TableBody,
  Table,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  Paper,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import { Scalars } from '../../../../api';
import moment from 'moment';
import _ from 'lodash';

const useStyles = makeStyles({
  title: {
    width: 130,
    fontWeight: 600,
    display: 'inline-block',
  },
});

interface USER {
  id: Scalars['UUID'];
  name: string;
  email: string;
  regular: {
    id: Scalars['UUID'];
    firstname: string;
    lastname: string;
    patronymic: string;
    phone: string;
    dirOrganization: {
      id: Scalars['UUID'];
      name: string;
      legalAddress: string;
    };
  };
}

interface Status {
  id: Scalars['UUID'];
  createdAt: Date;
  comment: string;
  value: {
    value: Scalars['UUID'];
    label: string;
  };
  file: {
    id: Scalars['UUID'];
    name: string;
    path: string;
  };
  updatedBy: USER;
}

const RequestStatusBoard: FC<{ statusArray: Array<Status> }> = ({ statusArray }) => {
  const classes = useStyles();

  const DOWNLOAD_LINK = process.env.REACT_APP_BACKEND_URI?.replace('/graphql', '');
  const [openDialog, setOpenDialog] = useState(false);
  const [currentUser, setCurrentUser] = useState<USER | null>(null);

  return (
    <div style={{ marginTop: 20 }}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="request-board-content"
          id="request-board-header"
        >
          <Typography style={{ fontWeight: 600 }}>История изменений статуса</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table aria-label=" status table">
              <TableHead>
                <TableRow>
                  <TableCell>Дата и время</TableCell>
                  <TableCell>Статус</TableCell>
                  <TableCell>Оператор</TableCell>
                  <TableCell>Комментарий</TableCell>
                  <TableCell>Документ</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {statusArray.map((status) => (
                  <TableRow key={status.id}>
                    <TableCell component="th" scope="row">
                      {moment(status.createdAt).format('DD.MM.YYYY HH:mm:ss')}
                    </TableCell>
                    <TableCell>{status?.value?.label}</TableCell>
                    <TableCell>
                      <Button
                        color="primary"
                        style={{ textTransform: 'capitalize' }}
                        onClick={() => {
                          setCurrentUser(status?.updatedBy);
                          setOpenDialog(true);
                        }}
                      >
                        {`${status?.updatedBy?.regular?.lastname || ''} ${
                          status?.updatedBy?.regular?.firstname || ''
                        } ${status?.updatedBy?.regular?.patronymic || ''}`}
                      </Button>
                    </TableCell>
                    <TableCell>{status?.comment}</TableCell>
                    <TableCell>
                      <Link href={`${DOWNLOAD_LINK}${status?.file?.path}`} style={{ wordBreak: 'break-all' }}>
                        {status?.file?.name}
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>

      <Dialog
        onClose={() => {
          setOpenDialog(false);
          setCurrentUser(null);
        }}
        aria-labelledby="user-dialog"
        open={openDialog}
      >
        <DialogTitle id="user-dialog">Оператор</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item container>
              <Typography>
                <span className={classes.title}>ФИО:</span>
                {`${currentUser?.regular?.lastname || ''} ${currentUser?.regular?.firstname || ''} ${
                  currentUser?.regular?.patronymic || ''
                }`}
              </Typography>
            </Grid>

            {currentUser?.regular?.dirOrganization && (
              <Grid item container>
                <Typography>
                  <span className={classes.title}>Организация:</span>
                  {`${currentUser?.regular?.dirOrganization?.name}, ${currentUser?.regular?.dirOrganization?.legalAddress}`}
                </Typography>
              </Grid>
            )}

            <Grid item container>
              <Typography>
                <span className={classes.title}>E-mail:</span>
                {currentUser?.email}
              </Typography>
            </Grid>

            <Grid item container>
              <Typography>
                <span className={classes.title}>Телефон:</span>
                {`${currentUser?.regular?.phone || '~'}`}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setOpenDialog(false);
              setCurrentUser(null);
            }}
          >
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RequestStatusBoard;
