import React, { FC } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, Button, Typography } from '@material-ui/core';

const ConfirmDialog: FC<{
  open: boolean;
  title: string;
  dangerText?: string;
  text: string;
  saveBtnText: string;
  cancelBtnText: string;
  loading?: boolean;
  btnVariant?: 'text' | 'outlined' | 'contained';
  saveBtnColor?: 'inherit' | 'default' | 'primary' | 'secondary';
  cancelBtnColor?: 'inherit' | 'default' | 'primary' | 'secondary';
  onCancel: () => void;
  onSave: () => void;
}> = ({
  open,
  title,
  text,
  dangerText,
  btnVariant,
  saveBtnText,
  cancelBtnText,
  saveBtnColor,
  cancelBtnColor,
  onCancel,
  onSave,
  loading,
}) => {
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>{text}</Typography>
        {dangerText && (
          <Typography style={{ fontWeight: 500, fontSize: 12, letterSpacing: 1.2 }} color="error" component="span">
            {dangerText}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Grid item container spacing={2} justify="space-between">
          <Grid item>
            <Button
              color={cancelBtnColor || 'secondary'}
              disabled={loading}
              variant={btnVariant}
              onClick={loading ? () => {} : onCancel}
            >
              {cancelBtnText}
            </Button>
          </Grid>
          <Grid item>
            <Button
              color={saveBtnColor || 'primary'}
              disabled={loading}
              variant={btnVariant}
              onClick={loading ? () => {} : onSave}
            >
              {saveBtnText}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
