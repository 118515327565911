import React from 'react';
import notfound from '../../../static/img/404-error.svg';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Link } from 'react-router-dom';

export default function NotFound() {
  return (
    <div style={{ display: 'table', margin: '0 auto' }}>
      <h1 style={{ textAlign: 'center', fontFamily: 'sans-serif', color: 'red' }}>Страница не найдена.</h1>
      <Link to="/">
        <ArrowBackIcon style={{ fontSize: '2.5rem' }} />
      </Link>
      <img src={notfound} alt="not found" />
    </div>
  );
}
