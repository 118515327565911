import React, { FC, useEffect, useState } from 'react';
import { useTrainersInOrganizationQuery, Scalars } from '../api';
import { Select } from '../components/Inputs';
import { ControllerProps } from 'react-hook-form/dist/types/props';
import _ from 'lodash';
import moment from 'moment';

interface TrainersModuleProps extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  error: boolean | undefined;
  organizationId: Scalars['UUID'];
  sportId: Scalars['UUID'];
  bannedTrainers?: Scalars['UUID'][];
  disabled?: boolean;
}

const TrainersModule: FC<TrainersModuleProps> = ({
  label,
  error,
  control,
  name,
  rules,
  organizationId,
  sportId,
  bannedTrainers,
  disabled,
}) => {
  const { data, loading } = useTrainersInOrganizationQuery({
    skip: !organizationId,
    variables: { organizationId, sportId, bannedTrainers },
  });
  const [parsed, setParsed] = useState<any>([]);

  useEffect(() => {
    if (!loading) {
      const prepare = data?.dirTrainers.map((item) => {
        return {
          value: item?.id,
          label: `${item?.dirPerson?.lastname || ''} ${item?.dirPerson?.firstname || ''} ${
            item?.dirPerson?.patronymic ? item?.dirPerson?.patronymic : ''
          } ${moment(item?.dirPerson?.birthday).format('DD.MM.YYYY')}`,
        };
      });
      setParsed(prepare || []);
    }
  }, [data, loading]);

  return (
    <Select
      label={label}
      data={parsed}
      control={control}
      error={error}
      name={name}
      rules={rules}
      loading={loading}
      disabled={disabled}
    />
  );
};

export default TrainersModule;
