import _ from 'lodash';

const rNumbers = {
  a: 100002484,
  b: 100002492,
  c: 100002486,
  d: 100002485,
  e: 100002500,
  f: 100002518,
  g: 100002526,
};

export const highRefereesNumber = [100002484, 100002492];

const getList = (registryNumber: number, list: any[]) =>
  _.sortBy(
    list.filter((r) => _.get(r, 'clsRefereePosition.registryNumber', 0) === registryNumber),
    ['dirReferee.dirPerson.lastname', 'dirReferee.dirPerson.firstname', 'dirReferee.dirPerson.patronymic'],
  );

export const sortReferees = (referees: any[]) =>
  _.flattenDeep([..._.values(rNumbers).map((n) => getList(n, referees))]) as any[];
