import moment from 'moment';

export const getValidUntilDate = (rank: { label: string; value: string }, date: Date) => {
  if (rank?.label?.trim() === 'Кандидат в мастера спорта') return moment(date).subtract(1, 'day').add(3, 'years');
  if (
    rank?.label !== 'Мастер спорта России' &&
    rank?.label !== 'Гроссмейстер России' &&
    rank?.label !== 'Заслуженный мастер спорта' &&
    rank?.label !== 'Мастер спорта России международного класса' &&
    date
  )
    return moment(date).subtract(1, 'day').add(2, 'years');
};
