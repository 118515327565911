import React, { FC } from 'react';
import { useCalendarTypesQuery } from '../api';
import { Select } from '../components/Inputs';
import { ControllerProps } from 'react-hook-form/dist/types/props';

interface ICalendarType extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  error: boolean | undefined;
  disabled?: boolean;
  defaultValue?: { label: string; value: Number };
}

const CalendarTypes: FC<ICalendarType> = ({ label, error, control, name, rules, defaultValue, disabled }) => {
  const { data, loading } = useCalendarTypesQuery();

  return (
    <Select
      label={label}
      data={data?.dirCalendarTypes || []}
      control={control}
      error={error}
      name={name}
      rules={rules}
      loading={loading}
      defaultValue={defaultValue || null}
      disabled={disabled}
    />
  );
};

export default CalendarTypes;
