import { SubmitHandler, useForm } from 'react-hook-form';
import React, { FC, FormEvent, useState } from 'react';
import {
  Grid,
  Tooltip,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Input } from '../../../Inputs';
import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import DatePicker from '../../../Inputs/DatePicker';
import { DirNationalTeamMember } from '../../../../api';
import ConfirmDialog from '../../../ConfirmDialog';
interface FromInterface {
  classes: any;
  readonly?: boolean;
  title: string;
  onSubmit: SubmitHandler<FormEvent<HTMLFormElement>>;
  onDelete: Function;
  canAdd: boolean;
  memberArray?: any;
}

const SupportMemberComponent: FC<FromInterface> = ({
  classes,
  title,
  readonly,
  onSubmit,
  canAdd,
  onDelete,
  memberArray = [],
}) => {
  const { handleSubmit, control, errors, reset, formState } = useForm();
  const [deleteItem, setDeleteItem] = useState<any>(null);
  const deleteNumberOnTitleMember = (titleMember: string) => {
    return titleMember.replace(/[0-9]/g, '');
  };
  const _handleSubmit = (value: any) => {
    if (formState.isDirty) onSubmit(value);
    reset();
  };
  return (
    <Grid item container>
      <Accordion style={{ width: '100%' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="supportMember-content"
          id="supportMember-header"
        >
          <Typography className={classes.title}>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: 'column' }}>
          {!readonly && (
            <form onSubmit={handleSubmit(_handleSubmit)} style={{ width: '100%' }}>
              <Grid container spacing={2} alignItems="flex-end" className={classes.verticalSpaceMarge}>
                <Grid item>
                  <Input
                    label="Фамилия"
                    control={control}
                    error={!!errors['lastname']}
                    name="lastname"
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item>
                  <Input
                    label="Имя"
                    control={control}
                    error={!!errors['firstname']}
                    name="firstname"
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item>
                  <Input
                    label="Отчество"
                    control={control}
                    error={!!errors['patronymic']}
                    name="patronymic"
                    rules={{ required: false }}
                  />
                </Grid>
                <Grid item>
                  <DatePicker
                    name="birthday"
                    label="ДР"
                    invalidDateMessage="Неверный формат"
                    control={control}
                    error={!!errors['birthday']}
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item>
                  <Tooltip title={(canAdd && 'добавить') || 'Исчерпан лимит на сопровождающих'} placement="top-start">
                    <IconButton
                      className={classes.btn}
                      color={formState.isDirty && canAdd ? 'primary' : 'default'}
                      type="submit"
                    >
                      <AddBoxIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </form>
          )}
          {memberArray.map((member: DirNationalTeamMember, index: number) => (
            <Grid key={member.id} item container alignItems="center">
              <Grid item xs={5}>
                {index + 1} {member.lastname} {member.firstname} {member.patronymic}
              </Grid>
              <Grid item xs={2}>
                {moment(member.birthday).format('DD.MM.YY')}
              </Grid>
              {!readonly && (
                <Grid item>
                  <Tooltip title="удалить" placement="top-start">
                    <IconButton className={classes.btn} color="secondary" onClick={() => setDeleteItem(member.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
              <ConfirmDialog
                title={`Удаление группы`}
                text={`Вы уверены что хотите удалить: ${deleteNumberOnTitleMember(title)} ${member.lastname} ${
                  member.firstname
                } ${member.patronymic}`}
                btnVariant="outlined"
                open={deleteItem === member.id}
                saveBtnText="удалить"
                cancelBtnText="отмена"
                saveBtnColor="secondary"
                cancelBtnColor="primary"
                onCancel={() => setDeleteItem(null)}
                onSave={() => {
                  onDelete(member.id);
                  setDeleteItem(null);
                }}
              />
            </Grid>
          ))}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default SupportMemberComponent;
