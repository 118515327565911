import React, { FC, useState } from 'react';
import { Button, IconButton, Grid, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { useForm } from 'react-hook-form';
import { DatePicker } from '../../../Inputs';
import getMessage from '../../../../messages';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import { useSnackbar } from 'notistack';
import {
  Scalars,
  UpdateOrganizationSportMutationHookResult,
  useUpdateOrganizationSportMutation,
} from '../../../../api';
import moment from 'moment';
import _ from 'lodash';

interface formState {
  trainingStartDate?: Date;
}

const DateBox: FC<{
  onUpdate: Function;
  sport: any;
  readonly?: boolean;
}> = ({ sport, onUpdate, readonly }) => {
  const [isEditing, setIsEditing] = useState(false);

  const [update]: UpdateOrganizationSportMutationHookResult = useUpdateOrganizationSportMutation();

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const defaultState: formState = {
    trainingStartDate: sport?.trainingStartDate,
  };

  const onHandleCancel = () => {
    setIsEditing(false);
    reset({});
  };

  const { handleSubmit, control, errors, reset, formState } = useForm({
    defaultValues: defaultState,
  });

  const onSubmit = async (values: formState) => {
    const { trainingStartDate } = values;

    try {
      await update({
        variables: {
          data: {
            trainingStartDate,
          },
          id: sport?.id,
        },
      });
      onUpdate();
      onHandleCancel();
      handleSnackBar('success', 'Дата успешно обновлена');
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  return (
    <form onSubmit={handleSubmit((values) => onSubmit(values))} style={{ width: '100%' }}>
      {!readonly && isEditing ? (
        <Grid container style={{ marginBottom: 15 }} alignItems="flex-end" spacing={2}>
          <Grid item md={3} xs={12}>
            <DatePicker
              fullWidth
              name="trainingStartDate"
              label="Дата"
              control={control}
              error={!!errors['trainingStartDate']}
              rules={{ required: true }}
            />
          </Grid>

          <Grid item>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              startIcon={<ClearIcon />}
              type="button"
              onClick={onHandleCancel}
            >
              Отменить
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<SaveIcon />}
              type="submit"
              disabled={!formState.isDirty}
            >
              Сохранить
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid container style={{ marginBottom: 10 }} alignItems="center" spacing={2}>
          <Grid item>
            <Typography>{`Дата: ${
              (sport?.trainingStartDate && moment(sport?.trainingStartDate).format('DD.MM.YYYY')) || ''
            }`}</Typography>
          </Grid>
          {!readonly && (
            <Grid item>
              <IconButton color="primary" type="button" onClick={() => setIsEditing(true)}>
                <EditIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      )}
    </form>
  );
};

export default DateBox;
