import React, { FC } from 'react';
import { useTeamStatusesQuery } from '../api';
import { Select } from '../components/Inputs';
import { ControllerProps } from 'react-hook-form/dist/types/props';

interface TeamStatus extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  error: boolean | undefined;
  filter?: any;
  disabled?: boolean;
}

const TeamStatus: FC<TeamStatus> = ({ label, error, control, name, rules, filter, disabled }) => {
  const { data } = useTeamStatusesQuery({ variables: { filter } });
  return (
    <Select
      label={label}
      data={(data && data.dirNationalTeamStatuses?.filter((status) => !status?.isDeprecated)) || []}
      control={control}
      error={error}
      name={name}
      rules={rules}
      disabled={disabled}
    />
  );
};

export default TeamStatus;
