import React, { FC } from 'react';
import { ClassifierValueFragment, useClsDepartmentAffiliationQuery } from '../api';
import { Select } from '../components/Inputs';
import { ControllerProps } from 'react-hook-form/dist/types/props';

interface SelectCls extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  error: boolean | undefined;
  usedValues?: any;
}

const DepartmentAffiliation: FC<SelectCls> = ({ label, error, control, name, rules, usedValues }) => {
  const { data } = useClsDepartmentAffiliationQuery();
  const classifierValues = data?.classifierValues || [];

  return (
    <Select
      label={label}
      data={
        (usedValues &&
          classifierValues.filter((cv: ClassifierValueFragment) => usedValues?.indexOf(cv.value) === -1)) ||
        classifierValues
      }
      control={control}
      error={error}
      name={name}
      rules={rules}
    />
  );
};

export default DepartmentAffiliation;
