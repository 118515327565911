import { Box, Button, Grid } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import {
  useAthletesCountLazyQuery,
  useAthletesSimilarCountLazyQuery,
  useGenerateAthletesXlsFileLazyQuery,
} from '../../api';

import { AthletesFilterAdditional } from './index';
import ClearIcon from '@material-ui/icons/Clear';
import ContentWrapper from '../layouts/ContentWrapper/ContentWrapper';
import { Count } from '../Count';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Input } from '../Inputs';
import SearchIcon from '@material-ui/icons/Search';
import TuneIcon from '@material-ui/icons/Tune';
import { makeStyles } from '@material-ui/core/styles';
import { onSetAthletesFilter } from './helpers';
import { useForm } from 'react-hook-form';
import useGenerateXlsx from '../../hooks/useGenerateXlsx';

const useStyle = makeStyles((theme) => ({
  btn: {
    marginRight: theme.spacing(2),
  },
}));

const Athletes: FC<{
  onSubmit?: (T: any, E: any) => void;
  value?: any;
  defaultFilter?: any;
  first: number;
}> = ({ onSubmit = () => {}, value = {}, defaultFilter = {}, first }) => {
  const style = useStyle();
  const { handleSubmit, control, errors, reset, watch, formState, setValue, getValues } = useForm({
    defaultValues: value,
  });
  const [additional, setAdditional] = useState<boolean>(Object.keys(value)?.length > 0);
  const [filter, setFilter] = useState<any>({
    ...defaultFilter,
    archive: null,
  });

  const { onDownloadFile } = useGenerateXlsx({
    lazyQuery: useGenerateAthletesXlsFileLazyQuery,
    getValues,
    setFilter: onSetAthletesFilter,
    fileName: 'athletes',
    first,
  });

  const handleSearchBox = (value: any) => {
    const filter: any = onSetAthletesFilter(value);

    setFilter(filter);
    onSubmit(filter, getValues());
  };

  return (
    <ContentWrapper>
      <form
        onSubmit={handleSubmit(handleSearchBox)}
        onReset={(e?) => {
          setValue('registryNumber', '');
          reset();
          setFilter({
            dirPerson: {
              archive: null,
            },
          });
          onSubmit(
            {
              dirPerson: {
                archive: null,
              },
            },
            {},
          );
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <Input
              label="Идентификатор спортсмена"
              control={control}
              error={!!errors['registryNumber']}
              name="registryNumber"
            />
          </Grid>
          <Grid item xs={3}>
            <Input label="фамилия имя отчество" control={control} error={!!errors['name']} name="name" />
          </Grid>
          {additional && <AthletesFilterAdditional watch={watch} control={control} errors={errors} />}
          <Grid item container alignItems={'center'}>
            <Button
              variant="outlined"
              className={style.btn}
              color="primary"
              type="reset"
              onClick={() =>
                reset({
                  registryNumber: '',
                  name: '',
                  previousLastname: '',
                  email: '',
                  guid: '',
                  displayNumber: '',
                  dirSport: null,
                  dirRegion: null,
                  dirCountry: null,
                  dirForeignCity: null,
                  clsStatusPerson: null,
                  disciplineGroups: null,
                  clsRanksAndAwards: null,
                  trainer: null,
                  dirOrganization: null,
                  isSelfTraining: null,
                  clsStagePreparing: null,
                  isRussia: null,
                  isValid: null,
                  isDuplicated: null,
                  archive: { value: false, label: 'Нет' },
                  birthday_gte: null,
                  birthday_lte: null,
                  validUntil_gte: null,
                  validUntil_lte: null,
                })
              }
              size="small"
              startIcon={<ClearIcon />}
            >
              Сброс
            </Button>
            <Button
              variant="outlined"
              color="primary"
              type="submit"
              size="small"
              className={style.btn}
              startIcon={<SearchIcon />}
              disabled={!formState.isDirty}
            >
              Поиск
            </Button>
            <Button
              onClick={() => setAdditional(!additional)}
              type="button"
              className={style.btn}
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<TuneIcon />}
            >
              Фильтр
            </Button>
            <Box pl={4}>
              <Button
                variant="outlined"
                color="primary"
                disabled={!formState.isDirty}
                onClick={onDownloadFile}
                size={'small'}
                startIcon={<GetAppIcon />}
              >
                XLS
              </Button>
              <Count
                key={JSON.stringify(filter)}
                hasSimilarQuery
                query={filter?.name ? useAthletesSimilarCountLazyQuery : useAthletesCountLazyQuery}
                filter={filter}
              />
            </Box>
          </Grid>
        </Grid>
      </form>
    </ContentWrapper>
  );
};

export default Athletes;
