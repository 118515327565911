import { Grid, Tooltip } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import React, { FC, useCallback, useState } from 'react';
import { getSettingsUserFilter, getSettingsUserFilterFormValue } from '../../store/selectors/settings';
import { useDispatch, useSelector } from 'react-redux';
import { useSportingEvents2ndLazyQuery, useSportingEventsLazyQuery } from '../../api';
import { AppStore } from '../../store/reducers';
import { BtnNew } from '../../components/BtnNew';
import { DataTable } from '../../components/DataTable/NewTable';
import { DictionaryWrapper } from '../../components/DictionaryWrapper';
import { MUIDataTableColumn } from 'mui-datatables';
import { MainTemplate } from '../../components/layouts';
import { SET_USER_SETTINGS_FILTER } from '../../store/constants';
import { SportingEventsFilter } from '../../components/Filters';
import _ from 'lodash';
import { usePermission } from '../../hooks/usePermission';
import userRights from '../../config/userRights';
import moment from 'moment';
import ImportExportIcon from '@material-ui/icons/ImportExport';

const Page: FC<{}> = () => {
  const dispatch = useDispatch();
  const { hasAdditionalFilter, isRoiv } = userRights();
  const filter: any = useSelector((state: AppStore) => getSettingsUserFilter(state, 'sporting_events'), _.isEqual);

  const isFcpsrSportingEventOrganizerProfile = JSON.parse(
    localStorage.getItem('fcpsrSportingEventOrganizerProfile') || null,
  );
  if (isFcpsrSportingEventOrganizerProfile) {
  }

  const formValue: any = useSelector(
    (state: AppStore) => getSettingsUserFilterFormValue(state, 'sporting_events'),
    _.isEqual,
  );
  const [first, setFirst] = useState(0);
  const { push } = useHistory();
  const access = usePermission('directory', 'sporting_events');

  const filterByRoleAccess = {};
  const filterByAccessMain = {};
  if (isFcpsrSportingEventOrganizerProfile?.dirCalendars?.length > 0 && !isRoiv) {
    filterByRoleAccess.dirCalendars = { id_in: isFcpsrSportingEventOrganizerProfile?.dirCalendars?.map((i) => i.id) };
  }
  if (isFcpsrSportingEventOrganizerProfile?.dirSports?.length > 0 && !isRoiv) {
    filterByRoleAccess.sportType = { id_in: isFcpsrSportingEventOrganizerProfile?.dirSports?.map((i) => i.id) };
  }

  if (isFcpsrSportingEventOrganizerProfile?.dirCalendars?.length > 0 && !isRoiv) {
    filterByAccessMain.dirCalendar = { id_in: isFcpsrSportingEventOrganizerProfile?.dirCalendars?.map((i) => i.id) };
  }
  if (isFcpsrSportingEventOrganizerProfile?.dirSports?.length > 0 && !isRoiv) {
    filterByAccessMain.sports_some = {
      dirSport: { id_in: isFcpsrSportingEventOrganizerProfile?.dirSports?.map((i) => i.id) },
    };
  }
  const setFilter = useCallback(
    (filter, form) => {
      dispatch({
        type: SET_USER_SETTINGS_FILTER,
        payload: {
          name: 'sporting_events',
          filter: filter,
          form,
        },
      });
    },
    [dispatch],
  );

  const columns: MUIDataTableColumn[] = [
    {
      name: 'name',
      label: 'Название мероприятия',
      options: {
        filter: false,
        sort: true,
        sortDescFirst: false,
        customHeadLabelRender: (columnMeta) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ImportExportIcon fontSize="small" /> {columnMeta.label}{' '}
          </div>
        ),

        customBodyRender: (name: any, tableMeta: any, ...other) => {
          const { rowData } = tableMeta;
          const { id } = rowData[1] || {};
          return (
            <React.Fragment>
              <Tooltip title="Перейти в карточку" placement="top-start">
                <Link
                  style={{
                    fontWeight: 500,
                    cursor: 'pointer',
                    textDecoration: 'none',
                  }}
                  to={`/sporting_event/${id}`}
                  target={'_blank'}
                >
                  {name}
                </Link>
              </Tooltip>
            </React.Fragment>
          );
        },
      },
    },
    {
      name: 'info',
      label: 'Название мероприятия',
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'venue',
      label: 'Место проведения',
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: 'sportTypendDisciplines',
      label: 'Вид спорта/ дисциплины',
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },

    // ${startDate && moment(startDate).format('DD.MM.YY')} - ${endDate && moment(endDate).format('DD.MM.YY')}
    {
      name: 'startDate',
      label: 'Период проведения',
      options: {
        filter: false,
        sort: true,
        display: true,
        customHeadLabelRender: (columnMeta) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ImportExportIcon fontSize="small" /> {columnMeta.label}{' '}
          </div>
        ),

        customBodyRender: (startDate: any, tableMeta: any, ...other) => {
          const { rowData } = tableMeta;
          const endDate = rowData[5] || null;
          return (
            <React.Fragment>
              {`${startDate && moment(startDate).format('DD.MM.YY')} - ${
                endDate && moment(endDate).format('DD.MM.YY')
              }`}
            </React.Fragment>
          );
        },
      },
    },
    {
      name: 'endDate',
      label: 'период проведения',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'dirNationalTeams',
      label: 'Сборных команд',
      options: {
        filter: false,
        sort: false,
        display: true,
        customBodyRender: (teams) => <React.Fragment>{teams?.length}</React.Fragment>,
      },
    },
    {
      name: 'athletesCount',
      label: ' Спортсменов',
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: 'athletesCompany',
      label: ' Сопровожд',
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: 'dirCalendar',
      label: 'Календарь',
      options: {
        filter: false,
        sort: false,
        display: true,

        customBodyRender: (status) => <React.Fragment>{status.shortName}</React.Fragment>,
      },
    },

    {
      name: 'statuses',
      label: 'Статус согласования',
      options: {
        filter: false,
        sort: false,
        display: true,

        customBodyRender: (statuses) => <React.Fragment>{_.get(statuses, '[0].value.name', '')}</React.Fragment>,
      },
    },
    {
      name: 'info',
      label: 'Реестровый номер',
      options: {
        filter: false,
        sort: false,
        display: true,

        customBodyRender: (value) => value?.registryNumber,
      },
    },
    {
      name: 'ekp',
      label: 'Номер ЕКП',
      options: {
        filter: false,
        sort: false,
        display: true,

        // customBodyRender: (value) => value?.registryNumber,
      },
    },
  ];

  return (
    <MainTemplate pageName="Справочник спортивных мероприятий" url="" title="Спортмероприятия ФЦПСР">
      <SportingEventsFilter
        onSubmit={(filters: any, form: any) => setFilter({ ...filters, proxy: null }, form)}
        first={first}
        value={formValue}
        updatedFilter={filter}
        defaultFilter={{
          ...hasAdditionalFilter.dirSportingEvents,
          AND: [{ dirCalendar: { type: { id: 2 } } }],
          OR: [
            { clsEventStages_every: { registryNumber_not_in: [100002914] } },
            { clsEventStages_some: { registryNumber_not: 100002914 } },
          ],
        }}
        filterByAccess={filterByRoleAccess}
      />
      <DictionaryWrapper>
        <DataTable
          tableName="sporting_events"
          title={
            <React.Fragment>
              <Grid container spacing={2} alignItems={'center'}>
                <Grid item>
                  <h2>Справочник спортивных мероприятий</h2>
                </Grid>
                {access.includes(2) && <Grid item>{<BtnNew onClick={() => push('/sporting_event/new')} />}</Grid>}
              </Grid>
            </React.Fragment>
          }
          isEvent
          columns={columns}
          lazyQuery={filter?.name_contains ? useSportingEvents2ndLazyQuery : useSportingEventsLazyQuery}
          filter={{
            ...filterByAccessMain,
            ...filter,
            ...hasAdditionalFilter.dirSportingEvents,
            AND: [{ dirCalendar: { type: { id: 2 } } }],
            OR: [
              { clsEventStages_every: { registryNumber_not_in: [100002914] } },
              { clsEventStages_some: { registryNumber_not: 100002914 } },
            ],
          }}
          revealFunctionName={filter?.name_contains ? 'eventsMultiple' : 'events'}
        />
      </DictionaryWrapper>
    </MainTemplate>
  );
};

export default Page;
