import { TReportPath } from '../types';
import { fetchCalendarGeneralReport } from './fetchCalendarGeneralReport';
import { fetchCalendarReport } from './fetchCalendarReport';
import { fetchCalendarReportUniversiad } from './fetchCalendarReportUniversiad';
import { fetchEventCompetitionReport } from './fetchEventCompetitionReport';
import { fetchSportingEventMedalReport } from './fetchSportingEventMedalReport';
import { fetchSportingEventRefereeReport } from './fetchSportingEventRefereeReport';

const reportObj = {
  calendarGeneralReport: fetchCalendarGeneralReport,
  calendarReport: fetchCalendarReport,
  calendarReportUniversiad: fetchCalendarReportUniversiad,
  sportingEventRefereeReport: fetchSportingEventRefereeReport,
  sportingEventMedalReport: fetchSportingEventMedalReport,
  sportingEventCompetitionReport: fetchEventCompetitionReport,
};

type TFunc =
  | typeof fetchCalendarReport
  | typeof fetchCalendarGeneralReport
  | typeof fetchSportingEventRefereeReport
  | typeof fetchCalendarReportUniversiad
  | typeof fetchSportingEventMedalReport
  | typeof fetchEventCompetitionReport;

export default class ReportService {
  async generate(reportServicePath: TReportPath, props: any) {
    try {
      const func: TFunc = reportObj[reportServicePath];
      func && (await func(props));
    } catch (error) {
      return;
    }
  }
}
