import { Grid, Tooltip } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { useTrainersLazyQuery, useTrainersSimilarLazyQuery } from '../../api';
import React, { FC, useCallback, useState } from 'react';
import { getOrganizations } from './helpers';
import { getSettingsUserFilter, getSettingsUserFilterFormValue } from '../../store/selectors/settings';
import { useDispatch, useSelector } from 'react-redux';

import { AppStore } from '../../store/reducers';
import { BtnNew } from '../../components/BtnNew';
import { DataTable } from '../../components/DataTable/NewTable';
import { DictionaryWrapper } from '../../components/DictionaryWrapper';
import { MUIDataTableColumn } from 'mui-datatables';
import { MainTemplate } from '../../components/layouts';
import { SET_USER_SETTINGS_FILTER } from '../../store/constants';
import { TrainersFilter } from '../../components/Filters';
import UniBox from '../../components/DupliBox';
import _ from 'lodash';
import moment from 'moment';
import { usePermission } from '../../hooks/usePermission';
import userRights from '../../config/userRights';

const Page: FC<{}> = () => {
  const { hasAdditionalFilter } = userRights();

  const dispatch = useDispatch();
  const filter: any = useSelector((state: AppStore) => getSettingsUserFilter(state, 'trainers'), _.isEqual);
  const formValue: any = useSelector((state: AppStore) => getSettingsUserFilterFormValue(state, 'trainers'), _.isEqual);

  const [first, setFirst] = useState(0);
  const { push } = useHistory();
  const access = usePermission('directory', 'trainers');

  const setFilter = useCallback(
    (filter, form) => {
      dispatch({
        type: SET_USER_SETTINGS_FILTER,
        payload: {
          name: 'trainers',
          filter: filter,
          form,
        },
      });
    },
    [dispatch],
  );

  const columns: MUIDataTableColumn[] = [
    {
      name: 'dirPerson',
      label: 'ФИО',
      options: {
        filter: false,
        sort: false,
        viewColumns: false,
        customBodyRender: (value: any, meta: any) => {
          const { rowData } = meta;
          const fio = [value?.lastname || '', value?.firstname || '', value?.patronymic || ''].join(' ');
          const id = rowData.slice(-1);
          return (
            <Tooltip title="Перейти в карточку" placement="top-start">
              <Link
                style={{
                  fontWeight: 500,
                  cursor: 'pointer',
                  textDecoration: 'none',
                }}
                to={`/trainer/${id}`}
                target={'_blank'}
              >
                {fio}
              </Link>
            </Tooltip>
          );
        },
      },
    },
    {
      name: 'dirPerson',
      label: 'Дата рождения',
      options: {
        filter: false,
        display: true,
        customBodyRender: (val) => moment(val?.birthday).format('DD.MM.YYYY'),
      },
    },
    {
      name: 'dirPerson',
      label: 'Пол',
      options: {
        filter: false,
        display: true,
        customBodyRender: (val) => {
          return val.isMale ? 'Муж' : 'Жен';
        },
      },
    },
    {
      name: 'dirPerson',
      label: 'Основной регион',
      options: {
        filter: false,
        sort: false,
        display: true,
        customBodyRender: (val) => val?.dirRegion?.label || '',
      },
    },
    {
      name: 'dirPerson',
      label: '',
      options: {
        filter: false,
        display: false,
        viewColumns: false,
        customBodyRender: (val) => val?.dirForeignCity?.label || '',
      },
    },
    {
      name: 'dirPerson',
      label: 'Страна/Город',
      options: {
        filter: false,
        display: true,
        customBodyRender: (value) => {
          if (value?.dirForeignCity) {
            return `${value?.dirCountry.label}/${value?.dirForeignCity?.label}`;
          }
          return value?.dirCountry?.label || '';
        },
      },
    },
    {
      name: 'sports',
      label: 'Вид спорта',
      options: {
        filter: false,
        display: true,
        customBodyRender: (value) => <>{value.map((v: any) => v.dirSport?.fullName).join(', ')}</>,
      },
    },
    {
      name: 'sports',
      label: 'Организация',
      options: {
        filter: false,
        display: true,
        customBodyRender: (value) => {
          return <>{getOrganizations(value)}</>;
        },
      },
    },
    {
      name: 'dirPerson',
      label: 'Реестровый номер',
      options: {
        sort: false,
        display: true,
        customBodyRender: (value) => value?.registryNumber,
      },
    },
    {
      name: 'dirPerson',
      label: ' ',
      options: {
        filter: false,
        display: true,
        sort: false,
        viewColumns: false,
        customBodyRender: (value: any, meta: any) => {
          return value?.archive ? null : <UniBox fio={`${meta.rowData[0] || ''}`} idPerson={value?.id} />;
        },
      },
    },
    {
      name: 'sports',
      label: ' ',
      options: {
        filter: false,
        display: false,
        sort: false,
        viewColumns: false,
        customBodyRender: (value) => null,
      },
    },
    {
      name: 'id',
      label: '',
      options: {
        filter: false,
        display: false,
        sort: false,
        viewColumns: false,
      },
    },
  ];

  return (
    <MainTemplate pageName="Тренеры" url="" title="Тренеры">
      <TrainersFilter
        onSubmit={(filters: any, form: any) => setFilter({ ...filters }, form)}
        value={formValue}
        defaultFilter={{ ...hasAdditionalFilter?.trainers }}
        first={first}
      />
      <DictionaryWrapper>
        <DataTable
          tableName="trainers"
          title={
            <Grid container spacing={2} alignItems={'center'}>
              <Grid item>
                <h2>Справочник тренеров</h2>
              </Grid>
              {access.includes(2) && <Grid item>{<BtnNew onClick={() => push('/trainer/new')} />}</Grid>}
            </Grid>
          }
          columns={columns}
          hasDoubleQuery
          lazyQuery={filter?.name ? useTrainersSimilarLazyQuery : useTrainersLazyQuery}
          filter={{ ...filter, ...hasAdditionalFilter?.trainers }}
          revealFunctionName={filter?.name ? 'trainers' : undefined}
        />
      </DictionaryWrapper>
    </MainTemplate>
  );
};

export default Page;
