import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { DatePicker, Input, TimePicker } from '../../Inputs';
import React, { FC, useState } from 'react';
import {
  Scalars,
  SportingEventDocument,
  UpdateSportingEventMutationHookResult,
  useSportingEventQuery,
  useUpdateSportingEventMutation,
} from '../../../api';

import AddBoxIcon from '@material-ui/icons/AddBox';
import ConfirmDialog from '../../ConfirmDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import DisciplinesMultiselect from '../../../module/DisciplinesMultiSelect';
import EditIcon from '@material-ui/icons/Edit';
import EventProtocolDocuments from './components/EventProtocolDocuments';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SaveIcon from '@material-ui/icons/Save';
import _ from 'lodash';
import getMessage from '../../../messages';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles({
  row: {
    display: 'flex',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  tinyIcon: {
    fontSize: 18,
  },
  icon: {
    fontSize: 20,
  },
  header: {
    fontWeight: 600,
    fontSize: 15,
  },
  accordion: {
    '&::before': {
      backgroundColor: 'unset',
    },
  },
});

interface formState {
  day?: Date;
  time?: string | null;
  members?: string | null;
  discipline?: { label: string; value: any; code?: string };
}

const Calendar: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const classes = useStyles();

  const defaultState: formState = {
    day: undefined,
    time: undefined,
    members: undefined,
    discipline: undefined,
  };

  const { loading, data, refetch } = useSportingEventQuery({ returnPartialData: true, variables: { id } });

  const { handleSubmit, control, errors, reset, formState } = useForm({
    defaultValues: defaultState,
  });
  const [deleteItem, setDeleteItem] = useState<any>(null);
  const [update]: UpdateSportingEventMutationHookResult = useUpdateSportingEventMutation();

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const [competitionCalendar, sports] = [data?.dirSportingEvent?.competitionCalendar, data?.dirSportingEvent?.sports];

  const [calendarId, setCalendarId] = useState<Scalars['UUID']>('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [isAddingOrEditingTime, setIsAddingOrEditingTime] = useState<string>('');

  const onSubmit = async (values: formState) => {
    const { day, time, members, discipline } = values;
    try {
      if (isAddingOrEditingTime === 'isEditing') {
        await update({
          variables: {
            data: {
              competitionCalendar: {
                update: [
                  {
                    where: { id: calendarId },
                    data: {
                      day,
                      time,
                      members,
                      discipline: {
                        connect: {
                          id: discipline?.value,
                        },
                      },
                    },
                  },
                ],
              },
            },
            id,
          },
        });
        setOpenDialog(false);
        handleSnackBar('success', 'Календарь успешно обновлена');
        setCalendarId('');
        refetch();
        return;
      }
      await update({
        variables: {
          data: {
            competitionCalendar: {
              create: [
                {
                  day,
                  time,
                  members,
                  discipline: {
                    connect: {
                      id: discipline?.value,
                    },
                  },
                },
              ],
            },
          },
          id,
        },
      });
      setOpenDialog(false);
      handleSnackBar('success', 'Календарь успешно создано');
      refetch();
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const deleteDayTime = async (calendarId: Scalars['UUID']) => {
    await update({
      variables: {
        data: {
          competitionCalendar: {
            delete: [
              {
                id: calendarId,
              },
            ],
          },
        },
        id,
      },
    });
    setOpenDialog(false);
    refetch();
    handleSnackBar('success', 'Календарь успешно удаленна');
  };

  if (loading) return <LinearProgress />;

  return (
    <div style={{ width: '100%' }}>
      <Grid container justify="space-between">
        <Grid item>
          {sports && sports.length > 0 && !readonly && (
            <div>
              <Tooltip title="добавить" placement="top-start">
                <IconButton
                  color="primary"
                  onClick={() => {
                    setOpenDialog(true);
                    setIsAddingOrEditingTime('');
                  }}
                >
                  <AddBoxIcon />
                </IconButton>
              </Tooltip>
            </div>
          )}
        </Grid>
        <Grid item>
          <EventProtocolDocuments loading={loading} data={data} refetch={refetch} readonly={readonly} id={id} />
        </Grid>
      </Grid>
      {competitionCalendar &&
        competitionCalendar.length > 0 &&
        _.chain(competitionCalendar)
          .groupBy((cCal) => moment(cCal.day).format('DD.MM.YY'))
          .map((tail, head) => ({
            head,
            tail,
          }))
          .value()
          .map(({ head, tail }) => (
            <Accordion key={head} className={classes.accordion}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${head}-content`} id={`${head}-header`}>
                <Typography className={classes.header}>{head}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <TableContainer component={Paper}>
                  <Table aria-label="Calendar table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Время</TableCell>
                        <TableCell>Дисциплина&nbsp;</TableCell>
                        <TableCell>Код дисциплины&nbsp;</TableCell>
                        <TableCell>Участники&nbsp;</TableCell>
                        <TableCell>
                          {!readonly && (
                            <Tooltip title="добавить" placement="top-start">
                              <IconButton
                                color="primary"
                                onClick={() => {
                                  reset({ ...defaultState, day: tail[0]?.day });
                                  setOpenDialog(true);
                                  setIsAddingOrEditingTime('isAddingNew');
                                }}
                              >
                                <AddBoxIcon />
                              </IconButton>
                            </Tooltip>
                          )}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {tail?.length > 0 &&
                        tail.map((cal) => (
                          <TableRow key={cal.id}>
                            <TableCell component="th" scope="row">
                              {moment(cal.time).format('HH:mm')}
                            </TableCell>
                            <TableCell>{cal?.discipline?.label}</TableCell>
                            <TableCell>{cal?.discipline?.code}</TableCell>
                            <TableCell>{cal.members}</TableCell>
                            <TableCell>
                              {!readonly && (
                                <div className={classes.row}>
                                  <Tooltip title="изменить" placement="top-start">
                                    <IconButton
                                      className={classes.btn}
                                      color="primary"
                                      onClick={() => {
                                        setIsAddingOrEditingTime('isEditing');
                                        setCalendarId(cal.id);
                                        reset(cal);
                                        setOpenDialog(true);
                                      }}
                                    >
                                      <EditIcon className={classes.icon} />
                                    </IconButton>
                                  </Tooltip>
                                  <Tooltip title="удалить" placement="top-start">
                                    <IconButton
                                      className={classes.btn}
                                      color="secondary"
                                      onClick={() => setDeleteItem(cal.id)}
                                    >
                                      <DeleteIcon className={classes.icon} />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              )}
                              <ConfirmDialog
                                title={`Удаление соревнования`}
                                text={`Вы уверены что хотите удалить : ${cal?.discipline?.label}`}
                                btnVariant="outlined"
                                open={deleteItem === cal.id}
                                saveBtnText="удалить"
                                cancelBtnText="отмена"
                                saveBtnColor="secondary"
                                cancelBtnColor="primary"
                                onCancel={() => setDeleteItem(null)}
                                onSave={() => {
                                  deleteDayTime(cal?.id).then(() => setDeleteItem(null));
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>
          ))}
      <Dialog
        onClose={() => {
          reset(defaultState);
          setOpenDialog(false);
          setIsAddingOrEditingTime('');
          setCalendarId('');
        }}
        aria-labelledby="add-calendar-dialog"
        open={openDialog}
      >
        <DialogTitle id="add-calendar-dialog">
          {(isAddingOrEditingTime === 'isEditing' && 'Редактировать даты') || 'Новая Дата'}
        </DialogTitle>
        <div style={{ padding: '1.5rem' }}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <Grid container spacing={2}>
              {sports && sports.length > 0 && (
                <Grid item xs={12}>
                  <DisciplinesMultiselect
                    id={sports.map((sport) => sport?.dirSport?.value)}
                    multiple={false}
                    label="Дисциплина"
                    control={control}
                    error={!!errors['discipline']}
                    name="discipline"
                    rules={{ required: true }}
                  />
                </Grid>
              )}
              <Grid item container justify="space-between">
                <Grid item xs={6}>
                  <DatePicker
                    label="Дата"
                    control={control}
                    error={!!errors['day']}
                    name="day"
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TimePicker
                    label="Время"
                    control={control}
                    error={!!errors['time']}
                    name="time"
                    rules={{ required: true }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Input
                  label="Участники"
                  control={control}
                  error={!!errors['members']}
                  name="members"
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item container justify="flex-end">
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  startIcon={<SaveIcon />}
                  type="submit"
                  disabled={!formState.isDirty}
                >
                  Сохранить
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Dialog>
    </div>
  );
};

export default Calendar;
