import React, { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import {
  FireTrainerMutationHookResult,
  FireTrainerWithAthleteAssignToAnotherTrainerMutationHookResult,
  FireTrainerWithAthleteExpelMutationHookResult,
  FireTrainerWithAthleteSelfTrainMutationHookResult,
  useAthletesCountByTrainerIdInOrganizationQuery,
  useFireTrainerMutation,
  useFireTrainerWithAthleteAssignToAnotherTrainerMutation,
  useFireTrainerWithAthleteExpelMutation,
  useFireTrainerWithAthleteSelfTrainMutation,
} from '../../api';
import { useForm } from 'react-hook-form';
import PersonsModule from '../../module/Persons';
import { DatePicker } from '../Inputs';
import Radios from '../Inputs/Radio';
import { Dialog, DialogActions, DialogContent } from '@material-ui/core';
import moment from 'moment';

type FormProps = {
  fromTrainerID: string;
  toTrainerID: string;
  organizationID: string;
  dismissalDate: string;
  firedTarget: string | null;
  trainer: { value: string; label: string };
};

const FireTrainer: FC<{
  orgId: string;
  sportOrgId: string;
  onSubmit: () => void;
  trainerId: string;
  idSport: string;
  onClose: () => void;
}> = ({ sportOrgId, orgId, onSubmit, trainerId, idSport, onClose }) => {
  const { handleSubmit, watch, control, errors, formState } = useForm({
    defaultValues: {
      dismissalDate: moment().format('MM.DD.YYYY'),
      firedTarget: null,
    } as FormProps,
  });

  const { data = { count: 0 } } = useAthletesCountByTrainerIdInOrganizationQuery({
    variables: { id: trainerId, orgID: orgId },
  });
  const [
    fireTrainerWithAthleteExpel,
  ]: FireTrainerWithAthleteExpelMutationHookResult = useFireTrainerWithAthleteExpelMutation();
  const [
    fireTrainerWithAthleteSelfTrain,
  ]: FireTrainerWithAthleteSelfTrainMutationHookResult = useFireTrainerWithAthleteSelfTrainMutation();
  const [
    fireTrainerWithAthleteAssignToAnotherTrainer,
  ]: FireTrainerWithAthleteAssignToAnotherTrainerMutationHookResult = useFireTrainerWithAthleteAssignToAnotherTrainerMutation();
  const [fireTrainer]: FireTrainerMutationHookResult = useFireTrainerMutation();
  const watchTargetFire = watch('firedTarget');

  const sentRequest = async (values: FormProps) => {
    if (values.firedTarget === 'trainerDismissalWithAthletesSelfTraining') {
      await fireTrainerWithAthleteSelfTrain({
        variables: {
          trainerID: trainerId,
          organizationID: orgId,
          dismissalDate: moment(values.dismissalDate).format('YYYY-MM-DD'),
        },
      });
      onSubmit();
    }
    if (values.firedTarget === 'trainerDismissalWithAthletesExpel') {
      await fireTrainerWithAthleteExpel({
        variables: {
          trainerID: trainerId,
          organizationID: orgId,
          dismissalDate: moment(values.dismissalDate).format('YYYY-MM-DD'),
        },
      });
      onSubmit();
    }
    if (values.firedTarget === 'trainerDismissalWithAthletesAssignToAnotherTrainer') {
      await fireTrainerWithAthleteAssignToAnotherTrainer({
        variables: {
          fromTrainerID: trainerId,
          toTrainerID: values.trainer.value,
          organizationID: orgId,
          dismissalDate: moment(values.dismissalDate).format('YYYY-MM-DD'),
        },
      });
      onSubmit();
    }
    if (!values.firedTarget) {
      await fireTrainer({
        variables: {
          id: sportOrgId,
          dismissalDate: moment(values.dismissalDate).format('YYYY-MM-DD'),
        },
      });
      onSubmit();
    }
  };

  return (
    <form>
      <Dialog open={true} aria-labelledby="form-dialog-title">
        <DialogContent>
          <Grid container direction="column" spacing={1}>
            {data?.count > 0 && (
              <Grid item>
                <Radios
                  label="Увольнение Тренера"
                  data={[
                    {
                      value: 'trainerDismissalWithAthletesSelfTraining',
                      label: 'Увольнение Тренера и перевод Спортсменов на самоподготовку',
                    },
                    {
                      value: 'trainerDismissalWithAthletesExpel',
                      label: 'Увольнение Тренера и отчисление Спортсменов',
                    },
                    {
                      value: 'trainerDismissalWithAthletesAssignToAnotherTrainer',
                      label: 'Увольнение Тренера и перевод Спортсменов другому Тренеру',
                    },
                  ]}
                  control={control}
                  error={!!errors['firedTarget']}
                  errorMessage={errors['firedTarget']?.message || ''}
                  name="firedTarget"
                  rules={{
                    required: true,
                    validate: (val) => {
                      return val === null ? 'Выберите значение' : undefined;
                    },
                  }}
                />
              </Grid>
            )}
            {watchTargetFire === 'trainerDismissalWithAthletesAssignToAnotherTrainer' && (
              <Grid item>
                <PersonsModule
                  label="Тренер"
                  error={!!errors['trainer']}
                  name="trainer"
                  control={control}
                  getterValue={(val: any) => {
                    return val?.trainerProfile?.id;
                  }}
                  filter={{
                    trainerProfile: {
                      id_not: trainerId,
                      sports_some: {
                        dirSport: { id: idSport },
                        organizations_some: { dirOrganization: { id: orgId }, dismissalDate: null },
                      },
                    },
                  }}
                  rules={{
                    required: true,
                    validate: (val) => {
                      return val === null ? 'Выберите значение' : undefined;
                    },
                  }}
                />
              </Grid>
            )}
            <Grid item md={12}>
              <DatePicker
                name="dismissalDate"
                label="Дата увольнения"
                control={control}
                error={!!errors['dismissalDate']}
                rules={{ required: true }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button size="small" onClick={onClose} type="button">
            Закрыть
          </Button>
          <Button size="small" variant="outlined" onClick={handleSubmit(sentRequest)} color="primary">
            Уволить
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};
export default FireTrainer;
