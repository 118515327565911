import { Button, CircularProgress, Dialog, DialogContent, DialogTitle, Grid, Link } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { Cancel, Save as SaveIcon } from '@material-ui/icons';

import { useForm } from 'react-hook-form';
import ReportService from './services';
import { TBtnReportProps, TFiltersPath } from './types';
import IC from '../Inputs/IC';
import FilterService from './services/filters';

const BtnReport: FC<TBtnReportProps> = (props) => {
  const reportService = new ReportService();
  const filterService = new FilterService();

  const {
    label,
    lazyQuery,
    filter,
    serviceApi,
    formProps,
    withDialog,
    dialogTitle,
    fields,
    isLink,
    otherProps,
  } = props;

  const [isGeneratingFile, setIsGeneratingFile] = useState(false);
  const [open, setOpen] = useState(false);
  const [isDownloaded, setIsDownloaded] = useState(false);

  const [fetch, { data: reportData, loading: reportDataLoading }] = lazyQuery({
    fetchPolicy: 'no-cache',
  });

  const { handleSubmit, errors, control } = useForm<any>(formProps);

  const handleClose = () => {
    setOpen(false);
    setIsGeneratingFile(false);
  };

  const onSubmit = async (value: any) => {
    const apiFilter = await filterService.generate(serviceApi as TFiltersPath, {
      ...(filter?.api || {}),
      ...value,
    });

    apiFilter &&
      (await fetch({
        variables: {
          filter: apiFilter,
        },
      }));

    setOpen(false);
  };

  const onFileData = async () => {
    setIsDownloaded(false);
    setIsGeneratingFile(true);
    if (withDialog) {
      setOpen(true);
    } else {
      await fetch({
        variables: {
          ...(filter?.api && { filter: filter?.api }),
        },
      });
    }
  };

  const onDownloadFile = async () => {
    const data = Object.values(reportData)[0];

    await reportService.generate(serviceApi, {
      data,
      ...props,
      ...otherProps,
    });
    setIsDownloaded(true);
    setIsGeneratingFile(false);
  };

  useEffect(() => {
    if (!reportDataLoading && reportData && !isDownloaded) {
      onDownloadFile();
    }
  }, [reportData, reportDataLoading, isDownloaded]);

  return (
    <>
      <Button
        disabled={isGeneratingFile}
        size="small"
        variant={isLink ? 'text' : 'outlined'}
        {...(isLink && {
          style: { textTransform: 'capitalize', background: 'none', textDecoration: 'underline', cursor: 'pointer' },
        })}
        color="primary"
        onClick={onFileData}
      >
        {label}
        {isGeneratingFile && <CircularProgress style={{ marginLeft: 10 }} size={20} />}
      </Button>

      {withDialog && (
        <Dialog onClose={handleClose} open={open}>
          <DialogTitle>{dialogTitle}</DialogTitle>
          <DialogContent>
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
              <Grid container spacing={1}>
                {fields &&
                  fields.map((field) => (
                    <Grid item md={12} key={field.id}>
                      <IC {...field} control={control} error={!!errors[field.name]} />
                    </Grid>
                  ))}

                <Grid item container justify="space-between" style={{ marginTop: 15, marginBottom: 15 }}>
                  <Button
                    variant="outlined"
                    color="secondary"
                    type="button"
                    size="small"
                    startIcon={<Cancel color="secondary" />}
                    onClick={handleClose}
                  >
                    Отменить
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    type="submit"
                    size="small"
                    startIcon={<SaveIcon color="primary" />}
                  >
                    Выгрузить
                  </Button>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default BtnReport;
