import { useUpdateAthleteSportMutation } from '../../../../api';
import { useForm } from 'react-hook-form';
import React, { FC, useState } from 'react';
import { Button, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import getMessage from '../../../../messages';
import { useSnackbar } from 'notistack';
import SaveIcon from '@material-ui/icons/Save';
import RemoveIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import PersonsModule from '../../../../module/Persons';
import ConfirmDialog from '../../../ConfirmDialog';

const PersonalTrainerBox: FC<{ readonly?: boolean; sport: any; onUpdate: Function }> = ({
  readonly,
  sport,
  onUpdate,
}) => {
  const { handleSubmit, control, errors, reset, formState } = useForm({
    defaultValues: { typeSport: null, trainer: null } as {
      typeSport: null | { value: string; label: string; valSport: string };
      trainer: null | { value: string; label: string };
    },
  });

  const [deleteItem, setDeleteItem] = useState<any>(null);

  const { enqueueSnackbar } = useSnackbar();

  const [remove]: any = useUpdateAthleteSportMutation();
  const [update]: any = useUpdateAthleteSportMutation();

  const onRemove = async (idSport: string, idTrainer: string) => {
    try {
      await remove({
        variables: {
          id: idSport,
          data: { dirTrainers: { disconnect: { id: idTrainer } } },
        },
      });
      enqueueSnackbar(`Тренер успешно удален`, { variant: 'success' });
      onUpdate();
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  const onSubmit = handleSubmit(async (values) => {
    try {
      await update({
        variables: {
          id: sport?.id,
          data: { dirTrainers: { connect: { id: values?.trainer?.value } } },
        },
      });

      enqueueSnackbar(`Тренер успешно добавлен`, {
        variant: 'success',
      });
      reset({ typeSport: null, trainer: null });
      onUpdate();
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  });

  return (
    <Grid container>
      {!readonly && (
        <form onSubmit={onSubmit} style={{ minWidth: '100%' }}>
          <Grid item container spacing={2} alignItems={'flex-end'}>
            <Grid item md={9}>
              <PersonsModule
                label="Тренер"
                error={!!errors['trainer']}
                name="trainer"
                control={control}
                getterValue={(val: any) => {
                  return val?.trainerProfile?.id;
                }}
                filter={{
                  trainerProfile: {
                    sports_some: {
                      dirSport: { id: sport?.dirSport?.value },
                    },
                  },
                }}
              />
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                type="submit"
                size="small"
                startIcon={<SaveIcon color="primary" />}
                disabled={!formState.isDirty}
              >
                Сохранить
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
      <Grid item xs={12}>
        <List>
          {sport?.dirTrainers?.map((item: any) => (
            <ListItem key={item.id} alignItems="flex-start">
              <ListItemText
                primary={`Тренер: ${item.dirPerson.lastname} ${item.dirPerson.firstname} 
                            ${item.dirPerson?.patronymic || ''}
                            ${moment(item.dirPerson.birthday).format('DD.MM.YYYY')}`}
              />
              {!readonly && (
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="редактировать" onClick={() => setDeleteItem(sport.id)}>
                    <RemoveIcon color="error" />
                  </IconButton>
                  <ConfirmDialog
                    title={`Удаление тренера`}
                    text={`Вы уверены что хотите удалить тренера?`}
                    btnVariant="outlined"
                    open={deleteItem === sport.id}
                    saveBtnText="да"
                    cancelBtnText="отмена"
                    saveBtnColor="secondary"
                    cancelBtnColor="primary"
                    onCancel={() => setDeleteItem(null)}
                    onSave={() => {
                      onRemove(sport.id, item.id).then(() => setDeleteItem(null));
                    }}
                  />
                </ListItemSecondaryAction>
              )}
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default PersonalTrainerBox;
