import React, { useState, useCallback, useEffect, useMemo } from 'react';
import {
  DirCalendarWhereInput,
  useEventListByPeriodQuery,
  CalendarEventByPeriodFragment,
  useEventListByPeriodLazyQuery,
  useEventListByMonthLazyQuery,
} from '../../api';
import { Button, Grid, LinearProgress, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Calendar } from './Calendar';
import { Card } from './Card';
import { fetchCalendarGeneralReport } from '../BtnReport/services/fetchCalendarGeneralReport';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import _ from 'lodash';
import moment from 'moment';
import { sortByPeriod } from './helpers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 5,
      position: 'fixed',
      bottom: 0,
      top: 210,
      left: 245,
      right: 0,
    },
    root_main: {
      padding: 5,
      position: 'absolute',
      bottom: 0,
      top: 60,
      left: '33%',
      right: 25,
    },
    list: {
      padding: 5,
      height: '100%',
      maxHeight: '100%',
      overflowY: 'auto',
    },
    list_main: {
      padding: 5,
      height: '100%',
      maxHeight: '100%',
      overflowY: 'auto',
    },
    load: {
      height: 400,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    popBox: {
      width: 5,
      height: 5,
      borderRadius: '50%',
      color: 'white',
      backgroundColor: 'blue',
      position: 'relative',
      opacity: 0.8,
      bottom: '13%',
      left: '60%',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 0,
      '& span': {
        fontSize: 10,
        fontweight: 'bold',
        fontFamily: 'monospace',
      },
    },
  }),
);

type CalendarEventListModalProps = {
  filter?: DirCalendarWhereInput;
  year?: number;
  isMain?: boolean;
};

export const CalendarEventListModal = ({ filter = {}, year = 2024, isMain }: CalendarEventListModalProps) => {
  const classes = useStyles();
  const [value, setValue] = useState<any>();
  const [monthPeriod, setMonthPeriod] = useState<any>();
  const [skip, setSkip] = useState(0);
  const [items, setItems] = useState<CalendarEventByPeriodFragment[]>([]);
  const [hasNextPage, setHasNextPage] = useState(true);

  const zeroFilter = {
    archive: null,
    OR: [
      { clsEventStages_every: { registryNumber_not_in: [100002914] } },
      { clsEventStages_some: { registryNumber_not: 100002914 } },
    ],
  };
  // const [first, setFirst] = useState(0);

  const { data, loading, error } = useEventListByPeriodQuery({
    skip: !value,
    variables: {
      periodFilter: {
        ...(value?.length === 1
          ? {
              endDate_gte: moment(value?.[0]).add(1, 'day').format('YYYY-MM-DDT00:00:00.000Z'),
              startDate_lte: moment(value?.[0]).add(1, 'day').format('YYYY-MM-DDT23:59:59.000Z'),
              dirCalendar: {
                ...filter,
              },
              ...zeroFilter,
            }
          : value?.length === 2
          ? {
              endDate_gte: moment(value?.[0]).add(1, 'day').format('YYYY-MM-DDT00:00:00.000Z'),
              startDate_lte: moment(value?.[1]).add(1, 'day').format('YYYY-MM-DDT23:59:59.000Z'),
              dirCalendar: {
                ...filter,
              },
              ...zeroFilter,
            }
          : {}),
      },
      first: 20,
      skip,
    },
    fetchPolicy: 'no-cache',
  });
  const [fetch, { data: fullData, loading: fullDataLoading }] = useEventListByPeriodLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const [fetchMonthEvents, { data: eventMonthData, loading: eventMonthLoading }] = useEventListByMonthLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const onLoadMore = () => {
    setSkip((prevProps) => prevProps + 20);
  };

  useEffect(() => {
    if (!loading && data) {
      if (data?.dirSportingEvents?.length > 0) {
        const result =
          value?.length === 1 ? data?.dirSportingEvents : _.uniqBy([...items, ...data?.dirSportingEvents], 'id');
        setItems(result.sort(sortByPeriod));

        data?.dirSportingEvents?.length > 19 && setHasNextPage(true);
      } else {
        value?.length === 1 && setItems([]);
        setHasNextPage(false);
      }
    }
  }, [data, loading, value]);

  useEffect(() => {
    if (error && error.message) {
      setItems([]);
    }
  }, [error]);

  useEffect(() => {
    if (!fullDataLoading && fullData?.dirSportingEvents && fullData?.dirSportingEvents?.length > 0) {
      fetchCalendarGeneralReport({
        data: fullData?.dirSportingEvents?.sort(sortByPeriod),
        fileName: 'Календарь соревнований',
      });
    }
  }, [fullData, fullDataLoading]);

  useEffect(() => {
    setItems([]);
  }, [filter]);

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore,
    disabled: !!error,
    rootMargin: '0px 0px 400px 0px',
  });

  useEffect(() => {
    fetchMonthEvents({
      variables: {
        start: moment(monthPeriod?.[0]).add(1, 'day').startOf('month').format('YYYY-MM-DDT00:00:00.000Z'),
        end: moment(monthPeriod?.[1]).endOf('month').format('YYYY-MM-DDT23:59:59.000Z'),
        calendarFilter: filter,
      },
    });
  }, [monthPeriod, filter, fetchMonthEvents]);

  const onChange = useCallback(
    (value) => {
      setItems([]);
      setValue(value);
    },
    [setValue],
  );

  const onSetMonthPeriod = useCallback(
    (newValue) => {
      setMonthPeriod(newValue);
    },
    [setMonthPeriod],
  );

  const onGenerateReport = async () => {
    fetch({
      variables: {
        periodFilter: {
          ...(value?.length === 1
            ? {
                OR: [
                  {
                    startDate_gte: moment(value?.[0]).startOf('month').format('YYYY-MM-DDT00:00:00.000Z'),
                    startDate_lte: moment(value?.[0]).add(1, 'day').format('YYYY-MM-DDT23:59:59.000Z'),
                    endDate_gte: moment(value?.[0]).add(1, 'day').format('YYYY-MM-DDT00:00:00.000Z'),
                    endDate_lte: moment(value?.[0]).endOf('month').format('YYYY-MM-DDT23:59:59.000Z'),
                    dirCalendar: {
                      ...filter,
                    },
                    ...zeroFilter,
                  },
                  {
                    startDate_gte: moment(value?.[0]).add(1, 'day').format('YYYY-MM-DDT00:00:00.000Z'),
                    startDate_lte: moment(value?.[0]).add(1, 'day').format('YYYY-MM-DDT23:59:59.000Z'),
                    dirCalendar: {
                      ...filter,
                    },
                    ...zeroFilter,
                  },
                ],
              }
            : value?.length === 2
            ? {
                startDate_gte: moment(value?.[0]).add(1, 'day').format('YYYY-MM-DDT00:00:00.000Z'),
                startDate_lte: moment(value?.[1]).add(1, 'day').format('YYYY-MM-DDT23:59:59.000Z'),
                dirCalendar: {
                  ...filter,
                },
                ...zeroFilter,
              }
            : {}),
        },
      },
    });
  };

  const getDayEvents = (day: Date) => {
    const isBin =
      _.find(
        eventMonthData?.dirSportingEvents,
        (event) =>
          moment(day).add(1, 'day').format('DD-MM-YYYY') === moment(event?.startDate).format('DD-MM-YYYY') ||
          moment(day).add(1, 'day').format('DD-MM-YYYY') === moment(event?.endDate).format('DD-MM-YYYY') ||
          moment(day).isBetween(event?.startDate, event?.endDate),
      ) !== undefined;
    return isBin ? <div className={classes.popBox} /> : null;
  };

  return (
    <div className={isMain ? classes.root_main : classes.root}>
      <Grid container style={{ height: '100%', maxHeight: '100%', flexWrap: 'nowrap', overflowY: 'auto' }} spacing={1}>
        <Grid item>
          <Calendar
            isMain={isMain}
            onSelect={onChange}
            onChangeMonth={onSetMonthPeriod}
            cellPop={getDayEvents}
            year={year}
            BTComponent={
              <Button
                disabled={items?.length === 0}
                color="primary"
                variant="outlined"
                size="small"
                endIcon={<GetAppIcon />}
                onClick={onGenerateReport}
              >
                скачать
              </Button>
            }
          />
        </Grid>

        <Grid item style={{ flex: 1 }}>
          <div ref={rootRef} className={isMain ? classes.list_main : classes.list}>
            {items.length === 0 && (
              <Typography style={{ fontWeight: 500, fontSize: 20, textAlign: 'center', marginTop: '50%' }}>
                Нет данных...
              </Typography>
            )}
            {items?.map((event) => (
              <Card key={event?.id} event={event} />
            ))}
            {items?.length > 19 && (loading || hasNextPage) && (
              <div ref={sentryRef}>
                <LinearProgress />
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
