import React, { FC, useState } from 'react';
import {
  Link,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  TableBody,
  Table,
  TableCell,
  TableHead,
  TableContainer,
  TableRow,
  Paper,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

const useStyles = makeStyles({
  title: {
    width: 130,
    fontWeight: 600,
    display: 'inline-block',
  },
});

const StatusBoard: FC<{ statusArray: any }> = ({ statusArray = [] }) => {
  const classes = useStyles();

  const DOWNLOAD_LINK = process.env.REACT_APP_BACKEND_URI?.replace('/graphql', '');
  const [openDialog, setOpenDialog] = useState(false);
  const [currentUser, setCurrentUser] = useState<any | null>(null);

  return (
    <div style={{ marginTop: 20 }}>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="request-board-content"
          id="request-board-header"
        >
          <Typography style={{ fontWeight: 600 }}>История изменений статуса</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer component={Paper}>
            <Table aria-label=" status table">
              <TableHead>
                <TableRow>
                  <TableCell>Дата и время</TableCell>
                  <TableCell>Статус</TableCell>
                  <TableCell>Оператор</TableCell>
                  <TableCell>Комментарий</TableCell>
                  <TableCell>Документ</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {statusArray?.map((status: any) => {
                  return (
                    <TableRow key={status.id}>
                      <TableCell component="th" scope="row">
                        {moment(status.createdAt).format('DD.MM.YYYY HH:mm:ss')}
                      </TableCell>
                      <TableCell>{status?.value?.name}</TableCell>
                      <TableCell>
                        <Button
                          color="primary"
                          style={{ textTransform: 'capitalize' }}
                          onClick={() => {
                            status?.createdBy && setCurrentUser(status?.createdBy);
                            status?.updatedBy && setCurrentUser(status?.updatedBy);
                            setOpenDialog(true);
                          }}
                        >
                          {`${status?.updatedBy?.regular?.lastname || status?.createdBy?.regular?.lastname || ''} ${
                            status?.updatedBy?.regular?.firstname || status?.createdBy?.regular?.lastname || ''
                          } ${status?.updatedBy?.regular?.patronymic || status?.createdBy?.regular?.lastname || ''}`}
                        </Button>
                      </TableCell>
                      <TableCell>{status?.comment}</TableCell>
                      <TableCell>
                        <Link href={`${DOWNLOAD_LINK}${status?.file?.path}`} style={{ wordBreak: 'break-all' }}>
                          {status?.file?.name}
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>

      <Dialog
        onClose={() => {
          setOpenDialog(false);
          setCurrentUser(null);
        }}
        aria-labelledby="user-dialog"
        open={openDialog}
      >
        <DialogTitle id="user-dialog">Оператор</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item container>
              <Typography>
                <span className={classes.title}>ФИО:</span>
                {`${currentUser?.regular?.lastname || ''} ${currentUser?.regular?.firstname || ''} ${
                  currentUser?.regular?.patronymic || ''
                }`}
              </Typography>
            </Grid>

            {currentUser?.regular?.dirOrganization && (
              <Grid item container>
                <Typography>
                  <span className={classes.title}>Организация:</span>
                  {`${currentUser?.regular?.dirOrganization?.name}, ${currentUser?.regular?.dirOrganization?.legalAddress}`}
                </Typography>
              </Grid>
            )}

            <Grid item container>
              <Typography>
                <span className={classes.title}>E-mail:</span>
                {currentUser?.email}
              </Typography>
            </Grid>

            <Grid item container>
              <Typography>
                <span className={classes.title}>Телефон:</span>
                {`${currentUser?.regular?.phone || '~'}`}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setOpenDialog(false);
              setCurrentUser(null);
            }}
          >
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default StatusBoard;
