import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  Tooltip,
} from '@material-ui/core';
import { DatePicker, Input } from '../../Inputs';
import React, { FC, useState } from 'react';
import {
  Scalars,
  SportingEventDocument,
  UpdateSportingEventMutationHookResult,
  UploadFileMutationHookResult,
  useClsDocumentTypesQuery,
  useSportingEventDocumentsListQuery,
  useUpdateSportingEventMutation,
  useUploadFileMutation,
} from '../../../api';

import ConfirmDialog from '../../ConfirmDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import DocumentSelect from '../../../module/Documents';
import DocumentTypes from '../../../module/DocumentTypes';
import { DropzoneArea } from 'material-ui-dropzone';
import SaveIcon from '@material-ui/icons/Save';
import _ from 'lodash';
import LoadingWall from '../../LoadingWall/LoadingWall';
import getMessage from '../../../messages';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { downloadB64File } from './helpers/onDownload';
import userRights from '../../../config/userRights';

const useStyles = makeStyles({
  title: {
    fontWeight: 500,
  },
  link: {
    cursor: 'pointer',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  icon: {
    fontSize: 20,
  },
  verticalSpaceMarge: {
    marginBottom: 35,
    padding: 10,
  },
  dropZone: {
    minHeight: 30,
    border: 0,
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    borderRadius: 0,

    '& > .MuiDropzonePreviewList-root': {
      margin: '0 !important',
      width: '100%',
      '& > .MuiDropzonePreviewList-imageContainer': {
        flexBasis: 'unset',
        width: '100%',
        maxWidth: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 15,
        '& > svg': {
          height: '40px',
        },
        '& > p': {
          marginLeft: 15,
          marginRight: 15,
        },
        '& > button': {
          top: 'unset',
          right: 0,
          position: 'unset',
          marginLeft: 30,

          color: '#d32f2f',
          boxShadow: 'unset',
        },
      },
    },
    '& > .MuiDropzoneArea-textContainer': {
      display: 'flex',

      '& > .MuiDropzoneArea-text': {
        margin: 0,
        fontSize: 15,
      },
      '& > .MuiDropzoneArea-icon': {
        width: 20,
        height: 20,
        marginLeft: 'auto',
      },
    },
    '&:focus': {
      outline: 0,
      border: 0,
      borderBotom: '2px solid #3f51b5',
    },
  },
});

const docTypes = {
  competitionReglaments: 'Положение/регламент соревнования',
  competitionProtocols: 'Протоколы соревнований',
  commissionAdmissionDecisions: 'Решение комиссии по допуску:',
  competitionFinalProtocols: 'Итоговый протокол соревнований:',
  mainJuryReports: 'Отчет главной судейской коллегии:',
};

const docRNumbers = {
  competitionReglaments: 100003144,
  competitionProtocols: 100003151,
  commissionAdmissionDecisions: 100003409,
  competitionFinalProtocols: 100003417,
  mainJuryReports: 100003425,
};

interface formState {
  title?: string;
  date?: Date;
  number?: string;
  clsType?: { label: string; value: string };
}

interface lightFormState {
  dirDocument?: { label: string; value: Scalars['UUID'] };
}

export const DocumentComponent: FC<{
  readonly?: boolean;
  id?: string;
  docKey: string;
  docRNumber: Number;
  docName: string;
  withSports?: boolean;
  loading: any;
  data: any;
  refetch: any;
  isProtocol?: boolean;
}> = ({ data, loading, refetch, readonly, id, docKey, docName, withSports, docRNumber, isProtocol }) => {
  const DOWNLOAD_LINK = process.env.REACT_APP_BACKEND_URI?.replace('/graphql', '');

  const defaultNewDocumentState: formState = {
    title: undefined,
    date: undefined,
    number: undefined,
    clsType: undefined,
  };

  const { data: { classifierValues: docTypes = [] } = { classifierValues: [] } } = useClsDocumentTypesQuery();

  const { handleSubmit, control, errors, reset, formState, setValue } = useForm({
    defaultValues: defaultNewDocumentState,
  });

  const {
    handleSubmit: documentFormHandleSubmit,
    control: documentFormControl,
    errors: documentFormErrors,
    reset: documentFormReset,
    formState: documentFormState,
  } = useForm({
    defaultValues: {
      dirDocument: undefined,
    },
  });

  const {
    handleSubmit: dateFormSubmit,
    control: dateFormControl,
    errors: dateFormErrors,
    formState: dateFormState,
  } = useForm();

  const onOpenDialog = () => {
    if (docTypes.length > 0 && docRNumber) {
      const t = docTypes.find((d) => d.registryNumber === docRNumber);
      if (t)
        reset({
          clsType: t,
        });
    }
    setIsAddingNEwDocumentFromSystem(true);
  };

  const [updateEventData]: UpdateSportingEventMutationHookResult = useUpdateSportingEventMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: SportingEventDocument,
        data: {
          ...data?.updateDirSportingEvent,
        },
      });
    },
  });

  const [uploadDocumentFile]: UploadFileMutationHookResult = useUploadFileMutation();
  const [deleteItem, setDeleteItem] = useState<any>(null);

  const items = _.get(data, `dirSportingEvent.${docKey}`, []);

  const [isAddingNewDocument, setIsAddingNewDocument] = useState(false);
  const [isAddingNEwDocumentFromSystem, setIsAddingNEwDocumentFromSystem] = useState(false);
  const [file, setFile] = useState<Array<File>>([]);
  const [openDateDialog, setOpenDateDialog] = useState(false);
  const [isGeneratingDoc, setIsGeneratingDoc] = useState(false);
  const [currentSportId, setCurrentSportId] = useState('');

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const addNewDocument = async (values: lightFormState) => {
    const { dirDocument } = values;
    try {
      if (isAddingNewDocument && dirDocument) {
        await updateEventData({
          variables: {
            data: {
              [docKey]: {
                connect: [
                  {
                    id: dirDocument?.value,
                  },
                ],
              },
            },
            id,
          },
        });
        handleSnackBar('success', 'документ успешно добавлен');
        documentFormReset();
        setIsAddingNewDocument(false);
        refetch();
      }
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const onDateFormSubmit = async (values) => {
    if (values.date && currentSportId) {
      setOpenDateDialog(false);
      setIsGeneratingDoc(true);
      await downloadB64File(
        `${DOWNLOAD_LINK}/reports/sporting-event-participants-with-date/${id}/${currentSportId}/${moment(
          values.date,
        ).format('YYYY-MM-DD')}`,
      );
      setIsGeneratingDoc(false);
      setCurrentSportId('');
    }
  };

  const handleAddNewDocumentFromSystem = async (values: formState) => {
    const { title, date, number, clsType } = values;
    try {
      if (isAddingNEwDocumentFromSystem && title && date && file) {
        uploadDocumentFile({
          variables: {
            file: file[0],
          },
        }).then(async (resp: any) => {
          const {
            uploadFile: { id: fileId },
          } = resp.data;
          await updateEventData({
            variables: {
              data: {
                [docKey]: {
                  create: [
                    {
                      title,
                      date,
                      number,
                      clsType: clsType && { connect: { id: clsType?.value } },
                      file: {
                        connect: { id: fileId },
                      },
                    },
                  ],
                },
              },
              id,
            },
          });
          handleSnackBar('success', 'документ успешно добавлен');
          setIsAddingNEwDocumentFromSystem(false);
          reset(defaultNewDocumentState);
          refetch();
        });
      }
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const deleteDocument = async (documentId: Scalars['UUID']) => {
    try {
      await updateEventData({
        variables: {
          data: {
            [docKey]: {
              disconnect: [
                {
                  id: documentId,
                },
              ],
            },
          },
          id,
        },
      });
      handleSnackBar('success', 'документ успешно удалён');
      refetch();
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };
  const classes = useStyles();

  if (loading && withSports) return <LinearProgress color="primary" variant="query" />;

  return (
    <div>
      <Grid container spacing={1}>
        {withSports &&
          data?.dirSportingEvent?.sports?.map((item: any) => (
            <Grid item key={item.id}>
              <Button
                onClick={() => {
                  item?.dirSport?.value && setCurrentSportId(item?.dirSport?.value);
                  setOpenDateDialog(true);
                }}
                size="small"
                variant="outlined"
                color="primary"
              >
                Выгрузка информации по участникам (решение комиссии по допуску)({item.dirSport.label})
              </Button>
            </Grid>
          ))}
      </Grid>

      <Grid container className={classes.verticalSpaceMarge}>
        <Grid item container alignItems="center" spacing={2}>
          <Grid item lg={4} md={6} sm={12}>
            <span className={classes.title}>{docName}&nbsp;</span>
          </Grid>
          {!readonly && (
            <Grid item container spacing={2} alignItems="center" lg={6} md={6} sm={12} xs={12}>
              {isAddingNewDocument ? (
                <React.Fragment>
                  <form onSubmit={documentFormHandleSubmit(addNewDocument)} style={{ width: '100%' }}>
                    <Grid item container spacing={2} alignItems="flex-end">
                      <Grid item md={5} xs={12}>
                        <DocumentSelect
                          filter={{
                            id_not_in: items?.map((item: any) => item.id),
                          }}
                          label="Документ"
                          control={documentFormControl}
                          error={!!documentFormErrors['dirDocument']}
                          name="dirDocument"
                          rules={{ required: true }}
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          style={{ marginRight: '0.5rem' }}
                          startIcon={<SaveIcon />}
                          type="submit"
                          disabled={!documentFormState.isDirty}
                        >
                          Сохранить
                        </Button>

                        <Button
                          variant="outlined"
                          color="secondary"
                          style={{ marginRight: '0.5rem' }}
                          size="small"
                          onClick={() => {
                            setIsAddingNewDocument(false);
                            documentFormReset();
                          }}
                        >
                          Отменить
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Grid item>
                    <span style={{ marginRight: '0.5rem' }}>Добавить документ</span>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ marginRight: '0.5rem' }}
                      size="small"
                      type="button"
                      onClick={() => setIsAddingNewDocument(true)}
                    >
                      Из базы
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ marginRight: '0.5rem' }}
                      size="small"
                      type="button"
                      onClick={onOpenDialog}
                      disabled={isAddingNewDocument}
                    >
                      Новый
                    </Button>
                  </Grid>
                </React.Fragment>
              )}
            </Grid>
          )}
        </Grid>
        {items?.map((item: any) => (
          <>
            <Grid item container alignItems="center" spacing={2} key={item?.id}>
              <Grid item>
                <Link href={`${DOWNLOAD_LINK}${item?.file.path}`} className={classes.link}>
                  {item?.title}
                </Link>
              </Grid>
              <Grid item>
                <span>{item?.date && moment(item?.date).format('DD.MM.YYYY')}</span>
              </Grid>
              <Grid item>{item?.number}</Grid>
              <Grid item>{item?.clsType?.label}</Grid>
              {!readonly && (
                <Grid item>
                  <Tooltip title="удалить" placement="top-start">
                    <IconButton className={classes.btn} color="secondary" onClick={() => setDeleteItem(item?.id)}>
                      <DeleteIcon className={classes.icon} />
                    </IconButton>
                  </Tooltip>
                  <ConfirmDialog
                    title={`Удаление спорта`}
                    text={`Вы уверены что хотите удалить документ от :  
                      ${moment(item?.date).format('DD.MM.YYYY')}`}
                    btnVariant="outlined"
                    open={deleteItem === item?.id}
                    saveBtnText="удалить"
                    cancelBtnText="отмена"
                    saveBtnColor="secondary"
                    cancelBtnColor="primary"
                    onCancel={() => setDeleteItem(null)}
                    onSave={() => {
                      deleteDocument(item?.id).then(() => setDeleteItem(null));
                    }}
                  />
                </Grid>
              )}
            </Grid>
            {isProtocol && (
              <strong>Дата и время добавления: {moment(item?.createdAt).format('DD.MM.YYYY HH:MM:ss')}</strong>
            )}{' '}
          </>
        ))}
      </Grid>

      <Dialog
        open={isAddingNEwDocumentFromSystem}
        onClose={() => {
          setIsAddingNEwDocumentFromSystem(false);
          reset(defaultNewDocumentState);
        }}
        maxWidth="xs"
      >
        <DialogTitle id="add-document-dialog">Новый Документ</DialogTitle>
        <div style={{ padding: '1.5rem' }}>
          <form onSubmit={handleSubmit(handleAddNewDocumentFromSystem)} style={{ width: '100%' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Input
                  label="Название документа"
                  control={control}
                  error={!!errors['title']}
                  name="title"
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <DocumentTypes
                  label="Тип документа"
                  error={!!errors['clsType']}
                  control={control}
                  rules={{ required: true }}
                  name="clsType"
                />
              </Grid>
              <Grid item xs={12}>
                <DatePicker
                  fullWidth
                  label="Дата"
                  control={control}
                  error={!!errors['date']}
                  name="date"
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <Input label="Номер документа" control={control} error={!!errors['number']} name="number" />
              </Grid>
              <Grid item xs={12}>
                <DropzoneArea
                  showFileNames
                  alertSnackbarProps={{
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                  }}
                  dropzoneClass={classes.dropZone}
                  showAlerts={['error']}
                  getFileAddedMessage={(fileName: string) => `Файл ${fileName} успешно загружено`}
                  getDropRejectMessage={(file: File) =>
                    `Файл ${file.name} отклонен. Тип файла не поддерживается. Файл слишком большой. Максимальный размер - 10 мегабайт.`
                  }
                  filesLimit={1}
                  maxFileSize={10485760}
                  clearOnUnmount
                  initialFiles={file}
                  onChange={(fileArray: File[]) => setFile(fileArray)}
                  dropzoneText="Файл"
                />
              </Grid>
              <Grid item container justify="flex-end">
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  startIcon={<SaveIcon />}
                  type="submit"
                  disabled={!(formState.isDirty && file.length > 0)}
                >
                  Добавить
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Dialog>
      <Dialog
        open={openDateDialog}
        onClose={() => {
          setOpenDateDialog(false);
          setCurrentSportId('');
        }}
      >
        <DialogTitle>Решение комиссии по допуску</DialogTitle>
        <DialogContent>
          <form onSubmit={dateFormSubmit(onDateFormSubmit)} style={{ width: '100%' }}>
            <Grid container spacing={2}>
              <DatePicker label="на дату" control={dateFormControl} error={!!dateFormErrors['date']} name="date" />
              <Grid item container justify="space-between">
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  type="button"
                  onClick={() => setOpenDateDialog(false)}
                  disabled={!dateFormState.isDirty}
                >
                  Закрыть
                </Button>
                <Button variant="outlined" color="primary" size="small" type="submit" disabled={!dateFormState.isDirty}>
                  Скачать
                </Button>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Dialog>
      {isGeneratingDoc && <LoadingWall label="отчет формируется, не закрывайте страницу" />}
    </div>
  );
};

const Documents: FC<{ readonly?: boolean; id?: string }> = ({ id, readonly }) => {
  const { isGskReferee } = userRights();

  const read_only = isGskReferee ? false : readonly;

  const { loading, data, refetch } = useSportingEventDocumentsListQuery({ returnPartialData: true, variables: { id } });

  return (
    <>
      {Object.keys(docTypes).map((key, index) => (
        <DocumentComponent
          key={key}
          readonly={read_only}
          id={id}
          loading={loading}
          data={data}
          refetch={refetch}
          docKey={key}
          docName={_.get(docTypes, key, '')}
          withSports={index === 0}
          docRNumber={_.get(docRNumbers, key, 0)}
        />
      ))}
    </>
  );
};

export default Documents;
