import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import { ControllerProps } from 'react-hook-form/dist/types/props';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsMoment from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/ru';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
moment.tz.setDefault('Africa/Bamako');
moment.locale('ru');

interface TDatePicker {
  label?: string;
  maxDate?: Date;
  minDate?: Date;
  fullWidth?: boolean;
  errorMessage?: string;
  invalidDateMessage?: string;
  error?: boolean | undefined;
  disabled?: boolean;
  onChange: (date: MaterialUiPickersDate, value?: string | null | undefined) => void;
  value: ParsableDate;
  required?: boolean;
  format?: string;
}

interface ReactHookFormInput extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  maxDate?: Date;
  minDate?: Date;
  fullWidth?: boolean;
  errorMessage?: string;
  invalidDateMessage?: string;
  error: boolean | undefined;
  disabled?: boolean;
  onSuperChange?: any;
}

const DatePicker: FC<ReactHookFormInput> = ({
  name,
  error,
  label,
  errorMessage = '',
  invalidDateMessage = 'Неверный формат',
  maxDate,
  minDate,
  control,
  rules,
  fullWidth,
  disabled,
  onSuperChange,
}) => {
  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={DateFnsMoment} locale="ru">
      <Controller
        render={({ onChange, ...props }) => {
          return (
            <KeyboardDatePicker
              autoOk
              fullWidth={fullWidth}
              variant="inline"
              format="DD.MM.YYYY"
              label={
                <>
                  {label} {rules?.required && <span style={{ color: 'red' }}>*</span>}
                </>
              }
              value={props.value}
              onChange={(event, data) => {
                if (onSuperChange) {
                  onSuperChange(event, data);
                } else {
                  onChange(event, data);
                }
              }}
              invalidDateMessage={invalidDateMessage}
              maxDate={maxDate}
              minDate={minDate}
              error={error}
              helperText={errorMessage}
              disabled={disabled}
            />
          );
        }}
        control={control}
        defaultValue={null}
        name={name}
        rules={rules}
      />
    </MuiPickersUtilsProvider>
  );
};

export const MDatePicker: FC<TDatePicker> = ({
  error,
  label,
  errorMessage = '',
  invalidDateMessage = 'Неверный формат',
  maxDate,
  minDate,
  fullWidth,
  disabled,
  onChange,
  value,
  required,
  format,
}) => {
  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={DateFnsMoment} locale="ru">
      <KeyboardDatePicker
        autoOk
        fullWidth={fullWidth}
        variant="inline"
        format={format || 'DD.MM.YYYY'}
        label={
          <>
            {label} {required && <span style={{ color: 'red' }}>*</span>}
          </>
        }
        value={value}
        onChange={onChange}
        invalidDateMessage={invalidDateMessage}
        maxDate={maxDate}
        minDate={minDate}
        error={error}
        helperText={errorMessage}
        disabled={disabled}
      />
    </MuiPickersUtilsProvider>
  );
};

export default DatePicker;
