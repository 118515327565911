import React, { FC } from 'react';
import Button, { ButtonProps } from '@material-ui/core/Button';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { green } from '@material-ui/core/colors';

interface BtnNewType {
  buttonText?: string;
}
const BtnNew: FC<ButtonProps & BtnNewType> = ({
  buttonText = 'Добавить',
  size = 'small',
  variant = 'outlined',
  startIcon = (
    <AddCircleOutlineOutlinedIcon
      htmlColor={`${green[600]}`}
      style={{
        fontSize: 18,
      }}
    />
  ),

  ...props
}) => (
  <Button {...props} size={size} variant={variant} startIcon={startIcon}>
    {buttonText}
  </Button>
);

export default BtnNew;
