import { Box, Button, Grid } from '@material-ui/core';
import { Input, Select } from '../Inputs';
import React, { FC, useState } from 'react';
import {
  useClsSportingEventStageQuery,
  useGenerateSportingEventsXlsFileLazyQuery,
  useSportingEventsCountLazyQuery,
} from '../../api';

import Calendars from '../../module/Calendars';
import ClearIcon from '@material-ui/icons/Clear';
import ContentWrapper from '../layouts/ContentWrapper/ContentWrapper';
import { Count } from '../Count';
import GetAppIcon from '@material-ui/icons/GetApp';
import SearchIcon from '@material-ui/icons/Search';
import SportingEventsStatusExtended from '../../module/SportingEventsStatusExtended';
import TypeSportsSelect from '../../module/TypeSportsSelect';
import { makeStyles } from '@material-ui/core/styles';
import { onSetSportingEventFilter } from './helpers';
import { useForm } from 'react-hook-form';
import useGenerateXlsx from '../../hooks/useGenerateXlsx';

interface Value {
  status?: {
    label: string;
    value: string;
  };
  sportType?: {
    label: string;
    value: string;
  };
  name?: string;
  dirCalendar?: {
    label: string;
    value: string;
  };
  archive?: {
    label: string;
    value: boolean | string;
  };
}

const useStyle = makeStyles((theme) => ({
  btn: {
    marginRight: theme.spacing(2),
  },
}));

const SportingEvents: FC<{
  onSubmit?: (T: any, E: any) => void;
  value?: any;
  defaultFilter?: any;
  updatedFilter?: any;
  first: number;
  filterByAccess?: any;
}> = ({ onSubmit = () => {}, value, defaultFilter = {}, updatedFilter = {}, first, filterByAccess }) => {
  const style = useStyle();

  const { data: clsStages } = useClsSportingEventStageQuery();
  const [filter, setFilter] = useState<any>({ ...defaultFilter, ...updatedFilter });

  const { handleSubmit, control, errors, reset, formState, getValues } = useForm({
    defaultValues: value,
  });

  const { onDownloadFile } = useGenerateXlsx({
    lazyQuery: useGenerateSportingEventsXlsFileLazyQuery,
    getValues,
    setFilter: (value: any) => ({ ...defaultFilter, ...onSetSportingEventFilter(value) }),
    fileName: 'sporting_events',
    first,
  });

  const handleSearchBox = (value: Value) => {
    const filters: any = onSetSportingEventFilter(value);

    setFilter({ ...defaultFilter, ...filters });
    onSubmit(filters, getValues());
  };

  return (
    <ContentWrapper>
      <form onSubmit={handleSubmit(handleSearchBox)}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <Input label="Название мероприятия" control={control} error={!!errors['name']} name="name" />
          </Grid>

          <Grid item xs={3}>
            <TypeSportsSelect
              label="Вид спорта"
              error={!!errors['sportType']}
              control={control}
              name="sportType"
              filter={filterByAccess?.sportType}
            />
          </Grid>

          <Grid item xs={3}>
            <Calendars
              label="Календарь"
              error={!!errors['dirCalendar']}
              control={control}
              name="dirCalendar"
              notInitialized
              isSportEvent
              filter={{ ...filterByAccess?.dirCalendars, archive: null }}
            />
          </Grid>
          <Grid item xs={3}>
            <SportingEventsStatusExtended label="Статус" error={!!errors['status']} control={control} name="status" />
          </Grid>
          <Grid item xs={3}>
            <Select
              label="В архиве"
              data={[
                { value: '', label: ' ' },
                { value: false, label: 'Нет' },
                { value: true, label: 'Да' },
              ]}
              control={control}
              error={!!errors['archive']}
              name="archive"
              defaultValue={{ value: false, label: 'Нет' }}
            />
          </Grid>
          <Grid item xs={3}>
            <Select
              label="Этап"
              data={clsStages?.classifierValues?.map(({ value, label }) => ({ value, label })) || []}
              control={control}
              error={!!errors['step']}
              name={'step'}
            />
          </Grid>

          <Grid item container alignItems={'center'}>
            <Button
              variant="outlined"
              className={style.btn}
              color="primary"
              type="button"
              onClick={() => {
                onSubmit(
                  {
                    ...defaultFilter,
                    archive: null,
                  },
                  {},
                );
                setFilter({
                  ...defaultFilter,
                  archive: null,
                });
                reset({
                  status: null,
                  sportType: null,
                  name: '',
                  dirCalendar: '',
                });
              }}
              size="small"
              startIcon={<ClearIcon />}
            >
              Сброс
            </Button>
            <Button
              variant="outlined"
              color="primary"
              type="submit"
              size="small"
              className={style.btn}
              startIcon={<SearchIcon />}
              disabled={!formState.isDirty}
            >
              Поиск
            </Button>
            <Box pl={4}>
              <Button
                variant="outlined"
                color="primary"
                disabled={!formState.isDirty}
                onClick={onDownloadFile}
                size={'small'}
                startIcon={<GetAppIcon />}
              >
                XLS
              </Button>
              <Count key={JSON.stringify(filter)} query={useSportingEventsCountLazyQuery} filter={filter} />
            </Box>
          </Grid>
        </Grid>
      </form>
    </ContentWrapper>
  );
};

export default SportingEvents;
