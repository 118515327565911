import React, { FC } from 'react';
import { useCalendarsSportsQuery, useCalendarTypesQuery } from '../api';
import { Select } from '../components/Inputs';
import { ControllerProps } from 'react-hook-form/dist/types/props';

interface ICalendarSports extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  error: boolean | undefined;
  disabled?: boolean;
  defaultValue?: { label: string; value: Number };
  id?: string;
}

const CalendarSports: FC<ICalendarSports> = ({ label, error, control, name, rules, defaultValue, disabled, id }) => {
  const { data, loading } = useCalendarsSportsQuery({
    variables: {
      id,
    },
    skip: !id,
  });

  return (
    <Select
      label={label}
      data={data?.dirCalendar?.dirSports?.map((sport) => ({ label: sport.fullName, value: sport.id })) || []}
      control={control}
      error={error}
      name={name}
      rules={rules}
      loading={loading}
      defaultValue={defaultValue || null}
      disabled={disabled}
    />
  );
};

export default CalendarSports;
