import React, { FC, useState } from 'react';
import { makeStyles, Grid, Button, Typography, AccordionSummary, AccordionDetails, Accordion } from '@material-ui/core';
import { useCreateVenueCompApplicationMutation, useGetVenueByRegionQuery } from '../../../api';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CompetitionVenueRegionItem } from './CompetitionVenueRegionItem';
import userRights from '../../../config/userRights';

const useStyles = makeStyles({
  statusBox: {
    maxHeight: 150,
    overflowY: 'auto',
    scrollBehavior: 'smooth',
  },
  title: {
    fontWeight: 500,
    marginRight: '0.5rem',
  },
  link: {
    cursor: 'pointer',
  },
  verticalSpace: {
    paddingBottom: 15,
  },
  verticalSpaceMarge: {
    marginBottom: 35,
  },
  divider: {
    height: 1,
    width: '100%',
    marginBottom: '1rem',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  tinyIcon: {
    fontSize: 18,
  },
  icon: {
    fontSize: 20,
  },
  dropZone: {
    minHeight: 30,
    border: 0,
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    borderRadius: 0,

    '& > .MuiDropzonePreviewList-root': {
      margin: '0 !important',
      width: '100%',
      '& > .MuiDropzonePreviewList-imageContainer': {
        flexBasis: 'unset',
        width: '100%',
        maxWidth: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 15,
        '& > svg': {
          height: '40px',
        },
        '& > p': {
          marginLeft: 15,
          marginRight: 15,
        },
        '& > button': {
          top: 'unset',
          right: 0,
          position: 'unset',
          marginLeft: 30,

          color: '#d32f2f',
          boxShadow: 'unset',
        },
      },
    },
    '& > .MuiDropzoneArea-textContainer': {
      display: 'flex',

      '& > .MuiDropzoneArea-text': {
        margin: 0,
        fontSize: 15,
      },
      '& > .MuiDropzoneArea-icon': {
        width: 20,
        height: 20,
        marginLeft: 'auto',
      },
    },
    '&:focus': {
      outline: 0,
      border: 0,
      borderBotom: '2px solid #3f51b5',
    },
  },
});

export const CompetitionVenueRegion: FC<{
  readonly?: boolean;
  id?: string;
  idRegion: string;
  notValidDate: boolean;
  email?: string;
}> = ({ readonly, id, idRegion, notValidDate, email }) => {
  const { isRoiv } = userRights();
  readonly = isRoiv ? notValidDate : readonly;

  const { data, refetch, loading } = useGetVenueByRegionQuery({
    skip: !id || id === 'new',
    variables: { idRegion: idRegion, id: id },
    fetchPolicy: 'no-cache',
  });
  const [active, setActive] = useState(false);
  const classes = useStyles();

  const [createComApp, { loading: loadingMutation }] = useCreateVenueCompApplicationMutation();

  const onCreateStub = async () => {
    const getMaxCount =
      data?.venueCompApplications.reduce((acc, v) => {
        if (v.number > acc) {
          return v.number;
        }
        return acc;
      }, 0) || 0;
    await createComApp({
      variables: {
        compId: id,
        number: getMaxCount + 1,
        dirRegion: idRegion,
        notificationSentToFCPSR: false,
      },
    });
    await refetch();
  };

  return (
    <Grid md={12} spacing={1} item container direction="column">
      <Grid item>
        <Button
          variant="contained"
          disabled={readonly || loadingMutation || loading}
          color="primary"
          onClick={onCreateStub}
        >
          Новая заявка
        </Button>
      </Grid>
      {data?.venueCompApplications?.map((venueCompApplication) => (
        <Grid item>
          <Accordion
            key={venueCompApplication.id}
            expanded={active === venueCompApplication.id}
            onChange={() => setActive(active === venueCompApplication.id ? '' : venueCompApplication.id)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${venueCompApplication.id}-content`}
              id={`${venueCompApplication.id}-header`}
            >
              <Typography className={classes.head} color="primary">
                Заявка №{venueCompApplication.number}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {active === venueCompApplication.id && (
                <CompetitionVenueRegionItem
                  notValidDate={notValidDate}
                  email={email}
                  id={venueCompApplication?.id}
                  readonly={readonly}
                  refetchMain={refetch}
                  refetchMainLoading={loading}
                />
              )}
            </AccordionDetails>
          </Accordion>
        </Grid>
      ))}
    </Grid>
  );
};
