import _ from 'lodash';
import { availableTags } from './constants';
interface IDefault {
  label: string;
  value: any;
}
export const setData = (
  value: any,
  isOrganizerOrParticipant: boolean | null | undefined,
  isRussia: string,
  dirRegions: any,
  dirCountry: any,
  dirForeignCities: any,
  sportTypesAndDisciplines: any,
  ageGroups: any,
) => {
  const {
    name,
    startDate,
    endDate,
    venue,
    ekp,
    object,
    properNoun,
    tags,
    oldRegistryNumber,
    athletesTrainersRefereesSpecialistsCount,
  } = value;

  const nonUniqField = {
    dirCalendar: {
      connect: {
        fullName: _.get(value, 'dirCalendar.value'),
      },
    },
    clsDirectionality: {
      connect: {
        registryNumber: Number(_.get(value, 'clsDirectionality.value')),
      },
    },
    clsSummarizingType: {
      connect: {
        registryNumber: Number(_.get(value, 'clsSummarizingType.value')),
      },
    },
  };

  const tagsObject = {
    isImportant: tags?.find((tag: IDefault) => tag?.value === 'isImportant') !== undefined,
    isOlympicGamesPreparation: tags?.find((tag: IDefault) => tag?.value === 'isOlympicGamesPreparation') !== undefined,
    federalBudgetFinancing: tags?.find((tag: IDefault) => tag?.value === 'federalBudgetFinancing') !== undefined,
    includingTrainingActivities:
      tags?.find((tag: IDefault) => tag?.value === 'includingTrainingActivities') !== undefined,
  };

  return {
    update: {
      name,
      ...(oldRegistryNumber && { oldRegistryNumber: Number(oldRegistryNumber) }),
      ...(athletesTrainersRefereesSpecialistsCount && {
        athletesTrainersRefereesSpecialistsCount: Number(athletesTrainersRefereesSpecialistsCount),
      }),
      properNoun,
      startDate,
      endDate,
      venue,
      ekp,
      object,
      ...tagsObject,
      organizers: {
        set: value?.organizers?.values && value?.organizers?.map((v: any) => ({ id: _.get(v, 'value') })),
      },
      ...nonUniqField,
      isOrganizerOrParticipant: isOrganizerOrParticipant + '' == 'false' ? false : true,
      clsEventCategories: {
        set: value?.clsEventCategories?.map((v: any) => ({ registryNumber: _.get(v, 'registryNumber') })),
      },
      clsDivisions: {
        set: value?.organizers?.values && value?.clsDivisions?.map((v: any) => ({ id: _.get(v, 'value') })),
      },

      clsEventStages: {
        set: value?.clsEventStages?.map((v: any) => ({ id: _.get(v, 'value') })),
      },
    },
    create: {
      name,
      ...(oldRegistryNumber && { oldRegistryNumber: Number(oldRegistryNumber) }),
      ...(athletesTrainersRefereesSpecialistsCount && {
        athletesTrainersRefereesSpecialistsCount: Number(athletesTrainersRefereesSpecialistsCount),
      }),
      properNoun,
      startDate,
      endDate,
      venue,
      ekp,
      object,
      isRussia: isRussia === 'true',
      ...(isRussia === 'true' && {
        dirRegions: {
          connect: dirRegions?.map((region: IDefault) => ({ id: region?.value })),
        },
      }),
      ...(isRussia === 'false' &&
        dirCountry &&
        dirCountry?.length > 0 && {
          countries: {
            create: _.map(dirCountry, (c) => ({
              dirCountry: {
                connect: { id: c?.value },
              },
              ...(dirForeignCities && {
                dirForeignCities: {
                  connect: dirForeignCities
                    ?.filter((city: any) => city?.country?.id === c?.value)
                    .map((city: any) => ({ id: city?.value })),
                },
              }),
            })),
          },
        }),
      ...tagsObject,
      organizers: {
        connect: value?.organizers?.values && value?.organizers?.map((v: any) => ({ id: _.get(v, 'value') })),
      },
      sports: {
        create: _.map(sportTypesAndDisciplines.sportTypes, (s) => ({
          dirSport: {
            connect: { id: s.value },
          },
          disciplineGroups: {
            connect: sportTypesAndDisciplines.disciplinesGroup
              ?.filter((d: any) => d.typeSport.id === s.value)
              .map((d: any) => ({ id: d.value })),
          },
          disciplines: {
            connect: sportTypesAndDisciplines.disciplines
              ?.filter((d: any) => d.typeSport.id === s.value)
              .map((d: any) => ({ id: d.value })),
          },
        })),
      },

      ...(ageGroups?.isAgeRestricted === true
        ? { isAgeRestricted: true }
        : ageGroups?.groups.length
        ? {
            ageGroups: {
              create: ageGroups?.groups?.map((group: any) => ({
                maxAge: group.maxAge || 0,
                minAge: group.minAge || 0,
                clsAgeGroup: {
                  connect: {
                    id: group.clsAgeGroup?.value,
                  },
                },
              })),
            },
            ageGroup: ageGroups?.ageGroup,
          }
        : {}),

      ...nonUniqField,
      isOrganizerOrParticipant: isOrganizerOrParticipant + '' === 'false' ? false : true,
      clsEventCategories: {
        connect: value?.clsEventCategories?.map((v: any) => ({ registryNumber: _.get(v, 'registryNumber') })),
      },
      clsDivisions: {
        connect: value?.clsDivisions?.map((v: any) => ({ id: _.get(v, 'value') })),
      },
      clsEventStages: {
        connect: value?.clsEventStages?.map((v: any) => ({ id: _.get(v, 'value') })),
      },
      status: {
        connect: {
          id: 1,
        },
      },
    },
  };
};

// Generate Name

export const generateName = (value: any) => {
  let newName = `${value?.clsEventCategories?.map((d: any) => d.fullName).join(', ')}`;

  if (value?.properNoun) newName += newName !== '' ? ` "${value?.properNoun}"` : `"${value?.properNoun}"`;
  if (value?.clsEventStages?.length)
    newName +=
      newName !== ''
        ? `, ${value?.clsEventStages?.map((d: any) => d.fullName).join(', ')}`
        : `${value?.clsEventStages?.map((d: any) => d.fullName).join(', ')}`;
  if (value?.clsDivisions?.length)
    newName +=
      newName !== ''
        ? `, ${value?.clsDivisions?.map((d: any) => _.get(d, 'label', '')).join(', ')}`
        : `${value?.clsDivisions?.map((d: any) => _.get(d, 'label', '')).join(', ')}`;
  if (value?.clsSummarizingType)
    newName +=
      newName !== ''
        ? `, ${_.get(value, 'clsSummarizingType.label', '')}`
        : `${_.get(value, 'clsSummarizingType.label', '')}`;

  return newName;
};
