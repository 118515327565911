import React, { FC } from 'react';
import { Grid } from '@material-ui/core';

import TotalCountUsers from './components/TotalCountUsers';
import AgeUsers from './components/AgeUsers';
import ClassificationUsers from './components/ClassificationUsers';
import ClassificationRefees from './components/ClassificationRefees';

const Analytics: FC<{ id?: string }> = ({ id }) => {
  return (
    <div>
      <Grid container>
        <Grid item container>
          <TotalCountUsers id={id} />
        </Grid>
        <Grid item container>
          <AgeUsers id={id} />
        </Grid>
        <Grid item container>
          <ClassificationUsers id={id} />
        </Grid>
        <Grid item container>
          <ClassificationRefees id={id} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Analytics;
