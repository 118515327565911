import roles from '../config/role';
import { UserRole } from '../types/types';
import _get from 'lodash/get';

const usePermission = (type: string, name?: string, userRole?: keyof UserRole) => {
  const localStore: keyof UserRole | null = localStorage.getItem('userRole') as keyof UserRole;
  const active = !userRole && localStore ? roles[localStore] : userRole && roles[userRole];
  return _get(active, `${type}.${name}`, _get(active, `${type}.all`, []));
};

const usePermissionMenu = (userRole?: keyof UserRole) => {
  const localStore: keyof UserRole | null = localStorage.getItem('userRole') as keyof UserRole;
  const active = !userRole && localStore ? roles[localStore] : userRole && roles[userRole];
  return active;
};

export { usePermission, usePermissionMenu };
