import React, { FC } from 'react';

import { LinearProgress } from '@material-ui/core';
import OrgAccordion from './components/OrgAccordion';
import { useTrainerByIdQuery } from '../../../api';

const Organizations: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const { data, loading, refetch } = useTrainerByIdQuery({
    skip: id === '' || id === 'new',
    variables: { id: id === 'new' ? '' : id },
    fetchPolicy: 'no-cache',
  });

  const sports = data?.dirTrainer?.sports;

  if (loading) return <LinearProgress />;

  return (
    <div style={{ width: '100%' }}>
      {sports?.map((sport) => (
        <OrgAccordion id={id} key={sport.id} sport={sport} readonly={readonly} onUpdate={() => refetch()} />
      ))}
    </div>
  );
};

export default Organizations;
