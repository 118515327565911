import {
  Accordion,
  AccordionDetails,
  Grid,
  AccordionSummary as MuiAccordionSummary,
  Typography,
  withStyles,
} from '@material-ui/core';
import { ContentWrapperWidget, MainTemplate } from '../../components/layouts';
import React, { ChangeEvent, FC, useState } from 'react';

import Calendar from '../../components/widgets/Minsports/Calendar';
import CompetitionResults from '../../components/widgets/Minsports/CompetitionResults';
import Costs from '../../components/widgets/Minsports/Costs';
import Documents from '../../components/widgets/Minsports/Documents';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MainInfo from '../../components/widgets/Minsports/MainInfo';
import ProgramTypes from '../../components/widgets/Minsports/ProgramTypes';
import Rules from '../../components/widgets/Minsports/Rules';
import RulesFull from '../../components/widgets/Minsports/RulesFull';
import SportingEventRequests from '../../components/widgets/Minsports/SportingEventRequests';
import { blue } from '@material-ui/core/colors';
import moment from 'moment';
import onExpand from '../../utils/onExpand';
import { usePermission } from '../../hooks/usePermission';
import { useRouteMatch } from 'react-router-dom';
import { useSportingEventQuery } from '../../api';

type AccordionTypes =
  | 'MainInfo'
  | 'Calendar'
  | 'TeamInfo'
  | 'Rules'
  | 'ProgramTypes'
  | 'CompetitionResults'
  | 'Documents'
  | 'SportingEventRequests'
  | 'Сonsumption'
  | undefined;

const AccordionSummary = withStyles({
  root: {
    backgroundColor: blue[700],
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    color: 'white',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary) as any;

const ProperSingle: FC<{}> = () => {
  const {
    params: { id },
  } = useRouteMatch();

  const [expanded, setExpanded] = useState<AccordionTypes>('MainInfo');

  const access = usePermission('directory', 'minsports');

  const { data, refetch } = useSportingEventQuery({
    skip: !id || id === 'new',
    variables: { id },
    fetchPolicy: 'no-cache',
  });

  const readonly = (data?.dirSportingEvent?.status?.label !== 'Проект' && id !== 'new') || !access.includes(2);

  const handleChangeAccordion = (panel: AccordionTypes) => (event: ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : undefined);
  };
  const OptionTitleArchive = data?.dirSportingEvent?.archive
    ? ` (В архиве с ${moment(data?.dirSportingEvent?.archive?.date).format('DD.MM.YYYY')})`
    : '';

  return (
    <MainTemplate pageName="" url="">
      <ContentWrapperWidget>
        {(id === 'new' && <h2>Добавить новое мероприятие</h2>) ||
          (data?.dirSportingEvent && (
            <h2>
              Мероприятие:{' '}
              {`${data?.dirSportingEvent?.registryNumber || ''}, ${
                data?.dirSportingEvent?.name || ''
              }, ${data?.dirSportingEvent?.sports?.map((sport) => sport?.dirSport?.label).join(', ')}, ${
                data?.dirSportingEvent?.startDate && moment(data?.dirSportingEvent?.startDate).format('DD.MM.YYYY')
              } -
              ${data?.dirSportingEvent?.endDate && moment(data?.dirSportingEvent?.endDate).format('DD.MM.YYYY')}`}
              {OptionTitleArchive}
            </h2>
          ))}
        <Accordion expanded={expanded === 'MainInfo'} onChange={handleChangeAccordion('MainInfo')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Общая информация</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {onExpand(expanded, 'MainInfo') && (
              <MainInfo id={id} readonly={readonly} onStatusUpdated={() => refetch()} />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'SportingEventRequests'}
          onChange={handleChangeAccordion('SportingEventRequests')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Заявки</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {onExpand(expanded, 'SportingEventRequests') && id && (
              <SportingEventRequests id={id} readonly={readonly} onStatusUpdated={() => refetch()} />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={!data?.dirSportingEvent?.isOrganizerOrParticipant === false || id === 'new'}
          expanded={expanded === 'TeamInfo'}
          onChange={handleChangeAccordion('TeamInfo')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Участники</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>
            {onExpand(expanded, 'TeamInfo') && <RulesFull id={id} readonly={readonly} />}
          </AccordionDetails>
        </Accordion>
        <Accordion
          id={'peoplesBlock'}
          disabled={id === 'new' || data?.dirSportingEvent?.isOrganizerOrParticipant === false}
          expanded={expanded === 'Rules'}
          onChange={handleChangeAccordion('Rules')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Команда</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>
            {onExpand(expanded, 'Rules') && <Rules id={id} readonly={readonly} />}
          </AccordionDetails>
        </Accordion>
        <Accordion disabled={id === 'new'}>
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>
              {data?.dirSportingEvent?.isOrganizerOrParticipant === false
                ? 'Расходы на организацию'
                : 'Расходы на участие'}
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>
            {onExpand(expanded, 'Сonsumption') && (
              <>
                {id}
                {
                  data?.dirSportingEvent?.isOrganizerOrParticipant === false ? (
                    <Costs id={id} readonly={readonly} />
                  ) : (
                    <Costs id={id} readonly={readonly} />
                  ) //'Расходы на участие'
                }
              </>
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'Documents'}
          onChange={handleChangeAccordion('Documents')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Отчетные документы</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>
            {onExpand(expanded, 'Documents') && id && <Documents id={id} />}
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'ProgramTypes'}
          onChange={handleChangeAccordion('ProgramTypes')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Виды программ</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>
            {onExpand(expanded, 'ProgramTypes') && <ProgramTypes id={id} />}
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'CompetitionResults'}
          onChange={handleChangeAccordion('CompetitionResults')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Результаты соревнований</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>
            {onExpand(expanded, 'CompetitionResults') && <CompetitionResults id={id} />}
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new' || data?.dirSportingEvent?.isOrganizerOrParticipant === false}
          expanded={expanded === 'Calendar'}
          onChange={handleChangeAccordion('Calendar')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Программа мероприятия</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {onExpand(expanded, 'Calendar') && <Calendar id={id} readonly={readonly} />}
          </AccordionDetails>
        </Accordion>
      </ContentWrapperWidget>
    </MainTemplate>
  );
};

export default ProperSingle;
