import React, { FC, useEffect } from 'react';
import { Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import { useSnackbar } from 'notistack';
import RanksAndTitle from '../../../module/RanksAndTitle';
import EditLocationIcon from '@material-ui/icons/EditLocation';
import { useForm } from 'react-hook-form';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Input } from '../../Inputs';
import getMessage from '../../../messages';
import TeamComposition from '../../../module/TeamComposition';
import { detectedChanges } from '../../../utils/compareSportingEvents';
import _ from 'lodash';
import {
  Scalars,
  useSportingEventQuery,
  UpdateSportingEventMutationHookResult,
  useUpdateSportingEventMutation,
  SportingEventDocument,
} from '../../../api';

const useStyles = makeStyles({
  alignCenter: {
    alignItems: 'center',
  },
  title: {
    fontWeight: 500,
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  tinyIcon: {
    fontSize: 18,
  },
  icon: {
    fontSize: 20,
  },
});

interface formState {
  athletesMan?: Scalars['Int'] | null;
  athletesWoman?: Scalars['Int'] | null;
  trainers?: Scalars['Int'] | null;
  referees?: Scalars['Int'] | null;
  administration?: Scalars['Int'] | null;
  medicalStaff?: Scalars['Int'] | null;
  specialists?: Scalars['Int'] | null;
  teams?: Scalars['Int'] | null;
  clsTeamComposition?: any;
}

const Rules: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const classes = useStyles();

  const { loading, data } = useSportingEventQuery({ fetchPolicy: 'no-cache', variables: { id } });
  const [update]: UpdateSportingEventMutationHookResult = useUpdateSportingEventMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: SportingEventDocument,
        data: {
          ...data?.updateDirSportingEvent,
        },
      });
    },
  });

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const { handleSubmit, control, errors, reset, formState } = useForm({
    defaultValues: {
      ...data?.dirSportingEvent,
      athletesMan: data?.dirSportingEvent?.quota?.athletesMan,
      athletesWoman: data?.dirSportingEvent?.quota?.athletesWoman,
    } as formState,
  });

  const onSubmit = async (values: formState) => {
    const {
      athletesMan,
      athletesWoman,
      trainers,
      referees,
      administration,
      medicalStaff,
      specialists,
      teams,
      clsTeamComposition,
    } = values;
    const clsTeamCompositionValue =
      (values.clsTeamComposition && {
        connect: {
          id: _.get(values, 'clsTeamComposition.value'),
        },
      }) ||
      (data?.dirSportingEvent?.quota?.clsTeamComposition?.value && {
        set: {
          id: data?.dirSportingEvent?.quota?.clsTeamComposition?.value,
        },
      });
    try {
      if (data?.dirSportingEvent?.quota?.id > 0) {
        await update({
          variables: {
            data: {
              quota: {
                update: {
                  clsTeamComposition: clsTeamCompositionValue,
                  ...(athletesMan && { athletesMan: Number(athletesMan) || 0 }),
                  ...(athletesWoman && { athletesWoman: Number(athletesWoman) || 0 }),
                  ...(trainers && { trainers: Number(trainers) || 0 }),
                  ...(referees && { referees: Number(referees) || 0 }),
                  ...(administration && { administration: Number(administration) || 0 }),
                  ...(medicalStaff && { medicalStaff: Number(medicalStaff) || 0 }),
                  ...(specialists && { specialists: Number(specialists) || 0 }),
                  ...(teams && { teams: Number(teams) || 0 }),
                },
              },
            },
            id,
          },
        });
      } else {
        await update({
          variables: {
            data: {
              quota: {
                create: {
                  clsTeamComposition: clsTeamCompositionValue,
                  ...(athletesMan && { athletesMan: Number(athletesMan) || 0 }),
                  ...(athletesWoman && { athletesWoman: Number(athletesWoman) || 0 }),
                  ...(trainers && { trainers: Number(trainers) || 0 }),
                  ...(referees && { referees: Number(referees) || 0 }),
                  ...(administration && { administration: Number(administration) || 0 }),
                  ...(medicalStaff && { medicalStaff: Number(medicalStaff) || 0 }),
                  ...(specialists && { specialists: Number(specialists) || 0 }),
                  ...(teams && { teams: Number(teams) || 0 }),
                },
              },
            },
            id,
          },
        });
      }

      handleSnackBar('success', 'Требование успешно обновлена');
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  useEffect(() => {
    reset({ ...data?.dirSportingEvent?.quota });
  }, [data, reset]);

  if (loading) return <LinearProgress />;

  const rChanges = detectedChanges(data?.dirSportingEvent).eventsQuotaArrayChange;

  return (
    <div style={{ paddingTop: '2rem' }}>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Grid container spacing={2} className={classes.alignCenter}>
          <Grid item container lg={6} md={6} xs={12} alignItems="flex-end">
            <Grid item xs>
              <TeamComposition
                label="Состав команды"
                control={control}
                error={!!errors['clsTeamComposition']}
                name="clsTeamComposition"
                rules={{ required: true }}
                disabled={readonly}
              />
            </Grid>
            {rChanges?.includes('clsTeamComposition') && (
              <Grid item>
                <EditLocationIcon color="secondary" />
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.alignCenter}>
          <Grid item lg={6} md={6} xs={12}>
            <h3>Состав сборной команды:</h3>
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.alignCenter}>
          <Grid container item lg={6} md={6} xs={12} alignItems="flex-end">
            <Grid item xs>
              <Input
                label="Возраст спортсменов муж."
                control={control}
                error={!!errors['athletesMan']}
                name="athletesMan"
                disabled={readonly}
              />
            </Grid>
            {rChanges?.includes('athletesMan') && (
              <Grid item>
                <EditLocationIcon color="secondary" />
              </Grid>
            )}
          </Grid>
          <Grid item container lg={6} md={6} xs={12} alignItems="flex-end">
            <Grid item xs>
              <Input
                label="Возраст спортсменов жен."
                control={control}
                error={!!errors['athletesWoman']}
                name="athletesWoman"
                disabled={readonly}
              />
            </Grid>
            {rChanges?.includes('athletesWoman') && (
              <Grid item>
                <EditLocationIcon color="secondary" />
              </Grid>
            )}
          </Grid>
          <Grid item container lg={6} md={6} xs={12} alignItems="flex-end">
            <Grid item xs>
              <Input
                label="Тренеры"
                control={control}
                error={!!errors['trainers']}
                name="trainers"
                disabled={readonly}
              />
            </Grid>
            {rChanges?.includes('trainers') && (
              <Grid item>
                <EditLocationIcon color="secondary" />
              </Grid>
            )}
          </Grid>
          <Grid item container lg={6} md={6} xs={12} alignItems="flex-end">
            <Grid item xs>
              <Input label="Судьи" control={control} error={!!errors['referees']} name="referees" disabled={readonly} />
            </Grid>
            {rChanges?.includes('referees') && (
              <Grid item>
                <EditLocationIcon color="secondary" />
              </Grid>
            )}
          </Grid>
          <Grid item container lg={6} md={6} xs={12} alignItems="flex-end">
            <Grid item xs>
              <Input
                label="Администрация"
                control={control}
                error={!!errors['administration']}
                name="administration"
                disabled={readonly}
              />
            </Grid>
            {rChanges?.includes('administration') && (
              <Grid item>
                <EditLocationIcon color="secondary" />
              </Grid>
            )}
          </Grid>
          <Grid item container lg={6} md={6} xs={12} alignItems="flex-end">
            <Grid item xs>
              <Input
                label="Медперсонал"
                control={control}
                error={!!errors['medicalStaff']}
                name="medicalStaff"
                disabled={readonly}
              />
            </Grid>
            {rChanges?.includes('medicalStaff') && (
              <Grid item>
                <EditLocationIcon color="secondary" />
              </Grid>
            )}
          </Grid>
          <Grid item container lg={6} md={6} xs={12} alignItems="flex-end">
            <Grid item xs>
              <Input
                label="Специалисты"
                control={control}
                error={!!errors['specialists']}
                name="specialists"
                disabled={readonly}
              />
            </Grid>
            {rChanges?.includes('specialists') && (
              <Grid item>
                <EditLocationIcon color="secondary" />
              </Grid>
            )}
          </Grid>
          <Grid item container lg={6} md={6} xs={12} alignItems="flex-end">
            <Grid item xs>
              <Input label="Команды" control={control} error={!!errors['teams']} name="teams" disabled={readonly} />
            </Grid>
            {rChanges?.includes('teams') && (
              <Grid item>
                <EditLocationIcon color="secondary" />
              </Grid>
            )}
          </Grid>
          <Grid item container lg={6} md={6} xs={12} justify="flex-end">
            <Button
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<SaveIcon />}
              type="submit"
              disabled={!formState.isDirty}
            >
              Сохранить
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default Rules;
