import {
  Accordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Typography,
  withStyles,
} from '@material-ui/core';
import { ContentWrapperWidget, MainTemplate } from '../../components/layouts';
import React, { ChangeEvent, FC, useState } from 'react';
import { useRegionByIdQuery } from '../../api';

import Analitics from '../../components/widgets/Regions/Analitics';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MainInfo from '../../components/widgets/Regions/MainInfo';
import { blue } from '@material-ui/core/colors';
import moment from 'moment';
import onExpand from '../../utils/onExpand';
import { usePermission } from '../../hooks/usePermission';
import { useRouteMatch } from 'react-router-dom';

type AccordionTypes = 'MainInfo' | 'Analitics' | undefined;

const AccordionSummary = withStyles({
  root: {
    backgroundColor: blue[700],
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    color: 'white',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary) as any;

// @TODO: Update View for Dialog request to Archive

const Single: FC<{}> = () => {
  const {
    params: { id },
  } = useRouteMatch();
  // const [page, setPage] = useState<number>(0);
  const [expanded, setExpanded] = useState<AccordionTypes>('MainInfo');

  // const access = usePermission('directory', 'person');
  // const style = useStyle();
  // const canAdd = true;

  const access = usePermission('directory', 'region');

  const { data } = useRegionByIdQuery({ variables: { id } });

  const handleChangeAccordion = (panel: AccordionTypes) => (event: ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : undefined);
  };
  const OptionTitleArchive = data?.dirRegion?.archive
    ? ` (В архиве с ${moment(data?.dirRegion?.archive?.date).format('DD.MM.YYYY')})`
    : '';

  return (
    <MainTemplate
      pageName=""
      url=""
      title={id === 'new' ? 'Добавить новый регион' : `Регион: ${data?.dirRegion?.fullName}`}
    >
      <ContentWrapperWidget>
        {id === 'new' ? (
          <h2>Добавить новый регион</h2>
        ) : (
          <h2>
            Регион: {data?.dirRegion?.fullName || data?.dirRegion?.shortName} {OptionTitleArchive}
          </h2>
        )}
        <Accordion expanded={expanded === 'MainInfo'} onChange={handleChangeAccordion('MainInfo')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Основная информация</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {onExpand(expanded, 'MainInfo') && <MainInfo id={id} readonly={!access.includes(2)} />}
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'Analitics'} onChange={handleChangeAccordion('Analitics')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Статистика</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {onExpand(expanded, 'Analitics') && <Analitics id={id} readonly={!access.includes(2)} />}
          </AccordionDetails>
        </Accordion>
      </ContentWrapperWidget>
    </MainTemplate>
  );
};

export default Single;
