import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import { Switch as MuiSwitch, FormControlLabel } from '@material-ui/core';
import { ControllerProps } from 'react-hook-form/dist/types/props';

interface ReactHookFormSwitch extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules' | 'type'> {
  label: string;
  errorMessage?: string;
  error: boolean | undefined;
  disabled?: boolean;
  color?: 'primary' | 'secondary';
}

const Switch: FC<ReactHookFormSwitch> = ({
  name,
  error,
  type = 'text',
  label,
  errorMessage = '',
  control,
  rules,
  disabled,
  color = 'primary',
}) => {
  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          render={(props) => {
            console.log(props.value);
            return (
              <MuiSwitch
                {...props}
                color={color}
                checked={!!props.value}
                onChange={(e) => props.onChange(e.target.checked)}
              />
            );
          }}
        />
      }
      label={label}
    />
  );
};

export default Switch;
