import { Button, Grid, TextField } from '@material-ui/core';
import { Controller, useForm } from 'react-hook-form';
import { LoginMutationHookResult, LoginMutationResult, useLoginMutation } from '../../api';
import { RESET_USER_SETTINGS_FILTER, SET_ADDITIONAL_USER_INFO } from '../../store/constants';
import React, { FC, useState } from 'react';

import { AuthTemplate } from '../../components/layouts';
import Container from '@material-ui/core/Container';
import ResetPassword from '../../components/Dialogs/ResetPassword/ResetPassword';
import getMessage from '../../messages';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';

const LoginPage: FC = () => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const { handleSubmit, control, errors } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });
  const [open, setOpen] = useState<boolean>(false);
  const [login]: LoginMutationHookResult = useLoginMutation();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = handleSubmit(async (values: { password: string; email: string }) => {
    try {
      const { data } = await login({ variables: values });

      dispatch({ type: SET_ADDITIONAL_USER_INFO, payload: data });

      localStorage.setItem('userRole', data?.signin.user.role.name || '');
      localStorage.setItem('userId', data?.signin.user.id || '');
      localStorage.setItem('userDescription', data?.signin.user.role.description || '');
      localStorage.setItem(
        'userName',
        [
          data?.signin.user.regular?.lastname || '',
          data?.signin.user.regular?.firstname || '',
          data?.signin.user.regular?.patronymic || '',
        ].join(' '),
      );
      localStorage.setItem('regularOrg', JSON.stringify(data?.signin?.user?.regular?.dirOrganization) || '');
      localStorage.setItem(
        'userRegion',
        data?.signin.user.personProfile?.dirRegion?.id || data?.signin.user.roivSpecialistProfile?.dirRegion?.id || '',
      );
      localStorage.setItem('userEmail', values?.email);
      localStorage.setItem('regionalSchoolProfile', JSON.stringify(data?.signin?.user?.regionalSchoolProfile) || '');
      localStorage.setItem(
        'regionalAdminProfile',
        JSON.stringify(data?.signin?.user?.regionalAdministratorProfile) || '',
      );
      localStorage.setItem(
        'fcpsrSportingEventOrganizerProfile',
        JSON.stringify(data?.signin?.user?.fcpsrSportingEventOrganizerProfile) || '',
      );
      localStorage.setItem('gskRefereeProfile', JSON.stringify(data?.signin?.user?.gskRefereeProfile) || '');
      dispatch({
        type: RESET_USER_SETTINGS_FILTER,
      });
      push('/');
      enqueueSnackbar('Вы успешно авторизовались', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  });

  return (
    <AuthTemplate>
      {open && <ResetPassword />}
      <form
        onSubmit={onSubmit}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <Container
          maxWidth="md"
          style={{
            flexGrow: 1,
            backgroundColor: '#fff',
            minWidth: '300px',
            padding: '14px',
            border: '1px solid #f3f3f3',
            borderRadius: '14px',
            boxShadow: '0 20px 25px -5px rgba(0,0,0,.1), 0 10px 10px -5px rgba(0,0,0,.04)',
          }}
        >
          <Grid alignItems="stretch" container direction="column" spacing={1}>
            <Grid item md={12}>
              <Controller
                as={
                  <TextField
                    label="Логин"
                    fullWidth
                    error={!!errors.email}
                    helperText={errors.email && errors.email.message}
                  />
                }
                control={control}
                name="email"
                rules={{ required: true }}
              />
            </Grid>
            <Grid item>
              <Controller
                as={
                  <TextField
                    label="Пароль"
                    type="password"
                    fullWidth
                    error={!!errors.password}
                    helperText={errors.password && errors.password.message}
                  />
                }
                control={control}
                name="password"
                rules={{ required: true }}
              />
            </Grid>

            <Grid container item justify="center" style={{ marginTop: 10 }}>
              <Button type="submit" variant="contained" color="primary">
                Войти
              </Button>
            </Grid>
            <Grid container item justify="flex-end" alignItems="flex-end">
              <Button style={{ textTransform: 'none' }} size="small" onClick={() => setOpen(true)}>
                Забыли пароль?
              </Button>
            </Grid>
          </Grid>
        </Container>
      </form>
    </AuthTemplate>
  );
};

export default LoginPage;
