import { Button, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import {
  ClassifierByIdDocument,
  ClassifierByIdQuery,
  ClassifierByIdQueryHookResult,
  ClassifierByIdQueryResult,
  ClassifierValue,
  ClassifierValueArchiveMutationHookResult,
  ClassifierValueUnArchiveMutationHookResult,
  CreateClassifierValueDocument,
  CreateClassifierValueMutationHookResult,
  CreateGenderAndAgeGroupMutationHookResult,
  UpdateClassifierValueMutationHookResult,
  UpdateGenderAndAgeGroupMutationHookResult,
  useClassifierByIdQuery,
  useClassifierValueArchiveMutation,
  useClassifierValueUnArchiveMutation,
  useCreateClassifierValueMutation,
  useCreateGenderAndAgeGroupMutation,
  useGenderAndAgeGroupsLazyQuery,
  useUniqueValidationClassifierValueLazyQuery,
  useUniqueValidationClassifierValueQuery,
  useUpdateClassifierValueMutation,
  useUpdateGenderAndAgeGroupMutation,
} from '../../../api';
import React, { FC, useEffect, useMemo, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import ArchiveComponent from '../../Dialogs/Archive/Archive';
import EditIcon from '@material-ui/icons/Edit';
import { Input } from '../../Inputs';
import SaveIcon from '@material-ui/icons/Save';
import { isEqual as _isEqual } from 'lodash';
import getMessage from '../../../messages';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import Radios from '../../Inputs/Radio';

type ClassifierValueProps = Pick<ClassifierValue, 'fullName' | 'shortName' | 'id' | 'order'>;

const ClassifierValuesWidget: FC<{ readonly?: boolean; id: string }> = ({ readonly, id }) => {
  const { data, refetch, loading } = useClassifierByIdQuery({ variables: { id }, fetchPolicy: 'no-cache' });
  const { data: exist, refetch: existFetch } = useUniqueValidationClassifierValueQuery({ skip: true });
  const [fetchGenderAndAgeGroup, { data: genderAndAgeGroupData }] = useGenderAndAgeGroupsLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const [fetchGenderAndAgeGroupList, { data: genderAndAgeGroupListData }] = useGenderAndAgeGroupsLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const { handleSubmit, register, control, errors, reset, formState } = useForm({
    defaultValues: {
      order: null,
      fullName: '',
      shortName: '',
      id: undefined,
      genre: undefined,
    } as ClassifierValueProps & { genre?: string },
  });
  const [mode, setMode] = useState<'list' | 'form'>('list');
  const [edit, setEdit] = useState<null | ClassifierValueProps>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [create]: CreateClassifierValueMutationHookResult = useCreateClassifierValueMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: ClassifierByIdDocument,
        data: {
          ...data?.createClassifierValue,
        },
      });
    },
  });

  const [createGenderAndAgeGroups]: CreateGenderAndAgeGroupMutationHookResult = useCreateGenderAndAgeGroupMutation();
  const [updateGenderAndAgeGroups]: UpdateGenderAndAgeGroupMutationHookResult = useUpdateGenderAndAgeGroupMutation();

  const isAgeGroup = useMemo(() => _.get(data, 'classifier.registryNumber', 0) === 100000116, [data]);

  const [archive]: ClassifierValueArchiveMutationHookResult = useClassifierValueArchiveMutation();
  const [unarchive]: ClassifierValueUnArchiveMutationHookResult = useClassifierValueUnArchiveMutation();

  const [update]: UpdateClassifierValueMutationHookResult = useUpdateClassifierValueMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: ClassifierByIdDocument,
        data: {
          ...data?.updateClassifierValue,
        },
      });
    },
  });

  const fetchGenderData = () => {
    if (_.get(data, 'classifier.values', []).length > 0) {
      fetchGenderAndAgeGroupList({
        variables: {
          filter: {
            clsAgeGroups: {
              id_in: _.get(data, 'classifier.values', []).map((v) => v?.id),
            },
          },
        },
      });
    }
  };

  const onSubmit = handleSubmit(async (values: ClassifierValueProps & { genre?: string }) => {
    const variableProps = {
      fullName: values.fullName || '',
      shortName: values.shortName || '',
      order: (values.order && +values.order) || 0,
    };
    const variables = {
      id,
      ...variableProps,
    };

    const existAgeGroup = _.get(genderAndAgeGroupData, 'dirGenderAndAgeGroupses[0]', undefined);
    const isMale = _.get(values, 'genre', undefined);
    const existIsMale = _.get(genderAndAgeGroupData, 'dirGenderAndAgeGroupses[0].isMale', undefined);
    const isExist = _isEqual(
      { fullName: edit?.fullName, shortName: edit?.shortName, order: edit?.order },
      variableProps,
    );

    const isExistFullFields = await existFetch({ fullName: variables.fullName });
    if (isExistFullFields?.data?.classifierValues.length) {
      const existVariables = {
        fullName: isExistFullFields?.data?.classifierValues[0].fullName,
        shortName: isExistFullFields?.data?.classifierValues[0].shortName,
        order: isExistFullFields?.data?.classifierValues[0].order,
        isMale: existIsMale,
      };
      const newVariables = {
        fullName: variables.fullName,
        shortName: variables.shortName,
        order: variables.order,
        isMale, //: (isMale && JSON.parse(isMale)) || undefined,
      };

      if (_isEqual(existVariables, newVariables)) {
        enqueueSnackbar('Такие значения классификатора уже существуют', {
          variant: 'error',
        });
        return false;
      }
    }

    try {
      if (edit) {
        if (isAgeGroup && isMale !== undefined && isMale !== existIsMale) {
          await (existAgeGroup
            ? updateGenderAndAgeGroups({
                variables: {
                  id: existAgeGroup?.id,
                  data: {
                    isMale: JSON.parse(isMale),
                  },
                },
              })
            : createGenderAndAgeGroups({
                variables: {
                  data: {
                    clsAgeGroups: {
                      connect: {
                        id: values?.id,
                      },
                    },
                    isMale: JSON.parse(isMale),
                  },
                },
              }));
        }
        if (!isExist) {
          await update({
            variables: { ...variables, id: values.id },
          });
        }
        setEdit(null);
        enqueueSnackbar(`Значение классификатора "${values?.fullName}" успешно обновлена`, {
          variant: 'success',
        });
      } else if (!isExist) {
        await create({
          variables,
        }).then(async (result) => {
          if (result && isMale !== undefined) {
            const newValueId = result?.data?.createClassifierValue?.id;
            if (newValueId) {
              await createGenderAndAgeGroups({
                variables: {
                  data: {
                    clsAgeGroups: {
                      connect: {
                        id: newValueId,
                      },
                    },
                    isMale: JSON.parse(isMale),
                  },
                },
              });
            }
          }
        });

        enqueueSnackbar(`Значение классификатора "${values?.fullName}" успешно добавлена`, {
          variant: 'success',
        });
      }
      reset({ order: null, fullName: '', shortName: '', id: undefined, genre: undefined });
      refetch();
      fetchGenderData();
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
    setMode('list');
  });

  const getGender = (id: string) => {
    const f = _.get(genderAndAgeGroupListData, 'dirGenderAndAgeGroupses', []).find((g) => g?.clsAgeGroups?.id === id)
      ?.isMale;
    return f === false ? 'жен.' : f === true ? 'муж.' : '';
  };

  useEffect(() => {
    if (data?.classifier) {
      reset({
        ...data?.classifier,
      });
    }
  }, [reset, data]);

  useEffect(() => {
    if (genderAndAgeGroupData?.dirGenderAndAgeGroupses) {
      const value = {
        ...edit,
        genre:
          typeof _.get(genderAndAgeGroupData, 'dirGenderAndAgeGroupses[0].isMale', undefined) === 'boolean'
            ? `${_.get(genderAndAgeGroupData, 'dirGenderAndAgeGroupses[0].isMale', false)}`
            : undefined,
      };
      reset(value);
    }
  }, [edit, genderAndAgeGroupData, reset]);

  useEffect(() => {
    if (edit?.id) {
      fetchGenderAndAgeGroup({
        variables: {
          filter: {
            clsAgeGroups: {
              id: edit?.id,
            },
          },
        },
      });
    }
  }, [edit, data, fetchGenderAndAgeGroup]);

  useEffect(() => {
    fetchGenderData();
  }, [data, fetchGenderAndAgeGroupList]);

  return (
    <Grid container>
      {!readonly && !(mode === 'form' || edit) && (
        <Grid item md={12}>
          <IconButton
            onClick={() => {
              setMode('form');
              reset({ order: null, fullName: '', shortName: '', id: undefined });
            }}
            edge="end"
            aria-label="add"
            type="button"
          >
            <AddIcon color="primary" />
          </IconButton>
        </Grid>
      )}
      {(mode === 'form' || edit) && (
        <form onSubmit={onSubmit} style={{ minWidth: '100%' }}>
          <Grid item md={12} container spacing={2} direction="row">
            <input type="hidden" name="id" ref={register()} />
            <Grid item md={5}>
              <Input
                label="Полное название"
                error={!!errors['fullName']}
                errorMessage={errors['fullName']?.message}
                name="fullName"
                control={control}
                rules={{
                  required: true,
                }}
              />
            </Grid>
            <Grid item md={2}>
              <Input
                label="Краткое название"
                control={control}
                error={!!errors['shortName']}
                name="shortName"
                rules={{ required: true }}
              />
            </Grid>
            <Grid item md={2}>
              <Input label="Порядковый номер" type="number" control={control} error={!!errors['order']} name="order" />
            </Grid>
            {isAgeGroup && genderAndAgeGroupData && (
              <Grid item md={12}>
                <Radios
                  label="Пол"
                  data={[
                    {
                      value: 'true',
                      label: 'муж',
                    },
                    {
                      value: 'false',
                      label: 'жен',
                    },
                  ]}
                  control={control}
                  error={!!errors['genre']}
                  errorMessage={errors['genre']?.message || ''}
                  name="genre"
                  rules={{
                    validate: (val) => {
                      return val === null ? 'Выберите значение' : undefined;
                    },
                  }}
                />
              </Grid>
            )}
            <Grid item md={12} container spacing={1}>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  type="button"
                  onClick={() => {
                    setMode('list');
                    setEdit(null);
                  }}
                  size="small"
                >
                  Отмена
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  type="submit"
                  size="small"
                  startIcon={<SaveIcon color="primary" />}
                  disabled={!formState.isDirty}
                >
                  Сохранить
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
      {mode === 'list' && !edit && (
        <Grid item md={6}>
          <List>
            {data?.classifier?.values?.map((item) => (
              <ListItem key={item.id} alignItems="flex-start">
                <ListItemText style={{ width: 150 }} primary={item.fullName} />
                <ListItemText primary={getGender(item?.id)} />
                {!readonly && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="редактировать"
                      onClick={() => {
                        reset(item);
                        setEdit(item);
                      }}
                    >
                      <EditIcon color="primary" />
                    </IconButton>
                    <ArchiveComponent
                      id={item.id}
                      archiveStatus={item.archive}
                      refetch={refetch}
                      archive={archive}
                      unarchive={unarchive}
                      canUnArchive
                    />
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            ))}
          </List>
        </Grid>
      )}
    </Grid>
  );
};

export default ClassifierValuesWidget;
