import React, { FC, useEffect, useMemo, useState } from 'react';
import { useAthletesInTeamLazyQuery, Scalars, useSimilarAthletesLazyQuery, useGenderAndAgeGroupsQuery } from '../api';
import Select from '../components/Inputs/SelectDeep';
import { ControllerProps } from 'react-hook-form/dist/types/props';
import useDebouncedSearch from '../hooks/useDebouncedSearch';
import _ from 'lodash';

interface IAthletes extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  error: boolean | undefined;
  eventId: Scalars['UUID'];
  deniedAthletes: Scalars['UUID'][];
  isMale?: boolean;
}

const AthletesInTeam: FC<IAthletes> = ({ label, error, control, name, rules, eventId, deniedAthletes, isMale }) => {
  console.log('isMale', isMale);

  const similarFilter = useMemo(
    () => ({
      id_not_in: deniedAthletes,
      ...(isMale !== undefined && { isMale }),
    }),
    [deniedAthletes, isMale],
  );

  const [fetchAthletes, { data: athletesData, loading: athletesLoading }] = useSimilarAthletesLazyQuery({
    variables: { search: '', ...similarFilter },
  });

  const [fetch, { data, loading, refetch }] = useAthletesInTeamLazyQuery({
    variables: { eventId },
  });
  const [parsed, setParsed] = useState<any>([]);

  const refetchAthletes = async (value: string) => {
    if (value?.length > 2) {
      await fetchAthletes({ variables: { search: value, ...similarFilter } });
    }
  };
  const useSearchAthletes = () => useDebouncedSearch((text) => refetchAthletes(text));

  const { setInputValue: setValue } = useSearchAthletes();

  useEffect(() => {
    if (!athletesLoading && athletesData?.dirPersonsSimilar) {
      const athletesIdArray = athletesData?.dirPersonsSimilar?.map((p) => p?.person?.athleteProfile?.id);
      setParsed([]);
      fetch({
        variables: {
          eventId,
          athletesIdArray,
        },
      });
    }
  }, [athletesLoading, athletesData?.dirPersonsSimilar, eventId, fetch]);

  useEffect(() => {
    if (!loading) {
      const athletesIdArray = athletesData?.dirPersonsSimilar?.map((p) => p?.person?.athleteProfile?.id) || [];
      const finallyArray = [];

      data?.dirNationalTeams?.map((team) =>
        team?.athleteGroups?.map((group) =>
          group?.athletes?.map((athlete) => {
            if (athletesIdArray.indexOf(athlete?.dirAthlete?.id) > -1) {
              finallyArray.push(athlete?.dirAthlete);
            }
          }),
        ),
      );
      setTimeout(() => {
        setParsed(_.uniq(finallyArray) || []);
      }, 0);
    }
  }, [data, loading, athletesData?.dirPersonsSimilar]);
  return (
    <Select
      enablePortal
      needFilter={false}
      label={label}
      data={parsed}
      control={control}
      error={error}
      name={name}
      rules={rules}
      onChangeInput={setValue}
      loading={loading || athletesLoading}
    />
  );
};

export default AthletesInTeam;
