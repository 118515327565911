import { Button, Grid } from '@material-ui/core';
import {
  CreateUserMutationHookResult,
  CreateUserMutationVariables,
  DirCalendar,
  DirFederalDistrict,
  DirOrganization,
  DirRegion,
  DirSportingEvent,
  FcpsrSportingEventOrganizerProfile,
  Maybe,
  RegionalAdministratorProfile,
  RegionalMinsportProfile,
  RegionalSchoolProfile,
  RegularUser,
  RoivSpecialistProfile,
  UpdateUserMutationHookResult,
  UpdateUserMutationVariables,
  UserRole,
  useCreateUserMutation,
  useUpdateUserMutation,
  useUserLazyQuery,
  useUsersQuery,
  GskRefereeProfile,
  DirSport,
} from '../../../api';
import { DatePicker, Input } from '../../Inputs';
import React, { FC, useEffect, useMemo } from 'react';

import Calendars from '../../../module/Calendars';
import { ContentWrapperWidget } from '../../layouts';
import { FederalDistricts } from '../../../module';
import Organizations from '../../../module/Organizations';
import Radios from '../../Inputs/Radio';
import Regions from '../../../module/Regions';
import SaveIcon from '@material-ui/icons/Save';
import { Scalars } from '../../../types/graphql';
import SportingEvents from '../../../module/SportingEvents';
import UserRoles from '../../../module/UserRoles';
import _ from 'lodash';
import getMessage from '../../../messages';
import { isFulfilled } from './helpers';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import TypeSportsSelect from '../../../module/TypeSportsSelect';

interface MainInfoFormProps {
  userRole: {
    value: UserRole['id'];
    label: UserRole['description'];
  } | null;
  name?: string | null;
  email: string;
  birthday?: string | null;
  isActive?: string | null;
  firstname?: string | null;
  lastname?: string | null;
  patronymic?: string | null;
  isMale?: boolean | string | null;
  regular?: Maybe<
    { __typename?: 'RegularUser' } & Pick<
      RegularUser,
      'address' | 'birthday' | 'firstname' | 'lastname' | 'patronymic' | 'isMale' | 'position' | 'phone'
    >
  >;
  regionalMinsportProfile?: Maybe<
    { __typename?: 'RegionalMinsportProfile' } & Pick<RegionalMinsportProfile, 'id'> & {
        dirRegion?: Maybe<{ __typename?: 'DirRegion' } & { value: DirRegion['id']; label: DirRegion['fullName'] }>;
        dirFederalDistrict?: Maybe<
          { __typename?: 'DirFederalDistrict' } & {
            value: DirFederalDistrict['id'];
            label: DirFederalDistrict['fullName'];
          }
        >;
      }
  >;
  regionalAdministratorProfile?: Maybe<
    { __typename?: 'RegionalAdministratorProfile' } & Pick<RegionalAdministratorProfile, 'id'> & {
        dirRegion?: Maybe<{ __typename?: 'DirRegion' } & { value: DirRegion['id']; label: DirRegion['fullName'] }>;
      }
  >;
  regionalSchoolProfile?: Maybe<
    { __typename?: 'RegionalSchoolProfile' } & Pick<RegionalSchoolProfile, 'id'> & {
        dirOrganization: { __typename?: 'DirOrganization' } & {
          value: DirOrganization['id'];
          label: DirOrganization['name'];
        };
      }
  >;
  roivSpecialistProfile?: Maybe<
    { __typename?: 'RoivSpecialistProfile' } & Pick<RoivSpecialistProfile, 'id'> & {
        dirRegion: { __typename?: 'DirRegion' } & {
          value: DirRegion['id'];
          label: DirRegion['fullName'];
        };
      }
  >;
  fcpsrSportingEventOrganizerProfile?: Maybe<
    { __typename?: 'FcpsrSportingEventOrganizerProfile' } & Pick<FcpsrSportingEventOrganizerProfile, 'id'> & {
        dirSports: Array<
          { __typename?: 'DirSport' } & {
            value: DirSport['id'];
            label: DirSport['fullName'];
          }
        > | null;
        dirCalendars: Array<
          { __typename?: 'DirCalendar' } & {
            value: DirCalendar['id'];
            label: DirCalendar['fullName'];
          }
        > | null;
      }
  >;
  gskRefereeProfile?: Maybe<
    { __typename?: 'GskRefereeProfile' } & Pick<GskRefereeProfile, 'id'> & {
        sportingEvents: Array<
          { __typename?: 'DirSportingEvent' } & {
            value: DirSportingEvent['id'];
            label: DirSportingEvent['name'];
          }
        > | null;
      }
  >;
  dirRegion?: Maybe<{ __typename?: 'DirRegion' } & { value: DirRegion['id']; label: DirRegion['fullName'] }>;
  sportingEvents?: Maybe<
    { __typename?: 'DirSportingEvent' } & { value: DirSportingEvent['id']; label: DirSportingEvent['name'] }
  >[];
  dirCalendars?: Maybe<{ __typename?: 'DirCalendar' } & { value: DirCalendar['id']; label: DirCalendar['fullName'] }>[];
  dirSports?: Maybe<{ __typename?: 'DirSport' } & { value: DirCalendar['id']; label: DirCalendar['fullName'] }>[];
  dirFederalDistrict?: Maybe<
    { __typename?: 'DirFederalDistrict' } & {
      value: DirFederalDistrict['id'];
      label: DirFederalDistrict['fullName'];
    }
  >;
  dirOrganization: { __typename?: 'DirOrganization' } & {
    value: DirOrganization['id'];
    label: DirOrganization['name'];
  };
}

const MainInfo: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const [getUser, { data }] = useUserLazyQuery({ fetchPolicy: 'no-cache' });
  const { refetch: refetchListUsers } = useUsersQuery({ skip: true, fetchPolicy: 'no-cache' });
  const { handleSubmit, control, errors, reset, formState, watch, setError } = useForm({
    defaultValues: {
      userRole: null,
      name: '',
      email: '',
    } as MainInfoFormProps,
  });
  const { push } = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [create]: CreateUserMutationHookResult = useCreateUserMutation();
  const [update]: UpdateUserMutationHookResult = useUpdateUserMutation();
  const watchUserRole = watch('userRole');

  const hasOrganization = useMemo(() => data?.user?.regular?.dirOrganization, [data]);
  const removeSavedProfile = useMemo(
    () => watchUserRole?.value !== 'REGIONAL_SCHOOL' && Boolean(data?.user?.regionalSchoolProfile),
    [data, watchUserRole],
  );

  const onSubmit = async (values: MainInfoFormProps) => {
    const { userRole, dirCalendars, dirOrganization, sportingEvents } = values;

    if (userRole?.value === 'FCPSR_SPORTING_EVENT_OPERATOR' && (dirCalendars?.length || 0) > 0 && !dirOrganization) {
      enqueueSnackbar('Укажите организацию', { variant: 'error' });
      return;
    }

    if (userRole?.value === 'GSK_REFEREE' && (sportingEvents?.length || 0) === 0) {
      enqueueSnackbar('Укажите мероприятия судьи ГСК', { variant: 'error' });
      setError('sportingEvents', {});
      return;
    }
    try {
      const variables: UpdateUserMutationVariables | CreateUserMutationVariables = {
        role: values.userRole?.value as string,
        name: values.name,
        email: values?.email,
      };

      const regular = {
        birthday: values?.birthday,
        firstname: values?.firstname,
        lastname: values?.lastname,
        patronymic: values?.patronymic,
        isMale: (values?.isMale === 'true') as boolean,
        dirOrganization: values?.dirOrganization?.value
          ? { connect: { id: values.dirOrganization?.value } }
          : hasOrganization
          ? { disconnect: true }
          : null,
      };
      if (values.userRole?.value === 'REGIONAL_MINSPORT') {
        variables.regional = {
          [values.regionalMinsportProfile && values.regionalMinsportProfile.id ? 'update' : 'create']: {
            dirRegion: values.dirRegion?.value ? { connect: { id: values.dirRegion?.value } } : null,
            dirFederalDistrict: values.dirFederalDistrict?.value
              ? { connect: { id: values.dirFederalDistrict?.value } }
              : null,
          },
        };
      }
      if (values.userRole?.value === 'REGIONAL_ADMINISTRATOR') {
        variables.regionalAdministratorProfile = {
          [values.regionalAdministratorProfile && values.regionalAdministratorProfile.id ? 'update' : 'create']: {
            dirRegion: values.dirRegion?.value ? { connect: { id: values.dirRegion?.value } } : null,
          },
        };
      }

      if (values.userRole?.value === 'REGIONAL_SCHOOL') {
        variables.school = {
          [values.regionalSchoolProfile && values.regionalSchoolProfile.id ? 'update' : 'create']: {
            dirOrganization: values?.dirOrganization ? { connect: { id: values?.dirOrganization.value } } : null,
          },
        };
      }
      if (values.userRole?.value === 'ROIV_SPECIALIST' || values.userRole?.value === 'FCPSR_ROIV_SPECIALIST') {
        variables.roivSpecialistProfile = {
          [values.roivSpecialistProfile && values.roivSpecialistProfile.id ? 'update' : 'create']: {
            dirRegion: values?.dirRegion ? { connect: { id: values?.dirRegion.value } } : null,
          },
        };
      }
      if (values.userRole?.value === 'FCPSR_SPORTING_EVENT_OPERATOR') {
        variables.fcpsrSportingEventOrganizerProfile = {
          [values.fcpsrSportingEventOrganizerProfile && values.fcpsrSportingEventOrganizerProfile.id
            ? 'update'
            : 'create']: {
            dirSports: values?.dirSports ? { connect: values?.dirSports?.map((e) => ({ id: e?.value })) } : null,
            dirCalendars: values?.dirCalendars
              ? { connect: values?.dirCalendars?.map((e) => ({ id: e?.value })) }
              : null,
          },
        };
      }
      if (values.userRole?.value === 'GSK_REFEREE') {
        variables.gskRefereeProfile = {
          [values.gskRefereeProfile && values.gskRefereeProfile.id ? 'update' : 'create']: {
            dirSportingEvents: values?.sportingEvents
              ? { connect: values?.sportingEvents?.map((e) => ({ id: e?.value })) }
              : null,
          },
        };
      }
      if (id !== 'new') {
        await update({
          variables: {
            ...variables,
            ...(removeSavedProfile ? { school: { delete: true } } : {}),
            id,
            userID: id as string,
            isActive: (values?.isActive === 'true') as boolean,
            regular: {
              [data?.user?.regular === null ? 'create' : 'update']: {
                ...regular,
              },
            },
            ...(values.userRole?.value === 'ROIV_SPECIALIST' && {
              personProfile: {
                [data?.user?.personProfile === null ? 'create' : 'update']: {
                  dirRegion: {
                    connect: { id: values.dirRegion?.value },
                  },
                },
              },
            }),
          },
        });
        enqueueSnackbar(`Пользователь "${values?.email}" успешно обновлен`, { variant: 'success' });
      } else {
        const { data: created } = await create({
          variables,
        });
        await update({
          variables: {
            ...variables,
            userID: created?.signup.user.id as string,
            isActive: (values?.isActive === 'true') as boolean,
            id: created?.signup.user.id,
            regular: {
              create: {
                ...regular,
              },
            },
            ...(values.userRole?.value === 'ROIV_SPECIALIST' && {
              personProfile: {
                [data?.user?.personProfile === null ? 'create' : 'update']: {
                  dirRegion: {
                    connect: { id: values.dirRegion?.value },
                  },
                },
              },
            }),
          },
        });
        if (created?.signup.token) {
          enqueueSnackbar(`Пользователь "${values?.email}" успешно добавлен`, { variant: 'success' });
          refetchListUsers();
          push(`/user/${created?.signup?.user.id}`);
        }
      }
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  useEffect(() => {
    if (id !== 'new') {
      getUser({ variables: { id } });
    }
  }, [getUser, id]);

  useEffect(() => {
    if (data?.user) {
      reset({
        ...data?.user,
        ...data?.user.regular,
        isMale: data?.user.regular?.isMale ? 'true' : 'false',
        isActive: data?.user.isActive ? 'true' : 'false',
        userRole: data?.user.role,
        dirRegion:
          data?.user.regionalMinsportProfile?.dirRegion ||
          data?.user.regionalAdministratorProfile?.dirRegion ||
          data?.user.roivSpecialistProfile?.dirRegion,
        dirFederalDistrict: data?.user.regionalMinsportProfile?.dirFederalDistrict,
        dirOrganization:
          data?.user.regionalSchoolProfile?.dirOrganization || data?.user.regular?.dirOrganization || undefined,
        sportingEvents: _.get(data, 'user.gskRefereeProfile.sportingEvents', undefined),
        dirSports: _.get(data, 'user.fcpsrSportingEventOrganizerProfile.dirSports', undefined),
        dirCalendars: _.get(data, 'user.fcpsrSportingEventOrganizerProfile.dirCalendars', []),
      });
    }
  }, [data, reset]);

  return (
    <>
      <Grid container>
        <Grid item lg={9} md={12} xs={12}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <ContentWrapperWidget>
              <Grid container direction="column" spacing={1}>
                <Grid item md={6}>
                  <UserRoles
                    label="Роли пользователя"
                    control={control}
                    error={!!errors['userRole']}
                    name="userRole"
                    rules={{ required: true }}
                    disabled={readonly}
                  />
                </Grid>
                <Grid item md={6}>
                  <Input
                    label="Email"
                    control={control}
                    error={!!errors['email']}
                    name="email"
                    rules={{
                      required: true,
                      pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    }}
                    disabled={readonly}
                  />
                </Grid>
                <Grid item md={6}>
                  <Input
                    label="Фамилия"
                    control={control}
                    error={!!errors['lastname']}
                    name="lastname"
                    rules={{ required: true }}
                    disabled={readonly}
                  />
                </Grid>
                <Grid item md={6}>
                  <Input
                    label="Имя"
                    control={control}
                    error={!!errors['firstname']}
                    name="firstname"
                    rules={{ required: true }}
                    disabled={readonly}
                  />
                </Grid>
                <Grid item md={6}>
                  <Input
                    label="Отчество"
                    control={control}
                    error={!!errors['patronymic']}
                    name="patronymic"
                    disabled={readonly}
                  />
                </Grid>
                <Grid item md={6}>
                  <Radios
                    label="Пол"
                    data={[
                      {
                        value: 'true',
                        label: 'Муж',
                      },
                      {
                        value: 'false',
                        label: 'Жен',
                      },
                    ]}
                    control={control}
                    error={!!errors['isMale']}
                    name="isMale"
                    rules={{ required: true }}
                    disabled={readonly}
                  />
                </Grid>
                {watchUserRole?.label === 'REGIONAL_MINSPORT' && (
                  <>
                    <Grid item md={6}>
                      <FederalDistricts
                        label="Федеральный округ"
                        control={control}
                        error={!!errors['dirFederalDistrict']}
                        name="dirFederalDistrict"
                        rules={{ required: true }}
                        disabled={readonly}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Regions
                        label="Регион"
                        control={control}
                        error={!!errors['dirRegion']}
                        name="dirRegion"
                        rules={{ required: true }}
                        disabled={readonly}
                      />
                    </Grid>
                  </>
                )}
                <Grid item md={6}>
                  <DatePicker
                    label="Дата рождения"
                    control={control}
                    error={!!errors['birthday']}
                    name="birthday"
                    rules={{ required: true }}
                    disabled={readonly}
                  />
                </Grid>
                {(watchUserRole?.value === 'REGIONAL_ADMINISTRATOR' ||
                  watchUserRole?.value === 'ROIV_SPECIALIST' ||
                  watchUserRole?.value === 'FCPSR_ROIV_SPECIALIST') && (
                  <>
                    <Grid item md={6}>
                      <Regions
                        label="Регион"
                        control={control}
                        error={!!errors['dirRegion']}
                        name="dirRegion"
                        {...(!readonly && { rules: { required: true } })}
                        disabled={readonly}
                      />
                    </Grid>
                  </>
                )}
                {['GSK_REFEREE'].includes(watchUserRole?.value) && (
                  <>
                    <Grid item md={6}>
                      <SportingEvents
                        label="Мероприятия судьи ГСК"
                        control={control}
                        error={!!errors['sportingEvents']}
                        name="sportingEvents"
                        multiple
                        rules={{ required: watchUserRole?.value === 'GSK_REFEREE' }}
                        filter={{
                          dirCalendar: {
                            type: {
                              id: 2,
                            },
                          },
                        }}
                        disabled={readonly}
                      />
                    </Grid>
                  </>
                )}
                {['FCPSR_SPORTING_EVENT_OPERATOR'].includes(watchUserRole?.value) && (
                  <>
                    <Grid item md={6}>
                      <TypeSportsSelect
                        label="Виды спорта"
                        control={control}
                        error={!!errors['dirSports']}
                        name="dirSports"
                        multiple
                        disabled={readonly}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Calendars
                        label="Календари"
                        error={!!errors['dirCalendars']}
                        control={control}
                        name="dirCalendars"
                        multiple
                        notInitialized
                        isSportEvent
                      />
                    </Grid>
                  </>
                )}
                <Grid item md={6}>
                  <Organizations
                    label="Организация"
                    control={control}
                    error={!!errors['dirOrganization']}
                    name="dirOrganization"
                    rules={{
                      required:
                        watchUserRole?.value === 'REGIONAL_SCHOOL' ||
                        watchUserRole?.value === 'FCPSR_SPORTING_EVENT_OPERATOR',
                    }}
                    disabled={readonly}
                  />
                </Grid>
                <Grid item md={6}>
                  <Radios
                    label="Статус"
                    data={[
                      {
                        value: 'true',
                        label: 'Активирован',
                      },
                      {
                        value: 'false',
                        label: 'Заблокирован',
                      },
                    ]}
                    control={control}
                    error={!!errors['isActive']}
                    name="isActive"
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item md={12}>
                  <Button
                    variant="outlined"
                    color="primary"
                    type="submit"
                    size="small"
                    startIcon={<SaveIcon color="primary" />}
                    disabled={!formState.isDirty || readonly}
                  >
                    Сохранить
                  </Button>
                </Grid>
              </Grid>
            </ContentWrapperWidget>
          </form>
        </Grid>
      </Grid>
    </>
  );
};

export default MainInfo;
