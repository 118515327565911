import React, { FC, useState } from 'react';
import {
  Button,
  IconButton,
  Grid,
  Typography,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useForm } from 'react-hook-form';
import getMessage from '../../../../messages';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useSnackbar } from 'notistack';
import {
  UpdatePersonAdditionalRegionsMutationHookResult,
  useUpdatePersonAdditionalRegionsMutation,
  Scalars,
} from '../../../../api';
import ConfirmDialog from '../../../ConfirmDialog';
import Radios from '../../../Inputs/Radio';
import Regions from '../../../../module/Regions';
import { Countries } from '../../../../module';
import ForeignCities from '../../../../module/ForeignCities';

interface formState {
  dirRegion?: { label: string; value: Scalars['UUID'] };
  dirCountry?: { label: string; value: Scalars['UUID'] };
  dirForeignCity?: { label: string; value: Scalars['UUID'] };
  isRussia: string;
}

const AddRegionsBox: FC<{ readonly?: boolean; person: any; updated: Function }> = ({ readonly, person, updated }) => {
  const [isAddingNew, setIsAddingNew] = useState(false);
  const [deleteItem, setDeleteItem] = useState<any>(null);
  const [update]: UpdatePersonAdditionalRegionsMutationHookResult = useUpdatePersonAdditionalRegionsMutation();

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const defaultState: formState = {
    dirRegion: undefined,
    dirCountry: undefined,
    dirForeignCity: undefined,
    isRussia: 'true',
  };

  const onHandleCancel = () => {
    setIsAddingNew(false);
    setDeleteItem(null);
    reset({ isRussia: 'true' });
  };

  const { handleSubmit, control, errors, reset, watch, formState } = useForm({
    defaultValues: defaultState,
  });

  let isRussia = watch('isRussia');
  const watchCountry = watch('dirCountry');

  const onSubmit = async (values: formState) => {
    const { dirRegion, dirCountry, dirForeignCity } = values;
    const isRussia = values?.isRussia === 'true';
    try {
      await update({
        variables: {
          data: {
            create: [
              {
                ...(!isRussia && dirCountry && { dirCountry: { connect: { id: dirCountry?.value } } }),
                ...(!isRussia && dirForeignCity && { dirForeignCity: { connect: { id: dirForeignCity?.value } } }),
                ...(isRussia && dirRegion && { dirRegion: { connect: { id: dirRegion?.value } } }),
                isRussia,
              },
            ],
          },
          id: person?.id,
        },
      });
      updated();
      onHandleCancel();
      handleSnackBar('success', 'регион успешно добавленно');
    } catch (e) {
      handleSnackBar('error', getMessage(e.message));
    }
  };

  const onDeleteRegion = async (regionId: Scalars['UUID']) => {
    try {
      await update({
        variables: {
          data: {
            delete: [
              {
                id: regionId,
              },
            ],
          },
          id: person?.id,
        },
      });
      updated();
      onHandleCancel();
      handleSnackBar('success', 'регион успешно удалена');
    } catch (e) {
      handleSnackBar('error', getMessage(e.message));
    }
  };

  if (!person?.id) return null;

  return (
    <>
      <Grid container style={{ marginBottom: 15 }} alignItems="flex-end" justify={'space-between'} spacing={2}>
        <Grid item>
          <Typography style={{ fontWeight: 600, fontSize: 16 }}>Дополнительные Регионы</Typography>
        </Grid>

        {!readonly && (
          <Grid item>
            <IconButton color="primary" size="small" type="button" onClick={() => setIsAddingNew(true)}>
              <AddIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>

      <Grid container style={{ marginBottom: 10, maxHeight: 250, overflowY: 'auto' }} alignItems="center" spacing={2}>
        {person?.additionalRegions?.map((element: any) => (
          <Grid item container key={element?.id} justify={'space-between'} alignItems={'center'}>
            <Grid item>
              {element?.dirRegion?.label ||
                `${element.dirCountry?.label}${(element.dirForeignCity && `, ${element.dirForeignCity?.label}`) || ''}`}
            </Grid>
            {!readonly && (
              <Grid item>
                <Tooltip title="удалить" placement="top">
                  <IconButton color="secondary" type="button" onClick={() => setDeleteItem(element)}>
                    <DeleteForeverIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        ))}
      </Grid>

      <Dialog open={isAddingNew} onClose={onHandleCancel}>
        <DialogTitle>Дополнительный Регион</DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit((values) => onSubmit(values))} style={{ width: '100%' }}>
            <Grid container spacing={2}>
              <Grid item container>
                <Radios
                  label="Страна"
                  data={[
                    {
                      value: 'true',
                      label: 'Россия',
                    },
                    {
                      value: 'false',
                      label: 'Зарубеж',
                    },
                  ]}
                  control={control}
                  error={!!errors['isRussia']}
                  name="isRussia"
                  rules={{ required: true }}
                  disabled={readonly}
                />
              </Grid>
              {isRussia === 'true' && (
                <Grid item container>
                  <Regions
                    label="Регион РФ"
                    control={control}
                    error={!!errors['dirRegion']}
                    name="dirRegion"
                    rules={{
                      required: true,
                      validate: async (val) => (val?.value ? undefined : 'Поле обязательное для заполнения'),
                    }}
                    disabled={readonly}
                    enablePortal
                  />
                </Grid>
              )}
              {isRussia === 'false' && (
                <>
                  <Grid item container>
                    <Countries
                      label="Название страны"
                      control={control}
                      error={!!errors['dirCountry']}
                      name="dirCountry"
                      rules={{
                        required: true,
                        validate: async (val) => (val?.value ? undefined : 'Поле обязательное для заполнения'),
                      }}
                      disabled={readonly}
                      enablePortal
                    />
                  </Grid>
                  {watchCountry?.value && (
                    <Grid item container>
                      <ForeignCities
                        label="Название города"
                        control={control}
                        filter={{ dirCountry: { id: watchCountry?.value } }}
                        error={!!errors['dirForeignCity']}
                        name="dirForeignCity"
                        disabled={readonly}
                        enablePortal
                      />
                    </Grid>
                  )}
                </>
              )}
            </Grid>
            <DialogActions>
              <Button
                style={{ width: '100%' }}
                type={'submit'}
                color={'primary'}
                variant={'outlined'}
                disabled={!formState.isDirty}
              >
                Добавить
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
      <ConfirmDialog
        title={`Удаление дополнительного региона`}
        text={`Вы уверены что хотите удалить : ${
          deleteItem?.dirRegion?.label ||
          `${deleteItem?.dirCountry?.label}${
            (deleteItem?.dirForeignCity && `, ${deleteItem?.dirForeignCity?.label}`) || ''
          }`
        } ?`}
        btnVariant="outlined"
        open={!!deleteItem}
        saveBtnText="удалить"
        cancelBtnText="отмена"
        saveBtnColor="secondary"
        cancelBtnColor="primary"
        onCancel={() => setDeleteItem(null)}
        onSave={() => onDeleteRegion(deleteItem?.id)}
      />
    </>
  );
};

export default AddRegionsBox;
