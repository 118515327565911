import React, { FC, Fragment, MouseEvent, useEffect, useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import { ReactComponent as Calculator } from './calculator.svg';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import _ from 'lodash';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      padding: theme.spacing(2),
      fontWeight: 600,
    },
  }),
);

interface CountType {
  query: any;
  filter?: any;
  hasSimilarQuery?: boolean;
}

const Count: FC<CountType> = ({ query, filter, hasSimilarQuery }) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const [fetch, { data }] = query();

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const open = Boolean(anchorEl);
  const id = open ? 'count-popover' : undefined;

  useEffect(() => {
    const { name, ...otherFilter } = filter;
    if (open)
      fetch({
        variables: hasSimilarQuery ? { name: name || '', filter: otherFilter } : { filter },
      });
  }, [open, fetch, filter, hasSimilarQuery]);

  return (
    <Fragment>
      <Tooltip title="Показать количество" placement="top">
        <IconButton onClick={handleOpen} color="primary">
          <Calculator style={{ width: 25, height: 25 }} />
        </IconButton>
      </Tooltip>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Typography className={classes.typography} color="primary">
          {hasSimilarQuery && filter?.name ? _.get(data, _.keys(data)[0], []).length : data?.count}
        </Typography>
      </Popover>
    </Fragment>
  );
};

export default Count;
