import React, { FC, Fragment, useState } from 'react';
import { Grid, Typography, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import { Scalars } from '../../../../api';
import ArchiveComponent from '../../../Dialogs/Archive/Archive';
import PersonalTrainerBox from './PersonalTrainerBox';
import GroupDisciplines from './GroupDisciplines';
import Role from './Role';
import RankAwards from './RanksAwards';
import OrgAccordion from './OrgAccordion';
import CompetitionAccordion from './Competition';
import NationalTeamAccordion from './NationalTeam';
import {
  TypeSportAthleteArchiveMutationHookResult,
  useTypeSportAthleteArchiveMutation,
  TypeSportAthleteUnArchiveMutationHookResult,
  useTypeSportAthleteUnArchiveMutation,
} from '../../../../api';

const useStyles = makeStyles({
  row: {
    display: 'flex',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  tinyIcon: {
    fontSize: 18,
  },
  icon: {
    fontSize: 20,
  },
  tableHead: {
    fontWeight: 'bold',
  },
  header: {
    fontWeight: 600,
    fontSize: 16,
    letterSpacing: 1.2,
  },
  verticalSpace: {
    marginTop: 5,
    marginBottom: 5,
  },
});

interface formState {
  organization?: { label: string; value: any };
  trainingStage?: { label: any; value: any };
  trainer?: { label: string; value: Scalars['UUID'] };
  trainingStart?: Date;
  exclusionDate?: Date;
  isSelfTraining?: string;
  orgId?: Scalars['UUID'];
}

const SportAccordion: FC<{
  readonly?: boolean;
  sport: any;
  onUpdate: Function;
  regionsId: Array<string>;
  athleteId: string;
}> = ({ readonly, sport, onUpdate, regionsId, athleteId }) => {
  const classes = useStyles();

  const [archive]: TypeSportAthleteArchiveMutationHookResult = useTypeSportAthleteArchiveMutation();
  const [unarchive]: TypeSportAthleteUnArchiveMutationHookResult = useTypeSportAthleteUnArchiveMutation();

  return (
    <Accordion key={sport.id}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${sport.dirSport?.label}-content`}
        id={`${sport.dirSport?.label}-header`}
      >
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Typography className={classes.header} color="primary">
              {sport.dirSport.label}
            </Typography>
          </Grid>
          {!readonly && (
            <Grid item onClick={(e) => e.stopPropagation()}>
              <ArchiveComponent
                id={sport?.id}
                archiveStatus={sport.archive}
                canUnArchive
                unarchive={unarchive}
                refetch={onUpdate}
                archive={archive}
              />
            </Grid>
          )}
        </Grid>
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: 'column' }}>
        <Grid container>
          <Role id={sport?.id} data={sport?.role || ''} refetch={onUpdate} readonly={readonly || sport?.archive} />
        </Grid>
        <Grid container spacing={1}>
          <Grid item container md={6} xs={12}>
            <PersonalTrainerBox sport={sport} readonly={readonly || sport?.archive} onUpdate={() => onUpdate()} />
          </Grid>
          <Grid item container md={6} xs={12}>
            <GroupDisciplines
              id={sport?.id}
              idSport={sport?.dirSport?.value}
              data={sport?.disciplineGroups}
              refetch={onUpdate}
              readonly={readonly || sport?.archive}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.verticalSpace}>
          <RankAwards
            id={sport?.id}
            idSport={sport?.dirSport?.value}
            data={sport?.ranksAndAwards}
            refetch={onUpdate}
            readonly={readonly || sport?.archive}
          />
        </Grid>
        <Grid container className={classes.verticalSpace}>
          <OrgAccordion sport={sport} onUpdate={onUpdate} regionsId={regionsId} readonly={readonly || sport?.archive} />
        </Grid>
        <Grid container className={classes.verticalSpace}>
          <CompetitionAccordion id={athleteId} sportId={sport?.dirSport?.value} />
        </Grid>
        <Grid container className={classes.verticalSpace}>
          <NationalTeamAccordion id={athleteId} sportId={sport?.dirSport?.value} />
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default SportAccordion;
