import React, { FC } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import _ from 'lodash';
import { useCalendarGRegulationsQuery } from '../../../../../api';
import { generateDoc } from './helpers';

export const GeneralRegulationBtn: FC<{ id?: string }> = ({ id }) => {
  const { data } = useCalendarGRegulationsQuery({
    skip: !id || id === 'new',
    variables: { id },
    fetchPolicy: 'no-cache',
  });

  const handleGeneralRegulation = () => {
    try {
      const sportCompetitionRegulationDocument = _.get(data, 'dirCalendar.sportCompetitionRegulationDocument.path', '');
      const filePaths: string[] = [];
      for (const item of _.get(data, 'dirCalendar.dirSportEventRegulations', [])) {
        if (_.get(item, 'status[0].isActive', false) && _.get(item, 'status[0].value.value', 0) === 5) {
          const agreedStatus = (item?.status || []).find((status) => _.get(status, 'value.value', 0) === 2);
          if (agreedStatus && agreedStatus.file) {
            filePaths.push(agreedStatus.file.path);
          }
        }
      }

      const result = [sportCompetitionRegulationDocument, ...filePaths]
        .filter((n) => n)
        .map((n) => n.replace('/files/common/', ''));

      result.length > 0 && generateDoc(result);
    } catch (error) {
      return;
    }
  };

  return (
    <Box>
      <Button variant="contained" color="primary" onClick={handleGeneralRegulation}>
        Сгенерировать итоговый регламент
      </Button>
      <Typography color="error" display="block">
        *Генерируется из добавленных word-версий документов
      </Typography>
    </Box>
  );
};
