import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import {
  USER_LOGGING_IN,
  USER_LOGGED_IN,
  SET_ADDITIONAL_USER_INFO,
  USER_LOGGED_OUT,
  CLEAR_JOIN,
  SET_PRIMARY,
  SET_SECONDARY,
  TRANSFER_PERSON,
  HYDRATE_TABLE,
  RESET_TABLE,
  SET_CAN_FETCH_MORE,
  SET_FIRST,
} from '../constants';
import { History } from 'history';
import { ActionJoin, ActionTable, ActionUser } from '../../types/types';
import { LoginMutation } from '../../api';
import { settingsReducer, SettingsStore } from './settings';
function joinReducer(state = { idPrimary: null, idSecondary: null }, action: ActionJoin) {
  switch (action.type) {
    case CLEAR_JOIN:
      return { ...state, idPrimary: null, idSecondary: null };
    case SET_PRIMARY:
      return { ...state, idPrimary: action.payload };
    case SET_SECONDARY:
      return { ...state, idSecondary: action.payload };
    case TRANSFER_PERSON:
      const idSecondary = state.idSecondary;
      return {
        ...state,
        idSecondary: state.idPrimary,
        idPrimary: idSecondary,
      };
    default:
      return state;
  }
}

const initialUser = {
  user: {},
};

function userReducer(state = initialUser, action: ActionUser) {
  switch (action.type) {
    case USER_LOGGING_IN:
      return { ...initialUser };
    case USER_LOGGED_IN:
      localStorage.setItem('token', action.payload.id);
      return { ...action.payload };
    case SET_ADDITIONAL_USER_INFO:
      const {
        signin: { token, user },
      }: LoginMutation = action.payload;
      localStorage.setItem('token', token);
      return {
        ...state,
        user: user || {},
      };
    case USER_LOGGED_OUT:
      localStorage.removeItem('token');
      return {
        userId: null,
        id: null,
      };
    default:
      return state;
  }
}

function tableReducer(state = { items: [], canFetchMore: true, skip: 0, first: 10 }, action: ActionTable) {
  switch (action.type) {
    case HYDRATE_TABLE:
      const newItems = [...state.items, ...action.payload];
      console.log(state.items, action.payload, state.skip);

      return { ...state, items: newItems, skip: newItems.length };
    case RESET_TABLE:
      return { ...state, items: [], canFetchMore: true, skip: 0 };
    case SET_CAN_FETCH_MORE:
      return { ...state, canFetchMore: action.payload };
    case SET_FIRST:
      return {
        ...state,
        first: action.payload,
      };
    default:
      return state;
  }
}

export type AppStore = {
  settings: SettingsStore;
  router: any;
  user: any;
  joinPerson: any;
  table: any;
};

export default function createReducer(history: History) {
  return combineReducers<AppStore>({
    router: connectRouter(history),
    user: userReducer,
    joinPerson: joinReducer,
    settings: settingsReducer,
    table: tableReducer,
  });
}
