import { Grid, Tooltip } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import {
  PersonFrFragment,
  useGeneratePersonsXlsFileLazyQuery,
  usePersonsLazyQuery,
  usePersonsSimilarLazyQuery,
} from '../../api';
import React, { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { getSettingsUserFilter, getSettingsUserFilterFormValue } from '../../store/selectors/settings';
import { useDispatch, useSelector } from 'react-redux';

import { AppStore } from '../../store/reducers';
import { BtnNew } from '../../components/BtnNew';
import { DataTable } from '../../components/DataTable/NewTable';
import { DictionaryWrapper } from '../../components/DictionaryWrapper';
import { MUIDataTableColumn } from 'mui-datatables';
import { MainTemplate } from '../../components/layouts';
import { PersonsFilter } from '../../components/Filters';
import { SET_USER_SETTINGS_FILTER } from '../../store/constants';
import UniBox from '../../components/DupliBox';
import _ from 'lodash';
import { downloadB64File } from '../../utils/downloadB64File';
import moment from 'moment';
import { usePermission } from '../../hooks/usePermission';
import userRights from '../../config/userRights';

const Page: FC<{}> = () => {
  const { hasAdditionalFilter } = userRights();

  const dispatch = useDispatch();
  const filter: any = useSelector((state: AppStore) => getSettingsUserFilter(state, 'persons'), _.isEqual);
  const formValue: any = useSelector((state: AppStore) => getSettingsUserFilterFormValue(state, 'persons'), _.isEqual);
  const { push } = useHistory();
  const access = usePermission('directory', 'person');
  const [first, setFirst] = useState(0);
  const [fetch, { data: binaryFile }] = useGeneratePersonsXlsFileLazyQuery();

  const setFilter = useCallback(
    (filter, form) => {
      dispatch({
        type: SET_USER_SETTINGS_FILTER,
        payload: {
          name: 'persons',
          filter: filter,
          form,
        },
      });
    },
    [dispatch],
  );

  const downLoadFile = async (filterString: string) => {
    await fetch({
      variables: {
        filterString,
        filter,
        first,
      },
    });
  };

  useEffect(() => {
    if (binaryFile) {
      const {
        dirPersonsReport: { xlsx },
      } = binaryFile;
      if (xlsx) {
        downloadB64File('persons', xlsx);
      }
    }
  }, [binaryFile]);

  const columns: MUIDataTableColumn[] = [
    {
      name: 'id',
      label: 'ФИО',
      options: {
        filter: false,
        sort: false,
        viewColumns: false,
        customBodyRender: (value: Pick<PersonFrFragment, 'id'>, meta: any) => {
          const { rowData } = meta;
          const fio = [rowData[1] || '', rowData[2] || '', rowData[3] || ''].join(' ');
          return (
            <Tooltip title="Перейти в карточку" placement="top-start">
              <Link
                style={{
                  fontWeight: 500,
                  cursor: 'pointer',
                  textDecoration: 'none',
                }}
                to={`/person/${value}`}
                target={'_blank'}
              >
                {fio}
              </Link>
            </Tooltip>
          );
        },
      },
    },
    {
      name: 'lastname',
      label: 'Фамилия',
      options: {
        sort: false,
        viewColumns: false,
        display: false,
      },
    },
    {
      name: 'firstname',
      label: 'Имя',
      options: {
        sort: false,
        viewColumns: false,
        display: false,
      },
    },
    {
      name: 'patronymic',
      label: 'Отчество',
      options: {
        sort: false,
        viewColumns: false,
        display: false,
      },
    },
    {
      name: 'birthday',
      label: 'Дата рождения',
      options: {
        filter: false,
        sort: false,
        display: true,

        customBodyRender: (value) => {
          return moment(value).format('DD.MM.YYYY');
        },
      },
    },
    {
      name: 'isMale',
      label: 'Пол',
      options: {
        filter: false,
        display: true,

        sort: false,
        customBodyRender: (value: Pick<PersonFrFragment, 'isMale'>) => {
          return value ? 'Муж' : 'Жен';
        },
      },
    },
    {
      name: 'registryNumber',
      label: 'Реестровый номер',
      options: {
        filter: false,
        display: true,

        sort: false,
      },
    },
    {
      name: 'archive',
      label: ' ',
      options: {
        filter: false,
        display: true,
        sort: false,
        viewColumns: false,
        customBodyRender: (value: any, meta: any) => {
          return value ? null : (
            <Fragment>
              <UniBox
                fio={`${meta.rowData[1] || ''} ${meta.rowData[2] || ''} ${meta.rowData[3] || ''}`}
                idPerson={meta.rowData[0]}
              />
            </Fragment>
          );
        },
      },
    },
  ];

  return (
    <MainTemplate pageName="Персоны" url="" title="Персоны">
      <PersonsFilter
        onSubmit={(filters: any, form: any) => setFilter({ ...filters }, form)}
        value={formValue}
        first={first}
        defaultFilter={{ ...hasAdditionalFilter?.persons }}
      />
      <DictionaryWrapper>
        <DataTable
          tableName="persons"
          title={
            <React.Fragment>
              <Grid container spacing={2} alignItems={'center'}>
                <Grid item>
                  <h2>Справочник персон</h2>
                </Grid>
                {access.includes(2) && <Grid item>{<BtnNew onClick={() => push('/person/new')} />}</Grid>}
              </Grid>
            </React.Fragment>
          }
          columns={columns}
          hasDoubleQuery
          lazyQuery={filter?.name ? usePersonsSimilarLazyQuery : usePersonsLazyQuery}
          revealFunctionName={filter?.name ? 'persons' : undefined}
          filter={{ ...filter, ...hasAdditionalFilter?.persons }}
        />
      </DictionaryWrapper>
    </MainTemplate>
  );
};

export default Page;
