import Xlsx from 'exceljs';
import { SportingEventAthletesByPointsRegionType } from '../../../api';
import { saveAs } from 'file-saver';

export type FetchCalendarReport = {
  data: Data;
  fileName: string;
};

export type Data = Array<SportingEventAthletesByPointsRegionType>;

export const fetchSportingEventMedalReport: (props: FetchCalendarReport) => void = async (props) => {
  try {
    const { data = [], fileName } = props;
    const hasUniversity = data?.filter((d) => d?.universityName).length > 0;
    const mergeStart = 2;
    const lastTableColumnLetter = hasUniversity ? 'I' : 'H';

    const workbook = new Xlsx.Workbook();
    workbook.addWorksheet();

    const saveFile = async () => {
      const buffer = await workbook.xlsx.writeBuffer({
        useStyles: true,
      });
      const blob = new Blob([buffer], { type: 'applicationi/xlsx' });
      fileName && saveAs(blob, `${fileName}.xlsx`);
    };

    const sheet = workbook.worksheets[0];

    const drawCurrentLastRowBorder = (lastColNumber: number) => {
      const lastTableRow = sheet.getRow(lastColNumber);

      lastTableRow.eachCell((cell) => {
        cell.style = {
          ...cell.style,
          border: {
            bottom: {
              style: 'thick',
              color: {
                argb: '000000',
              },
            },
          },
          alignment: {
            vertical: 'middle',
            horizontal: 'center',
            wrapText: true,
            shrinkToFit: true,
          },
        };
      });
    };

    // const mergeCells = (cellsPosition: string) => {
    //   sheet.unMergeCells(cellsPosition);
    //   sheet.mergeCells(cellsPosition);
    // };

    const drawLastColumnBorder = () => {
      for (let index = 0; index < tableTrack.length; index++) {
        const cellName = `${lastTableColumnLetter}${index + mergeStart}`;
        const cell = sheet.getCell(cellName);

        sheet.getCell(cellName).style = {
          ...cell.style,
          border: {
            ...cell.style.border,
            right: {
              style: 'thick',
              color: {
                argb: '000000',
              },
            },
          },
        };
      }
    };

    const tableTrack: number[] = [];

    //header
    sheet.insertRow(1, [
      '№',
      'Сборная региона',
      ...(hasUniversity ? ['ВУЗ'] : []),
      'Кол-во спортсменов всего ',
      'Золото',
      'Серебро',
      'Бронза',
      '4-10 места',
      '11-20 места',
    ]);
    sheet.getRow(1).eachCell((cell) => {
      cell.alignment = {
        vertical: 'middle',
        horizontal: 'center',
      };
    });

    if (data && data.length > 0) {
      for (let index = 0; index < data.length; index++) {
        tableTrack.push(index + 1);
        const item = data[index];
        const rowIndex = mergeStart + tableTrack.length - 1;
        sheet.insertRow(rowIndex, [
          tableTrack.length,
          item.name,
          ...(hasUniversity ? [item.universityName] : []),
          item.athletesNumber,
          Number(item.totalGold),
          Number(item.totalSilver),
          Number(item.totalBronze),
          Number(item.total_4_10),
          Number(item.total_11_20),
        ]);
        sheet.getRow(rowIndex).eachCell((cell) => {
          cell.alignment = {
            vertical: 'middle',
            horizontal: 'center',
          };
        });
      }
    }

    drawCurrentLastRowBorder(mergeStart + tableTrack.length - 1);
    drawLastColumnBorder();

    await saveFile();
  } catch (error) {
    console.log('error', error);
  }
};
