export const checkRolesAndReturnDefaultFilter = () => {
  const who = localStorage.getItem('userRole');
  if (who === 'REGIONAL_ADMINISTRATOR')
    return {
      name_in: ['ROIV_SPECIALIST', 'REGIONAL_SCHOOL'],
    };

  if (who === 'FCPSR_ADMINISTRATOR' || who === 'FCPSR_SPECIALIST')
    return {
      name_in: [
        'FCPSR_SPORTING_EVENT_OPERATOR',
        'FCPSR_SPECIALIST',
        'FCPSR_ADMINISTRATOR',
        'FCPSR_ROIV_SPECIALIST',
        'REGIONAL_SCHOOL',
        'GSK_REFEREE',
      ],
    };

  if (who === 'FCPSR_SPORTING_EVENT_OPERATOR')
    return {
      name_in: ['GSK_REFEREE'],
    };
  else if (who === 'ROIV_SPECIALIST')
    return {
      name_in: ['ROIV_SPECIALIST', 'REGIONAL_SCHOOL'],
    };
  else return {};
};
