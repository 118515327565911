import exceljs from 'exceljs';
import { AthleteCompetitionResultFragment, CompetitionsResultsFragmentFragment, DirAthlete } from '../../../api';
import { saveAs } from 'file-saver';
import moment from 'moment';
import _ from 'lodash';

export type FetchEventCompetitionReport = {
  data: Data;
  mergeCellsPosition?: Array<[string, string]>;
  fileName: string;
  mergeStart?: number;
  teams?: any;
  isUniverciad?: boolean;
  event?: CompetitionsResultsFragmentFragment;
  programType?: any;
};

export type Data = Array<AthleteCompetitionResultFragment>;

export const fetchEventCompetitionReport: (props: FetchEventCompetitionReport) => void = async (props) => {
  try {
    const { data = [], fileName, teams = [], event, isUniverciad, programType } = props;

    const workbook = new exceljs.Workbook();
    const blancUrl = isUniverciad
      ? '/templates/blancCompetWithUniverciadReport.xlsx'
      : '/templates/blancCompetReport.xlsx';

    const saveFile = async () => {
      const buffer = await workbook.xlsx.writeBuffer({
        useStyles: true,
      });
      const blob = new Blob([buffer], { type: 'applicationi/xlsx' });
      saveAs(blob, fileName);
    };
    const setCellText = (position: string, value: string) => {
      sheet.getCell(position).value = value;
    };
    const blanc = await (await fetch(blancUrl)).arrayBuffer();
    await workbook.xlsx.load(blanc);
    const sheet = workbook.worksheets[0];

    if (isUniverciad) {
      sheet.getColumn('D').eachCell((cell, index) => {
        if (index > 5) {
          cell.style.alignment = {
            ...cell.style.alignment,
            wrapText: true,
          };
        }
      });
    }

    const addSignRow = (position: number, values: any[]) => {
      sheet.insertRow(position, values).eachCell((cell, index) => {
        if (index > 3) {
          cell.style.alignment = {
            horizontal: 'center',
            vertical: 'middle',
          };
        }
      });
    };

    const primaryReferee = _.find(
      event?.referees,
      (referee) => referee.clsRefereePosition.id === '4e4e7ff9-e7ec-41c1-84f6-e3aea9246cb5',
    )?.dirReferee?.dirPerson;

    const primarySecretaryReferee = _.find(
      event?.referees,
      (referee) => referee.clsRefereePosition.id === '7026b40a-365d-4422-a8f8-12c9da114a43',
    )?.dirReferee?.dirPerson;

    const [first, ...others] = data;
    const firstCellData = [
      `${first.point || ''}${first.pointTo ? ` - ${first.pointTo}` : ''}`.trim(),
      `${first?.dirAthlete?.dirPerson?.lastname} ${first?.dirAthlete?.dirPerson?.firstname} ${
        first?.dirAthlete?.dirPerson?.patronymic || ''
      }`,
      ...(isUniverciad
        ? [
            teams?.find((t: any) => t?.athletesArray?.find((a: DirAthlete) => a?.id === first?.dirAthlete?.id))
              ?.university?.name || '',
          ]
        : []),
      moment(first?.dirAthlete?.dirPerson?.birthday).format('DD.MM.YY'),
      teams?.find((t: any) => t?.athletesArray?.find((a: DirAthlete) => a?.id === first?.dirAthlete?.id))?.region
        ?.label || '',
      first?.result,
      first?.score || '',
    ];
    let i = 0;
    sheet.getRow(6).eachCell((cell) => {
      cell.value = firstCellData[i];
      i += 1;
    });

    const freeResults = others.filter((r) => !r.score);
    const fullResults = others.filter((r) => r.score);

    fullResults.forEach((result) => {
      const row = [
        '',
        `${result.point || ''}${result.pointTo ? ` - ${result.pointTo}` : ''}`.trim(),
        `${result?.dirAthlete?.dirPerson?.lastname} ${result?.dirAthlete?.dirPerson?.firstname} ${
          result?.dirAthlete?.dirPerson?.patronymic || ''
        }`,
        ...(isUniverciad
          ? [
              teams?.find((t: any) => t?.athletesArray?.find((a: DirAthlete) => a?.id === result?.dirAthlete?.id))
                ?.university?.name || '',
            ]
          : []),
        moment(result?.dirAthlete?.dirPerson?.birthday).format('DD.MM.YY'),
        teams?.find((t: any) => t?.athletesArray?.find((a: DirAthlete) => a?.id === result?.dirAthlete?.id))?.region
          ?.label || '',
        result?.result || '',
        result?.score || '',
      ];
      sheet.addRow(row, 'i');
    });
    freeResults.forEach((result) => {
      const row = [
        '',
        `${result.point || ''}${result.pointTo ? ` - ${result.pointTo}` : ''}`,
        `${result?.dirAthlete?.dirPerson?.lastname} ${result?.dirAthlete?.dirPerson?.firstname} ${
          result?.dirAthlete?.dirPerson?.patronymic || ''
        }`,
        ...(isUniverciad
          ? [
              teams?.find((t: any) => t?.athletesArray?.find((a: DirAthlete) => a?.id === result?.dirAthlete?.id))
                ?.university?.name || '',
            ]
          : []),
        moment(result?.dirAthlete?.dirPerson?.birthday).format('DD.MM.YY'),
        teams?.find((t: any) => t?.athletesArray?.find((a: DirAthlete) => a?.id === result?.dirAthlete?.id))?.region
          ?.label || '',
        result?.noResultReason?.fullName || '',
        '',
      ];
      sheet.addRow(row, 'i');
    });

    const sportType = _.get(event, 'sports[0].dirSport.fullName', '');
    const discipline = _.get(programType, 'discipline.label');
    const calendarName = _.get(event, 'dirCalendar.fullName', '');
    const stageName = _.get(event, 'clsEventStages[0].fullName', '');
    const minAge = _.get(programType, 'minAge', '') ? `${_.get(programType, 'minAge', '')}-` : 'по ';
    const sportCategory =
      _.get(programType, 'clsSportCategory.fullName', '') || _.get(programType, 'clsAgeGroups[0].label', '');
    const eventName = `  ${calendarName} - ${stageName} - ${sportType}`;

    const primaryRefereePosition = data.length + 8;
    const primarySecretaryRefereePosition = data.length + 10;

    const primaryRefereeFIO = [
      _.get(primaryReferee, 'lastname', ''),
      _.get(primaryReferee, 'firstname', ''),
      _.get(primaryReferee, 'patronymic', ''),
    ].join(' ');
    const primarySecretaryRefereeFIO = [
      _.get(primarySecretaryReferee, 'lastname', ''),
      _.get(primarySecretaryReferee, 'firstname', ''),
      _.get(primarySecretaryReferee, 'patronymic', ''),
    ].join(' ');

    addSignRow(primaryRefereePosition, ['', '', 'Главный судья ', '______________', primaryRefereeFIO]);
    addSignRow(primaryRefereePosition + 1, ['', '', '', 'подпись', 'Ф.И.О.']);
    addSignRow(primarySecretaryRefereePosition, [
      '',
      '',
      'Главный секретарь ',
      '______________',
      primarySecretaryRefereeFIO,
    ]);
    addSignRow(primarySecretaryRefereePosition + 1, ['', '', '', 'подпись', 'Ф.И.О.']);

    if (isUniverciad) {
      sheet.mergeCells(`E${primaryRefereePosition}:F${primaryRefereePosition}`);
      sheet.mergeCells(`E${primaryRefereePosition + 1}:F${primaryRefereePosition + 1}`);
      sheet.mergeCells(`E${primarySecretaryRefereePosition}:F${primarySecretaryRefereePosition}`);
      sheet.mergeCells(`E${primarySecretaryRefereePosition + 1}:F${primarySecretaryRefereePosition + 1}`);
    }

    setCellText(isUniverciad ? 'B1:H1' : 'B1:G1', `  ${calendarName} - ${stageName} - ${sportType}`);
    if (eventName.length > 90) {
      sheet.getRow(1).height = 50;
    }
    setCellText(isUniverciad ? 'B2:F2' : 'B2:E2', `  ${event?.venue || ''},${event?.object || ''}`);
    setCellText(isUniverciad ? 'G2:H2' : 'F2:G2', `  ${programType?.start || ''}`);
    setCellText(
      isUniverciad ? 'E3:F3' : 'D3:E3',
      `  ${discipline}, ${sportCategory}, ${minAge}${_.get(programType, 'maxAge', '')}`,
    );

    await saveFile();
  } catch (error) {}
};
