import { Button, Divider, Grid, Typography } from '@material-ui/core';
import {
  CreateSportingEventMutationHookResult,
  DirSportingEvent,
  UpdateSportingEventMutationHookResult,
  useCreateSportingEventMutation,
  useUpdateSportingEventMutation,
} from '../../../../api';
import { DatePicker, Input } from '../../../Inputs';
import React, { FC } from 'react';

import Calendars from '../../../../module/Calendars';
import CategorySportingEvents from '../../../../module/CategorySportingEvents';
import EventStages from '../../../../module/EventStages';
import SaveIcon from '@material-ui/icons/Save';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
interface EventInfo {
  classes?: any;
  infoUpdated: Function;
  newCreated: Function;
  onError: Function;
  dirSportingEvent?: any;
  readOnly?: boolean;
}

const Info: FC<EventInfo> = ({ classes, infoUpdated, newCreated, dirSportingEvent, onError, readOnly }) => {
  const id = dirSportingEvent?.id;
  const [update]: UpdateSportingEventMutationHookResult = useUpdateSportingEventMutation();
  const [createNewEvent]: CreateSportingEventMutationHookResult = useCreateSportingEventMutation();

  const { handleSubmit, control, errors, formState } = useForm({
    defaultValues: {
      ...dirSportingEvent,
      clsEventCategories: dirSportingEvent?.clsEventCategories[0],
    } as DirSportingEvent,
  });

  const onSubmit = async (value: DirSportingEvent) => {
    const { name, startDate, endDate, venue, ekp, object, clsEventStages, daysBeforeStartToAddProtocols } = value;
    const dirCalendar =
      (value.dirCalendar && {
        connect: {
          id: _.get(value, 'dirCalendar.value'),
        },
      }) ||
      (dirSportingEvent.dirCalendar &&
        dirSportingEvent.dirCalendar.id && {
          set: {
            id: dirSportingEvent.dirCalendar.value,
          },
        });
    try {
      if (id) {
        update({
          variables: {
            data: {
              name,
              startDate,
              endDate,
              venue,
              ekp,
              object,
              dirCalendar,
              daysBeforeStartToAddProtocols: Number(daysBeforeStartToAddProtocols),
              ...((_.get(value, 'clsEventCategories.value') && {
                clsEventCategories: {
                  set: [
                    {
                      id: _.get(value, 'clsEventCategories.value'),
                    },
                  ],
                },
              }) ||
                (_.get(dirSportingEvent, 'clsEventCategories[0]') &&
                  !_.get(value, 'clsEventCategories.value') && {
                    clsEventCategories: {
                      disconnect: [
                        {
                          id: _.get(dirSportingEvent, 'clsEventCategories[0].value'),
                        },
                      ],
                    },
                  }) ||
                {}),
              clsEventStages: {
                set: clsEventStages?.map((stage) => ({ id: _.get(stage, 'value') })),
              },
            },
            id,
          },
        }).then((resp) => {
          infoUpdated(true);
        });
      } else {
        createNewEvent({
          variables: {
            data: {
              name,
              startDate,
              endDate,
              venue,
              ekp,
              object,
              dirCalendar,
              daysBeforeStartToAddProtocols: Number(daysBeforeStartToAddProtocols),
              ...((_.get(value, 'clsEventCategories.value') && {
                clsEventCategories: {
                  connect: [{ id: _.get(value, 'clsEventCategories.value') }],
                },
              }) ||
                {}),
              clsEventStages: {
                connect: clsEventStages?.map((stage) => ({ id: _.get(stage, 'value') })),
              },
              statuses: {
                create: [
                  {
                    value: { connect: { id: 1 } },
                  },
                ],
              },
              status: {
                connect: {
                  id: 1,
                },
              },
            },
          },
        }).then((resp: any) => {
          const { createDirSportingEvent } = resp.data;
          newCreated(createDirSportingEvent && createDirSportingEvent.id);
        });
      }
    } catch (error) {
      onError(error);
    }
  };

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Grid container style={{ marginTop: '1.5rem' }}>
          <Grid item container xs={12} justify="space-between" className={classes.verticalSpaceMarge}>
            <Grid item xs={5}>
              <Calendars
                label="Календарь"
                control={control}
                error={!!errors['dirCalendar']}
                name="dirCalendar"
                isSportEvent
                filter={{ archive: null }}
                rules={{ required: true }}
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={5}>
              <Input
                label="Название мероприятия"
                control={control}
                error={!!errors['name']}
                name="name"
                rules={{ required: true }}
                disabled={readOnly}
              />
            </Grid>
          </Grid>
          <Grid item container justify="space-between" className={classes.verticalSpaceMarge}>
            <Grid item container xs={5} spacing={2}>
              <Grid item xs={12}>
                <Typography>Период проведения:</Typography>
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <DatePicker
                  label="от"
                  control={control}
                  error={!!errors['startDate']}
                  name="startDate"
                  rules={{ required: true }}
                  disabled={readOnly}
                />
              </Grid>
              <Grid item lg={6} md={6} xs={12}>
                <DatePicker
                  label="до"
                  control={control}
                  error={!!errors['endDate']}
                  name="endDate"
                  rules={{ required: true }}
                  disabled={readOnly}
                />
              </Grid>
            </Grid>
            <Grid item xs={5}>
              <Input
                label="Место проведения"
                control={control}
                error={!!errors['venue']}
                name="venue"
                rules={{ required: true }}
                disabled={readOnly}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} justify="space-between" className={classes.verticalSpaceMarge}>
            <Grid item xs={5}>
              <Input
                label="Номер ЕКП Минспорта"
                control={control}
                error={!!errors['ekp']}
                name="ekp"
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={5}>
              <EventStages
                label="Этапы"
                control={control}
                error={!!errors['clsEventStages']}
                name="clsEventStages"
                disabled={readOnly}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} justify="space-between" className={classes.verticalSpaceMarge}>
            <Grid item xs={5}>
              <Input
                label="Спортивный объект"
                control={control}
                error={!!errors['object']}
                name="object"
                disabled={readOnly}
              />
            </Grid>
            <Grid item xs={5}>
              <CategorySportingEvents
                label="Категория мероприятия"
                control={control}
                error={!!errors['clsEventCategories']}
                name="clsEventCategories"
                disabled={readOnly}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} justify="space-between" className={classes.verticalSpaceMarge}>
            <Grid item xs={5}>
              <Input
                label="Кол-во дней до даты начала для добавления протоколов I этапа"
                control={control}
                type="number"
                positive
                error={!!errors['daysBeforeStartToAddProtocols']}
                name="daysBeforeStartToAddProtocols"
                disabled={readOnly}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container justify="flex-end" className={classes.verticalSpace}>
          <Button
            variant="outlined"
            color="primary"
            size="small"
            startIcon={<SaveIcon />}
            type="submit"
            disabled={!formState.isDirty}
          >
            Сохранить
          </Button>
        </Grid>
        <Grid container>
          <Divider className={classes.divider} />
        </Grid>
      </form>
    </React.Fragment>
  );
};

export default Info;
