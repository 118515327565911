import React, { useState, useCallback, useMemo } from 'react';
import { Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import { Calendar as ICalendar } from '../Calendar';
import '../Calendar/main.css';
import { monthsLabel, weekDaysLabel } from './constants';
import moment from 'moment';
moment.tz.setDefault('Africa/Bamako');
moment.locale('ru');

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 10,
      borderRadius: 10,
      border: '1px solid black',
      '& .rc_root .rc ': {
        width: '500px !important',
        height: '500px !important',
      },
      '& .rc_root ': {
        height: 'calc(100% - 60px)',
      },
      '&  .rc_header span': {
        fontWeight: 'bold',
        fontSize: 20,
      },
      '& .rc_body-weekdays_cell': {
        fontSize: 18,
      },
      '& .rc_body-days-of-month .rc_body-cell button': {
        fontSize: 15,
      },
      '& .rc_body-days-of-month .rc_body-cell.rc_range_start button': {
        fontWeight: 'bold',
      },
      '& .rc_body-days-of-month .rc_body-cell .rc_body-cell_value': {
        borderRadius: '25px',
      },
    },
    root_main: {
      padding: 10,
      borderRadius: 10,
      border: '1px solid black',
      '& .rc_root .rc ': {
        width: '350px !important',
        height: '350px !important',
      },

      '&  .rc_header span': {
        fontWeight: 'bold',
        fontSize: 20,
      },
      '& .rc_body-weekdays_cell': {
        fontSize: 18,
      },
      '& .rc_body-days-of-month .rc_body-cell button': {
        fontSize: 15,
      },
      '& .rc_body-days-of-month .rc_body-cell.rc_range_start button': {
        fontWeight: 'bold',
      },
      '& .rc_body-days-of-month .rc_body-cell .rc_body-cell_value': {
        borderRadius: '25px',
      },
    },
    header: {
      display: 'flex',
      padding: 10,
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
    period: {
      fontSize: 15,
      fontWeight: 500,
    },
    title: {
      fontWeight: 600,
    },
  }),
);

export const Calendar = ({
  onSelect,
  BTComponent,
  year,
  isMain,
  onChangeMonth,
  cellPop,
}: {
  onSelect?: (value: any) => void;
  onChangeMonth: (value: any) => void;
  BTComponent?: React.ReactNode;
  year: number;
  isMain?: boolean;
  cellPop: (day: Date) => React.ReactNode;
}) => {
  const classes = useStyles();
  const [value, setValue] = useState<any[]>([]);

  const onChange = useCallback(
    (value) => {
      setValue(value);
      onSelect && onSelect(value);
    },
    [onSelect],
  );

  const onPSelect = (value) => {
    setValue([value]);
    onSelect && onSelect([value]);
  };

  const showYear = useMemo(
    () => value.length > 1 && moment(value[0]).format('YYYY') !== moment(value[1]).format('YYYY'),
    [value],
  );

  return (
    <div className={isMain ? classes.root_main : classes.root}>
      <div className={classes.header}>
        <div>
          <Typography className={classes.title}>за период:</Typography>
          <Typography className={classes.period}>
            {value[0] &&
              moment(value[0])
                .add(1, 'day')
                .format(showYear ? 'DD MMM YYYY' : 'DD MMM')}

            {value[1] &&
              ` - ${moment(value[1])
                .add(1, 'day')
                .format(showYear ? 'DD MMM YYYY' : 'DD MMM')}`}
          </Typography>
        </div>
        <div>{BTComponent}</div>
      </div>

      <ICalendar
        key={year}
        isRangeSelector
        initialViewDate={new Date(year, 1, 1)}
        weekDaysLabel={weekDaysLabel}
        monthsLabel={monthsLabel}
        value={value}
        cellPop={cellPop}
        onChange={onChange}
        onChangeMonth={onChangeMonth}
        onPartialRangeSelect={onPSelect}
        initialView="months"
      />
    </div>
  );
};
