import { Accordion, AccordionDetails, AccordionSummary, Box, Typography, makeStyles } from '@material-ui/core';
import { FC } from 'react';
import { Maybe } from '../../../../api';
import React from 'react';
import { EventListByYearAndCalendarCategoryAccordion } from './EventListByYearAndCalendarCategoryAccordion';
import ExpandMore from '@material-ui/icons/ExpandMore';

export type CalendarCategoryProps = {
  __typename?: 'ClassifierValue' | undefined;
} & {
  label: string;
  shortLabel: Maybe<string> | undefined;
  value: number;
};

interface EventListByCalendarCategoryAccordionProps {
  calendarCategory?: CalendarCategoryProps;
  regionId?: string;
  calendarYearList?: number[];
}

const useStyles = makeStyles({
  row: {
    display: 'flex',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  tinyIcon: {
    fontSize: 18,
  },
  icon: {
    fontSize: 20,
  },
  tableHead: {
    fontWeight: 'bold',
  },
  header: {
    fontWeight: 600,
    fontSize: 16,
    letterSpacing: 1.2,
  },
  verticalSpace: {
    marginTop: 5,
    marginBottom: 5,
  },
});

export const EventListByCalendarCategoryAccordion: FC<EventListByCalendarCategoryAccordionProps> = ({
  calendarCategory,
  regionId,
  calendarYearList,
}) => {
  const classes = useStyles();

  const canLoad = !!(calendarCategory && regionId && calendarYearList);
  if (!canLoad) return null;

  return (
    <div style={{ margin: '10px 0' }}>
      <Accordion key={calendarCategory?.value}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls={`${calendarCategory?.label}-content`}
          id={`${calendarCategory?.label}-header`}
        >
          <div>
            <Typography className={classes.header} color="primary">
              {calendarCategory?.label}
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: 'column' }}>
          <div>
            {calendarYearList?.map((calendarYear) => (
              <EventListByYearAndCalendarCategoryAccordion
                key={calendarYear}
                calendarYear={calendarYear}
                regionId={regionId}
                calendarCategoryRegistryNumber={calendarCategory?.value}
              />
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
