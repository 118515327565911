import React, { FC } from 'react';
import userRights from '../../../../config/userRights';

import RegionsAccordionList from '../RegionsAccordionList/RegionsAccordionList';
import SportAccordion from '../SportAccordion/SportAccordion';

const FirstEtape: FC<{ id?: string; readonly?: boolean; isUniverciad?: boolean; calendarName?: string }> = ({
  id,
  readonly,
  isUniverciad,
  calendarName,
}) => {
  const { isRoiv, userRegion } = userRights();
  const regionFilters = isRoiv
    ? {
        id: userRegion,
      }
    : undefined;
  return (
    <div style={{ width: '100%' }}>
      {isUniverciad ? (
        <SportAccordion id={id} readonly={readonly} rNumber={100003375} stageRegistryNumber={100002914} />
      ) : (
        <RegionsAccordionList
          isRoiv={isRoiv}
          id={id}
          readonly={readonly}
          calendarName={calendarName}
          regionFilters={regionFilters}
          extendedReadonly
          firstStep
        />
      )}
    </div>
  );
};

export default FirstEtape;
