import { Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import {
  NationalTeamAddAthleteMutationHookResult,
  Scalars,
  useGenderAndAgeGroupsQuery,
  useNationalTeamAddAthleteMutation,
} from '../../../../api';
import React, { FC, useEffect, useMemo, useState } from 'react';

import AddBoxIcon from '@material-ui/icons/AddBox';
import { Input } from '../../../Inputs';
import SearchAthletes from '../../../../module/SearchAthletes';
import { getAthleteFormHint } from '../../../../utils/getAthleteFormHint';
import getMessage from '../../../../messages';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import _ from 'lodash';

interface formState {
  athlete?: { value: Scalars['UUID']; label?: string; personRoleId?: Scalars['UUID'] };
  newAthleteBestResult?: string;
}

const AthleteForm: FC<{
  group: any;
  classes: any;
  region: String;
  university?: String;
  id?: Scalars['UUID'];
  bannedAthletesFromSearch: Array<String>;
  canAddAthlete?: boolean;
  athleteIsInRange?: boolean;
  athleteIsInRangeRank?: boolean;
  onAddAthlete: () => void;
  readonly?: boolean;
  event?: any;
  additionalParams: any;
}> = ({
  group,
  id,
  region,
  university,
  readonly,
  canAddAthlete,
  onAddAthlete,
  classes,
  bannedAthletesFromSearch,
  event,
  athleteIsInRange,
  athleteIsInRangeRank,
  additionalParams,
}) => {
  const { minManBirthYear, maxManBirthYear, minWomanBirthYear, maxWomanBirthYear } = event;

  const { data } = useGenderAndAgeGroupsQuery({
    variables: {
      filter: {
        clsAgeGroups: {
          id_in: _.get(group, 'clsAgeGroups', []).map((n) => n?.id),
        },
      },
    },
    fetchPolicy: 'no-cache',
    skip: _.get(group, 'clsAgeGroups[0].id', '') === '',
  });

  const genderIsMale = useMemo(() => _.get(data, 'dirGenderAndAgeGroupses[0].isMale', undefined), [data]);
  const skipIsMale = useMemo(
    () => _.get(data, 'dirGenderAndAgeGroupses', []).find((n) => n?.isMale !== genderIsMale) !== undefined,
    [data, genderIsMale],
  );

  const [add]: NationalTeamAddAthleteMutationHookResult = useNationalTeamAddAthleteMutation();

  const [hint, setHint] = useState('');

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const defaultState: formState = {
    athlete: undefined,
    newAthleteBestResult: undefined,
  };

  const onHandleCancel = () => {
    reset({});
  };

  const { handleSubmit, control, errors, reset, formState, getValues, watch } = useForm({
    defaultValues: defaultState,
  });

  let athleteWatch = watch('athlete');

  const onSubmit = async (values: formState) => {
    const { athlete, newAthleteBestResult } = values;

    if (canAddAthlete && !range_bool && !range_bool_rank_bool) {
      try {
        if (!bannedAthletesFromSearch.includes(athlete?.personRoleId)) {
          await add({
            variables: {
              id,
              groupId: group.id,
              athleteId: athlete?.personRoleId,
              bestResult: newAthleteBestResult,
            },
          });
          onAddAthlete();
          onHandleCancel();
          handleSnackBar('success', 'Спортсмен  успешно добавлено');
        } else {
          handleSnackBar('error', 'Данный спортсмен уже добавлен в сборную');
        }
      } catch (error) {
        handleSnackBar('error', getMessage(error.message));
      }
    }
  };

  useEffect(() => {
    const values = getValues();
    if (values.athlete) setHint(getAthleteFormHint(values.athlete, group?.sportId, event) || '');
    else setHint('');
  }, [formState]);

  const getDate = (date = '', content: any) => (date ? content : {});

  // const hasForm = useMemo(
  //   () => (ageGroup.trim() === 'мужчины' && isManDateSet) || (ageGroup.trim() === 'женщины' && isWoManDateSet),
  //   [ageGroup, isManDateSet, isWoManDateSet],
  // );

  const athleteProfile = {
    sports_some: {
      dirSport: { id: group?.sportId },
      ...(university && {
        organizations_some: {
          dirOrganization: { id: university },
        },
      }),
    },
  };

  const getAthletesFilter = () =>
    skipIsMale
      ? {
          OR: [
            {
              isMale: false,
              ...getDate(maxWomanBirthYear, {
                birthday_lte: moment([maxWomanBirthYear]).endOf('year').endOf('day'),
              }),
              ...getDate(minWomanBirthYear, { birthday_gte: moment([minWomanBirthYear]).startOf('day') }),
              archive: null,
              dirRegion: { id: region },
              athleteProfile,
            },
            {
              isMale: true,
              ...getDate(maxManBirthYear, { birthday_lte: moment([maxManBirthYear]).endOf('year').endOf('day') }),
              ...getDate(minManBirthYear, { birthday_gte: moment([minManBirthYear]).startOf('day') }),
              archive: null,
              dirRegion: { id: region },
              athleteProfile,
            },
          ],
        }
      : {
          isMale: genderIsMale, // getGenre(isManDateSet, true),
          ...(genderIsMale
            ? {
                ...getDate(maxManBirthYear, { birthday_lte: moment([maxManBirthYear]).endOf('year').endOf('day') }),
                ...getDate(minManBirthYear, { birthday_gte: moment([minManBirthYear]).startOf('day') }),
              }
            : {
                ...getDate(maxWomanBirthYear, { birthday_lte: moment([maxWomanBirthYear]).endOf('year').endOf('day') }),
                ...getDate(minWomanBirthYear, { birthday_gte: moment([minWomanBirthYear]).startOf('day') }),
              }),
          archive: null,
          dirRegion: { id: region },
          athleteProfile,
        };

  const s_filter = () =>
    (genderIsMale
      ? [
          maxManBirthYear
            ? {
                ...getDate(maxManBirthYear, { birthday_gte: moment([maxManBirthYear]).endOf('year').endOf('day') }),
              }
            : {},

          minManBirthYear
            ? {
                ...getDate(minManBirthYear, { birthday_lte: moment([minManBirthYear]).startOf('day') }),
              }
            : {},
        ]
      : [
          maxWomanBirthYear
            ? {
                ...getDate(maxWomanBirthYear, { birthday_gte: moment([maxWomanBirthYear]).endOf('year').endOf('day') }),
              }
            : {},

          minWomanBirthYear
            ? {
                ...getDate(minWomanBirthYear, { birthday_lte: moment([minWomanBirthYear]).startOf('day') }),
              }
            : {},
        ]
    ).filter((n) => n);

  const isNotAddingAthleteOutOfRange = (athlete: any) => {
    const inIntervalYear = (date: any, minYear: any, maxYear: any) => {
      if (!minYear) minYear = 1900;
      if (!maxYear) maxYear = 2200;
      const d = date ? Number(moment(date).format('YYYY')) : 0;
      if (!date || (!minYear && !maxYear)) return false;
      if (minYear && maxYear) return d >= minYear && d <= maxYear;
    };
    return _.get(athlete, 'isMale', false)
      ? !inIntervalYear(_.get(athlete, 'birthday', 0), minManBirthYear, maxManBirthYear)
      : !inIntervalYear(_.get(athlete, 'birthday', 0), minWomanBirthYear, maxWomanBirthYear);
  };

  const range_bool = isNotAddingAthleteOutOfRange(athleteWatch) ? !athleteIsInRange : false;
  const range_bool_rank_bool =
    hint === 'Отсутствует разряд/звание, соответствующий регламенту' ? !athleteIsInRangeRank : false;

  const checkQuotaGender = () => {
    if (additionalParams.athleteQuotaByGender && athleteWatch) {
      if (athleteWatch.isMale) {
        return additionalParams.manQuota >= additionalParams.manFact + 1;
      } else {
        return additionalParams.womanQuota >= additionalParams.womanFact + 1;
      }
    }
    return true;
  };

  let bool = canAddAthlete && checkQuotaGender() && !range_bool && !range_bool_rank_bool;

  return (
    <form onSubmit={handleSubmit((values) => bool && onSubmit(values))} style={{ width: '100%' }}>
      <Grid container spacing={2} alignItems="flex-end">
        <Grid key={_.get(data, 'dirGenderAndAgeGroupses.length', 0)} item md={5} xs={12}>
          <SearchAthletes
            name="athlete"
            label="Спорстмен"
            control={control}
            error={!!errors['athlete']}
            rules={{ required: true }}
            profile="athleteProfile"
            isMale={genderIsMale}
            filter={{
              ...getAthletesFilter(),
            }}
            s_filter={{
              OR: s_filter()
                .filter((n) => Object.keys(n).length > 0)
                .map((i) => ({ ...i, isMale: genderIsMale, dirRegion: { id: region }, archive: null, athleteProfile })),
            }}
          />
        </Grid>
        <Grid item>
          <Input
            label="комментарии"
            control={control}
            error={!!errors['newAthleteBestResult']}
            name="newAthleteBestResult"
          />
        </Grid>
        <Grid item>
          <Tooltip
            title={`
              ${
                (canAddAthlete &&
                checkQuotaGender() &&
                !(range_bool && athleteWatch) &&
                !(range_bool_rank_bool && athleteWatch)
                  ? 'Добавить'
                  : '') || (!canAddAthlete || !checkQuotaGender() ? 'Исчерпан лимит на спортсмены' : '')
              }
              ${
                range_bool && athleteWatch
                  ? 'Достигнут лимит спортсменов в сборной, не подходящих под возрастной диапазон. '
                  : ''
              }
              ${
                range_bool_rank_bool && athleteWatch
                  ? 'Достигнут лимит спортсменов в сборной, не подходящих под требования к спорткваллификации. '
                  : ''
              }
            `}
            placement="top-start"
          >
            <IconButton className={classes.btn} type="submit" color={bool ? 'primary' : 'default'}>
              <AddBoxIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      {hint && <Typography color="error">{hint}</Typography>}
    </form>
  );
};

export default AthleteForm;
