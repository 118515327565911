import React, { FC, useEffect, useState } from 'react';
import {
  makeStyles,
  Grid,
  Button,
  DialogTitle,
  Dialog,
  IconButton,
  Typography,
  Link,
  AccordionSummary,
  AccordionDetails,
  Accordion,
} from '@material-ui/core';
import { DatePicker, Input } from '../../Inputs';
import { DropzoneArea, DropzoneDialog } from 'material-ui-dropzone';
import SaveIcon from '@material-ui/icons/Save';
import { useSnackbar } from 'notistack';
import getMessage from '../../../messages';
import { useForm } from 'react-hook-form';
import {
  CreateVenueCompetitionMutationHookResult,
  DecisionVenueCompetitionNotifyMutationHookResult,
  UploadFileMutationHookResult,
  useCalendarQuery,
  useCreateVenueCompetitionMutation,
  useDecisionVenueCompetitionNotifyMutation,
  useGetActiveRegionsQuery,
  useRegionsListQuery,
  useUploadFileMutation,
  useVenueAddDirDocumentMutation,
} from '../../../api';
import DocumentSelect from '../../../module/Documents';
import { DocumentTypes } from '../../../module';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import { Email } from '@material-ui/icons';
import moment from 'moment';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { CompetitionVenueRegion } from './CompetitionVenueRegion';
import userRights from '../../../config/userRights';
import ConfirmDialog from '../../ConfirmDialog';

const useStyles = makeStyles({
  statusBox: {
    maxHeight: 150,
    overflowY: 'auto',
    scrollBehavior: 'smooth',
  },
  title: {
    fontWeight: 500,
    marginRight: '0.5rem',
  },
  link: {
    cursor: 'pointer',
  },
  verticalSpace: {
    paddingBottom: 15,
  },
  verticalSpaceMarge: {
    marginBottom: 35,
  },
  divider: {
    height: 1,
    width: '100%',
    marginBottom: '1rem',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  tinyIcon: {
    fontSize: 18,
  },
  icon: {
    fontSize: 20,
  },
  dropZone: {
    minHeight: 30,
    border: 0,
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    borderRadius: 0,

    '& > .MuiDropzonePreviewList-root': {
      margin: '0 !important',
      width: '100%',
      '& > .MuiDropzonePreviewList-imageContainer': {
        flexBasis: 'unset',
        width: '100%',
        maxWidth: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 15,
        '& > svg': {
          height: '40px',
        },
        '& > p': {
          marginLeft: 15,
          marginRight: 15,
        },
        '& > button': {
          top: 'unset',
          right: 0,
          position: 'unset',
          marginLeft: 30,

          color: '#d32f2f',
          boxShadow: 'unset',
        },
      },
    },
    '& > .MuiDropzoneArea-textContainer': {
      display: 'flex',

      '& > .MuiDropzoneArea-text': {
        margin: 0,
        fontSize: 15,
      },
      '& > .MuiDropzoneArea-icon': {
        width: 20,
        height: 20,
        marginLeft: 'auto',
      },
    },
    '&:focus': {
      outline: 0,
      border: 0,
      borderBotom: '2px solid #3f51b5',
    },
  },
});

const defaultNewDocumentState: any = {
  title: undefined,
  date: undefined,
  number: undefined,
};

export const CompetitionVenueContinue: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const { data, refetch } = useCalendarQuery({ skip: !id || id === 'new', variables: { id }, fetchPolicy: 'no-cache' });
  const { isRoiv } = userRights();
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(false);
  const [confirmSentDecision, setConfirmSentDecision] = useState(false);
  const [
    decisionVenueCompetitionNotify,
  ]: DecisionVenueCompetitionNotifyMutationHookResult = useDecisionVenueCompetitionNotifyMutation();

  const [isAddingNewCompetitionDocumentFromExist, setAddingNewCompetitionDocumentFromExist] = useState(false);

  const [isAddingNewDocumentFromSystem, setIsAddingNewDocumentFromSystem] = useState(false);
  const [file, setFile] = useState<Array<File>>([]);
  const classes = useStyles();

  const [uploadDocumentFile]: UploadFileMutationHookResult = useUploadFileMutation();
  const [createVenueCompetition]: CreateVenueCompetitionMutationHookResult = useCreateVenueCompetitionMutation();
  const [updateCalendar] = useVenueAddDirDocumentMutation();
  const {
    handleSubmit: competitionExistDocumentSubmit,
    control: competitionExistDocumentControl,
    errors: competitionExistDocumentErrors,
    reset: competitionExistDocumentReset,
    formState: competitionExistDocumentState,
  } = useForm({
    defaultValues: {
      dirDocument: undefined,
    },
  });
  const isNewVenue = data?.dirCalendar?.venueCompetition === null;

  const { handleSubmit, control, errors, reset, formState, watch } = useForm({
    defaultValues: {},
  });

  const { formAdditionalFilter } = userRights();

  const { data: dataRegion, loading } = useGetActiveRegionsQuery({
    variables: {
      filter: { ...{ archive: null }, ...((formAdditionalFilter.dirRegions && formAdditionalFilter.dirRegions) || {}) },
    },
  });
  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const addExistDocument = async (values: any) => {
    const { dirDocument } = values;
    try {
      if (dirDocument) {
        if (isNewVenue) {
          await updateCalendar({
            variables: {
              idCalendar: id,
              data: {
                venueCompetition: {
                  create: {
                    venueCompDecisionDoc: { connect: { id: dirDocument?.value } },
                  },
                },
              },
            },
          });
        } else {
          await updateCalendar({
            variables: {
              idCalendar: id,
              data: {
                venueCompetition: {
                  update: {
                    venueCompDecisionDoc: { connect: { id: dirDocument?.value } },
                  },
                },
              },
            },
          });
        }

        handleSnackBar('success', 'документ успешно добавлен');
        setAddingNewCompetitionDocumentFromExist(false);
        refetch();
      }
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const handleAddNewDocumentFromSystem = async (values: any) => {
    const { title, date, number, typeDocument } = values;

    try {
      if (isAddingNewDocumentFromSystem && title && date && file) {
        uploadDocumentFile({
          variables: {
            file: file[0],
          },
        }).then(async (resp: any) => {
          const {
            uploadFile: { id: fileId },
          } = resp.data;

          if (isNewVenue) {
            await createVenueCompetition({
              variables: {
                data: {
                  venueCompDecisionDoc: {
                    create: {
                      file: { connect: { id: fileId } },
                      clsType: { connect: { id: typeDocument?.value } },
                      date,
                      title,
                      number,
                    },
                  },
                  dirCalendar: { connect: { id: id } },
                },
              },
            });
          } else {
            await updateCalendar({
              variables: {
                idCalendar: id,
                data: {
                  venueCompetition: {
                    update: {
                      venueCompDecisionDoc: {
                        create: {
                          file: { connect: { id: fileId } },
                          clsType: { connect: { id: typeDocument?.value } },
                          date,
                          title,
                          number,
                        },
                      },
                    },
                  },
                },
              },
            });
          }

          handleSnackBar('success', 'документ успешно добавлен');
          setIsAddingNewDocumentFromSystem(false);
          refetch();
          reset(defaultNewDocumentState);
        });
      }
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const removeDirDocument = async () => {
    await updateCalendar({
      variables: {
        idCalendar: id,
        data: {
          venueCompetition: {
            [isNewVenue ? 'create' : 'update']: {
              venueCompDecisionDoc: { disconnect: true },
            },
          },
        },
      },
    });
    await refetch();
  };

  const onAddVenueCompRegulationDocAdds = async (files) => {
    await Promise.all(
      files?.map(async (f) => {
        return new Promise(async (resolve, reject) => {
          await uploadDocumentFile({
            variables: {
              file: f,
            },
          }).then(async (resp: any) => {
            await updateCalendar({
              variables: {
                idCalendar: id,
                data: {
                  venueCompetition: {
                    [isNewVenue ? 'create' : 'update']: {
                      venueCompDecisionDocAdds: {
                        connect: { id: resp?.data?.uploadFile?.id },
                      },
                    },
                  },
                },
              },
            });
            resolve();
          });
        });
      }),
    );
    await refetch();
    setOpen(false);
  };

  const onDeleteVenueCompRegulationDocAdds = async (fileId) => {
    await updateCalendar({
      variables: {
        idCalendar: id,
        data: {
          venueCompetition: {
            [isNewVenue ? 'create' : 'update']: {
              venueCompDecisionDocAdds: { disconnect: [{ id: fileId }] },
            },
          },
        },
      },
    });
    await refetch();
  };

  const onSentDecision = async () => {
    if (!data?.dirCalendar?.venueCompetition?.id) return setConfirmSentDecision(false);
    const res = await decisionVenueCompetitionNotify({
      variables: { venueCompetitionId: data?.dirCalendar?.venueCompetition?.id },
    });
    if (res?.data?.decisionVenueCompetitionNotify) {
      handleSnackBar(
        'success',
        'Началась рассылка на официальные почты РОИВ решения о конкурсе на право проведения спортивных соревнований',
      );
    }
    setConfirmSentDecision(false);
  };

  const DOWNLOAD_LINK = process.env.REACT_APP_BACKEND_URI?.replace('/graphql', '');

  return (
    <>
      <ConfirmDialog
        title={`Подтвердите рассылку`}
        text={`Подтвердите рассылку на официальные почты РОИВ решения конкурсной комиссии на право проведения`}
        btnVariant="contained"
        open={confirmSentDecision}
        saveBtnText="Подтвердить"
        cancelBtnText="Отмена"
        saveBtnColor="primary"
        cancelBtnColor="primary"
        onCancel={() => setConfirmSentDecision(false)}
        onSave={onSentDecision}
      />
      <Grid container justify="space-between" direction="column">
        <Grid item md={6} container>
          <Grid item container alignItems="center">
            <Grid item>
              <Typography>
                Решение конкурсной комиссии на право проведения<span style={{ color: 'red' }}>*</span>:&nbsp;
              </Typography>
            </Grid>
            {!readonly && !data?.dirCalendar?.venueCompetition?.venueCompDecisionDoc && (
              <>
                {isAddingNewCompetitionDocumentFromExist ? (
                  <form onSubmit={competitionExistDocumentSubmit(addExistDocument)} style={{ width: '100%' }}>
                    <Grid item container md={12} spacing={2} alignItems="center">
                      <Grid item md={5} xs={12}>
                        <DocumentSelect
                          // filter={{
                          //   id_not_in: competitionProtocols?.map((protocol) => protocol.id),
                          // }}
                          label="Документ"
                          control={competitionExistDocumentControl}
                          error={!!competitionExistDocumentErrors['dirDocument']}
                          name="dirDocument"
                          rules={{ required: true }}
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          style={{ marginRight: '0.5rem' }}
                          startIcon={<SaveIcon />}
                          type="submit"
                          disabled={!competitionExistDocumentState.isDirty}
                        >
                          Сохранить
                        </Button>

                        <Button
                          variant="outlined"
                          color="secondary"
                          style={{ marginRight: '0.5rem' }}
                          size="small"
                          onClick={() => {
                            setAddingNewCompetitionDocumentFromExist(false);
                            competitionExistDocumentReset();
                          }}
                        >
                          Отменить
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                ) : (
                  <React.Fragment>
                    <Grid item>
                      <Typography style={{ marginRight: '0.5rem' }}>Добавить документ</Typography>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{ marginRight: '0.5rem' }}
                        size="small"
                        type="button"
                        onClick={() => setAddingNewCompetitionDocumentFromExist(true)}
                      >
                        Из базы
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{ marginRight: '0.5rem' }}
                        size="small"
                        type="button"
                        onClick={() => {
                          setIsAddingNewDocumentFromSystem(true);
                        }}
                      >
                        Новый
                      </Button>
                    </Grid>
                  </React.Fragment>
                )}
              </>
            )}
          </Grid>
          {data?.dirCalendar?.venueCompetition?.venueCompDecisionDoc && (
            <Grid item container spacing={2} alignItems="center">
              <Grid item>
                <Link
                  // className={`${classes.link}`}
                  target="_blank"
                  href={`${DOWNLOAD_LINK}${data?.dirCalendar?.venueCompetition?.venueCompDecisionDoc?.file?.path}`}
                >
                  <Typography>{data?.dirCalendar?.venueCompetition?.venueCompDecisionDoc?.title}</Typography>
                </Link>
              </Grid>
              <Grid item>
                <Typography>
                  {moment(data?.dirCalendar?.venueCompetition?.venueCompDecisionDoc?.date).format('DD.MM.YYYY')}
                </Typography>
              </Grid>
              <Grid item>{data?.dirCalendar?.venueCompetition?.venueCompDecisionDoc?.title}</Grid>
              <Grid item>{data?.dirCalendar?.venueCompetition?.venueCompDecisionDoc?.number}</Grid>
              <Grid item>{data?.dirCalendar?.venueCompetition?.venueCompDecisionDoc?.clsType?.name}</Grid>
              {!readonly && (
                <IconButton onClick={removeDirDocument}>
                  <DeleteIcon color="error" />
                </IconButton>
              )}
            </Grid>
          )}
          <Dialog
            open={isAddingNewDocumentFromSystem}
            onClose={() => {
              setIsAddingNewDocumentFromSystem(false);
              reset(defaultNewDocumentState);
            }}
            maxWidth="xs"
          >
            <DialogTitle id="add-document-dialog">Новый Документ</DialogTitle>
            <div style={{ padding: '1.5rem' }}>
              <form onSubmit={handleSubmit(handleAddNewDocumentFromSystem)} style={{ width: '100%' }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Input
                      label="Название документа"
                      control={control}
                      error={!!errors['title']}
                      name="title"
                      rules={{ required: true }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DatePicker
                      fullWidth
                      label="Дата документа"
                      control={control}
                      error={!!errors['date']}
                      name="date"
                      rules={{ required: true }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DocumentTypes
                      label="Тип документа"
                      control={control}
                      defaultValue={{
                        label: 'Решение на право проведения',
                        value: 'a53f3467-6510-4525-ba78-96b92520b664',
                      }}
                      error={!!errors['typeDocument']}
                      name="typeDocument"
                      rules={{ required: true }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input label="Номер документа" control={control} error={!!errors['number']} name="number" />
                  </Grid>
                  <Grid item xs={12}>
                    <DropzoneArea
                      showFileNames
                      alertSnackbarProps={{
                        anchorOrigin: { vertical: 'top', horizontal: 'right' },
                      }}
                      dropzoneClass={classes.dropZone}
                      showAlerts={['error']}
                      getFileAddedMessage={(fileName: string) => `Файл ${fileName} успешно загружено`}
                      getDropRejectMessage={(file: File) =>
                        `Файл ${file.name} отклонен. Тип файла не поддерживается. Файл слишком большой. Максимальный размер - 10 мегабайт.`
                      }
                      filesLimit={1}
                      maxFileSize={10485760}
                      clearOnUnmount
                      initialFiles={file}
                      onChange={(fileArray: File[]) => setFile(fileArray)}
                      dropzoneText="Файл"
                    />
                  </Grid>
                  <Grid item container justify="flex-end">
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      startIcon={<SaveIcon />}
                      type="submit"
                      disabled={!(formState.isDirty && file.length > 0)}
                    >
                      Добавить
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Dialog>
          <div style={{ margin: '15px 0', width: '100%' }}>
            <Typography display="inline" className={classes.title}>
              Приложения к решению:&nbsp;
            </Typography>
            <Button variant="contained" size="small" disabled={readonly} color="primary" onClick={() => setOpen(true)}>
              Загрузить файлы
            </Button>
            <DropzoneDialog
              showFileNames
              open={open}
              alertSnackbarProps={{
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
              }}
              showAlerts={['error']}
              getFileAddedMessage={(fileName: string) => `Файл ${fileName} успешно загружен`}
              getDropRejectMessage={(file: File) =>
                `Файл ${file.name} отклонен. Тип файла не поддерживается. Файл слишком большой. Максимальный размер - 10 мегабайт.`
              }
              getFileLimitExceedMessage={(filesLimit: number) =>
                `За один раз возможно загрузить максимально ${filesLimit} файлов`
              }
              cancelButtonText={'закрыть'}
              submitButtonText={'Загрузить'}
              dialogTitle={'Загрузить файл'}
              onClose={() => setOpen(false)}
              filesLimit={10}
              maxFileSize={10485760}
              previewText={'Предпросмотр: '}
              clearOnUnmount
              initialFiles={[]}
              onSave={(files: any) => {
                return onAddVenueCompRegulationDocAdds(files);
              }}
              dropzoneText="Максимальное количество файлов за раз: 10"
            />
            {data?.dirCalendar?.venueCompetition?.venueCompDecisionDocAdds &&
              data?.dirCalendar?.venueCompetition?.venueCompDecisionDocAdds.length > 0 && (
                <Grid container style={{ marginTop: 15 }} spacing={1}>
                  {data?.dirCalendar?.venueCompetition?.venueCompDecisionDocAdds?.map((file, index) => (
                    <Grid item container alignItems="center" spacing={1}>
                      <Grid item>
                        <a href={DOWNLOAD_LINK + file.path}>{file.name}</a>
                      </Grid>
                      <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                        <a style={{ height: '24px', color: 'black' }} href={DOWNLOAD_LINK + file.path}>
                          <DownloadIcon />
                        </a>
                      </Grid>
                      {!readonly && (
                        <Grid item>
                          <IconButton
                            style={{ padding: 0 }}
                            edge="end"
                            aria-label="delete"
                            onClick={() => onDeleteVenueCompRegulationDocAdds(file.id)}
                          >
                            <DeleteIcon color="error" />
                          </IconButton>
                        </Grid>
                      )}
                    </Grid>
                  ))}
                </Grid>
              )}
          </div>
        </Grid>
        <Grid md={6} item container alignItems="flex-end" direction="column">
          <Grid item>
            <Button
              disabled={
                readonly ||
                !data?.dirCalendar?.venueCompetition?.endDate ||
                !data?.dirCalendar?.venueCompetition?.venueCompDecisionDoc
              }
              style={{ maxHeight: 30 }}
              variant="outlined"
              startIcon={<Email color="primary" />}
              onClick={() => setConfirmSentDecision(true)}
            >
              Разослать регионам Решение
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid md={12} item container direction="column">
        {dataRegion?.dirRegions?.map((dR) => (
          <Grid item>
            <Accordion
              key={dR.id}
              expanded={active === dR.fullName}
              onChange={() => setActive(active === dR.fullName ? '' : dR.fullName)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${dR.fullName}-content`}
                id={`sport1-header`}
              >
                <Typography className={classes.head} color="primary">
                  {dR.fullName}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {active === dR.fullName && (
                  <CompetitionVenueRegion
                    id={data?.dirCalendar?.venueCompetition?.id}
                    idRegion={dR.id}
                    email={data?.dirCalendar?.venueCompetition?.emailSenderFCPSR?.email}
                    notValidDate={moment().isAfter(moment(data?.dirCalendar?.venueCompetition?.endDate), 'day')}
                    readonly={
                      readonly ||
                      !data?.dirCalendar?.venueCompetition?.endDate ||
                      moment().isAfter(moment(data?.dirCalendar?.venueCompetition?.endDate), 'day')
                    }
                  />
                )}
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
