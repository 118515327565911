import { Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import {
  OrganizationCreateDeparmentAffiliationMutationHookResult,
  OrganizationDepartmentAffiliationDocument,
  OrganizationRemoveDeparmentAffiliationMutationHookResult,
  useOrganizationCreateDeparmentAffiliationMutation,
  useOrganizationDepartmentAffiliationQuery,
  useOrganizationRemoveDeparmentAffiliationMutation,
} from '../../../api';
import React, { FC, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import ConfirmDialog from '../../ConfirmDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import DepartmentAffiliation from '../../../module/DepartmentAffiliation';
import { RanksAndTitle } from '../../../module';
import _get from 'lodash/get';
import getMessage from '../../../messages';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

const OrganizationDepartmentAffiliation: FC<{ readonly?: boolean; id: string }> = ({ readonly, id }) => {
  const { data } = useOrganizationDepartmentAffiliationQuery({ variables: { id }, fetchPolicy: 'no-cache' });
  const [deleteItem, setDeleteItem] = useState<any>(null);
  const { handleSubmit, control, errors, reset } = useForm({
    defaultValues: { item: null },
  });
  const { enqueueSnackbar } = useSnackbar();

  const [
    create,
  ]: OrganizationCreateDeparmentAffiliationMutationHookResult = useOrganizationCreateDeparmentAffiliationMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: OrganizationDepartmentAffiliationDocument,
        data: {
          ...data?.updateDirOrganization,
        },
      });
    },
  });
  const [
    remove,
  ]: OrganizationRemoveDeparmentAffiliationMutationHookResult = useOrganizationRemoveDeparmentAffiliationMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: OrganizationDepartmentAffiliationDocument,
        data: {
          ...data?.updateDirOrganization,
        },
      });
    },
  });

  const onRemove = async (value: { value: any; label: string }) => {
    try {
      await remove({
        variables: {
          id,
          idItem: _get(value, 'value'),
        },
      });
      enqueueSnackbar(`Ведомственная принадлежность "${value.label}" успешно удален`, { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  const onSubmit = handleSubmit(async (value) => {
    try {
      await create({
        variables: {
          id,
          idItem: _get(value, 'item.value'),
        },
      });
      enqueueSnackbar(`Ведомственная принадлежность "${_get(value, 'item.label')}" успешно добавлен`, {
        variant: 'success',
      });
      reset({ item: null });
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  });

  return (
    <Grid container>
      <Grid item md={6}>
        <List>
          {!readonly && (
            <form onSubmit={onSubmit}>
              <ListItem key="add_new">
                <DepartmentAffiliation
                  label="Вид ведомственной принадлежности"
                  error={!!errors['item']}
                  name="item"
                  control={control}
                  usedValues={data?.dirOrganization?.clsDepartmentalAffiliations?.map((item) => item.value)}
                  rules={{
                    required: true,
                    validate: async (val) => (val?.value ? undefined : 'Поле обязательное для заполнения'),
                  }}
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="add" type="submit">
                    <AddIcon color="primary" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </form>
          )}
          {data?.dirOrganization?.clsDepartmentalAffiliations?.map((item) => (
            <ListItem key={item.value}>
              <ListItemText primary={item.label} />
              {!readonly && (
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete" onClick={() => setDeleteItem(item.value)}>
                    <DeleteIcon color="error" />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
              <ConfirmDialog
                title={`Удаление принадлежности`}
                text={`Вы уверены что хотите удалить : ${item.label} `}
                btnVariant="outlined"
                open={deleteItem === item.value}
                saveBtnText="удалить"
                cancelBtnText="отмена"
                saveBtnColor="secondary"
                cancelBtnColor="primary"
                onCancel={() => setDeleteItem(null)}
                onSave={() => {
                  onRemove(item).then(() => {
                    setDeleteItem(null);
                  });
                }}
              />
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default OrganizationDepartmentAffiliation;
