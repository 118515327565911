import { Button, Dialog, DialogContent, DialogTitle, Grid } from '@material-ui/core';

import React, { FC } from 'react';

import { DatePicker, Input } from '../../../Inputs';
import { useForm } from 'react-hook-form';
import { Cancel, Save as SaveIcon } from '@material-ui/icons';
import { CalendarEventFragment } from '../../../../api';
import CalendarSports from '../../../../module/CalendarSports';
import FederalDistricts from '../../../../module/FederalDistricts';

export type TForm = {
  name: string;
  venue: string;
  startDate: string;
  endDate: string;
  membersQuota: string;
  ekp: string;
  dirSport?: { label: string; value: string };
  dirFederalDistricts?: Array<{ label: string; value: string }>;
};

const AddNewEventFom: FC<{
  onSubmit: (values: TForm) => void;
  handleClose: () => void;
  defaultEvent?: CalendarEventFragment;
  id?: string;
  stage?: string;
  etape?: number;
  ekp?: string | null;
  secondEtape?: boolean;
  blockTwiceClick?: boolean;
}> = ({ blockTwiceClick, onSubmit, handleClose, defaultEvent, id, etape, ekp, secondEtape }) => {
  const isEmptyValue = (value?: null | string | number) => (value ? String(value) : '');
  const { handleSubmit, control, errors, formState } = useForm<TForm>({
    defaultValues: {
      name: isEmptyValue(defaultEvent?.name),
      venue: isEmptyValue(defaultEvent?.venue),
      startDate: isEmptyValue(defaultEvent?.startDate),
      endDate: isEmptyValue(defaultEvent?.endDate),
      membersQuota: isEmptyValue(defaultEvent?.membersQuota),
      ekp: isEmptyValue(defaultEvent?.ekp || ekp),
    },
  });

  const handlePreventEvent = (evt: React.MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
  };

  return (
    <Dialog onClick={handlePreventEvent} onClose={handleClose} open={true}>
      <DialogTitle>Новое мероприятие </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
          <Grid container spacing={1}>
            {secondEtape && (
              <Grid item md={12}>
                <CalendarSports
                  label="Вид спорта"
                  control={control}
                  error={!!errors['dirSport']}
                  name="dirSport"
                  rules={{ required: true }}
                  id={id}
                />
              </Grid>
            )}
            <Grid item md={6}>
              <Input
                label="Название мероприятия"
                control={control}
                error={!!errors['name']}
                name="name"
                rules={{ required: true }}
                defaultValue=""
              />
            </Grid>
            <Grid item md={6}>
              <Input
                label="Место проведения"
                control={control}
                error={!!errors['venue']}
                name="venue"
                rules={{ required: true }}
                defaultValue=""
              />
            </Grid>
            <Grid item md={6}>
              <DatePicker
                fullWidth
                label="Дата начала"
                control={control}
                error={!!errors['startDate']}
                name="startDate"
                rules={{ required: true }}
              />
            </Grid>
            <Grid item md={6}>
              <DatePicker
                fullWidth
                label="Дата завершения"
                control={control}
                error={!!errors['endDate']}
                name="endDate"
                rules={{ required: true }}
              />
            </Grid>
            <Grid item md={6}>
              <Input
                label="Кол-во участников"
                control={control}
                error={!!errors['membersQuota']}
                name="membersQuota"
                defaultValue=""
              />
            </Grid>
            <Grid item md={6}>
              <Input label="Номер в ЕКП" control={control} error={!!errors['ekp']} name="ekp" defaultValue="" />
            </Grid>
            {etape === 2 && (
              <Grid item md={12}>
                <FederalDistricts
                  label="ФО"
                  control={control}
                  error={!!errors['dirFederalDistricts']}
                  name="dirFederalDistricts"
                  multiple
                  rules={{ required: true }}
                />
              </Grid>
            )}
            {!secondEtape && etape === 3 && (
              <>
                <Grid item md={12}>
                  <CalendarSports
                    label="Вид спорта"
                    control={control}
                    error={!!errors['dirSport']}
                    name="dirSport"
                    rules={{ required: true }}
                    id={id}
                  />
                </Grid>
                <Grid item md={12}>
                  <Input
                    label="Кол-во дней до даты начала для добавления протоколов I этапа"
                    control={control}
                    error={!!errors['daysBeforeStartToAddProtocols']}
                    name="daysBeforeStartToAddProtocols"
                    defaultValue=""
                  />
                </Grid>
              </>
            )}

            <Grid item container justify="space-between" style={{ marginTop: 15, marginBottom: 15 }}>
              <Button
                variant="outlined"
                color="secondary"
                type="button"
                size="small"
                startIcon={<Cancel color="secondary" />}
                onClick={handleClose}
              >
                Отменить
              </Button>
              <Button
                variant="outlined"
                color="primary"
                type={blockTwiceClick ? 'button' : 'submit'}
                size="small"
                startIcon={<SaveIcon color="primary" />}
                disabled={blockTwiceClick || !formState.isDirty}
              >
                Сохранить
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddNewEventFom;
