import React, { FC, Fragment, useState } from 'react';
import { Typography, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DisciplineGroupsBox from './DisciplineGroupsBox';
import ArchiveComponent from '../../../Dialogs/Archive/Archive';
import {
  TypeSportTrainerArchiveMutationHookResult,
  TypeSportTrainerUnArchiveMutationHookResult,
  useTypeSportTrainerArchiveMutation,
  useTypeSportTrainerUnArchiveMutation,
} from '../../../../api';
import _ from 'lodash';

const useStyles = makeStyles({
  row: {
    display: 'flex',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  tinyIcon: {
    fontSize: 18,
  },
  icon: {
    fontSize: 20,
  },
  tableHead: {
    fontWeight: 'bold',
  },
  header: {
    fontWeight: 600,
    fontSize: 15,
    letterSpacing: 1.2,
  },
});

const SportAccordion: FC<{ readonly?: boolean; sport: any; onUpdate: Function }> = ({ readonly, sport, onUpdate }) => {
  const classes = useStyles();

  const [archive]: TypeSportTrainerArchiveMutationHookResult = useTypeSportTrainerArchiveMutation();
  const [unarchive]: TypeSportTrainerUnArchiveMutationHookResult = useTypeSportTrainerUnArchiveMutation();

  return (
    <Accordion key={sport.id} style={{ width: '100%', marginBottom: 15 }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${sport.dirSport?.label}-content`}
        id={`${sport.dirSport?.label}-header`}
        style={{ backgroundColor: (sport?.archive && 'lightgrey') || 'inherit' }}
      >
        <Grid container spacing={2} justify="space-between" alignItems="center">
          <Grid item>
            <Typography className={classes.header} color={(sport?.archive && 'inherit') || 'primary'}>
              {sport.dirSport.label}
            </Typography>
          </Grid>
          <Grid item onClick={(e) => e.stopPropagation()}>
            {!readonly && (
              <ArchiveComponent
                id={sport?.id}
                archiveStatus={sport?.archive}
                canUnArchive
                unarchive={unarchive}
                refetch={onUpdate}
                archive={archive}
              />
            )}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: 'column' }}>
        <DisciplineGroupsBox sport={sport} classes={classes} onUpdate={onUpdate} readOnly={readonly || sport.archive} />
      </AccordionDetails>
    </Accordion>
  );
};

export default SportAccordion;
