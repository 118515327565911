import exceljs, { Row } from 'exceljs';
import { CalendarQuantyRequestStageTwoFragment } from '../../../api';
import { saveAs } from 'file-saver';
import { get, orderBy } from 'lodash';

export type FetchEventCompetitionReport = {
  data: Data;
  mergeCellsPosition?: Array<[string, string]>;
  blancUrl?: string;
  calendarName: string;
  fileName: string;
  mergeStart?: number;
};

export type Data = CalendarQuantyRequestStageTwoFragment[];

export const fetchRegionSummaryInformationOnTheNumberOfParticipantsReport: (
  props: FetchEventCompetitionReport,
) => void = async (props) => {
  try {
    const { data = [], blancUrl = '/templates/blancRegionSummaryInfoReport.xlsx', fileName, calendarName } = props;

    const workbook = new exceljs.Workbook();

    const saveFile = async () => {
      const buffer = await workbook.xlsx.writeBuffer({
        useStyles: true,
      });
      const blob = new Blob([buffer], { type: 'applicationi/xlsx' });
      saveAs(blob, fileName + '.xlsx');
    };
    const setCellText = (position: string, value: string) => {
      sheet.getCell(position).value = value;
    };
    const blanc = await (await fetch(blancUrl)).arrayBuffer();
    await workbook.xlsx.load(blanc);
    const sheet = workbook.worksheets[0];

    // zero row
    const zeroRow = sheet.getRow(16);

    //unmerge foot cells
    const unMergeFootCells = () => {
      sheet.unMergeCells('A20');
      sheet.unMergeCells('A21');
      sheet.unMergeCells('A23');
      sheet.unMergeCells('N23');
      sheet.unMergeCells('A24');
      sheet.unMergeCells('N24');
      sheet.unMergeCells('A26');
    };

    //merge foot cells
    const mergeFootCells = (lastRowIndex) => {
      sheet.mergeCells(`A${lastRowIndex + 5}:BB${lastRowIndex + 5}`);
      sheet.mergeCells(`A${lastRowIndex + 6}:BB${lastRowIndex + 6}`);
      sheet.mergeCells(`A${lastRowIndex + 8}:L${lastRowIndex + 8}`);
      sheet.mergeCells(`A${lastRowIndex + 9}:L${lastRowIndex + 9}`);
      sheet.mergeCells(`N${lastRowIndex + 8}:BL${lastRowIndex + 8}`);
      sheet.mergeCells(`N${lastRowIndex + 9}:BL${lastRowIndex + 9}`);
      sheet.mergeCells(`A${lastRowIndex + 11}:L${lastRowIndex + 11}`);
    };

    // merge with index
    const mergeCells = (rowIndex) => {
      sheet.mergeCells(`A${rowIndex}:C${rowIndex}`);
      sheet.mergeCells(`D${rowIndex}:X${rowIndex}`);
      sheet.mergeCells(`Y${rowIndex}:AF${rowIndex}`);
      sheet.mergeCells(`AG${rowIndex}:AN${rowIndex}`);
      sheet.mergeCells(`AO${rowIndex}:AV${rowIndex}`);
      sheet.mergeCells(`AW${rowIndex}:BD${rowIndex}`);
      sheet.mergeCells(`BE${rowIndex}:BL${rowIndex}`);
    };

    const mergeLastRow = (lastIndex) => {
      sheet.mergeCells(`A${lastIndex}:X${lastIndex}`);
      sheet.mergeCells(`Y${lastIndex}:AF${lastIndex}`);
      sheet.mergeCells(`AG${lastIndex}:AN${lastIndex}`);
      sheet.mergeCells(`AO${lastIndex}:AV${lastIndex}`);
      sheet.mergeCells(`AW${lastIndex}:BD${lastIndex}`);
      sheet.mergeCells(`BE${lastIndex}:BL${lastIndex}`);
    };

    const updateRowData = (
      row: Row,
      item: CalendarQuantyRequestStageTwoFragment,
      tableRowIndex: number,
      rowIndex: number,
    ) => {
      row.getCell('A').value = tableRowIndex;
      row.getCell('D').value = item?.dirSport?.fullName;
      row.getCell('Y').value = item?.regionQuotaMaleAthlStageTwo;
      row.getCell('AG').value = item?.regionQuotaWomenAthlStageTwo;
      row.getCell('AO').value = {
        formula: `SUM(Y${rowIndex}:AN${rowIndex})`,
        date1904: false,
      };
      row.getCell('AW').value = item?.regionQuotaAccompStageTwo;
      row.getCell('BE').value = {
        formula: `SUM(AO${rowIndex}:BD${rowIndex})`,
        date1904: false,
      };
      return;
    };

    const updateTotalRowData = (row: Row, lastIndex: number) => {
      row.getCell('A').value = 'ИТОГО:';
      row.getCell('Y').value = { formula: `SUM(Y16:AF${lastIndex - 1})`, date1904: false };
      row.getCell('AG').value = { formula: `SUM(AG16:AN${lastIndex - 1})`, date1904: false };
      row.getCell('AO').value = { formula: `SUM(AO16:AV${lastIndex - 1})`, date1904: false };
      row.getCell('AW').value = { formula: `SUM(AW16:BD${lastIndex - 1})`, date1904: false };
      row.getCell('BE').value = { formula: `SUM(BE16:BL${lastIndex - 1})`, date1904: false };

      ['A', 'Y', 'AG', 'AO', 'AW', 'BE'].forEach((i) => {
        row.getCell(i).style = {
          ...zeroRow.getCell(i).style,
          font: {
            ...zeroRow.getCell(i).style.font,
            size: 16,
            bold: true,
          },
          ...(i === 'A' ? { alignment: { ...zeroRow.getCell(i).style.alignment, horizontal: 'right' } } : {}),
        };
      });
      return;
    };

    const addTotalRow = (lastIndex: number) => {
      sheet.duplicateRow(lastIndex - 1, 1, true);
      const row = sheet.getRow(lastIndex);
      //last row
      mergeLastRow(lastIndex);
      updateTotalRowData(row, lastIndex);
      mergeFootCells(lastIndex - 1);
    };

    const regionName = get(data, '[0].dirRegion.fullName');

    //unmerge foot cells
    unMergeFootCells();

    const [first, ...others] = orderBy(data, 'dirSport.fullName', 'asc');
    // add rows
    const lastIndex = 16 + data.length;
    sheet.duplicateRow(16, data.length - 1, true);
    //update first Row
    updateRowData(sheet.getRow(16), first, 1, 16);
    //
    sheet.eachRow((row, rowIndex) => {
      if (rowIndex > 16 && rowIndex < lastIndex) {
        mergeCells(rowIndex);
        updateRowData(row, others[rowIndex - 17], rowIndex - 15, rowIndex);
      }
    });

    //add total row
    addTotalRow(lastIndex);

    setCellText('Z7:BL7', `  ${regionName}`);
    setCellText('A10:BL7', `  ${calendarName}`);
    await saveFile();
  } catch (error) {
    return;
  }
};
