import {
  Accordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Typography,
  withStyles,
} from '@material-ui/core';
import { ContentWrapperWidget, MainTemplate } from '../../components/layouts';
import React, { ChangeEvent, FC, useState } from 'react';
import { useForeignCityByIdQuery } from '../../api';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MainInfo from '../../components/widgets/ForeignCities/MainInfo';
import { blue } from '@material-ui/core/colors';
import moment from 'moment';
import onExpand from '../../utils/onExpand';
import { usePermission } from '../../hooks/usePermission';
import { useRouteMatch } from 'react-router-dom';

type AccordionTypes = 'MainInfo' | undefined;

const AccordionSummary = withStyles({
  root: {
    backgroundColor: blue[700],
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    color: 'white',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary) as any;

// @TODO: Update View for Dialog request to Archive

const Single: FC<{}> = () => {
  const {
    params: { id },
  } = useRouteMatch();
  const [expanded, setExpanded] = useState<AccordionTypes>('MainInfo');

  const access = usePermission('directory', 'foreign_cities');
  const { data } = useForeignCityByIdQuery({ variables: { id } });
  const OptionTitleArchive = data?.dirForeignCity?.archive
    ? ` (В архиве с ${moment(data?.dirForeignCity?.archive?.date).format('DD.MM.YYYY')})`
    : '';

  const handleChangeAccordion = (panel: AccordionTypes) => (event: ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : undefined);
  };

  return (
    <MainTemplate
      pageName=""
      url=""
      title={id === 'new' ? 'Добавить новый зарубежный город' : `Зарубежный город: ${data?.dirForeignCity?.fullName}`}
    >
      <ContentWrapperWidget>
        {id === 'new' ? (
          <h2>Добавить новый зарубежный город</h2>
        ) : (
          <h2>
            Зарубежный город: {data?.dirForeignCity?.fullName || data?.dirForeignCity?.shortName} {OptionTitleArchive}
          </h2>
        )}
        <Accordion expanded={expanded === 'MainInfo'} onChange={handleChangeAccordion('MainInfo')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Основная информация</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {onExpand(expanded, 'MainInfo') && <MainInfo id={id} readonly={!access.includes(2)} />}
          </AccordionDetails>
        </Accordion>
      </ContentWrapperWidget>
    </MainTemplate>
  );
};

export default Single;
