import React, { FC, useEffect } from 'react';
import { Grid, Divider, Typography, List, ListItem, ListItemText } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import GroupDisciplines from '../../../../module/GroupDisciplines';
import { useSnackbar } from 'notistack';
import getMessage from '../../../../messages';
import _ from 'lodash';
import { Scalars, UpdateTrainerSportMutationHookResult, useUpdateTrainerSportMutation } from '../../../../api';

interface IDisciplineGroups {
  classes?: any;
  sport: any;
  onUpdate: Function;
  readOnly?: boolean;
}

interface formInterface {
  disciplineGroups: any;
}

const DisciplineGroupsBox: FC<IDisciplineGroups> = ({ classes, sport, onUpdate, readOnly }) => {
  const { disciplineGroups } = sport;

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const { handleSubmit, control, errors, watch } = useForm({
    defaultValues: {
      disciplineGroups,
    } as formInterface,
  });

  let watchDisciplines = watch('disciplineGroups', disciplineGroups);

  const [update]: UpdateTrainerSportMutationHookResult = useUpdateTrainerSportMutation();

  const onSubmit = async (values: formInterface) => {
    console.log('this form wont be submited');
  };

  const updateDisciplines = async (values: { disciplineGroups: any }) => {
    const { disciplineGroups } = values;
    try {
      await update({
        variables: {
          data: {
            disciplineGroups: {
              [sport?.disciplineGroups?.length > 0
                ? 'set'
                : 'connect']: disciplineGroups.map(({ value }: { value: Scalars['UUID'] }) => ({ id: value })),
            },
          },
          id: sport?.id,
        },
      });
      onUpdate();
      handleSnackBar('success', 'Группы дисциплин успешно обновлена');
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  useEffect(() => {
    if (!_.isEqual(disciplineGroups, watchDisciplines)) updateDisciplines({ disciplineGroups: watchDisciplines });
  }, [watchDisciplines]);

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Grid container className={classes.verticalSpaceMarge} spacing={2}>
          {readOnly ? (
            <Grid item xs={12}>
              <Typography style={{ fontWeight: 'bold' }}>Группы дисциплин:</Typography>
              <List>
                {disciplineGroups?.map((d: any) => (
                  <ListItem key={d?.value}>
                    <span style={{ marginRight: 5 }}>&#8226;</span>
                    <ListItemText primary={d?.label} />
                  </ListItem>
                ))}
              </List>
            </Grid>
          ) : (
            <Grid item xs={12}>
              <GroupDisciplines
                filter={{ dirSport: { id: sport?.dirSport?.value } }}
                multiple
                label="Группы дисциплин"
                control={control}
                error={!!errors['disciplineGroups']}
                name="disciplineGroups"
                rules={{ required: true }}
                disabled={readOnly}
              />
            </Grid>
          )}
        </Grid>
      </form>
      <Grid container>
        <Divider className={classes.divider} />
      </Grid>
    </React.Fragment>
  );
};

export default DisciplineGroupsBox;
