import { Accordion, AccordionDetails, Typography } from '@material-ui/core';
import { Button, Grid, LinearProgress, Link } from '@material-ui/core';
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  useClsStagePreparingQuery,
  useOrganizationTypeSportsQuery,
  useOrganizationsAthletesBySportQuery,
  useOrganizationsTrainersBySportQuery,
} from '../../../api';

import AthletesComponent from './components/AthletesComponent';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FireAthlete from '../../FireBox/FireAthlete';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import _ from 'lodash';
import { cuuid } from '../../../utils/cuuid';
import unifyTrainerAndAthlete from '../../../utils';
import { useSnackbar } from 'notistack';

const AccordionSummary = withStyles({
  root: {
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    color: 'white',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary) as any;

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  header: {
    fontWeight: 600,
    fontSize: 15,
    letterSpacing: 1.2,
  },
});

const OrganizationAthlete: FC<{ readonly?: boolean; regionId?: string; id?: string }> = ({ id, regionId }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState<string | undefined>(undefined);
  const [currentView, setCurrentView] = useState('list');

  const { loading, data: organizationSports } = useOrganizationTypeSportsQuery({
    variables: {
      id,
    },
    fetchPolicy: 'no-cache',
  });

  const handleChangeAccordion = (panel: string) => (event: ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : undefined);
  };

  if (loading) return <LinearProgress />;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      {organizationSports?.dirOrganizationSports?.map((type) => (
        <Accordion
          expanded={expanded === type?.dirSport?.value}
          onChange={handleChangeAccordion(type?.dirSport?.value)}
          style={{ width: '100%' }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Grid container spacing={2}>
              <Grid item>
                <Typography className={classes.header} color="primary">
                  {type?.dirSport?.label}
                </Typography>
              </Grid>
              <Grid item container xs spacing={2}>
                <Grid item>
                  <Button
                    variant="outlined"
                    size="small"
                    color={(currentView === 'list' && 'primary') || 'default'}
                    onClick={(e) => {
                      e.stopPropagation();
                      setCurrentView('list');
                    }}
                  >
                    Показать списком
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    size="small"
                    color={(currentView === 'table' && 'primary') || 'default'}
                    onClick={(e) => {
                      e.stopPropagation();
                      setCurrentView('table');
                    }}
                  >
                    Показать по этапам подготовки
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column', width: '100%' }}>
            <InfoTable sportId={type?.dirSport?.value} currentView={currentView} regionId={regionId} id={id} />
          </AccordionDetails>
        </Accordion>
      )) || <></>}
    </div>
  );
};

const InfoTable: FC<{ id: any; sportId: any; regionId?: string; currentView: string }> = ({
  id,
  sportId,
  currentView,
  regionId,
}) => {
  const classes = useStyles();
  const [tableKey, setTableKey] = useState(cuuid());
  const [tableObject, setTableObject] = useState<any>({});
  const [total, setTotal] = useState({});
  const [open, setOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState({});
  const [dialogWidth, setDialogWidth] = useState<'xs' | 'sm' | 'md' | 'lg' | 'xl' | false>('sm');

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (
    variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined,
    message: string,
  ) => {
    enqueueSnackbar(message, { variant });
  };

  const { loading, data: cls } = useClsStagePreparingQuery();

  const { refetch: refetchAthletes, data: athletes } = useOrganizationsAthletesBySportQuery({
    fetchPolicy: 'no-cache',
    variables: {
      sportID: sportId,
      id,
      filter: {
        dirOrganization: {
          id,
        },
        exclusionDate: null,
      },
    },
  });

  const { refetch: refetchTrainers, data: trainers } = useOrganizationsTrainersBySportQuery({
    fetchPolicy: 'no-cache',
    variables: {
      sportID: sportId,
      id,
    },
  });

  const toggleOpenDialog = (info?: any) => {
    setModalInfo({ ...info });
    setOpen(!open);
  };

  const updateValues = useCallback(async () => {
    const sportmens = await refetchAthletes();
    const trainers = await refetchTrainers();
    if (!sportmens.loading && !sportmens.errors && !trainers.errors && !trainers.loading) {
      const { data, totalData } = unifyTrainerAndAthlete(
        trainers?.data?.dirTrainers || [],
        cls?.classifierValues || [],
        id,
        athletes?.dirAthletes,
      );
      setTableObject(data);
      setTotal(totalData);
    }
  }, [athletes, cls, id, refetchAthletes, refetchTrainers]);

  const handleFireSuccess = async (value: any) => {
    if (value) {
      handleSnackBar('success', 'Спортсмен успешно исключен!');
      await updateValues();
      toggleOpenDialog();
    }
  };

  useEffect(() => {
    setTableKey(cuuid());
  }, [open]);

  useEffect(() => {
    updateValues();
  }, [currentView, open, updateValues]);

  useEffect(() => {
    if (trainers && cls) {
      const { data, totalData } = unifyTrainerAndAthlete(
        trainers.dirTrainers || [],
        cls?.classifierValues || [],
        id,
        athletes?.dirAthletes,
      );
      setTableObject(data);
      setTotal(totalData);
    }
  }, [athletes, trainers, cls, id]);

  return (
    <>
      {currentView === 'list' ? (
        <AthletesComponent id={id} sportId={sportId} regionId={regionId} />
      ) : (
        <>
          {loading && <span style={{ marginLeft: '1rem' }}>Загружается....</span>}
          {Object.keys(tableObject).length !== 0 && (
            <TableContainer component={Paper} key={tableKey}>
              <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                  <TableRow>
                    <TableCell>Тренеры</TableCell>
                    {cls?.classifierValues.map((stage) => (
                      <TableCell key={stage.value} align="center">
                        {stage.shortLabel}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Object.keys(tableObject).map((key) => (
                    <TableRow key={key}>
                      <TableCell key={key} component="th" scope="row">
                        {(key === 'sportmenWithoutTrainer' && 'Самоподготовка') || (
                          <Link
                            style={{ fontWeight: 500, cursor: 'pointer' }}
                            target="_blank"
                            href={`/trainer/${tableObject[key]['Без группы']?.trainerId}`}
                          >
                            {key}
                          </Link>
                        )}
                      </TableCell>
                      {Object.keys(tableObject[key]).map((innerKey) =>
                        tableObject[key][innerKey].athletes ? (
                          <TableCell
                            style={{ cursor: 'pointer', fontWeight: 500 }}
                            key={innerKey}
                            align="center"
                            onClick={() =>
                              toggleOpenDialog({
                                ...tableObject[key][innerKey],
                                selfTraining: key === 'sportmenWithoutTrainer',
                              })
                            }
                          >
                            <Typography color="primary" component="h2" style={{ fontWeight: 500 }}>
                              {tableObject[key][innerKey].athletes}
                            </Typography>
                          </TableCell>
                        ) : (
                          <TableCell key={innerKey} align="center">
                            {tableObject[key][innerKey].athletes}
                          </TableCell>
                        ),
                      )}
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell>Итого: </TableCell>
                    {Object.values(total).map((value: any) =>
                      value.athletes ? (
                        <TableCell style={{ fontWeight: 500 }} key={value.id} align="center">
                          {value.athletes}
                        </TableCell>
                      ) : (
                        <TableCell key={value.id} align="center">
                          0
                        </TableCell>
                      ),
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <Dialog
            fullWidth
            maxWidth={dialogWidth}
            onClose={toggleOpenDialog}
            aria-labelledby="dialog-title"
            open={open}
          >
            <DialogTitle id="dialog-title">Отчисление спортсменов</DialogTitle>
            <FireAthlete
              setDialogWidth={setDialogWidth}
              onFireSuccessful={handleFireSuccess}
              modalInfo={modalInfo}
              sportID={sportId}
            />
          </Dialog>
        </>
      )}
    </>
  );
};

export default OrganizationAthlete;
