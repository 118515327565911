import React, { FC } from 'react';
import { Select } from '../components/Inputs';
import { ControllerProps } from 'react-hook-form/dist/types/props';
import { useUserRoleQuery } from '../api';
import { checkUserAndReturnFilterForRoles } from '../utils/checkUserAndReturnFilterForRoles';

interface UserRoles extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  error: boolean | undefined;
  disabled?: boolean;
}

const UserRoles: FC<UserRoles> = ({ label, error, control, name, rules, disabled }) => {
  const filter = checkUserAndReturnFilterForRoles();
  const { data } = useUserRoleQuery({ variables: { filter } });
  return (
    <Select
      label={label}
      data={(data && data.userRoles) || []}
      control={control}
      error={error}
      name={name}
      rules={rules}
      disabled={disabled}
    />
  );
};

export default UserRoles;
