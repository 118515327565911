import {
  AddTypeSportsToTrainerMutationHookResult,
  TrainerByIdDocument,
  useAddTypeSportsToTrainerMutation,
  useTrainerTypeSportsQuery,
} from '../../../api';
import { Grid, IconButton, LinearProgress } from '@material-ui/core';
import React, { FC, useEffect } from 'react';

import AddIcon from '@material-ui/icons/Add';
import SportAccordion from './components/SportAccordion';
import TypeSportModule from '../../../module/TypeSportsSelect';
import _ from 'lodash';
import getMessage from '../../../messages';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

const TypeSports: FC<{ readonly?: boolean; id: string }> = ({ readonly, id }) => {
  const { data, refetch, loading } = useTrainerTypeSportsQuery({ variables: { id }, fetchPolicy: 'no-cache' });
  const { handleSubmit, control, errors, reset } = useForm({
    defaultValues: { typesport: null },
  });
  const { enqueueSnackbar } = useSnackbar();
  const [create]: AddTypeSportsToTrainerMutationHookResult = useAddTypeSportsToTrainerMutation();

  const onSubmit = handleSubmit(async (values?: { typesport?: { label: string; value: any } | null }) => {
    if (!values?.typesport) return;
    try {
      await create({
        variables: {
          id,
          sportId: values?.typesport?.value,
        },
      });
      reset({ typesport: null });
      refetch();
      enqueueSnackbar(`Вид спорта "${values?.typesport?.label}" успешно добавлен`, {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  });

  if (loading) return <LinearProgress />;

  return (
    <Grid container>
      <Grid item container>
        {!readonly && (
          <form onSubmit={onSubmit} style={{ width: '100%', marginBottom: 20 }}>
            <Grid item container md={6}>
              <Grid item md={10}>
                <TypeSportModule
                  usedValue={data?.dirTrainerSports?.map((sport: any) => sport.dirSport.value)}
                  label="Вид спорта"
                  error={!!errors['typesport']}
                  name="typesport"
                  control={control}
                />
              </Grid>
              <Grid item md={2}>
                <IconButton edge="end" aria-label="add" type="submit">
                  <AddIcon color="primary" />
                </IconButton>
              </Grid>
            </Grid>
          </form>
        )}
      </Grid>
      {_.sortBy(data?.dirTrainerSports, function (o) {
        return o.archive !== null;
      })?.map((item) => (
        <Grid key={item?.id} item container>
          <SportAccordion sport={item} readonly={readonly} onUpdate={() => refetch()} />
        </Grid>
      ))}
    </Grid>
  );
};

export default TypeSports;
