import React, { FC, useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import useDebouncedSearch from '../hooks/useDebouncedSearch';
import _ from 'lodash';

const CustomSuperSelect: FC<{
  resetKey?: string;
  fieldValue?: any;
  queryHook: any;
  onChange: Function;
  name?: string;
  label?: string;
  getOptionLabelFunc: (option: any) => string;
  neededObjValue: string;
  minWidthValue?: string;
  baseOptions?: any;
  needFilter?: any;
}> = ({
  resetKey,
  fieldValue,
  needFilter = true,
  queryHook,
  onChange,
  name,
  label,
  getOptionLabelFunc,
  neededObjValue,
  minWidthValue,
  baseOptions,
}) => {
  const [skip, setSkip] = useState(true);
  const [similar, setSimilar] = useState([]);
  const { loading, data, refetch } = queryHook({
    skip,
    variables: {
      search: '',
      ...baseOptions,
    },
  });
  const SimilarLookUp = (text: string) => {
    setSkip(false);
    refetch({
      search: text,
      ...baseOptions,
    });
  };

  const useSearchOrganisations = () => useDebouncedSearch((text: string) => SimilarLookUp(text));

  const { setInputValue: setValue } = useSearchOrganisations();

  useEffect(() => {
    if (data && Object.keys(data).length > 0 && data[Object.keys(data)[0]]) {
      setSimilar(data[Object.keys(data)[0]]);
    }
  }, [loading, data]);

  const updateValues = (event: any) => {
    if (event.target.value.length >= 3) {
      setValue(event.target.value);
    }
    if (!event.target.value) {
      if (fieldValue) {
        onChange(event);
      }
      setSimilar([]);
      setSkip(true);
    }
  };

  const updateField = (event: any, value: any) => {
    if (value) {
      onChange({
        target: {
          name,
          value: _.get(value, neededObjValue),
        },
      });
    } else {
      onChange({
        target: {
          name,
          value: '',
        },
      });
    }
  };

  return (
    <Autocomplete
      key={resetKey}
      options={similar}
      loading={loading}
      getOptionLabel={getOptionLabelFunc}
      onSelect={updateValues}
      filterOptions={(data, state) => {
        if (needFilter) {
          return data?.filter((item) => {
            return item && state?.getOptionLabel(item).indexOf(state.inputValue) > -1;
          });
        }
        return data;
      }}
      onChange={updateField}
      noOptionsText=""
      renderInput={(params) => (
        <TextField {...params} name={name} style={{ minWidth: minWidthValue || '30ch' }} size="small" label={label} />
      )}
    />
  );
};

export default CustomSuperSelect;
