import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/CloudDownload';

const AthleteDocuments = () => {
  return (
    <Grid container style={{ padding: 10 }}>
      <Typography style={{ fontWeight: 'bold' }}>Шаблоны согласий для участников сборных:</Typography>
      <Grid container direction="row">
        <Grid item container xs={12} alignItems="center">
          <a
            download="Согласие на обработку персональных данных.docx"
            href={`/79e80ffe-c0ce-4b3a-acd2-35e213715f63.docx`}
          >
            Согласие на обработку персональных данных.docx
          </a>
          <DownloadIcon style={{ marginLeft: 16 }} />
        </Grid>
        <Grid item container xs={12} alignItems="center">
          <a
            download="Согласие на обработку персональных данных, разрешенных для распространения.docx"
            href={`/7053409a-88b2-4590-9b33-b71034f08633.docx`}
          >
            Согласие на обработку персональных данных, разрешенных для распространения.docx
          </a>
          <DownloadIcon style={{ marginLeft: 16 }} />
        </Grid>
        <Grid item container xs={12} alignItems="center">
          <a
            download="Согласие на обработку персональных данных от законного представителя субъекта персональных данных.docx"
            href={`/05a0f960-0692-41ea-a9e1-937112fc6d1c.docx`}
          >
            Согласие на обработку персональных данных от законного представителя субъекта персональных данных.docx
          </a>
          <DownloadIcon style={{ marginLeft: 16 }} />
        </Grid>
        <Grid item container xs={12} alignItems="center">
          <a
            download="Согласие родителя (законного представителя) на обработку персональных данных несовершеннолетнего,
            разрешенных родителем (законным представителем) для распространения.docx"
            href={`/c953dc46-536c-49ff-b013-fc0ccc68b09f.docx`}
          >
            Согласие родителя (законного представителя) на обработку персональных данных несовершеннолетнего,
            разрешенных родителем (законным представителем) для распространения.docx
          </a>
          <DownloadIcon style={{ marginLeft: 16 }} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AthleteDocuments;
