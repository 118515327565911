import React, { FC } from 'react';

import { ControllerProps } from 'react-hook-form/dist/types/props';
import { DatePicker } from './index';
import { Grid } from '@material-ui/core';

interface ReactHookFormInput extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  errorMessage?: string;
  error: boolean | undefined;
}

const DatePickerRange: FC<{ start: ReactHookFormInput; end: ReactHookFormInput; fullWidth?: boolean }> = ({
  start,
  end,
  fullWidth,
}) => {
  return (
    <Grid container justify="space-between" spacing={1}>
      <Grid item xs={6}>
        <DatePicker {...start} fullWidth={fullWidth} />
      </Grid>
      <Grid item xs={6}>
        <DatePicker {...end} fullWidth={fullWidth} />
      </Grid>
    </Grid>
  );
};
export default DatePickerRange;
