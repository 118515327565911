import React, { FC } from 'react';
import { Typography, Accordion, AccordionSummary, AccordionDetails, Grid } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import { useAthleteTeamsQuery } from '../../../../api';
import moment from 'moment';

const useStyles = makeStyles({
  row: {
    display: 'flex',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  tinyIcon: {
    fontSize: 18,
  },
  icon: {
    fontSize: 20,
  },
  tableHead: {
    fontWeight: 'bold',
  },
  header: {
    fontWeight: 600,
    fontSize: 15,
    letterSpacing: 1.2,
  },
});

const NationalTeamAccordion: FC<{ id: string; sportId: string }> = ({ id, sportId }) => {
  const { loading, data } = useAthleteTeamsQuery({ skip: !id, variables: { sportId, id } });
  const classes = useStyles();

  const team = data?.dirNationalTeams[0];

  return (
    <Accordion key={id} style={{ width: '100%' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`org-content`} id={`org-header`}>
        <Typography className={classes.header} color="primary">
          Сборные команды
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: 'column' }}>
        {team && (
          <>
            <Grid container spacing={1}>
              <Grid item>
                <strong>Название мероприятия:</strong>
              </Grid>
              <Grid item>{team?.dirSportingEvent?.name}</Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item>
                <strong>Дата проведения:</strong>
              </Grid>
              <Grid item>
                {moment(team?.dirSportingEvent?.startDate).format('DD.MM.YYYY')} -{' '}
                {moment(team?.dirSportingEvent?.endDate).format('DD.MM.YYYY')}
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item>
                <strong>Регион сборной:</strong>
              </Grid>
              <Grid item>{team?.dirRegion?.fullName}</Grid>
            </Grid>
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default NationalTeamAccordion;
