import { Button, Grid, TextField, Typography } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import {
  RegionArchiveMutationHookResult,
  RegionCreateMutationHookResult,
  RegionFragment,
  RegionUnArchiveMutationHookResult,
  RegionUpdateMutationHookResult,
  useListOrganizationOfOivQuery,
  useListSportsOfBaseRegionsQuery,
  useRegionArchiveMutation,
  useRegionByIdQuery,
  useRegionCreateMutation,
  useRegionUnArchiveMutation,
  useRegionUpdateMutation,
} from '../../../api';

import ArchiveWidget from '../Shared/Archive';
import { ContentWrapperWidget } from '../../layouts';
import { FederalDistricts } from '../../../module';
import { Input } from '../../Inputs';
import { Link } from 'react-router-dom';
import SaveIcon from '@material-ui/icons/Save';
import getMessage from '../../../messages';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import * as Validation from '../../../utils/validation';

const MainInfo: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const { data, refetch } = useRegionByIdQuery({ variables: { id }, fetchPolicy: 'no-cache' });
  const { handleSubmit, control, errors, reset, formState, setValue } = useForm({
    defaultValues: data?.dirRegion as RegionFragment,
  });
  const [emailValue, setEmailValue] = useState(data?.dirRegion?.roivOfficialEmail || '');

  const { data: dataOiv } = useListOrganizationOfOivQuery({ variables: { id } });
  const { data: baseRegions } = useListSportsOfBaseRegionsQuery({ variables: { id } });

  const { push } = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [create]: RegionCreateMutationHookResult = useRegionCreateMutation();
  const [update]: RegionUpdateMutationHookResult = useRegionUpdateMutation();
  const [archive]: RegionArchiveMutationHookResult = useRegionArchiveMutation();
  const [unarchive]: RegionUnArchiveMutationHookResult = useRegionUnArchiveMutation();

  const onSubmit = async (values: RegionFragment) => {
    try {
      if (id !== 'new') {
        await update({
          variables: {
            id,
            fullName: values.fullName,
            shortName: values.shortName,
            code: values.code,
            regionId: { connect: { id: values.dirFederalDistrict.value } },
            roivEmail: values.roivOfficialEmail?.length === 0 ? null : values.roivOfficialEmail,
          },
        });
        enqueueSnackbar(`Регион "${values?.fullName}" успешно обновлен`, { variant: 'success' });
      } else {
        const { data } = await create({
          variables: {
            fullName: values.fullName as string,
            shortName: values.shortName as string,
            code: values.code,
            roivEmail: values.roivOfficialEmail?.length === 0 ? null : values.roivOfficialEmail,
            regionId: { connect: { id: values.dirFederalDistrict.value } },
          },
        });
        if (data?.createDirRegion && data?.createDirRegion?.id) {
          enqueueSnackbar(`Регион "${values?.fullName}" успешно добавлен`, { variant: 'success' });
          push(`/region/${data?.createDirRegion?.id}`);
        }
      }
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  useEffect(() => {
    if (data?.dirRegion) {
      reset(data?.dirRegion);
    }
  }, [data, reset]);

  return (
    <>
      <Grid container>
        {readonly ? (
          <Grid container>
            <Grid item container>
              <strong>Название города :{`${data?.dirRegion?.fullName || ''}`}</strong>
            </Grid>
            <Grid item container>
              <strong>Краткое название: {` ${data?.dirRegion?.shortName || '~'}`}</strong>
            </Grid>
            <Grid item container>
              <strong>Код: {` ${data?.dirRegion?.code || '~'}`}</strong>
            </Grid>
            <Grid item container>
              <strong>Федеральный округ: {` ${data?.dirRegion?.dirFederalDistrict?.label || ''}`}</strong>
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid item lg={6} md={12} xs={12}>
              <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
                <ContentWrapperWidget>
                  <Grid container spacing={1}>
                    <Grid item md={6}>
                      <Input
                        label="Название"
                        control={control}
                        error={!!errors['fullName']}
                        name="fullName"
                        rules={{ required: true }}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Input
                        label="Краткое название"
                        control={control}
                        error={!!errors['shortName']}
                        name="shortName"
                        rules={{ required: true }}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Input
                        label="Код"
                        control={control}
                        error={!!errors['code']}
                        name="code"
                        rules={{ required: true }}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <FederalDistricts
                        label="Федеральный округ"
                        error={!!errors['dirFederalDistrict']}
                        name="dirFederalDistrict"
                        control={control}
                        rules={{ required: true }}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <Input
                        label="Официальная почта РОИВ"
                        rules={{
                          validate: (value) => {
                            return value.length === 0 || !Validation.email(value) || 'Неверный формат Email';
                          },
                        }}
                        control={control}
                        style={{ marginBottom: 16 }}
                        error={!!errors['roivOfficialEmail']}
                        errorMessage={errors['roivOfficialEmail']?.message || ''}
                        name="roivOfficialEmail"
                      />
                    </Grid>
                    <Grid item md={12}>
                      <Button
                        variant="outlined"
                        color="primary"
                        type="submit"
                        size="small"
                        startIcon={<SaveIcon color="primary" />}
                        disabled={!formState.isDirty}
                      >
                        Сохранить
                      </Button>
                    </Grid>
                  </Grid>
                </ContentWrapperWidget>
              </form>
            </Grid>
            {id && id !== 'new' && (
              <Grid item lg={12} md={12} xs={12}>
                <ArchiveWidget
                  archive={data?.dirRegion?.archive || null}
                  id={id}
                  refetch={refetch}
                  onArchive={archive}
                  onUnarchive={unarchive}
                />
              </Grid>
            )}
          </>
        )}
        <Grid item xs={12} spacing={1} direction="row">
          <ContentWrapperWidget>
            <Grid item xs={12}>
              <Typography variant="body2">
                <strong>ОИВ в области физической культуры и спорта в субъекте РФ:</strong>&nbsp;
                {(dataOiv?.dirOrganizations.length && dataOiv?.dirOrganizations?.[0].label && (
                  <Link
                    style={{
                      fontWeight: 500,
                      cursor: 'pointer',
                      textDecoration: 'none',
                    }}
                    target="_blank"
                    to={`/organization/${dataOiv?.dirOrganizations?.[0].value}`}
                  >
                    {dataOiv?.dirOrganizations?.[0].label}
                  </Link>
                )) ||
                  '~'}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2">
                <strong>Базовый вид спорта:</strong>
              </Typography>
              {(baseRegions?.dirSports.length &&
                baseRegions?.dirSports.map((sport) => {
                  return sport.baseRegions?.map((region) => {
                    if (region.dirRegion.id !== id) return null;
                    return (
                      <Grid
                        key={sport.value}
                        item
                        xs={12}
                        style={{
                          cursor: 'pointer',
                          color: region && moment(region.endDate).isBefore(moment()) ? '#cecece' : '#000000',
                        }}
                      >
                        <span>{sport.label}</span>
                        <span>
                          {region.startDate && (
                            <React.Fragment> c {region && moment(region.startDate).format('DD.MM.YY')}</React.Fragment>
                          )}
                          {region.endDate && (
                            <React.Fragment> по {region && moment(region.endDate).format('DD.MM.YY')}</React.Fragment>
                          )}
                        </span>
                      </Grid>
                    );
                  });
                })) || <span>~</span>}
            </Grid>
          </ContentWrapperWidget>
        </Grid>
      </Grid>
    </>
  );
};

export default MainInfo;
