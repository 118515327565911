import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import { GenderSelect, TypeSports } from '../../module';
import { FieldErrors } from 'react-hook-form';
import { ControllerProps } from 'react-hook-form/dist/types/props';
import { DatePickerRange, Input, Select } from '../Inputs';
import Organizations from '../../module/Organizations';
import Positions from '../../module/Positions';
import Regions from '../../module/Regions';
import StatusConfirm from '../../module/StatusConfirm';
import TypeSportsSelect from '../../module/TypeSportsSelect';

interface TrainersAdditionalProps extends Pick<ControllerProps<'input'>, 'control'> {
  errors: FieldErrors;
}

const TrainersAdditional: FC<TrainersAdditionalProps> = ({ errors, control }) => {
  return (
    <>
      <Grid item xs={3}>
        <Select
          label="В архиве"
          data={[
            { value: '', label: ' ' },
            { value: false, label: 'Нет' },
            { value: true, label: 'Да' },
          ]}
          control={control}
          error={errors['archive']}
          name="archive"
          defaultValue={{ value: false, label: 'Нет' }}
        />
      </Grid>
      <Grid item xs={3}>
        <Input
          label="Предыдущая фамилия"
          control={control}
          error={!!errors['previousLastname']}
          name="previousLastname"
        />
      </Grid>
      <Grid item xs={4}>
        <DatePickerRange
          start={{ label: 'Дата рождения с', control, error: !!errors['birthday_gte'], name: 'birthday_gte' }}
          end={{ label: 'по', control, error: !!errors['birthday_lte'], name: 'birthday_lte' }}
        />
      </Grid>
      <Grid item xs={2}>
        <GenderSelect label="Пол" control={control} error={!!errors['isMale']} name="isMale" />
      </Grid>
      <Grid item xs={3}>
        <Input label="Email" control={control} error={!!errors['email']} name="email" />
      </Grid>
      <Grid item xs={3}>
        <Organizations
          control={control}
          label="Место работы"
          error={!!errors['dirOrganization']}
          name="dirOrganization"
        />
      </Grid>
      <Grid item xs={3}>
        <TypeSportsSelect control={control} label="Вид спорта" error={!!errors['dirSport']} name="dirSport" />
      </Grid>
      <Grid item xs={3}>
        <Positions control={control} label="Должность" error={!!errors['clsPosition']} name="clsPosition" />
      </Grid>
      <Grid item xs={3}>
        <Regions control={control} label="Регион" error={!!errors['dirRegion']} name="dirRegion" />
      </Grid>
      <Grid item xs={3}>
        <StatusConfirm
          control={control}
          label="Статус персоны"
          error={!!errors['clsStatusPerson']}
          name="clsStatusPerson"
        />
      </Grid>
    </>
  );
};

export default TrainersAdditional;
