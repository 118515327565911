import { Box, Button, Grid } from '@material-ui/core';
import { GroupDisciplines, TeamStatus, TypeSports } from '../../module';
import { Input, Select } from '../Inputs';
import React, { FC, useEffect, useState } from 'react';
import { useGenerateNationalTeamsXlsFileLazyQuery, useNationalTeamsCountLazyQuery } from '../../api';

import ClearIcon from '@material-ui/icons/Clear';
import ContentWrapper from '../layouts/ContentWrapper/ContentWrapper';
import { Count } from '../Count';
import GetAppIcon from '@material-ui/icons/GetApp';
import Regions from '../../module/Regions';
import SearchIcon from '@material-ui/icons/Search';
import SportingEvents from '../../module/SportEvents';
import TuneIcon from '@material-ui/icons/Tune';
import TypeSportsSelect from '../../module/TypeSportsSelect';
import { makeStyles } from '@material-ui/core/styles';
import { onSetNationalTeamFilter } from './helpers';
import { useForm } from 'react-hook-form';
import useGenerateXlsx from '../../hooks/useGenerateXlsx';

interface SportingEvent {
  registryNumber?: number;
  name_contains?: string;
  sports_some?: {
    dirSport?: {
      id: string;
    };
  };
}

interface Value {
  registryNumber?: number;
  dirRegion?: {
    label: string;
    value: string;
  };
  sportType?: {
    label: string;
    value: string;
  };
  event?: {
    label: string;
    value: string;
  };
  activityRegistryNumber?: number;
  dealStatus?: {
    label: string;
    value: string;
  };
  archive?: {
    label: string;
    value: boolean | string;
  };
}

const useStyle = makeStyles((theme) => ({
  btn: {
    marginRight: theme.spacing(2),
  },
}));

const NationalTeams: FC<{
  onSubmit?: (T: any, E: any) => void;
  value?: any;
  first: number;
  defaultFilter?: any;
}> = ({ onSubmit = () => {}, value = {}, defaultFilter = {}, first }) => {
  const style = useStyle();

  const [additional, setAdditional] = useState<boolean>(Object.keys(value)?.length > 0);
  const [filter, setFilter] = useState<any>({
    ...defaultFilter,
    archive: null,
  });

  const { handleSubmit, control, errors, reset, formState, getValues } = useForm({
    defaultValues: value,
  });

  const { onDownloadFile } = useGenerateXlsx({
    lazyQuery: useGenerateNationalTeamsXlsFileLazyQuery,
    getValues,
    setFilter: onSetNationalTeamFilter,
    fileName: 'nationalTeams',
    first,
  });

  const handleSearchBox = (value: Value) => {
    const filters: any = onSetNationalTeamFilter(value);

    setFilter(filters);
    onSubmit(filters, getValues());
  };

  return (
    <ContentWrapper>
      <form onSubmit={handleSubmit(handleSearchBox)}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <Input
              label="Реестровый номер"
              control={control}
              error={!!errors['registryNumber']}
              name="registryNumber"
            />
          </Grid>
          <Grid item xs={3}>
            <SportingEvents label="Название мероприятия" error={!!errors['event']} control={control} name="event" />
          </Grid>
          <Grid item xs={3}>
            <Regions label="Регион" error={!!errors['dirRegion']} control={control} name="dirRegion" />
          </Grid>
          <Grid item xs={3}>
            <TypeSportsSelect label="Вид спорта" error={!!errors['sportType']} control={control} name="sportType" />
          </Grid>

          {additional && (
            <React.Fragment>
              <Grid item xs={3}>
                <Input
                  label="Реестровый номер мероприятия"
                  control={control}
                  error={!!errors['activityRegistryNumber']}
                  name="activityRegistryNumber"
                />
              </Grid>
              <Grid item xs={3}>
                <TeamStatus
                  label="статус согласования"
                  error={!!errors['dealStatus']}
                  control={control}
                  name="dealStatus"
                />
              </Grid>
              <Grid item xs={3}>
                <Select
                  label="В архиве"
                  data={[
                    { value: '', label: ' ' },
                    { value: false, label: 'Нет' },
                    { value: true, label: 'Да' },
                  ]}
                  control={control}
                  error={!!errors['archive']}
                  name="archive"
                />
              </Grid>
            </React.Fragment>
          )}

          <Grid item container alignItems={'center'}>
            <Button
              variant="outlined"
              className={style.btn}
              color="primary"
              type="button"
              onClick={() => {
                onSubmit(
                  {
                    archive: null,
                  },
                  {},
                );
                setFilter({
                  archive: null,
                });
                reset({
                  archive: { value: false, label: 'Нет' },
                  registryNumber: '',
                  dirRegion: null,
                  sportType: null,
                  activityName: '',
                  activityRegistryNumber: '',
                  dealStatus: null,
                });
              }}
              size="small"
              startIcon={<ClearIcon />}
            >
              Сброс
            </Button>
            <Button
              variant="outlined"
              color="primary"
              type="submit"
              size="small"
              className={style.btn}
              startIcon={<SearchIcon />}
              disabled={!formState.isDirty}
            >
              Поиск
            </Button>
            <Button
              onClick={() => setAdditional(!additional)}
              type="button"
              className={style.btn}
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<TuneIcon />}
            >
              Фильтр
            </Button>
            <Box pl={4}>
              <Button
                variant="outlined"
                color="primary"
                disabled={!formState.isDirty}
                onClick={onDownloadFile}
                size={'small'}
                startIcon={<GetAppIcon />}
              >
                XLS
              </Button>
              <Count key={JSON.stringify(filter)} query={useNationalTeamsCountLazyQuery} filter={filter} />
            </Box>
          </Grid>
        </Grid>
      </form>
    </ContentWrapper>
  );
};

export default NationalTeams;
