import _ from 'lodash';

const userRights = () => {
  const role = localStorage.getItem('userRole');
  const userRegion = localStorage.getItem('userRegion');
  const userEmail = localStorage.getItem('userEmail');
  const schoolProfile = localStorage.getItem('regionalSchoolProfile');
  const regionalSchoolProfile = schoolProfile ? JSON.parse(schoolProfile) : null;
  const regionalSchoolProfileHasEkp =
    regionalSchoolProfile?.dirOrganization.clsOrganizationCategories?.filter(
      (cat: any) => cat.registryNumber === 100003169,
    )?.length > 0;
  const regionalAdminProfile =
    localStorage.getItem('regionalAdminProfile') !== null &&
    JSON.parse(localStorage.getItem('regionalAdminProfile') || '""');
  const fcpsrSportingEventOrganizerProfile =
    localStorage.getItem('fcpsrSportingEventOrganizerProfile') !== null &&
    JSON.parse(localStorage.getItem('fcpsrSportingEventOrganizerProfile') || '""');

  const gskRefereeProfile =
    localStorage.getItem('gskRefereeProfile') !== null && JSON.parse(localStorage.getItem('gskRefereeProfile') || '""');

  const sportingEvents =
    role === 'GSK_REFEREE'
      ? _.get(gskRefereeProfile, 'sportingEvents', [])
      : _.get(fcpsrSportingEventOrganizerProfile, 'sportingEvents', []);
  const calendars = _.get(fcpsrSportingEventOrganizerProfile, 'dirCalendars', []);
  const regularOrg =
    localStorage.getItem('regularOrg') !== null && JSON.parse(localStorage.getItem('regularOrg') || '""');

  const org = regionalSchoolProfile?.dirOrganization;
  const adminRegion = regionalAdminProfile?.dirRegion?.id;

  return {
    isRoiv: role === 'ROIV_SPECIALIST' || role === 'FCPSR_ROIV_SPECIALIST',
    isAdminOrSpecialist: role === 'FCPSR_ADMINISTRATOR' || role === 'FCPSR_SPECIALIST' || role === 'ADMIN',
    isRegionalSchool: role === 'REGIONAL_SCHOOL',
    isRoivFspcr: role === 'FCPSR_ROIV_SPECIALIST',
    isGskReferee: role === 'GSK_REFEREE',
    isRegionalAdmin: role === 'REGIONAL_ADMINISTRATOR',
    isSportingEventOperatorFspcr: role === 'FCPSR_SPORTING_EVENT_OPERATOR',
    isAdminFspcr: role === 'FCPSR_ADMINISTRATOR',
    canSendNationalTeamBackToStart:
      role && ['ADMIN', 'FCPSR_ADMINISTRATOR', 'FCPSR_ROIV_SPECIALIST', 'REGIONAL_SCHOOL'].includes(role),
    canEditSportingEventTeamMember: role === 'FCPSR_ADMINISTRATOR' || role === 'ADMIN',
    regionalSchoolProfileHasEkp,
    userRegion,
    userEmail,
    regularOrg,
    hasLimAccess:
      role === 'FCPSR_ADMINISTRATOR' ||
      role === 'FCPSR_SPORTING_EVENT_OPERATOR' ||
      role === 'ROIV_SPECIALIST' ||
      role === 'REGIONAL_ADMINISTRATOR',
    hasEkpLimAccess: role === 'REGIONAL_SCHOOL' || role === 'MINSPORT',
    canChangeSportingEventStatus: role === 'FCPSR_ADMINISTRATOR' || role === 'FCPSR_SPECIALIST' || role === 'ADMIN',
    hideEventStatusForRole:
      role === 'FCPSR_SPORTING_EVENT_OPERATOR' || role === 'ROIV_SPECIALIST' || role === 'FCPSR_ROIV_SPECIALIST',
    hasSportEventLimAccess: role === 'FCPSR_SPORTING_EVENT_OPERATOR',
    canChangeEkpSportingEventStatus: role === 'REGIONAL_SCHOOL' || role === 'ADMIN',
    canChangeNationalTeamStatus:
      role === 'FCPSR_ADMINISTRATOR' ||
      role === 'FCPSR_SPECIALIST' ||
      role === 'FCPSR_SPORTING_EVENT_OPERATOR' ||
      role === 'FCPSR_ROIV_SPECIALIST' ||
      role === 'ROIV_SPECIALIST' ||
      role === 'REGIONAL_ADMINISTRATOR' ||
      role === 'ADMIN',
    who: role,
    adminRegion,
    regionalSchoolOrg: org?.id,
    hasAdditionalFilter:
      role === 'ROIV_SPECIALIST' || role === 'FCPSR_ROIV_SPECIALIST'
        ? {
            persons: {
              OR: [
                { dirForeignCity: { id: userRegion } },
                { dirRegion: { id: userRegion } },
                { additionalRegions_some: { dirRegion: { id: userRegion } } },
              ],
            },
            athletes: {
              dirPerson: {
                OR: [
                  { dirForeignCity: { id: userRegion } },
                  { dirRegion: { id: userRegion } },
                  { additionalRegions_some: { dirRegion: { id: userRegion } } },
                ],
              },
            },
            trainers: {
              dirPerson: {
                OR: [
                  { dirForeignCity: { id: userRegion } },
                  { dirRegion: { id: userRegion } },
                  { additionalRegions_some: { dirRegion: { id: userRegion } } },
                ],
              },
            },
            referees: {
              dirPerson: {
                OR: [
                  { dirForeignCity: { id: userRegion } },
                  { dirRegion: { id: userRegion } },
                  { additionalRegions_some: { dirRegion: { id: userRegion } } },
                ],
              },
            },
            organizations: {
              dirRegion: { id: userRegion },
            },
            national_teams: {
              dirRegion: { id: userRegion },
            },
            dirSportingEvents: {
              dirNationalTeams_some: {
                dirRegion: { id: userRegion },
              },
            },
          }
        : role === 'REGIONAL_SCHOOL'
        ? {
            dirSportingEvents: {
              dirCalendar: {
                clsCalendarCategory: {
                  registryNumber: 100003375,
                },
              },
            },
            national_teams: {
              dirRegion: { id: org?.id },
            },
            organizations: {
              id_in: [org.id],
            },
            athletes: {
              sports_some: {
                organizations_some: {
                  dirOrganization: { id: org.id },
                  exclusionDate: null,
                },
              },
            },
            trainers: {
              sports_some: {
                organizations_some: {
                  dirOrganization: { id: org.id },
                  dismissalDate: null,
                },
              },
            },
            minsports: {
              sports_some: {
                dirSport: {
                  id_in: org?.sports?.map((s: any) => s?.dirSport?.id),
                },
              },
            },
          }
        : role === 'REGIONAL_ADMINISTRATOR'
        ? {
            athletes: {
              dirPerson: { AND: [{ dirRegion: { id: adminRegion } }] },
            },
            trainers: {
              dirPerson: { AND: [{ dirRegion: { id: adminRegion } }] },
            },
            referees: {
              dirPerson: { AND: [{ dirRegion: { id: adminRegion } }] },
            },
            organizations: {
              dirRegion: { id: adminRegion },
            },
            national_teams: {
              dirRegion: { id: adminRegion },
            },
          }
        : role === 'FCPSR_SPORTING_EVENT_OPERATOR'
        ? {
            dirSportingEvents: {
              OR: [
                {
                  id_in: sportingEvents?.map((event: any) => event?.id),
                },
                {
                  dirCalendar: { id_in: calendars?.map((calendar: any) => calendar?.id) },
                  sports_some: {
                    dirSport: {
                      id_in: regularOrg?.sports?.map((s: any) => s?.dirSport?.id),
                    },
                  },
                },
              ],
            },
            calendars:
              calendars?.length > 0
                ? {
                    id_in: calendars?.map((calendar: any) => calendar?.id),
                  }
                : {},
            sportsFilter: regularOrg?.sports?.map((s: any) => s?.dirSport?.id),
            national_teams: {
              dirSportingEvent: {
                OR: [
                  { id_in: sportingEvents?.map((event: any) => event?.id) },
                  {
                    dirCalendar: { id_in: calendars?.map((calendar: any) => calendar?.id) },
                    sports_some: {
                      dirSport: {
                        id_in: regularOrg?.sports?.map((s: any) => s?.dirSport?.id),
                      },
                    },
                  },
                ],
              },
            },
            referees: {
              sports_some: { dirSport: { id_in: _.get(regularOrg, 'sports', []).map((s: any) => s?.dirSport?.id) } },
            },
          }
        : role === 'GSK_REFEREE'
        ? {
            dirSportingEvents: {
              id_in: sportingEvents?.map((event: any) => event?.id),
            },
          }
        : {},
    formAdditionalFilter:
      role === 'FCPSR_ROIV_SPECIALIST'
        ? {
            dirRegions: { id: userRegion },
          }
        : {},
  };
};

export default userRights;
