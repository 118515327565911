import { AthleteUpdateAmpluaToSportMutationHookResult, useAthleteUpdateAmpluaToSportMutation } from '../../../../api';
import { useForm } from 'react-hook-form';
import React, { FC, useState } from 'react';
import { Grid, Button, IconButton } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import getMessage from '../../../../messages';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';
import { makeStyles } from '@material-ui/core/styles';
import { Input } from '../../../Inputs';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const Role: FC<{
  data?: string;
  id: string;
  refetch: any;
  readonly?: boolean;
}> = ({ data, id, refetch, readonly }) => {
  const classes = useStyles();

  const { handleSubmit, control, errors, formState, reset } = useForm({
    defaultValues: { amplua: data || '' },
  });
  const [isEditing, setIsEditing] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [update]: AthleteUpdateAmpluaToSportMutationHookResult = useAthleteUpdateAmpluaToSportMutation();

  const onSubmit = handleSubmit(async (values?) => {
    try {
      await update({
        variables: {
          idSport: id,
          amplua: values?.amplua,
        },
      });
      enqueueSnackbar(`Амплуа успешно обновлено`, { variant: 'success' });
      setIsEditing(false);
      refetch();
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  });

  return (
    <Grid container>
      {isEditing ? (
        <Grid item container md={6}>
          <form onSubmit={onSubmit}>
            <Grid container alignItems={'flex-end'} spacing={1}>
              <Grid item>
                <Input label="Амплуа" control={control} error={!!errors['amplua']} name="amplua" />
              </Grid>
              <Grid item>
                <Button
                  aria-label="add"
                  type="submit"
                  size={'small'}
                  color={'primary'}
                  variant={'outlined'}
                  disabled={!formState.isDirty}
                  startIcon={<SaveIcon />}
                >
                  Сохранить
                </Button>
              </Grid>
              <Grid item>
                <Button
                  aria-label="delete"
                  type="button"
                  size={'small'}
                  color={'secondary'}
                  variant={'outlined'}
                  disabled={!formState.isDirty}
                  startIcon={<ClearIcon />}
                  onClick={() => setIsEditing(false)}
                >
                  Отменить
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      ) : (
        <Grid item container md={6} alignItems={'flex-end'}>
          <Grid item>
            <Typography>
              Амплуа<strong>{`${data && `: ${data}`}`}</strong>
            </Typography>{' '}
          </Grid>
          <Grid item>
            {!readonly && (
              <IconButton
                edge="end"
                aria-label="add"
                size="small"
                onClick={() => setIsEditing(true)}
                style={{ display: 'inline', marginLeft: 16 }}
              >
                <EditIcon color="primary" />
              </IconButton>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default Role;
