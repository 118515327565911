import {
  Accordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Typography,
  withStyles,
} from '@material-ui/core';
import { ContentWrapperWidget, MainTemplate } from '../../components/layouts';
import { CreateDocumentMutationHookResult, useCreateDocumentMutation, useDocumentByIdQuery } from '../../api';
import React, { ChangeEvent, FC, useState } from 'react';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MainInfo from '../../components/widgets/Documents/MainInfo';
import { blue } from '@material-ui/core/colors';
import moment from 'moment';
import onExpand from '../../utils/onExpand';
import { usePermission } from '../../hooks/usePermission';
import { useRouteMatch } from 'react-router-dom';

type AccordionTypes = 'MainInfo' | undefined;

const AccordionSummary = withStyles({
  root: {
    backgroundColor: blue[700],
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    color: 'white',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary) as any;

// @TODO: Update View for Dialog request to Archive

const Single: FC<{}> = () => {
  const {
    params: { id },
  } = useRouteMatch();
  // const [page, setPage] = useState<number>(0);
  const [expanded, setExpanded] = useState<AccordionTypes>('MainInfo');

  // const access = usePermission('directory', 'person');
  // const style = useStyle();
  // const canAdd = true;
  const { data } = useDocumentByIdQuery({ variables: { id }, fetchPolicy: 'no-cache' });
  const OptionTitleArchive = data?.dirDocument?.archive
    ? ` (В архиве с ${moment(data?.dirDocument?.archive?.date).format('DD.MM.YYYY')})`
    : '';

  const [create]: CreateDocumentMutationHookResult = useCreateDocumentMutation();
  const handleChangeAccordion = (panel: AccordionTypes) => (event: ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : undefined);
  };

  const access = usePermission('directory', 'documents');

  return (
    <MainTemplate
      pageName=""
      url=""
      title={id === 'new' ? 'Добавить новый документ' : `Документ: ${data?.dirDocument?.title}`}
    >
      <ContentWrapperWidget>
        {id === 'new' ? (
          <h2>Добавить новый документ</h2>
        ) : (
          <h2>
            Документ: {data?.dirDocument?.title}
            {OptionTitleArchive}
          </h2>
        )}
        <Accordion expanded={expanded === 'MainInfo'} onChange={handleChangeAccordion('MainInfo')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Общая информация</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {onExpand(expanded, 'MainInfo') && (
              <MainInfo id={data?.dirDocument?.id || 'new'} readonly={!access.includes(2)} />
            )}
          </AccordionDetails>
        </Accordion>
      </ContentWrapperWidget>
    </MainTemplate>
  );
};

export default Single;
