import React, { FC } from 'react';
import { useCountriesFetchQuery } from '../api';
import { Select } from '../components/Inputs';
import { ControllerProps } from 'react-hook-form/dist/types/props';

interface Country extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  error: boolean | undefined;
  disabled?: boolean;
  enablePortal?: boolean;
  multiple?: boolean;
  filter?: any;
}

const Countries: FC<Country> = ({ label, error, control, name, rules, disabled, enablePortal, multiple, filter }) => {
  const { data } = useCountriesFetchQuery({ variables: { filter } });
  return (
    <Select
      label={label}
      data={(data && data.dirCountries) || []}
      control={control}
      error={error}
      name={name}
      rules={rules}
      enablePortal={enablePortal}
      disabled={disabled}
      multiple={multiple}
    />
  );
};

export default Countries;
