import { Paper } from '@material-ui/core';
import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}));

const ContentWrapper: FC<{ children: NonNullable<React.ReactNode> }> = ({ children }) => {
  const styles = useStyle();

  return (
    <Paper square elevation={2} className={styles.container}>
      {children}
    </Paper>
  );
};

export default ContentWrapper;
