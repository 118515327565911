import React, { FC } from 'react';
import { Controller } from 'react-hook-form';
import { TextField } from '@material-ui/core';
import MaskedInput from 'react-text-mask';
import { ControllerProps } from 'react-hook-form/dist/types/props';

const TextMaskCustom: FC<any> = (props: any) => {
  const { inputRef, mask, showMask, guide, placeholderChar, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={mask}
      placeholderChar={placeholderChar}
      keepCharPositions
      guide={guide}
      showMask={showMask}
    />
  );
};

interface ReactHookFormInput extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules' | 'type'> {
  label: string;
  errorMessage?: string;
  error: boolean | undefined;
  disabled?: boolean;
  mask: any;
  showMask?: boolean;
  guide?: boolean;
  placeholderChar?: string;
  isNotTextMask?: boolean;
}

const Input: FC<ReactHookFormInput> = ({
  name,
  error,
  type = 'text',
  label,
  errorMessage = '',
  control,
  rules,
  disabled,
  mask,
  showMask,
  guide,
  placeholderChar,
  isNotTextMask,
}) => {
  const maskFunction = require('../../utils/validation')[mask];

  return (
    <Controller
      {...(!isNotTextMask
        ? {
            as: (
              <TextField
                size="small"
                type={type}
                label={
                  <>
                    {label}
                    {rules?.required === true && <span style={{ color: 'red' }}>*</span>}
                  </>
                }
                fullWidth
                error={error}
                helperText={errorMessage}
                disabled={disabled}
                defaultValue=""
                InputProps={{
                  inputComponent: TextMaskCustom as any,
                  inputProps: {
                    mask,
                    showMask,
                    guide,
                    placeholderChar,
                  },
                }}
              />
            ),
          }
        : {
            render: (props) => (
              <TextField
                size="small"
                type={type}
                label={
                  <>
                    {label}
                    {rules?.required === true && <span style={{ color: 'red' }}>*</span>}
                  </>
                }
                fullWidth
                value={props.value}
                error={error}
                helperText={errorMessage}
                disabled={disabled}
                defaultValue=""
                onChange={(e) => maskFunction(e.target.value) === undefined && props.onChange(e.target.value)}
              />
            ),
          })}
      control={control}
      name={name}
      rules={rules}
      defaultValue=""
    />
  );
};

export default Input;
