import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Link,
  LinearProgress,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  TableContainer,
  Table,
  Paper,
  Box,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import { useSportingEventUnArchivedTeamInfoQuery } from '../../../../api';
import _, { divide, get } from 'lodash';
import { teamCount } from '../../SportingEvents/helpers/teamCount';
import { fetchSportingEventLateArrivalReport } from '../../../BtnReport/services/fetchSportingEventLateArrivalReport';

const useStyles = makeStyles({
  row: {
    display: 'flex',
  },
  container: {
    maxHeight: 440,
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  tinyIcon: {
    fontSize: 18,
  },
  icon: {
    fontSize: 20,
  },
  tableHead: {
    fontWeight: 'bold',
  },
  header: {
    fontWeight: 600,
    fontSize: 15,
    letterSpacing: 1.2,
  },
});

const LateArrival: FC<{ readonly?: boolean; id?: string; eventName?: string; isUniverciad?: boolean }> = ({
  readonly,
  id,
  eventName,
  isUniverciad,
}) => {
  const classes = useStyles();

  // @ts-ignore
  const { loading, data } = useSportingEventUnArchivedTeamInfoQuery({
    fetchPolicy: 'no-cache',
    returnPartialData: true,
    variables: {
      id,
    },
  });

  const items = useMemo(
    () =>
      _.sortBy(data?.dirSportingEvent?.dirNationalTeams?.map((t) => ({ ...t, name: t.dirRegion?.label })) || [], [
        'name',
      ]),
    [data],
  );

  // const handleArrivalReport = () => {
  //   fetchSportingEventLateArrivalReport({
  //     data: items,
  //     fileName: 'Недозаезд ' + eventName,
  //     eventName,
  //     isUniverciad,
  //   });
  // };

  return (
    <Accordion key="Недозаезд" style={{ width: '100%', marginBottom: 15 }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`Недозаезд-content`} id={`Недозаезд-header`}>
        <Grid container spacing={2} justify="space-between" alignItems="center">
          <Grid item>
            <Typography className={classes.header} color="primary">
              Недозаезд
            </Typography>
          </Grid>
          <Grid item onClick={(e) => e.stopPropagation()}></Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: 'column' }}>
        <Box>
          {/* <Button onClick={handleArrivalReport} size="small" variant="outlined" color="primary" endIcon={<SaveIcon />}>
            Скачать
          </Button> */}
        </Box>

        <Box pt={3}>
          {loading ? (
            <LinearProgress />
          ) : (
            <TableContainer className={classes.container} component={Paper}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell>Регион</TableCell>
                    {isUniverciad && <TableCell>ВУЗ</TableCell>}
                    <TableCell>Квота муж.</TableCell>
                    <TableCell>Квота жен.</TableCell>
                    <TableCell>Квота на сопр-ние</TableCell>
                    <TableCell>Квота итого</TableCell>
                    <TableCell>Факт муж</TableCell>
                    <TableCell>Факт жен</TableCell>
                    <TableCell>Факт сопр-ние</TableCell>
                    <TableCell>Факт итого</TableCell>
                    <TableCell>Недозаезд кол-во чел.</TableCell>
                    <TableCell>Недозаезд %</TableCell>
                    <TableCell>Место</TableCell>
                    <TableCell>Кол-во очков</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.map((item) => {
                    const {
                      countMen,
                      countWomen,
                      specialistsCount,
                      medicsCount,
                      delegationHeadsCount,
                      trainersCount,
                    } = teamCount(item as any);
                    const kvot =
                      get(item, 'manTeamQuota', 0) + get(item, 'womanTeamQuota', 0) + get(item, 'supportTeamQuota', 0);
                    const fact =
                      specialistsCount + medicsCount + trainersCount + delegationHeadsCount + countMen + countWomen;
                    return (
                      <TableRow key={item.id}>
                        <TableCell>{item.name}</TableCell>
                        {isUniverciad && <TableCell>{item?.university?.label || ''}</TableCell>}
                        <TableCell>{item?.manTeamQuota || ''}</TableCell>
                        <TableCell>{item?.womanTeamQuota || ''}</TableCell>
                        <TableCell>{item?.supportTeamQuota}</TableCell>
                        <TableCell>{kvot}</TableCell>
                        <TableCell>{countMen}</TableCell>
                        <TableCell>{countWomen}</TableCell>
                        <TableCell>{specialistsCount + medicsCount + trainersCount + delegationHeadsCount}</TableCell>
                        <TableCell>{fact}</TableCell>
                        <TableCell>{Math.abs(kvot - fact)}</TableCell>
                        <TableCell>{(100 * divide(Math.abs(kvot - fact), kvot)).toFixed(2)}%</TableCell>
                        <TableCell>{item.score || ''}</TableCell>
                        <TableCell>{item.point || ''}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default LateArrival;
