import { AppStore } from '../reducers';

export function getSettingsUserFilter(state: AppStore, nameDirectory: string) {
  return state.settings[nameDirectory]?.filter;
}

export function getSettingsUserFilterFormValue(state: AppStore, nameDirectory: string) {
  return state.settings[nameDirectory]?.form;
}

export function getSettingsUserColumnHidden(state: AppStore, nameDirectory: string) {
  return state.settings[nameDirectory]?.column?.hidden;
}

export function getSettingsUserColumnOrder(state: AppStore, nameDirectory: string) {
  return state.settings[nameDirectory]?.order || undefined;
}

export function getTableState(state: AppStore, nameDirectory?: string) {
  return nameDirectory ? state.settings[nameDirectory] : false;
}

export function getTableCurrentState(state: AppStore) {
  return state.table;
}

export function getTableCurrentStateSkip(state: AppStore) {
  return state.table.skip;
}
