import {
  useSportingEventCallAthleteNotifyQuery,
  UploadFileMutationHookResult,
  useUploadFileMutation,
  CallAthleteNotifyAddMutationHookResult,
  useCallAthleteNotifyAddMutation,
  CallAthleteNotifyDeleteMutationHookResult,
  useCallAthleteNotifyDeleteMutation,
  useCallAthleteNotifyAddFromExistMutation,
  CallAthleteNotifyAddFromExistMutationHookResult,
  useCallAthleteNotifyUpdateMutation,
  CallAthleteNotifyUpdateMutationHookResult,
} from '../../../api';
import React, { FC, useEffect, useState } from 'react';
import { Button, Dialog, DialogTitle, Grid, IconButton, LinearProgress, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import getMessage from '../../../messages';
import { useForm } from 'react-hook-form';
import { DatePicker, Input } from '../../Inputs';
import { DropzoneArea } from 'material-ui-dropzone';
import SaveIcon from '@material-ui/icons/Save';
import DocumentSelect from '../../../module/Documents';
import moment from 'moment';
import _ from 'lodash';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmDialog from '../../ConfirmDialog';
import EditIcon from '@material-ui/icons/Edit';
import NationalTeams from '../../../module/NationalTeams';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles({
  statusBox: {
    maxHeight: 150,
    overflowY: 'auto',
    scrollBehavior: 'smooth',
  },
  title: {
    fontWeight: 500,
    marginRight: '0.5rem',
  },
  link: {
    cursor: 'pointer',
  },
  verticalSpace: {
    paddingBottom: 15,
  },
  verticalSpaceMarge: {
    marginBottom: 35,
  },
  divider: {
    height: 1,
    width: '100%',
    marginBottom: '1rem',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  tinyIcon: {
    fontSize: 18,
  },
  icon: {
    fontSize: 20,
  },
  dropZone: {
    minHeight: 30,
    border: 0,
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    borderRadius: 0,

    '& > .MuiDropzonePreviewList-root': {
      margin: '0 !important',
      width: '100%',
      '& > .MuiDropzonePreviewList-imageContainer': {
        flexBasis: 'unset',
        width: '100%',
        maxWidth: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 15,
        '& > svg': {
          height: '40px',
        },
        '& > p': {
          marginLeft: 15,
          marginRight: 15,
        },
        '& > button': {
          top: 'unset',
          right: 0,
          position: 'unset',
          marginLeft: 30,

          color: '#d32f2f',
          boxShadow: 'unset',
        },
      },
    },
    '& > .MuiDropzoneArea-textContainer': {
      display: 'flex',

      '& > .MuiDropzoneArea-text': {
        margin: 0,
        fontSize: 15,
      },
      '& > .MuiDropzoneArea-icon': {
        width: 20,
        height: 20,
        marginLeft: 'auto',
      },
    },
    '&:focus': {
      outline: 0,
      border: 0,
      borderBotom: '2px solid #3f51b5',
    },
  },
});

export const DocumentComponent: FC<{
  readonly?: boolean;
  id?: string;
  eventId: string;
}> = ({ readonly, id, eventId }) => {
  const defaultNewDocumentState: any = {
    title: undefined,
    date: undefined,
    number: undefined,
  };

  const { handleSubmit, control, errors, reset, formState, setValue } = useForm({
    defaultValues: defaultNewDocumentState,
  });

  const {
    handleSubmit: documentFormHandleSubmit,
    control: documentFormControl,
    errors: documentFormErrors,
    reset: documentFormReset,
    formState: documentFormState,
  } = useForm({
    defaultValues: {
      dirDocument: undefined,
    },
  });

  const {
    handleSubmit: regionFormHandleSubmit,
    control: regionFormControl,
    errors: regionFormErrors,
    reset: regionFormReset,
    watch: regionFormWatch,
  } = useForm({
    defaultValues: {
      nationalTeams: [],
    },
  });
  const DOWNLOAD_LINK = process.env.REACT_APP_BACKEND_URI?.replace('/graphql', '');

  const watchNationalTeams = regionFormWatch('nationalTeams');

  const onOpenDialog = () => {
    setIsAddingNewDocumentFromSystem(true);
  };
  const { loading, data, refetch } = useSportingEventCallAthleteNotifyQuery({
    returnPartialData: true,
    variables: { id: eventId },
  });

  const [addCallNotify]: CallAthleteNotifyAddMutationHookResult = useCallAthleteNotifyAddMutation();
  const [
    addCallNotifyExist,
  ]: CallAthleteNotifyAddFromExistMutationHookResult = useCallAthleteNotifyAddFromExistMutation();
  const [deleteCallNotify]: CallAthleteNotifyDeleteMutationHookResult = useCallAthleteNotifyDeleteMutation();
  const [updateCallNotify]: CallAthleteNotifyUpdateMutationHookResult = useCallAthleteNotifyUpdateMutation();

  const [uploadDocumentFile]: UploadFileMutationHookResult = useUploadFileMutation();
  const [deleteItem, setDeleteItem] = useState<any>(null);
  const [editItem, setEditItem] = useState<any>(null);

  const [isAddingNewDocument, setIsAddingNewDocument] = useState(false);
  const [isAddingNEwDocumentFromSystem, setIsAddingNewDocumentFromSystem] = useState(false);
  const [file, setFile] = useState<Array<File>>([]);

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const addNewDocument = async (values: any) => {
    const { dirDocument } = values;
    try {
      if (isAddingNewDocument && dirDocument) {
        await addCallNotifyExist({
          variables: {
            idDocument: dirDocument?.value,
            id: eventId,
          },
        });
        handleSnackBar('success', 'документ успешно добавлен');
        documentFormReset();
        refetch();
        setIsAddingNewDocument(false);
      }
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const handleAddNewDocumentFromSystem = async (values: any) => {
    const { title, date, number } = values;
    try {
      if (isAddingNEwDocumentFromSystem && title && date && file) {
        uploadDocumentFile({
          variables: {
            file: file[0],
          },
        }).then(async (resp: any) => {
          const {
            uploadFile: { id: fileId },
          } = resp.data;
          await addCallNotify({
            variables: {
              id: eventId,
              idDocument: fileId,
              title,
              number,
              date,
            },
          });
          handleSnackBar('success', 'документ успешно добавлен');
          setIsAddingNewDocumentFromSystem(false);
          refetch();
          reset(defaultNewDocumentState);
        });
      }
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const classes = useStyles();

  const updateTeam = async (id: any) => {
    try {
      const existTeams = data?.dirSportingEvent?.athleteCallNotifications?.[editItem]?.teams?.reduce(
        (acc: any, team) => {
          return [
            ...acc,
            { value: team.ditNationalTeam?.id, label: team?.ditNationalTeam?.dirRegion?.label, valueTeam: team.id },
          ];
        },
        [],
      );
      const finalTeams = watchNationalTeams;
      const deleteNew = _.differenceBy(existTeams, finalTeams, 'value');
      const addNew = _.differenceBy(finalTeams, existTeams || [], 'value');
      await updateCallNotify({
        variables: {
          id: eventId,
          idCallNotify: id,
          // @ts-ignore
          teams: {
            create: addNew.map((t: any) => ({
              ditNationalTeam: { connect: { id: t.value } },
              isNotificationRequired: true,
              isNotified: false,
            })),
            delete: deleteNew.map((d: any) => ({ id: d.valueTeam })),
          },
        },
      });
      refetch();
      setEditItem(null);
    } catch (e) {
      console.log('Error');
    }
  };

  useEffect(() => {
    if (editItem !== null && editItem !== undefined) {
      // @ts-ignore
      regionFormReset({
        // @ts-ignore
        nationalTeams: data?.dirSportingEvent?.athleteCallNotifications?.[editItem]?.teams?.reduce((acc: any, team) => {
          return [
            ...acc,
            { value: team.ditNationalTeam?.id, label: team?.ditNationalTeam?.dirRegion?.label, valueTeam: team.id },
          ];
        }, []),
      });
    }
  }, [data, editItem]);

  if (loading) return <LinearProgress color="primary" variant="query" />;

  return (
    <React.Fragment>
      <Grid container>
        <Grid item container alignItems="center" spacing={2}>
          {!readonly && (
            <Grid item container spacing={2} alignItems="center" lg={6} md={6} sm={12} xs={12}>
              {isAddingNewDocument ? (
                <React.Fragment>
                  <form
                    onSubmit={documentFormHandleSubmit(addNewDocument)}
                    style={{ width: '100%', marginBottom: '16px', marginLeft: '8px' }}
                  >
                    <Grid item container spacing={2} alignItems="flex-end">
                      <Grid item md={5} xs={12}>
                        <DocumentSelect
                          label="Документ"
                          control={documentFormControl}
                          error={!!documentFormErrors['dirDocument']}
                          name="dirDocument"
                          rules={{ required: true }}
                        />
                      </Grid>
                      <Grid item>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          style={{ marginRight: '0.5rem' }}
                          startIcon={<SaveIcon />}
                          type="submit"
                          disabled={!documentFormState.isDirty}
                        >
                          Сохранить
                        </Button>

                        <Button
                          variant="outlined"
                          color="secondary"
                          style={{ marginRight: '0.5rem' }}
                          size="small"
                          onClick={() => {
                            setIsAddingNewDocument(false);
                            documentFormReset();
                          }}
                        >
                          Отменить
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </React.Fragment>
              ) : (
                <Grid item container spacing={2} alignItems="center" xs={12}>
                  <Grid item>
                    <span style={{ marginRight: '0.5rem' }}>Добавить документ-вызов</span>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ marginRight: '0.5rem' }}
                      size="small"
                      type="button"
                      onClick={() => setIsAddingNewDocument(true)}
                    >
                      Из базы
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ marginRight: '0.5rem' }}
                      size="small"
                      type="button"
                      onClick={onOpenDialog}
                      disabled={isAddingNewDocument}
                    >
                      Новый
                    </Button>
                  </Grid>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
        <br />
        <br />
        <Grid item container alignItems="center" spacing={2} xs={12}>
          <Grid item xs={2}>
            <strong>Дата создания вызова</strong>
          </Grid>
          <Grid item xs={2}>
            <strong>Название</strong>
          </Grid>
          <Grid item xs={2}>
            <strong>Дата документа</strong>
          </Grid>
          <Grid item xs={2}>
            <strong>Номер документа</strong>
          </Grid>
          <Grid item xs={3}>
            <strong>Отправлен в регионы</strong>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
        {data?.dirSportingEvent?.athleteCallNotifications?.map((item, index) => (
          <Grid item container alignItems="center" spacing={2} key={item?.id} xs={12}>
            <Grid item xs={2}>
              <span>{item?.document.createdAt && moment(item?.document.createdAt).format('DD.MM.YYYY')}</span>
            </Grid>
            <Grid item xs={2}>
              <span>
                <a href={DOWNLOAD_LINK + item?.document.file.path}>{item?.document.title}</a>
              </span>
            </Grid>
            <Grid item xs={2}>
              <span>{item?.document.date && moment(item?.document.date).format('DD.MM.YYYY')}</span>
            </Grid>
            <Grid item xs={2}>
              <span>{item?.document.number}</span>
            </Grid>
            <Grid item xs={3}>
              {index === editItem ? (
                <NationalTeams
                  label=""
                  data={data?.dirSportingEvent}
                  control={regionFormControl}
                  error={!!regionFormErrors['nationalTeams']}
                  name="nationalTeams"
                />
              ) : (
                item?.teams?.map((team) => team.ditNationalTeam?.dirRegion?.label).join(', ')
              )}
            </Grid>
            {!readonly && (
              <Grid item xs={1}>
                {index !== editItem && (
                  <Tooltip title="редактировать" placement="top-start">
                    <IconButton className={classes.btn} color="primary" onClick={() => setEditItem(index)}>
                      <EditIcon className={classes.icon} />
                    </IconButton>
                  </Tooltip>
                )}
                {index === editItem && (
                  <Tooltip title="редактировать" placement="top-start">
                    <>
                      <IconButton className={classes.btn} color="primary" onClick={() => updateTeam(item?.id)}>
                        <SaveIcon className={classes.icon} />
                      </IconButton>
                      <IconButton className={classes.btn} color="primary" onClick={() => setEditItem(item?.id)}>
                        <CloseIcon className={classes.icon} />
                      </IconButton>
                    </>
                  </Tooltip>
                )}
                <Tooltip title="удалить" placement="top-start">
                  <IconButton className={classes.btn} color="secondary" onClick={() => setDeleteItem(item?.id)}>
                    <DeleteIcon className={classes.icon} />
                  </IconButton>
                </Tooltip>
                <ConfirmDialog
                  title={`Удаление уведомления вызова спортсменов`}
                  text={`Вы уверены что хотите удалить документ?`}
                  btnVariant="outlined"
                  open={deleteItem === item?.id}
                  saveBtnText="удалить"
                  cancelBtnText="отмена"
                  saveBtnColor="secondary"
                  cancelBtnColor="primary"
                  onCancel={() => setDeleteItem(null)}
                  onSave={() => {
                    deleteCallNotify({ variables: { id: eventId, idCallNotify: item?.id } }).then(() => {
                      setDeleteItem(null);
                      refetch();
                    });
                  }}
                />
              </Grid>
            )}
          </Grid>
        ))}
      </Grid>

      <Dialog
        open={isAddingNEwDocumentFromSystem}
        onClose={() => {
          setIsAddingNewDocumentFromSystem(false);
          reset(defaultNewDocumentState);
        }}
        maxWidth="xs"
      >
        <DialogTitle id="add-document-dialog">Новый Документ</DialogTitle>
        <div style={{ padding: '1.5rem' }}>
          <form onSubmit={handleSubmit(handleAddNewDocumentFromSystem)} style={{ width: '100%' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Input
                  label="Название документа"
                  control={control}
                  error={!!errors['title']}
                  name="title"
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <DatePicker
                  fullWidth
                  label="Дата документа"
                  control={control}
                  error={!!errors['date']}
                  name="date"
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <Input label="Номер документа" control={control} error={!!errors['number']} name="number" />
              </Grid>
              <Grid item xs={12}>
                <DropzoneArea
                  showFileNames
                  alertSnackbarProps={{
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                  }}
                  dropzoneClass={classes.dropZone}
                  showAlerts={['error']}
                  getFileAddedMessage={(fileName: string) => `Файл ${fileName} успешно загружено`}
                  getDropRejectMessage={(file: File) =>
                    `Файл ${file.name} отклонен. Тип файла не поддерживается. Файл слишком большой. Максимальный размер - 10 мегабайт.`
                  }
                  filesLimit={1}
                  maxFileSize={10485760}
                  clearOnUnmount
                  initialFiles={file}
                  onChange={(fileArray: File[]) => setFile(fileArray)}
                  dropzoneText="Файл"
                />
              </Grid>
              <Grid item container justify="flex-end">
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  startIcon={<SaveIcon />}
                  type="submit"
                  disabled={!(formState.isDirty && file.length > 0)}
                >
                  Добавить
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

const CallAthleteNotify: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  return <>{id && <DocumentComponent eventId={id} />}</>;

  // return (
  //   <>
  //     <Grid container>
  //       <Grid item lg={12} md={12} xs={12}>
  //         {data?.dirSportingEvent && id && (
  //           <Status
  //             id={id}
  //             statuses={data?.dirSportingEvent?.statuses}
  //             classes={classes}
  //             statusUpdated={async () => {
  //               await refetch();
  //               await onStatusUpdated();
  //             }}
  //             readOnly={readonly}
  //           />
  //         )}
  //         <Info
  //           readOnly={readonly}
  //           dirSportingEvent={data?.dirSportingEvent}
  //           classes={classes}
  //           infoUpdated={(value: boolean) => {
  //             if (value) {
  //               handleSnackBar('success', 'Информация успешно обновлена');
  //               refetch();
  //             }
  //           }}
  //           newCreated={(value: Scalars['UUID']) => {
  //             if (value) {
  //               handleSnackBar('success', 'Новое мероприятие успешно создано');
  //               push(`/sporting_event/${value}`);
  //             }
  //           }}
  //           onError={(error: any) => {
  //             handleSnackBar('error', getMessage(error.message));
  //           }}
  //         />
  //         {id && id !== 'new' && (
  //           <Grid container style={{ marginBottom: '1.5rem' }} spacing={2}>
  //             <Grid item xs={5}>
  //               <Regions
  //                 label="Регион РФ"
  //                 control={control}
  //                 error={!!errors['dirRegion']}
  //                 name="dirRegion"
  //                 onSuperChange={async (event: any, data: any) => {
  //                   if (data?.value) {
  //                     await updateRegion({ variables: { id, idRegion: data?.value } }).then((resp) => {
  //                       setValue('dirRegion', data);
  //                       handleSnackBar('success', 'Регион РФ успешно обновлен');
  //                     });
  //                   }
  //                 }}
  //               />
  //             </Grid>
  //             <Grid item xs={12}>
  //               <FederalDistricts
  //                 label="Федеральный округ"
  //                 control={control}
  //                 error={!!errors['dirFederalDistricts']}
  //                 name="dirFederalDistricts"
  //                 multiple
  //                 onSuperChange={async (event: any, data: any) => {
  //                   const { dirFederalDistricts = [] } = getValues();
  //                   const removeItem = dirFederalDistricts?.filter((x) => !data.includes(x));
  //                   const addItem = data?.filter((x: any) => !dirFederalDistricts?.includes(x));
  //                   if (removeItem && removeItem.length > 0) {
  //                     await removeDistinctRegion({ variables: { id, idFederalRegion: removeItem[0].value } }).then(
  //                       (resp) => {
  //                         setValue('dirFederalDistricts', data);
  //                         handleSnackBar('success', `Федеральный округ "${removeItem[0].label}" успешно удален`);
  //                       },
  //                     );
  //                   }
  //                   if (addItem && addItem.length > 0) {
  //                     await addDistinctRegion({ variables: { id, idFederalRegion: addItem[0].value } }).then((resp) => {
  //                       setValue('dirFederalDistricts', data);
  //                       handleSnackBar('success', `Федеральный округ "${addItem[0].label}" успешно добавлен`);
  //                     });
  //                   }
  //                 }}
  //               />
  //             </Grid>
  //           </Grid>
  //         )}
  //         {data?.dirSportingEvent && (
  //           <React.Fragment>
  //             <Sports
  //               readOnly={readonly}
  //               id={data?.dirSportingEvent?.id}
  //               classes={classes}
  //               SportUpdated={() => {
  //                 handleSnackBar('success', 'вид спорта и группы дисциплин успешно обновлена');
  //               }}
  //               onError={(error: any) => {
  //                 handleSnackBar('error', getMessage(error.message));
  //               }}
  //             />
  //             <Structure
  //               readOnly={readonly}
  //               classes={classes}
  //               dirSportingEvent={data?.dirSportingEvent}
  //               onError={(error: any) => {
  //                 handleSnackBar('error', getMessage(error.message));
  //               }}
  //               commentUpdated={() => {
  //                 handleSnackBar('success', 'комментарии успешно обновлена');
  //                 refetch();
  //               }}
  //               parentAdded={() => {
  //                 handleSnackBar('success', 'Основное мероприятие успешно обновлено');
  //                 refetch();
  //               }}
  //               parentRemoved={() => {
  //                 handleSnackBar('success', 'Основное мероприятие успешно удалено');
  //                 refetch();
  //               }}
  //               childAdded={() => {
  //                 handleSnackBar('success', 'Зависимое мероприятие успешно добавлено');
  //                 refetch();
  //               }}
  //               childRemoved={() => {
  //                 handleSnackBar('success', 'Зависимое мероприятие успешно удалено');
  //                 refetch();
  //               }}
  //             />
  //           </React.Fragment>
  //         )}
  //       </Grid>
  //       {id && id !== 'new' && !readonly && (
  //         <Grid item lg={6} md={12} xs={12}>
  //           <ArchiveWidget
  //             readonly={readonly}
  //             archive={data?.dirSportingEvent?.archive || null}
  //             id={id}
  //             refetch={refetch}
  //             onArchive={archive}
  //             onUnarchive={unarchive}
  //           />
  //         </Grid>
  //       )}
  //     </Grid>
  //   </>
  // );
};

export default CallAthleteNotify;
