import React, { FC, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  TextField,
} from '@material-ui/core';
import { useForm, Controller } from 'react-hook-form';
import { useResetPasswordMutation } from '../../../api';
import { useSnackbar } from 'notistack';
import getMessage from '../../../messages';

const ResetPassword: FC = () => {
  const { control, errors, handleSubmit } = useForm({
    defaultValues: {
      resetEmail: '',
    },
  });
  const [open, setOpen] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();
  const [reset] = useResetPasswordMutation();
  const handleClose = () => setOpen(false);
  const onSubmit = handleSubmit(async (values) => {
    try {
      await reset({ variables: values });
      enqueueSnackbar('Новый пароль отправлен на ваш email');
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  });
  return (
    <form onSubmit={onSubmit}>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Восстановление пароля</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Если вы забыли ваш пароль, вы можете его сбросить. Для этого вам необходимо ввести e-mail, на который
            зарегистрирован ваш аккаунт. Инструкции по сбросу пароля будут отправлены вам на e-mail.
          </DialogContentText>
          <Controller
            as={
              <TextField
                label="Адрес электронной почтый"
                fullWidth
                error={!!errors.resetEmail}
                helperText={errors.resetEmail && errors.resetEmail.message}
              />
            }
            control={control}
            name="resetEmail"
            rules={{ required: true }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Закрыть</Button>
          <Button type="submit" variant="contained" color="primary" onClick={onSubmit}>
            Отправить
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
};

export default ResetPassword;
