import React, { FC, Fragment, useEffect, useState } from 'react';
import { Typography, Accordion, AccordionSummary, AccordionDetails, Button, Link } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import { useAnalyticsTotalCountUsersLazyQuery } from '../../../../api';
import SaveIcon from '@material-ui/icons/Save';
import { Save } from '@material-ui/icons';
const useStyles = makeStyles({
  row: {
    display: 'flex',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  tinyIcon: {
    fontSize: 18,
  },
  icon: {
    fontSize: 20,
  },
  tableHead: {
    fontWeight: 'bold',
  },
  header: {
    fontWeight: 600,
    fontSize: 15,
    letterSpacing: 1.2,
  },
});

const columns = [
  {
    name: 'name',
    label: 'Название мероприятия',
    options: {
      filter: false,
      display: true,
      sort: false,
    },
  },
  {
    name: 'sports',
    label: 'Виды спорта',
    options: {
      filter: false,
      display: true,
      sort: false,
      customBodyRender: (data = []) => {
        return <>{data.join(', ')}</>;
      },
    },
  },
  {
    name: 'disciplineGroups',
    label: 'Группы дисциплин',
    options: {
      filter: false,
      display: true,
      sort: false,
      customBodyRender: (data = []) => {
        return <>{data.join(', ')}</>;
      },
    },
  },
  {
    name: 'subjectsCount',
    label: 'Количество субъектов',
    options: {
      filter: false,
      display: true,
      sort: false,
    },
  },
  {
    name: 'athletesCountByMan',
    label: 'Количество спортсменов - мужчины',
    options: {
      filter: false,
      display: true,
      sort: false,
    },
  },
  {
    name: 'athletesCountByWoman',
    label: 'Количество спортсменов - женщины',
    options: {
      filter: false,
      display: true,
      sort: false,
    },
  },
  {
    name: 'athletesCount',
    label: 'Количество спортсменов - всего',
    options: {
      filter: false,
      display: true,
      sort: false,
    },
  },
];

const options = {
  filterType: 'checkbox',
};

const TotalCountUsers: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const classes = useStyles();

  const [dataTable, setDataTable] = useState();
  // @ts-ignore
  const [fetch, { data, loading, refetch }]: any = useAnalyticsTotalCountUsersLazyQuery({
    fetchPolicy: 'no-cache',
    variables: { id },
  });
  const DOWNLOAD_LINK = process.env.REACT_APP_BACKEND_URI?.replace('/graphql', '');
  useEffect(() => {
    if (id) {
      fetch();
    }
  }, [fetch, id]);
  return (
    <Accordion key="Общее количество участников" style={{ width: '100%', marginBottom: 15 }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`Общее количество участников-content`}
        id={`Общее количество участников-header`}
      >
        <Grid container spacing={2} justify="space-between" alignItems="center">
          <Grid item>
            <Typography className={classes.header} color="primary">
              Общее количество участников
            </Typography>
          </Grid>
          <Grid item onClick={(e) => e.stopPropagation()}></Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: 'column' }}>
        <Link href={`${DOWNLOAD_LINK}/reports/sporting-event-participants-by-event/${id}`}>
          <Button size="small" variant="outlined" color="primary">
            <SaveIcon />
          </Button>
        </Link>
        <MUIDataTable
          title={''}
          data={[data?.aggregateParticipantsNumberByEvent] || []}
          columns={columns}
          options={{
            filter: false,
            search: false,
            pagination: false,
            selectableRowsHideCheckboxes: true,
            selectableRows: 'none',
            selectableRowsHeader: false,
            print: false,
            download: data?.length > 0,
            viewColumns: false,
            textLabels: {
              body: {
                noMatch: 'Извините, данные отсутствуют',
              },
            },
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default TotalCountUsers;
