import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  LinearProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { FC, useState } from 'react';

import { useCalendarReportEventsLazyQuery, useCalendarsSportsQuery } from '../../../../api';
import EventTable from '../EventTable/EventTable';
import BtnReport from '../../../BtnReport/BtnReport';
import userRights from '../../../../config/userRights';

const useStyles = makeStyles({
  head: {
    fontWeight: 600,
    fontSize: 15,
    letterSpacing: 1.2,
  },
});

const SportAccordion: FC<{
  id?: string;
  regionId?: string;
  readonly?: boolean;
  rNumber?: number;
  stageRegistryNumber: number;
  hideBtnReport?: boolean;
  extendedReadonly?: boolean;
  firstStep?: boolean;
}> = ({ id, regionId, rNumber, stageRegistryNumber, readonly, hideBtnReport, extendedReadonly, firstStep }) => {
  const classes = useStyles();
  const [active, setActive] = useState('');

  const { data, loading } = useCalendarsSportsQuery({
    variables: {
      id,
    },
    fetchPolicy: 'no-cache',
  });
  const { hasAdditionalFilter } = userRights();
  const sportFilter = hasAdditionalFilter?.sportsFilter || [];

  if (loading) return <LinearProgress />;

  return (
    <div style={{ width: '100%' }}>
      {!hideBtnReport && (
        <Grid container justify="flex-end" style={{ marginBottom: 10 }}>
          <BtnReport
            calendarName={data?.dirCalendar?.fullName}
            lazyQuery={useCalendarReportEventsLazyQuery}
            lastTableColumnLetter={'F'}
            label="Выгрузить отчет (Приложение 2)"
            serviceApi="calendarReportUniversiad"
            filter={{
              api: {
                dirCalendar: { id },
                clsEventStages_some: { registryNumber: 100002914 },
                archive: null,
              },
            }}
            fileName="Приложение № 2_Отчет о проведении I этапа Соревнований календаря.xlsx"
          />
        </Grid>
      )}

      {data?.dirCalendar?.dirSports
        ?.filter((sport) => sportFilter.length === 0 || (sportFilter.length > 0 && sportFilter.includes(sport.id)))
        .map((sport) => (
          <Accordion
            key={sport.id}
            expanded={active === sport.fullName}
            onChange={() => setActive(active === sport.fullName ? '' : sport.fullName)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${sport.id}-content`}
              id={`${sport.id}-header`}
            >
              <Typography className={classes.head} color="primary">
                {sport.fullName}
              </Typography>
            </AccordionSummary>
            <AccordionDetails style={{ display: 'block' }}>
              {active === sport.fullName && (
                <EventTable
                  id={id}
                  sportId={sport.id}
                  regionId={regionId}
                  rNumber={rNumber}
                  idCalendar={id}
                  showSearchRoiv={active === sport.fullName && firstStep}
                  stageRegistryNumber={stageRegistryNumber}
                  readonly={readonly}
                  extendedReadonly={extendedReadonly}
                />
              )}
            </AccordionDetails>
          </Accordion>
        ))}
    </div>
  );
};

export default SportAccordion;
