import { usePersonByIdQuery } from '../../../api';
import React, { FC } from 'react';
import { Grid, List, ListItem, ListItemIcon, ListItemText, ListSubheader, Link } from '@material-ui/core';
import moment from 'moment';
import PersonIcon from '@material-ui/icons/Person';
import LinkIcon from '@material-ui/icons/Link';

const Duplicates: FC<{ readonly?: boolean; id: string }> = ({ readonly, id }) => {
  const { data } = usePersonByIdQuery({ variables: { id }, fetchPolicy: 'no-cache' });

  return (
    <Grid container>
      {Boolean(data?.dirPerson?.primary) && (
        <Grid item md={6}>
          <List
            subheader={
              <ListSubheader component="div" id="duplicates">
                Персона является дублем
              </ListSubheader>
            }
          >
            <Link
              style={{ textDecoration: 'unset' }}
              href={`/person/${data?.dirPerson?.primary?.id}`}
              key={data?.dirPerson?.primary?.registryNumber}
            >
              <ListItem button>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText
                  primary={`${data?.dirPerson?.primary?.lastname} ${data?.dirPerson?.primary?.firstname} ${
                    data?.dirPerson?.primary?.patronymic || ''
                  }`}
                  secondary={`${data?.dirPerson?.primary?.registryNumber}  ${
                    data?.dirPerson?.primary?.mergedAt
                      ? `Дата объединения: ${moment(data?.dirPerson?.primary?.mergedAt).format('DD.MM.YYYY')}`
                      : ''
                  }`}
                  style={{ marginRight: '32px', flex: '0 1 auto' }}
                />
                <LinkIcon />
              </ListItem>
            </Link>
          </List>
        </Grid>
      )}
      {Boolean(data?.dirPerson?.duplicates?.length) && (
        <Grid item md={6}>
          <List
            subheader={
              <ListSubheader component="div" id="duplicates">
                Персоны дубликаты
              </ListSubheader>
            }
          >
            {data?.dirPerson?.duplicates?.map((item) => (
              <Link style={{ textDecoration: 'unset' }} href={`/person/${item.id}`} key={item?.registryNumber}>
                <ListItem button>
                  <ListItemIcon>
                    <PersonIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${item.lastname} ${item.firstname} ${item.patronymic ? item.patronymic : ''}`}
                    secondary={`${item.registryNumber}  ${
                      item.mergedAt ? `Дата объединения: ${moment(item.mergedAt).format('DD.MM.YYYY')}` : ''
                    }`}
                    style={{ marginRight: '32px', flex: '0 1 auto' }}
                  />
                  <LinkIcon />
                </ListItem>
              </Link>
            ))}
          </List>
        </Grid>
      )}
    </Grid>
  );
};

export default Duplicates;
