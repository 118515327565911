import React, { FC } from 'react';
import { Select } from '../components/Inputs';
import { ControllerProps } from 'react-hook-form/dist/types/props';

interface StatusConfirm extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  error: boolean | undefined;
  disabled?: boolean;
}

export const OPTIONS = [
  {
    label: 'На рассмотрении',
    value: 1,
  },
  {
    label: 'Подтвержден',
    value: 2,
  },
  {
    label: 'Отменен',
    value: 3,
  },
];

const StatusConfirm: FC<StatusConfirm> = ({ label, error, control, name, rules, disabled }) => {
  return (
    <Select
      label={label}
      data={OPTIONS}
      control={control}
      error={error}
      name={name}
      rules={rules}
      disabled={disabled}
    />
  );
};

export default StatusConfirm;
