import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import { Countries } from '../../module';
import SportingEventsStatus from '../../module/SportingEventsStatus';
import SportingEventsRequestStatus from '../../module/SportingEventsRequestStatus';
import { FieldErrors } from 'react-hook-form';
import { ControllerProps } from 'react-hook-form/dist/types/props';
import { DatePickerRange, Input, Select, Checkbox, MaskedInput } from '../Inputs';
import Organizations from '../../module/Organizations';
import Regions from '../../module/Regions';
import ForeignCities from '../../module/ForeignCities';

interface MinsportsAdditionalProps extends Pick<ControllerProps<'input'>, 'control'> {
  errors: FieldErrors;
  watch: any;
}

const MinsportsAdditional: FC<MinsportsAdditionalProps> = ({ watch, errors, control }) => {
  const watchIsRussia: { label: string; value: string } | undefined = watch('isRussia');
  const watchCountry: { label: string; value: string } | undefined = watch('dirCountry');

  return (
    <>
      <Grid item xs={3}>
        <Select
          label="Россия/Зарубеж"
          data={[
            { value: true, label: 'Россия' },
            { value: false, label: 'Зарубеж' },
          ]}
          control={control}
          error={!!errors['activityName']}
          name="isRussia"
        />
      </Grid>
      {watchIsRussia?.value && (
        <Grid item xs={4}>
          <Regions label="Регион РФ" control={control} error={!!errors['dirRegion']} name="dirRegion" />
        </Grid>
      )}
      {watchIsRussia && !watchIsRussia?.value && (
        <>
          <Grid item xs={4}>
            <Countries label="Название страны" control={control} error={!!errors['dirCountry']} name="dirCountry" />
          </Grid>
          {watchCountry?.value && (
            <Grid item xs={4}>
              <ForeignCities
                label="Название города"
                control={control}
                filter={{ dirCountry: { id: watchCountry?.value } }}
                error={!!errors['dirForeignCity']}
                name="dirForeignCity"
              />
            </Grid>
          )}
        </>
      )}
      <Grid item xs={3}>
        <Input label="Место проведения" control={control} error={!!errors['venue']} name="venue" />
      </Grid>
      <Grid item xs={3}>
        <SportingEventsStatus label="Статус мероприятия" error={!!errors['status']} control={control} name="status" />
      </Grid>
      <Grid item xs={3}>
        <SportingEventsRequestStatus
          label="Статус заявки"
          control={control}
          error={!!errors['activityName']}
          name="request"
        />
      </Grid>
      <Grid item xs={3}>
        <Organizations
          label="Организатор"
          error={!!errors['dirOrganization']}
          control={control}
          name="dirOrganization"
        />
      </Grid>
      <Grid item xs={3}>
        <Checkbox
          type="checkbox"
          label="Особо значимое"
          control={control}
          error={!!errors['isImportant']}
          name="isImportant"
        />
      </Grid>
      <Grid item xs={3}>
        <Checkbox
          type="checkbox"
          label="Подготовка к ОИ"
          control={control}
          error={!!errors['isOlympicGamesPreparation']}
          name="isOlympicGamesPreparation"
        />
      </Grid>
      <Grid item xs={3}>
        <MaskedInput
          label="Старый реестровый номер"
          control={control}
          error={!!errors['oldRegistryNumber']}
          name="oldRegistryNumber"
          isNotTextMask
          mask="onlyNumber"
        />
      </Grid>
      <Grid item xs={6}>
        <DatePickerRange
          start={{ label: 'Дата проведения с', control, error: !!errors['activityName'], name: 'startDate' }}
          end={{ label: 'по', control, error: !!errors['activityName'], name: 'endDate' }}
        />
      </Grid>
      <Grid item xs={6}>
        <DatePickerRange
          start={{
            label: 'Дата изменения с',
            control,
            error: !!errors['activityName'],
            name: 'startChangeDate',
          }}
          end={{ label: 'по', control, error: !!errors['activityName'], name: 'endChangeDate' }}
        />
      </Grid>
      <Grid item xs={3}>
        <Select
          label="В архиве"
          data={[
            { value: '', label: ' ' },
            { value: false, label: 'Нет' },
            { value: true, label: 'Да' },
          ]}
          control={control}
          error={!!errors['archive']}
          name="archive"
          defaultValue={{ value: false, label: 'Нет' }}
        />
      </Grid>
      <Grid item xs={2}>
        <Select
          label="Состав"
          data={[
            { value: 'youth', label: 'молодежный' },
            { value: 'elders', label: 'основной' },
          ]}
          control={control}
          error={!!errors['structure']}
          name="structure"
        />
      </Grid>
    </>
  );
};

export default MinsportsAdditional;
