import React, { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { getSettingsUserFilter, getSettingsUserFilterFormValue } from '../../store/selectors/settings';
import { useDispatch, useSelector } from 'react-redux';

import { AppStore } from '../../store/reducers';
import Board from '../../components/widgets/UnificationTasks/Board';
import { DictionaryWrapper } from '../../components/DictionaryWrapper';
import { MainTemplate } from '../../components/layouts';
import { SET_USER_SETTINGS_FILTER } from '../../store/constants';
import { UnificationTasks } from '../../components/Filters';
import _ from 'lodash';
import { usePermission } from '../../hooks/usePermission';

const Page: FC<{}> = () => {
  const dispatch = useDispatch();
  const filter: any = useSelector((state: AppStore) => getSettingsUserFilter(state, 'unification_tasks'), _.isEqual);

  const access = usePermission('directory', 'unificationtasks');

  const formValue: any = useSelector(
    (state: AppStore) => getSettingsUserFilterFormValue(state, 'unification_tasks'),
    _.isEqual,
  );

  const setFilter = useCallback(
    (filter, form) => {
      dispatch({
        type: SET_USER_SETTINGS_FILTER,
        payload: {
          name: 'unification_tasks',
          filter: filter,
          form,
        },
      });
    },
    [dispatch],
  );

  return (
    <MainTemplate pageName="Спортсмены" url="" title="Задания на объединения">
      <UnificationTasks
        onSubmit={(filters: any, form: any) => setFilter({ ...filters }, form)}
        value={formValue}
        defaultFilter={filter}
      />
      <DictionaryWrapper>
        <Board filter={filter} readonly={!access.includes(2)} />
      </DictionaryWrapper>
    </MainTemplate>
  );
};

export default Page;
