import {
  Button,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { Checkbox, Input } from '../../Inputs';
import {
  DirPersonPhone,
  Scalars,
  UpdateSportingEventMutationHookResult,
  useFindRefereeByEventClsPersonLazyQuery,
  useSportingEventRefereesListLazyQuery,
  useSportingEventRefereesQuery,
  useUpdateSportingEventMutation,
} from '../../../api';
import React, { FC, useEffect, useState } from 'react';

import AddBoxIcon from '@material-ui/icons/AddBox';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import ConfirmDialog from '../../ConfirmDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import Persons from '../../../module/Persons';
import RefereeForm from './components/refereeForm';
import RefereeStatus from './components/RefereeStatus';
import RefereesPositions from '../../../module/RefereesPositions';
import SaveIcon from '@material-ui/icons/Save';
import TableBody from '@material-ui/core/TableBody';
import _ from 'lodash';
import getMessage from '../../../messages';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { sortReferees } from '../../../utils/sortRefereesInSportingEvents';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import userRights from '../../../config/userRights';
import RefereesDocuments from './RefereesDocuments';
import BtnReport from '../../BtnReport/BtnReport';

const STATIC_FIELDS = [
  {
    size: 6,
    value: 'countryCode',
    text: 'Код страны',
  },
  {
    size: 6,
    value: 'operatorCode',
    text: 'Код оператора/ региона',
    render: (value: string) => (value ? ` (${value}) ` : ''),
  },
  {
    size: 6,
    value: 'number',
    text: 'Номер',
  },
  {
    size: 6,
    value: 'additionalNumber',
    render: (value: string) => (value ? `(доб. ${value})` : ''),
    text: 'Добавочный',
  },
];

function parsingPhone(item: any) {
  return (
    <div>
      {STATIC_FIELDS.map((field) => {
        return field.render ? field.render(item[field.value]) : item[field.value];
      })}
    </div>
  );
}

const useStyles = makeStyles({
  title: {
    fontWeight: 500,
  },
  verticalSpaceMarge: {
    marginBottom: 35,
  },
  row: {
    display: 'flex',
  },
  link: {
    cursor: 'pointer',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  icon: {
    fontSize: 20,
  },
});

interface formState {
  referee?: {
    label: string;
    value: Scalars['UUID'];
    personRoleId: Scalars['UUID'];
  };
  isNonresident?: boolean;
  position?: { label: string; value: Scalars['UUID']; __typename?: string };
}

const Referees: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const { hasSportEventLimAccess } = userRights();
  const defaultRefereeState: formState = {
    referee: undefined,
    position: undefined,
    isNonresident: false,
  };

  const DOWNLOAD_LINK = process.env.REACT_APP_BACKEND_URI?.replace('/graphql', '');

  const [isUpdatingReferee, setIsUpdatingReferee] = useState('');
  const [isAddingNewReferee, setIsAddingNewReferee] = useState(false);
  const [refereeId, setRefereeId] = useState<Scalars['UUID']>('');
  const [positionId, setPositionId] = useState<Scalars['UUID']>('');

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });
  const classes = useStyles();

  const { data, loading, refetch } = useSportingEventRefereesQuery({ variables: { id } });

  const [updateEvent]: UpdateSportingEventMutationHookResult = useUpdateSportingEventMutation();

  const { handleSubmit, getValues, control, errors, reset, formState } = useForm({
    defaultValues: defaultRefereeState,
  });
  const [loadingAnother, setLoadingAnother] = useState(false);

  const [deleteItem, setDeleteItem] = useState<any>(null);
  const [requestAddReferee, setRequestAddReferee] = useState();
  const {
    handleSubmit: primaryRefereeFormHandleSubmit,
    control: primaryRefereeFormControl,
    getValues: primaryRefereeFormControlGetValues,
    errors: primaryRefereeFormErrors,
    reset: primaryRefereeFormReset,
    formState: primaryRefereeFormState,
  } = useForm({
    defaultValues: defaultRefereeState,
  });

  const {
    handleSubmit: primarySecretaryRefereeFormHandleSubmit,
    control: primarySecretaryRefereeFormControl,
    getValues: primarySecretaryRefereeFormControlGetValues,
    errors: primarySecretaryRefereeFormErrors,
    reset: primarySecretaryRefereeFormReset,
    formState: primarySecretaryRefereeFormState,
  } = useForm({
    defaultValues: defaultRefereeState,
  });

  const {
    handleSubmit: kVotFormHandleSubmit,
    control: kVotFormControl,
    errors: kVotFormErrors,
    reset: kVotFormReset,
    formState: kVotFormState,
    getValues: kVotFormValues,
  } = useForm({
    defaultValues: {
      refereesQuota: data?.SportingEventReferees?.refereesQuota,
      nonresidentRefereesQuota: data?.SportingEventReferees?.nonresidentRefereesQuota,
    },
  });
  const [refereesQuota, nonresidentRefereesQuota, referees] = [
    data?.SportingEventReferees?.refereesQuota,
    data?.SportingEventReferees?.nonresidentRefereesQuota,
    data?.SportingEventReferees?.referees,
  ];

  const [fetchExistReferee] = useFindRefereeByEventClsPersonLazyQuery({
    onCompleted: async (data) => {
      if (
        data?.dirSportingEvent &&
        data?.dirSportingEvent?.referees?.length > 0 &&
        ['primaryReferee', 'primarySecretaryReferee'].includes(requestAddReferee?.role)
      ) {
        handleSnackBar('warning', 'Судья уже был добавлен, обновите страницу для отображения актуальных данных');
        refetch();
        setIsAddingNewReferee(false);
        setIsUpdatingReferee('');
        setPositionId('');
        setRequestAddReferee({});
        setLoadingAnother(false);
        return;
      }
      await updateEvent({
        variables: {
          data: {
            referees: {
              create: [
                {
                  dirReferee: {
                    connect: {
                      id: requestAddReferee?.idReferee,
                    },
                  },
                  clsRefereePosition: {
                    connect: {
                      id: requestAddReferee?.clsId,
                    },
                  },
                  isNonresident: requestAddReferee?.isNonresident || false,
                },
              ],
            },
          },
          id,
        },
      });

      handleSnackBar('success', 'Судья успешно добавлен');
      setIsAddingNewReferee(false);
      setIsUpdatingReferee('');
      setPositionId('');
      switch (requestAddReferee?.role) {
        case 'primaryReferee':
          primaryRefereeFormReset(defaultRefereeState);
          break;
        case 'primarySecretaryReferee':
          primarySecretaryRefereeFormReset(defaultRefereeState);
          break;

        default:
          break;
      }
      setRequestAddReferee({});

      reset(defaultRefereeState);
      refetch();
      setLoadingAnother(false);
    },
  });

  const sports: any = data?.SportingEventReferees?.sports;

  const {
    primaryReferee,
    primarySecretaryReferee,
    vicePrimaryReferees,
    vicePrimarySecretaryReferees,
    sportReferees,
    commissionerReferees,
    techReferees,
  } = sortReferees(referees, sports);
  const collectRefereesIds = referees?.map((i) => i?.dirReferee?.id);

  const getPersonValue = (person: any) =>
    person
      ? {
          label: [person.lastname || '', person.patronymic || '', person.firstname || ''].join(' '),
          value: person.id,
          personRoleId: person.roleId,
        }
      : undefined;

  const deleteReferee = async (refereeId: Scalars['UUID']) => {
    if (refereeId) {
      setLoadingAnother(true);
      try {
        await updateEvent({
          variables: {
            data: {
              referees: {
                delete: [
                  {
                    id: refereeId,
                  },
                ],
              },
            },
            id,
          },
        });
        handleSnackBar('success', 'Судья успешно удален');
        refetch();
        setLoadingAnother(false);
      } catch (error) {
        handleSnackBar('error', getMessage(error.message));
        setLoadingAnother(false);
      }
    }
  };

  const onSubmit = async (values: formState, role: string) => {
    const { referee, position, isNonresident } = values;

    setLoadingAnother(true);
    try {
      if (refereesQuota || nonresidentRefereesQuota) {
        if (isUpdatingReferee && refereeId) {
          await updateEvent({
            variables: {
              data: {
                referees: {
                  update: [
                    {
                      where: {
                        id: refereeId,
                      },
                      data: {
                        clsRefereePosition: {
                          connect: {
                            id: position?.value || positionId,
                          },
                        },
                        isNonresident: isNonresident || false,
                      },
                    },
                  ],
                },
              },
              id,
            },
          });
          handleSnackBar('success', 'Судья успешно обновлен');
          setIsUpdatingReferee('');

          switch (role) {
            case 'primaryReferee':
              primaryRefereeFormReset(defaultRefereeState);
              break;
            case 'primarySecretaryReferee':
              primarySecretaryRefereeFormReset(defaultRefereeState);
              break;

            default:
              break;
          }
          reset(defaultRefereeState);

          return;
        }
        setRequestAddReferee({
          idEvent: id,
          idReferee: referee?.personRoleId,
          clsId: position?.value || positionId,
          isNonresident,
          role,
        });
        await fetchExistReferee({
          variables: { idEvent: id, clsId: position?.value || positionId },
        });
      }
    } catch (error) {
      setLoadingAnother(false);
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const onUpdateKvots = async (values: any) => {
    const { refereesQuota, nonresidentRefereesQuota } = values;

    try {
      await updateEvent({
        variables: {
          data: {
            refereesQuota: parseInt(refereesQuota, 10) || 0,
            nonresidentRefereesQuota: parseInt(nonresidentRefereesQuota, 10) || 0,
          },
          id,
        },
      });
      handleSnackBar('success', 'квоты успешно обновлена');
      refetch();
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const availableAddEditNewReferee =
    (refereesQuota && _.filter(referees, (referee) => !referee?.isNonresident).length < refereesQuota) ||
    (nonresidentRefereesQuota &&
      _.filter(referees, (referee) => referee?.isNonresident).length < nonresidentRefereesQuota);

  const availableAddNonResident =
    nonresidentRefereesQuota &&
    _.filter(referees, (referee) => referee?.isNonresident).length < nonresidentRefereesQuota;

  useEffect(() => {
    kVotFormReset({
      refereesQuota,
      nonresidentRefereesQuota,
    });
  }, [kVotFormReset, nonresidentRefereesQuota, refereesQuota]);

  if (loading) return <LinearProgress />;

  return (
    <div style={{ marginTop: 15 }}>
      <Grid container>
        {/* <Link href={`${DOWNLOAD_LINK}/reports/sporting-event-referees/${id}`}>
          <Button size="small" variant="outlined" color="primary">
            Выгрузить список судей
          </Button>
        </Link> */}
        <BtnReport
          lazyQuery={useSportingEventRefereesListLazyQuery}
          serviceApi="sportingEventRefereeReport"
          label="Выгрузить список судей"
          fileName={`Список судей`}
          filter={{
            api: {
              id,
            },
          }}
        />
      </Grid>
      <form onSubmit={kVotFormHandleSubmit(onUpdateKvots)} style={{ width: '100%' }}>
        <Grid container spacing={2} className={classes.verticalSpaceMarge}>
          <Grid item container>
            <Grid item xs={4}></Grid>
            <Grid item xs={1} style={{ textAlign: 'center' }}>
              <span className={classes.title}>Факт</span>
            </Grid>
          </Grid>
          <Grid item container alignItems="center">
            <Grid item xs={4}>
              <Input
                label="Квота на судей"
                control={kVotFormControl}
                error={!!kVotFormErrors['refereesQuota']}
                name="refereesQuota"
                disabled={hasSportEventLimAccess || readonly}
                rules={{ required: true }}
              />
            </Grid>
            <Grid item xs={1} style={{ textAlign: 'center' }}>
              <span className={classes.title}>{referees?.length}</span>
            </Grid>
          </Grid>
          <Grid item container alignItems="center">
            <Grid item xs={4}>
              <Input
                label="Квота на иногородних судей"
                control={kVotFormControl}
                error={!!kVotFormErrors['nonresidentRefereesQuota']}
                name="nonresidentRefereesQuota"
                disabled={hasSportEventLimAccess || readonly}
                rules={{
                  required: true,
                  validate: (val) => {
                    return (
                      +val <=
                      +(
                        kVotFormValues('refereesQuota') ??
                        _.filter(referees, (referee) => !referee?.isNonresident).length
                      )
                    );
                  },
                }}
              />
            </Grid>
            <Grid item xs={1} style={{ textAlign: 'center' }}>
              <span className={classes.title}>{_.filter(referees, (referee) => referee?.isNonresident).length}</span>
            </Grid>
          </Grid>
          <Grid item container>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<SaveIcon />}
              type={loadingAnother ? 'button' : 'submit'}
              disabled={!kVotFormState.isDirty || loadingAnother}
            >
              Сохранить
            </Button>
          </Grid>
        </Grid>
      </form>
      <div>
        <RefereeStatus
          idEvent={id}
          statuses={data?.SportingEventReferees?.statuses}
          refereeStatuses={data?.SportingEventReferees?.refereeStatuses}
          classes={classes}
          statusUpdated={async () => {
            await refetch();
          }}
          readOnly={readonly}
        />
      </div>
      <TableContainer component={Paper}>
        <Table aria-label="referee table">
          <TableHead>
            <TableRow>
              <TableCell>ФИО</TableCell>
              <TableCell>Должность</TableCell>
              <TableCell>Иногородний</TableCell>
              <TableCell>Город проживания</TableCell>
              <TableCell>Тел.</TableCell>
              <TableCell>Email </TableCell>
              <TableCell>Судейская категория</TableCell>
              <TableCell>Действ.до&nbsp;</TableCell>
              <TableCell>Регион&nbsp;</TableCell>
              <TableCell>Документ</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row" key="0e0e7095-c750-5f97-9c0a-db2f5e855e3c">
                {isUpdatingReferee === '0e0e7095-c750-5f97-9c0a-db2f5e855e3c' ? (
                  <form
                    onSubmit={primaryRefereeFormHandleSubmit((values) => onSubmit(values, 'primaryReferee'))}
                    style={{ width: '100%' }}
                  >
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={12}>
                        <Persons
                          label=""
                          control={primaryRefereeFormControl}
                          error={!!primaryRefereeFormErrors['referee']}
                          thershold={0.3}
                          filter={{
                            refereeProfile: {
                              sports_some: {
                                dirSport: {
                                  id_in: sports.map((s: any) => s?.dirSport.value),
                                },
                              },
                              id_not_in: collectRefereesIds || [],
                            },
                          }}
                          profile="refereeProfile"
                          name="referee"
                          rules={{ required: true }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Checkbox
                          type="checkbox"
                          label="Иногородний"
                          disabled={
                            (primaryRefereeFormControlGetValues('isNonresident') === undefined ||
                              primaryRefereeFormControlGetValues('isNonresident') === false) &&
                            !availableAddNonResident
                          }
                          control={primaryRefereeFormControl}
                          error={!!primaryRefereeFormErrors['isNonresident']}
                          name="isNonresident"
                        />
                      </Grid>

                      <Grid item>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          startIcon={<SaveIcon />}
                          type={loadingAnother ? 'button' : 'submit'}
                          disabled={!primaryRefereeFormState.isDirty || loadingAnother}
                        >
                          Сохранить
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="outlined"
                          color="secondary"
                          size="small"
                          startIcon={<ClearIcon />}
                          type="submit"
                          onClick={() => setIsUpdatingReferee('')}
                        >
                          Отменить
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                ) : (
                  <Link href={`/referee/${primaryReferee?.dirReferee?.id}`} target={'_blank'}>
                    {primaryReferee?.dirReferee?.dirPerson.lastname || ''}
                    &nbsp;
                    {primaryReferee?.dirReferee?.dirPerson.firstname || ''}
                    &nbsp;
                    {primaryReferee?.dirReferee?.dirPerson.patronymic || ''}
                    &nbsp;
                  </Link>
                )}
              </TableCell>
              <TableCell>Гл. спортивный судья</TableCell>
              <TableCell>
                {primaryReferee?.isNonresident ? (
                  <CheckIcon style={{ color: 'green' }} />
                ) : (
                  <NotInterestedIcon color="secondary" />
                )}
              </TableCell>
              <TableCell>{primaryReferee?.dirReferee?.dirPerson.residenceCity}</TableCell>
              <TableCell>
                {primaryReferee?.dirReferee?.dirPerson.phones?.map((p: DirPersonPhone) => parsingPhone(p))}
              </TableCell>
              <TableCell>
                {primaryReferee?.dirReferee?.dirPerson.email || ''}
                &nbsp;
              </TableCell>
              <TableCell>{primaryReferee?.category?.clsRefereeCategory?.fullName}</TableCell>
              <TableCell>
                {(primaryReferee?.category?.isEndless && 'Бессрочно') ||
                  (primaryReferee?.category?.validUntil &&
                    moment(primaryReferee?.category?.validUntil).format('DD.MM.YY'))}
              </TableCell>
              <TableCell>
                {(primaryReferee?.dirReferee?.dirPerson?.isRussia &&
                  primaryReferee?.dirReferee?.dirPerson?.dirRegion?.fullName) ||
                  primaryReferee?.dirReferee?.dirPerson?.dirCountry?.fullName ||
                  primaryReferee?.dirReferee?.dirPerson?.dirForeignCity?.fullName ||
                  ''}
              </TableCell>
              <TableCell>
                {primaryReferee?.category?.dirDocuments?.map((document: any) => (
                  <Link href={`${DOWNLOAD_LINK}${document?.file.path}` || '#'}>{document?.title}</Link>
                ))}
              </TableCell>
              <TableCell>
                {!readonly && (
                  <div className={classes.row}>
                    {!(isUpdatingReferee === '0e0e7095-c750-5f97-9c0a-db2f5e855e3c') && (
                      <Tooltip title="изменить" placement="top-start">
                        <IconButton
                          className={classes.btn}
                          color={(!(refereesQuota || nonresidentRefereesQuota) && 'default') || 'primary'}
                          onClick={() => {
                            if (refereesQuota || nonresidentRefereesQuota) {
                              setIsUpdatingReferee('0e0e7095-c750-5f97-9c0a-db2f5e855e3c');
                              setRefereeId(primaryReferee?.id);
                              setPositionId(
                                primaryReferee?.clsRefereePosition?.value || '4e4e7ff9-e7ec-41c1-84f6-e3aea9246cb5',
                              );
                              primaryRefereeFormReset({
                                referee: getPersonValue(primaryReferee.dirReferee?.dirPerson),
                                isNonresident: primaryReferee?.isNonresident,
                              });
                            }
                          }}
                        >
                          <EditIcon className={classes.icon} />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="удалить" placement="top-start">
                      <IconButton
                        className={classes.btn}
                        onClick={() => setDeleteItem(primaryReferee?.id)}
                        color="secondary"
                      >
                        <DeleteIcon className={classes.icon} />
                      </IconButton>
                    </Tooltip>
                    <ConfirmDialog
                      title={`Удаление судьи`}
                      text={`Вы уверены что хотите удалить этого судью?`}
                      btnVariant="outlined"
                      open={deleteItem === primaryReferee?.id}
                      saveBtnText="да"
                      loading={loadingAnother}
                      cancelBtnText="отмена"
                      saveBtnColor="secondary"
                      cancelBtnColor="primary"
                      onCancel={() => setDeleteItem(null)}
                      onSave={() => {
                        deleteReferee(primaryReferee?.id).then(() => setDeleteItem(null));
                      }}
                    />
                  </div>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row" key="7f694a09-1315-5d80-8be0-b4662a054edc">
                {isUpdatingReferee === '7f694a09-1315-5d80-8be0-b4662a054edc' ? (
                  <form
                    onSubmit={primarySecretaryRefereeFormHandleSubmit((values) =>
                      onSubmit(values, 'primarySecretaryReferee'),
                    )}
                    style={{ width: '100%' }}
                  >
                    <Grid container spacing={1} alignItems="center">
                      <Grid item xs={12}>
                        <Persons
                          label=""
                          control={primarySecretaryRefereeFormControl}
                          error={!!primarySecretaryRefereeFormErrors['referee']}
                          thershold={0.3}
                          filter={{
                            refereeProfile: {
                              sports_some: {
                                dirSport: {
                                  id_in: sports.map((s: any) => s?.dirSport.value),
                                },
                              },
                              id_not_in: collectRefereesIds || [],
                            },
                          }}
                          profile="refereeProfile"
                          name="referee"
                          rules={{ required: true }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Checkbox
                          type="checkbox"
                          label="Иногородний"
                          disabled={
                            (primarySecretaryRefereeFormControlGetValues('isNonresident') === undefined ||
                              primarySecretaryRefereeFormControlGetValues('isNonresident') === false) &&
                            !availableAddNonResident
                          }
                          control={primarySecretaryRefereeFormControl}
                          error={!!primarySecretaryRefereeFormErrors['isNonresident']}
                          name="isNonresident"
                        />
                      </Grid>

                      <Grid item>
                        <Button
                          variant="outlined"
                          color="primary"
                          size="small"
                          startIcon={<SaveIcon />}
                          type={loadingAnother ? 'button' : 'submit'}
                          disabled={!primarySecretaryRefereeFormState.isDirty || loadingAnother}
                        >
                          Сохранить
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="outlined"
                          color="secondary"
                          size="small"
                          startIcon={<ClearIcon />}
                          type="submit"
                          onClick={() => setIsUpdatingReferee('')}
                        >
                          Отменить
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                ) : (
                  <Link href={`/referee/${primarySecretaryReferee?.dirReferee?.id}`} target={'_blank'}>
                    {primarySecretaryReferee?.dirReferee?.dirPerson.lastname || ''}
                    &nbsp;
                    {primarySecretaryReferee?.dirReferee?.dirPerson.firstname || ''}
                    &nbsp;
                    {primarySecretaryReferee?.dirReferee?.dirPerson.patronymic || ''}
                    &nbsp;
                  </Link>
                )}
              </TableCell>
              <TableCell>Гл. спортивный судья - секретарь </TableCell>
              <TableCell>
                {primarySecretaryReferee?.isNonresident ? (
                  <CheckIcon style={{ color: 'green' }} />
                ) : (
                  <NotInterestedIcon color="secondary" />
                )}
              </TableCell>
              <TableCell>{primarySecretaryReferee?.dirReferee?.dirPerson.residenceCity}</TableCell>
              <TableCell>
                {primarySecretaryReferee?.dirReferee?.dirPerson.phones.map((p: DirPersonPhone) => parsingPhone(p))}
              </TableCell>
              <TableCell>
                {primarySecretaryReferee?.dirReferee?.dirPerson.email || ''}
                &nbsp;
              </TableCell>
              <TableCell>{primarySecretaryReferee?.category?.clsRefereeCategory?.fullName}</TableCell>
              <TableCell>
                {(primarySecretaryReferee?.category?.isEndless && 'Бессрочно') ||
                  (primarySecretaryReferee?.category?.validUntil &&
                    moment(primarySecretaryReferee?.category?.validUntil).format('DD.MM.YY'))}
              </TableCell>
              <TableCell>
                {(primarySecretaryReferee?.dirReferee?.dirPerson?.isRussia &&
                  primarySecretaryReferee?.dirReferee?.dirPerson?.dirRegion?.fullName) ||
                  primarySecretaryReferee?.dirReferee?.dirPerson?.dirCountry?.fullName ||
                  primarySecretaryReferee?.dirReferee?.dirPerson?.dirForeignCity?.fullName ||
                  ''}
              </TableCell>
              <TableCell>
                {primarySecretaryReferee?.category?.dirDocuments?.map((document: any) => (
                  <Link href={`${DOWNLOAD_LINK}${document?.file.path}` || '#'}>{document?.title}</Link>
                ))}
              </TableCell>
              <TableCell>
                {!readonly && (
                  <div className={classes.row}>
                    {!(isUpdatingReferee === '7f694a09-1315-5d80-8be0-b4662a054edc') && (
                      <Tooltip title="изменить" placement="top-start">
                        <IconButton
                          className={classes.btn}
                          color={(!(refereesQuota || nonresidentRefereesQuota) && 'default') || 'primary'}
                          onClick={() => {
                            if (refereesQuota || nonresidentRefereesQuota) {
                              setIsUpdatingReferee('7f694a09-1315-5d80-8be0-b4662a054edc');
                              setRefereeId(primarySecretaryReferee?.id);
                              setPositionId(
                                primarySecretaryReferee?.clsRefereePosition?.value ||
                                  '7026b40a-365d-4422-a8f8-12c9da114a43',
                              );
                              primarySecretaryRefereeFormReset({
                                referee: getPersonValue(primarySecretaryReferee?.dirReferee?.dirPerson),
                                isNonresident: primarySecretaryReferee?.isNonresident,
                              });
                            }
                          }}
                        >
                          <EditIcon className={classes.icon} />
                        </IconButton>
                      </Tooltip>
                    )}
                    <Tooltip title="удалить" placement="top-start">
                      <IconButton
                        className={classes.btn}
                        onClick={() => setDeleteItem(primarySecretaryReferee?.id)}
                        color="secondary"
                      >
                        <DeleteIcon className={classes.icon} />
                      </IconButton>
                    </Tooltip>
                    <ConfirmDialog
                      title={`Удаление судьи`}
                      text={`Вы уверены что хотите удалить этого судью?`}
                      btnVariant="outlined"
                      open={deleteItem === primarySecretaryReferee?.id}
                      saveBtnText="да"
                      cancelBtnText="отмена"
                      saveBtnColor="secondary"
                      cancelBtnColor="primary"
                      onCancel={() => setDeleteItem(null)}
                      onSave={() => {
                        deleteReferee(primarySecretaryReferee?.id).then(() => setDeleteItem(null));
                      }}
                    />
                  </div>
                )}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                key="9b9bd8eb-a98d-5ef4-abb7-b3829e041dee"
                colSpan={1}
                style={{ borderBottom: 'none' }}
              >
                <span className={classes.title}>Судьи:&nbsp;</span>
                {!isAddingNewReferee && !readonly && (
                  <Tooltip title="добавить" placement="top-start">
                    <IconButton
                      className={classes.btn}
                      color={(!(refereesQuota || nonresidentRefereesQuota) && 'default') || 'primary'}
                      onClick={() =>
                        (refereesQuota || nonresidentRefereesQuota) &&
                        isUpdatingReferee === '' &&
                        availableAddEditNewReferee &&
                        setIsAddingNewReferee(true)
                      }
                      disabled={referees?.length === refereesQuota}
                    >
                      <AddBoxIcon className={classes.icon} />
                    </IconButton>
                  </Tooltip>
                )}
              </TableCell>
              {isAddingNewReferee && availableAddEditNewReferee && (
                <TableCell colSpan={8}>
                  <form onSubmit={handleSubmit((values) => onSubmit(values, ''))} style={{ width: '100%' }}>
                    <Grid container spacing={2} alignItems="flex-end">
                      <Grid item md={5} xs={12}>
                        <Persons
                          label=""
                          control={control}
                          error={!!errors['referee']}
                          thershold={0.3}
                          filter={{
                            refereeProfile: {
                              sports_some: {
                                dirSport: {
                                  id_in: sports?.map((s: any) => s?.dirSport.value),
                                },
                              },
                              id_not_in: collectRefereesIds || [],
                            },
                            archive: null,
                          }}
                          profile="refereeProfile"
                          name="referee"
                          rules={{ required: true }}
                        />
                      </Grid>
                      <Grid item md={4} xs={12}>
                        <RefereesPositions
                          label="Должность"
                          control={control}
                          error={!!errors['position']}
                          name="position"
                          rules={{ required: true }}
                          isNotArchive
                        />
                      </Grid>
                      <Grid item>
                        <Checkbox
                          type="checkbox"
                          label="Иногородний"
                          disabled={
                            (getValues('isNonresident') === undefined || getValues('isNonresident') === false) &&
                            !availableAddNonResident
                          }
                          color="primary"
                          control={control}
                          error={!!errors['isNonresident']}
                          name="isNonresident"
                        />
                      </Grid>
                      {!readonly && (
                        <Grid item>
                          <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            startIcon={<SaveIcon />}
                            type={loadingAnother ? 'button' : 'submit'}
                            disabled={!formState.isDirty || loadingAnother}
                          >
                            Сохранить
                          </Button>
                        </Grid>
                      )}
                      <Grid item>
                        <Button
                          variant="outlined"
                          color="secondary"
                          size="small"
                          startIcon={<ClearIcon />}
                          type="submit"
                          onClick={() => setIsAddingNewReferee(false)}
                        >
                          Отменить
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </TableCell>
              )}
            </TableRow>
            {techReferees?.map((referee) => (
              <TableRow key={referee.id}>
                <TableCell component="th" scope="row">
                  <Link href={`/referee/${referee?.dirReferee?.id}`} target={'_blank'}>
                    {referee?.dirReferee?.dirPerson.lastname || ''}
                    &nbsp;
                    {referee?.dirReferee?.dirPerson.firstname || ''}
                    &nbsp;
                    {referee?.dirReferee?.dirPerson.patronymic || ''}
                    &nbsp;
                  </Link>
                </TableCell>
                <TableCell>
                  <RefereeForm
                    referee={referee}
                    id={id}
                    isUpdatingReferee={isUpdatingReferee === referee?.id}
                    onUpdate={() => {
                      setIsUpdatingReferee('');
                      refetch();
                    }}
                    onCancel={() => setIsUpdatingReferee('')}
                  />
                </TableCell>
                <TableCell>
                  {referee?.isNonresident ? (
                    <CheckIcon style={{ color: 'green' }} />
                  ) : (
                    <NotInterestedIcon color="secondary" />
                  )}
                </TableCell>
                <TableCell>{referee?.dirReferee?.dirPerson.residenceCity}</TableCell>
                <TableCell>
                  {referee?.dirReferee?.dirPerson.phones.map((p: DirPersonPhone) => parsingPhone(p))}
                </TableCell>
                <TableCell>
                  {referee?.dirReferee?.dirPerson.email || ''}
                  &nbsp;
                </TableCell>
                <TableCell>{referee?.category?.clsRefereeCategory?.fullName}</TableCell>
                <TableCell>
                  {(referee?.category?.isEndless && 'Бессрочно') ||
                    (referee?.category?.validUntil && moment(referee?.category?.validUntil).format('DD.MM.YY'))}
                </TableCell>
                <TableCell>
                  {(referee?.dirReferee?.dirPerson?.isRussia && referee?.dirReferee?.dirPerson?.dirRegion?.fullName) ||
                    referee?.dirReferee?.dirPerson?.dirCountry?.fullName ||
                    referee?.dirReferee?.dirPerson?.dirForeignCity?.fullName ||
                    ''}
                </TableCell>
                <TableCell>
                  {referee?.category?.dirDocuments?.map((document: any) => (
                    <Link href={`${DOWNLOAD_LINK}${document?.file.path}` || '#'}>
                      {document?.title}
                      {` `}
                    </Link>
                  ))}
                </TableCell>
                <TableCell>
                  {!readonly && (
                    <div className={classes.row}>
                      {!(isUpdatingReferee === referee.id) && (
                        <Tooltip title="изменить" placement="top-start">
                          <IconButton
                            className={classes.btn}
                            onClick={() => {
                              if (refereesQuota || nonresidentRefereesQuota) {
                                setIsUpdatingReferee(referee.id);
                                setRefereeId(referee.id);
                                setPositionId(
                                  referee?.clsRefereePosition?.value || '4e4e7ff9-e7ec-41c1-84f6-e3aea9246cb7',
                                );
                              }
                            }}
                            color={(!(refereesQuota || nonresidentRefereesQuota) && 'default') || 'primary'}
                          >
                            <EditIcon className={classes.icon} />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title="удалить" placement="top-start">
                        <IconButton
                          className={classes.btn}
                          color="secondary"
                          onClick={() => setDeleteItem(referee?.id)}
                        >
                          <DeleteIcon className={classes.icon} />
                        </IconButton>
                      </Tooltip>
                      <ConfirmDialog
                        title={`Удаление судьи`}
                        text={`Вы уверены что хотите удалить этого судью?`}
                        btnVariant="outlined"
                        open={deleteItem === referee?.id}
                        saveBtnText="да"
                        loading={loadingAnother}
                        cancelBtnText="отмена"
                        saveBtnColor="secondary"
                        cancelBtnColor="primary"
                        onCancel={() => setDeleteItem(null)}
                        onSave={() => {
                          deleteReferee(referee?.id).then(() => setDeleteItem(null));
                        }}
                      />
                    </div>
                  )}
                </TableCell>
              </TableRow>
            ))}
            {commissionerReferees?.map((referee) => (
              <TableRow key={referee.id}>
                <TableCell component="th" scope="row">
                  <Link href={`/referee/${referee?.dirReferee?.id}`} target={'_blank'}>
                    {referee?.dirReferee?.dirPerson.lastname || ''}
                    &nbsp;
                    {referee?.dirReferee?.dirPerson.firstname || ''}
                    &nbsp;
                    {referee?.dirReferee?.dirPerson.patronymic || ''}
                    &nbsp;
                  </Link>
                </TableCell>
                <TableCell>
                  <RefereeForm
                    referee={referee}
                    id={id}
                    isUpdatingReferee={isUpdatingReferee === referee?.id}
                    onUpdate={() => {
                      setIsUpdatingReferee('');
                      refetch();
                    }}
                    onCancel={() => setIsUpdatingReferee('')}
                  />
                </TableCell>
                <TableCell>
                  {referee?.isNonresident ? (
                    <CheckIcon style={{ color: 'green' }} />
                  ) : (
                    <NotInterestedIcon color="secondary" />
                  )}
                </TableCell>
                <TableCell>{referee?.dirReferee?.dirPerson.residenceCity}</TableCell>
                <TableCell>
                  {referee?.dirReferee?.dirPerson.phones.map((p: DirPersonPhone) => parsingPhone(p))}
                </TableCell>
                <TableCell>
                  {referee?.dirReferee?.dirPerson.email || ''}
                  &nbsp;
                </TableCell>
                <TableCell>{referee?.category?.clsRefereeCategory?.fullName}</TableCell>
                <TableCell>
                  {(referee?.category?.isEndless && 'Бессрочно') ||
                    (referee?.category?.validUntil && moment(referee?.category?.validUntil).format('DD.MM.YY'))}
                </TableCell>
                <TableCell>
                  {(referee?.dirReferee?.dirPerson?.isRussia && referee?.dirReferee?.dirPerson?.dirRegion?.fullName) ||
                    referee?.dirReferee?.dirPerson?.dirCountry?.fullName ||
                    referee?.dirReferee?.dirPerson?.dirForeignCity?.fullName ||
                    ''}
                </TableCell>
                <TableCell>
                  {referee?.category?.dirDocuments?.map((document: any) => (
                    <Link href={`${DOWNLOAD_LINK}${document?.file.path}` || '#'}>
                      {document?.title}
                      {` `}
                    </Link>
                  ))}
                </TableCell>
                <TableCell>
                  {!readonly && (
                    <div className={classes.row}>
                      {!(isUpdatingReferee === referee.id) && (
                        <Tooltip title="изменить" placement="top-start">
                          <IconButton
                            className={classes.btn}
                            onClick={() => {
                              if (refereesQuota || nonresidentRefereesQuota) {
                                setIsUpdatingReferee(referee.id);
                                setRefereeId(referee.id);
                                setPositionId(
                                  referee?.clsRefereePosition?.value || '4e4e7ff9-e7ec-41c1-84f6-e3aea9246cb6',
                                );
                              }
                            }}
                            color={(!(refereesQuota || nonresidentRefereesQuota) && 'default') || 'primary'}
                          >
                            <EditIcon className={classes.icon} />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title="удалить" placement="top-start">
                        <IconButton
                          className={classes.btn}
                          color="secondary"
                          onClick={() => setDeleteItem(referee?.id)}
                        >
                          <DeleteIcon className={classes.icon} />
                        </IconButton>
                      </Tooltip>
                      <ConfirmDialog
                        title={`Удаление судьи`}
                        text={`Вы уверены что хотите удалить этого судью?`}
                        btnVariant="outlined"
                        open={deleteItem === referee?.id}
                        saveBtnText="да"
                        loading={loadingAnother}
                        cancelBtnText="отмена"
                        saveBtnColor="secondary"
                        cancelBtnColor="primary"
                        onCancel={() => setDeleteItem(null)}
                        onSave={() => {
                          deleteReferee(referee?.id).then(() => setDeleteItem(null));
                        }}
                      />
                    </div>
                  )}
                </TableCell>
              </TableRow>
            ))}
            {vicePrimaryReferees?.map((referee) => (
              <TableRow key={referee.id}>
                <TableCell component="th" scope="row">
                  <Link href={`/referee/${referee?.dirReferee?.id}`} target={'_blank'}>
                    {referee?.dirReferee?.dirPerson.lastname || ''}
                    &nbsp;
                    {referee?.dirReferee?.dirPerson.firstname || ''}
                    &nbsp;
                    {referee?.dirReferee?.dirPerson.patronymic || ''}
                    &nbsp;
                  </Link>
                </TableCell>
                <TableCell>
                  <RefereeForm
                    referee={referee}
                    id={id}
                    isUpdatingReferee={isUpdatingReferee === referee?.id}
                    onUpdate={() => {
                      setIsUpdatingReferee('');
                      refetch();
                    }}
                    onCancel={() => setIsUpdatingReferee('')}
                  />
                </TableCell>
                <TableCell>
                  {referee?.isNonresident ? (
                    <CheckIcon style={{ color: 'green' }} />
                  ) : (
                    <NotInterestedIcon color="secondary" />
                  )}
                </TableCell>
                <TableCell>{referee?.dirReferee?.dirPerson.residenceCity}</TableCell>
                <TableCell>
                  {referee?.dirReferee?.dirPerson.phones.map((p: DirPersonPhone) => parsingPhone(p))}
                </TableCell>
                <TableCell>
                  {referee?.dirReferee?.dirPerson.email || ''}
                  &nbsp;
                </TableCell>
                <TableCell>{referee?.category?.clsRefereeCategory?.fullName}</TableCell>
                <TableCell>
                  {(referee?.category?.isEndless && 'Бессрочно') ||
                    (referee?.category?.validUntil && moment(referee?.category?.validUntil).format('DD.MM.YY'))}
                </TableCell>
                <TableCell>
                  {(referee?.dirReferee?.dirPerson?.isRussia && referee?.dirReferee?.dirPerson?.dirRegion?.fullName) ||
                    referee?.dirReferee?.dirPerson?.dirCountry?.fullName ||
                    referee?.dirReferee?.dirPerson?.dirForeignCity?.fullName ||
                    ''}
                </TableCell>
                <TableCell>
                  {referee?.category?.dirDocuments?.map((document: any) => (
                    <Link href={`${DOWNLOAD_LINK}${document?.file.path}` || '#'}>
                      {document?.title}
                      {` `}
                    </Link>
                  ))}
                </TableCell>
                <TableCell>
                  {!readonly && (
                    <div className={classes.row}>
                      {!(isUpdatingReferee === referee.id) && (
                        <Tooltip title="изменить" placement="top-start">
                          <IconButton
                            className={classes.btn}
                            onClick={() => {
                              if (refereesQuota || nonresidentRefereesQuota) {
                                setIsUpdatingReferee(referee.id);
                                setRefereeId(referee.id);
                                setPositionId(
                                  referee?.clsRefereePosition?.value || 'fc81c851-24f8-44be-b0c3-7b626e28aba7',
                                );
                              }
                            }}
                            color={(!(refereesQuota || nonresidentRefereesQuota) && 'default') || 'primary'}
                          >
                            <EditIcon className={classes.icon} />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title="удалить" placement="top-start">
                        <IconButton
                          className={classes.btn}
                          color="secondary"
                          onClick={() => setDeleteItem(referee?.id)}
                        >
                          <DeleteIcon className={classes.icon} />
                        </IconButton>
                      </Tooltip>
                      <ConfirmDialog
                        title={`Удаление судьи`}
                        text={`Вы уверены что хотите удалить этого судью?`}
                        btnVariant="outlined"
                        open={deleteItem === referee?.id}
                        saveBtnText="да"
                        loading={loadingAnother}
                        cancelBtnText="отмена"
                        saveBtnColor="secondary"
                        cancelBtnColor="primary"
                        onCancel={() => setDeleteItem(null)}
                        onSave={() => {
                          deleteReferee(referee?.id).then(() => setDeleteItem(null));
                        }}
                      />
                    </div>
                  )}
                </TableCell>
              </TableRow>
            ))}
            {vicePrimarySecretaryReferees?.map((referee) => (
              <TableRow key={referee.id}>
                <TableCell component="th" scope="row">
                  <Link href={`/referee/${referee?.dirReferee?.id}`} target={'_blank'}>
                    {referee?.dirReferee.dirPerson.lastname || ''}
                    &nbsp;
                    {referee?.dirReferee.dirPerson.firstname || ''}
                    &nbsp;
                    {referee?.dirReferee.dirPerson.patronymic || ''}
                    &nbsp;
                  </Link>
                </TableCell>
                <TableCell>
                  <RefereeForm
                    referee={referee}
                    id={id}
                    isUpdatingReferee={isUpdatingReferee === referee?.id}
                    onUpdate={() => {
                      setIsUpdatingReferee('');
                      refetch();
                    }}
                    onCancel={() => setIsUpdatingReferee('')}
                  />
                </TableCell>
                <TableCell>
                  {referee?.isNonresident ? (
                    <CheckIcon style={{ color: 'green' }} />
                  ) : (
                    <NotInterestedIcon color="secondary" />
                  )}
                </TableCell>
                <TableCell>{referee?.dirReferee?.dirPerson.residenceCity}</TableCell>
                <TableCell>
                  {referee?.dirReferee.dirPerson.phones.map((p: DirPersonPhone) => parsingPhone(p))}
                </TableCell>
                <TableCell>
                  {referee?.dirReferee.dirPerson.email || ''}
                  &nbsp;
                </TableCell>
                <TableCell>{referee?.category?.clsRefereeCategory?.fullName}</TableCell>
                <TableCell>
                  {(referee?.category?.isEndless && 'Бессрочно') ||
                    (referee?.category?.validUntil && moment(referee?.category?.validUntil).format('DD.MM.YY'))}
                </TableCell>
                <TableCell>
                  {(referee?.dirReferee?.dirPerson?.isRussia && referee?.dirReferee?.dirPerson?.dirRegion?.fullName) ||
                    referee?.dirReferee?.dirPerson?.dirCountry?.fullName ||
                    referee?.dirReferee?.dirPerson?.dirForeignCity?.fullName ||
                    ''}
                </TableCell>
                <TableCell>
                  {referee?.category?.dirDocuments?.map((document: any) => (
                    <Link href={`${DOWNLOAD_LINK}${document?.file.path}` || '#'}>
                      {document?.title}
                      {` `}
                    </Link>
                  ))}
                </TableCell>
                <TableCell>
                  {!readonly && (
                    <div className={classes.row}>
                      {!(isUpdatingReferee === referee.id) && (
                        <Tooltip title="изменить" placement="top-start">
                          <IconButton
                            className={classes.btn}
                            color={(!(refereesQuota || nonresidentRefereesQuota) && 'default') || 'primary'}
                            onClick={() => {
                              if (refereesQuota || nonresidentRefereesQuota) {
                                setIsUpdatingReferee(referee.id);
                                setRefereeId(referee.id);
                                setPositionId(referee?.clsRefereePosition.value);
                              }
                            }}
                          >
                            <EditIcon className={classes.icon} />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title="удалить" placement="top-start">
                        <IconButton
                          className={classes.btn}
                          onClick={() => setDeleteItem(referee?.id)}
                          color="secondary"
                        >
                          <DeleteIcon className={classes.icon} />
                        </IconButton>
                      </Tooltip>
                      <ConfirmDialog
                        title={`Удаление судьи`}
                        text={`Вы уверены что хотите удалить этого судью?`}
                        btnVariant="outlined"
                        open={deleteItem === referee?.id}
                        saveBtnText="да"
                        loading={loadingAnother}
                        cancelBtnText="отмена"
                        saveBtnColor="secondary"
                        cancelBtnColor="primary"
                        onCancel={() => setDeleteItem(null)}
                        onSave={() => {
                          deleteReferee(referee?.id).then(() => setDeleteItem(null));
                        }}
                      />
                    </div>
                  )}
                </TableCell>
              </TableRow>
            ))}
            {sportReferees?.map((referee) => (
              <TableRow key={referee.id}>
                <TableCell component="th" scope="row">
                  <Link href={`/referee/${referee?.dirReferee?.id}`} target={'_blank'}>
                    {referee?.dirReferee?.dirPerson.lastname || ''}
                    &nbsp;
                    {referee?.dirReferee?.dirPerson.firstname || ''}
                    &nbsp;
                    {referee?.dirReferee?.dirPerson.patronymic || ''}
                    &nbsp;
                  </Link>
                </TableCell>

                <TableCell>
                  <RefereeForm
                    referee={referee}
                    id={id}
                    isUpdatingReferee={isUpdatingReferee === referee?.id}
                    onUpdate={() => {
                      setIsUpdatingReferee('');
                      refetch();
                    }}
                    onCancel={() => setIsUpdatingReferee('')}
                  />
                </TableCell>
                <TableCell>
                  {referee?.isNonresident ? (
                    <CheckIcon style={{ color: 'green' }} />
                  ) : (
                    <NotInterestedIcon color="secondary" />
                  )}
                </TableCell>
                <TableCell>{referee?.dirReferee?.dirPerson.residenceCity}</TableCell>
                <TableCell>
                  {referee?.dirReferee?.dirPerson.phones?.map((p: DirPersonPhone) => parsingPhone(p))}
                </TableCell>
                <TableCell>
                  {referee?.dirReferee?.dirPerson.email || ''}
                  &nbsp;
                </TableCell>
                <TableCell>{referee?.category?.clsRefereeCategory?.fullName}</TableCell>
                <TableCell>
                  {(referee?.category?.isEndless && 'Бессрочно') ||
                    (referee?.category?.validUntil && moment(referee?.category?.validUntil).format('DD.MM.YY'))}
                </TableCell>
                <TableCell>
                  {(referee?.dirReferee?.dirPerson?.isRussia && referee?.dirReferee?.dirPerson?.dirRegion?.fullName) ||
                    referee?.dirReferee?.dirPerson?.dirCountry?.fullName ||
                    referee?.dirReferee?.dirPerson?.dirForeignCity?.fullName ||
                    ''}
                </TableCell>
                <TableCell>
                  {referee?.category?.dirDocuments?.map((document: any) => (
                    <Link href={`${DOWNLOAD_LINK}${document?.file.path}` || '#'}>
                      {document?.title}
                      {` `}
                    </Link>
                  ))}
                </TableCell>
                <TableCell>
                  {!readonly && (
                    <div className={classes.row}>
                      {!(isUpdatingReferee === referee.id) && (
                        <Tooltip title="изменить" placement="top-start">
                          <IconButton
                            className={classes.btn}
                            color={(!(refereesQuota || nonresidentRefereesQuota) && 'default') || 'primary'}
                            onClick={() => {
                              if (refereesQuota || nonresidentRefereesQuota) {
                                setIsUpdatingReferee(referee.id);
                                setRefereeId(referee.id);
                                setPositionId(
                                  referee?.clsRefereePosition?.value || 'dd6483bb-7864-4251-b876-be2ffee0a856',
                                );
                              }
                            }}
                          >
                            <EditIcon className={classes.icon} />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title="удалить" placement="top-start">
                        <IconButton
                          className={classes.btn}
                          color="secondary"
                          onClick={() => setDeleteItem(referee?.id)}
                        >
                          <DeleteIcon className={classes.icon} />
                        </IconButton>
                      </Tooltip>
                      <ConfirmDialog
                        title={`Удаление судьи`}
                        text={`Вы уверены что хотите удалить этого судью?`}
                        btnVariant="outlined"
                        open={deleteItem === referee?.id}
                        saveBtnText="да"
                        loading={loadingAnother}
                        cancelBtnText="отмена"
                        saveBtnColor="secondary"
                        cancelBtnColor="primary"
                        onCancel={() => setDeleteItem(null)}
                        onSave={() => {
                          deleteReferee(referee?.id).then(() => setDeleteItem(null));
                        }}
                      />
                    </div>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {id && <RefereesDocuments id={id} />}
    </div>
  );
};
export default Referees;
