import React, { FC, Fragment, useState } from 'react';
import {
  IconButton,
  Tooltip,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import AddBoxIcon from '@material-ui/icons/AddBox';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import getMessage from '../../../../messages';
import OrgForm from './OrgForm';
import {
  Scalars,
  AddAthleteOrganizationMutationHookResult,
  useAddAthleteOrganizationMutation,
  UpdateAthleteOrganizationMutationHookResult,
  useUpdateAthleteOrganizationMutation,
  DeleteAthleteOrganizationMutationHookResult,
  useDeleteAthleteOrganizationMutation,
} from '../../../../api';

import moment from 'moment';
import _ from 'lodash';

const useStyles = makeStyles({
  row: {
    display: 'flex',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  tinyIcon: {
    fontSize: 18,
  },
  icon: {
    fontSize: 20,
  },
  tableHead: {
    fontWeight: 'bold',
  },
  header: {
    fontWeight: 600,
    fontSize: 15,
    letterSpacing: 1.2,
  },
});

interface formState {
  organization?: { label: string; value: any };
  trainingStage?: { label: any; value: any };
  trainer?: { label: string; value: Scalars['UUID'] };
  trainingStart?: Date;
  exclusionDate?: Date;
  isSelfTraining?: string;
  orgId?: Scalars['UUID'];
}

const OrgAccordion: FC<{ readonly?: boolean; sport: any; regionsId: Array<string>; onUpdate: Function }> = ({
  readonly,
  sport,
  regionsId,
  onUpdate,
}) => {
  const classes = useStyles();
  const [currentTrainer, setCurrentTrainer] = useState('');
  const [currentTrainingStage, setCurrentTrainingStage] = useState('');
  const [action, setAction] = useState('');
  const [orgId, setOrgId] = useState('');
  const [defaultFormValue, setDefaultFormValue] = useState<any>(null);

  const [add]: AddAthleteOrganizationMutationHookResult = useAddAthleteOrganizationMutation();
  const [update]: UpdateAthleteOrganizationMutationHookResult = useUpdateAthleteOrganizationMutation();
  const [deleteOrganization]: DeleteAthleteOrganizationMutationHookResult = useDeleteAthleteOrganizationMutation();

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const updateDefaultFormValue = (org: any) =>
    setDefaultFormValue({
      trainer: {
        value: org?.dirTrainer?.value,
        label: `${org?.dirTrainer?.dirPerson.lastname} ${org?.dirTrainer?.dirPerson.firstname} ${
          org?.dirTrainer?.dirPerson.patronymic ? org?.dirTrainer?.dirPerson.patronymic : ''
        } ${moment(org?.dirTrainer?.dirPerson.birthday).format('DD.MM.YYYY')}`,
      },
      isSelfTraining: (org.isSelfTraining === true && 'true') || (org.isSelfTraining === false && 'false') || '',
      trainingStage: {
        ...org?.clsTrainingStage,
      },
      organization: {
        ...org?.dirOrganization,
      },
      trainingStart: org?.trainingStart,
      exclusionDate: org?.exclusionDate,
    });

  const onAdd = async (values: formState, idSport: Scalars['UUID']) => {
    const { organization, trainingStage, trainingStart, trainer, isSelfTraining } = values;

    try {
      await add({
        variables: {
          idSport,
          idDirOrganization: organization?.value,
          isSelfTraining: isSelfTraining === 'true',
          idDirTrainer: trainer ? { connect: { id: trainer?.value } } : null,
          clsTrainingStage: trainingStage?.value,
          trainingStart,
        },
      });
      onUpdate();
      handleSnackBar('success', 'организация успешно добавлено');
      setAction('');
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const onEdit = async (values: formState, idSport: Scalars['UUID']) => {
    const { organization, trainingStage, trainingStart, trainer, isSelfTraining, exclusionDate } = values;

    try {
      await update({
        variables: {
          id: orgId,
          idDirOrganization: organization?.value,
          isSelfTraining: isSelfTraining === 'true',
          idDirTrainer:
            action === 'transfer' && currentTrainer
              ? { connect: { id: currentTrainer } }
              : action === 'edit' && trainer
              ? { connect: { id: trainer?.value } }
              : action === 'edit' && currentTrainer
              ? { disconnect: true }
              : null,
          clsTrainingStage: action === 'transfer' ? currentTrainingStage : trainingStage?.value,
          exclusionDate,
          trainingStart,
        },
      });
      action === 'transfer' &&
        (await add({
          variables: {
            idSport,
            idDirOrganization: organization?.value,
            isSelfTraining: isSelfTraining === 'true',
            idDirTrainer: trainer ? { connect: { id: trainer?.value } } : null,
            clsTrainingStage: trainingStage?.value,
            trainingStart: exclusionDate,
          },
        }));
      onUpdate();
      handleSnackBar('success', 'организация успешно обновлено');
      setOrgId('');
      setCurrentTrainer('');
      setCurrentTrainingStage('');
      setAction('');
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const onDelete = async (values: formState) => {
    const { exclusionDate } = values;
    try {
      await deleteOrganization({
        variables: {
          id: orgId,
          exclusionDate: exclusionDate,
        },
      });
      onUpdate();
      handleSnackBar('success', 'организация успешно обновлено');
      setAction('');
      setOrgId('');
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  return (
    <Accordion key={sport.id} style={{ width: '100%' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`org-content`} id={`org-header`}>
        <Typography className={classes.header} color="primary">
          Организации
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: 'column' }}>
        {action && (
          <OrgForm
            defaultValue={defaultFormValue}
            regionsId={regionsId}
            sport={sport}
            action={action}
            onAdd={(values: formState) => onAdd(values, sport.id)}
            onEdit={(values: formState) => onEdit(values, sport.id)}
            onDelete={(values: formState) => onDelete(values)}
            onCancel={() => {
              setAction('');
              setDefaultFormValue(null);
            }}
          />
        )}

        <TableContainer component={Paper}>
          <Table aria-label={`${sport.dirSport?.label} table`}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHead}>
                  {action !== 'add' && !readonly && (
                    <Tooltip title="добавить" placement="top-start">
                      <IconButton className={classes.btn} color="primary" onClick={() => setAction('add')}>
                        <AddBoxIcon className={classes.icon} />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell className={classes.tableHead}>Этап подготовки</TableCell>
                <TableCell className={classes.tableHead}>Тренер</TableCell>
                <TableCell className={classes.tableHead}>Дата начала</TableCell>
                <TableCell className={classes.tableHead}>Дата отчисления</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sport.organizations &&
                sport.organizations.length > 0 &&
                _.map(_.values(_.groupBy(sport.organizations, (o) => o.dirOrganization.label)), (v) =>
                  v.map((org, index) => (
                    <TableRow key={org.dirOrganization && org.dirOrganization.value + index}>
                      {index === 0 ? (
                        <TableCell component="th" scope="row">
                          {readonly ? (
                            <span>{org.dirOrganization.label}</span>
                          ) : (
                            <Link
                              style={{ fontWeight: 500, cursor: 'pointer' }}
                              target="_blank"
                              href={`/organization/${org.dirOrganization.value}`}
                            >
                              {org.dirOrganization.label}
                            </Link>
                          )}
                        </TableCell>
                      ) : (
                        <TableCell component="th" scope="row"></TableCell>
                      )}
                      <TableCell>{org.clsTrainingStage && org.clsTrainingStage.label}</TableCell>
                      <TableCell>
                        {(org.dirTrainer && (
                          <Link
                            style={{ fontWeight: 500, cursor: 'pointer' }}
                            target="_blank"
                            href={`/trainer/${org.dirTrainer.value}`}
                          >
                            {org.dirTrainer.dirPerson &&
                              `${org.dirTrainer.dirPerson.lastname} ${org.dirTrainer.dirPerson.firstname} ${
                                org.dirTrainer.dirPerson.patronymic || ''
                              } `}
                            {org.dirTrainer &&
                              org.dirTrainer.dirPerson &&
                              org.dirTrainer.dirPerson.birthday &&
                              moment(org.dirTrainer.dirPerson.birthday).format('DD.MM.YYYY')}
                          </Link>
                        )) ||
                          'Самоподготовка'}
                      </TableCell>
                      <TableCell>{org.trainingStart && moment(org.trainingStart).format('DD.MM.YYYY')}</TableCell>
                      <TableCell>{org.exclusionDate && moment(org.exclusionDate).format('DD.MM.YYYY')}</TableCell>

                      {
                        <TableCell>
                          <div className={classes.row}>
                            {!org.exclusionDate && !readonly && !['fire', 'transfer', 'edit'].includes(action) && (
                              <Fragment>
                                <Button
                                  size="small"
                                  color="primary"
                                  variant="contained"
                                  style={{ marginRight: 10 }}
                                  onClick={() => {
                                    updateDefaultFormValue(org);
                                    setOrgId(org.id);
                                    setCurrentTrainer(org.dirTrainer?.value);
                                    setCurrentTrainingStage(org.clsTrainingStage?.value);
                                    setAction('transfer');
                                  }}
                                >
                                  Перевод
                                </Button>
                                <Tooltip title="удалить" placement="top-start">
                                  <Button
                                    className={classes.btn}
                                    color="secondary"
                                    variant="contained"
                                    style={{ marginRight: 10 }}
                                    onClick={() => {
                                      setOrgId(org.id);
                                      setAction('fire');
                                    }}
                                  >
                                    <ClearIcon className={classes.icon} />
                                  </Button>
                                </Tooltip>
                              </Fragment>
                            )}

                            {!['fire', 'transfer', 'edit'].includes(action) && !readonly && (
                              <Tooltip title="изменить" placement="top-start">
                                <Button
                                  className={classes.btn}
                                  color="primary"
                                  variant="contained"
                                  onClick={() => {
                                    updateDefaultFormValue(org);
                                    setOrgId(org.id);
                                    setCurrentTrainer(org.dirTrainer?.value);
                                    setAction('edit');
                                  }}
                                >
                                  <EditIcon className={classes.icon} />
                                </Button>
                              </Tooltip>
                            )}
                          </div>
                        </TableCell>
                      }
                    </TableRow>
                  )),
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default OrgAccordion;
