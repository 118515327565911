import React, { FC } from 'react';
import { Select } from '../components/Inputs';
import { ControllerProps } from 'react-hook-form/dist/types/props';

interface YearsProps extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  maxYears?: number;
  error: boolean | undefined;
  errorMessage?: string;
}

const Years: FC<YearsProps> = ({ errorMessage, maxYears = 15, label, error, control, name, rules }) => {
  const currentYear = Number(new Date().getFullYear());
  const data: { label: string; value: string }[] = [];
  for (let year = currentYear - maxYears; year < currentYear + maxYears; year++) {
    data.push({ label: year.toString(), value: year.toString() });
  }
  console.log(error);
  return (
    <Select
      label={label}
      data={data || []}
      control={control}
      error={error}
      errorMessage={errorMessage}
      name={name}
      rules={rules}
    />
  );
};

export default Years;
