import {
  Document,
  AlignmentType,
  IRunOptions,
  Paragraph,
  ParagraphChild,
  Table,
  TableCell,
  TableRow,
  TextRun,
  VerticalAlign,
  WidthType,
  Packer,
  ISpacingProperties,
  IIndentAttributesProperties,
} from 'docx';
import { DirPerson, DirSportingEventReferee, SportingEventRefereesListFragment } from '../../../api';
import moment from 'moment';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import { highRefereesNumber, sortReferees } from '../helpers/sortReferees';

type Data = SportingEventRefereesListFragment;

type TProps = {
  data: Data;
  fileName: string;
};

type TParagraphProps = {
  spacing?: ISpacingProperties;
  indent?: IIndentAttributesProperties;
};

const addText = (props: IRunOptions) => new TextRun({ ...props, font: 'Times New Roman' });
const addParagraph = (
  children: ParagraphChild[],
  alignment: AlignmentType = AlignmentType.CENTER,
  props?: TParagraphProps,
) =>
  new Paragraph({
    children,
    alignment,
    ...(props?.spacing && { spacing: props?.spacing }),
    ...(props?.indent && { indent: props?.indent }),
  });

const addRow = (cells: TableCell[], tableHeader?: boolean) => new TableRow({ children: cells, tableHeader });

const addCell = (children: (Paragraph | Table)[]) =>
  new TableCell({
    children,
    verticalAlign: VerticalAlign.CENTER,
  });

const joinArrayToString = (array: (string | undefined | null)[] = [], symbol = ',') =>
  array.filter((n) => n).join(symbol);

const getRefereeCategory = (referee: DirSportingEventReferee, eventSport = '') => {
  const categories = _.flatMap(
    referee.dirReferee?.sports?.filter((s) => s?.dirSport?.id === eventSport)?.map((sport) => sport.categories) ?? [],
  );

  return moment().isBefore(moment(categories[0]?.validUntil)) ? categories[0]?.clsRefereeCategory?.shortName : '';
};

const getRegion = (person: DirPerson) =>
  person?.dirRegion?.fullName + (person.residenceCity ? ', ' + person.residenceCity : '');

export const fetchSportingEventRefereeReport = async (props: any) => {
  const { data, fileName }: TProps = props;
  const { referees, ekp = '05215', startDate, endDate, venue = '', name = '', sports = [] } = data;
  const eventSport = _.get(sports, '[0].dirSport.id', '');
  // ${data?.SportingEventReferees?.name}.docx

  const header = [
    addParagraph(
      [
        addText({ text: 'УТВЕРЖДАЮ      ' }),
        addText({
          text: 'Заместитель директора',
          break: 1,
        }),
        addText({ text: 'ФГБУ ФЦПСР      ', break: 1 }),
      ],
      AlignmentType.RIGHT,
    ),
    addParagraph([]),
    addParagraph(
      [
        addText({
          text: '_____________ А.Г. Филюшкин',
        }),
        addText({
          text: `«____» _______________ 20__ г.`,
          break: 1,
        }),
      ],
      AlignmentType.RIGHT,
    ),
    addParagraph([
      addText({
        text: 'СПИСОК',
        break: 1,
        bold: true,
      }),
      addText({
        text: 'судейской коллегии для проведения',
        break: 1,
        bold: true,
      }),
      addText({
        text: 'спортивных соревнований',
        break: 1,
        bold: true,
      }),
      addText({
        text: name,
        break: 1,
        bold: true,
      }),
      addText({
        text: 'подтвердивших судейские категории в соответствии с ',
        break: 1,
        bold: true,
      }),
      addText({
        text: 'приказом Минспорта России от 28.02.2017 № 134 с изменениями, внесенными',
        break: 1,
        bold: true,
      }),
      addText({
        text: 'приказом Минспорта России от 13 февраля 2018 г. № 123',
        break: 1,
        bold: true,
      }),
    ]),
    addParagraph([addText({ text: `(спортивное мероприятие № ${ekp} в ЕКП)`, break: 1 })], AlignmentType.CENTER, {}),
    addParagraph([]),
    addParagraph(
      [
        addText({
          text: `Место проведения:   г.${venue}    `,
        }),
        addText({
          text: `Сроки: ${moment(startDate).format('DD.MM.YYYY')}-${moment(endDate).format('DD.MM.YYYY')}`,
        }),
      ],
      AlignmentType.CENTER,
    ),
    addParagraph([]),
  ];

  const footer = [
    addParagraph([]),
    addParagraph(
      [
        addText({ text: 'Ответственный от федерации*', bold: true }),
        addText({ text: '___________ / ' }),
        addText({
          text: '________________________________/',
        }),
      ],
      AlignmentType.CENTER,
    ),
    addParagraph(
      [
        addText({
          text: '(подпись)                                  ',
          subScript: true,
          bold: true,
        }),
        addText({
          text: '(расшифровка подписи)                                            ',
          subScript: true,
          bold: true,
        }),
      ],
      AlignmentType.RIGHT,
    ),
    addParagraph([]),
    addParagraph(
      [
        addText({
          text: 'тел.: ________________  ',
        }),
        addText({
          text: 'e-mail: __________________    ',
        }),
      ],
      AlignmentType.RIGHT,
    ),
    addParagraph([]),
    addParagraph(
      [
        addText({
          text:
            'Оплата работы спортивных судей осуществляется за счет субсидий, выделяемых ФГБУ ФЦПСР на проведение спортивных мероприятий в рамках исполнения государственного задания.  ',
        }),
        addText({
          text:
            'Судьям выделенным жирным шрифтом дополнительно оплачиваются расходы по проживанию, проезд к месту проведения спортивных соревнований и обратно. ',
          break: 1,
        }),
      ],
      AlignmentType.JUSTIFIED,
    ),
  ];

  const refereesColumn = ((referees && _.flattenDeep(_.values(sortReferees(referees)))) || [])?.map((referee, index) =>
    addRow(
      [
        addCell([addParagraph([addText({ text: String(index + 1), bold: referee.isNonresident })])]),
        addCell([
          addParagraph([
            addText({
              text: joinArrayToString(
                [
                  referee.dirReferee.dirPerson.lastname,
                  referee.dirReferee.dirPerson.firstname,
                  referee.dirReferee.dirPerson.patronymic,
                ],
                ' ',
              ),
              bold: referee.isNonresident,
            }),
          ]),
        ]),
        addCell([
          addParagraph([
            addText({
              text: referee.clsRefereePosition.shortName || '',
              bold: referee.isNonresident,
            }),
            ...((highRefereesNumber.includes(_.get(referee, 'clsRefereePosition.registryNumber', 0)) &&
              referee.dirReferee.dirPerson.phones &&
              referee.dirReferee.dirPerson.phones?.length > 0 && [
                addText({
                  text:
                    `тел.:` +
                    joinArrayToString(
                      referee.dirReferee.dirPerson.phones?.map(
                        (p) =>
                          `${[
                            p.countryCode || '',
                            p.operatorCode ? `(${p.operatorCode})` : '',
                            p.number || '',
                            p?.additionalNumber ? `(доб. ${p?.additionalNumber})` : '',
                          ].join(' ')} (${p.clsContactType.label})`,
                      ) || [],
                    ),
                  bold: referee.isNonresident,
                  break: 2,
                }),
              ]) ||
              []),
            ...((highRefereesNumber.includes(_.get(referee, 'clsRefereePosition.registryNumber', 0)) &&
              referee.dirReferee.dirPerson.email && [
                addText({
                  text: `e-mail:` + referee.dirReferee.dirPerson.email,
                  bold: referee.isNonresident,
                  break: 1,
                }),
              ]) ||
              []),
          ]),
        ]),
        addCell([
          addParagraph([
            addText({ text: getRefereeCategory(referee as any, eventSport) || '', bold: referee.isNonresident }),
          ]),
        ]),

        addCell([
          addParagraph([
            addText({
              text: getRegion(referee.dirReferee.dirPerson as any),
              bold: referee.isNonresident,
            }),
          ]),
        ]),
      ],
      true,
    ),
  );
  const table = new Table({
    width: {
      size: 100,
      type: WidthType.AUTO,
    },
    columnWidths: [600, 3000, 2000, 1500, 2000],
    rows: [
      addRow([
        addCell([addParagraph([addText({ text: '№п/п', bold: true })])]),
        addCell([addParagraph([addText({ text: 'Ф.И.О.(полностью)', bold: true })])]),
        addCell([addParagraph([addText({ text: 'Должность', bold: true })])]),
        addCell([addParagraph([addText({ text: 'Судейская категория', bold: true })])]),
        addCell([addParagraph([addText({ text: 'Субъект РФ', bold: true })])]),
      ]),
      ...refereesColumn,
    ],
  });

  const doc = new Document({
    sections: [
      {
        children: [...header, table, ...footer],
      },
    ],
  });

  Packer.toBlob(doc).then((blob) => {
    saveAs(blob, fileName + '.docx');
  });
};
