import {
  AgeGroupFrFragment,
  GroupTypesSportAndDisciplinesDocument,
  TypeSportAgeGroupsArchiveMutationHookResult,
  TypeSportAgeGroupsCreateMutationHookResult,
  TypeSportAgeGroupsRequirementsCreateMutationHookResult,
  TypeSportAgeGroupsRequirementsDeleteMutationHookResult,
  TypeSportAgeGroupsUnArchiveMutationHookResult,
  TypeSportAgeGroupsUpdateMutationHookResult,
  useTypeSportAgeGroupsArchiveMutation,
  useTypeSportAgeGroupsCreateMutation,
  useTypeSportAgeGroupsQuery,
  useTypeSportAgeGroupsRequirementsCreateMutation,
  useTypeSportAgeGroupsRequirementsDeleteMutation,
  useTypeSportAgeGroupsUnArchiveMutation,
  useTypeSportAgeGroupsUpdateMutation,
} from '../../../api';
import {
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import React, { FC, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import { AgeGroups, RanksAndTitle } from '../../../module';
import ArchiveComponent from '../../Dialogs/Archive/Archive';
import EditIcon from '@material-ui/icons/Edit';
import { Input, Select } from '../../Inputs';
import SaveIcon from '@material-ui/icons/Save';
import _ from 'lodash';
import getMessage from '../../../messages';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import CalendarCategoryId from '../../../module/CalendarCategoryId';

const RequirementByCapture: FC<{ open: boolean; onClose: () => void; onSubmit: (T: any) => void }> = ({
  open,
  onClose,
  onSubmit,
}) => {
  const { control, errors, reset, handleSubmit } = useForm({});

  return (
    <Dialog
      open={open}
      onClose={(e) => onClose()}
      aria-labelledby="parent-dialog-title"
      aria-describedby="parent-dialog-description"
    >
      <DialogTitle id="parent-dialog-title">Требование к участию</DialogTitle>
      <DialogContent style={{ padding: '1rem' }}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '40ch' }}>
          <CalendarCategoryId
            label="Категория календаря"
            error={!!errors['clsCalendarCategory']}
            control={control}
            name="clsCalendarCategory"
            rules={{ required: true }}
          />
          <br />
          <RanksAndTitle
            label="Минимальный спортивный разряд"
            error={!!errors['clsRanksAndAwards']}
            control={control}
            name="clsRanksAndAwards"
            rules={{ required: true }}
          />
          <Button type="submit" variant="outlined" color="primary" style={{ width: '100%', marginTop: '1rem' }}>
            Сохранить
          </Button>
          <Button
            type="button"
            onClick={() => {
              reset({});
              onClose();
            }}
            variant="outlined"
            color="primary"
            style={{ width: '100%', marginTop: '1rem' }}
          >
            Отмена
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const AgeGroupsWidget: FC<{ readonly?: boolean; id: string }> = ({ readonly, id }) => {
  const [open, setOpen] = useState(false);
  const [openId, setOpenId] = useState<number | null>(null);
  const onClose = () => {
    setOpen(false);
  };
  const onOpen = () => {
    setOpen(true);
  };

  const { data, refetch } = useTypeSportAgeGroupsQuery({ variables: { id } });
  const { handleSubmit, register, control, errors, watch, reset, formState } = useForm({
    defaultValues: { minAge: 0, maxAge: undefined, clsAgeGroup: {}, id: null } as AgeGroupFrFragment,
  });
  const [mode, setMode] = useState<'list' | 'form'>('list');
  const [edit, setEdit] = useState<null | AgeGroupFrFragment>(null);
  const { enqueueSnackbar } = useSnackbar();

  const [archive]: TypeSportAgeGroupsArchiveMutationHookResult = useTypeSportAgeGroupsArchiveMutation();
  const [unarchive]: TypeSportAgeGroupsUnArchiveMutationHookResult = useTypeSportAgeGroupsUnArchiveMutation();

  const [create]: TypeSportAgeGroupsCreateMutationHookResult = useTypeSportAgeGroupsCreateMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: GroupTypesSportAndDisciplinesDocument,
        data: {
          ...data?.updateDirSport,
        },
      });
    },
  });

  const [update]: TypeSportAgeGroupsUpdateMutationHookResult = useTypeSportAgeGroupsUpdateMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: GroupTypesSportAndDisciplinesDocument,
        data: {
          ...data?.updateDirSport,
        },
      });
    },
  });

  const [
    createRequirements,
  ]: TypeSportAgeGroupsRequirementsCreateMutationHookResult = useTypeSportAgeGroupsRequirementsCreateMutation();

  const [
    deleteRequirements,
  ]: TypeSportAgeGroupsRequirementsDeleteMutationHookResult = useTypeSportAgeGroupsRequirementsDeleteMutation();

  const onSubmit = handleSubmit(async (values: AgeGroupFrFragment) => {
    const maxAge = values?.maxAge ? +values?.maxAge : null;
    const minAge = values?.minAge ? +values?.minAge : 0;
    try {
      if (edit) {
        await update({
          variables: {
            id,
            maxAge,
            minAge,
            idGroup: values?.id,
            idCls: values?.clsAgeGroup?.value,
          },
        });
        setEdit(null);
        enqueueSnackbar(`Половозрастная группа "${values?.clsAgeGroup?.label}" успешно обновлена`, {
          variant: 'success',
        });
      } else {
        await create({
          variables: {
            id,
            maxAge,
            minAge,
            idCls: values?.clsAgeGroup?.value,
          },
        });
        enqueueSnackbar(`Половозрастная группа "${values?.clsAgeGroup?.label}" успешно добавлена`, {
          variant: 'success',
        });
      }
      reset({ minAge: undefined, maxAge: undefined, clsAgeGroup: {}, id: null });
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
    setMode('list');
  });

  const watchedMaxAge = watch('maxAge');

  return (
    <Grid container>
      {!readonly && !(mode === 'form' || edit) && (
        <Grid item md={12}>
          <IconButton
            onClick={() => {
              setMode('form');
              reset({ minAge: undefined, maxAge: undefined, clsAgeGroup: {}, id: null });
            }}
            edge="end"
            aria-label="add"
            type="button"
          >
            <AddIcon color="primary" />
          </IconButton>
        </Grid>
      )}
      {(mode === 'form' || edit) && (
        <form onSubmit={onSubmit} style={{ minWidth: '100%' }}>
          <Grid item md={12} container spacing={2} direction="row">
            <input type="hidden" name="id" ref={register()} />
            <Grid item md={5}>
              <AgeGroups
                label="Половозрастная группа"
                error={!!errors['clsAgeGroup']}
                name="clsAgeGroup"
                control={control}
                rules={{ required: true }}
              />
            </Grid>
            <Grid item md={2}>
              <Input
                label="От"
                type="number"
                control={control}
                error={!!errors['minAge']}
                errorMessage={errors['minAge']?.message}
                name="minAge"
                rules={{
                  required: true,
                  validate: (val) => {
                    return !watchedMaxAge
                      ? undefined
                      : +watchedMaxAge >= +val
                      ? undefined
                      : 'Возраст ОТ не может быть больше ДО';
                  },
                }}
              />
            </Grid>
            <Grid item md={2}>
              <Input label="До" control={control} error={!!errors['maxAge']} name="maxAge" />
            </Grid>
            <Grid item md={12} container spacing={1}>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  type="button"
                  onClick={() => {
                    setMode('list');
                    setEdit(null);
                  }}
                  size="small"
                >
                  Отмена
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  type="submit"
                  size="small"
                  startIcon={<SaveIcon color="primary" />}
                  disabled={!formState.isDirty}
                >
                  Сохранить
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
      {mode === 'list' && !edit && (
        <Grid item md={6}>
          <List>
            {_.sortBy(data?.dirSport?.ageGroups, [(o) => o?.archive !== null])?.map((item) => (
              <>
                <ListItem key={item.id} alignItems="flex-start">
                  <ListItemText
                    primary={item.clsAgeGroup.label}
                    secondary={`От ${item.minAge}${item.maxAge ? ` до ${item.maxAge}` : ''}`}
                    {...(item?.archive && { style: { color: 'gray' } })}
                  />
                  {!readonly && (
                    <ListItemSecondaryAction>
                      {!item?.archive && (
                        <>
                          <IconButton
                            edge="end"
                            aria-label="редактировать"
                            onClick={() => {
                              reset(item);
                              setEdit(item);
                            }}
                          >
                            <EditIcon color="primary" />
                          </IconButton>
                        </>
                      )}
                      <ArchiveComponent
                        canUnArchive
                        id={item.id}
                        archiveStatus={item.archive}
                        unarchive={unarchive}
                        refetch={refetch}
                        archive={archive}
                      />
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
                <div style={{ paddingLeft: 16, paddingBottom: 16 }}>
                  <span style={{ fontWeight: 'bold' }}>Требование к участию</span>
                  <IconButton
                    onClick={(e) => {
                      onOpen();
                      setOpenId(item.id);
                    }}
                    edge="end"
                    aria-label="add"
                    type="button"
                  >
                    <AddIcon color="primary" />
                  </IconButton>
                  <div>
                    {item?.participationRequirements?.map((i) => {
                      return (
                        <Chip
                          variant="outlined"
                          style={{ marginBottom: 4 }}
                          onDelete={() =>
                            deleteRequirements({ variables: { idAgeGroup: item.id, id: i.id } }).then((res) =>
                              refetch(),
                            )
                          }
                          label={`${i?.clsCalendarCategory?.label} (мин: ${i?.clsRanksAndAwards?.label})`}
                        />
                      );
                    })}
                  </div>
                </div>
                <Divider />
              </>
            ))}
          </List>
        </Grid>
      )}
      <RequirementByCapture
        open={open}
        onClose={() => onClose()}
        onSubmit={(data: any) => {
          createRequirements({
            variables: {
              idAgeGroup: openId,
              clsCalendarCategory: data?.clsCalendarCategory?.value,
              clsRanksAndAwards: data?.clsRanksAndAwards?.value,
            },
          }).then((i) => {
            refetch();
            setOpen(false);
            setOpenId(null);
          });
        }}
      />
    </Grid>
  );
};

export default AgeGroupsWidget;
