import exceljs, { Row } from 'exceljs';
import { FederalDistrictPortionFragment, CalendarQuantyRequestStageTwoFragment } from '../../../api';
import { saveAs } from 'file-saver';
import _, { flattenDeep, get } from 'lodash';

interface SportModel {
  man: number;
  woman: number;
  athleteTotal: number;
  coach: number;
  total: number;
}

export type FetchSummaryInformationOnTheNumberOfParticipantsReport = {
  data: Data;
  mergeCellsPosition?: Array<[string, string]>;
  blancUrl?: string;
  calendarName: string;
  calendarSports: any[];
  fileName: string;
  mergeStart?: number;
};

export type Data = {
  quantyRequestStageTwoes: CalendarQuantyRequestStageTwoFragment[];
  dirFederalDistricts: FederalDistrictPortionFragment[];
};

const fyis = {
  'fb3499cd-ab3e-46a7-ae46-1ca95b302f89': 'ЦФО',
  'c7e8e2f6-6ff9-448b-bf5d-e4cf74f7b31c': 'СЗФО',
  'b337df4d-5307-48df-a012-c9a93e40f306': 'ЮФО',
  '13aa0371-1fb5-483b-a782-37292ee6330a': 'СКФО',
  'a9769af5-885c-4f17-8d10-077250c5f8f6': 'ПФО',
  '8b764da5-80ce-4c85-aee7-1bff332f7137': 'УФО',
  '9112e7d2-2302-43ce-95fd-ea75eab8e2c0': 'СФО',
  '1279d0cc-b61e-4528-8268-a2b6a098dce1': 'ДФО',
};

export const fetchSummaryInformationOnTheNumberOfParticipantsReport: (
  props: FetchSummaryInformationOnTheNumberOfParticipantsReport,
) => void = async (props) => {
  try {
    const {
      calendarName,
      data,
      blancUrl = '/templates/blancSummaryInfoReport.xlsx',
      fileName,
      calendarSports: sports = [],
    } = props;

    const { quantyRequestStageTwoes = [], dirFederalDistricts = [] } = data;

    const workbook = new exceljs.Workbook();

    const setCellText = (position: string, value: string) => {
      sheet.getCell(position).value = value;
    };
    const blanc = await (await fetch(blancUrl)).arrayBuffer();
    await workbook.xlsx.load(blanc);
    const sheet = workbook.worksheets[0];

    const firstRow = sheet.getRow(5);
    const secondRow = sheet.getRow(6);

    const totalRow = sheet.getRow(8);

    const startTab = secondRow.getCell('C');
    const contentTab = secondRow.getCell('F');
    const endTab = secondRow.getCell('I');

    const totalCell = totalRow.getCell('B');

    //vertical title - under sports
    const cell = firstRow.getCell('C');

    // add sport column along with title tabs
    const addNewSportTab = (startIndex: number, value: string) => {
      const fields = ['alignment', 'border', 'font', 'style', 'fill'];
      const tableHeadFields = ['Количество субъектов РФ', 'Юноши', 'Девушки', 'Всего', 'Тренеры', 'Итого'];

      //add sport title and merge cells

      const endColumn = sheet.getColumn(startIndex + 5);
      const startColumn = sheet.getColumn(startIndex);
      sheet.mergeCells(`${startColumn.letter}5:${endColumn.letter}5`);

      fields.forEach((field) => {
        firstRow.getCell(startColumn.letter)[field] = cell[field];
      });
      firstRow.getCell(startColumn.letter).value = value;

      // add content headers
      tableHeadFields.forEach((item, index) => {
        fields.forEach((field) => {
          secondRow.getCell(startIndex + index)[field] =
            index === 0 ? startTab[field] : index > 5 ? endTab[field] : contentTab[field];
        });
        secondRow.getCell(startIndex + index).value = item;
      });
    };

    //set doc title - calendar name
    setCellText('B1:AA1', `  ${calendarName}. Количественные заявки субъектов РФ на II этап`);

    // add the sport tabs to doc
    sports.forEach((sport, index) => {
      // if index == 0 just update text in cell
      if (index === 0) {
        firstRow.getCell('J').value = sport?.fullName;
      } else {
        //else add new sport tab
        addNewSportTab(10 + index * 6, sport?.fullName);
      }
    });

    //build three
    const result = _.keys(fyis).map((key) => {
      const district = dirFederalDistricts.find((d) => d?.id === key);
      const regionTotal = {
        man: 0,
        woman: 0,
        athleteTotal: 0,
        coach: 0,
        total: 0,
        sports: 0,
        subjectRf: 0,
        sportTotal: [],
        sportTotalObject: {},
      };

      //sport total object
      const sportTotal = {};
      sports?.forEach((s) => {
        sportTotal[s.id] = [0, 0, 0, 0, 0, 0];
      });

      const regions = district?.dirRegions?.map((region) => {
        const quantys = quantyRequestStageTwoes?.filter((r) => r?.dirRegion?.id === region?.id);

        const Sport = {} as SportModel;
        const sportsArray: number[][] = [];
        let manCount = 0;
        let womanCount = 0;
        let coachCount = 0;
        let totalCount = 0;
        let athleteTotalCount = 0;

        for (const s of sports) {
          const quanty = quantys?.find((q) => q.dirSport?.id === s?.id);
          const sportLength = quanty !== undefined ? 1 : 0;

          const athleteTotal =
            get(quanty, 'regionQuotaMaleAthlStageTwo', 0) + get(quanty, 'regionQuotaWomenAthlStageTwo', 0);
          const [man, woman, coach, total] = [
            quanty?.regionQuotaMaleAthlStageTwo || 0,
            quanty?.regionQuotaWomenAthlStageTwo || 0,
            quanty?.regionQuotaAccompStageTwo || 0,
            athleteTotal + (quanty?.regionQuotaAccompStageTwo || 0),
          ];
          if (quanty) {
            Sport[s.id] = {
              [s.id]: [sportLength, man, woman, null, coach, total],
              man,
              woman,
              athleteTotal,
              coach,
              total,
            };
            sportTotal[s.id] = [
              sportTotal[s.id][0] + sportLength,
              sportTotal[s.id][1] + man,
              sportTotal[s.id][2] + woman,
              sportTotal[s.id][3] + athleteTotal,
              sportTotal[s.id][4] + coach,
              sportTotal[s.id][5] + total,
            ];
          }

          sportsArray.push([sportLength, man, woman, -1, coach, total]);

          manCount += man;
          womanCount += woman;
          coachCount += coach;
          athleteTotalCount += athleteTotal;
          totalCount += total;
        }

        const subjectRf = Object.keys(Sport).length > 0 ? 1 : 0;
        const sportCount = Object.keys(Sport).length;

        regionTotal.man = regionTotal.man + manCount;
        regionTotal.woman = regionTotal.woman + womanCount;
        regionTotal.athleteTotal = regionTotal.athleteTotal + athleteTotalCount;
        regionTotal.coach = regionTotal.coach + coachCount;
        regionTotal.total = regionTotal.total + totalCount;
        regionTotal.sports = regionTotal.sports + sportCount;
        regionTotal.subjectRf = regionTotal.subjectRf + subjectRf;
        regionTotal.sportTotalObject = sportTotal;
        regionTotal.sportTotal = flattenDeep(Object.values(sportTotal));

        return {
          ...region,
          subjectRf,
          sportCount,
          Sport,
          sportsArray,
          manCount,
          womanCount,
          coachCount,
          athleteTotalCount,
          totalCount,
        };
      });

      return {
        ...district,

        regions,
        regionTotal,
      };
    });

    //sport total calculation
    const sportTotal = () =>
      flattenDeep(
        sports.map((sport) =>
          result
            .map((r) => r?.regionTotal?.sportTotalObject[sport?.id])
            .reduce((acc, curr) => curr.map((_, index) => curr[index] + acc[index]), [0, 0, 0, 0, 0, 0]),
        ),
      );

    const updateRowData = (row: Row, item: any, tableRowIndex: number, rowIndex: number) => {
      row.values = [
        tableRowIndex,
        item.fullName || '',
        item.subjectRf || '',
        item.sportCount || '',
        item.manCount || '',
        item.womanCount || '',
        {
          formula: `SUM(E${rowIndex}:F${rowIndex})`,
          date1904: false,
        },
        item.coachCount || '',
        {
          formula: `SUM(G${rowIndex}:H${rowIndex})`,
          date1904: false,
        },
        ...flattenDeep(item.sportsArray).map((n) => n || ''),
      ];
      row.eachCell((cell, cellIndex) => {
        if (cell.value === -1) {
          const manAddress = get(row.getCell(cellIndex - 2), '_column.letter', '');
          const womanAdress = get(row.getCell(cellIndex - 1), '_column.letter', '');

          cell.value = {
            formula: `SUM(${manAddress}${rowIndex}:${womanAdress}${rowIndex})`,
            date1904: false,
          };
        }
        if (cell.value === -2) {
          const coachAddress = get(row.getCell(cellIndex - 1), '_column.letter', '');
          const totalAdress = get(row.getCell(cellIndex - 2), '_column.letter', '');
          cell.value = {
            formula: `SUM(${totalAdress}${rowIndex}:${coachAddress}${rowIndex})`,
            date1904: false,
          };
        }
        if (cellIndex > 2) {
          cell.style.alignment = {
            horizontal: 'center',
            vertical: 'middle',
          };
        }
      });

      return;
    };

    //update totals
    const updateTotals = (totalSum: string[]) => {
      sheet.getRow(8).values = ['', 'ИТОГО:', 0, 0, 0, 0, 0, 0, 0, ...sportTotal()];
      sheet.getRow(8).eachCell((cell, index) => {
        if (index > 1) {
          cell.style = totalCell.style;
        }
        if (index > 2) {
          cell.value = {
            formula: totalSum[index - 3],
            date1904: false,
          };
          cell.style.alignment = {
            horizontal: 'center',
            vertical: 'middle',
          };
        }
      });
      return;
    };

    // add tree to docs
    let track = 9;
    let totalSum: string[] = [];
    const addValue = async () => {
      result?.forEach((item, resultIndex) => {
        resultIndex === 0 && (sheet.getRow(track).getCell('B').value = fyis[item?.id]);

        track += 1;
        const regionLength = get(item, 'regions', []).length;
        const rows: any[] = [];
        sheet.duplicateRow(track, regionLength - 1, true);
        for (const [index, region] of get(item, 'regions', []).entries()) {
          updateRowData(sheet.getRow(track + index), region, index + 1, track + index);
          rows.push([
            index + 1,
            region.fullName || '',
            region.subjectRf,
            region.sportCount,
            region.manCount,
            region.womanCount,
            region.athleteTotalCount,
            region.coachCount,
            region.totalCount,
            ...flattenDeep(region.sportsArray),
          ]);
        }

        // update total count row
        sheet.getRow(track + rows.length).values = [
          '',
          'Всего',
          item?.regionTotal?.subjectRf,
          item?.regionTotal?.sports,
          item?.regionTotal?.man,
          item?.regionTotal?.woman,
          item?.regionTotal?.athleteTotal,
          item?.regionTotal?.coach,
          item?.regionTotal?.total,
          ...item?.regionTotal?.sportTotal,
        ];
        sheet.getRow(track + rows.length).eachCell((cell, index) => {
          if (index > 1) {
            cell.style = totalCell.style;
          }
          if (index > 2) {
            const colAddress = get(cell, '_column.letter', '');
            const formula = `SUM(${colAddress}${track}:${colAddress}${track + rows.length - 1})`;
            totalSum[index - 3] = (totalSum[index - 3] || '') + '+' + formula;
            cell.value = {
              formula,
              date1904: false,
            };
            cell.style.alignment = {
              horizontal: 'center',
              vertical: 'middle',
            };
          }
        });
        track += rows.length + 1;
      });
      return;
    };

    await addValue();

    await updateTotals(totalSum);

    const saveFile = async () => {
      const buffer = await workbook.xlsx.writeBuffer({
        useStyles: true,
      });
      const blob = new Blob([buffer], { type: 'applicationi/xlsx' });
      saveAs(blob, fileName + '.xlsx');
    };

    saveFile();
  } catch (error) {}
};
