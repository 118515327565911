import React, { FC, useEffect, useMemo, useState } from 'react';
import { useFindSimiliarPersonAthletesLazyQuery } from '../api';
import { Select } from '../components/Inputs';
import { ControllerProps } from 'react-hook-form/dist/types/props';
import useDebouncedSearch from '../hooks/useDebouncedSearch';
import _ from 'lodash';
import moment from 'moment';

interface PersonsModuleProps extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  error: boolean | undefined;
  getterValue?: (T: any) => void;
  // По фильтрам смотреть доку
  filter?: any;
  s_filter?: any;
  profile?: string;
  disabled?: boolean;
}

const SearchAthletes: FC<PersonsModuleProps> = ({
  getterValue,
  label,
  error,
  control,
  name,
  profile,
  rules,
  filter,
  s_filter,
  disabled,
}) => {
  const [fetch, { data, loading }] = useFindSimiliarPersonAthletesLazyQuery({
    variables: {
      search: '',
      filter,
      s_filter,
    },
  });

  const [parsed, setParsed] = useState<any>([]);

  const refetchData = async (value: string) => {
    if (value?.length > 2) {
      await fetch({ variables: { search: value, filter } });
    }
  };
  const useSearch = () => useDebouncedSearch((text) => refetchData(text));

  const { setInputValue: setValue } = useSearch();

  const setOptionProps = (item: any, isOut = false) => {
    return {
      value: getterValue?.(item.person) || item.person.id,
      label: `${item.person.lastname} ${item.person.firstname} ${
        item.person.patronymic ? item.person.patronymic : ''
      } ${moment(item.person.birthday).format('DD.MM.YYYY')}`,
      labelElement: (
        <span>
          {item.person.lastname} {item.person.firstname} {item.person.patronymic ? item.person.patronymic : ''}{' '}
          {moment(item.person.birthday).format('DD.MM.YYYY')}
          {isOut && <span style={{ color: 'red', marginLeft: '5px' }}>(Возраст не соотв. треб-ям)</span>}
        </span>
      ),
      isMale: item?.person.isMale,
      birthday: item?.person?.birthday || '',
      personRoleId: _.get(item, `person.${profile}.id`),
      roleData: _.get(item, `person.${profile}`),
    };
  };

  useEffect(() => {
    if (!loading && data) {
      const { _in = [], _out = [] } = data || {};
      const prepare = [..._in?.map((item) => setOptionProps(item)), ..._out.map((item) => setOptionProps(item, true))];
      setParsed(prepare || []);
    }
  }, [data, getterValue, loading, profile]);

  return (
    <Select
      label={label}
      data={parsed}
      control={control}
      error={error}
      name={name}
      rules={rules}
      shouldNtFilter
      onChangeInput={setValue}
      loading={loading}
      disabled={disabled}
    />
  );
};

export default SearchAthletes;
