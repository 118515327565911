import React, { FC } from 'react';
import { DirSport, useTypeSportsListSelectQuery } from '../api';
import { Select } from '../components/Inputs';
import { ReactHookFormSelect } from '../components/Inputs/Select';

interface TypeSports
  extends Pick<ReactHookFormSelect, 'control' | 'name' | 'rules' | 'disabled' | 'label' | 'error' | 'errorMessage'> {
  data?: {
    value: DirSport['id'];
    label: DirSport['fullName'];
  }[];
  usedValue?: DirSport['id'][];
  multiple?: boolean;
  filter?: any;
}

const TypeSportsSelect: FC<TypeSports> = ({
  disabled,
  usedValue,
  label,
  error,
  control,
  name,
  rules,
  data,
  multiple,
  filter = {},
}) => {
  const { data: dirSports } = useTypeSportsListSelectQuery({ variables: { where: filter } });
  return (
    <Select
      label={label}
      data={
        data ||
        (usedValue && dirSports?.dirSports?.filter((sport) => usedValue?.indexOf(sport.value) === -1)) ||
        dirSports?.dirSports ||
        []
      }
      multiple={multiple}
      control={control}
      error={error}
      disabled={disabled}
      name={name}
      rules={rules}
    />
  );
};

export default TypeSportsSelect;
