import _get from 'lodash/get';

const messages = {
  'Wrong password': 'Неверный логин или пароль',
  'A unique constraint would be violated on DirOrganization. Details: Field name = ogrn':
    'Организация с данным ОГРН уже существует в системе',
  'A unique constraint would be violated on DirSport. Details: Field name = fullName':
    'Данный вид спорта уже заведен в систему',
  'A unique constraint would be violated on DirSport. Details: Field name = evsk':
    'Вида Спорта с данным ЕВСК уже существует в системе',
  'A unique constraint would be violated on User. Details: Field name = email':
    'Пользователь с данным логином уже существует',
  'User does not exists': 'Такого пользователя не существует',
  'Task already exists': 'такая задача уже существует',
  'Inverse task already exists': 'Обратная задача уже существует',
  'Invalid task': 'Недействительная задача',
  "The change you are trying to make would violate the required relation 'DirAthleteCompetitionResult_sportingEventProgramType' between DirAthleteCompetitionResult and DirSportingEventProgramType":
    'Если вы действительно хотите удалить Вид программы, сначала необходимо удалить Результаты соревнований по этому Виду программ.',
};

const getMessage = (key: string): string => {
  return _get(messages, key, key);
};

export default getMessage;
