import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme) => ({
  container: {
    width: '100%',
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      bottom: -20,
    },
  },
}));

const ContentWrapperWidget: FC<{ children: NonNullable<React.ReactNode> }> = ({ children }) => {
  const styles = useStyle();

  return <div className={styles.container}>{children}</div>;
};

export default ContentWrapperWidget;
