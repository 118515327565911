import { DirRegion, useRegionsLazyQuery } from '../../api';
import { Grid, Tooltip } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import React, { FC, useCallback, useState } from 'react';
import { getSettingsUserFilter, getSettingsUserFilterFormValue } from '../../store/selectors/settings';
import { useDispatch, useSelector } from 'react-redux';

import { AppStore } from '../../store/reducers';
import { BtnNew } from '../../components/BtnNew';
import { DataTable } from '../../components/DataTable/NewTable';
import { DictionaryWrapper } from '../../components/DictionaryWrapper';
import { MUIDataTableColumn } from 'mui-datatables';
import { MainTemplate } from '../../components/layouts';
import { RegionsFilter } from '../../components/Filters';
import { SET_USER_SETTINGS_FILTER } from '../../store/constants';
import _ from 'lodash';
import { usePermission } from '../../hooks/usePermission';

const Page: FC<{}> = () => {
  const dispatch = useDispatch();
  const filter: any = useSelector((state: AppStore) => getSettingsUserFilter(state, 'regions'), _.isEqual);
  const formValue: any = useSelector((state: AppStore) => getSettingsUserFilterFormValue(state, 'regions'), _.isEqual);
  const { push } = useHistory();
  const [first, setFirst] = useState(0);
  const access = usePermission('directory', 'region');

  const setFilter = useCallback(
    (filter, form) => {
      dispatch({
        type: SET_USER_SETTINGS_FILTER,
        payload: {
          name: 'regions',
          filter: filter,
          form,
        },
      });
    },
    [dispatch],
  );

  const columns: MUIDataTableColumn[] = [
    {
      name: 'id',
      label: 'Название',
      options: {
        filter: false,
        sort: false,
        viewColumns: false,
        customBodyRender: (value: Pick<DirRegion, 'id'>, meta: any) => {
          return (
            <React.Fragment>
              <Tooltip title="Перейти в карточку" placement="top-start">
                <Link
                  style={{
                    fontWeight: 500,
                    cursor: 'pointer',
                    textDecoration: 'none',
                  }}
                  to={`/region/${value}`}
                  target={'_blank'}
                >
                  {meta.rowData[1]}
                </Link>
              </Tooltip>
            </React.Fragment>
          );
        },
      },
    },
    {
      name: 'fullName',
      options: {
        display: false,
        viewColumns: false,
      },
    },

    {
      name: 'shortName',
      label: 'Краткое название',
      options: {
        sort: false,
        display: true,
      },
    },
    {
      name: 'dirFederalDistrict',
      label: 'Федеральный округ',
      options: {
        sort: false,
        display: true,
        customBodyRender: (value: any, meta) => {
          return value.label;
        },
      },
    },
  ];

  return (
    <MainTemplate pageName="Регионы" url="" title="Регионы">
      <RegionsFilter
        onSubmit={(filters: any, form: any) => setFilter({ ...filters }, form)}
        first={first}
        value={formValue}
      />
      <DictionaryWrapper>
        <DataTable
          tableName="regions"
          title={
            <React.Fragment>
              <Grid container spacing={2} alignItems={'center'}>
                <Grid item>
                  <h2>Регионы</h2>
                </Grid>
                {access.includes(2) && <Grid item>{<BtnNew onClick={() => push('/region/new')} />}</Grid>}
              </Grid>
            </React.Fragment>
          }
          columns={columns}
          lazyQuery={useRegionsLazyQuery}
          filter={filter}
        />
      </DictionaryWrapper>
    </MainTemplate>
  );
};

export default Page;
