import { TFiltersPath } from '../types';

const getCalendarGeneralReportApiFilterProps = (value: any) => {
  const { id, dirSport, stageNumber } = value;
  return {
    dirCalendar: { id },
    ...((dirSport && { sports_some: { dirSport: { id: dirSport.value } } }) || {}),
    ...((stageNumber && { clsEventStages_some: { registryNumber: stageNumber.value } }) || {}),
    archive: null,
  };
};

const filters = {
  calendarGeneralReport: getCalendarGeneralReportApiFilterProps,
};

type TFunc = typeof getCalendarGeneralReportApiFilterProps;

export default class FilterService {
  async generate(filterPath: TFiltersPath, props: any) {
    try {
      const func: TFunc = filters[filterPath];
      return (func && (await func(props))) || null;
    } catch (error) {
      return;
    }
  }
}
