import React, { FC } from 'react';
import { useEventsStatusesExtendedQuery } from '../api';
import { Select } from '../components/Inputs';
import { ControllerProps } from 'react-hook-form/dist/types/props';

interface SportingEventsStatus extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  error: boolean | undefined;
  disabled?: boolean;
  filter?: any;
}

const SportingEventsStatus: FC<SportingEventsStatus> = ({ label, error, control, name, rules, filter, disabled }) => {
  const { data } = useEventsStatusesExtendedQuery({ variables: { filter } });
  return (
    <Select
      label={label}
      data={(data && data.dirSportingEventExtendedStatusValues) || []}
      control={control}
      error={error}
      name={name}
      rules={rules}
      disabled={disabled}
    />
  );
};

export default SportingEventsStatus;
