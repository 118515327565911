import React, { FC, useMemo, useState } from 'react';
import {
  CalendarRegulationsNotifyMutationHookResult,
  CreateCalendarRegulationMutationHookResult,
  RegulationAddUserRegulationsDocMutationHookResult,
  UpdateCalendarRegulationMutationHookResult,
  useCalendarRegulationsNotifyMutation,
  useCalendarRegulationsSportsQuery,
  useCalendarsSportsQuery,
  useCreateCalendarRegulationMutation,
  useRegulationAddUserRegulationsDocMutation,
  useUpdateCalendarRegulationMutation,
} from '../../../api';
import { BtnDocument } from '../../BtnDocument/BtnDocument';
import Status from './components/Status';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  LinearProgress,
  Typography,
  makeStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import userRights from '../../../config/userRights';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles({
  head: {
    fontWeight: 600,
    fontSize: 15,
    letterSpacing: 1.2,
  },
});

const RegulationAccordion: FC<{
  readonly?: boolean;
  id?: string;
  sportId?: string;
  calendarName?: string;
  sportName: string;
}> = ({ readonly, id = '', sportId = '', calendarName = '', sportName = '' }) => {
  const { isSportingEventOperatorFspcr } = userRights();
  const { data, refetch, loading } = useCalendarRegulationsSportsQuery({
    skip: !id || id === 'new' || !sportId,
    variables: { id, sportId },
    fetchPolicy: 'no-cache',
  });
  const { enqueueSnackbar } = useSnackbar();

  const [create]: CreateCalendarRegulationMutationHookResult = useCreateCalendarRegulationMutation();
  const [update]: UpdateCalendarRegulationMutationHookResult = useUpdateCalendarRegulationMutation();
  const [notify]: CalendarRegulationsNotifyMutationHookResult = useCalendarRegulationsNotifyMutation();
  const [updateUser]: RegulationAddUserRegulationsDocMutationHookResult = useRegulationAddUserRegulationsDocMutation();

  const regulation = data?.dirSportEventRegulationses[0] || null;

  const onAddNewDocument = async (fileId: string, docPath: string, notificationTypeNumber: string) => {
    if (regulation && regulation.id) {
      await update({
        variables: {
          id: regulation?.id,
          data: {
            [docPath]: {
              connect: {
                id: fileId,
              },
            },
          },
        },
      });
      if (docPath === 'regulationsDocument') {
        await updateUser({
          variables: {
            idRegulation: regulation?.id,
            idUser: localStorage.getItem('userId'),
          },
        });
      }
    } else {
      const localData = await create({
        variables: {
          data: {
            dirCalendar: {
              connect: {
                id,
              },
            },
            dirSport: {
              connect: {
                id: sportId,
              },
            },
            // status: {
            //   create: [
            //     {
            //       value: { connect: { id: 1 } },
            //     },
            //   ],
            // },
            [docPath]: {
              connect: {
                id: fileId,
              },
            },
          },
        },
      });
      if (docPath === 'regulationsDocument') {
        await updateUser({
          variables: {
            idRegulation: localData?.data?.createDirSportEventRegulations?.id,
            idUser: localStorage.getItem('userId'),
          },
        });
      }
    }
    await notify({
      variables: {
        notificationTypeNumber,
        calendarName,
        calendarId: id,
        sportId,
        sportName,
      },
    });
    if (docPath === 'regulationsAgreedDocument') {
      enqueueSnackbar('Уведомление специалистам Федерации о добавлении согласованного регламента отправлено', {
        variant: 'success',
      });
    }
    await refetch();
    return;
  };

  const onRemoveDocument = async (docPath: string) => {
    await update({
      variables: {
        id: regulation?.id,
        data: {
          [docPath]: {
            disconnect: true,
          },
        },
      },
    });
    await refetch();
    return;
  };

  const r_only = isSportingEventOperatorFspcr ? false : readonly;

  if (loading) return <LinearProgress />;

  return (
    <div style={{ width: '100%' }}>
      <Status
        regulation={regulation}
        onlyWord
        Header={
          <Box>
            <BtnDocument
              doc={regulation?.regulationsDocument}
              onAdd={onAddNewDocument}
              onRemove={onRemoveDocument}
              docPath={'regulationsDocument'}
              notificationTypeNumber={'1'}
              title="Регламент исходник"
              readonly={readonly}
              user={
                data?.dirSportEventRegulationses?.[0]?.fcpsrAddRegulationsDoc?.email
                  ? {
                      ...data?.dirSportEventRegulationses?.[0]?.fcpsrAddRegulationsDoc?.regular,
                      email: data?.dirSportEventRegulationses?.[0]?.fcpsrAddRegulationsDoc?.email,
                    }
                  : null
              }
            />
            <BtnDocument
              doc={regulation?.regulationsAmendedDocument}
              onAdd={onAddNewDocument}
              onRemove={onRemoveDocument}
              docPath={'regulationsAmendedDocument'}
              notificationTypeNumber={'2'}
              title="Регламент измененный федерацией"
              readonly={r_only}
            />
            <BtnDocument
              doc={regulation?.regulationsAgreedDocument}
              onAdd={onAddNewDocument}
              onRemove={onRemoveDocument}
              docPath={'regulationsAgreedDocument'}
              notificationTypeNumber={'3'}
              title="Регламент согласованный"
              readonly={r_only}
            />
          </Box>
        }
        id={id}
        sportId={sportId}
        readonly={readonly}
        onUpdate={() => refetch()}
      />
    </div>
  );
};

export const Regulations: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const classes = useStyles();
  const { isSportingEventOperatorFspcr, regularOrg } = userRights();
  const [active, setActive] = useState('');

  const { data, loading } = useCalendarsSportsQuery({
    skip: !id || id === 'new',
    variables: { id },
    fetchPolicy: 'no-cache',
  });

  const regularOrgSports = regularOrg?.sports?.map((s) => s?.dirSport?.id) || [];
  const regularOrgCategoriesRNumbers = regularOrg?.clsOrganizationCategories?.map((c) => c?.registryNumber) || [];

  const sportData = useMemo(
    () =>
      isSportingEventOperatorFspcr
        ? regularOrgCategoriesRNumbers.includes(100020122)
          ? data?.dirCalendar?.dirSports?.filter((sport) => regularOrgSports.includes(sport?.id))
          : []
        : data?.dirCalendar?.dirSports,
    [data, isSportingEventOperatorFspcr, regularOrgSports, regularOrgCategoriesRNumbers],
  );

  if (loading) return <LinearProgress />;
  return (
    <div style={{ width: '100%' }}>
      {sportData?.map((sport) => (
        <Accordion
          key={sport.id}
          expanded={active === sport.fullName}
          onChange={() => setActive(active === sport.fullName ? '' : sport.fullName)}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`sport1-content`} id={`sport1-header`}>
            <Typography className={classes.head} color="primary">
              {sport.fullName}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {active === sport.fullName && (
              <RegulationAccordion
                id={id}
                sportId={sport?.id}
                calendarName={data?.dirCalendar?.fullName}
                sportName={sport?.fullName}
                readonly={readonly}
              />
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};
