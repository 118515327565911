import { Button, Grid, IconButton, Typography } from '@material-ui/core';
import React, { FC, useState } from 'react';
import { UpdateRefereeSportMutationHookResult, useUpdateRefereeSportMutation } from '../../../../api';

import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import { Input } from '../../../Inputs';
import SaveIcon from '@material-ui/icons/Save';
import getMessage from '../../../../messages';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

const WorkExperience: FC<{
  data?: string;
  id: string;
  refetch: any;
  readOnly?: boolean;
}> = ({ data, id, refetch, readOnly }) => {
  const { handleSubmit, control, errors, formState, reset } = useForm({
    defaultValues: { workExperience: data || '' },
  });
  const [isEditing, setIsEditing] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [updateSportData]: UpdateRefereeSportMutationHookResult = useUpdateRefereeSportMutation();

  const onUpdateWorkExperience = async (values: any) => {
    const { workExperience } = values;
    try {
      await updateSportData({
        variables: {
          data: {
            workExperience: parseInt(workExperience, 10),
          },
          id,
        },
      });

      enqueueSnackbar(`Стаж Судейской деятельности успешно обновлено`, { variant: 'success' });
      refetch();
      setIsEditing(false);
    } catch (error) {
      enqueueSnackbar(getMessage(error.message), { variant: 'error' });
    }
  };

  return (
    <Grid container>
      {isEditing ? (
        <Grid item container md={7}>
          <form onSubmit={handleSubmit(onUpdateWorkExperience)}>
            <Grid container alignItems={'flex-end'} spacing={1}>
              <Grid>
                <Input
                  label="Стаж Судейской деятельности"
                  control={control}
                  error={!!errors['workExperience']}
                  name="workExperience"
                  rules={{
                    required: true,
                    pattern: {
                      value: /^[0-9]*$/gm,
                      message: 'только цифры доступны',
                    },
                  }}
                  errorMessage={errors['workExperience']?.message}
                  style={{ width: 235 }}
                />
              </Grid>
              <Grid item>
                <Button
                  aria-label="add"
                  type="submit"
                  size={'small'}
                  color={'primary'}
                  variant={'outlined'}
                  disabled={!formState.isDirty}
                  startIcon={<SaveIcon />}
                >
                  Сохранить
                </Button>
              </Grid>
              <Grid item>
                <Button
                  aria-label="delete"
                  type="button"
                  size={'small'}
                  color={'secondary'}
                  variant={'outlined'}
                  startIcon={<ClearIcon />}
                  onClick={() => setIsEditing(false)}
                >
                  Отменить
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      ) : (
        <Grid item container md={6} alignItems={'flex-end'}>
          <Grid item>
            <Typography>
              Стаж Судейской деятельности: <strong>{data ? ` с ${data} года` : ''}</strong>
            </Typography>{' '}
          </Grid>
          <Grid item>
            {!readOnly && (
              <IconButton
                edge="end"
                aria-label="add"
                size="small"
                onClick={() => {
                  setIsEditing(true);
                  reset();
                }}
                style={{ display: 'inline', marginLeft: 16 }}
              >
                <EditIcon color="primary" />
              </IconButton>
            )}
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default WorkExperience;
