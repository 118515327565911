import { Button, Grid } from '@material-ui/core';
import {
  ForeignCityArchiveMutationHookResult,
  ForeignCityCreateMutationHookResult,
  ForeignCityFragment,
  ForeignCityUnArchiveMutationHookResult,
  ForeignCityUpdateMutationHookResult,
  useForeignCityArchiveMutation,
  useForeignCityByIdQuery,
  useForeignCityCreateMutation,
  useForeignCityUnArchiveMutation,
  useForeignCityUpdateMutation,
} from '../../../api';
import React, { FC, useEffect } from 'react';

import ArchiveWidget from '../Shared/Archive';
import { ContentWrapperWidget } from '../../layouts';
import { Countries } from '../../../module';
import { Input } from '../../Inputs';
import SaveIcon from '@material-ui/icons/Save';
import getMessage from '../../../messages';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';

const MainInfo: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const { data, refetch } = useForeignCityByIdQuery({ variables: { id }, fetchPolicy: 'no-cache' });
  const { handleSubmit, control, errors, reset, formState } = useForm({
    defaultValues: data?.dirForeignCity as ForeignCityFragment,
  });
  const { push } = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [create]: ForeignCityCreateMutationHookResult = useForeignCityCreateMutation();
  const [archive]: ForeignCityArchiveMutationHookResult = useForeignCityArchiveMutation();
  const [unarchive]: ForeignCityUnArchiveMutationHookResult = useForeignCityUnArchiveMutation();
  const [update]: ForeignCityUpdateMutationHookResult = useForeignCityUpdateMutation();

  const onSubmit = async (values: ForeignCityFragment) => {
    try {
      if (id !== 'new') {
        await update({
          variables: {
            id,
            fullName: values.fullName,
            shortName: values.shortName,
            countryId: values.dirCountry.value ? { connect: { id: values.dirCountry.value } } : null,
          },
        });
        enqueueSnackbar(`Зарубежный город "${values?.fullName}" успешно обновлен`, { variant: 'success' });
      } else {
        const { data } = await create({
          variables: {
            fullName: values.fullName as string,
            shortName: values.shortName as string,
            countryId: { connect: { id: values.dirCountry.value } },
          },
        });
        if (data?.createDirForeignCity && data?.createDirForeignCity?.id) {
          enqueueSnackbar(`Зарубежный город "${values?.fullName}" успешно добавлен`, { variant: 'success' });
          push(`/foreign_city/${data?.createDirForeignCity?.id}`);
        }
      }
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  useEffect(() => {
    if (data?.dirForeignCity) {
      reset(data?.dirForeignCity);
    }
  }, [data, reset]);

  if (readonly)
    return (
      <Grid container spacing={1}>
        <Grid item container spacing={1}>
          <Grid item>
            <strong>Название города: </strong>
          </Grid>
          <Grid item>
            <span>{data?.dirForeignCity?.fullName}</span>
          </Grid>
        </Grid>
        <Grid item container spacing={1}>
          <Grid item>
            <strong>Краткое название: </strong>
          </Grid>
          <Grid item>
            <span>{data?.dirForeignCity?.shortName}</span>
          </Grid>
        </Grid>
        <Grid item container spacing={1}>
          <Grid item>
            <strong>Страна: </strong>
          </Grid>
          <Grid item>
            <span>{data?.dirForeignCity?.dirCountry?.label}</span>
          </Grid>
        </Grid>
      </Grid>
    );

  return (
    <>
      <Grid container>
        <Grid item lg={6} md={12} xs={12}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <ContentWrapperWidget>
              <Grid container spacing={1}>
                <Grid item md={6}>
                  <Input
                    label="Название города"
                    control={control}
                    error={!!errors['fullName']}
                    name="fullName"
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item md={6}>
                  <Input
                    label="Краткое название"
                    control={control}
                    error={!!errors['shortName']}
                    name="shortName"
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item md={6}>
                  <Countries
                    label="Страна"
                    error={!!errors['dirCountry']}
                    name="dirCountry"
                    control={control}
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item md={12}>
                  <Button
                    variant="outlined"
                    color="primary"
                    type="submit"
                    size="small"
                    startIcon={<SaveIcon color="primary" />}
                    disabled={!formState.isDirty}
                  >
                    Сохранить
                  </Button>
                </Grid>
              </Grid>
            </ContentWrapperWidget>
          </form>
        </Grid>
        {id && id !== 'new' && (
          <Grid item lg={12} md={12} xs={12}>
            <ArchiveWidget
              archive={data?.dirForeignCity?.archive || null}
              id={id}
              refetch={refetch}
              onArchive={archive}
              onUnarchive={unarchive}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default MainInfo;
