import React, { useEffect, useMemo, useState } from 'react';
import {
  InitiateMailingMutationHookResult,
  useCalendarSummaryInformationOnParticipantNumberLazyQuery,
  useFetchListSportsRequestApplicationLazyQuery,
  useGetDateQuoteRequestApplicationLazyQuery,
  useInitiateMailingMutation,
} from '../../../../../api';
import { RequestApplicationModalQuotes } from './RequestApplicationModalQuotes';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Grid,
  Button,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import _ from 'lodash';
import userRights from '../../../../../config/userRights';
import { RequestApplicationModalDate } from './RequestApplicationModalDate';
import { RequestApplicationRegionList } from './RequestApplicationRegionList';
import CircularProgress from '@material-ui/core/CircularProgress';
import moment from 'moment/moment';
import { fetchSummaryInformationOnTheNumberOfParticipantsReport } from '../../../../BtnReport/services/fetchSummaryInformationOnTheNumberOfParticipantsReport';
import { useSnackbar } from 'notistack';
import getMessage from '../../../../../messages';

export const RequestApplication = ({
  id,
  calendarName = '',
  sportsFiltered = [],
  calendarDateAcceptQuotasAndFilesStageTwo,
}) => {
  const { isRoiv, isAdminOrSpecialist, userEmail } = userRights();
  const { enqueueSnackbar } = useSnackbar();
  const [listSports, setListSports] = useState([]);
  const [listSportsValue, setListSportsValue] = useState([]);
  const [expand, setExpand] = useState(false);
  const [active, setActive] = useState();
  const [open, setOpen] = useState<boolean | string>(false);
  const [openDate, setOpenDate] = useState<boolean | string>(false);

  const [initiateMailing]: InitiateMailingMutationHookResult = useInitiateMailingMutation();

  const [callRequest, { data, loading, refetch }] = useFetchListSportsRequestApplicationLazyQuery({
    variables: { IdCalendar: id },
  });
  const [callRequestDate, { data: dataDate, refetch: refetchDate }] = useGetDateQuoteRequestApplicationLazyQuery({
    variables: { id },
  });

  const [
    fetchSummaryInformation,
    { data: fetchSummaryInformationData, loading: fetchSummaryInformationLoading },
  ] = useCalendarSummaryInformationOnParticipantNumberLazyQuery({
    variables: {
      id,
    },
  });

  const handleSummaryInfomation = (evt: any) => {
    evt?.stopPropagation();
    fetchSummaryInformation();
  };

  const handleInitiateEmail = async () => {
    try {
      if (id && isAdminOrSpecialist && userEmail) {
        await initiateMailing({
          variables: {
            email: userEmail,
            calendarId: id,
          },
        });
        enqueueSnackbar(`Рассылка успешно инициирована`, { variant: 'success' });
      }
    } catch (error) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  useEffect(() => {
    if (!fetchSummaryInformationLoading && fetchSummaryInformationData) {
      fetchSummaryInformationOnTheNumberOfParticipantsReport({
        data: {
          ...fetchSummaryInformationData,
        },
        calendarName,
        calendarSports: sportsFiltered,
        fileName: 'Сводная информация по количеству участников 2 этапа',
      });
    }
  }, [calendarName, fetchSummaryInformationData, fetchSummaryInformationLoading]);

  useEffect(() => {
    if (expand) {
      callRequest();
    }
  }, [callRequest, expand]);

  useEffect(() => {
    if (expand) {
      callRequestDate();
    }
  }, [callRequestDate, expand]);

  useEffect(() => {
    if (data && !loading && expand) {
      const sports = new Set();
      const params = _.keyBy(data?.quotaForStageTwoBySports, 'dirSport.id');
      data?.dirSportingEvents.forEach((sport) => {
        sport?.sports?.forEach((sp) => {
          sports.add(sp.dirSport);
        });
      });
      const list = [];
      const listData = [];
      Array.from(sports).reduce((acc, val) => {
        const current = { fullName: val.fullName, id: val.id };
        current.form = params?.[current.id] || {};
        acc.push(current);
        listData.push(val);
        return acc;
      }, list);
      setListSports(_.orderBy(listData, ['fullName'], ['asc']));
      setListSportsValue(_.orderBy(list, ['fullName'], ['asc']));
    }
  }, [loading, data, expand]);

  const canDownloadRequestApplicationReports = useMemo(
    () => (isAdminOrSpecialist || isRoiv) && sportsFiltered.length > 0,
    [isAdminOrSpecialist, isRoiv, sportsFiltered.length],
  );

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div>
      <Accordion expanded={expand} onChange={() => setExpand((v) => !v)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id={`sport1-header`}>
          <Grid container justify="space-between">
            <Grid item>
              <Typography color="primary">Запрос заявок на 2 этап</Typography>
            </Grid>
            <Grid item>
              {canDownloadRequestApplicationReports && (
                <Button onClick={handleSummaryInfomation} variant="outlined" color="primary" size="small">
                  Сводная информация по количеству участников
                </Button>
              )}
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails style={{ flexDirection: 'column' }}>
          <Grid container justify="space-between">
            <Grid item>
              Прием квот и файлов до:{' '}
              {dataDate?.dirCalendar?.dateAcceptQuotasAndFilesStageTwo &&
                moment(dataDate?.dirCalendar?.dateAcceptQuotasAndFilesStageTwo).format('DD.MM.YYYY')}{' '}
              {!isRoiv && (
                <IconButton
                  onClick={() => {
                    setOpenDate(true);
                  }}
                >
                  <EditIcon color="primary" />
                </IconButton>
              )}
            </Grid>
            <Grid item>
              {isAdminOrSpecialist && calendarDateAcceptQuotasAndFilesStageTwo && (
                <Button
                  style={{ marginRight: '35px' }}
                  onClick={handleInitiateEmail}
                  variant="outlined"
                  color="primary"
                  size="small"
                  disabled={!userEmail}
                >
                  Инициировать рассылку
                </Button>
              )}
            </Grid>
          </Grid>
          {/* <div>
            Прием квот и файлов до:{' '}
            {dataDate?.dirCalendar?.dateAcceptQuotasAndFilesStageTwo &&
              moment(dataDate?.dirCalendar?.dateAcceptQuotasAndFilesStageTwo).format('DD.MM.YYYY')}{' '}
            {!isRoiv && (
              <IconButton
                onClick={() => {
                  setOpenDate(true);
                }}
              >
                <EditIcon color="primary" />
              </IconButton>
            )}
          </div> */}
          <TableContainer style={{ width: 650, marginBottom: 8 }}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell align="right">Квота М</TableCell>
                  <TableCell align="right">Квота Ж</TableCell>
                  <TableCell align="right">Квота Сопр</TableCell>
                  <TableCell align="right"></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listSportsValue.map((row) => (
                  <TableRow key={row?.id}>
                    <TableCell component="th" scope="row">
                      {row?.fullName}
                    </TableCell>
                    <TableCell align="right">{row?.form?.quotaMaleAthlStageTwo || 0}</TableCell>
                    <TableCell align="right">{row?.form?.quotaWomenAthlStageTwo || 0}</TableCell>
                    <TableCell align="right">{row?.form?.quotaAccompStageTwo || 0}</TableCell>
                    <TableCell align="right">
                      {!isRoiv && (
                        <IconButton
                          onClick={() => {
                            setOpen(true);
                            setActive(row);
                          }}
                        >
                          <EditIcon color="primary" />
                        </IconButton>
                      )}{' '}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <RequestApplicationRegionList
            calendarName={calendarName}
            canDownloadReport={canDownloadRequestApplicationReports}
            listSports={listSports}
            id={id}
            listSportsValue={listSportsValue}
            date={dataDate?.dirCalendar?.dateAcceptQuotasAndFilesStageTwo}
          />
          <RequestApplicationModalQuotes
            onUpdate={() => {
              refetch();
            }}
            onClose={() => {
              setOpen(false);
            }}
            idSport={active?.id}
            row={active || {}}
            sportName={active?.fullName}
            idCalendar={id}
            open={open}
          />
          <RequestApplicationModalDate
            onUpdate={() => {
              refetchDate?.();
            }}
            onClose={() => {
              setOpenDate(false);
            }}
            date={dataDate?.dirCalendar?.dateAcceptQuotasAndFilesStageTwo || null}
            idCalendar={id}
            open={openDate}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
