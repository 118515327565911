import { Button, Dialog, DialogTitle, Grid, IconButton, LinearProgress, Link, Tooltip } from '@material-ui/core';
import { DatePicker, Input } from '../../../Inputs';
import React, { FC, useMemo, useState } from 'react';
import {
  Scalars,
  SportingEventDocument,
  UpdateSportingEventMutationHookResult,
  UploadFileMutationHookResult,
  useSportingEventProtocolDocQuery,
  useUpdateSportingEventMutation,
  useUploadFileMutation,
} from '../../../../api';

import ConfirmDialog from '../../../ConfirmDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import DocumentRTypes from '../../../../module/DocumentRTypes';
import { DropzoneArea } from 'material-ui-dropzone';
import SaveIcon from '@material-ui/icons/Save';
import getMessage from '../../../../messages';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles({
  title: {
    fontWeight: 500,
  },
  link: {
    cursor: 'pointer',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  icon: {
    fontSize: 20,
  },
  verticalSpaceMarge: {
    marginBottom: 35,
    padding: 0,
  },
  dropZone: {
    minHeight: 30,
    border: 0,
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    borderRadius: 0,

    '& > .MuiDropzonePreviewList-root': {
      margin: '0 !important',
      width: '100%',
      '& > .MuiDropzonePreviewList-imageContainer': {
        flexBasis: 'unset',
        width: '100%',
        maxWidth: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 15,
        '& > svg': {
          height: '40px',
        },
        '& > p': {
          marginLeft: 15,
          marginRight: 15,
        },
        '& > button': {
          top: 'unset',
          right: 0,
          position: 'unset',
          marginLeft: 30,

          color: '#d32f2f',
          boxShadow: 'unset',
        },
      },
    },
    '& > .MuiDropzoneArea-textContainer': {
      display: 'flex',

      '& > .MuiDropzoneArea-text': {
        margin: 0,
        fontSize: 15,
      },
      '& > .MuiDropzoneArea-icon': {
        width: 20,
        height: 20,
        marginLeft: 'auto',
      },
    },
    '&:focus': {
      outline: 0,
      border: 0,
      borderBotom: '2px solid #3f51b5',
    },
  },
});

interface formState {
  title?: string;
  date?: Date;
  number?: string;
  clsType?: { label: string; value: number };
}

const EventProtocolDoc: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const DOWNLOAD_LINK = process.env.REACT_APP_BACKEND_URI?.replace('/graphql', '');

  const defaultNewDocumentState: formState = {
    title: undefined,
    date: undefined,
    number: undefined,
    clsType: {
      value: 100003250,
      label: 'Протокол мандатной комиссии',
    },
  };

  const { loading, data, refetch } = useSportingEventProtocolDocQuery({ returnPartialData: true, variables: { id } });

  const { handleSubmit, control, errors, reset, formState } = useForm({
    defaultValues: defaultNewDocumentState,
  });

  const [updateEventData]: UpdateSportingEventMutationHookResult = useUpdateSportingEventMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: SportingEventDocument,
        data: {
          ...data?.updateDirSportingEvent,
        },
      });
    },
  });

  const [uploadDocumentFile]: UploadFileMutationHookResult = useUploadFileMutation();
  const [deleteItem, setDeleteItem] = useState<any>(null);

  const credentialsCommitteeProtocolDocuments = useMemo(
    () => data?.dirSportingEvent?.credentialsCommitteeProtocolDocuments || [],
    [data],
  );

  const [isAddingNEwDocumentFromSystem, setIsAddingNEwDocumentFromSystem] = useState(false);
  const [file, setFile] = useState<Array<File>>([]);

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const handleAddNewDocumentFromSystem = async (values: formState) => {
    const { title, date, number, clsType } = values;
    try {
      if (isAddingNEwDocumentFromSystem && title && date && file) {
        uploadDocumentFile({
          variables: {
            file: file[0],
          },
        }).then(async (resp: any) => {
          const {
            uploadFile: { id: fileId },
          } = resp.data;
          await updateEventData({
            variables: {
              data: {
                credentialsCommitteeProtocolDocuments: {
                  create: [
                    {
                      title,
                      date,
                      number,
                      clsType: clsType && { connect: { registryNumber: clsType?.value } },
                      file: {
                        connect: { id: fileId },
                      },
                    },
                  ],
                },
              },
              id,
            },
          });
          handleSnackBar('success', 'документ успешно добавлен');
          setIsAddingNEwDocumentFromSystem(false);
          reset(defaultNewDocumentState);
          refetch();
        });
      }
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const deleteDocument = async (documentId: Scalars['UUID']) => {
    try {
      await updateEventData({
        variables: {
          data: {
            credentialsCommitteeProtocolDocuments: {
              disconnect: [
                {
                  id: documentId,
                },
              ],
            },
          },
          id,
        },
      });
      handleSnackBar('success', 'документ успешно удален');
      refetch();
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const classes = useStyles();

  if (loading) return <LinearProgress color="primary" variant="query" />;

  return (
    <div>
      <Grid container className={classes.verticalSpaceMarge}>
        <Grid style={{ marginBottom: 15 }} item container justify="flex-end">
          {!readonly ? (
            <Button
              variant="outlined"
              color="primary"
              size="small"
              type="button"
              onClick={() => {
                setIsAddingNEwDocumentFromSystem(true);
              }}
            >
              Загрузить протокол мандатной комиссии
            </Button>
          ) : (
            <span className={classes.title}>Протокол мандатной комиссии:&nbsp;</span>
          )}
        </Grid>
        {credentialsCommitteeProtocolDocuments?.map((item: any) => (
          <Grid item container alignItems="center" justify="flex-end" spacing={2} key={item.id}>
            <Grid item>
              <Link href={`${DOWNLOAD_LINK}${item.file.path}`} className={classes.link}>
                {item.title} {item.date && moment(item.date).format('DD.MM.YYYY')} {item.number} {item?.clsType?.label}
              </Link>
            </Grid>
            {!readonly && (
              <Grid item>
                <Tooltip title="удалить" placement="top-start">
                  <IconButton className={classes.btn} color="secondary" onClick={() => setDeleteItem(item.id)}>
                    <DeleteIcon className={classes.icon} />
                  </IconButton>
                </Tooltip>
                <ConfirmDialog
                  title={`Удаление протокола мандатной комиссии`}
                  text={`Вы уверены что хотите удалить документ от :  
                      ${moment(item.date).format('DD.MM.YYYY')}`}
                  btnVariant="outlined"
                  open={deleteItem === item.id}
                  saveBtnText="удалить"
                  cancelBtnText="отмена"
                  saveBtnColor="secondary"
                  cancelBtnColor="primary"
                  onCancel={() => setDeleteItem(null)}
                  onSave={() => {
                    deleteDocument(item.id).then(() => setDeleteItem(null));
                  }}
                />
              </Grid>
            )}
          </Grid>
        ))}
      </Grid>
      <Dialog
        open={isAddingNEwDocumentFromSystem}
        onClose={() => {
          setIsAddingNEwDocumentFromSystem(false);
          reset(defaultNewDocumentState);
        }}
        maxWidth="xs"
      >
        <DialogTitle id="add-document-dialog">Новый Документ</DialogTitle>
        <div style={{ padding: '1.5rem' }}>
          <form onSubmit={handleSubmit(handleAddNewDocumentFromSystem)} style={{ width: '100%' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Input
                  label="Название документа"
                  control={control}
                  error={!!errors['title']}
                  name="title"
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <DocumentRTypes
                  label="Тип документа"
                  error={!!errors['clsType']}
                  control={control}
                  rules={{ required: true }}
                  name="clsType"
                />
              </Grid>
              <Grid item xs={12}>
                <DatePicker
                  fullWidth
                  label="Дата"
                  control={control}
                  error={!!errors['date']}
                  name="date"
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <Input label="Номер документа" control={control} error={!!errors['number']} name="number" />
              </Grid>
              <Grid item xs={12}>
                <DropzoneArea
                  showFileNames
                  alertSnackbarProps={{
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                  }}
                  dropzoneClass={classes.dropZone}
                  showAlerts={['error']}
                  getFileAddedMessage={(fileName: string) => `Файл ${fileName} успешно загружено`}
                  getDropRejectMessage={(file: File) =>
                    `Файл ${file.name} отклонен. Тип файла не поддерживается. Файл слишком большой. Максимальный размер - 10 мегабайт.`
                  }
                  filesLimit={1}
                  maxFileSize={10485760}
                  clearOnUnmount
                  initialFiles={file}
                  onChange={(fileArray: File[]) => setFile(fileArray)}
                  dropzoneText="Файл"
                />
              </Grid>
              <Grid item container justify="flex-end">
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  startIcon={<SaveIcon />}
                  type="submit"
                  disabled={!(formState.isDirty && file.length > 0)}
                >
                  Добавить
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Dialog>
    </div>
  );
};

export default EventProtocolDoc;
