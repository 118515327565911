import React, { FC } from 'react';

import { ControllerProps } from 'react-hook-form/dist/types/props';
import { Select } from '../components/Inputs';
import { useTypeSportsListSelectQuery } from '../api';

interface TypeSports extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  error: boolean | undefined;
  disabled?: boolean;
  onSuperChange?: any;
}

const TypeSports: FC<TypeSports> = ({ label, error, control, name, rules, disabled, onSuperChange }) => {
  const { loading, data } = useTypeSportsListSelectQuery();
  return (
    <Select
      label={label}
      onSuperChange={onSuperChange}
      multiple
      data={data?.dirSports || []}
      loading={loading}
      control={control}
      error={error}
      name={name}
      rules={rules}
      disabled={disabled}
    />
  );
};

export default TypeSports;
