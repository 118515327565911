import React, { FC, useEffect, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { DatePickerRange, Input, Select } from '../Inputs';
import ContentWrapper from '../layouts/ContentWrapper/ContentWrapper';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import TuneIcon from '@material-ui/icons/Tune';
import { makeStyles } from '@material-ui/core/styles';
import UserRoles from '../../module/UserRoles';
import _ from 'lodash';
import {
  Maybe,
  RegionalMinsportProfile,
  RegionalMinsportProfileCreateOneInput,
  RegularUserWhereInput,
  UserRoleWhereInput,
  UserWhereInput,
} from '../../api';
import _isEmpty from 'lodash/isEmpty';
import { GenderSelect } from '../../module';
import Organizations from '../../module/Organizations';
import Regions from '../../module/Regions';

const useStyle = makeStyles((theme) => ({
  btn: {
    marginRight: theme.spacing(2),
  },
}));

const Users: FC<{ onSubmit?: (T: any, E: any) => void; value?: any }> = ({ onSubmit = () => {}, value = {} }) => {
  const style = useStyle();
  const { handleSubmit, control, errors, reset, formState, getValues } = useForm({
    defaultValues: value,
  });
  const [additional, setAdditional] = useState<boolean>(Object.keys(value)?.length > 0);

  const handleSearchBox = (value: any) => {
    console.log(value);
    const userFilter: UserWhereInput = {};
    userFilter.regular = {};

    for (let item in value) {
      if (item === 'birthday_gte' && value[item]) {
        userFilter.regular.birthday_gte = value[item];
      }
      if (item === 'birthday_lte' && value[item]) {
        userFilter.regular.birthday_lte = value[item];
      }
      if (item === 'lastname' && value[item]) {
        userFilter.regular.lastname_contains = value[item];
      }
      if (item === 'firstname' && value[item]) {
        userFilter.regular.firstname_contains = value[item];
      }
      if (item === 'patronymic' && value[item]) {
        userFilter.regular.patronymic_contains = value[item];
      }
      if (item === 'dirRegion' && value[item]) {
        userFilter.OR = [
          { regionalAdministratorProfile: { dirRegion: { id: value[item].value } } },
          { regionalMinsportProfile: { dirRegion: { id: value[item].value } } },
          { roivSpecialistProfile: { dirRegion: { id: value[item].value } } },
        ];
      }
      if (item === 'email' && value[item]) {
        userFilter.email_contains = value[item];
      }
      if (item === 'dirOrganization' && value[item] && value[item].value) {
        userFilter.regular.dirOrganization = { id: value[item].value };
      }
      if (item === 'role' && value[item]) {
        userFilter.role = { name: value[item].value };
      }
      if (item === 'isActive' && value[item]?.value) {
        if (value[item]?.value.length > 0) {
          userFilter.isActive = value[item].value === 'true';
        }
      }
      if (item === 'isMale' && value[item]) {
        userFilter.regular.isMale = value[item].value;
      }
    }

    if (_isEmpty(userFilter.regular)) {
      delete userFilter.regular;
    }

    if (_isEmpty(userFilter.role)) {
      delete userFilter.role;
    }
    onSubmit({ role: { name_not: 'PERSON' }, AND: userFilter }, getValues());
  };

  return (
    <ContentWrapper>
      <form onSubmit={handleSubmit(handleSearchBox)}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Input label="Фамилия" control={control} error={!!errors['lastname']} name="lastname" />
          </Grid>
          <Grid item xs={3}>
            <Input label="Имя" control={control} error={!!errors['firstname']} name="firstname" />
          </Grid>
          <Grid item xs={3}>
            <Input label="Отчество" control={control} error={!!errors['patronymic']} name="patronymic" />
          </Grid>
          <Grid item xs={3}>
            <Input label="Email" control={control} error={!!errors['email']} name="email" />
          </Grid>
          <Grid item xs={3}>
            <UserRoles label="Роль" control={control} error={!!errors['role']} name="role" />
          </Grid>
          <Grid item xs={3}>
            <Select
              label="Статус"
              data={[
                { label: 'Любой', value: '' },
                { label: 'Активирован', value: 'true' },
                { label: 'Заблокирован', value: 'false' },
              ]}
              control={control}
              error={!!errors['isActive']}
              name="isActive"
            />
          </Grid>

          {additional && (
            <>
              <Grid item xs={4}>
                <DatePickerRange
                  start={{ label: 'Дата рождения с', control, error: !!errors['birthday_gte'], name: 'birthday_gte' }}
                  end={{ label: 'по', control, error: !!errors['birthday_lte'], name: 'birthday_lte' }}
                />
              </Grid>
              <Grid item xs={3}>
                <Organizations
                  control={control}
                  label="Организация"
                  error={!!errors['dirOrganization']}
                  name="dirOrganization"
                />
              </Grid>
              <Grid item xs={3}>
                <Regions label="Регион" control={control} error={!!errors['dirRegion']} name="dirRegion" />
              </Grid>
              <Grid item xs={2}>
                <GenderSelect label="Пол" control={control} error={!!errors['isMale']} name="isMale" />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Button
              variant="outlined"
              className={style.btn}
              color="primary"
              type="button"
              onClick={() => {
                onSubmit({}, {});
                reset({
                  isActive: '',
                  birthday_gte: '',
                  birthday_lte: '',
                  dirOrganization: '',
                  dirRegion: '',
                  email: '',
                  firstname: '',
                  isMale: null,
                  lastname: '',
                  patronymic: '',
                  role: null,
                });
              }}
              size="small"
              startIcon={<ClearIcon />}
            >
              Сброс
            </Button>
            <Button
              variant="outlined"
              color="primary"
              type="submit"
              size="small"
              className={style.btn}
              startIcon={<SearchIcon />}
              disabled={!formState.isDirty}
            >
              Поиск
            </Button>
            <Button
              onClick={() => setAdditional(!additional)}
              type="button"
              className={style.btn}
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<TuneIcon />}
            >
              Фильтр
            </Button>
          </Grid>
        </Grid>
      </form>
    </ContentWrapper>
  );
};

export default Users;
