import React, { FC } from 'react';

import { ControllerProps } from 'react-hook-form/dist/types/props';
import { Select } from '../components/Inputs';
import { useClsEventStagesQuery } from '../api';

interface EventStages extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  error: boolean | undefined;
  disabled?: boolean;
}

const EventStages: FC<EventStages> = ({ label, error, control, name, rules, disabled }) => {
  const { data } = useClsEventStagesQuery();

  return (
    <Select
      multiple
      label={label}
      data={(data && data.classifierValues) || []}
      control={control}
      error={error}
      name={name}
      rules={rules}
      disabled={disabled}
    />
  );
};

export default EventStages;
