import { Grid, Link } from '@material-ui/core';

import React, { FC, useMemo } from 'react';
import { DirCalendar } from '../../../../api';

import DownloadIcon from '@material-ui/icons/CloudDownload';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  link: {
    cursor: 'pointer',
  },
  icon: {
    fontSize: 20,
  },
});

const CompetitionRegulationChanges: FC<{ dirCalendar?: any }> = ({ dirCalendar }) => {
  const DOWNLOAD_LINK = process.env.REACT_APP_BACKEND_URI?.replace('/graphql', '');

  const regulationChanges = useMemo(() => dirCalendar?.regulationChanges, [dirCalendar]);

  const classes = useStyles();

  return (
    <div>
      {regulationChanges?.map((item: any, index: number) => (
        <Grid container alignItems="center" spacing={2} key={item.id}>
          <Grid item>
            {index + 1}.&nbsp;
            <Link href={`${DOWNLOAD_LINK}${item.path}`} className={classes.link}>
              {item.name}
            </Link>
          </Grid>
          <Grid item>
            <DownloadIcon onClick={() => {}} />
          </Grid>
        </Grid>
      ))}
    </div>
  );
};

export default CompetitionRegulationChanges;
