export const setQueryOptions = (otherProps: any = {}, filter: any, isEvent = false, hasDoubleQuery = false) => {
  const { name, name_contains, ...otherFilters } = filter || {};

  return hasDoubleQuery && name
    ? {
        variables: { filter: otherFilters, name, ...otherProps },
        fetchPolicy: 'no-cache',
      }
    : isEvent && name_contains
    ? {
        variables: { filter: otherFilters, name: name_contains, ...otherProps },
        fetchPolicy: 'no-cache',
      }
    : {
        variables: { filter, ...otherProps },
        fetchPolicy: 'no-cache',
      };
};
