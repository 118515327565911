import React, { FC, useEffect, useMemo, useState } from 'react';
import { Grid, Button, Paper } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import {
  CreateSportingEventMutationHookResult,
  DirSportingEvent,
  DirSportingEventSport,
  Scalars,
  UpdateSportingEventMutationHookResult,
  useCreateSportingEventMutation,
  useOrganizationByOgrnLazyQuery,
  useUpdateSportingEventMutation,
} from '../../../../api';
import { setData, generateName } from './helpers';
import InfoForm from './InfoForm';
import { useForm } from 'react-hook-form';
import { availableTags } from './constants';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import moment from 'moment';

interface EventInfo {
  classes?: any;
  infoUpdated: Function;
  newCreated: Function;
  onError: Function;
  dirSportingEvent?: any;
  readonly?: boolean;
  dChanges?: string[];
}

interface dirSportInterface {
  value: Scalars['UUID'];
  label: string;
}
interface disciplineInterface {
  value: Scalars['UUID'];
  label: string;
  typeSport: {
    id: Scalars['UUID'];
  };
}

interface IREGION {
  isRussia: string;
  dirRegions?: Array<{
    value: Scalars['UUID'];
    label: string;
  }>;
  dirForeignCities?: Array<{
    value: Scalars['UUID'];
    label: string;
    country: {
      id: Scalars['UUID'];
    };
  }>;
  dirCountry?: Array<{
    value: Scalars['UUID'];
    label: string;
  }>;
}

const Info: FC<EventInfo> = ({ classes, infoUpdated, newCreated, dirSportingEvent, onError, readonly, dChanges }) => {
  const id = dirSportingEvent?.id;

  const [sportTypesAndDisciplines, setSportTypesAndDisciplines] = useState<{
    sportTypes: Array<dirSportInterface>;
    disciplinesGroup: Array<disciplineInterface>;
    disciplines: Array<disciplineInterface>;
  }>({ sportTypes: [], disciplinesGroup: [], disciplines: [] });
  const [{ isRussia, dirRegions, dirCountry, dirForeignCities }, setRegions] = useState<IREGION>({
    isRussia: 'true',
    dirRegions: [],
    dirForeignCities: [],
    dirCountry: [],
  });

  const [ageGroups, setAgeGroups] = useState<{
    groups: any;
    isAgeRestricted: boolean;
    ageGroup: string;
  }>({ groups: [], isAgeRestricted: false, ageGroup: '' });
  const [update]: UpdateSportingEventMutationHookResult = useUpdateSportingEventMutation();
  const [createNewEvent]: CreateSportingEventMutationHookResult = useCreateSportingEventMutation();

  let filter = {
    sports_some: {
      dirSport: {
        id_in:
          dirSportingEvent?.sports.map((s: DirSportingEventSport) => _.get(s, 'dirSport.value')) ||
          sportTypesAndDisciplines.sportTypes?.map((s) => s?.value),
      },
    },
    clsOrganizationCategories_some: {
      registryNumber: 100003169,
    },
    archive: null,
  };
  const [
    organizationsFetch,
    { data: organizationsData, loading: organizationsLoading },
  ] = useOrganizationByOgrnLazyQuery({
    variables: { filter },
  });

  const { enqueueSnackbar } = useSnackbar();
  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });
  const { handleSubmit, control, errors, watch, reset, getValues } = useForm({
    defaultValues: {
      ...dirSportingEvent,
      isOrganizerOrParticipant:
        dirSportingEvent?.isOrganizerOrParticipant === undefined
          ? 'false'
          : dirSportingEvent?.isOrganizerOrParticipant
          ? 'true'
          : 'false',

      dirCalendar: dirSportingEvent?.dirCalendar
        ? { ...dirSportingEvent?.dirCalendar, value: dirSportingEvent?.dirCalendar?.label }
        : { value: 'ЕКП 2021', label: 'ЕКП 2021' },
      clsDirectionality: dirSportingEvent?.clsDirectionality
        ? dirSportingEvent?.clsDirectionality
        : { value: 100002807, label: 'Спорт', __typename: 'ClassifierValue' },
      clsEventCategories: dirSportingEvent?.clsEventCategories
        ? dirSportingEvent?.clsEventCategories
        : [
            {
              registryNumber: 100002625,
              fullName: 'Первенство России',
              label: 'Первенство России',
              __typename: 'ClassifierValue',
            },
          ],
      clsSummarizingType:
        (dirSportingEvent?.clsSummarizingType && dirSportingEvent?.clsSummarizingType) ||
        (!dirSportingEvent && {
          value: 100002856,
          label: 'лично-командное',
          __typename: 'ClassifierValue',
        }),
    } as DirSportingEvent & {
      tags: Array<{ value: string; label: string }>;
    },
  });

  const handleGenerateOrg = async () => {
    const values: any = getValues();
    if (isRussia === 'false' && _.get(values, 'isOrganizerOrParticipant', '') === 'true') {
      await organizationsFetch({ variables: { filter: { ogrn: '1127746520824' } } });
    } else {
      await organizationsFetch({ variables: { filter } });
    }
  };

  useEffect(() => {
    if (!organizationsLoading) {
      const value = getValues();
      if (organizationsData?.dirOrganizations && organizationsData?.dirOrganizations.length > 0) {
        reset({
          ...value,
          organizers: organizationsData?.dirOrganizations,
        });
      }
    }
  }, [getValues, organizationsData, organizationsLoading, reset]);

  let isOrganizerOrParticipant = watch('isOrganizerOrParticipant');
  const startDate = watch('startDate');
  const endDate = watch('endDate');

  const isDateError = useMemo(
    () =>
      moment(startDate).format('DD.MM.YYYY') === moment(endDate).format('DD.MM.YYYY')
        ? false
        : moment(endDate).isBefore(moment(startDate)),
    [startDate, endDate],
  );

  const onSubmit = async (
    value: DirSportingEvent & {
      tags: Array<{ value: string; label: string }>;
    },
  ) => {
    console.log(value);

    try {
      if (id && !isDateError) {
        await update({
          variables: {
            data: {
              ...setData(
                value,
                isOrganizerOrParticipant,
                isRussia,
                dirRegions,
                dirCountry,
                dirForeignCities,
                sportTypesAndDisciplines,
                ageGroups,
              ).update,
            },
            id,
          },
        });
        await infoUpdated(true);
      } else {
        ((isRussia === 'true' && dirRegions && dirRegions?.length > 0) || isRussia === 'false') &&
          !isDateError &&
          createNewEvent({
            variables: {
              data: {
                ...setData(
                  value,
                  isOrganizerOrParticipant,
                  isRussia,
                  dirRegions,
                  dirCountry,
                  dirForeignCities,
                  sportTypesAndDisciplines,
                  ageGroups,
                ).create,
              },
            },
          }).then((resp: any) => {
            const { createDirSportingEvent } = resp.data;
            newCreated(createDirSportingEvent && createDirSportingEvent.id);
          });
      }
    } catch (error) {
      onError(error);
    }
  };

  const handleGenerateName = () => {
    const value = getValues();
    const newName = generateName(value);

    reset({
      ...value,
      name: newName.trim(),
    });
  };

  useEffect(() => {
    if (dirSportingEvent) {
      const values = getValues();
      const tags: Array<{ value: string; label: string }> = _.values(availableTags).filter(
        (tag) => dirSportingEvent[tag.value],
      );

      reset({
        ...values,
        tags,
      });
    }
  }, [dirSportingEvent, getValues, reset]);

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Grid container>
          <InfoForm
            classes={classes}
            control={control}
            dirSportingEvent={dirSportingEvent}
            onSportsBlockChanged={(value: any) => setSportTypesAndDisciplines({ ...value })}
            onAgeGroupChanged={(value: any) => setAgeGroups({ ...value })}
            errors={errors}
            readonly={readonly}
            dChanges={dChanges}
            handleSnackBar={handleSnackBar}
            sports={
              dirSportingEvent?.sports?.map((sport: any) => sport?.dirSport?.value) ||
              sportTypesAndDisciplines.sportTypes?.map((sport) => sport?.value) ||
              []
            }
            endDate={endDate}
            startDate={startDate}
            isDateError={isDateError}
            isRussia={isRussia}
            onRegionsBoxChange={({ dirRegions, isRussia, dirForeignCities, dirCountry }: IREGION) =>
              setRegions({ isRussia, dirRegions, dirForeignCities, dirCountry })
            }
            handleGenerateOrg={handleGenerateOrg}
            handleGenerateName={handleGenerateName}
          />

          <Paper elevation={3} style={{ padding: 10, margin: '10px 0', marginLeft: 'auto' }}>
            <Grid item container>
              <Button
                variant="contained"
                color="primary"
                startIcon={<SaveIcon />}
                type="submit"
                style={{ fontWeight: 600, letterSpacing: 1.2 }}
                disabled={readonly}
              >
                Сохранить все изменения
              </Button>
            </Grid>
          </Paper>
        </Grid>
      </form>
    </React.Fragment>
  );
};

export default Info;
