import React, { FC, useMemo } from 'react';

import { ControllerProps } from 'react-hook-form/dist/types/props';
import { Select } from '../components/Inputs';
import { useUniversityUsersRoivQuery, useUsersRoivQuery } from '../api';

interface ITeamsPossibleRoiv extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  error: boolean | undefined;
  disabled?: boolean;
  regionId: string;
  organizationId?: string;
  multiple?: boolean;
  defaultEmails: string[];
  isUniversityRoiv?: boolean;
}

const TeamsPossibleRoiv: FC<ITeamsPossibleRoiv> = ({
  label,
  error,
  control,
  name,
  rules,
  disabled,
  regionId,
  organizationId,
  defaultEmails,
  multiple,
  isUniversityRoiv,
}) => {
  const { data: universityUsersData } = useUniversityUsersRoivQuery({
    variables: { organizationId },
    skip: !organizationId,
    fetchPolicy: 'no-cache',
  });

  const { data } = useUsersRoivQuery({
    variables: { regionId },
    skip: !regionId,
    fetchPolicy: 'no-cache',
  });

  const defaultValue = data?.users.map((user) => defaultEmails.includes(user?.value));

  const items = useMemo(
    () => (isUniversityRoiv ? [...(universityUsersData?.users || []), ...(data?.users || [])] : data?.users),
    [data, universityUsersData, isUniversityRoiv],
  );

  return (
    <Select
      label={label}
      data={items?.map(({ value, regular }: any) => ({ value, label: `${setLabel(regular)} | ${value}` })) || []}
      control={control}
      error={error}
      name={name}
      rules={rules}
      multiple={multiple}
      disabled={disabled}
      defaultValue={defaultValue}
    />
  );
};

export default TeamsPossibleRoiv;

const setLabel = (item: any) => [item?.lastname || '', item?.firstname || '', item?.patronymic || ''].join(' ');
