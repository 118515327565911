export const USER_LOGGING_IN = 'USER_LOGGING_IN';
export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export const SET_ADDITIONAL_USER_INFO = 'SET_ADDITIONAL_USER_INFO';
export const CLEAR_JOIN = 'CLEAR_JOIN';
export const SET_PRIMARY = 'SET_PRIMARY';
export const SET_SECONDARY = 'SET_SECONDARY';
export const TRANSFER_PERSON = 'TRANSFER_PERSON';

// Save user settings for table
export const INIT_TABLE_SETTINGS = 'INIT_TABLE_SETTINGS';
export const GET_USER_SETTINGS_FILTER = 'GET_USER_SETTINGS_FILTER';
export const SET_USER_SETTINGS_FILTER = 'SET_USER_SETTINGS_FILTER';
export const RESET_USER_SETTINGS_FILTER = 'RESET_USER_SETTINGS_FILTER';
export const UPDATE_ORDER_COLUMN = 'UPDATE_ORDER_COLUMN';
export const HYDRATE_TABLE = 'HYDRATE_TABLE';
export const RESET_TABLE = 'RESET_TABLE';
export const SET_CAN_FETCH_MORE = 'SET_CAN_FETCH_MORE';
export const SET_FIRST = 'SET_FIRST';

export const GET_USER_SETTINGS_COLUMN_VISIBLE = 'GET_USER_SETTINGS_COLUMN_VISIBLE';
export const SET_USER_SETTINGS_COLUMN_VISIBLE = 'SET_USER_SETTINGS_COLUMN_VISIBLE';
export const RESET_USER_SETTINGS_COLUMN_VISIBLE = 'RESET_USER_SETTINGS_COLUMN_VISIBLE';
