import { Grid, Tooltip } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import React, { FC, useCallback, useState } from 'react';
import { getSettingsUserFilter, getSettingsUserFilterFormValue } from '../../store/selectors/settings';
import { useCalendarsLazyQuery, useCalendarsQuery } from '../../api';
import { useDispatch, useSelector } from 'react-redux';
import { DataTable } from '../../components/DataTable/NewTable';

import { AppStore } from '../../store/reducers';
import { BtnNew } from '../../components/BtnNew';
import { CalendarsFilter } from '../../components/Filters';
import { DictionaryWrapper } from '../../components/DictionaryWrapper';
import { MUIDataTableColumn } from 'mui-datatables';
import { MainTemplate } from '../../components/layouts';
import { SET_USER_SETTINGS_FILTER } from '../../store/constants';
import _ from 'lodash';
import { usePermission } from '../../hooks/usePermission';
import userRights from '../../config/userRights';
import { CalendarEventListModal } from '../../components/CalendarEventModal';

const Page: FC<{}> = () => {
  const { push } = useHistory();
  const access = usePermission('directory', 'calendars');
  const [first, setFirst] = useState(0);
  const dispatch = useDispatch();
  const filter: any = useSelector((state: AppStore) => getSettingsUserFilter(state, 'сalendars'), _.isEqual);
  const formValue: any = useSelector(
    (state: AppStore) => getSettingsUserFilterFormValue(state, 'сalendars'),
    _.isEqual,
  );
  const { hasAdditionalFilter } = userRights();

  const setFilter = useCallback(
    (filter, form) => {
      dispatch({
        type: SET_USER_SETTINGS_FILTER,
        payload: {
          name: 'сalendars',
          filter: filter,
          form,
        },
      });
    },
    [dispatch],
  );

  const columns: MUIDataTableColumn[] = [
    {
      name: 'info',
      label: 'Полное название',
      options: {
        filter: false,
        viewColumns: false,
        sort: false,
        customBodyRender: (info: any, tableMeta: any) => {
          const { rowData } = tableMeta;
          const name = rowData[1];
          const { id } = info || {};
          return (
            <React.Fragment>
              <Tooltip title="Перейти в карточку" placement="top-start">
                <Link
                  style={{
                    fontWeight: 500,
                    cursor: 'pointer',
                    textDecoration: 'none',
                  }}
                  to={`/calendar/${id}`}
                  target={'_blank'}
                >
                  {name}
                </Link>
              </Tooltip>
            </React.Fragment>
          );
        },
      },
    },
    {
      name: 'fullName',
      label: 'Полное название',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'shortName',
      label: 'Краткое название',
      options: {
        filter: false,
        display: true,

        sort: false,
      },
    },
    {
      name: 'year',
      label: 'Год',
      options: {
        filter: false,
        display: true,

        sort: false,
      },
    },
    {
      name: 'registryNumber',
      label: 'Реестровый номер',
      options: {
        filter: false,
        display: true,
        sort: false,
      },
    },
  ];

  return (
    <MainTemplate pageName="Календарь" url="" title="Справочник календарей">
      <CalendarsFilter
        onSubmit={(filters: any, form: any) => setFilter({ ...filters }, form)}
        first={first}
        value={formValue}
        defaultFilter={{ ...hasAdditionalFilter?.calendars }}
      />
      <DictionaryWrapper>
        {filter?.year ? (
          <CalendarEventListModal year={_.get(filter, 'year', 2024)} filter={_.omit(filter, 'year')} />
        ) : (
          <DataTable
            tableName="сalendars"
            title={
              <React.Fragment>
                <Grid container spacing={2} alignItems={'center'}>
                  <Grid item>
                    <h2>Справочник Календарей</h2>
                  </Grid>
                  {access.includes(2) && <Grid item>{<BtnNew onClick={() => push('/calendar/new')} />}</Grid>}
                </Grid>
              </React.Fragment>
            }
            columns={columns}
            lazyQuery={useCalendarsLazyQuery}
            filter={{ type: { id: 2 }, ...filter, ...hasAdditionalFilter?.calendars }}
            revealFunctionName="calendars"
          />
        )}
      </DictionaryWrapper>
    </MainTemplate>
  );
};

export default Page;
