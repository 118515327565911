import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  LinearProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';

import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

import React, { FC, useMemo, useState } from 'react';

import { useCalendarReportEventsLazyQuery, useCalendarStageQuery } from '../../api';
import userRights from '../../config/userRights';
import FirstEtape from './components/FirstEtape/FirstEtape';
import SecondEtape from './components/SecondEtape/SecondEtape';

import FinalEtape from './components/FinalEtape/FinalEtape';
import BtnReport from '../BtnReport/BtnReport';
import { TField } from '../BtnReport/types';

const useStyles = makeStyles({
  head: {
    fontWeight: 600,
    fontSize: 15,
    letterSpacing: 1.2,
    display: 'flex',
    alignItems: 'center',
  },
});

const CalendarSportingEventList: FC<{ id?: string; isEkp?: boolean; isUniverciad?: boolean; readonly?: boolean }> = ({
  id,
  readonly,
  isUniverciad,
  isEkp,
}) => {
  const { isRoiv } = userRights();
  const classes = useStyles();

  const [active, setActive] = useState('');

  const { data, loading } = useCalendarStageQuery({ variables: { id }, skip: !id });

  const getActiveValue = (newValue: string) => (newValue === active ? '' : newValue);
  const isFinal = useMemo(
    () =>
      data?.dirCalendar?.stageNumber?.fullName &&
      ['3 этапа', '3 этап'].includes(data?.dirCalendar?.stageNumber?.fullName),
    [data],
  );

  const isFirst = useMemo(
    () =>
      data?.dirCalendar?.stageNumber?.fullName &&
      (['1 этапа', '1 этап'].includes(data?.dirCalendar?.stageNumber?.fullName) ||
        data?.dirCalendar?.stageNumber?.registryNumber === 114040538),
    [data],
  );

  const selectData = isFirst
    ? [{ label: '2-й этап (Финал)', value: 100003441 }]
    : !isFinal
    ? [
        { label: '1-й этап', value: 100002914 },
        { label: '2-й этап (Финал)', value: 100003441 },
      ]
    : [
        { label: '1-й этап', value: 100002914 },
        { label: '2-й этап', value: 100002922 },
        { label: '3-й этап (Финал)', value: 100003458 },
      ];

  const fields: TField[] = [
    {
      id: '0d4cb159',
      label: 'Этап спортмероприятия',
      name: 'stageNumber',
      data: selectData,
      component: 'Select',
    },
    {
      id: id || '0d4cb1',
      label: 'Вид спорта',
      name: 'dirSport',
      component: 'CalendarSports',
    },
  ];

  const readOnly = !['ADMIN', 'FCPSR_ADMINISTRATOR', 'FCPSR_SPECIALIST'].includes(localStorage.getItem('userRole'));

  if (loading) return <LinearProgress />;

  return (
    <div style={{ width: '100%' }}>
      <Grid container justify="flex-end" style={{ marginBottom: 10 }}>
        <BtnReport
          lazyQuery={useCalendarReportEventsLazyQuery}
          fields={fields}
          filter={{
            api: {
              id,
            },
          }}
          withDialog
          label="Календарь соревнований"
          serviceApi="calendarGeneralReport"
          fileName="Календарь соревнований"
        />
      </Grid>

      {data?.dirCalendar?.stageNumber ? (
        <div>
          {!isFirst && (
            <Accordion expanded={active === 'etape1'} onChange={() => setActive(getActiveValue('etape1'))}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="I этап-content" id="Iэтап-header">
                <Typography className={classes.head} color="primary">
                  I этап
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {active === 'etape1' && (
                  <FirstEtape
                    id={id}
                    isUniverciad={isUniverciad}
                    calendarName={data?.dirCalendar?.fullName}
                    readonly={readOnly}
                  />
                )}
              </AccordionDetails>
            </Accordion>
          )}
          {isRoiv && isFinal && (
            <Accordion expanded={active === 'etape2'} onChange={() => setActive(getActiveValue('etape2'))}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="II этап (Финал)-content"
                id="IIэтап(Финал)-header"
              >
                <Typography className={classes.head} color="primary">
                  II этап
                </Typography>
              </AccordionSummary>
              <AccordionDetails>{active === 'etape2' && <SecondEtape id={id} readonly={readonly} />}</AccordionDetails>
            </Accordion>
          )}
          {!isRoiv && (
            <>
              <Accordion expanded={active === 'etape2'} onChange={() => setActive(getActiveValue('etape2'))}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="II этап (Финал)-content"
                  id="IIэтап(Финал)-header"
                >
                  <Typography className={classes.head} color="primary">
                    {'II этап ' + (isFinal ? '' : '(Финал)')}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {active === 'etape2' &&
                    (isFinal ? (
                      <SecondEtape id={id} readonly={readonly} />
                    ) : (
                      <FinalEtape stageRegistryNumber={100003441} readonly={readonly} id={id} />
                    ))}{' '}
                </AccordionDetails>
              </Accordion>
              {isFinal && (
                <Accordion expanded={active === 'etape3'} onChange={() => setActive(getActiveValue('etape3'))}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="III этап (Финал)-content"
                    id="IIIэтап(Финал)-header"
                  >
                    <Typography className={classes.head} color="primary">
                      III этап (Финал)
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {active === 'etape3' && <FinalEtape readonly={readonly} stageRegistryNumber={100003458} id={id} />}
                  </AccordionDetails>
                </Accordion>
              )}
            </>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default CalendarSportingEventList;
