import _ from 'lodash';

export const onSetOrgFilter = (value: any) => {
  const filters: any = {};

  if (value.registryNumber) {
    filters.registryNumber = Number(value.registryNumber);
  }

  if (value?.archive && value?.archive?.value !== '') {
    if (value?.archive?.value === false) {
      filters.archive = null;
    }
    if (value?.archive?.value === true) {
      filters.NOT = { archive: null };
    }
  }

  if (value.dirRegion) {
    filters.dirRegion = {
      id: value.dirRegion.value,
    };
  }
  if (value.sportType) {
    filters.sports_some = {
      dirSport: { id: value.sportType.value },
    };
  }

  if (value.organizationCategory) {
    filters.clsOrganizationCategories_some = {
      id: value.organizationCategory.value,
    };
  }
  if (value.name) {
    filters.name = value.name;
  }
  if (value.isLegalEntity && value.isLegalEntity?.value !== '') {
    filters.isLegalEntity = value.isLegalEntity?.value === 'Да';
  }
  if (value.isUniversity && value.isUniversity?.value !== '') {
    if (value.isUniversity?.value === 'true') {
      filters.isUniversity = true;
    }
  }
  if (value.isUniversity && value.isUniversity?.value === 'false') {
    filters.isUniversity = false;
  }
  if (value.ogrn) {
    filters.ogrn_contains = value.ogrn;
    // filters.isLegalEntity = true;
  }

  return filters;
};

export const onSetAthletesFilter = (value: any) => {
  const filter: any = {
    dirPerson: {},
    sports_some: {
      organizations_some: {},
    },
    medCerts_some: {},
    OR: {},
  };

  for (let item in value) {
    if (item === 'birthday_gte' && value[item]) {
      filter.dirPerson[item] = value[item];
    }
    if (item === 'birthday_lte' && value[item]) {
      filter.dirPerson[item] = value[item];
    }
    if (item === 'validUntil_gte' && value[item]) {
      filter.medCerts_some = { ...filter.medCerts_some, validUntil_gte: value[item] };
    }
    if (item === 'validUntil_lte' && value[item]) {
      filter.medCerts_some = { ...filter.medCerts_some, validUntil_lte: value[item] };
    }
    if (item === 'registryNumber' && value[item]) {
      filter.dirPerson[item] = Number(value[item]);
    }
    if (item === 'name' && value[item]) {
      filter['name'] = value[item];
    }
    if ((item === 'previousLastname' || item === 'email') && value[item]) {
      filter.dirPerson[item + '_contains'] = value[item];
    }
    if (item === 'clsStatusPerson' && value?.clsStatusPerson?.value) {
      filter.dirPerson.confirmStatus = {
        id: Number(value?.clsStatusPerson?.value),
      };
    }
    if (item === 'dirRegion' && value?.dirRegion?.value) {
      filter.dirPerson[item] = {
        id: value?.dirRegion?.value,
      };
    }
    if (item === 'dirCountry' && value?.dirCountry?.value) {
      filter.dirPerson[item] = {
        id: value?.dirCountry?.value,
      };
    }
    if (item === 'dirForeignCity' && value?.dirForeignCity?.value) {
      filter.dirPerson[item] = {
        id: value?.dirForeignCity?.value,
      };
    }
    if (item === 'dirSport' && value?.dirSport?.value) {
      filter.sports_some = {
        ...filter.sports_some,
        dirSport: {
          id: value?.dirSport?.value,
        },
      };
    }
    if (item === 'disciplineGroups' && value?.disciplineGroups?.value) {
      filter.sports_some = {
        ...filter.sports_some,
        disciplineGroups_some: {
          id: value?.disciplineGroups?.value,
        },
      };
    }

    if (item === 'clsStagePreparing' && value?.clsStagePreparing?.value) {
      filter.sports_some.organizations_some = {
        ...filter.sports_some.organizations_some,
        clsTrainingStage: {
          id: value?.clsStagePreparing?.value,
        },
      };
    }

    if (item === 'trainer' && value?.trainer?.value) {
      filter.OR = [
        {
          sports_some: {
            dirTrainers_some: {
              id: value?.trainer?.value,
            },
          },
        },
        {
          sports_some: {
            organizations_some: {
              dirTrainer: {
                id: value?.trainer?.value,
              },
            },
          },
        },
      ];
    }

    if (item === 'clsRanksAndAwards' && value?.clsRanksAndAwards?.value) {
      filter.sports_some = {
        ...filter.sports_some,
        ranksAndAwards_some: {
          clsRankAndAward: { id: value?.clsRanksAndAwards?.value },
        },
      };
    }
    if (item === 'dirOrganization' && value?.dirOrganization?.value) {
      filter.sports_some.organizations_some = {
        ...filter.sports_some.organizations_some,
        dirOrganization: {
          id: value?.dirOrganization?.value,
        },
      };
    }

    if (item === 'isSelfTraining' && value?.isSelfTraining !== '') {
      if (value?.isSelfTraining?.value === false) {
        filter.sports_some.organizations_some = {
          ...filter.sports_some.organizations_some,
          isSelfTraining_not: true,
        };
      }
      if (value?.isSelfTraining?.value === true) {
        filter.sports_some.organizations_some = {
          ...filter.sports_some.organizations_some,
          isSelfTraining: true,
        };
      }
    }
    if (item === 'isMale' && value?.isMale?.value !== '') {
      filter.dirPerson[item] = value?.isMale?.value;
    }

    if (item === 'archive' && value?.archive?.value !== '') {
      if (value?.archive?.value === false) {
        filter.dirPerson[item] = null;
      }
      if (value?.archive?.value === true) {
        filter.dirPerson.NOT = {
          archive: null,
        };
      }
    }
    if (item === 'isDuplicated' && value?.isDuplicated?.value !== '') {
      if (value[item]?.value === false) {
        filter.dirPerson.primary = null;
      }
      if (value[item]?.value === true) {
        filter.dirPerson = {
          ...filter.dirPerson,
          NOT: {
            primary: null,
          },
        };
      }
    }
    if (item === 'isRussia' && value?.isRussia !== '') {
      filter.dirPerson[item] = value?.isRussia?.value;
    }

    if (item === 'guid' && value[item]) {
      filter.medCerts_some = { ...filter.medCerts_some, id: value[item] };
    }
    if (item === 'displayNumber' && value[item]) {
      filter.medCerts_some = { ...filter.medCerts_some, displayNumber: value[item] };
    }
    if (item === 'isValid' && value?.isValid !== '') {
      if (value?.isValid?.value === false) {
        filter.medCerts_some = {
          ...filter.medCerts_some,
          isValid_not: true,
        };
      }
      if (value?.isValid?.value === true) {
        filter.medCerts_some = {
          ...filter.medCerts_some,
          isValid: true,
        };
      }
    }
  }
  if (Object.keys(filter.dirPerson).length === 0) delete filter.dirPerson;
  if (Object.keys(filter.medCerts_some).length === 0) delete filter.medCerts_some;
  if (Object.keys(filter.OR).length === 0) delete filter.OR;
  if (Object.keys(filter.sports_some.organizations_some).length === 0) delete filter.sports_some.organizations_some;

  if (Object.keys(filter.sports_some).length === 0) delete filter.sports_some;

  return filter;
};

export const onSetCalendarFilter = ({
  fullName,
  year,
  clsEkpPart,
  // clsSeason,
  clsCalendarCategory,
  ekpMinsportNumber,
  archive,
}: any) => {
  const filters: any = {};

  if (year) {
    filters.year = parseInt(year?.value);
  }
  if (clsEkpPart) {
    filters.clsEkpPart = { registryNumber: clsEkpPart?.value };
  }
  // if (clsSeason) {
  //   filters.clsSeason = { registryNumber: clsSeason?.value };
  // }
  if (archive && archive?.value !== '') {
    if (archive?.value === false) {
      filters.archive = null;
    }
    if (archive?.value === true) {
      filters.NOT = {
        archive: null,
      };
    }
  }
  if (clsCalendarCategory) {
    filters.clsCalendarCategory = { registryNumber: clsCalendarCategory?.value };
  }
  if (ekpMinsportNumber !== '') {
    filters.ekpMinsportNumber = ekpMinsportNumber;
  }

  if (fullName !== '') {
    filters.OR = [
      {
        fullName_contains: fullName,
      },
      {
        fullName_contains: _.startCase(fullName),
      },
    ];
  }
  return filters;
};

export const onSetRegionsFilter = (value: any) => {
  const filters: any = {};

  for (let item in value) {
    if (item === 'fullName' && value[item] && value[item] !== '') {
      filters[item + '_contains'] = value[item] && value[item].length > 1 ? value[item].slice(1) : value[item];
    }
    if (item === 'shortName' && value[item] && value[item] !== '') {
      filters[item + '_contains'] = value[item] && value[item].length > 1 ? value[item].slice(1) : value[item];
    }
    if (item === 'dirFederalDistrict' && value[item] && value[item] !== '') {
      filters[item] = value[item] ? { id: value[item].value } : {};
    }
    if (item === 'archive' && value?.archive?.value !== '') {
      if (value?.archive?.value === false) {
        filters[item] = null;
      }
      if (value?.archive?.value === true) {
        filters.NOT = {
          archive: null,
        };
      }
    }
  }

  return filters;
};

export const onSetPersonFilter = (value: any) => {
  const filter: any = {};

  for (let item in value) {
    if (item === 'registryNumber' && value[item]) {
      filter[item] = Number(value[item]);
    }
    if (item === 'name' && value[item]) {
      filter['name'] = value[item];
    }
    if (item === 'previousLastname' && value[item]) {
      filter[item + '_contains'] = value[item];
    }
    if (item === 'birthday_gte' && value[item]) {
      filter[item] = value[item];
    }
    if (item === 'birthday_lte' && value[item]) {
      filter[item] = value[item];
    }
    if (item === 'isMale' && value?.isMale?.value !== '') {
      filter[item] = value?.isMale?.value;
    }
    if (item === 'email' && value[item]) {
      filter[item + '_contains'] = value[item];
    }
    if (item === 'isRussia' && value?.isRussia !== '') {
      filter[item] = value?.isRussia?.value;
    }
    if (item === 'dirRegion' && value?.dirRegion?.value) {
      filter[item] = {
        id: value?.dirRegion?.value,
      };
    }
    if (item === 'dirCountry' && value?.dirCountry?.value) {
      filter[item] = {
        id: value?.dirCountry?.value,
      };
    }
    if (item === 'dirForeignCity' && value?.dirForeignCity?.value) {
      filter[item] = {
        id: value?.dirForeignCity?.value,
      };
    }
    if (item === 'clsStatusPerson' && value?.clsStatusPerson?.value) {
      filter.confirmStatus = {
        id: Number(value?.clsStatusPerson?.value),
      };
    }
    if (item === 'archive' && value?.archive?.value !== '') {
      if (value?.archive?.value === false) {
        filter[item] = null;
      }
      if (value?.archive?.value === true) {
        filter.NOT = {
          archive: null,
        };
      }
    }
    if (item === 'isDuplicated' && value?.isDuplicated?.value !== '') {
      if (value[item]?.value === false) {
        filter.primary = null;
      }
      if (value[item]?.value === true) {
        filter.NOT = {
          primary: null,
        };
      }
    }
  }

  return filter;
};

export const onSetTrainerFilter = (value: any) => {
  const filters: any = {
    dirPerson: {},
    sports_some: {
      organizations_some: {},
    },
  };

  for (let item in value) {
    if (item === 'birthday_gte' && value[item]) {
      filters.dirPerson.birthday_gte = value[item];
    }
    if (item === 'birthday_lte' && value[item]) {
      filters.dirPerson.birthday_lte = value[item];
    }
    if (item === 'registryNumber' && value[item]) {
      filters.dirPerson.registryNumber = +value[item];
    }
    if (item === 'name' && value[item]) {
      filters['name'] = value[item];
    }
    if ((item === 'previousLastname' || item === 'email') && value[item]) {
      filters.dirPerson[item + '_contains'] = value[item];
    }
    if (item === 'clsStatusPerson' && value[item]) {
      filters.dirPerson.confirmStatus = {
        id: Number(value?.clsStatusPerson?.value),
      };
    }
    if (item === 'dirRegion' && value[item]) {
      filters.dirPerson[item] = {
        id: value?.dirRegion?.value,
      };
    }
    if (item === 'clsPosition' && value[item]) {
      filters.sports_some.organizations_some = {
        ...filters.sports_some.organizations_some,
        dismissalDate: null,
        clsPosition: { id: value?.clsPosition?.value },
      };
    }
    if (item === 'dirSport' && value[item]) {
      filters.sports_some.dirSport = {
        id: value?.dirSport?.value,
      };
    }
    if (item === 'dirOrganization' && value[item]) {
      filters.sports_some.organizations_some = {
        ...filters.sports_some.organizations_some,
        dirOrganization: { id: value[item].value },
        dismissalDate: null,
      };
    }
    if (item === 'isMale' && value?.isMale) {
      filters.dirPerson[item] = value?.isMale?.value;
    }
    if (item === 'birthday' && value[item]) {
      filters.dirPerson[item + '_lte'] = value[item];
    }
    if (item === 'archive' && value?.archive?.value !== '') {
      if (value?.archive?.value === false) {
        filters.dirPerson[item] = null;
      }
      if (value[item].value === true) {
        filters.dirPerson.NOT = {
          archive: null,
        };
      }
    }
  }
  if (Object.keys(filters.dirPerson).length === 0) delete filters.dirPerson;
  if (
    Object.keys(filters.sports_some).length === 1 &&
    Object.keys(filters.sports_some.organizations_some).length === 0
  ) {
    delete filters.sports_some.organizations_some;
    delete filters.sports_some;
  }

  return filters;
};

export const onSetRefereeFilter = (value: any) => {
  const filter: any = {
    dirPerson: {},
    sports_some: {
      organizations_some: {},
      categories_some: {},
    },
    OR: {},
  };

  for (let item in value) {
    if (item === 'birthday_gte' && value[item]) {
      filter.dirPerson[item] = value[item];
    }
    if (item === 'birthday_lte' && value[item]) {
      filter.dirPerson[item] = value[item];
    }

    if (item === 'registryNumber' && value[item]) {
      filter.dirPerson[item] = Number(value[item]);
    }
    if (item === 'name' && value[item]) {
      filter['name'] = value[item];
      filter['search'] = value[item];
    }
    if ((item === 'previousLastname' || item === 'email') && value[item]) {
      filter.dirPerson[item + '_contains'] = value[item];
    }
    if (item === 'clsStatusPerson' && value?.clsStatusPerson?.value) {
      filter.dirPerson.confirmStatus = {
        id: Number(value?.clsStatusPerson?.value),
      };
    }
    if (item === 'dirRegion' && value?.dirRegion?.value) {
      filter.dirPerson[item] = {
        id: value?.dirRegion?.value,
      };
    }
    if (item === 'dirCountry' && value?.dirCountry?.value) {
      filter.dirPerson[item] = {
        id: value?.dirCountry?.value,
      };
    }
    if (item === 'dirForeignCity' && value?.dirForeignCity?.value) {
      filter.dirPerson[item] = {
        id: value?.dirForeignCity?.value,
      };
    }
    if (item === 'dirSport' && value?.dirSport?.value) {
      filter.sports_some = {
        ...filter.sports_some,
        dirSport: {
          id: value?.dirSport?.value,
        },
      };
    }

    if (item === 'clsRefereeCategory' && value?.clsRefereeCategory?.value) {
      filter.sports_some = {
        ...filter.sports_some,
        categories_some: {
          clsRefereeCategory: {
            id: value?.clsRefereeCategory?.value,
          },
        },
      };
    }
    if (item === 'dirOrganization' && value?.dirOrganization?.value) {
      filter.sports_some.organizations_some = {
        ...filter.sports_some.organizations_some,
        dirOrganization: {
          id: value?.dirOrganization?.value,
        },
      };
    }

    if (item === 'isSelfTraining' && value?.isSelfTraining) {
      if (value?.isSelfTraining?.value === false) {
        filter.sports_some.organizations_some = {
          ...filter.sports_some.organizations_some,
          isSelfTraining_not: true,
        };
      }
      if (value?.isSelfTraining?.value === true) {
        filter.sports_some.organizations_some = {
          ...filter.sports_some.organizations_some,
          isSelfTraining: true,
        };
      }
    }
    if (item === 'isMale' && value?.isMale?.value !== '') {
      filter.dirPerson[item] = value?.isMale?.value;
    }

    if (item === 'archive' && value?.archive) {
      if (value?.archive?.value === false) {
        filter.dirPerson[item] = null;
      }
      if (value?.archive?.value === true) {
        filter.dirPerson.NOT = {
          archive: null,
        };
      }
    }
    if (item === 'isDuplicated' && value?.isDuplicated?.value !== '') {
      if (value[item]?.value === false) {
        filter.dirPerson.primary = null;
      }
      if (value[item]?.value === true) {
        filter.dirPerson = {
          ...filter.dirPerson,
          NOT: {
            primary: null,
          },
        };
      }
    }
    if (item === 'isRussia' && value?.isRussia) {
      filter.dirPerson[item] = value?.isRussia?.value;
    }
  }
  if (Object.keys(filter.dirPerson).length === 0) delete filter.dirPerson;
  if (Object.keys(filter.OR).length === 0) delete filter.OR;
  if (Object.keys(filter.sports_some.organizations_some).length === 0) delete filter.sports_some.organizations_some;
  if (Object.keys(filter.sports_some.categories_some).length === 0) delete filter.sports_some.categories_some;

  if (Object.keys(filter.sports_some).length === 0) delete filter.sports_some;

  return filter;
};

export const onSetNationalTeamFilter = (value: any) => {
  const filters: any = {
    dirSportingEvent: {},
    status: {},
  };

  if (value.registryNumber) {
    filters.registryNumber = Number(value.registryNumber);
  }
  if (value.dirRegion) {
    filters.dirRegion = {
      id: value.dirRegion.value,
    };
  }
  if (value.sportType) {
    filters.dirSportingEvent.sports_some = {
      ...filters.dirSportingEvent?.sports_some,
      dirSport: {
        id: value.sportType.value,
      },
    };
  }
  if (value.event) {
    filters.dirSportingEvent.id = value.event.value;
  }
  if (value.activityRegistryNumber) {
    filters.dirSportingEvent.registryNumber = Number(value.activityRegistryNumber);
  }
  if (value.dealStatus) {
    filters.statuses_some = {
      isActive: true,
      value: {
        id: value.dealStatus.value,
      },
    };
  }

  if (value.archive !== undefined) {
    if (value?.archive?.value === false) {
      filters['archive'] = null;
    }
    if (value?.archive?.value === true) {
      filters.NOT = {
        archive: null,
      };
    }
  }
  if (Object.keys(filters.dirSportingEvent).length === 0) delete filters.dirSportingEvent;
  if (Object.keys(filters.status).length === 0) delete filters.status;

  return filters;
};

export const onSetSportingEventFilter = (value: any) => {
  const filters: any = {};

  if (value.name) {
    filters.name_contains = value.name;
  }
  if (value.sportType) {
    filters.sports_some = {
      dirSport: {
        id: value.sportType.value,
      },
    };
  }
  if (value.dirCalendar) {
    filters.dirCalendar = {
      id: value.dirCalendar.value,
    };
  }
  if (value.status) {
    filters.statuses_some = {
      isActive: true,
      value: {
        id: value.status.value,
      },
    };
  }
  if (value.step && value.step?.value) {
    filters.clsEventStages_some = {
      id: value.step?.value,
    };
  }
  if (value?.archive?.value !== '') {
    if (value?.archive?.value === false) {
      filters.archive = null;
    }
    if (value?.archive?.value === true) {
      delete filters.archive;
      filters.NOT = {
        archive: null,
      };
    }
  }

  return filters;
};

export const onSetTypeSportsFilter = (value: any) => {
  const filters: any = {};
  for (let item in value) {
    if (item === 'registryNumber' && value[item] && value[item] !== '') {
      filters[item] = Number(value[item]);
    }
    if (
      item === 'clsRanksAndAwards' &&
      value?.clsRanksAndAwards?.value !== '' &&
      value?.clsRanksAndAwards?.value !== undefined
    ) {
      filters[item + '_some'] = {
        id: value?.clsRanksAndAwards?.value,
      };
    }
    if (item === 'ageGroups' && value?.ageGroups?.value !== '' && value?.ageGroups?.value !== undefined) {
      filters[item + '_some'] = {
        clsAgeGroup: {
          id: value?.ageGroups?.value,
        },
      };
    }
    if (
      item === 'clsSportAndDisciplineGroups' &&
      value?.clsSportAndDisciplineGroups?.value !== '' &&
      value?.clsSportAndDisciplineGroups?.value !== undefined
    ) {
      filters[item + '_some'] = {
        id: value?.clsSportAndDisciplineGroups?.value,
      };
    }
    if ((item === 'disciplines' || item === 'disciplineGroups') && value[item] && value[item] !== '') {
      filters[item + '_some'] = {
        name_contains: value[item] && value[item].length > 1 ? value[item].slice(1) : value[item],
      };
    }
    if (item === 'archive' && value[item] !== '') {
      if (value[item].value === false) {
        filters[item] = null;
      }
      if (value[item].value === true) {
        filters.NOT = {
          archive: null,
        };
      }
    }
    if (item === 'evsk' && value[item] && value[item] !== '') {
      filters[item + '_contains'] = value[item].toUpperCase();
    }
    if (item === 'fullName' && value[item] && value[item] !== '') {
      filters[item + '_contains'] = value[item] && value[item].length > 1 ? value[item].slice(1) : value[item];
    }
  }
  return filters;
};

export const onSetMinsportFilter = (value: any) => {
  const filters: any = {
    dirSportingEvent: {},
    status: {},
    archive: null,
  };
  if (value.startDate) {
    filters.startDate_gt = value.startDate;
  }
  if (value.endDate) {
    filters.endDate_lt = value.endDate;
  }
  if (value.isImportant) {
    filters.isImportant = value.isImportant;
  }
  if (value.isOlympicGamesPreparation) {
    filters.isOlympicGamesPreparation = value.isOlympicGamesPreparation;
  }
  if (value.federalBudgetFinancing) {
    filters.federalBudgetFinancing = value.federalBudgetFinancing;
  }
  if (value.startChangeDate) {
    filters.updatedAt_gt = value.startChangeDate;
  }
  if (value.endChangeDate) {
    filters.updatedAt_lt = value.endChangeDate;
  }
  if (value.registryNumber) {
    filters.registryNumber = Number(value.registryNumber);
  }
  if (value.oldRegistryNumber) {
    filters.oldRegistryNumber = Number(value.oldRegistryNumber);
  }
  if (value.dirCalendar) {
    filters.dirCalendar = {
      id: value.dirCalendar.value,
    };
  }
  if (value.request) {
    filters.requests_some = {
      statuses_some: {
        isActive: true,
        value: {
          id: value.request.value,
        },
      },
    };
  }
  if (value.dirOrganization) {
    filters.organizers_some = {
      id: value.dirOrganization.value,
    };
  }
  if (value.venue) {
    filters.venue_contains = value.venue;
  }
  if (value.dirRegion) {
    filters.dirRegions_some = {
      id: value.dirRegion.value,
    };
  }
  if (value.dirCountry) {
    filters.dirCountry = {
      id: value.dirCountry.value,
    };
  }
  if (value.dirForeignCity) {
    filters.dirForeignCity = {
      id: value.dirForeignCity.value,
    };
  }
  if (value.sportType) {
    filters.sports_some = {
      ...filters.sports_some,
      dirSport: {
        id: value.sportType.value,
      },
    };
  }
  if (value.sportStatusEkp) {
    filters.sports_some = {
      ...filters.sports_some,
      event: {
        id: value.sportStatusEkp.value,
      },
    };
  }
  if (value.activityName) {
    filters.name_contains = value.activityName;
  }
  if (value.activityRegistryNumber) {
    filters.registryNumber = Number(value.activityRegistryNumber);
  }
  if (value.status) {
    filters.status = {
      id: value?.status.value,
    };
  }
  if (value?.archive?.value !== '') {
    if (value?.archive?.value === false) {
      filters.archive = null;
    }
    if (value?.archive?.value === true) {
      delete filters.archive;
      filters.NOT = {
        archive: null,
      };
    }
  }
  if (value?.structure?.value !== '') {
    if (value?.structure?.value === 'youth') {
      filters.ageGroups_some = {
        clsAgeGroup: {
          registryNumber_not_in: [100000835, 100000744],
        },
      };
    }
    if (value?.structure?.value === 'elders') {
      filters.ageGroups_some = {
        clsAgeGroup: {
          registryNumber_in: [100000835, 100000744],
        },
      };
    }
  }
  if (Object.keys(filters.dirSportingEvent).length === 0) delete filters.dirSportingEvent;
  if (Object.keys(filters.status).length === 0) delete filters.status;

  return filters;
};
