import Xlsx from 'exceljs';

import { saveAs } from 'file-saver';
import _ from 'lodash';

export type FetchParticipantsGeneralReportProps = {
  data: Data;
  fileName: string;
  isUniverciad?: boolean;
};

export type Data = any[];

export const fetchParticipantsGeneralReport: (props: FetchParticipantsGeneralReportProps) => void = async (props) => {
  try {
    const { data = [], fileName, isUniverciad } = props;
    const mergeStart = 2;
    const lastTableColumnLetter = 'M';

    const workbook = new Xlsx.Workbook();
    workbook.addWorksheet();

    const saveFile = async () => {
      const buffer = await workbook.xlsx.writeBuffer({
        useStyles: true,
      });
      const blob = new Blob([buffer], { type: 'applicationi/xlsx' });
      fileName && saveAs(blob, `${fileName}.xlsx`);
    };

    const sheet = workbook.worksheets[0];

    const drawCurrentLastRowBorder = (lastColNumber: number) => {
      const lastTableRow = sheet.getRow(lastColNumber);

      lastTableRow.eachCell((cell) => {
        cell.style = {
          ...cell.style,
          border: {
            bottom: {
              style: 'thin',
              color: {
                argb: '000000',
              },
            },
          },
          alignment: {
            vertical: 'middle',
            horizontal: 'center',
            wrapText: true,
            shrinkToFit: true,
          },
        };
      });
    };

    // const mergeCells = (cellsPosition: string) => {
    //   sheet.unMergeCells(cellsPosition);
    //   sheet.mergeCells(cellsPosition);
    // };

    const drawLastColumnBorder = () => {
      for (let index = 0; index < tableTrack.length; index++) {
        const cellName = `${lastTableColumnLetter}${index + mergeStart}`;
        const cell = sheet.getCell(cellName);

        sheet.getCell(cellName).style = {
          ...cell.style,
          border: {
            ...cell.style.border,
            right: {
              style: 'thick',
              color: {
                argb: '000000',
              },
            },
          },
        };
      }
    };

    const tableTrack: number[] = [];

    //header
    sheet.insertRow(1, [
      'Регион РФ',
      ...(isUniverciad ? ['ВУЗ'] : []),
      'Квота м',
      'Квота ж',
      'Квота м+ж',
      'Квота сопр',
      'Квота итого',
      'Факт м',
      'Факт ж',
      'Факт сопр',
      'Факт итого',
      'Место',
      'Количество очков',
    ]);

    if (data && data.length > 0) {
      for (let index = 0; index < data.length; index++) {
        tableTrack.push(index + 1);
        const item = data[index];
        const rowIndex = mergeStart + tableTrack.length - 1;
        sheet.insertRow(rowIndex, [
          item.region,
          ...(isUniverciad ? [item?.university?.label || ''] : []),
          item.manTeamQuota || 0,
          item.womanTeamQuota || 0,
          item.athleteTotalQuota || 0,
          item.supportTeamQuota || 0,
          item.totalQuota || 0,
          item.countMen || 0,
          item.countWomen || 0,
          item.factSupport || 0,
          item.factTotal || 0,
          item.score || 0,
          item.point || 0,
        ]);
        sheet.getRow(rowIndex).eachCell((cell) => {
          cell.style = {
            ...cell.style,
            border: {
              ...cell.style.border,
              right: {
                style: 'thin',
                color: {
                  argb: '000000',
                },
              },
              bottom: {
                style: 'thin',
                color: {
                  argb: '000000',
                },
              },
            },
            alignment: {
              vertical: 'middle',
              horizontal: 'center',
            },
          };
        });
      }
    }

    drawCurrentLastRowBorder(1);
    // drawLastColumnBorder();

    //footer

    sheet.insertRow(mergeStart + tableTrack.length, [
      'Всего:',
      ...(isUniverciad ? [' '] : []),
      _.sum(data.map((item) => item?.manTeamQuota || 0)),
      _.sum(data.map((item) => item?.womanTeamQuota || 0)),
      _.sum(data.map((item) => item?.athleteTotalQuota || 0)),
      _.sum(data.map((item) => item?.supportTeamQuota || 0)),
      _.sum(data.map((item) => item?.totalQuota || 0)),
      _.sum(data.map((item) => item?.countMen || 0)),
      _.sum(data.map((item) => item?.countWomen || 0)),
      _.sum(data.map((item) => item?.factSupport || 0)),
      _.sum(data.map((item) => item?.factTotal || 0)),
    ]);
    sheet.getRow(mergeStart + tableTrack.length).eachCell((cell) => {
      cell.style = {
        ...cell.style,
        border: {
          ...cell.style.border,
          right: {
            style: 'thin',
            color: {
              argb: '000000',
            },
          },
          bottom: {
            style: 'thin',
            color: {
              argb: '000000',
            },
          },
        },
        alignment: {
          vertical: 'middle',
          horizontal: 'center',
        },
      };
    });
    sheet.getRow(1).eachCell((cell) => {
      cell.style = {
        ...cell.style,
        border: {
          ...cell.style.border,
          right: {
            style: 'thin',
            color: {
              argb: '000000',
            },
          },
        },
        alignment: {
          vertical: 'middle',
          horizontal: 'center',
        },
      };
    });
    sheet.getRow(1).eachCell((cell) => {
      cell.style = {
        ...cell.style,
        border: {
          ...cell.style.border,
          right: {
            style: 'thin',
            color: {
              argb: '000000',
            },
          },
        },
        alignment: {
          vertical: 'middle',
          horizontal: 'center',
        },
      };
    });

    await saveFile();
  } catch (error) {
    console.log('error', error);
  }
};
