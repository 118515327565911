import React, { FC } from 'react';
import { Accordion, AccordionSummary, Button, Grid, Link, AccordionDetails, Typography } from '@material-ui/core';

import TotalCountUsers from './components/TotalCountUsers';
import TotalCountUsersChilds from './components/TotalCountUsersChilds';
import MedalStandings from '../Events/components/MedalStandings';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSportingEventAnalyticsChildsQuery } from '../../../api';
import LateArrival from '../Events/components/LateArrival';

const Analytics: FC<{ readonly?: boolean; id?: string; eventName?: string; isUniverciad?: boolean }> = ({
  readonly,
  id,
  eventName,
  isUniverciad,
}) => {
  const { data } = useSportingEventAnalyticsChildsQuery({ skip: !id, variables: { id }, returnPartialData: true });
  const childs = data?.dirSportingEvent?.children || [];
  const DOWNLOAD_LINK = process.env.REACT_APP_BACKEND_URI?.replace('/graphql', '');

  return (
    <div>
      <Grid container>
        <Grid item container>
          <LateArrival id={id} isUniverciad={isUniverciad} eventName={eventName} />
        </Grid>
        <Grid item container>
          {childs && childs.length > 0 && (
            <Accordion key="Статистика по судьям" style={{ width: '100%', marginBottom: 15 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`Статистика по судьям-content`}
                id={`Статистика по судьям-header`}
              >
                <Typography
                  style={{
                    fontWeight: 600,
                    fontSize: 15,
                    letterSpacing: 1.2,
                  }}
                  color="primary"
                >
                  Статистика по судьям
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <Link href={`${DOWNLOAD_LINK}/reports/sporting-event-referees-by-category/${id}`}>
                  <Button color="primary" variant="contained">
                    Выгрузить статистику по судьям.xls
                  </Button>
                </Link>
              </AccordionDetails>
            </Accordion>
          )}
          {!childs || (childs && childs.length === 0 && <TotalCountUsers id={id} />)}
          {childs && childs.length > 0 && <TotalCountUsersChilds id={id} />}
        </Grid>
        <Grid item container>
          <MedalStandings id={id} eventName={eventName} />
        </Grid>
      </Grid>
    </div>
  );
};

export default Analytics;
