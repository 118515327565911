import Docxtemplater from 'docxtemplater';
import PizZip from 'pizzip';
import { saveAs } from 'file-saver';
import { loadFilePZip } from './helpers/loadFilePZip';
import { sortTeamPeopleRow } from './helpers/sortTeamPeopleRow';
import _ from 'lodash';
export const fetchMandatoryReport = (data: any) => {
  const { event, rows, date } = data;

  const referee = [
    _.get(event, 'referees[0].dirReferee.dirPerson.lastname', ''),
    _.get(event, 'referees[0].dirReferee.dirPerson.firstname', ''),
    _.get(event, 'referees[0].dirReferee.dirPerson.patronymic', ''),
  ]
    .filter((n) => n)
    .join(' ');

  const { manTotal, womanTotal, athletes, total } = sortTeamPeopleRow(rows, date);

  const hasUniversityLabel = athletes.find((a) => a.university) !== undefined;

  const docData = {
    date: date.format('DD.MM.YYYY'),
    eventName: event.name,
    manTotal,
    womanTotal,
    total,
    referee,
    athletes,
  };

  const path = hasUniversityLabel ? '/templates/mandatoryTemplateUniversity.docx' : '/templates/mandatoryTemplate.docx';

  loadFilePZip(path, function (error, content) {
    if (error) {
      throw error;
    }
    const zip = new PizZip(content);
    const doc = new Docxtemplater(zip, {
      paragraphLoop: true,
      linebreaks: true,
    });
    doc.render(docData);
    const blob = doc.getZip().generate({
      type: 'blob',
      mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    });
    saveAs(blob, 'Приложение_3_1_Протокол_мандатной_комиссии.docx');
  });
};
