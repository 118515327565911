import React, { FC } from 'react';

import { ControllerProps } from 'react-hook-form/dist/types/props';
import { Select } from '../components/Inputs';
import _ from 'lodash';
import { useCalendarsQuery } from '../api';

interface Organizations extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  error: boolean | undefined;
  disabled?: boolean;
  notInitialized?: boolean;
  isEkp?: Boolean;
  useFullName?: Boolean;
  isSportEvent?: Boolean;
  multiple?: boolean;
  defaultValue?: any;
  filter: any;
}

const Calendars: FC<Organizations> = ({
  label,
  error,
  control,
  name,
  multiple,
  rules,
  disabled,
  notInitialized,
  isEkp,
  useFullName,
  isSportEvent,
  defaultValue,
  filter = {},
}) => {
  const { data, loading } = useCalendarsQuery({
    ...((isEkp || isSportEvent) && { variables: { filter: { type: { id: isEkp ? 1 : 2 }, ...filter } } }),
  });

  return (
    <Select
      label={label}
      data={
        (data &&
          data.dirCalendars.map(({ id, fullName, shortName }) =>
            useFullName ? { value: fullName, label: fullName } : { value: id, label: fullName, shortName },
          )) ||
        []
      }
      control={control}
      multiple={multiple}
      error={error}
      name={name}
      rules={rules}
      loading={loading}
      disabled={disabled}
      {...(!notInitialized && { defaultValue })}
    />
  );
};

export default Calendars;
