import { get, values } from 'lodash';

import dictionary from '../utils/dictionary.json';
import moment from 'moment';
import { useEffect } from 'react';

const downloadB64File = (binary: string, fileName: string) => {
  let a = document.createElement('a');

  a.href = `data:application/xlsx;base64,${binary}`;

  a.download = `${fileName}Report.xlsx`;
  a.click();
};

const useGenerateXlsx = ({
  lazyQuery,
  getValues,
  setFilter,
  first,
  fileName,
  person,
}: {
  getValues: any;
  setFilter: any;
  lazyQuery: any;
  first: number;
  fileName: string;
  person?: boolean;
}) => {
  const [fetch, { data }] = lazyQuery({ fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (data) {
      const { xlsx } = data[Object.keys(data)[0]];
      if (xlsx) {
        downloadB64File(xlsx, fileName);
      }
    }
  }, [data]);

  const getKey = (key: string) =>
    person && key === 'name' ? 'personName' : person && key === 'registryNumber' ? 'personRegistryNumber' : key;
  const onDownloadFile = async () => {
    const formValue = getValues();
    const { search, name, ...filter } = setFilter(formValue);
    let filterString: any = {};
    for (let [key, value] of Object.entries(formValue)) {
      if (value !== undefined && value !== '' && value !== null) {
        if (typeof value === 'boolean') {
          if (key === 'isMale') filterString[key] = `${get(dictionary, key, '')}: ${value ? 'Муж' : 'Жен'}`;
          else filterString[key] = `${get(dictionary, key, '')}: ${value ? 'Да' : 'Нет'}`;
        } else if (value instanceof Date) {
          filterString[key] = `${get(dictionary, key, '')}: ${moment(value).format('DD.MM.YY')}`;
        } else if (typeof value === 'object') {
          filterString[key] = `${get(dictionary, key, '')}: ${get(value, 'label', '')}`;
        } else {
          filterString[key] = `${get(dictionary, getKey(key), '')}: ${value}`;
        }
      }
    }
    if (filterString) {
      await fetch({
        variables: {
          filterString: values(filterString)
            .filter((n) => n)
            .join(', '),
          ...(search && { search }),
          filter: { ...filter, ...(!search && { name }) },
          first,
        },
      });
    }
  };

  return {
    onDownloadFile,
  };
};

export default useGenerateXlsx;
