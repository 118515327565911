import { Button, CardHeader, Grid, LinearProgress } from '@material-ui/core';
import {
  CreateRefereeSportMutationHookResult,
  RefereeSportArchiveMutationHookResult,
  RefereeSportUnArchiveMutationHookResult,
  useCreateRefereeSportMutation,
  useRefereeByIdQuery,
  useRefereeSportArchiveMutation,
  useRefereeSportUnArchiveMutation,
} from '../../../api';
import React, { FC } from 'react';

import ArchiveComponent from '../../Dialogs/Archive/Archive';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Category from './components/Category';
import SaveIcon from '@material-ui/icons/Save';
import SportAccordion from './components/SportAccordion';
import TypeSports from '../../../module/TypeSports';
import TypeSportsSelect from '../../../module/TypeSportsSelect';
import Typography from '@material-ui/core/Typography';
import _sortBy from 'lodash/sortBy';
import getMessage from '../../../messages';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import TypeSportModule from '../../../module/TypeSports';

const RefereeCategory: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const { data, refetch, loading } = useRefereeByIdQuery({
    variables: { id },
    fetchPolicy: 'no-cache',
  });

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const [createSportData]: CreateRefereeSportMutationHookResult = useCreateRefereeSportMutation();
  const [archive]: RefereeSportArchiveMutationHookResult = useRefereeSportArchiveMutation();
  const [unarchive]: RefereeSportUnArchiveMutationHookResult = useRefereeSportUnArchiveMutation();

  const { handleSubmit, control, errors, reset, formState } = useForm({
    defaultValues: {
      typeSport: undefined,
    },
  });

  const onAddSportType = async (values: any) => {
    const { typeSport } = values;
    try {
      await createSportData({
        variables: {
          sportId: typeSport?.value,
          id,
        },
      });
      handleSnackBar('success', 'Вид спорта  успешно добавлена');
      reset();
      refetch();
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  if (loading) return <LinearProgress />;

  return (
    <div style={{ width: '100%', paddingTop: 15 }}>
      {!readonly && (
        <form onSubmit={handleSubmit(onAddSportType)} style={{ width: '100%', marginBottom: 20 }}>
          <Grid container spacing={1} alignItems="flex-end">
            <Grid item md={8}>
              <TypeSportModule
                usedValue={data?.dirReferee?.sports?.map((sport: any) => sport.dirSport.value)}
                label="Вид спорта"
                error={!!errors['typeSport']}
                name="typeSport"
                control={control}
              />
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<SaveIcon />}
                type="submit"
                disabled={!formState.isDirty}
              >
                Добавить
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
      {id && id !== 'new' && (
        <Grid md={12} style={{ marginTop: 16 }}>
          {_sortBy(data?.dirReferee?.sports, (o) => o.archive !== null)?.map((sport: any) => (
            <SportAccordion
              key={sport?.id}
              sport={sport}
              onUpdate={() => refetch()}
              refereeId={id}
              readonly={readonly}
            />
          ))}
        </Grid>
      )}
    </div>
  );
};

export default RefereeCategory;
