import React from 'react';
import { Link, Paper, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      width: '100%',
      padding: 10,
      border: '1px solid #3f51b5',
      borderLeft: '3px solid #3f51b5',
      marginBottom: 10,
    },
    text: {
      fontWeight: 'bold',
      fontSize: 13,
    },
    link: {
      fontSize: 14,
      fontWeight: 600,
      cursor: 'pointer',
      wordWrap: 'break-word',
    },
  }),
);

interface ICard {
  event?: any;
}

export const Card = ({ event }: ICard) => {
  const classes = useStyles();

  return (
    <div>
      <Paper className={classes.card} elevation={4}>
        <Typography className={classes.text}>
          {moment(event?.startDate).format('DD.MM.YYYY')} - {moment(event?.endDate).format('DD.MM.YYYY')}
        </Typography>
        <Link target="_blank" href={`/sporting_event/${event?.id}`} className={classes.link}>
          {event?.name}
        </Link>
        <Typography className={classes.text}>{event?.sports?.map((s) => s?.dirSport?.fullName).join(',')}</Typography>
        <Typography className={classes.text}>{event?.venue}</Typography>
        <Typography className={classes.text}>{event?.clsEventStages[0]?.fullName}</Typography>
      </Paper>
    </div>
  );
};
