import React, { FC } from 'react';
import { DataTable } from '../../components/DataTable/NewTable';
import { MainTemplate } from '../../components/layouts';
import { DictionaryWrapper } from '../../components/DictionaryWrapper';
import { useFederalDistrictsLazyQuery } from '../../api';
import { MUIDataTableColumn } from 'mui-datatables';
import { usePermission } from '../../hooks/usePermission';
import { useHistory } from 'react-router-dom';

const Page: FC<{}> = () => {
  const { push } = useHistory();
  const access = usePermission('directory', 'federal_district');

  const columns: MUIDataTableColumn[] = [
    {
      name: 'fullName',
      label: 'Название',
      options: {
        sort: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <React.Fragment>
              <span>{value}</span>
            </React.Fragment>
          );
        },
      },
    },
    {
      name: 'shortName',
      label: 'Краткое название',
      options: {
        sort: false,
        display: true,
      },
    },
  ];

  return (
    <MainTemplate pageName="Федеральные округа" url="" title="Федеральные округа">
      <DictionaryWrapper>
        <DataTable
          tableName="federal_district"
          title={
            <React.Fragment>
              <h2>Федеральные округа</h2>
            </React.Fragment>
          }
          columns={columns}
          lazyQuery={useFederalDistrictsLazyQuery}
          filter={null}
        />
      </DictionaryWrapper>
    </MainTemplate>
  );
};

export default Page;
