export const sortTeamPeopleRow = (rows: any[], date: any) => {
  const items: any[] = [];
  const count = {
    manTotal: 0,
    womanTotal: 0,
    total: 0,
  };

  for (const row of rows) {
    const entryData = row
      .filter((r) => r.entranceStatus && r.admissionDate && date.isSame(r.admissionDate, 'day'))
      .map((r) => ({
        ...r,
        maleSign: r.status === 'Спортсмен' && r?.isMale ? '+' : '-',
        femaleSign: r.status === 'Спортсмен' && !r?.isMale ? '+' : '-',
        isSpec: r.status !== 'Спортсмен' ? '+' : '-',
      }));
    const [primaryCoach, coach, medics, specialists, delegationHead, athletes] = [
      entryData.filter((r) => r.status === 'Главный тренер'),
      entryData.filter((r) => r.status === 'Тренер'),
      entryData.filter((r) => r.status === 'Мед. работник'),
      entryData.filter((r) => r.status === 'Специалист'),
      entryData.filter((r) => r.status === 'Руководитель делегации'),
      entryData.filter((r) => r.status === 'Спортсмен'),
    ];

    count.manTotal = count.manTotal + athletes.filter((r) => r.isMale).length;
    count.womanTotal = count.womanTotal + athletes.filter((r) => !r.isMale).length;
    count.total =
      count.total + primaryCoach.length + coach.length + medics.length + specialists.length + delegationHead.length;

    items.push([...athletes, ...primaryCoach, ...coach, ...medics, ...specialists, ...delegationHead]);
  }

  return {
    ...count,
    athletes: items.flat().map((a, index) => ({ ...a, nb: index + 1 })),
  };
};
