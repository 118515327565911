import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import { Select } from '../Inputs';
import { Controller, useForm } from 'react-hook-form';
import { DropzoneArea } from 'material-ui-dropzone';
import {
  CreateUserSupportRequestMutationHookResult,
  CreateUserSupportRequestWithoutFileMutationHookResult,
  UploadFileMutationHookResult,
  useCreateUserSupportRequestMutation,
  useCreateUserSupportRequestWithoutFileMutation,
  useUploadFileMutation,
  useUserSupportRequestTypesQuery,
} from '../../api';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles({
  btn: {
    position: 'fixed',
    right: 10,
    bottom: 10,
    zIndex: 9999,
    borderRadius: 50,
    background: '#ffffff',
    '&:hover': {
      background: '#ffffff',
    },
    '& .MuiSvgIcon-root': {
      width: '3em',
      height: '3em',
    },
  },
  dropZone: {
    minHeight: 30,
    border: 0,
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    borderRadius: 0,

    '& > .MuiDropzonePreviewList-root': {
      margin: '0 !important',
      width: '100%',
      '& > .MuiDropzonePreviewList-imageContainer': {
        flexBasis: 'unset',
        width: '100%',
        maxWidth: '100%',
        display: 'flex',
        alignItems: 'center',
        '& > svg': {
          height: '40px',
        },
        '& > p': {
          marginLeft: 15,
          marginRight: 15,
        },
        '& > button': {
          top: 'unset',
          right: 0,
          color: '#d32f2f',
          boxShadow: 'unset',
        },
      },
    },
    '& > .MuiDropzoneArea-textContainer': {
      display: 'flex',

      '& > .MuiDropzoneArea-text': {
        margin: 0,
        fontSize: 15,

        '&:after': {
          content: '"*"',
          color: 'red',
          verticalAlign: 'middle',
          marginLeft: 4,
        },
      },
      '& > .MuiDropzoneArea-icon': {
        width: 20,
        height: 20,
        marginLeft: 'auto',
      },
    },
    '&:focus': {
      outline: 0,
      border: 0,
      borderBotom: '2px solid #3f51b5',
    },
  },
});

export default function DialogRequest() {
  const [open, setOpen] = React.useState(false);
  const [upload, setUpload] = useState<null | File>(null);
  const [uploadDocumentFile]: UploadFileMutationHookResult = useUploadFileMutation();

  const classes = useStyles();
  const { data } = useUserSupportRequestTypesQuery();
  const [create]: CreateUserSupportRequestMutationHookResult = useCreateUserSupportRequestMutation();
  const [
    createWithoutFile,
  ]: CreateUserSupportRequestWithoutFileMutationHookResult = useCreateUserSupportRequestWithoutFileMutation();
  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });
  const { handleSubmit, control, errors } = useForm({
    defaultValues: { typeRequest: 'Вопрос', body: '', title: '' },
  });
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (values: any) => {
    let file = null;
    let resp = null;
    if (upload) {
      file = await uploadDocumentFile({
        variables: {
          file: upload,
        },
      });
      resp = await create({
        variables: {
          operator_id: { connect: { id: '8469be39-e6ce-4c8a-b4f1-53333063f9aa' } },
          user_id: { connect: { id: localStorage.getItem('userId') } },
          file_id: { connect: { id: file?.data?.uploadFile?.id } },
          request_type: { connect: { id: values?.typeRequest?.value } },
          text: values?.body,
        },
      });
    } else {
      resp = await createWithoutFile({
        variables: {
          operator_id: { connect: { id: '8469be39-e6ce-4c8a-b4f1-53333063f9aa' } },
          user_id: { connect: { id: localStorage.getItem('userId') } },
          request_type: { connect: { id: values?.typeRequest?.value } },
          text: values?.body,
        },
      });
    }
    if (resp) {
      handleSnackBar('success', 'Запрос в поддержку отправлен.');
      handleClose();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Button variant="outlined" color="primary" onClick={handleClickOpen} className={classes.btn}>
        <ContactSupportIcon />
      </Button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth>
        <DialogTitle id="form-dialog-title">Форма обратной связи</DialogTitle>
        <DialogContent>
          <Select
            label="Тип обращения"
            data={data?.userSupportRequestTypes || []}
            rules={{ required: true }}
            control={control}
            error={!!errors['typeRequest']}
            name="typeRequest"
          />
          <Controller
            name="body"
            control={control}
            error={!!errors['body']}
            rules={{ required: true }}
            as={
              <TextField
                multiline
                rows={5}
                label="Сообщение"
                type="text"
                fullWidth
                required
                InputProps={{
                  inputProps: {
                    min: 0,
                  },
                }}
              />
            }
          />
          <DropzoneArea
            showFileNames
            alertSnackbarProps={{
              anchorOrigin: { vertical: 'top', horizontal: 'right' },
            }}
            dropzoneClass={classes.dropZone}
            showAlerts={['error']}
            getFileAddedMessage={(fileName) => `Файл ${fileName} успешно загружено`}
            getDropRejectMessage={(file) =>
              `Файл ${file.name} отклонен. Тип файла не поддерживается. Файл слишком большой. Максимальный размер - 10 мегабайт.`
            }
            filesLimit={1}
            maxFileSize={10485760}
            onChange={(fileArray) => {
              if (fileArray.length > 0) {
                setUpload(fileArray[0]);
              }
            }}
            dropzoneText="Файл"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Закрыть
          </Button>
          <Button onClick={handleSubmit(onSubmit)} color="primary">
            Отправить
          </Button>
        </DialogActions>
      </Dialog>
    </form>
  );
}
