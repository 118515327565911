import PersonAddRoundedIcon from '@material-ui/icons/PersonAddRounded';
import SportsHandballRoundedIcon from '@material-ui/icons/SportsHandballRounded';
import TimelineRoundedIcon from '@material-ui/icons/TimelineRounded';
import SportsGolfRoundedIcon from '@material-ui/icons/SportsGolfRounded';
import HomeWorkRoundedIcon from '@material-ui/icons/HomeWorkRounded';
import PeopleRoundedIcon from '@material-ui/icons/PeopleRounded';
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';
import MapIcon from '@material-ui/icons/Map';
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';
import RoomIcon from '@material-ui/icons/Room';
import TodayIcon from '@material-ui/icons/Today';
import SportsKabaddiRoundedIcon from '@material-ui/icons/SportsKabaddiRounded';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import DescriptionIcon from '@material-ui/icons/Description';
import GavelIcon from '@material-ui/icons/Gavel';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ListAltIcon from '@material-ui/icons/ListAlt';
import organizationsBlue from '../../../../static/img/Main/icons/svg/blue/org.svg';
import calendarFcpsrBlue from '../../../../static/img/Main/icons/svg/blue/FCPSR_B.svg';
import calendarFcpsrRed from '../../../../static/img/Main/icons/svg/red/FCPSR_B.svg';
import personsBlue from '../../../../static/img/Main/icons/svg/blue/useradd.svg';
import athletesBlue from '../../../../static/img/Main/icons/svg/blue/athlete.svg';
import trainersBlue from '../../../../static/img/Main/icons/svg/blue/trainer.svg';
import refereesBlue from '../../../../static/img/Main/icons/svg/blue/referee.svg';
import national_teamsBlue from '../../../../static/img/Main/icons/svg/blue/team.svg';
import sporting_eventsBlue from '../../../../static/img/Main/icons/svg/blue/fcpsr.svg';
import documentsBlue from '../../../../static/img/Main/icons/svg/blue/doc.svg';
import minsportsBlue from '../../../../static/img/Main/icons/svg/blue/ecp.svg';
import type_sportsBlue from '../../../../static/img/Main/icons/svg/blue/evsc.svg';
import classifiersBlue from '../../../../static/img/Main/icons/svg/blue/classifier.svg';
import unification_tasksBlue from '../../../../static/img/Main/icons/svg/blue/task.svg';
import organizationsRed from '../../../../static/img/Main/icons/svg/red/org.svg';
import personsRed from '../../../../static/img/Main/icons/svg/red/useradd.svg';
import athletesRed from '../../../../static/img/Main/icons/svg/red/athlete.svg';
import trainersRed from '../../../../static/img/Main/icons/svg/red/trainer.svg';
import refereesRed from '../../../../static/img/Main/icons/svg/red/referee.svg';
import national_teamsRed from '../../../../static/img/Main/icons/svg/red/team.svg';
import sporting_eventsRed from '../../../../static/img/Main/icons/svg/red/fcpsr.svg';
import documentsRed from '../../../../static/img/Main/icons/svg/red/doc.svg';
import minsportsRed from '../../../../static/img/Main/icons/svg/red/ecp.svg';
import type_sportsRed from '../../../../static/img/Main/icons/svg/red/evsc.svg';
import classifiersRed from '../../../../static/img/Main/icons/svg/red/classifier.svg';
import unification_tasksRed from '../../../../static/img/Main/icons/svg/red/task.svg';
import { MenuPoint } from '../../../../types/types';

const gridPoints: MenuPoint[] = [
  {
    icon: HomeWorkRoundedIcon,
    title: 'Спортивные организации',
    id: 'organizations',
    link: '/organizations',
    createNew: '/organization/new',
    srcBlue: organizationsBlue,
    srcRed: organizationsRed,
  },
  {
    icon: PersonAddRoundedIcon,
    title: 'Персоны',
    id: 'person',
    link: '/persons',
    createNew: '/person/new',
    srcBlue: personsBlue,
    srcRed: personsRed,
  },

  {
    icon: SportsHandballRoundedIcon,
    title: 'Спортсмены',
    id: 'athletes',
    link: '/athletes',
    createNew: '/athlete/new',
    srcBlue: athletesBlue,
    srcRed: athletesRed,
  },
  {
    icon: TimelineRoundedIcon,
    title: 'Тренеры',
    id: 'trainers',
    link: '/trainers',
    createNew: '/trainer/new',
    srcBlue: trainersBlue,
    srcRed: trainersRed,
  },
  {
    icon: GavelIcon,
    title: 'Судьи',
    id: 'referees',
    link: '/referees',
    createNew: '/referee/new',
    srcBlue: refereesBlue,
    srcRed: refereesRed,
  },
  {
    icon: SportsKabaddiRoundedIcon,
    title: 'Сборные команды',
    id: 'national_teams',
    link: '/national_teams',
    createNew: '/national_team/new',
    srcBlue: national_teamsBlue,
    srcRed: national_teamsRed,
  },
  {
    icon: HomeWorkRoundedIcon,
    title: 'Календари ФЦПСР',
    id: 'calendars',
    link: '/calendars',
    createNew: '/calendar/new',
    srcBlue: calendarFcpsrBlue,
    srcRed: calendarFcpsrRed,
  },
  {
    icon: LocalActivityIcon,
    title: 'Спортмероприятия ФЦПСР',
    id: 'sporting_events',
    link: '/sporting_events',
    createNew: '/sporting_event/new',
    srcBlue: sporting_eventsBlue,
    srcRed: sporting_eventsRed,
  },
  {
    icon: DescriptionIcon,
    title: 'Документы',
    id: 'documents',
    link: '/documents',
    createNew: '/document/new',
    srcBlue: documentsBlue,
    srcRed: documentsRed,
  },
  {
    icon: NotListedLocationIcon,
    title: 'ЕКП Минспорт',
    id: 'minsports',
    link: '/minsports',
    createNew: '/minsports/new',
    srcBlue: minsportsBlue,
    srcRed: minsportsRed,
  },
  {
    icon: SportsGolfRoundedIcon,
    title: 'ЕВСК',
    id: 'type_sports',
    link: '/type_sports',
    createNew: '/type_sports/new',
    srcBlue: type_sportsBlue,
    srcRed: type_sportsRed,
  },
  {
    icon: AssignmentRoundedIcon,
    title: 'Классификаторы',
    id: 'classifiers',
    link: '/classifiers',
    createNew: '/classifier/new',
    srcBlue: classifiersBlue,
    srcRed: classifiersRed,
  },
  {
    icon: ListAltIcon,
    title: 'Задания на объединение',
    id: 'unificationtasks',
    link: '/unification_tasks',
    createNew: '/unification_tasks',
    srcBlue: unification_tasksBlue,
    srcRed: unification_tasksRed,
  },
];

export { gridPoints };
