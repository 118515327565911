import {
  DocumentArchiveMutationHookResult,
  DocumentFragment,
  useDocumentArchiveMutation,
  CreateDocumentMutationHookResult,
  UpdateDocumentMutationHookResult,
  DocumentUnArchiveMutationHookResult,
  useDocumentUnArchiveMutation,
  useCreateDocumentMutation,
  useUpdateDocumentMutation,
  UploadFileMutationHookResult,
  useUploadFileMutation,
  useDocumentByIdLazyQuery,
} from '../../../api';
import { useForm } from 'react-hook-form';
import React, { FC, useEffect, useState } from 'react';
import { Button, Grid, Link, Tooltip, IconButton } from '@material-ui/core';
import { Input, DatePicker } from '../../Inputs';
import SaveIcon from '@material-ui/icons/Save';
import { ContentWrapperWidget } from '../../layouts';
import { useSnackbar } from 'notistack';
import getMessage from '../../../messages';
import { useHistory } from 'react-router';
import ArchiveWidget from '../Shared/Archive';
import { DropzoneArea } from 'material-ui-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteIcon from '@material-ui/icons/Delete';
import moment from 'moment';
import _ from 'lodash';
import { DocumentTypes } from '../../../module';
import ConfirmDialog from '../../ConfirmDialog';

const useStyles = makeStyles({
  title: {
    fontWeight: 500,
  },
  link: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'flex-end',
  },
  verticalSpace: {
    paddingBottom: 15,
  },
  verticalSpaceMarge: {
    marginBottom: 35,
  },
  divider: {
    height: 1,
    width: '100%',
    marginBottom: '1rem',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  tinyIcon: {
    fontSize: 18,
  },
  icon: {
    fontSize: 20,
  },
  dropZone: {
    minHeight: 30,
    border: 0,
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    borderRadius: 0,

    '& > .MuiDropzonePreviewList-root': {
      margin: '0 !important',
      width: '100%',
      '& > .MuiDropzonePreviewList-imageContainer': {
        flexBasis: 'unset',
        width: '100%',
        maxWidth: '100%',
        display: 'flex',
        alignItems: 'center',
        '& > svg': {
          height: '40px',
        },
        '& > p': {
          marginLeft: 15,
          marginRight: 15,
        },
        '& > button': {
          top: 'unset',
          right: 0,
          color: '#d32f2f',
          boxShadow: 'unset',
        },
      },
    },
    '& > .MuiDropzoneArea-textContainer': {
      display: 'flex',

      '& > .MuiDropzoneArea-text': {
        margin: 0,
        fontSize: 15,

        '&:after': {
          content: '"*"',
          color: 'red',
          verticalAlign: 'middle',
          marginLeft: 4,
        },
      },
      '& > .MuiDropzoneArea-icon': {
        width: 20,
        height: 20,
        marginLeft: 'auto',
      },
    },
    '&:focus': {
      outline: 0,
      border: 0,
      borderBotom: '2px solid #3f51b5',
    },
  },
});

const MainInfo: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const DOWNLOAD_LINK = process.env.REACT_APP_BACKEND_URI?.replace('/graphql', '');
  const classes = useStyles();
  const [fetch, { data, refetch }] = useDocumentByIdLazyQuery({ variables: { id }, fetchPolicy: 'no-cache' });
  const { handleSubmit, control, errors, reset, formState } = useForm({
    defaultValues: { ...data?.dirDocument } as DocumentFragment,
  });
  const { push } = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const [deleteItem, setDeleteItem] = useState<any>(null);
  const [uploadDocumentFile]: UploadFileMutationHookResult = useUploadFileMutation();
  const [create]: CreateDocumentMutationHookResult = useCreateDocumentMutation();
  const [update]: UpdateDocumentMutationHookResult = useUpdateDocumentMutation();
  const [archive]: DocumentArchiveMutationHookResult = useDocumentArchiveMutation();
  const [unarchive]: DocumentUnArchiveMutationHookResult = useDocumentUnArchiveMutation();

  const [upload, setUpload] = useState<null | File>(null);
  const [defaultFile, setDefaultFile] = useState<any>(undefined);

  const onSubmit = async (values: DocumentFragment) => {
    const { title, date, number, clsType } = values;
    try {
      if (id !== 'new') {
        if (upload) {
          uploadDocumentFile({
            variables: {
              file: upload,
            },
          }).then(async (resp: any) => {
            const {
              uploadFile: { id: fileId },
            } = resp.data;
            await update({
              variables: {
                data: {
                  title,
                  date,
                  number,
                  clsType:
                    (data?.dirDocument?.clsType && clsType && { delete: true }) ||
                    (clsType && { connect: { id: clsType?.value } }),
                  file: {
                    connect: { id: fileId },
                  },
                },
                id,
              },
            });
            handleSnackBar('success', `Документ "${values?.title}" успешно обновлен`);
            refetch?.();
          });
          setUpload(null);
          return;
        }

        await update({
          variables: {
            data: {
              title,
              date,
              number,
              clsType:
                (data?.dirDocument?.clsType && !clsType && { delete: true }) ||
                (clsType && { connect: { id: clsType?.value } }),
            },
            id,
          },
        });
        handleSnackBar('success', `Документ "${values?.title}" успешно обновлен`);
        refetch?.();
        return;
      } else {
        uploadDocumentFile({
          variables: {
            file: upload,
          },
        }).then(async (resp: any) => {
          setUpload(null);
          const {
            uploadFile: { id: fileId },
          } = resp.data;
          await create({
            variables: {
              data: {
                title,
                date,
                number,
                clsType: clsType && { connect: { id: clsType?.value } },
                file: {
                  connect: { id: fileId },
                },
              },
            },
          }).then((resp: any) => {
            const { createDirDocument } = resp.data;
            handleSnackBar('success', `Документ "${values?.title}" успешно добавлен`);
            push(`/document/${createDirDocument?.id}`);
          });
        });
      }
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  useEffect(() => {
    if (id !== 'new' && id !== '') {
      fetch({ variables: { id } });
    }
  }, [fetch, id]);

  useEffect(() => {
    if (data?.dirDocument) {
      reset(_.omit(data?.dirDocument, ['file']));
      setDefaultFile(data?.dirDocument?.file || undefined);
    }
  }, [data, reset]);

  if (readonly)
    return (
      <Grid container>
        <Grid item container>
          <strong>Название документа :{` ${data?.dirDocument?.title || ''}`}</strong>
        </Grid>
        <Grid item container>
          <strong>Номер: {` ${data?.dirDocument?.number || '~'}`}</strong>
        </Grid>
        <Grid item container>
          <strong>Тип Документа: {` ${data?.dirDocument?.clsType?.label || '~'}`}</strong>
        </Grid>
        <Grid item container>
          <strong>Дата: {` ${moment(data?.dirDocument?.date).format('DD.MM.YY') || '~'}`}</strong>
        </Grid>

        <Grid item>
          {defaultFile && (
            <Link className={classes.link} target="_blank" href={`${DOWNLOAD_LINK}${defaultFile?.path}`}>
              <DescriptionIcon />
              <span style={{ marginLeft: '0.5rem' }}>{defaultFile?.name}</span>
            </Link>
          )}
        </Grid>
      </Grid>
    );

  return (
    <>
      <Grid container>
        <Grid item lg={6} md={12} xs={12}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <ContentWrapperWidget>
              <Grid container spacing={1} alignItems="flex-end">
                <Grid item md={6}>
                  <Input
                    label="Название документа"
                    control={control}
                    error={!!errors['title']}
                    name="title"
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item md={6}>
                  <Input label="Номер" control={control} error={!!errors['number']} name="number" />
                </Grid>
                <Grid item md={6}>
                  <DatePicker
                    fullWidth
                    label="Дата"
                    control={control}
                    error={!!errors['date']}
                    name="date"
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item md={6}>
                  <DocumentTypes
                    label="Тип документа"
                    error={!!errors['clsType']}
                    control={control}
                    rules={{ required: true }}
                    name="clsType"
                  />
                </Grid>
                <Grid item container justify="space-between" alignItems="flex-end" style={{ marginTop: 20 }}>
                  {(defaultFile && (
                    <React.Fragment>
                      <Grid item>
                        <Link className={classes.link} target="_blank" href={`${DOWNLOAD_LINK}${defaultFile?.path}`}>
                          <DescriptionIcon />
                          <span style={{ marginLeft: '0.5rem' }}>{defaultFile?.name}</span>
                        </Link>
                      </Grid>
                      {!readonly && (
                        <Grid item>
                          <Tooltip title="удалить" placement="top-start">
                            <IconButton color="secondary" onClick={() => setDeleteItem(defaultFile.id)}>
                              <DeleteIcon className={classes.icon} />
                            </IconButton>
                          </Tooltip>
                          <ConfirmDialog
                            title={`Удаление документа`}
                            text={`Вы уверены что хотите удалить документ : ${defaultFile.name}`}
                            btnVariant="outlined"
                            open={deleteItem === defaultFile.id}
                            saveBtnText="удалить"
                            cancelBtnText="отмена"
                            saveBtnColor="secondary"
                            cancelBtnColor="primary"
                            onCancel={() => setDeleteItem(null)}
                            onSave={() => {
                              setDefaultFile(null);
                              setDeleteItem(null);
                            }}
                          />
                        </Grid>
                      )}
                    </React.Fragment>
                  )) || (
                    <DropzoneArea
                      showFileNames
                      alertSnackbarProps={{
                        anchorOrigin: { vertical: 'top', horizontal: 'right' },
                      }}
                      dropzoneClass={classes.dropZone}
                      showAlerts={['error']}
                      getFileAddedMessage={(fileName) => `Файл ${fileName} успешно загружено`}
                      getDropRejectMessage={(file) =>
                        `Файл ${file.name} отклонен. Тип файла не поддерживается. Файл слишком большой. Максимальный размер - 10 мегабайт.`
                      }
                      filesLimit={1}
                      maxFileSize={10485760}
                      onChange={(fileArray) => {
                        if (fileArray.length > 0) {
                          setUpload(fileArray[0]);
                        }
                      }}
                      dropzoneText="Файл"
                    />
                  )}
                </Grid>
                <Grid item md={12}>
                  <Button
                    variant="outlined"
                    color="primary"
                    type="submit"
                    size="small"
                    startIcon={<SaveIcon color="primary" />}
                    disabled={!formState.isDirty || (id === 'new' && !upload)}
                  >
                    Сохранить
                  </Button>
                </Grid>
              </Grid>
            </ContentWrapperWidget>
          </form>
        </Grid>
        {id && id !== 'new' && (
          <Grid item lg={12} md={12} xs={12}>
            <ArchiveWidget
              archive={data?.dirDocument?.archive || null}
              id={id}
              refetch={refetch}
              onArchive={archive}
              onUnarchive={unarchive}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default MainInfo;
