import React, { FC, useEffect, useState } from 'react';
import { useSimilarSportingEventsLazyQuery } from '../api';
import { Select } from '../components/Inputs';
import useDebouncedSearch from '../hooks/useDebouncedSearch';
import { ControllerProps } from 'react-hook-form/dist/types/props';

interface SportingEvents extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  error: boolean | undefined;
  filter?: any;
}

const SportEvents: FC<SportingEvents> = ({ label, error, control, name, rules, filter }) => {
  const [fetch, { data, loading, refetch }] = useSimilarSportingEventsLazyQuery({
    variables: { search: '', filter },
  });
  const [parsed, setParsed] = useState<any>([]);

  const refetchDocuments = async (value: string) => {
    if (value?.length > 2) {
      await fetch({ variables: { search: value, filter } });
      // console.log(
      //   data,
      //   data?.data.map((item) => item.organization),
      // );
    }
  };
  const useSearchDocuments = () => useDebouncedSearch((text) => refetchDocuments(text));

  const { setInputValue: setValue } = useSearchDocuments();

  useEffect(() => {
    if (!loading) {
      const prepare = data?.dirSportingEventsSimilar?.map((similar) => similar?.sportingEvent);
      setParsed(prepare || []);
    }
  }, [data, loading]);

  return (
    <Select
      enablePortal
      label={label}
      data={parsed}
      control={control}
      error={error}
      name={name}
      rules={rules}
      onChangeInput={setValue}
      loading={loading}
    />
  );
};

export default SportEvents;
