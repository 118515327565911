import _ from 'lodash';
import { DirSportingEventSport } from '../api';

const getCategory = (referee: any, sports: DirSportingEventSport[]) =>
  _.get(
    _.filter(
      _.intersectionBy(referee?.dirReferee.sports, sports, (s: any) => s.dirSport.value),
      (cat) => _.get(cat, 'categories[0].isEndless'),
    )[0],
    'categories[0]',
  ) ||
  (_.get(
    _.intersectionBy(referee?.dirReferee.sports, sports, (s: any) => s.dirSport.value).sort(
      (a: any, b: any) => _.get(a, 'categories[0].validUntil') - _.get(b, 'categories[0].validUntil'),
    )[0],
    'categories[0]',
  )?.validUntil !== null &&
    _.get(
      _.intersectionBy(referee?.dirReferee.sports, sports, (s: any) => s.dirSport.value).sort(
        (a: any, b: any) => _.get(a, 'categories[0].validUntil') - _.get(b, 'categories[0].validUntil'),
      )[0],
      'categories[0]',
    ));

export const parseReferees = (refereesArray: any = [], sports: DirSportingEventSport[]) =>
  _.orderBy(
    refereesArray.map((referee: any) => ({ ...referee, category: getCategory(referee, sports) })),
    ['dirReferee.dirPerson.lastname'],
    ['asc'],
  );

export const sortReferees = (refereesArray: any, sports: DirSportingEventSport[]) => {
  if (refereesArray && sports) {
    const primaryReferee = _.find(
      refereesArray,
      (referee) => referee.clsRefereePosition.value === '4e4e7ff9-e7ec-41c1-84f6-e3aea9246cb5',
    );

    const primarySecretaryReferee = _.find(
      refereesArray,
      (referee) => referee.clsRefereePosition.value === '7026b40a-365d-4422-a8f8-12c9da114a43',
    );

    const vicePrimaryReferees = _.filter(
      refereesArray,
      (referee) => referee.clsRefereePosition.value === 'fc81c851-24f8-44be-b0c3-7b626e28aba7',
    ).map((referee) => ({ ...referee, category: getCategory(referee, sports) }));

    const vicePrimarySecretaryReferees = _.filter(
      refereesArray,
      (referee) => referee.clsRefereePosition.value === '6fe2989f-43bc-46aa-8964-11022760bbec',
    ).map((referee) => ({ ...referee, category: getCategory(referee, sports) }));

    const sportReferees = _.filter(
      refereesArray,
      (referee) => referee.clsRefereePosition.value === 'dd6483bb-7864-4251-b876-be2ffee0a856',
    ).map((referee) => ({ ...referee, category: getCategory(referee, sports) }));

    const commissionerReferees = _.filter(
      refereesArray,
      (referee) => referee.clsRefereePosition.value === '4e4e7ff9-e7ec-41c1-84f6-e3aea9246cb6',
    ).map((referee) => ({ ...referee, category: getCategory(referee, sports) }));

    const techReferees = _.filter(
      refereesArray,
      (referee) => referee.clsRefereePosition.value === '4e4e7ff9-e7ec-41c1-84f6-e3aea9246cb7',
    ).map((referee) => ({ ...referee, category: getCategory(referee, sports) }));

    return {
      primaryReferee: {
        ...primaryReferee,
        category: getCategory(primaryReferee, sports),
      },
      primarySecretaryReferee: {
        ...primarySecretaryReferee,
        category: getCategory(primarySecretaryReferee, sports),
      },
      vicePrimaryReferees: _.orderBy(vicePrimaryReferees, ['dirReferee.dirPerson.lastname'], ['asc']),
      vicePrimarySecretaryReferees: _.orderBy(vicePrimarySecretaryReferees, ['dirReferee.dirPerson.lastname'], ['asc']),
      sportReferees: _.orderBy(sportReferees, ['dirReferee.dirPerson.lastname'], ['asc']),
      commissionerReferees: _.orderBy(commissionerReferees, ['dirReferee.dirPerson.lastname'], ['asc']),
      techReferees: _.orderBy(techReferees, ['dirReferee.dirPerson.lastname'], ['asc']),
    };
  }
  return {
    primaryReferee: undefined,
    primarySecretaryReferee: undefined,
    vicePrimaryReferees: undefined,
    vicePrimarySecretaryReferees: undefined,
    sportReferees: undefined,
  };
};
