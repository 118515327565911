import React, { FC, useEffect, useState } from 'react';
import { useFindEventsLazyQuery, useEventsByRegistryNumberLazyQuery } from '../api';
import { Select } from '../components/Inputs';
import useDebouncedSearch from '../hooks/useDebouncedSearch';
import { ReactHookFormSelect } from '../components/Inputs/Select';

interface ISportingEvents extends Pick<ReactHookFormSelect, 'control' | 'name' | 'rules' | 'disabled'> {
  label: string;
  error: boolean | undefined;
  filter?: any;
  multiple?: boolean;
  disabled?: boolean;
}

const SportingEvents: FC<ISportingEvents> = ({ disabled, label, error, control, name, rules, filter, multiple }) => {
  const [fetch, { data, loading, refetch }] = useFindEventsLazyQuery({ variables: { search: '', filter } });
  const [eventsFetch, { data: eventsData, loading: eventsLoading }] = useEventsByRegistryNumberLazyQuery({
    variables: { filter },
  });
  const [parsed, setParsed] = useState<any>([]);
  const [shouldNtFilter, setShouldNtFilter] = useState(false);

  const refetchEvents = async (value: string) => {
    if (value?.length > 2) {
      const isNotANumber = isNaN(Number(value));
      setShouldNtFilter(!isNotANumber);

      if (!isNotANumber) {
        await eventsFetch({
          variables: { filter: { ...filter, registryNumber: Number(value) } },
        });
      } else {
        await fetch({
          variables: { search: value, filter },
        });
      }
    }
  };
  const useSearchSportingEvents = () => useDebouncedSearch((text) => refetchEvents(text));

  const { setInputValue: setValue } = useSearchSportingEvents();

  useEffect(() => {
    if (!loading || !eventsLoading) {
      const prepare = shouldNtFilter ? eventsData?.dirSportingEvents : data?.data.map((item) => item.sportingEvent);
      setParsed(prepare || []);
    }
  }, [data, loading, eventsLoading]);

  return (
    <Select
      shouldNtFilter={shouldNtFilter}
      label={label}
      data={parsed}
      multiple={multiple}
      control={control}
      disabled={disabled}
      error={error}
      name={name}
      rules={rules}
      placeholder="Введите название или реестровый номер"
      onChangeInput={setValue}
      loading={loading || eventsLoading}
    />
  );
};

export default SportingEvents;
