import { ActionSettings } from '../../types/types';
import {
  SET_USER_SETTINGS_FILTER,
  RESET_USER_SETTINGS_FILTER,
  SET_USER_SETTINGS_COLUMN_VISIBLE,
  RESET_USER_SETTINGS_COLUMN_VISIBLE,
  INIT_TABLE_SETTINGS,
  UPDATE_ORDER_COLUMN,
} from '../constants';
import _ from 'lodash';
import userRights from '../../config/userRights';
import { checkRolesAndReturnDefaultFilter } from '../../_pages/UsersPage/helpers';

export interface SettingsStore {
  [key: string]: { filter?: any; form?: any; column: { hidden: { [key: string]: boolean } }; order?: number[] };
}

const { hasAdditionalFilter } = userRights();
const additionalFilter = checkRolesAndReturnDefaultFilter();

export const INITIAL_STATE = {
  athletes: {
    filter: {
      ...hasAdditionalFilter?.athletes,
      dirPerson: {
        ...hasAdditionalFilter?.athletes?.dirPerson,
        archive: null,
      },
    },
    column: {
      hidden: {},
    },
  },
  minsports_table: {
    filter: {
      archive: null,
      proxy: null,
    },
    column: {
      hidden: {},
    },
  },
  ekp_calendar: {
    filter: {
      archive: null,
    },
    column: { hidden: {} },
  },
  сalendars: {
    filter: {
      archive: null,
    },
    column: { hidden: {} },
  },
  documents: {
    filter: {
      archive: null,
    },
    column: { hidden: {} },
  },
  classifiers: {
    filter: {
      archive: null,
    },
    column: { hidden: {} },
  },
  national_teams: {
    filter: {
      archive: null,
      ...hasAdditionalFilter?.national_teams,
    },
    column: { hidden: {} },
  },
  referees: {
    filter: {
      ...hasAdditionalFilter?.referees,
      dirPerson: {
        ...hasAdditionalFilter?.referees?.dirPerson,
        archive: null,
      },
    },
    column: { hidden: {} },
  },
  regions: {
    filter: {
      archive: null,
    },
    column: { hidden: {} },
  },
  sporting_events: {
    filter: {
      archive: null,
      proxy: null,
    },
    column: { hidden: {} },
  },
  trainers: {
    filter: {
      ...hasAdditionalFilter?.trainers,
      dirPerson: {
        ...hasAdditionalFilter?.trainers?.dirPerson,
        archive: null,
      },
    },
    column: { hidden: {} },
  },
  users: {
    filter: {
      role: { name_not: 'PERSON', AND: [additionalFilter] },
    },
    column: { hidden: {} },
  },
  organizations: {
    filter: {
      archive: null,
      ...hasAdditionalFilter?.organizations,
    },
    column: { hidden: {} },
  },
  typesport: {
    filter: {
      archive: null,
    },
    column: { hidden: {} },
  },
};

//Это есть есть settings redux
export function settingsReducer(state: SettingsStore = INITIAL_STATE, action: ActionSettings) {
  switch (action.type) {
    case INIT_TABLE_SETTINGS:
      return {
        ...state,
        ...{ [action.payload.name]: { column: { hidden: {} } } },
      };
    case UPDATE_ORDER_COLUMN:
      return {
        ...state,
        ...{
          [action.payload.name]: {
            ...state[action.payload.name],
            order: action.payload.order,
          },
        },
      };
    case SET_USER_SETTINGS_FILTER:
      return {
        ...state,
        ...{
          [action.payload.name]: {
            ...state[action.payload.name],
            filter: action.payload.filter,
            form: action.payload.form,
          },
        },
      };
    // case SET_USER_SETTINGS_FILTER:
    //   return {
    //     ...state,
    //     filter: { ...state[action.payload.name]?.filter, ...action.payload.data },
    //   };
    // case GET_USER_SETTINGS_COLUMN_VISIBLE:
    //   return state.filter;
    case SET_USER_SETTINGS_COLUMN_VISIBLE:
      const activeGrid = state[action.payload.name];
      const status = action.payload.status;
      const resultColumn =
        status !== 'remove'
          ? _.set(activeGrid.column.hidden, action.payload.columnName, 'true')
          : _.set(activeGrid.column.hidden, action.payload.columnName, 'false');

      return {
        ...state,
        ...{
          [action.payload.name]: {
            ...activeGrid,
            column: { hidden: { ...resultColumn } },
          },
        },
      };
    case RESET_USER_SETTINGS_FILTER:
      return {
        ...INITIAL_STATE,
      };
    case RESET_USER_SETTINGS_COLUMN_VISIBLE:
      return {
        ...state,
        column: {
          ...state.column,
          visible: [],
        },
      };

    default:
      return state;
  }
}
