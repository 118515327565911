import { Grid, Tooltip } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import React, { FC, useCallback, useState } from 'react';
import { getSettingsUserFilter, getSettingsUserFilterFormValue } from '../../store/selectors/settings';
import { useCalendarsLazyQuery } from '../../api';
import { useDispatch, useSelector } from 'react-redux';

import { AppStore } from '../../store/reducers';
import { BtnNew } from '../../components/BtnNew';
import { CalendarsFilter } from '../../components/Filters';
import { DictionaryWrapper } from '../../components/DictionaryWrapper';
import { MUIDataTableColumn } from 'mui-datatables';
import { MainTemplate } from '../../components/layouts';
import { SET_USER_SETTINGS_FILTER } from '../../store/constants';
import _ from 'lodash';
import { usePermission } from '../../hooks/usePermission';
import { DataTable } from '../../components/DataTable/NewTable';

const Page: FC<{}> = () => {
  const { push } = useHistory();
  const [first, setFirst] = useState(0);
  const dispatch = useDispatch();
  const filter: any = useSelector((state: AppStore) => getSettingsUserFilter(state, 'ekp_calendar'), _.isEqual);
  const formValue: any = useSelector(
    (state: AppStore) => getSettingsUserFilterFormValue(state, 'ekp_calendar'),
    _.isEqual,
  );

  const access = usePermission('directory', 'eventscalendar');

  const columns: MUIDataTableColumn[] = [
    {
      name: 'info',
      label: 'Полное название',
      options: {
        filter: false,
        display: true,
        sort: false,
        viewColumns: false,
        customBodyRender: (info: any, meta: any) => {
          const { rowData } = meta;
          const name = rowData[1];
          const { id } = info || {};

          return (
            <Tooltip title="Перейти в карточку" placement="top-start">
              <Link
                style={{
                  fontWeight: 500,
                  cursor: 'pointer',
                  textDecoration: 'none',
                }}
                to={`/event/${id}`}
                target={'_blank'}
              >
                {name}
              </Link>
            </Tooltip>
          );
        },
      },
    },
    {
      name: 'fullName',
      label: 'Полное название',
      options: {
        filter: false,
        display: false,
        sort: false,
      },
    },
    {
      name: 'shortName',
      label: 'Краткое название',
      options: {
        filter: false,
        display: true,
        sort: false,
      },
    },
    {
      name: 'period',
      label: 'Период',
      options: {
        filter: false,
        display: true,
        sort: false,
      },
    },
    {
      name: 'registryNumber',
      label: 'Реестровый номер',
      options: {
        filter: false,
        display: true,
        sort: false,
      },
    },
  ];

  const setFilter = useCallback(
    (filter, form) => {
      dispatch({
        type: SET_USER_SETTINGS_FILTER,
        payload: {
          name: 'ekp_calendar',
          filter: filter,
          form,
        },
      });
    },
    [dispatch],
  );

  return (
    <MainTemplate pageName="Календарь" url="" title="Календарь ЕКП">
      <CalendarsFilter
        onSubmit={(filters: any, form: any) => setFilter({ ...filters }, form)}
        first={first}
        value={formValue}
      />
      <DictionaryWrapper>
        <DataTable
          tableName="events"
          title={
            <Grid container spacing={2} alignItems={'center'}>
              <Grid item>
                <h2>Календарь ЕКП</h2>
              </Grid>
              {access.includes(2) && <Grid item>{<BtnNew onClick={() => push('/event/new')} />}</Grid>}
            </Grid>
          }
          lazyQuery={useCalendarsLazyQuery}
          revealFunctionName="calendars"
          filter={{ ...filter, type: { id: 1 } }}
          columns={columns}
        />
      </DictionaryWrapper>
    </MainTemplate>
  );
};

export default Page;
