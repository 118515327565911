import { Button, Grid } from '@material-ui/core';
import React, { FC, useEffect } from 'react';
import {
  UpdateUserContactInfoMutationHookResult,
  useUpdateUserContactInfoMutation,
  useUserLazyQuery,
} from '../../../api';

import { ContentWrapperWidget } from '../../layouts';
import { Input } from '../../Inputs';
import SaveIcon from '@material-ui/icons/Save';
import getMessage from '../../../messages';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

interface ContactInfoFormProps {
  phone?: string | null;
  address?: string | null;
  email?: string | null;
  regular?: any;
}

const ContactInfo: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const [getUser, { data }] = useUserLazyQuery({ fetchPolicy: 'no-cache' });
  const { handleSubmit, control, errors, reset, formState } = useForm({
    defaultValues: {
      address: '',
      phone: '',
      email: '',
      regular: null,
    } as ContactInfoFormProps,
  });
  const { enqueueSnackbar } = useSnackbar();

  const [update]: UpdateUserContactInfoMutationHookResult = useUpdateUserContactInfoMutation();
  const onSubmit = async (values: ContactInfoFormProps) => {
    try {
      if (id !== 'new') {
        await update({
          variables: {
            id,
            regular: {
              update: { ...values.regular, phone: values.phone, address: values.address },
            },
          },
        });
        enqueueSnackbar(`Пользователь успешно обновлен`, { variant: 'success' });
      }
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  useEffect(() => {
    if (id !== 'new') {
      getUser({ variables: { id } });
    }
  }, [id]);

  useEffect(() => {
    if (data?.user) {
      reset({
        ...data?.user,
        ...data?.user.regular,
      });
    }
  }, [data]);

  if (readonly)
    return (
      <Grid container spacing={1}>
        <Grid item container spacing={1}>
          <Grid item>
            <strong>Телефон: </strong>
          </Grid>
          <Grid item>
            <span>{data?.user?.regular?.phone}</span>
          </Grid>
        </Grid>
        <Grid item container spacing={1}>
          <Grid item>
            <strong>Адрес: </strong>
          </Grid>
          <Grid item>
            <span>{data?.user?.regular?.address}</span>
          </Grid>
        </Grid>
      </Grid>
    );

  return (
    <>
      <Grid container>
        <Grid item lg={9} md={12} xs={12}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <ContentWrapperWidget>
              <Grid container spacing={1}>
                <Grid item md={6}>
                  <Input label="Телефон" control={control} error={!!errors['phone']} name="phone" />
                </Grid>
                <Grid item md={6}>
                  <Input label="Адрес" control={control} error={!!errors['address']} name="address" />
                </Grid>
                <Grid item md={12}>
                  <Button
                    variant="outlined"
                    color="primary"
                    type="submit"
                    size="small"
                    startIcon={<SaveIcon color="primary" />}
                    disabled={!formState.isDirty}
                  >
                    Сохранить
                  </Button>
                </Grid>
              </Grid>
            </ContentWrapperWidget>
          </form>
        </Grid>
      </Grid>
    </>
  );
};

export default ContactInfo;
