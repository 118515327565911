import {
  Accordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Typography,
  withStyles,
} from '@material-ui/core';
import { ContentWrapperWidget, MainTemplate } from '../../components/layouts';
import { GroupTypesSportAndDisciplines, TSStagePreparing, TypeSportsMainInfo } from '../../components/widgets';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { useFetchTypeSportByIdMainInfoLazyQuery, useFetchTypeSportByIdMainInfoQuery } from '../../api';

import AgeGroupsWidget from '../../components/widgets/TypeSports/AgeGroups';
import BaseRegionsWidget from '../../components/widgets/TypeSports/BaseRegions';
import Disciplines from '../../components/widgets/TypeSports/Disciplines';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GroupsDisciplines from '../../components/widgets/TypeSports/GroupsDisciplines';
import RanksAndTitles from '../../components/widgets/TypeSports/RanksAndTitles';
import { blue } from '@material-ui/core/colors';
import moment from 'moment';
import onExpand from '../../utils/onExpand';
import { usePermission } from '../../hooks/usePermission';
import { useRouteMatch } from 'react-router-dom';

type AccordionTypes =
  | 'MainInfo'
  | 'GroupsTypesSportAndDisciplines'
  | 'Disciplines'
  | 'GroupsDisciplines'
  | 'AgeGroups'
  | 'StagePrepare'
  | 'RankTitle'
  | 'BaseRegions'
  | undefined;

const AccordionSummary = withStyles({
  root: {
    backgroundColor: blue[700],
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    color: 'white',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary) as any;

// @TODO: Update View for Dialog request to Archive

const Single: FC<{}> = () => {
  const {
    params: { id },
  } = useRouteMatch();
  // const [page, setPage] = useState<number>(0);
  const [expanded, setExpanded] = useState<AccordionTypes>('MainInfo');

  const access = usePermission('directory', 'type_sports');

  // const access = usePermission('directory', 'person');
  // const style = useStyle();
  // const canAdd = true;
  const [fetch, { data }] = useFetchTypeSportByIdMainInfoLazyQuery({ variables: { id } });

  const handleChangeAccordion = (panel: AccordionTypes) => (event: ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : undefined);
  };
  const OptionTitleArchive = data?.dirSport?.archive
    ? ` (В архиве с ${moment(data?.dirSport?.archive?.date).format('DD.MM.YYYY')})`
    : '';

  useEffect(() => {
    if (id !== '') {
      fetch({ variables: { id } });
    }
  }, [fetch, id]);

  return (
    <MainTemplate
      pageName=""
      url=""
      title={id === 'new' ? 'Добавить вид спорта' : `Вид спорта: ${data?.dirSport?.fullName}`}
    >
      <ContentWrapperWidget>
        {id === 'new' ? (
          <h2>Добавить новый вид спорта</h2>
        ) : (
          <h2>
            Вид спорта: {data?.dirSport?.fullName || data?.dirSport?.shortName} {OptionTitleArchive}
          </h2>
        )}
        <Accordion expanded={expanded === 'MainInfo'} onChange={handleChangeAccordion('MainInfo')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Общая информация</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {onExpand(expanded, 'MainInfo') && <TypeSportsMainInfo id={id} readonly={!access.includes(2)} />}
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'BaseRegions'}
          onChange={handleChangeAccordion('BaseRegions')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Базовый регион</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {onExpand(expanded, 'BaseRegions') && id !== 'new' && (
              <BaseRegionsWidget id={id} readonly={!access.includes(2)} />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'Disciplines'}
          onChange={handleChangeAccordion('Disciplines')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Дисциплины</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {onExpand(expanded, 'Disciplines') && id !== 'new' && (
              <Disciplines id={id} readonly={!access.includes(2)} />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'GroupsDisciplines'}
          onChange={handleChangeAccordion('GroupsDisciplines')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Группы дисциплин</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {onExpand(expanded, 'GroupsDisciplines') && id !== 'new' && (
              <GroupsDisciplines id={id} readonly={!access.includes(2)} />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'AgeGroups'}
          onChange={handleChangeAccordion('AgeGroups')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Возрастные группы</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {onExpand(expanded, 'AgeGroups') && id !== 'new' && (
              <AgeGroupsWidget id={id} readonly={!access.includes(2)} />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'StagePrepare'}
          onChange={handleChangeAccordion('StagePrepare')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Этапы подготовки</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {onExpand(expanded, 'StagePrepare') && id !== 'new' && (
              <TSStagePreparing id={id} readonly={!access.includes(2)} />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'RankTitle'}
          onChange={handleChangeAccordion('RankTitle')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Разряды и звания</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {onExpand(expanded, 'RankTitle') && id !== 'new' && (
              <RanksAndTitles id={id} readonly={!access.includes(2)} />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'GroupsTypesSportAndDisciplines'}
          onChange={handleChangeAccordion('GroupsTypesSportAndDisciplines')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Группа видов спорта и дисциплин</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {onExpand(expanded, 'GroupsTypesSportAndDisciplines') && id !== 'new' && (
              <GroupTypesSportAndDisciplines id={id} readonly={!access.includes(2)} />
            )}
          </AccordionDetails>
        </Accordion>
      </ContentWrapperWidget>
    </MainTemplate>
  );
};

export default Single;
