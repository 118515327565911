import {
  Grid,
  IconButton,
  LinearProgress,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import AddBoxIcon from '@material-ui/icons/AddBox';
import React, { FC, Fragment, useEffect, useState } from 'react';

import ArchiveComponent from '../../../Dialogs/Archive/Archive';

import moment from 'moment';

import {
  useCalendarEventsQuery,
  CreateSportingEventMutationHookResult,
  useCreateSportingEventMutation,
  CalendarEventFragment,
  SportingEventArchiveMutationHookResult,
  useSportingEventArchiveMutation,
  SportingEventUnArchiveMutationHookResult,
  useSportingEventUnArchiveMutation,
  UpdateSportingEventMutationHookResult,
  useUpdateSportingEventMutation,
  useCalendarEventsWithoutRegionsQuery,
  useValidationEditableStepOneLazyQuery,
  useValidationEditableEventBlockLazyQuery,
} from '../../../../api';
import AddNewEventFom, { TForm } from '../AddNewEventFom/AddNewEventFom';
import getMessage from '../../../../messages';
import { useSnackbar } from 'notistack';
import EventProtocolDocuments from './EventProtocolDocuments';
import SearchUsersRoiv from '../../../../module/SearchUsersRoiv';
import _ from 'lodash';

const useStyles = makeStyles({
  topCell: {
    borderBottom: 0,
  },
});

const EventTable: FC<{
  id?: string;
  sportId: string;
  rNumber?: number;
  regionId?: string;
  readonly?: boolean;
  stageRegistryNumber: number;
  extendedReadonly?: boolean;
  showSearchRoiv?: any;
  idCalendar?: number;
}> = ({
  id,
  regionId,
  sportId,
  rNumber,
  readonly,
  stageRegistryNumber,
  extendedReadonly,
  idCalendar,
  showSearchRoiv,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [blockTwiceClick, setBlockTwiceClick] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentEvent, setCurrentEvent] = useState<CalendarEventFragment | null>(null);

  const [createNewEvent]: CreateSportingEventMutationHookResult = useCreateSportingEventMutation();
  const [update]: UpdateSportingEventMutationHookResult = useUpdateSportingEventMutation();
  const [archive]: SportingEventArchiveMutationHookResult = useSportingEventArchiveMutation();
  const [unarchive]: SportingEventUnArchiveMutationHookResult = useSportingEventUnArchiveMutation();

  const fn = regionId ? useCalendarEventsQuery : useCalendarEventsWithoutRegionsQuery;

  const [fetchDate, { data: dataDate }] = useValidationEditableStepOneLazyQuery({
    variables: {
      id,
      idSport: sportId,
    },
  });

  const [fetchBlock, { data: dataBlock }] = useValidationEditableEventBlockLazyQuery();

  const logic = async () => {
    await fetchDate({
      variables: {
        id,
        idSport: sportId,
      },
    });
  };

  useEffect(() => {
    if (dataDate) {
      const dayOff = _.get(_.first(dataDate?.dirSportingEvents), 'daysBeforeStartToAddProtocols', 0);
      if (dayOff && dayOff > 0) {
        fetchBlock({
          variables: {
            id,
            idSport: sportId,
            dateValid: moment().add(dayOff - 1, 'd'),
          },
        });
      }
    }
  }, [dataDate]);

  useEffect(() => {
    if (extendedReadonly) {
      logic();
    }
  }, [id, sportId, extendedReadonly]);

  const { data, loading, refetch } = fn({
    variables: {
      id,
      ...(rNumber && { registryNumber: rNumber }),
      stageRegistryNumber,
      ...(regionId && { regionId }),
      sportId,
    },
    fetchPolicy: 'no-cache',
  });

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  if (loading) return <LinearProgress />;

  const handleClose = () => {
    setOpen(false);
    setCurrentEvent(null);
  };

  const handleEditEvent = async (event: CalendarEventFragment) => {
    setCurrentEvent(event);
    setOpen(true);
  };

  const handleSubmitEventForm = async (value: TForm) => {
    const { name, venue, startDate, endDate, membersQuota, ekp } = value;
    setBlockTwiceClick(true);
    try {
      if (currentEvent) {
        await update({
          variables: {
            id: currentEvent.id,
            data: {
              name,
              venue,
              startDate,
              endDate,
              membersQuota: parseInt(membersQuota, 10),
              ekp,
            },
          },
        });
        handleSnackBar('success', 'Информация успешно обновлена');
        setCurrentEvent(null);
      } else {
        await createNewEvent({
          variables: {
            data: {
              name,
              venue,
              startDate,
              endDate,
              membersQuota: parseInt(membersQuota, 10),
              ekp,
              dirCalendar: {
                connect: {
                  id,
                },
              },
              ...(regionId && {
                dirRegion: {
                  connect: {
                    id: regionId,
                  },
                },
              }),
              clsEventStages: {
                connect: [
                  {
                    registryNumber: 100002914,
                  },
                ],
              },
              statuses: {
                create: [
                  {
                    value: { connect: { id: 1 } },
                  },
                ],
              },
              status: {
                connect: {
                  id: 1,
                },
              },
              sports: {
                create: [
                  {
                    dirSport: {
                      connect: {
                        id: sportId,
                      },
                    },
                  },
                ],
              },
            },
          },
        });
        handleSnackBar('success', 'Новое мероприятие успешно создано');
      }
      setOpen(false);
      await refetch();
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
    setBlockTwiceClick(false);
  };

  const isAdmin = ['ADMIN', 'FCPSR_ADMINISTRATOR', 'FCPSR_SPECIALIST'].includes(localStorage.getItem('userRole'));
  const dayOff = _.get(_.first(dataDate?.dirSportingEvents), 'daysBeforeStartToAddProtocols', null);

  readonly = !isAdmin && extendedReadonly && (dataBlock?.dirSportingEvents?.length || 0) > 0;

  const num_word = (value, words) => {
    value = Math.abs(value) % 100;
    var num = value % 10;
    if (value > 10 && value < 20) return words[2];
    if (num > 1 && num < 5) return words[1];
    if (num == 1) return words[0];
    return words[2];
  };

  if (loading) return <LinearProgress color="primary" />;
  return (
    <div style={{ width: '100%' }}>
      {dayOff && dayOff > 0 && (
        <Typography style={{ fontWeight: 'bold' }}>
          Добавление протокола недоступно за {dayOff} {num_word(dayOff, ['день', 'дня', 'дней'])} до финального
          мероприятия
        </Typography>
      )}
      {showSearchRoiv && <SearchUsersRoiv idRegion={regionId} idCalendar={idCalendar} idSport={sportId} />}
      <br />
      {open && (
        <AddNewEventFom
          {...(currentEvent && { defaultEvent: currentEvent })}
          handleClose={handleClose}
          onSubmit={handleSubmitEventForm}
          blockTwiceClick={blockTwiceClick}
        />
      )}
      <TableContainer component={Paper}>
        <Table aria-label="Calendar table">
          <TableHead>
            <TableRow>
              {!readonly && (
                <TableCell>
                  <IconButton color="primary" onClick={() => setOpen(true)}>
                    <AddBoxIcon />
                  </IconButton>
                </TableCell>
              )}
              <TableCell>Название мероприятия</TableCell>
              <TableCell>Место проведения&nbsp;</TableCell>
              <TableCell>Период проведения&nbsp;</TableCell>
              <TableCell>Кол-во участников&nbsp;</TableCell>
              <TableCell>Номер ЕКП</TableCell>
              {!readonly && <TableCell />}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.dirSportingEvents.map(
              (event) =>
                !event.archive && (
                  <Fragment key={event.id}>
                    <TableRow>
                      {!readonly && <TableCell component="th" className={classes.topCell} />}
                      <TableCell component="th" scope="row" className={classes.topCell}>
                        <Link
                          style={{ fontWeight: 600, cursor: 'pointer' }}
                          target="_blank"
                          href={`/sporting_event/${event.id}`}
                        >
                          {event.name}
                        </Link>
                      </TableCell>
                      <TableCell className={classes.topCell}>
                        <Typography color={event.archive ? 'textSecondary' : 'inherit'}>{event.venue}</Typography>
                      </TableCell>
                      <TableCell className={classes.topCell}>
                        <Typography color={event.archive ? 'textSecondary' : 'inherit'}>
                          {moment(event.startDate).format('DD.MM.YYYY')} - {moment(event.endDate).format('DD.MM.YYYY')}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.topCell}>
                        <Typography color={event.archive ? 'textSecondary' : 'inherit'}>
                          {event.membersQuota}
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.topCell}>
                        <Typography color={event.archive ? 'textSecondary' : 'inherit'}>{event.ekp}</Typography>
                      </TableCell>
                      {!readonly && (
                        <TableCell className={classes.topCell}>
                          <Grid container justify="space-between" style={{ width: 100 }}>
                            <ArchiveComponent
                              id={event.id}
                              archiveStatus={event.archive}
                              canUnArchive
                              unarchive={unarchive}
                              refetch={refetch}
                              archive={archive}
                            />
                            {!event.archive && (
                              <IconButton color="primary" onClick={() => handleEditEvent(event)}>
                                <EditIcon />
                              </IconButton>
                            )}
                          </Grid>
                        </TableCell>
                      )}
                    </TableRow>
                    <TableRow>
                      <TableCell />
                      <TableCell colSpan={6}>
                        <EventProtocolDocuments readonly={readonly} id={event.id} />
                      </TableCell>
                    </TableRow>
                  </Fragment>
                ),
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default EventTable;
