import { Button, Grid, IconButton, Tooltip } from '@material-ui/core';
import {
  CalendarArchiveMutationHookResult,
  CalendarFragment,
  CalendarUnArchiveMutationHookResult,
  CreateCalendarMutationHookResult,
  UpdateCalendarMutationHookResult,
  UploadFileMutationHookResult,
  useCalendarArchiveMutation,
  useCalendarQuery,
  useCalendarUnArchiveMutation,
  useCreateCalendarMutation,
  useUpdateCalendarMutation,
  useUploadFileMutation,
} from '../../../api';
import React, { FC, useEffect, useMemo, useState } from 'react';

import ArchiveWidget from '../Shared/Archive';
import CalendarTypes from '../../../module/CalendarTypes';
import ConfirmDialog from '../../ConfirmDialog';
import { ContentWrapperWidget } from '../../layouts';
import DeleteIcon from '@material-ui/icons/Delete';
import { Input } from '../../Inputs';
import SaveIcon from '@material-ui/icons/Save';
import { get } from 'lodash';
import getMessage from '../../../messages';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import Years from '../../../module/Years';
import CalendarCategory from '../../../module/CalendarCategory';
import PartEKP from '../../../module/PartEKP';
import CalendarDocuments from './components/CalendarDocument';
import TypeSports from '../../../module/TypeSports';
import CalendarStage from '../../../module/CalendarStage';
import { GeneralRegulationBtn } from './components/GeneralRegulationButton';
import { CalendarEventListModal } from '../../CalendarEventModal';
import _ from 'lodash';

const MainInfo: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const DOWNLOAD_LINK = process.env.REACT_APP_BACKEND_URI?.replace('/graphql', '');

  const { data, refetch } = useCalendarQuery({ skip: !id || id === 'new', variables: { id }, fetchPolicy: 'no-cache' });
  const { handleSubmit, control, errors, reset, formState } = useForm<CalendarFragment & { year: any }>({});

  const { push } = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [deleteItem, setDeleteItem] = useState<any>(null);

  const [create]: CreateCalendarMutationHookResult = useCreateCalendarMutation();
  const [update]: UpdateCalendarMutationHookResult = useUpdateCalendarMutation();
  const [archive]: CalendarArchiveMutationHookResult = useCalendarArchiveMutation();
  const [unarchive]: CalendarUnArchiveMutationHookResult = useCalendarUnArchiveMutation();
  const [uploadDocumentFile]: UploadFileMutationHookResult = useUploadFileMutation();

  const isSpecial = useMemo(
    () =>
      data?.dirCalendar?.clsCalendarCategory?.label?.toLocaleLowerCase().startsWith('универсиада') ||
      data?.dirCalendar?.clsCalendarCategory?.label?.toLocaleLowerCase().startsWith('спартакиада'),
    [data],
  );

  const onUploadDocument = (e: any) => {
    const file = get(e, 'target.files[0]', null);
    if (file) {
      uploadDocumentFile({
        variables: {
          file,
        },
      }).then(async (resp: any) => {
        const {
          uploadFile: { id: fileId },
        } = resp.data;
        try {
          await update({
            variables: {
              id,
              data: {
                sportCompetitionRegulationDocument: {
                  connect: {
                    id: fileId,
                  },
                },
              },
            },
          });
          enqueueSnackbar(`Документ "${file.name}" успешно добавлен`, { variant: 'success' });
          await refetch();
        } catch (e) {
          enqueueSnackbar(getMessage(e.message), { variant: 'error' });
        }
      });
    }
  };

  const deleteDocument = async (item: any) => {
    if (item) {
      try {
        await update({
          variables: {
            id,
            data: {
              sportCompetitionRegulationDocument: {
                disconnect: true,
              },
            },
          },
        });

        enqueueSnackbar(`Документ "${item.name}" успешно удален`, { variant: 'success' });
        await refetch();
      } catch (e) {
        enqueueSnackbar(getMessage(e.message), { variant: 'error' });
      }
    }
  };

  const onSubmit = async (values: CalendarFragment & { year: any }) => {
    const { stageNumber } = values;
    const oldStageNumber = data?.dirCalendar?.stageNumber;
    try {
      if (id !== 'new') {
        await update({
          variables: {
            id,
            data: {
              fullName: values.fullName,
              shortName: values.shortName,
              type: {
                connect: {
                  id: values?.type?.value,
                },
              },
              dirSports: {
                set: values.dirSports?.map((sport) => ({ id: sport?.value })),
              },
              ...((stageNumber && {
                stageNumber: {
                  connect: {
                    id: stageNumber?.value,
                  },
                },
              }) ||
                (oldStageNumber && {
                  stageNumber: {
                    disconnect: true,
                  },
                }) ||
                {}),
              year: parseInt(values.year?.value),
              clsEkpPart: { connect: { registryNumber: values.clsEkpPart?.value } },
              clsCalendarCategory: { connect: { registryNumber: values.clsCalendarCategory?.value } },
              // clsSeason: { connect: { registryNumber: values.clsSeason?.value } },
              ekpMinsportNumber: values.ekpMinsportNumber,
            },
          },
        });
        enqueueSnackbar(`Календарь "${values?.fullName}" успешно обновлен`, { variant: 'success' });
      } else {
        const { data } = await create({
          variables: {
            data: {
              fullName: values.fullName as string,
              shortName: values.shortName as string,
              type: {
                connect: {
                  id: values?.type?.value,
                },
              },
              year: parseInt(values.year?.value),
              dirSports: {
                connect: values.dirSports?.map((sport) => ({ id: sport?.value })),
              },
              ...((stageNumber && {
                stageNumber: {
                  connect: {
                    id: stageNumber?.value,
                  },
                },
              }) ||
                {}),
              clsEkpPart: { connect: { registryNumber: values.clsEkpPart?.value } },
              clsCalendarCategory: { connect: { registryNumber: values.clsCalendarCategory?.value } },
              // clsSeason: { connect: { registryNumber: values.clsSeason?.value } },
              ekpMinsportNumber: values.ekpMinsportNumber,
            },
          },
        });
        if (data?.createDirCalendar && data?.createDirCalendar?.id) {
          enqueueSnackbar(`Календарь "${values?.fullName}" успешно добавлен`, { variant: 'success' });
          push(`/calendar/${data?.createDirCalendar?.id}`);
        }
      }
    } catch (e) {
      if (e.message === 'A unique constraint would be violated on DirCalendar. Details: Field name = fullName') {
        enqueueSnackbar(getMessage('Неуникальное название'), { variant: 'error' });
      } else {
        enqueueSnackbar(getMessage(e.message), { variant: 'error' });
      }
    }
  };

  useEffect(() => {
    if (data?.dirCalendar) {
      reset({
        ...data?.dirCalendar,
        year: { value: data?.dirCalendar.year?.toString(), label: data?.dirCalendar.year?.toString() },
      });
    }
  }, [data, reset]);

  if (readonly)
    return (
      <Grid container>
        <Grid item container>
          <strong>Название :{` ${data?.dirCalendar?.fullName || ''}`}</strong>
        </Grid>
        <Grid item container>
          <strong>Краткое название: {` ${data?.dirCalendar?.shortName || '~'}`}</strong>
        </Grid>
        <Grid item container>
          <strong>Тип календаря: {` ${data?.dirCalendar?.type?.label || '~'}`}</strong>
        </Grid>
        <Grid item container>
          <strong>Год: {` ${data?.dirCalendar?.year || '~'}`}</strong>
        </Grid>
        <Grid item container>
          <strong>Часть ЕКП: {` ${data?.dirCalendar?.clsEkpPart?.label || '~'}`}</strong>
        </Grid>
        <Grid item container>
          <strong>Категория календаря: {` ${data?.dirCalendar?.clsCalendarCategory?.label || '~'}`}</strong>
        </Grid>
        <Grid item container>
          <strong>Номер ЕКП Минспорта России: {` ${data?.dirCalendar?.ekpMinsportNumber || '~'}`}</strong>
        </Grid>
        <Grid item container>
          <strong>
            Виды спорта: {` ${data?.dirCalendar?.dirSports?.map((sport) => sport?.label).join(', ') || '~'}`}
          </strong>
        </Grid>
        <Grid item container>
          <strong>Количество этапов календаря: {` ${data?.dirCalendar?.stageNumber?.label || '~'}`}</strong>
        </Grid>
      </Grid>
    );
  return (
    <div style={{ display: 'block' }}>
      <Grid container>
        <Grid item lg={isSpecial ? 4 : 6} md={isSpecial ? 4 : 12} xs={isSpecial ? 4 : 12}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <ContentWrapperWidget>
              <Grid container spacing={1}>
                <Grid item md={isSpecial ? 12 : 6}>
                  <Input
                    label="Название"
                    control={control}
                    error={!!errors['fullName']}
                    name="fullName"
                    rules={{ required: true }}
                    defaultValue=""
                  />
                </Grid>
                <Grid item md={isSpecial ? 12 : 6}>
                  <Input
                    label="Краткое название"
                    control={control}
                    error={!!errors['shortName']}
                    name="shortName"
                    rules={{ required: true }}
                    defaultValue=""
                  />
                </Grid>
                <Grid item md={isSpecial ? 12 : 4}>
                  <CalendarTypes
                    label="Тип календаря"
                    error={!!errors['type']}
                    control={control}
                    name="type"
                    defaultValue={{
                      value: 2,
                      label: 'ФЦПСР',
                    }}
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item md={isSpecial ? 12 : 4}>
                  <Years
                    name="year"
                    label="Год"
                    error={!!errors['year']}
                    control={control}
                    rules={{ required: true }}
                  />
                </Grid>
                {/*<Grid item md={4}>*/}
                {/*  <Season*/}
                {/*    label="Сезон"*/}
                {/*    error={!!errors['clsSeason']}*/}
                {/*    control={control}*/}
                {/*    name="clsSeason"*/}
                {/*    rules={{ required: true }}*/}
                {/*  />*/}
                {/*</Grid>*/}
                <Grid item md={isSpecial ? 12 : 4}>
                  <PartEKP
                    label="Часть ЕКП"
                    error={!!errors['clsEkpPart']}
                    control={control}
                    name="clsEkpPart"
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item md={isSpecial ? 12 : 4}>
                  <CalendarCategory
                    label="Категория календаря"
                    error={!!errors['clsCalendarCategory']}
                    control={control}
                    name="clsCalendarCategory"
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item md={isSpecial ? 12 : 6}>
                  <Input
                    label="Номер ЕКП Минспорта России"
                    control={control}
                    error={!!errors['ekpMinsportNumber']}
                    name="ekpMinsportNumber"
                    type="number"
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item md={12}>
                  <TypeSports
                    label="Виды спорта"
                    multiple
                    control={control}
                    error={!!errors['dirSports']}
                    name="dirSports"
                  />
                </Grid>
                <Grid item container alignItems="flex-end" justify="space-between">
                  <Grid item md={6}>
                    <CalendarStage
                      label="Количество этапов календаря"
                      control={control}
                      error={!!errors['stageNumber']}
                      name="stageNumber"
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      type="submit"
                      size="small"
                      startIcon={<SaveIcon color="primary" />}
                      disabled={!formState.isDirty}
                    >
                      Сохранить
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              {id && id !== 'new' && (
                <>
                  <Grid style={{ marginTop: 10 }} container alignItems="center" spacing={2}>
                    <Grid item>
                      <span style={{ fontSize: 16, fontWeight: 'bold' }}>Регламент спортивных соревнований:</span>
                    </Grid>
                    {data?.dirCalendar?.sportCompetitionRegulationDocument ? (
                      <>
                        <Grid item>
                          <Button
                            href={`${DOWNLOAD_LINK}${data?.dirCalendar?.sportCompetitionRegulationDocument?.path}`}
                            color="primary"
                            size="small"
                            variant="contained"
                          >
                            скачать
                          </Button>
                        </Grid>
                        {!readonly && data?.dirCalendar?.sportCompetitionRegulationDocument && (
                          <Grid item>
                            <Tooltip title="удалить" placement="top-start">
                              <IconButton
                                color="secondary"
                                onClick={() => setDeleteItem(data?.dirCalendar?.sportCompetitionRegulationDocument)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                            <ConfirmDialog
                              title={`Удаление регламента спортивных соревнований`}
                              text={`Вы уверены что хотите удалить документ от :  
                      ${moment(deleteItem?.date).format('DD.MM.YYYY')}`}
                              btnVariant="outlined"
                              open={Boolean(deleteItem)}
                              saveBtnText="удалить"
                              cancelBtnText="отмена"
                              saveBtnColor="secondary"
                              cancelBtnColor="primary"
                              onCancel={() => setDeleteItem(null)}
                              onSave={() => {
                                deleteDocument(deleteItem).then(() => setDeleteItem(null));
                              }}
                            />
                          </Grid>
                        )}
                      </>
                    ) : (
                      <Grid item>
                        <input
                          style={{ display: 'none' }}
                          id="contained-button-file"
                          type="file"
                          onChange={onUploadDocument}
                        />
                        <label htmlFor="contained-button-file">
                          <Button variant="contained" color="primary" component="span" size="small">
                            Добавить
                          </Button>
                        </label>
                      </Grid>
                    )}
                  </Grid>
                  <GeneralRegulationBtn id={id} />
                  <Grid style={{ marginTop: 10 }} container>
                    <CalendarDocuments id={id} readonly={readonly} />
                  </Grid>
                </>
              )}
            </ContentWrapperWidget>
          </form>
        </Grid>
        {id && id !== 'new' && isSpecial && (
          <Grid item lg={8} md={12} xs={12}>
            <CalendarEventListModal
              filter={{
                id,
              }}
              year={_.get(data, 'dirCalendar.year', 2024)}
              isMain
            />
          </Grid>
        )}
        {id && id !== 'new' && (
          <Grid item lg={12} md={12} xs={12}>
            <ArchiveWidget
              archive={data?.dirCalendar?.archive || null}
              id={id}
              refetch={refetch}
              onArchive={archive}
              onUnarchive={unarchive}
            />
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default MainInfo;
