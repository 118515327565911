import { ArchiveCreateInput, ArchiveStatusFragment, ClassifierValueFragment } from '../../../api';
import { useForm } from 'react-hook-form';
import React, { FC, useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Input, DatePicker } from '../../Inputs';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import { ContentWrapperWidget } from '../../layouts';
import { useSnackbar } from 'notistack';
import getMessage from '../../../messages';
import { ArchiveIssues } from '../../../module';
import moment from 'moment';
import ArchiveIcon from '@material-ui/icons/Archive';
import UnarchiveIcon from '@material-ui/icons/Unarchive';

interface FormType extends ArchiveCreateInput {
  reason: any;
  date: Date | null;
}

const ArchiveWidget: FC<{
  archive: ArchiveStatusFragment | null;
  id: string;
  onArchive?: (T: { variables: { archive: { create: ArchiveCreateInput } } & { id: string } }) => void;
  onUnarchive?: (T: { variables: { id: string } }) => void;
  refetch: any;
  readonly?: boolean;
  caption?: string;
}> = ({ refetch, readonly, id, onArchive, onUnarchive, archive, caption }) => {
  const { handleSubmit, control, errors, reset, formState } = useForm({
    defaultValues: { reason: null, comment: '', date: null, id } as FormType,
  });
  const [open, setOpen] = useState<boolean>(false);
  const today = new Date();
  const { enqueueSnackbar } = useSnackbar();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onArchiveHandler = async (values: FormType) => {
    try {
      if (onArchive) {
        await onArchive({
          variables: { id, archive: { create: { ...values, reason: values.reason?.label } } },
        });
        await refetch();
        handleClose();
        reset({ reason: null, comment: '', id });
        enqueueSnackbar(`Запись успешно помещена в архив`, { variant: 'success' });
      }
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  const onUnarchiveHandler = async () => {
    try {
      if (onUnarchive) {
        await onUnarchive({ variables: { id } });
        await refetch();
        enqueueSnackbar(`Запись успешно убрана из архива`, { variant: 'success' });
      }
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };
  return (
    <>
      {archive === null && (
        <Button
          variant="outlined"
          color="primary"
          startIcon={<ArchiveIcon color="primary" />}
          onClick={handleClickOpen}
        >
          Архив
        </Button>
      )}
      {archive && (
        <>
          <Tooltip
            title={
              <>
                {archive.reason && <p>Причина: {archive.reason}</p>}
                <p>Комментарий: {archive.comment ? archive.comment : '-'}</p>
              </>
            }
            placement="top-start"
          >
            <Typography>
              <span style={{ color: 'red' }}>В архиве</span> с {moment(archive.date).format('DD.MM.YY')}
            </Typography>
          </Tooltip>
          <br />
          <Button
            variant="outlined"
            color="primary"
            startIcon={<UnarchiveIcon color="secondary" />}
            onClick={() => !readonly && onUnarchiveHandler()}
          >
            Убрать из архива
          </Button>
        </>
      )}
      {open && (
        <form onSubmit={handleSubmit(onArchiveHandler)}>
          <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
              Добавление в архив{caption && <div style={{ color: 'red', fontSize: 12 }}>{caption}</div>}
            </DialogTitle>
            <DialogContent style={{ width: 400 }}>
              <Grid container direction="column" spacing={1}>
                <Grid item container>
                  <ArchiveIssues
                    label="Причина"
                    error={!!errors['reason']}
                    name="reason"
                    control={control}
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item container>
                  <Input label="Комментарий" error={!!errors['comment']} name="comment" control={control} />
                </Grid>
                <Grid item container>
                  <DatePicker
                    label="Дата"
                    control={control}
                    error={!!errors['date']}
                    name="date"
                    rules={{ required: true }}
                    maxDate={today}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button size="small" onClick={handleClose}>
                Закрыть
              </Button>
              <Button
                size="small"
                variant="outlined"
                onClick={handleSubmit(onArchiveHandler)}
                color="primary"
                startIcon={<SaveIcon />}
              >
                Сохранить
              </Button>
            </DialogActions>
          </Dialog>
        </form>
      )}
    </>
  );
};

export default ArchiveWidget;
