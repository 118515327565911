import React, { FC } from 'react';
import MultiSelect from '../components/Inputs/MultiSelect';
import { ControllerProps } from 'react-hook-form/dist/types/props';
import { useClsOrganizationCategoriesQuery } from '../api';

interface CategoryOrganizations extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  error: boolean | undefined;
  multiple?: boolean;
  disabled?: boolean;
}

const CategoryOrganizations: FC<CategoryOrganizations> = ({
  label,
  error,
  control,
  name,
  rules,
  multiple,
  disabled,
}) => {
  const { data } = useClsOrganizationCategoriesQuery();
  return (
    <MultiSelect
      multiple={multiple}
      label={label}
      data={(data && data.classifierValues) || []}
      control={control}
      error={error}
      name={name}
      rules={rules}
      disabled={disabled}
    />
  );
};

export default CategoryOrganizations;
