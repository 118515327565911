import React, { FC, useMemo } from 'react';
import { DirSport, useTypeSportDisciplinesDrillQuery } from '../api';
import { Select } from '../components/Inputs';
import { ReactHookFormSelect } from '../components/Inputs/Select';
import { sortBy as _sortBy } from 'lodash';

interface SelectCls extends Pick<ReactHookFormSelect, 'control' | 'name' | 'rules' | 'label' | 'error'> {
  filter?: any;
  multiple?: boolean;
  usedValue?: DirSport['id'][];
}

const TypeSportDisciplines: FC<SelectCls> = ({ multiple = true, filter = {}, label, error, control, name, rules }) => {
  const { data } = useTypeSportDisciplinesDrillQuery({ variables: { filter } });
  const sortedSportDisciplines = useMemo(() => {
    if (!data?.dirSportDisciplines) return [];
    // Вот так не работатет
    // data?.dirSportDisciplines.sort -> Cannot assign to read only property '0' of object '[object Array]'
    // Вот так работает -> создается новый массив и сортируется, потому что старый заморожен
    return [...data?.dirSportDisciplines].sort((a, b) => {
      const labelA = a?.label.toLowerCase(),
        labelB = b?.label.toLowerCase();
      if (labelA < labelB) return -1;
      if (labelA > labelB) return 1;
      return 0;
    });
  }, [data?.dirSportDisciplines]);

  return (
    <Select
      multiple={multiple}
      label={label}
      data={sortedSportDisciplines || []}
      control={control}
      error={error}
      name={name}
      rules={rules}
    />
  );
};

export default TypeSportDisciplines;
