import {
  Accordion,
  AccordionDetails,
  Grid,
  AccordionSummary as MuiAccordionSummary,
  Typography,
  withStyles,
} from '@material-ui/core';
import { ContentWrapperWidget, MainTemplate } from '../../components/layouts';
import React, { ChangeEvent, FC, useState } from 'react';

import Calendar from '../../components/widgets/Minsports/Calendar';
import CompetitionResults from '../../components/widgets/Minsports/CompetitionResults';
import Costs from '../../components/widgets/Minsports/Costs';
import Documents from '../../components/widgets/Minsports/Documents';
import EditLocationIcon from '@material-ui/icons/EditLocation';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MainInfo from '../../components/widgets/Minsports/MainInfo';
import ProgramTypes from '../../components/widgets/Minsports/ProgramTypes';
import Rules from '../../components/widgets/Minsports/Rules';
import RulesFull from '../../components/widgets/Minsports/RulesFull';
import SportingEventRequests from '../../components/widgets/Minsports/SportingEventRequests';
import TeamInfo from '../../components/widgets/Minsports/TeamInfo';
import { blue } from '@material-ui/core/colors';
import { detectedChanges } from '../../utils/compareSportingEvents';
import onExpand from '../../utils/onExpand';
import { useRouteMatch } from 'react-router-dom';
import { useSportingEventRequestQuery } from '../../api';

type AccordionTypes =
  | 'MainInfo'
  | 'Calendar'
  | 'TeamInfo'
  | 'Rules'
  | 'ProgramTypes'
  | 'CompetitionResults'
  | 'Documents'
  | 'SportingEventRequests'
  | 'Сonsumption'
  | undefined;

const AccordionSummary = withStyles({
  root: {
    backgroundColor: blue[700],
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    color: 'white',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary) as any;

const SingleDependOnRequest: FC<{}> = () => {
  const {
    params: { id },
  } = useRouteMatch();

  const filter = { id };

  const { loading, data, refetch } = useSportingEventRequestQuery({
    skip: !id || id === 'new',
    fetchPolicy: 'no-cache',
    variables: { filter },
  });

  const currentRequest = data?.dirSportingEventRequests[0] || null;

  const isRequestExcludedFromEkp = currentRequest?.type?.value === 3;

  const [expanded, setExpanded] = useState<AccordionTypes>('SportingEventRequests');

  const handleChangeAccordion = (panel: AccordionTypes) => (event: ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : undefined);
  };

  const dChanges = detectedChanges(currentRequest?.event).changesArray;

  const readonly = currentRequest?.event?.status?.label !== 'Проект' && id !== 'new';

  return (
    <MainTemplate pageName="" url="">
      <ContentWrapperWidget>
        {
          <h2>
            {`Заявка: ${currentRequest?.event?.registryNumber || ''} - ${
              (currentRequest?.orderNumber && (currentRequest?.orderNumber || 0) < 10 && '0') || ''
            }${currentRequest?.orderNumber || ''}  ${currentRequest?.event?.name || ''}`}
          </h2>
        }
        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'SportingEventRequests'}
          onChange={handleChangeAccordion('SportingEventRequests')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Заявка</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {onExpand(expanded, 'SportingEventRequests') && currentRequest?.event?.id && (
              <SportingEventRequests
                id={currentRequest?.event?.id}
                requestId={id}
                isRequestPage
                readonly={readonly}
                onStatusUpdated={() => refetch()}
              />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new' || isRequestExcludedFromEkp}
          expanded={expanded === 'MainInfo'}
          onChange={handleChangeAccordion('MainInfo')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Общая информация</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {onExpand(expanded, 'MainInfo') && currentRequest?.event?.id && (
              <MainInfo
                id={currentRequest?.event?.id}
                readonly={readonly}
                onStatusUpdated={() => refetch()}
                isRequestPage
              />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={
            !currentRequest?.event?.isOrganizerOrParticipant === false || isRequestExcludedFromEkp || id === 'new'
          }
          expanded={expanded === 'TeamInfo'}
          onChange={handleChangeAccordion('TeamInfo')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Участники</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>
            {onExpand(expanded, 'TeamInfo') && <RulesFull id={currentRequest?.event?.id} readonly={readonly} />}
          </AccordionDetails>
        </Accordion>
        <Accordion
          id={'peoplesBlock'}
          disabled={
            id === 'new' || isRequestExcludedFromEkp || currentRequest?.event?.isOrganizerOrParticipant === false
          }
          expanded={expanded === 'Rules'}
          onChange={handleChangeAccordion('Rules')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Команда</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>
            {onExpand(expanded, 'Rules') && <Rules id={currentRequest?.event?.id} readonly={readonly} />}
          </AccordionDetails>
        </Accordion>
        <Accordion disabled={isRequestExcludedFromEkp}>
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>
              {currentRequest?.event?.isOrganizerOrParticipant === false
                ? 'Расходы на организацию'
                : 'Расходы на участие'}
            </Typography>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>
            {expanded === 'Сonsumption' && id}
            {
              currentRequest?.event?.isOrganizerOrParticipant === false ? (
                <Costs id={currentRequest?.event?.id} readonly={readonly} />
              ) : (
                <Costs id={currentRequest?.event?.id} readonly={readonly} />
              ) //'Расходы на участие'
            }
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new' || isRequestExcludedFromEkp}
          expanded={expanded === 'Documents'}
          onChange={handleChangeAccordion('Documents')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Отчетные документы</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>
            {onExpand(expanded, 'Documents') && id && <Documents id={currentRequest?.event?.id} />}
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new' || isRequestExcludedFromEkp}
          expanded={expanded === 'ProgramTypes'}
          onChange={handleChangeAccordion('ProgramTypes')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Виды программ</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>
            {onExpand(expanded, 'ProgramTypes') && <ProgramTypes id={currentRequest?.event?.id} />}
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new' || isRequestExcludedFromEkp}
          expanded={expanded === 'CompetitionResults'}
          onChange={handleChangeAccordion('CompetitionResults')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Результаты соревнований</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>
            {onExpand(expanded, 'CompetitionResults') && <CompetitionResults id={currentRequest?.event?.id} />}
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={
            id === 'new' || isRequestExcludedFromEkp || currentRequest?.event?.isOrganizerOrParticipant === false
          }
          expanded={expanded === 'Calendar'}
          onChange={handleChangeAccordion('Calendar')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Программа мероприятия</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {onExpand(expanded, 'Calendar') && currentRequest?.event?.id && (
              <Calendar id={currentRequest?.event?.id} readonly={readonly} />
            )}
          </AccordionDetails>
        </Accordion>
      </ContentWrapperWidget>
    </MainTemplate>
  );
};

export default SingleDependOnRequest;
