import {
  Button,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@material-ui/core';
import { Countries, TypeSports } from '../../../module';
import {
  CreatePersonCitizenshipMutationHookResult,
  CreatePersonMutationHookResult,
  PersonArchiveMutationHookResult,
  PersonByIdDocument,
  PersonUnArchiveMutationHookResult,
  RemovePersonCitizenshipMutationHookResult,
  Scalars,
  UpdatePersonMutationHookResult,
  useCountriesFetchQuery,
  useCreatePersonCitizenshipMutation,
  useCreatePersonMutation,
  useFindSimiliarPersonLazyQuery,
  usePersonArchiveMutation,
  usePersonByIdLazyQuery,
  usePersonUnArchiveMutation,
  useRemovePersonCitizenshipMutation,
  useUpdatePersonMutation,
} from '../../../api';
import { DatePicker, Input } from '../../Inputs';
import React, { FC, useEffect, useState } from 'react';
import StatusConfirm, { OPTIONS } from '../../../module/StatusConfirm';

import AddRegionsBox from './components/AddRegionsBox';
import ArchiveWidget from '../Shared/Archive';
import { ContentWrapperWidget } from '../../layouts';
import EditIcon from '@material-ui/icons/Edit';
import ForeignCities from '../../../module/ForeignCities';
import GavelIcon from '@material-ui/icons/Gavel';
import { Link } from 'react-router-dom';
import MultiSelect from '../../Inputs/MultiSelect';
import PersonIcon from '@material-ui/icons/Person';
import Radios from '../../Inputs/Radio';
import Regions from '../../../module/Regions';
import SaveIcon from '@material-ui/icons/Save';
import SearchIcon from '@material-ui/icons/Search';
import SportsHandballRoundedIcon from '@material-ui/icons/SportsHandballRounded';
import StatusDop from './components/StatusDop';
import TimelineRoundedIcon from '@material-ui/icons/TimelineRounded';
import WithLoader from '../../WithLoader';
import _ from 'lodash';
import getMessage from '../../../messages';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import TypeSportsSelect from '../../../module/TypeSportsSelect';

const MainInfo: FC<{
  readonly?: boolean;
  id?: string;
  isAthlete?: boolean;
  isTrainer?: boolean;
  isReferee?: boolean;
  onUpdate?: (id?: string, sportType?: any) => void;
}> = ({ readonly, isAthlete, isTrainer, isReferee, id, onUpdate }) => {
  const [fetchPerson, { data, refetch }] = usePersonByIdLazyQuery({
    variables: { id: id === 'new' ? '' : id },
    fetchPolicy: 'no-cache',
  });
  const [findSimilar, { called, data: resultSimilar, loading }] = useFindSimiliarPersonLazyQuery({
    variables: { search: '' },
  });
  const { data: countryData } = useCountriesFetchQuery();
  const [editFIO, setEditFIO] = useState(false);
  const { handleSubmit, watch, control, errors, reset, formState, setValue, getValues } = useForm({
    defaultValues: {
      email: null,
      dirCountry: null,
      typesport: null,
      ...data?.dirPerson,
      isMale: data?.dirPerson?.isMale === undefined ? null : data?.dirPerson?.isMale ? 'true' : 'false',
      isRussia: data?.dirPerson?.isRussia === undefined ? 'true' : data?.dirPerson?.isRussia ? 'true' : 'false',
    } as any,
  });
  const { push } = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  let isRussia = watch('isRussia');
  const [archive]: PersonArchiveMutationHookResult = usePersonArchiveMutation();
  const [unarchive]: PersonUnArchiveMutationHookResult = usePersonUnArchiveMutation();
  const [create]: CreatePersonMutationHookResult = useCreatePersonMutation();
  const [update]: UpdatePersonMutationHookResult = useUpdatePersonMutation();
  const [addCitizenship]: CreatePersonCitizenshipMutationHookResult = useCreatePersonCitizenshipMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: PersonByIdDocument,
        data: {
          ...data?.updateDirPerson,
        },
      });
    },
  });

  const [removeCitizenship]: RemovePersonCitizenshipMutationHookResult = useRemovePersonCitizenshipMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: PersonByIdDocument,
        data: {
          ...data?.updateDirPerson,
        },
      });
    },
  });
  const []: UpdatePersonMutationHookResult = useUpdatePersonMutation();
  const watchCountry = watch('dirCountry');
  let watchTypeSport = watch('typesport', null);
  useEffect(() => {
    if (watchCountry?.value !== data?.dirPerson?.dirCountry?.value) {
      setValue('dirForeignCity', null);
    }
  }, [data, setValue, watchCountry]);

  useEffect(() => {
    if (id !== '' && id !== 'new') {
      fetchPerson({
        variables: { id },
      });
    }
  }, [fetchPerson, id]);

  useEffect(() => {
    if (id === 'new' || id === '') {
      const values = getValues();
      reset({
        ...values,
        dirCitizenships: countryData?.dirCountries?.filter((item) => item.label === 'Россия'),
      });
    }
  }, [countryData, getValues, id, reset]);

  const onSubmit = async (values: any) => {
    if (id === 'new' && formState.isDirty) {
      await findSimilar({
        variables: {
          search: [values.lastname, values.firstname, values.patronymic].join(' '),
          filter: {
            archive: null,
          },
        },
      });
      reset(values);
      return;
    }

    // Получаем список различий по гражданству
    // _.differenceBy([{ 'x': 2 }, { 'x': 1 }], [{ 'x': 1 }], 'x');
    const addCitizensipsArr = _.differenceBy(values.dirCitizenships, data?.dirPerson?.dirCitizenships || [], 'value');
    const removeCitizensipsArr = _.differenceBy(
      data?.dirPerson?.dirCitizenships || [],
      values.dirCitizenships,
      'value',
    );
    // Если коффицент > 0.7 значит рассмотрение если меньше то подтвержден

    try {
      if (id !== 'new') {
        await update({
          variables: {
            id,
            ...values,
            email: values?.email === '' ? null : values?.email,
            ...(!editFIO && {
              firstname: data?.dirPerson?.firstname,
              lastname: data?.dirPerson?.lastname,
              patronymic: data?.dirPerson?.patronymic,
            }),
            countryID:
              values?.isRussia === 'false' && values?.dirCountry?.value
                ? { connect: { id: values?.dirCountry?.value } }
                : data?.dirPerson?.dirCountry !== null
                ? { disconnect: true }
                : null,
            foreignCityID:
              values?.isRussia === 'false' && values?.dirForeignCity?.value
                ? { connect: { id: values?.dirForeignCity?.value } }
                : data?.dirPerson?.dirForeignCity !== null
                ? { disconnect: true }
                : null,
            regionID:
              values?.isRussia === 'true'
                ? values?.dirRegion?.value && { connect: { id: values?.dirRegion?.value } }
                : data?.dirPerson?.dirRegion !== null
                ? { disconnect: true }
                : null,
            isMale: values?.isMale === 'false' ? false : true,
            isRussia: values?.isRussia === 'false' ? false : true,
            confirmStatus: values?.confirmStatus?.value && { connect: { id: values?.confirmStatus?.value } },
          },
        });
        onUpdate && onUpdate();
        addCitizensipsArr.forEach((item: any) => {
          addCitizenship({
            variables: {
              id,
              idItem: item?.value,
            },
          });
        });
        removeCitizensipsArr.forEach((item: any) => {
          removeCitizenship({
            variables: {
              id,
              idItem: item?.value,
            },
          });
        });
        enqueueSnackbar(
          `Персона "${[
            values.lastname || data?.dirPerson?.lastname,
            values.firstname || data?.dirPerson?.firstname,
            values.patronymic || data?.dirPerson?.patronymic,
          ].join(' ')}" успешно обновлена`,
          { variant: 'success' },
        );
      } else {
        const { data } = await create({
          variables: {
            ...values,
            email: values?.email === '' ? null : values?.email,
            countryID: values?.dirCountry?.value && { connect: { id: values?.dirCountry?.value } },
            foreignCityID:
              (values?.dirForeignCity?.value && { connect: { id: values?.dirForeignCity?.value } }) || null,
            regionID: (values?.dirRegion?.value && { connect: { id: values?.dirRegion?.value } }) || null,
            isMale: values?.isMale === 'false' ? false : true,
            isRussia: values?.isRussia === 'false' ? false : true,
            confirmStatus:
              resultSimilar?.dirPersonsSimilar?.length === 0
                ? { connect: { id: 2 } }
                : {
                    connect: {
                      id:
                        (
                          resultSimilar?.dirPersonsSimilar?.filter(
                            (person) => person?.similarity && person?.similarity >= 0.7,
                          ) || []
                        ).length > 0
                          ? 1
                          : 2,
                    },
                  },
          },
        });
        if (data?.createDirPerson && data?.createDirPerson?.id) {
          enqueueSnackbar(
            `Персона "${[values.lastname, values.firstname, values.patronymic].join(' ')}" успешно добавлена`,
            { variant: 'success' },
          );
          addCitizensipsArr.forEach((item: any) => {
            addCitizenship({
              variables: {
                id: data?.createDirPerson?.id,
                idItem: item?.value,
              },
            });
          });
          removeCitizensipsArr.forEach((item: any) => {
            removeCitizenship({
              variables: {
                id: data?.createDirPerson?.id,
                idItem: item?.value,
              },
            });
          });
          onUpdate && onUpdate(data?.createDirPerson?.id, values?.typesport?.value);
          !onUpdate && push(`/person/${data?.createDirPerson?.id}`);
        }
      }
      setEditFIO(false);
    } catch (e) {
      if (e.graphQLErrors[0].code === 3010) {
        enqueueSnackbar(getMessage('Не уникальный E-mail'), { variant: 'error' });
      } else {
        enqueueSnackbar(getMessage(e.message), { variant: 'error' });
      }
    }
  };

  const createRoleFromDuplicate = async (duplicateId?: Scalars['UUID'], hasSport?: boolean) => {
    if (hasSport) {
      onUpdate && onUpdate(duplicateId, watchTypeSport?.value);
      !onUpdate && push(`/person/${duplicateId}`);
      return;
    } else {
      onUpdate && onUpdate(duplicateId);
      !onUpdate && push(`/person/${duplicateId}`);
      return;
    }
  };

  useEffect(() => {
    if (data?.dirPerson) {
      reset({
        ...data?.dirPerson,
        isMale: data?.dirPerson?.isMale === undefined ? null : data?.dirPerson?.isMale ? 'true' : 'false',
        isRussia: data?.dirPerson?.isRussia === undefined ? null : data?.dirPerson?.isRussia ? 'true' : 'false',
        confirmStatus: data.dirPerson.confirmStatus
          ? OPTIONS.filter((value) => value.value === data?.dirPerson?.confirmStatus?.value)?.[0]
          : null,
        dirCitizenships:
          data.dirPerson?.dirCitizenships || countryData?.dirCountries?.filter((item) => item.label === 'Россия'),
      });
    }
  }, [countryData, data, reset]);

  if (readonly)
    return (
      <Grid container>
        <Grid item container>
          <strong>
            {`ФИО : ${data?.dirPerson?.lastname || ''} ${data?.dirPerson?.firstname || ''} ${
              data?.dirPerson?.patronymic || ''
            }`}
          </strong>
        </Grid>
        <Grid item container>
          <strong>Предыдущая фамилия: {` ${data?.dirPerson?.previousLastname || '~'}`}</strong>
        </Grid>
        <Grid item container>
          <strong>Email: {` ${data?.dirPerson?.email || '~'}`}</strong>
        </Grid>
        <Grid item container>
          <strong>Дата рождения: {` ${moment(data?.dirPerson?.birthday).format('DD.MM.YY') || ''}`}</strong>
        </Grid>
        {isAthlete && id && id !== 'new' && (
          <Grid item container>
            <StatusDop />
          </Grid>
        )}
        <Grid item container>
          <strong> Пол: {` ${(data?.dirPerson?.isMale === true && 'Муж') || 'Жен' || ''}`}</strong>
        </Grid>
        <Grid item container>
          <strong>Страна: {`${(data?.dirPerson?.isRussia === true && 'Россия') || 'Зарубеж' || ''}`}</strong>
        </Grid>
        {data?.dirPerson?.isRussia === true && (
          <Grid item container>
            <strong>Регион РФ: {`${data?.dirPerson?.dirRegion?.label || ''}`}</strong>
          </Grid>
        )}
        {data?.dirPerson?.isRussia === false && (
          <Grid item container>
            <strong>
              Название города: {`${data?.dirPerson?.dirForeignCity?.label || data?.dirPerson?.dirCountry?.label || ''}`}
            </strong>
          </Grid>
        )}
      </Grid>
    );

  return (
    <>
      <Grid container>
        <Grid item container md={6} xs={12}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <ContentWrapperWidget>
              <Grid container direction="column" spacing={1}>
                {id !== 'new' && data && !editFIO && (
                  <Grid item container>
                    <Grid
                      item
                      container
                      justify="space-between"
                      alignItems="center"
                      style={{ borderBottom: '1px solid gray' }}
                      md={12}
                    >
                      <Grid item>
                        <strong>{`${data?.dirPerson?.lastname || ''} ${data?.dirPerson?.firstname || ''} ${
                          data?.dirPerson?.patronymic || ''
                        }`}</strong>
                      </Grid>
                      <Grid item>
                        <Tooltip title="Редактировать" placement="top">
                          <IconButton color="primary" onClick={() => setEditFIO(true)}>
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
                {(id === 'new' || editFIO) && (
                  <>
                    <Grid item md={12}>
                      <Input
                        label="Фамилия"
                        control={control}
                        error={!!errors['lastname']}
                        name="lastname"
                        rules={{
                          required: true,
                          pattern: {
                            value: /^[ЁёА-я -]+$/,
                            message: 'Поле может содержать только русские буквы',
                          },
                        }}
                        disabled={readonly}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <Input
                        label="Имя"
                        control={control}
                        error={!!errors['firstname']}
                        name="firstname"
                        rules={{
                          required: true,
                          pattern: {
                            value: /^[ЁёА-я -]+$/,
                            message: 'Поле может содержать только русские буквы',
                          },
                        }}
                        disabled={readonly}
                      />
                    </Grid>
                    <Grid item md={12}>
                      <Input
                        label="Отчество"
                        control={control}
                        error={!!errors['patronymic']}
                        name="patronymic"
                        rules={{
                          pattern: {
                            value: /^[ЁёА-я -]+$/,
                            message: 'Поле может содержать только русские буквы',
                          },
                        }}
                        disabled={readonly}
                      />
                    </Grid>
                  </>
                )}

                <Grid item md={12}>
                  <Input
                    label="Предыдущая фамилия"
                    control={control}
                    error={!!errors['previousLastname']}
                    name="previousLastname"
                    disabled={readonly}
                  />
                </Grid>
                <Grid item container md={12}>
                  <DatePicker
                    label="Дата рождения"
                    control={control}
                    error={!!errors['birthday']}
                    name="birthday"
                    rules={{ required: true }}
                    disabled={readonly}
                  />
                </Grid>
                <Grid item md={12}>
                  <Input label="Email" control={control} error={!!errors['email']} name="email" disabled={readonly} />
                </Grid>
                {isAthlete && id && id !== 'new' && (
                  <Grid item container md={12}>
                    <StatusDop />
                  </Grid>
                )}
                {id === 'new' && (isAthlete || isTrainer) && (
                  <Grid item md={12}>
                    <TypeSportsSelect
                      label="Вид спорта"
                      error={!!errors['typesport']}
                      name="typesport"
                      control={control}
                      rules={{
                        required: true,
                        validate: async (val) => (val?.value ? undefined : 'Поле обязательное для заполнения'),
                      }}
                    />
                  </Grid>
                )}

                <Grid item md={12} style={{ marginTop: 10 }}>
                  <Radios
                    label="Пол"
                    data={[
                      {
                        value: 'true',
                        label: 'Муж',
                      },
                      {
                        value: 'false',
                        label: 'Жен',
                      },
                    ]}
                    control={control}
                    error={!!errors['isMale']}
                    name="isMale"
                    rules={{ required: true }}
                    disabled={readonly}
                  />
                </Grid>
                <Divider flexItem style={{ width: '100%', height: 1, margin: '10px 0' }} />
                <Grid item container direction="column" spacing={2} alignItems={'baseline'}>
                  <Grid item container md={12}>
                    <Grid item md={12}>
                      <Radios
                        label="Страна"
                        data={[
                          {
                            value: 'true',
                            label: 'Россия',
                          },
                          {
                            value: 'false',
                            label: 'Зарубеж',
                          },
                        ]}
                        control={control}
                        error={!!errors['isRussia']}
                        name="isRussia"
                        rules={{ required: true }}
                        disabled={readonly}
                      />
                    </Grid>
                    {isRussia === 'true' && (
                      <Grid item md={12}>
                        <Regions
                          label="Регион РФ"
                          control={control}
                          error={!!errors['dirRegion']}
                          name="dirRegion"
                          rules={{
                            required: true,
                            validate: async (val) => (val?.value ? undefined : 'Поле обязательное для заполнения'),
                          }}
                          disabled={readonly}
                        />
                      </Grid>
                    )}
                    {isRussia === 'false' && (
                      <>
                        <Grid item md={12}>
                          <Countries
                            label="Название страны"
                            control={control}
                            error={!!errors['dirCountry']}
                            name="dirCountry"
                            rules={{
                              required: true,
                              validate: async (val) => (val?.value ? undefined : 'Поле обязательное для заполнения'),
                            }}
                            disabled={readonly}
                          />
                        </Grid>
                        {watchCountry?.value && (
                          <Grid item md={12}>
                            <ForeignCities
                              label="Название города"
                              control={control}
                              filter={{ dirCountry: { id: watchCountry?.value } }}
                              error={!!errors['dirForeignCity']}
                              name="dirForeignCity"
                              disabled={readonly}
                            />
                          </Grid>
                        )}
                      </>
                    )}
                    {isReferee && (
                      <Grid item md={12} style={{ marginTop: 10 }}>
                        <Input
                          label="Город проживания"
                          control={control}
                          error={!!errors['residenceCity']}
                          name="residenceCity"
                          disabled={readonly}
                        />
                      </Grid>
                    )}
                  </Grid>
                  <Grid item container>
                    <AddRegionsBox updated={() => refetch && refetch()} readonly={readonly} person={data?.dirPerson} />
                  </Grid>
                </Grid>
                <Divider flexItem style={{ width: '100%', height: 1, margin: '10px 0' }} />

                {id !== 'new' && (
                  <Grid item md={12}>
                    <StatusConfirm
                      label="Статус подтверждения"
                      control={control}
                      error={!!errors['confirmStatus']}
                      name="confirmStatus"
                      rules={{
                        required: true,
                        validate: async (val) => (val?.value ? undefined : 'Поле обязательное для заполнения'),
                      }}
                      disabled={readonly}
                    />
                  </Grid>
                )}
                <Grid item md={12}>
                  <MultiSelect
                    multiple
                    label="Гражданство"
                    data={countryData?.dirCountries || []}
                    control={control}
                    error={!!errors['dirCitizenships']}
                    name="dirCitizenships"
                    disabled={readonly}
                  />
                </Grid>

                <Grid item md={12}></Grid>
                <Grid item container md={12}>
                  {id === 'new' && (
                    <WithLoader isLoading={loading} maxHeight={250}>
                      {resultSimilar && resultSimilar?.dirPersonsSimilar.length > 0 && (
                        <Grid item md={12}>
                          <h4>Найдены дубликаты: {resultSimilar?.dirPersonsSimilar.length} </h4>
                          <List>
                            {resultSimilar?.dirPersonsSimilar?.map(({ person, similarity }) => {
                              return (
                                <ListItem
                                  button
                                  // onClick={() => push(`/person/${person.registryNumber}`)}
                                  key={person.registryNumber}
                                  style={{ flexDirection: 'column' }}
                                >
                                  <Grid container spacing={2} style={{ marginBottom: 10 }}>
                                    <Grid item xs={4}>
                                      <ListItemText
                                        primary={`${person.lastname} ${person.firstname} ${
                                          person.patronymic ? person.patronymic : ''
                                        }`}
                                        secondary={`${person.registryNumber}`}
                                        style={{ marginRight: '32px', flex: '0 1 auto' }}
                                      />
                                    </Grid>

                                    {person.id && (
                                      <Grid item container spacing={1} xs={4}>
                                        <Grid item>
                                          <ListItemIcon>
                                            <PersonIcon />
                                            <Link
                                              style={{
                                                fontWeight: 500,
                                                cursor: 'pointer',
                                                textDecoration: 'none',
                                              }}
                                              target="_blank"
                                              to={`/person/${person.id}`}
                                            >
                                              Персона
                                            </Link>
                                          </ListItemIcon>
                                        </Grid>
                                        <Grid item xs={12}>
                                          <strong style={{ color: '#000' }}>{`${
                                            person?.dirRegion?.fullName || person?.dirForeignCity?.fullName || ''
                                          } ${
                                            (person?.birthday && moment(person?.birthday).format('DD.MM.YYYY')) || ''
                                          }`}</strong>
                                        </Grid>
                                      </Grid>
                                    )}

                                    {person.trainerProfile?.id && (
                                      <Grid item container spacing={1} xs={4}>
                                        <Grid item>
                                          <ListItemIcon style={{ marginRight: 16 }}>
                                            <TimelineRoundedIcon />
                                            <Link
                                              style={{
                                                fontWeight: 500,
                                                cursor: 'pointer',
                                                textDecoration: 'none',
                                              }}
                                              target="_blank"
                                              to={`/trainer/${person.trainerProfile?.id}`}
                                            >
                                              Тренер
                                            </Link>
                                          </ListItemIcon>
                                        </Grid>
                                        <Grid item xs={12}>
                                          <strong style={{ color: '#000' }}>
                                            {person.trainerProfile?.sports
                                              ?.map((sport) => sport?.dirSport?.fullName)
                                              ?.join(', ')}
                                          </strong>
                                        </Grid>
                                      </Grid>
                                    )}
                                    {person.athleteProfile?.id && (
                                      <Grid item container spacing={1} xs={4}>
                                        <Grid item>
                                          <ListItemIcon style={{ marginRight: 16 }}>
                                            <SportsHandballRoundedIcon />
                                            <Link
                                              style={{
                                                fontWeight: 500,
                                                cursor: 'pointer',
                                                textDecoration: 'none',
                                              }}
                                              target="_blank"
                                              to={`/athlete/${person.athleteProfile?.id}`}
                                            >
                                              Атлет
                                            </Link>
                                          </ListItemIcon>
                                        </Grid>
                                        <Grid item xs={12}>
                                          <strong style={{ color: '#000' }}>
                                            {person.athleteProfile?.sports
                                              ?.map((sport) => sport?.dirSport?.fullName)
                                              ?.join(', ')}
                                          </strong>
                                        </Grid>
                                      </Grid>
                                    )}
                                    {person.refereeProfile?.id && (
                                      <Grid item container spacing={1} xs={4}>
                                        <Grid item>
                                          <ListItemIcon style={{ marginRight: 16 }}>
                                            <GavelIcon />
                                            <Link
                                              style={{
                                                fontWeight: 500,
                                                cursor: 'pointer',
                                                textDecoration: 'none',
                                              }}
                                              target="_blank"
                                              to={`/referee/${person.refereeProfile?.id}`}
                                            >
                                              Судья
                                            </Link>
                                          </ListItemIcon>
                                        </Grid>
                                        <Grid item xs={12}>
                                          <strong style={{ color: '#000' }}>
                                            {person.refereeProfile?.sports
                                              ?.map((sport) => sport?.dirSport?.fullName)
                                              ?.join(', ')}
                                          </strong>
                                        </Grid>
                                      </Grid>
                                    )}
                                  </Grid>
                                  <Grid item container spacing={2}>
                                    {isTrainer && !person.trainerProfile?.id && (
                                      <Grid item xs={4}>
                                        <Button
                                          variant="outlined"
                                          color="primary"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            createRoleFromDuplicate(person.id, getValues()?.typesport?.value);
                                          }}
                                          style={{ marginLeft: 'auto' }}
                                        >
                                          Создать тренера
                                        </Button>
                                      </Grid>
                                    )}
                                    {isAthlete && !person.athleteProfile?.id && (
                                      <Grid item xs={4}>
                                        <Button
                                          variant="outlined"
                                          color="primary"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            createRoleFromDuplicate(person.id, getValues()?.typesport?.value);
                                          }}
                                          style={{ marginLeft: 'auto' }}
                                        >
                                          Создать спортсмена
                                        </Button>
                                      </Grid>
                                    )}
                                    {isReferee && !person.refereeProfile?.id && (
                                      <Grid item xs={4}>
                                        <Button
                                          variant="outlined"
                                          color="primary"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            createRoleFromDuplicate(person.id, getValues()?.typesport?.value);
                                          }}
                                          style={{ marginLeft: 'auto' }}
                                        >
                                          Создать судью
                                        </Button>
                                      </Grid>
                                    )}
                                  </Grid>
                                </ListItem>
                              );
                            })}
                          </List>
                        </Grid>
                      )}
                    </WithLoader>
                  )}
                </Grid>

                {!readonly && (
                  <Grid item md={12}>
                    <Button
                      variant="outlined"
                      color="primary"
                      type="submit"
                      size="small"
                      startIcon={id === 'new' && formState.isDirty ? <SearchIcon color="primary" /> : <SaveIcon />}
                    >
                      {id === 'new' && formState.isDirty
                        ? 'Проверить на повторяемость'
                        : id !== 'new'
                        ? 'Сохранить'
                        : 'Создать новую персону'}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </ContentWrapperWidget>
          </form>
        </Grid>
        {id && id !== 'new' && !readonly && (
          <Grid item container md={12} xs={12}>
            <ArchiveWidget
              archive={data?.dirPerson?.archive || null}
              id={id}
              refetch={refetch}
              onArchive={archive}
              onUnarchive={unarchive}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default MainInfo;
