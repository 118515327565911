import { useMemo } from 'react';

function useSortSportDisciplines(programTypes: any): any {
  const sortedSportDisciplines = useMemo(() => {
    if (programTypes) {
      const resultArray = [...programTypes];
      resultArray.sort((a: any, b: any) => {
        const labelA = a?.discipline?.label.toLowerCase(),
          labelB = b?.discipline?.label.toLowerCase();
        if (labelA < labelB) return -1;
        if (labelA > labelB) return 1;
        return 0;
      });
      return resultArray;
    }
  }, [programTypes]);
  return sortedSportDisciplines;
}

export default useSortSportDisciplines;
