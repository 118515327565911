import { useNationalTeamQuery } from '../../../api';
import React, { FC } from 'react';
import { Grid, Link, LinearProgress, Typography } from '@material-ui/core';
import moment from 'moment';
import _ from 'lodash';

const ChangesHistory: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const { loading, data } = useNationalTeamQuery({ skip: !id, variables: { id }, fetchPolicy: 'no-cache' });

  if (loading) return <LinearProgress color="primary" variant="query" />;
  return (
    <Grid container>
      {_.flattenDeep(data?.dirNationalTeam?.athleteGroups?.map((g) => g?.athletes))?.map((athlete, index) => (
        <Grid item container key={athlete?.id} spacing={2}>
          <Grid item md={3} xs={12}>
            {index + 1}.{' '}
            <Link
              style={{ cursor: 'pointer', fontSize: 16 }}
              target="_blank"
              href={`/athlete/${athlete?.dirAthlete?.id}`}
            >{`${athlete?.dirAthlete?.dirPerson?.lastname || ''} ${athlete?.dirAthlete?.dirPerson?.firstname || ''} ${
              athlete?.dirAthlete?.dirPerson?.patronymic || ''
            }`}</Link>
          </Grid>
          <Grid item md={3} xs={12}>
            {' '}
            <Typography style={{ fontSize: 16 }}>{athlete?.dirAthlete?.dirPerson?.registryNumber}</Typography>
          </Grid>
          <Grid item md={3} xs={12}>
            {' '}
            <Typography style={{ fontSize: 16 }}>
              {(athlete?.dirAthlete?.dirPerson?.birthday &&
                moment(athlete?.dirAthlete?.dirPerson?.birthday).format('DD.MM.YYYY')) ||
                ''}
            </Typography>
          </Grid>
          <Grid item md={3} xs={12}>
            <Typography style={{ fontSize: 16 }}>{`Включен в сборную: ${
              (athlete && moment(athlete?.createdAt).format('DD.MM.YYYY')) || ''
            }`}</Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default ChangesHistory;
