import { Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import {
  GroupTypesSportAndDisciplinesDocument,
  TypeSportRanksAndTitlesCreateMutationHookResult,
  TypeSportRanksAndTitlesRemoveMutationHookResult,
  useTypeSportRanksAndTitlesCreateMutation,
  useTypeSportRanksAndTitlesQuery,
  useTypeSportRanksAndTitlesRemoveMutation,
} from '../../../api';
import React, { FC, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import ConfirmDialog from '../../ConfirmDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import { RanksAndTitle } from '../../../module';
import getMessage from '../../../messages';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

const RanksAndTitles: FC<{ readonly?: boolean; id: string }> = ({ readonly, id }) => {
  const { data } = useTypeSportRanksAndTitlesQuery({ variables: { id }, fetchPolicy: 'no-cache' });
  const [deleteItem, setDeleteItem] = useState<any>(null);
  const { handleSubmit, control, errors, reset } = useForm({
    defaultValues: { item: null },
  });
  const { enqueueSnackbar } = useSnackbar();
  const [create]: TypeSportRanksAndTitlesCreateMutationHookResult = useTypeSportRanksAndTitlesCreateMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: GroupTypesSportAndDisciplinesDocument,
        data: {
          ...data?.updateDirSport,
        },
      });
    },
  });

  const [remove]: TypeSportRanksAndTitlesRemoveMutationHookResult = useTypeSportRanksAndTitlesRemoveMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: GroupTypesSportAndDisciplinesDocument,
        data: {
          ...data?.updateDirSport,
        },
      });
    },
  });

  const onRemove = async (value: string, label: string) => {
    try {
      await remove({
        variables: {
          id,
          idItem: value,
        },
      });
      enqueueSnackbar(`Запись "${label}" успешно удалена`, { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  const onSubmit = handleSubmit(async (values?: { item?: { label: string; value: any } | null }) => {
    if (!values?.item) return;
    try {
      await create({
        variables: {
          id,
          idItem: values?.item?.value,
        },
      });
      reset({ item: null });
      enqueueSnackbar(`Запись "${values?.item?.label}" успешно добавлена`, { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  });

  return (
    <Grid container>
      <Grid item md={6}>
        <List>
          {!readonly && (
            <form onSubmit={onSubmit}>
              <ListItem key="add_new">
                <RanksAndTitle
                  label="Разряд и звание"
                  error={!!errors['item']}
                  name="item"
                  control={control}
                  rules={{ required: true }}
                  usedValues={data?.dirSport?.clsRanksAndAwards?.map((item) => item.value)}
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="add" type="submit">
                    <AddIcon color="primary" />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            </form>
          )}
          {data?.dirSport?.clsRanksAndAwards?.map((item) => (
            <ListItem key={item.value}>
              <ListItemText primary={item.label} />
              {!readonly && (
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="delete" onClick={() => setDeleteItem(item.value)}>
                    <DeleteIcon color="error" />
                  </IconButton>
                </ListItemSecondaryAction>
              )}
              <ConfirmDialog
                title={`Удаление`}
                text={`Вы уверены что хотите удалить этот разряд и звания ?`}
                btnVariant="outlined"
                open={deleteItem === item.value}
                saveBtnText="удалить"
                cancelBtnText="отмена"
                saveBtnColor="secondary"
                cancelBtnColor="primary"
                onCancel={() => setDeleteItem(null)}
                onSave={() => {
                  onRemove(item.value, item.label).then(() => setDeleteItem(null));
                }}
              />
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default RanksAndTitles;
