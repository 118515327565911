import { LinearProgress } from '@material-ui/core';
import { FC, useMemo } from 'react';
import { useClsCalendarCategoryQuery } from '../../../../api';
import React from 'react';
import { EventListByCalendarCategoryAccordion } from './EventListByCalendarCategoryAccordion';
import _ from 'lodash';

interface PervenstvoCategoryProps {
  regionId?: string;
  calendarYearList?: number[];
}

export const PervenstvoCategory: FC<PervenstvoCategoryProps> = ({ regionId, calendarYearList }) => {
  const canLoad = !!(regionId && calendarYearList);
  const { data } = useClsCalendarCategoryQuery({
    fetchPolicy: 'no-cache',
    skip: !canLoad,
  });

  const category = useMemo(
    () =>
      _.get(
        data?.classifierValues?.filter((c) => c?.label?.toLowerCase().trim() === 'первенство россии'),
        '[0]',
        null,
      ),
    [data],
  );

  if (!canLoad) return null;

  // if (loading) return <LinearProgress color="primary" variant="query" />;
  return (
    <div style={{ width: '100%' }}>
      <EventListByCalendarCategoryAccordion
        calendarYearList={calendarYearList}
        regionId={regionId}
        calendarCategory={category}
      />
    </div>
  );
};
