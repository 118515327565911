import React, { FC, useState } from 'react';
import { Button, Grid, Divider } from '@material-ui/core';
import { DropzoneArea } from 'material-ui-dropzone';
import ClearIcon from '@material-ui/icons/Clear';
import { useForm } from 'react-hook-form';
import { Input } from '../../../Inputs';
import SaveIcon from '@material-ui/icons/Save';
import { makeStyles } from '@material-ui/core/styles';
import SportingEventsRequestStatus from '../../../../module/SportingEventsRequestStatus';
import { Scalars } from '../../../../api';
import _ from 'lodash';

const useStyles = makeStyles({
  dropZone: {
    minHeight: 30,
    border: 0,
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    borderRadius: 0,

    '& > .MuiDropzonePreviewList-root': {
      margin: '0 !important',
      width: '100%',
      '& > .MuiDropzonePreviewList-imageContainer': {
        flexBasis: 'unset',
        width: '100%',
        maxWidth: '100%',
        display: 'flex',
        alignItems: 'center',
        '& > svg': {
          height: '40px',
        },
        '& > p': {
          marginLeft: 15,
          marginRight: 15,
        },
        '& > button': {
          top: 'unset',
          right: 0,
          color: '#d32f2f',
          boxShadow: 'unset',
        },
      },
    },
    '& > .MuiDropzoneArea-textContainer': {
      display: 'flex',

      '& > .MuiDropzoneArea-text': {
        margin: 0,
        fontSize: 15,
      },
      '& > .MuiDropzoneArea-icon': {
        width: 20,
        height: 20,
        marginLeft: 'auto',
      },
    },
    '&:focus': {
      outline: 0,
      border: 0,
      borderBotom: '2px solid #3f51b5',
    },
  },
});

interface formState {
  comment?: string;
  file?: File | { id: Scalars['UUID']; name: string; path: string };
}

const SportingEventRequestForm: FC<{
  IsReturning?: boolean;
  isSubmitting?: boolean;
  onEdit: Function;
  onCancel: Function;
}> = ({ onEdit, onCancel, IsReturning, isSubmitting }) => {
  const classes = useStyles();
  const [file, setFile] = useState<null | File>(null);
  const defaultState: formState = {
    comment: IsReturning ? 'Возврат сборной на редактирование в связи с необходимостью замены участников' : undefined,
    file: undefined,
  };

  const onHandleCancel = () => {
    reset({});

    setFile(null);

    onCancel();
  };

  const onSubmit = (values) => {
    (IsReturning ? values.comment : true) && onEdit({ ...values, file });
  };

  const { handleSubmit, control, errors, reset } = useForm({
    defaultValues: defaultState,
  });

  return (
    <form
      onSubmit={(event) => {
        event?.stopPropagation();
        handleSubmit(onSubmit)(event);
      }}
      style={{ width: '100%' }}
    >
      <Grid container style={{ marginBottom: 15 }} spacing={2}>
        <Grid item container spacing={2}>
          <Grid item container>
            <Input
              multiline
              rows={4}
              label="Комментарий"
              control={control}
              error={!!errors['comment']}
              name="comment"
              rules={{ required: IsReturning }}
            />
          </Grid>
          <Grid item container>
            <DropzoneArea
              showFileNames
              alertSnackbarProps={{
                anchorOrigin: { vertical: 'top', horizontal: 'right' },
              }}
              dropzoneClass={classes.dropZone}
              showAlerts={['error']}
              getFileAddedMessage={(fileName) => `Файл ${fileName} успешно загружено`}
              getDropRejectMessage={(file) =>
                `Файл ${file.name} отклонен. Тип файла не поддерживается. Файл слишком большой. Максимальный размер - 10 мегабайт.`
              }
              filesLimit={1}
              maxFileSize={10485760}
              onChange={(fileArray) => {
                if (fileArray.length > 0) {
                  setFile(fileArray[0]);
                }
              }}
              dropzoneText="Файл"
            />
          </Grid>
        </Grid>

        <Grid item container justify="flex-end" spacing={2}>
          <Grid item>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              startIcon={<ClearIcon />}
              type="button"
              onClick={onHandleCancel}
            >
              Отменить
            </Button>
          </Grid>
          <Grid item>
            <Button
              disabled={isSubmitting}
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<SaveIcon />}
              type="submit"
            >
              Подтвердить
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default SportingEventRequestForm;
