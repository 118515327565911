import React, { FC, Fragment } from 'react';
import { Button, Grid } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { useForm } from 'react-hook-form';
import { DatePicker } from '../../../Inputs';
import Radios from '../../../Inputs/Radio';
import SaveIcon from '@material-ui/icons/Save';
import TrainingStages from '../../../../module/TrainingStages';
import TrainersModule from '../../../../module/Trainers';
import { Scalars } from '../../../../api';
import _ from 'lodash';
import Persons from '../../../../module/Persons';
import userRights from '../../../../config/userRights';

interface formState {
  athlete?: { label: string; value: any };
  trainingStage?: { label: any; value: any };
  trainer?: { label: string; value: Scalars['UUID'] };
  trainingStart?: Date;
  exclusionDate?: Date;
  isSelfTraining?: string;
}

const OrgForm: FC<{
  action: string;
  defaultValue: any;
  regionsId: Array<string>;
  orgId: string;
  sport: string;
  onAdd: Function;
  onDelete: Function;
  onEdit: Function;
  onCancel: Function;
}> = ({ action, defaultValue, regionsId, orgId, sport, onAdd, onDelete, onEdit, onCancel }) => {
  const defaultState: formState = defaultValue || {
    athlete: undefined,
    trainingStage: undefined,
    trainingStart: undefined,
    exclusionDate: undefined,
    trainer: undefined,
    isSelfTraining: 'false',
  };

  const onHandleCancel = () => {
    (action === 'edit' && reset({})) || reset({ isSelfTraining: 'false' });

    onCancel();
  };

  const { handleSubmit, control, errors, watch, reset, formState } = useForm({
    defaultValues: defaultState,
  });

  let watchIsSelfTraining = watch('isSelfTraining');

  return (
    <form
      onSubmit={handleSubmit((values) =>
        action === 'add' ? onAdd(values) : action === 'fire' ? onDelete(values) : onEdit(values),
      )}
      style={{ width: '100%' }}
    >
      <Grid container style={{ marginBottom: 15 }} spacing={2}>
        {action !== 'fire' && (
          <Fragment>
            <Grid item xs={12}>
              <Persons
                filter={{
                  athleteProfile: {
                    sports_some: {
                      dirSport: { id: sport },
                    },
                  },
                  dirRegion: { id_in: regionsId },
                  archive: null,
                }}
                profile="athleteProfile"
                label="Спортсмен"
                control={control}
                name="athlete"
                error={!!errors['athlete']}
                disabled={action !== 'add'}
                rules={{ required: true }}
              />
            </Grid>

            <>
              <Grid item md={3} xs={12}>
                <TrainingStages
                  label="Этап подготовки"
                  control={control}
                  error={!!errors['trainingStage']}
                  name="trainingStage"
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item md={2} xs={3}>
                <Radios
                  label="Самоподготовка"
                  data={[
                    {
                      value: 'true',
                      label: 'Да',
                    },
                    {
                      value: 'false',
                      label: 'Нет',
                    },
                  ]}
                  control={control}
                  error={!!errors['isSelfTraining']}
                  name="isSelfTraining"
                  rules={{ required: true }}
                />
              </Grid>

              {watchIsSelfTraining === 'false' && (
                <Grid item md={3} xs={12}>
                  <TrainersModule
                    label="Тренер"
                    organizationId={orgId}
                    sportId={sport}
                    control={control}
                    error={!!errors['trainer']}
                    name="trainer"
                    rules={{ required: true }}
                  />
                </Grid>
              )}
              <Grid item md={3} xs={12}>
                <DatePicker
                  fullWidth
                  name="trainingStart"
                  label="Дата начала занятий"
                  control={control}
                  error={!!errors['trainingStart']}
                  rules={{ required: true }}
                  disabled={action !== 'add'}
                />
              </Grid>
            </>
          </Fragment>
        )}

        {(action === 'transfer' || action === 'fire') && (
          <Grid item md={3} xs={12}>
            <DatePicker
              fullWidth
              name="exclusionDate"
              label="Дата отчисления"
              control={control}
              error={!!errors['exclusionDate']}
              rules={{ required: true }}
            />
          </Grid>
        )}
        <Grid item container justify="flex-end" spacing={2}>
          <Grid item>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              startIcon={<ClearIcon />}
              type="button"
              onClick={onHandleCancel}
            >
              Отменить
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<SaveIcon />}
              type="submit"
              disabled={!formState.isDirty}
            >
              Сохранить
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default OrgForm;
