import moment from 'moment';

export const getAthleteFormHint = (athlete: any, sportId: string, event: any) => {
  const {
    sportQualificationFemaleNotLower,
    sportQualificationMaleNotLower,
    clsMinRang,
    clsSportingQualificationNotGreater,
  } = event;
  let result = [];
  const gender = athlete.isMale !== undefined ? athlete.isMale : athlete.dirAthlete?.dirPerson?.isMale;
  if (gender && !sportQualificationMaleNotLower && !clsMinRang) return '';
  if (!gender && !sportQualificationFemaleNotLower && !clsSportingQualificationNotGreater) return '';
  const sport = (athlete.roleData || athlete.dirAthlete)?.sports.filter((s: any) => s.dirSport?.id === sportId);
  if (sport.length === 0) {
    return 'Отсутствует разряд/звание, соответствующий регламенту';
  }
  if (gender && sportQualificationMaleNotLower) {
    result.push(
      sport?.[0].ranksAndAwards.some((rank: any) => {
        if (!rank.validUntil || (rank.validUntil && moment().isSameOrBefore(moment(rank.validUntil)))) {
          return rank?.clsRankAndAward?.order >= sportQualificationMaleNotLower.order;
        } else {
          return false;
        }
      })
        ? ''
        : 'Отсутствует разряд/звание, соответствующий регламенту',
    );
  }
  if (gender === false && sportQualificationFemaleNotLower) {
    result.push(
      sport?.[0].ranksAndAwards.some((rank: any) => {
        if (!rank.validUntil || (rank.validUntil && moment().isSameOrBefore(moment(rank.validUntil)))) {
          return rank?.clsRankAndAward?.order >= sportQualificationFemaleNotLower.order;
        } else {
          return false;
        }
      })
        ? ''
        : 'Отсутствует разряд/звание, соответствующий регламенту',
    );
  }
  if (gender && clsMinRang) {
    if (sport?.[0].ranksAndAwards.length === 0) return '';
    result.push(
      sport?.[0].ranksAndAwards.some((rank: any) => {
        if (!rank.validUntil || (rank.validUntil && moment().isSameOrBefore(moment(rank.validUntil)))) {
          return rank?.clsRankAndAward?.order <= clsMinRang.order;
        } else {
          return false;
        }
      })
        ? ''
        : 'Отсутствует разряд/звание, соответствующий регламенту',
    );
  }
  if (gender === false && clsSportingQualificationNotGreater) {
    if (sport?.[0].ranksAndAwards.length === 0) return '';
    result.push(
      sport?.[0].ranksAndAwards.some((rank: any) => {
        if (!rank.validUntil || (rank.validUntil && moment().isSameOrBefore(moment(rank.validUntil)))) {
          return rank?.clsRankAndAward?.order <= clsSportingQualificationNotGreater.order;
        } else {
          return false;
        }
      })
        ? ''
        : 'Отсутствует разряд/звание, соответствующий регламенту',
    );
  }
  return result.filter((item) => item !== '').length > 0 ? 'Отсутствует разряд/звание, соответствующий регламенту' : '';
};
