import * as React from 'react';

import {
  AddAthleteOrganizationMutationHookResult,
  DeleteAthleteOrganizationMutationHookResult,
  Scalars,
  UpdateAthleteOrganizationMutationHookResult,
  useAddAthleteOrganizationMutation,
  useDeleteAthleteOrganizationMutation,
  useOrganizationsAthletesBySportQuery,
  useUpdateAthleteOrganizationMutation,
} from '../../../../api';
import {
  Button,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';

import AddBoxIcon from '@material-ui/icons/AddBox';
import AthletesForm from './AthletesForm';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import _ from 'lodash';
import getMessage from '../../../../messages';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useState } from 'react';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  header: {
    fontWeight: 600,
    fontSize: 15,
    letterSpacing: 1.2,
  },
  row: {
    display: 'flex',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  tinyIcon: {
    fontSize: 18,
  },
  icon: {
    fontSize: 20,
  },
});

interface formState {
  athlete?: { label: string; value: any };
  trainingStage?: { label: any; value: any };
  trainer?: { label: string; value: Scalars['UUID'] };
  trainingStart?: Date;
  exclusionDate?: Date;
  isSelfTraining?: string;
}

interface IAComponent {
  id: string;
  sportId: string;
  regionId?: string;
  readonly?: boolean;
}

export default function AthletesComponent({ id, sportId, regionId, readonly }: IAComponent) {
  const classes = useStyles();
  const [action, setAction] = useState('');
  const [defaultFormValue, setDefaultFormValue] = useState<any>(null);
  const [currentTrainer, setCurrentTrainer] = useState('');
  const [currentTrainingStage, setCurrentTrainingStage] = useState('');

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const [add]: AddAthleteOrganizationMutationHookResult = useAddAthleteOrganizationMutation();
  const [update]: UpdateAthleteOrganizationMutationHookResult = useUpdateAthleteOrganizationMutation();
  const [deleteOrganization]: DeleteAthleteOrganizationMutationHookResult = useDeleteAthleteOrganizationMutation();

  const { refetch, data, loading } = useOrganizationsAthletesBySportQuery({
    fetchPolicy: 'no-cache',
    variables: {
      sportID: sportId,
      id,
      filter: {
        dirOrganization: {
          id,
        },
      },
    },
  });

  const updateDefaultFormValue = (org: any) =>
    setDefaultFormValue({
      trainer: {
        value: org?.dirTrainer?.id,
        label: `${org?.dirTrainer?.dirPerson.lastname} ${org?.dirTrainer?.dirPerson.firstname} ${
          org?.dirTrainer?.dirPerson.patronymic ? org?.dirTrainer?.dirPerson.patronymic : ''
        } ${moment(org?.dirTrainer?.dirPerson.birthday).format('DD.MM.YYYY')}`,
      },
      isSelfTraining: (org.isSelfTraining === true && 'true') || (org.isSelfTraining === false && 'false') || '',
      trainingStage: {
        label: org?.clsTrainingStage?.fullName,
        value: org?.clsTrainingStage?.id,
      },
      athlete: {
        value: org?.athlete?.id,
        label: `${org?.athlete?.dirPerson.lastname} ${org?.athlete?.dirPerson.firstname} ${
          org?.athlete?.dirPerson.patronymic ? org?.athlete?.dirPerson.patronymic : ''
        } ${moment(org?.athlete?.dirPerson.birthday).format('DD.MM.YYYY')}`,
        ...org?.athlete,
      },
      trainingStart: org?.trainingStart,
      exclusionDate: org?.exclusionDate,
    });

  const onAdd = async (values: formState) => {
    const { athlete, trainingStage, trainingStart, trainer, isSelfTraining } = values;
    const sportID = _.get(athlete, 'roleData.sports', []).find((s: any) => s?.dirSport?.id === sportId)?.id;

    try {
      await add({
        variables: {
          idSport: sportID,
          idDirOrganization: id,
          isSelfTraining: isSelfTraining === 'true',
          idDirTrainer: trainer ? { connect: { id: trainer?.value } } : null,
          clsTrainingStage: trainingStage?.value,
          trainingStart,
        },
      });
      refetch();
      handleSnackBar('success', 'Спортсмен успешно добавлен');
      setAction('');
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const onEdit = async (values: formState) => {
    const { athlete, trainingStage, trainingStart, trainer, isSelfTraining, exclusionDate } = values;
    const sportID = _.get(athlete, 'sports[0].id', '');
    const orgID = _.get(athlete, 'sports[0].organizations[0].id', '');

    try {
      await update({
        variables: {
          id: orgID,
          idDirOrganization: id,
          isSelfTraining: isSelfTraining === 'true',
          idDirTrainer:
            action === 'transfer' && currentTrainer
              ? { connect: { id: currentTrainer } }
              : action === 'edit' && trainer
              ? { connect: { id: trainer?.value } }
              : action === 'edit' && currentTrainer
              ? { disconnect: true }
              : null,
          clsTrainingStage: action === 'transfer' ? currentTrainingStage : trainingStage?.value,
          exclusionDate,
          trainingStart,
        },
      });
      action === 'transfer' &&
        (await add({
          variables: {
            idSport: sportID,
            idDirOrganization: id,
            isSelfTraining: isSelfTraining === 'true',
            idDirTrainer: trainer ? { connect: { id: trainer?.value } } : null,
            clsTrainingStage: trainingStage?.value,
            trainingStart: exclusionDate,
          },
        }));
      refetch();
      handleSnackBar('success', 'Спортсмен успешно обновлен');
      setCurrentTrainer('');
      setCurrentTrainingStage('');
      setAction('');
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const onDelete = async (values: formState) => {
    const { exclusionDate } = values;
    const orgID = _.get(defaultFormValue, 'athlete.sports[0].organizations[0].id', '');

    try {
      await deleteOrganization({
        variables: {
          id: orgID,
          exclusionDate: exclusionDate,
        },
      });
      refetch();
      handleSnackBar('success', 'Спортсмен успешно обновлен');
      setAction('');
      setDefaultFormValue(null);
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const sortBy = (a: any, b: any) => {
    if (!_.get(b, 'sports[0].organizations[0].exclusionDate')) {
      return 1;
    } else if (!_.get(a, 'sports[0].organizations[0].exclusionDate')) {
      return -1;
    } else {
      return (
        _.get(b, 'sports[0].organizations[0].exclusionDate') - _.get(a, 'sports[0].organizations[0].exclusionDate')
      );
    }
  };

  return (
    <>
      {action && (
        <Paper style={{ padding: 15 }}>
          <AthletesForm
            defaultValue={defaultFormValue}
            action={action}
            orgId={id}
            regionsId={[regionId || '']}
            sport={sportId}
            onAdd={(values: formState) => onAdd(values)}
            onEdit={(values: formState) => onEdit(values)}
            onDelete={(values: formState) => onDelete(values)}
            onCancel={() => {
              setAction('');
              setDefaultFormValue(null);
            }}
          />
        </Paper>
      )}
      {loading ? (
        <LinearProgress />
      ) : (
        <TableContainer component={Paper} style={{ marginTop: 10 }}>
          <Table style={{ minWidth: 650 }} aria-label="athletes table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.header} align="center">
                  {!action && !readonly && (
                    <IconButton color="primary" onClick={() => setAction('add')}>
                      <AddBoxIcon />
                    </IconButton>
                  )}
                </TableCell>
                <TableCell className={classes.header}>ФИО</TableCell>
                <TableCell className={classes.header}>Этап подготовки</TableCell>
                <TableCell className={classes.header}>Тренер</TableCell>
                <TableCell className={classes.header}>Дата начала</TableCell>
                <TableCell className={classes.header}>Дата отчисления</TableCell>
                <TableCell className={classes.header}>Действия</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.dirAthletes
                ?.sort((a: any, b: any) => sortBy(a, b))
                ?.map((athlete, index) =>
                  _.get(athlete, 'sports[0].organizations', []).map((org: any, i: number) => (
                    <TableRow key={org.id}>
                      <TableCell component="th" scope="row" align="center">
                        {i === 0 ? index + 1 : ''}
                      </TableCell>
                      <TableCell>
                        {i === 0
                          ? [
                              athlete?.dirPerson?.lastname || '',
                              athlete?.dirPerson?.firstname || '',
                              athlete?.dirPerson?.patronymic || '',
                            ].join(' ')
                          : ''}
                      </TableCell>
                      <TableCell>{_.get(org, 'clsTrainingStage.shortName', '')}</TableCell>
                      <TableCell>
                        {[
                          _.get(org, 'dirTrainer.dirPerson.lastname', ''),
                          _.get(org, 'dirTrainer.dirPerson.firstname', ''),
                          _.get(org, 'dirTrainer.dirPerson.patronymic', ''),
                        ].join(' ')}
                      </TableCell>
                      <TableCell>{org?.trainingStart ? moment(org.trainingStart).format('DD.MM.YYYY') : ''}</TableCell>
                      <TableCell>{org?.exclusionDate ? moment(org?.exclusionDate).format('DD.MM.YYYY') : ''}</TableCell>
                      <TableCell>
                        {!readonly
                          ? !org?.exclusionDate &&
                            !action && (
                              <>
                                <Button
                                  size="small"
                                  color="primary"
                                  variant="contained"
                                  style={{ marginRight: 10 }}
                                  onClick={() => {
                                    updateDefaultFormValue({
                                      ...org,
                                      athlete: {
                                        ...athlete,
                                      },
                                    });
                                    setCurrentTrainer(_.get(org, 'dirTrainer.id', ''));
                                    setCurrentTrainingStage(_.get(org, 'clsTrainingStage.id', ''));
                                    setAction('transfer');
                                  }}
                                >
                                  Перевод
                                </Button>
                                <Tooltip title="удалить" placement="top-start">
                                  <Button
                                    className={classes.btn}
                                    color="secondary"
                                    variant="contained"
                                    style={{ marginRight: 10 }}
                                    onClick={() => {
                                      updateDefaultFormValue({
                                        athlete: {
                                          ...athlete,
                                        },
                                      });
                                      setAction('fire');
                                    }}
                                  >
                                    <ClearIcon className={classes.icon} />
                                  </Button>
                                </Tooltip>

                                <Tooltip title="изменить" placement="top-start">
                                  <Button
                                    className={classes.btn}
                                    color="primary"
                                    variant="contained"
                                    onClick={() => {
                                      updateDefaultFormValue({
                                        ...org,
                                        athlete: {
                                          ...athlete,
                                        },
                                      });
                                      setCurrentTrainer(_.get(org, 'dirTrainer.id', ''));
                                      setAction('edit');
                                    }}
                                  >
                                    <EditIcon className={classes.icon} />
                                  </Button>
                                </Tooltip>
                              </>
                            )
                          : ''}
                      </TableCell>
                    </TableRow>
                  )),
                )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
