import {
  Button,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { DatePicker, Input, Select } from '../../Inputs';
import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import {
  Scalars,
  UpdateSportingEventMutationHookResult,
  UploadFileMutationHookResult,
  useSportingEventLazyQuery,
  useSportingEventProgramTypesLazyQuery,
  useSportingEventQuery,
  useUpdateSportingEventMutation,
  useUploadFileMutation,
} from '../../../api';

import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import DocumentSelect from '../../../module/Documents';
import Documents from '../SportingEvents/components/ProgramTypeDocument';
import { DropzoneArea } from 'material-ui-dropzone';
import EditIcon from '@material-ui/icons/Edit';
import EditLocationIcon from '@material-ui/icons/EditLocation';
import SaveIcon from '@material-ui/icons/Save';
import { SportCategories } from '../../../module';
import _ from 'lodash';
import { detectedChanges } from '../../../utils/compareSportingEvents';
import getMessage from '../../../messages';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import useSortSportDisciplines from '../../../hooks/useSortSportDisciplines';

const useStyles = makeStyles({
  row: {
    display: 'flex',
  },
  link: {
    cursor: 'pointer',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  icon: {
    fontSize: 20,
  },
  dropZone: {
    minHeight: 30,
    border: 0,
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    borderRadius: 0,

    '& > .MuiDropzonePreviewList-root': {
      margin: '0 !important',
      width: '100%',
      '& > .MuiDropzonePreviewList-imageContainer': {
        flexBasis: 'unset',
        width: '100%',
        maxWidth: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 15,
        '& > svg': {
          height: '40px',
        },
        '& > p': {
          marginLeft: 15,
          marginRight: 15,
        },
        '& > button': {
          top: 'unset',
          right: 0,
          position: 'unset',
          marginLeft: 30,

          color: '#d32f2f',
          boxShadow: 'unset',
        },
      },
    },
    '& > .MuiDropzoneArea-textContainer': {
      display: 'flex',

      '& > .MuiDropzoneArea-text': {
        margin: 0,
        fontSize: 15,
      },
      '& > .MuiDropzoneArea-icon': {
        width: 20,
        height: 20,
        marginLeft: 'auto',
      },
    },
    '&:focus': {
      outline: 0,
      border: 0,
      borderBotom: '2px solid #3f51b5',
    },
  },
});

interface formState {
  minAge?: Scalars['Int'] | null;
  maxAge?: Scalars['Int'] | null;
  start?: string | null;
  discipline?: { label: string; value: Scalars['UUID'] } | null;
  clsAgeGroups?: {
    label: string;
    value: Scalars['UUID'];
    labelElement: ReactNode;
  } | null;
  clsSportCategory?: { label: string; value: Scalars['UUID'] } | null;
  title?: string;
  date?: Date;
  number?: string;
}

interface lightFormState {
  resultsDocument?: { label: string; value: Scalars['UUID'] };
}

const ProgramTypes: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const classes = useStyles();

  const { loading, refetch, data } = useSportingEventQuery({ fetchPolicy: 'no-cache', variables: { id } });

  const [sports, programTypes, eventCategories] = [
    data?.dirSportingEvent?.sports,
    data?.dirSportingEvent?.programTypes,
    data?.dirSportingEvent?.clsEventCategories,
  ];

  const defaultState: formState = {
    minAge: undefined,
    maxAge: undefined,
    start: undefined,
    discipline: undefined,
    clsAgeGroups: undefined,
    clsSportCategory: undefined,
  };

  const [openDialog, setOpenDialog] = useState(false);
  const [currentRequest, setCurrentRequest] = useState('');
  const [programTypeId, setProgramTypeId] = useState<Scalars['UUID']>('');
  const [selectedAgeGroupInfo, setSelectedAgeGroupInfo] = useState<{
    minAge: Scalars['Int'] | null | undefined;
    maxAge?: Scalars['Int'] | null | undefined;
  } | null>(null);
  const { handleSubmit, control, errors, reset, formState, setValue } = useForm({
    defaultValues: defaultState,
  });

  const [eventFetch, { loading: load, data: eventData }] = useSportingEventLazyQuery({
    fetchPolicy: 'no-cache',
    variables: { id },
  });
  const [
    fetch,
    { refetch: refet, loading: programLoading, data: dataSportingEventProgramTypes },
  ] = useSportingEventProgramTypesLazyQuery({
    fetchPolicy: 'no-cache',
    variables: { filter: { event: { id } } },
  });
  const [file, setFile] = useState<Array<File>>([]);

  const [updateEvent]: UpdateSportingEventMutationHookResult = useUpdateSportingEventMutation();

  const programTypesMemoizedValue = useSortSportDisciplines(programTypes);

  const [isAddingDocument, setIsAddingDocument] = useState(false);

  const [isAddingNEwDocumentFromSystem, setIsAddingNEwDocumentFromSystem] = useState(false);

  const [replaceFile, setReplaceFile] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (id && id !== 'new') {
      eventFetch({ variables: { id } });
      fetch({ variables: { filter: { event: { id } } } });
    }
  }, [eventFetch, fetch, id]);

  useEffect(() => {
    setValue('minAge', selectedAgeGroupInfo?.minAge);
    setValue('maxAge', selectedAgeGroupInfo?.maxAge);
  }, [selectedAgeGroupInfo, setValue]);

  const disciplinesArray = useMemo(
    () =>
      _.flattenDeep(sports?.map((sport) => sport?.disciplineGroups?.map((group) => group?.disciplines))).length
        ? _.flattenDeep(sports?.map((sport) => sport?.disciplineGroups?.map((group) => group?.disciplines)))
        : _.flattenDeep(sports?.map((sport) => sport?.dirSport?.disciplines)),
    [sports],
  );

  const {
    handleSubmit: DocumentFormHandleSubmit,
    control: DocumentFormControl,
    errors: DocumentFormErrors,
    reset: DocumentFormReset,
    formState: DocumentFormState,
  } = useForm({
    defaultValues: {
      resultsDocument: undefined,
    },
  });

  const {
    handleSubmit: NewDocumentFormHandleSubmit,
    control: NewDocumentFormControl,
    errors: NewDocumentFormErrors,
    reset: NewDocumentFormReset,
    formState: NewDocumentFormState,
  } = useForm({
    defaultValues: {},
  });
  const defaultNewDocumentState: formState = {
    title: undefined,
    date: undefined,
    number: undefined,
  };
  const addNewDocument = async (values: lightFormState) => {
    const { resultsDocument } = values;
    try {
      if (isAddingDocument && resultsDocument) {
        await updateEvent({
          variables: {
            data: {
              programTypes: {
                update: [
                  {
                    where: {
                      id: programTypeId,
                    },
                    data: {
                      resultsDocument: {
                        connect: { id: resultsDocument?.value },
                      },
                    },
                  },
                ],
              },
            },
            id,
          },
        });
        handleSnackBar('success', 'документ успешно добавлен');
        DocumentFormReset();
        setIsAddingDocument(false);
        refetch();
      }
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };
  const [competitionReglaments, competitionProtocols] = [
    data?.dirSportingEvent?.competitionReglaments,
    data?.dirSportingEvent?.competitionProtocols,
  ];

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });
  const [uploadDocumentFile]: UploadFileMutationHookResult = useUploadFileMutation();
  const handleAddNewDocumentFromSystem = async (values: formState) => {
    const { title, date, number } = values;
    try {
      if (isAddingNEwDocumentFromSystem && title && date && file) {
        uploadDocumentFile({
          variables: {
            file: file[0],
          },
        }).then(async (resp: any) => {
          const {
            uploadFile: { id: fileId },
          } = resp.data;
          await updateEvent({
            variables: {
              data: {
                programTypes: {
                  update: [
                    {
                      where: {
                        id: programTypeId,
                      },
                      data: {
                        resultsDocument: {
                          create: {
                            title,
                            date,
                            number,
                            file: {
                              connect: { id: fileId },
                            },
                          },
                        },
                      },
                    },
                  ],
                },
              },
              id,
            },
          });
          handleSnackBar('success', 'документ успешно добавлен');
          setIsAddingNEwDocumentFromSystem(false);
          reset(defaultState);
          refetch();
        });
      }
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const onSubmit = async (values: formState) => {
    const { minAge, maxAge, start, clsAgeGroups, discipline, clsSportCategory } = values;
    try {
      if (currentRequest === 'update') {
        await updateEvent({
          variables: {
            data: {
              programTypes: {
                update: [
                  {
                    where: {
                      id: programTypeId,
                    },
                    data: {
                      minAge: Number(minAge) || 0,
                      maxAge: Number(maxAge) || 0,
                      start,
                      clsAgeGroups: {
                        set: [
                          {
                            id: clsAgeGroups?.value,
                          },
                        ],
                      },
                      discipline: {
                        connect: {
                          id: discipline?.value,
                        },
                      },
                      ...(clsSportCategory && {
                        clsSportCategory: {
                          connect: { id: clsSportCategory?.value },
                        },
                      }),
                    },
                  },
                ],
              },
            },
            id,
          },
        });

        handleSnackBar('success', 'Вид програм успешно обновлена');
        setOpenDialog(false);
        setCurrentRequest('');
        setProgramTypeId('');
        setSelectedAgeGroupInfo(null);
        reset(defaultState);
        refetch();

        return;
      }
      await updateEvent({
        variables: {
          data: {
            programTypes: {
              create: [
                {
                  minAge: Number(minAge) || 0,
                  maxAge: Number(maxAge) || 0,
                  start,
                  clsAgeGroups: {
                    connect: [
                      {
                        id: clsAgeGroups?.value,
                      },
                    ],
                  },
                  ...(clsSportCategory && {
                    clsSportCategory: {
                      connect: { id: clsSportCategory?.value },
                    },
                  }),

                  discipline: {
                    connect: {
                      id: discipline?.value,
                    },
                  },
                },
              ],
            },
          },
          id,
        },
      });
      handleSnackBar('success', 'Вид програм успешно создано');
      setOpenDialog(false);
      setSelectedAgeGroupInfo(null);
      reset(defaultState);
      refetch();
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const deleteProgram = async (programId: Scalars['UUID']) => {
    try {
      await updateEvent({
        variables: {
          data: {
            programTypes: {
              delete: [
                {
                  id: programId,
                },
              ],
            },
          },
          id,
        },
      });

      handleSnackBar('success', 'Вид програм успешно удаленно');
      refetch();
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  if (loading) return <LinearProgress />;

  const pChanges = detectedChanges(data?.dirSportingEvent).programTypeArrayChange;

  return (
    <div>
      {sports && sports.length > 0 && (
        <div>
          <Tooltip title="добавить" placement="top-start">
            <IconButton
              color="primary"
              onClick={() => {
                reset({ ...defaultState, clsSportCategory: eventCategories?.length ? eventCategories[0] : undefined });
                setOpenDialog(true);
              }}
            >
              <AddBoxIcon />
            </IconButton>
          </Tooltip>
        </div>
      )}
      {programTypesMemoizedValue && programTypesMemoizedValue?.length > 0 && (
        <TableContainer component={Paper}>
          <Table aria-label="program types table">
            <TableHead>
              <TableRow>
                <TableCell>Дисциплина&nbsp;</TableCell>
                <TableCell>Категория&nbsp;</TableCell>
                <TableCell>Возрастные группы</TableCell>
                <TableCell>Возраст с</TableCell>
                <TableCell>Возраст по&nbsp;</TableCell>
                <TableCell>Старт&nbsp;</TableCell>
                <TableCell>Документы&nbsp;</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {programTypesMemoizedValue.map((program: any) => (
                <TableRow key={program.id}>
                  <TableCell>{program.discipline?.label}</TableCell>
                  <TableCell component="th" scope="row">
                    {program.clsSportCategory && program.clsSportCategory?.label}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {program.clsAgeGroups && program.clsAgeGroups[0]?.label}
                  </TableCell>
                  <TableCell>{program.minAge}</TableCell>
                  <TableCell>{program.maxAge}</TableCell>
                  <TableCell>{program.start} </TableCell>
                  <TableCell>
                    <Documents
                      id={id}
                      programId={program.id}
                      documents={program.resultDocuments}
                      onUpdateDocument={() => {
                        refetch && refetch();
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <div className={classes.row}>
                      <Tooltip title="изменить" placement="top-start">
                        <IconButton
                          className={classes.btn}
                          color="primary"
                          onClick={() => {
                            reset({ ...program, clsAgeGroups: program.clsAgeGroups && program.clsAgeGroups[0] });
                            setProgramTypeId(program?.id);
                            setCurrentRequest('update');
                            setOpenDialog(true);
                          }}
                        >
                          <EditIcon className={classes.icon} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="подробности" placement="top-start">
                        <IconButton
                          className={classes.btn}
                          color="secondary"
                          onClick={() => deleteProgram(program?.id)}
                        >
                          <DeleteIcon className={classes.icon} />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Dialog
        onClose={() => {
          setOpenDialog(false);
          setProgramTypeId('');
          setSelectedAgeGroupInfo(null);
          reset(defaultState);
        }}
        aria-labelledby="add-programType-dialog"
        open={openDialog}
      >
        <DialogTitle id="add-programType-dialog">Вид программы</DialogTitle>
        <div style={{ padding: '1.5rem', minWidth: 350 }}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <Grid item xs={12}>
              <Select
                data={disciplinesArray}
                label="Дисциплина"
                control={control}
                error={!!errors['discipline']}
                name="discipline"
                rules={{ required: true }}
              />
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Select
                  data={eventCategories || []}
                  label="Категория"
                  control={control}
                  error={!!errors['clsSportCategory']}
                  name="clsSportCategory"
                  enablePortal
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Select
                data={_.flattenDeep(
                  sports?.map((sport) =>
                    sport?.dirSport?.ageGroups?.map((a) => ({
                      ...a?.clsAgeGroup,
                      labelElement: (
                        <Grid
                          container
                          spacing={2}
                          onClick={() => setSelectedAgeGroupInfo({ minAge: a?.minAge, maxAge: a?.maxAge })}
                        >
                          <Grid item xs={6}>
                            {a?.clsAgeGroup?.label}
                          </Grid>{' '}
                          <Grid item xs={6} style={{ color: 'grey' }}>
                            {(a?.minAge && `От ${a?.minAge}`) || ''} {(a?.minAge && `до ${a?.maxAge}`) || ''}
                          </Grid>
                        </Grid>
                      ),
                    })),
                  ),
                )}
                label="Возрастные группы"
                control={control}
                error={!!errors['clsAgeGroups']}
                name="clsAgeGroups"
                rules={{ required: true }}
                enablePortal
              />

              <Grid item xs={12}>
                <Input label="Старт" control={control} error={!!errors['start']} name="start" />
              </Grid>
              <Grid item container justify="space-between">
                <Grid item xs={4}>
                  <Input label="Возраст с" control={control} error={!!errors['minAge']} name="minAge" disabled />
                </Grid>
                <Grid item xs={4}>
                  <Input
                    label="Возраст по"
                    control={control}
                    error={!!errors['maxAge']}
                    name="maxAge"
                    rules={{ required: true }}
                    disabled
                  />
                </Grid>
              </Grid>
              <Grid item container justify="flex-end" style={{ marginTop: 15 }}>
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  startIcon={<SaveIcon />}
                  type="submit"
                  disabled={!formState.isDirty}
                >
                  Сохранить
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Dialog>
      <Dialog
        open={isAddingNEwDocumentFromSystem}
        onClose={() => {
          setIsAddingNEwDocumentFromSystem(false);
          NewDocumentFormReset(defaultNewDocumentState);
        }}
        maxWidth="xs"
      >
        <DialogTitle id="add-document-dialog">Новый Документ</DialogTitle>
        <div style={{ padding: '1.5rem' }}>
          <form onSubmit={NewDocumentFormHandleSubmit(handleAddNewDocumentFromSystem)} style={{ width: '100%' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Input
                  label="Название документа"
                  control={NewDocumentFormControl}
                  error={!!errors['title']}
                  name="title"
                  rules={{ required: true }}
                />
              </Grid>

              <Grid item xs={12}>
                <DatePicker
                  fullWidth
                  label="Дата"
                  control={NewDocumentFormControl}
                  error={!!errors['date']}
                  name="date"
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  label="Номер документа"
                  control={NewDocumentFormControl}
                  error={!!errors['number']}
                  name="number"
                />
              </Grid>
              <Grid item xs={12}>
                <DropzoneArea
                  showFileNames
                  alertSnackbarProps={{
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                  }}
                  dropzoneClass={classes.dropZone}
                  showAlerts={['error']}
                  getFileAddedMessage={(fileName: string) => `Файл ${fileName} успешно загружено`}
                  getDropRejectMessage={(file: File) =>
                    `Файл ${file.name} отклонен. Тип файла не поддерживается. Файл слишком большой. Максимальный размер - 10 мегабайт.`
                  }
                  filesLimit={1}
                  maxFileSize={10485760}
                  clearOnUnmount
                  initialFiles={file}
                  onChange={(fileArray: File[]) => setFile(fileArray)}
                  dropzoneText="Файл"
                />
              </Grid>
              <Grid item container justify="flex-end">
                <Button variant="outlined" color="primary" size="small" startIcon={<SaveIcon />} type="submit">
                  Добавить
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Dialog>
    </div>
  );
};

export default ProgramTypes;
