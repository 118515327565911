import React, { FC, useEffect, useState } from 'react';
import { useSimilarAthletesLazyQuery, Scalars, useAthletesMiniLazyQuery } from '../api';
import { Select } from '../components/Inputs';
import { ControllerProps } from 'react-hook-form/dist/types/props';
import useDebouncedSearch from '../hooks/useDebouncedSearch';
import _ from 'lodash';
import moment, { Moment } from 'moment';

interface Organizations extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  error: boolean | undefined;
  sportsId: Scalars['UUID'][];
  deniedAthletes: Scalars['UUID'][];
  minBday?: Date | Number | Moment;
  maxBday?: Date | Number | Moment;
}

const AthletesInEKP: FC<Organizations> = ({
  label,
  error,
  control,
  name,
  rules,
  sportsId,
  deniedAthletes = [],
  minBday,
  maxBday,
}) => {
  const filter = {
    ...(deniedAthletes.length > 0 && { id_not_in: deniedAthletes }),
    sports_some: { dirSport: { id_in: sportsId } },
    ...(minBday && { dirPerson: { birthday_lte: minBday } }),
    ...(maxBday && { dirPerson: { birthday_gte: maxBday } }),
  };

  const similarFilter = {
    sportsId,
    ...(deniedAthletes.length > 0 && { deniedAthletes }),
    ...(minBday && { minBday }),
    ...(maxBday && { maxBday }),
  };

  const [fetch, { data, loading, refetch }] = useSimilarAthletesLazyQuery({
    variables: { search: '', ...similarFilter },
  });
  const [athleteFetch, { data: athleteData, loading: athleteLoading }] = useAthletesMiniLazyQuery({
    variables: { filter },
  });
  const [parsed, setParsed] = useState<any>([]);
  const [shouldNtFilter, setShouldNtFilter] = useState(false);

  const refetchAthletes = async (value: string) => {
    if (value?.length > 2) {
      const isNotANumber = isNaN(Number(value));
      setShouldNtFilter(!isNotANumber);

      if (!isNotANumber) {
        await athleteFetch({
          variables: { filter: { ...filter, dirPerson: { registryNumber: Number(value) } } },
        });
      } else {
        await fetch({ variables: { search: value, ...similarFilter } });
      }
    }
  };
  const useSearchAthletes = () => useDebouncedSearch((text) => refetchAthletes(text));

  const { setInputValue: setValue } = useSearchAthletes();

  useEffect(() => {
    if (!loading || !athleteLoading) {
      const prepare = shouldNtFilter
        ? athleteData?.dirAthletes?.map((item) => {
            return {
              value: item?.id,
              label: `${item?.dirPerson?.lastname} ${item?.dirPerson?.firstname} ${
                item?.dirPerson?.patronymic || ''
              } ${moment(item?.dirPerson?.birthday).format('DD.MM.YYYY')} ${
                item?.dirPerson?.dirRegion?.fullName ||
                item?.dirPerson?.dirForeignCity?.fullName ||
                item?.dirPerson?.dirCountry?.fullName ||
                ''
              }`,
            };
          })
        : data?.dirPersonsSimilar.map((item) => {
            return {
              value: item?.person?.athleteProfile?.id,
              label: `${item?.person?.lastname} ${item?.person?.firstname} ${item?.person?.patronymic || ''} ${moment(
                item.person.birthday,
              ).format('DD.MM.YYYY')} ${
                item?.person?.dirRegion?.fullName ||
                item?.person?.dirForeignCity?.fullName ||
                item?.person?.dirCountry?.fullName ||
                ''
              }`,
            };
          });

      setParsed(prepare || []);
    }
  }, [data, loading, athleteLoading]);

  return (
    <Select
      enablePortal
      shouldNtFilter={shouldNtFilter}
      label={label}
      data={parsed}
      control={control}
      error={error}
      name={name}
      rules={rules}
      onChangeInput={setValue}
      loading={loading}
      placeholder="ФИО спортсмена или Реестровый номер персоны"
    />
  );
};

export default AthletesInEKP;
