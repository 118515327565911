import React, { FC } from 'react';
import PropreSingle from './PropreSingle';
import SingleDependOnRequest from './SingleDependOnRequest';
import { useRouteMatch } from 'react-router-dom';

const Single: FC<{}> = () => {
  const { path } = useRouteMatch();

  const isRequestPage = path.startsWith('/request');

  return <>{isRequestPage ? <SingleDependOnRequest /> : <PropreSingle />}</>;
};

export default Single;
