import {
  SportingEventArchiveMutationHookResult,
  SportingEventUnArchiveMutationHookResult,
  useSportingEventArchiveMutation,
  useSportingEventQuery,
  useSportingEventUnArchiveMutation,
  CreateSportingEventRequestMutationHookResult,
  useCreateSportingEventRequestMutation,
  Scalars,
  CloneSportingEventMutationHookResult,
  useCloneSportingEventMutation,
  UpdateSportingEventMutationHookResult,
  useUpdateSportingEventMutation,
  CreateSportingEventRequestStatusMutationHookResult,
  useCreateSportingEventRequestStatusMutation,
} from '../../../api';
import React, { FC, useState } from 'react';
import {
  Grid,
  LinearProgress,
  Link,
  Button,
  Divider,
  Paper,
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Info, Structure } from './components';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { detectedChanges } from '../../../utils/compareSportingEvents';
import PostAddIcon from '@material-ui/icons/PostAdd';
import SpeakerNotesOffIcon from '@material-ui/icons/SpeakerNotesOff';
import QueuePlayNextIcon from '@material-ui/icons/QueuePlayNext';
import ArchiveWidget from '../Shared/Archive';
import getMessage from '../../../messages';
import moment from 'moment';
import _ from 'lodash';
import EditLocationIcon from '@material-ui/icons/EditLocation';
import userRights from '../../../config/userRights';

const useStyles = makeStyles({
  statusBox: {
    maxHeight: 150,
    overflowY: 'auto',
    scrollBehavior: 'smooth',
  },
  title: {
    fontWeight: 500,
    marginRight: '0.5rem',
  },
  link: {
    cursor: 'pointer',
  },
  verticalSpace: {
    paddingBottom: 15,
  },
  verticalSpaceMarge: {
    marginBottom: 35,
  },
  divider: {
    height: 1,
    width: '100%',
    marginBottom: '1rem',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  tinyIcon: {
    fontSize: 18,
  },
  icon: {
    fontSize: 20,
  },
});

const MainInfo: FC<{ readonly?: boolean; id?: string; onStatusUpdated: Function; isRequestPage?: boolean }> = ({
  readonly,
  id,
  onStatusUpdated,
  isRequestPage,
}) => {
  const classes = useStyles();
  const [isOpenStatusWindow, setIsOpenStatusWindow] = useState(false);
  const { loading, data, refetch } = useSportingEventQuery({
    fetchPolicy: 'no-cache',
    skip: !id || id === 'new',
    variables: { id },
  });

  const { push } = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const { canChangeEkpSportingEventStatus } = userRights();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const [archive]: SportingEventArchiveMutationHookResult = useSportingEventArchiveMutation();
  const [unarchive]: SportingEventUnArchiveMutationHookResult = useSportingEventUnArchiveMutation();
  const [createRequest]: CreateSportingEventRequestMutationHookResult = useCreateSportingEventRequestMutation();
  const [
    createRequestStatus,
  ]: CreateSportingEventRequestStatusMutationHookResult = useCreateSportingEventRequestStatusMutation();
  const [cloneEvent]: CloneSportingEventMutationHookResult = useCloneSportingEventMutation();
  const [updateEvent]: UpdateSportingEventMutationHookResult = useUpdateSportingEventMutation();

  const hasUnFulfilledRequests =
    data?.dirSportingEvent?.requests?.find(
      (request) => ![2, 3, 8].includes(_.get(request, 'statuses[0].value.id', '')),
    ) !== undefined ||
    _.flattenDeep(data?.dirSportingEvent?.changes?.map((c) => c?.requests)).find(
      (request) => ![2, 3, 8].includes(_.get(request, 'statuses[0].value.id')),
    ) !== undefined;

  const request = data?.dirSportingEvent?.requests?.find((r) => r?.type?.id === 1);
  const hasRequests =
    _.get(data, 'dirSportingEvent.requests')?.filter((request: any) => request?.type?.id === 1)?.length > 0;
  const canAddMoreEkpRequest = !hasRequests
    ? true
    : _.find(
        data?.dirSportingEvent?.requests,
        (request: any) => request?.type?.id === 1 && _.get(request, 'statuses[0].value.id') !== 2,
      ) === undefined;

  const excludedRequest =
    _.find(
      data?.dirSportingEvent?.requests,
      (request: any) => request?.type?.id === 3 && _.get(request, 'statuses[0].value.id') !== 2,
    ) !== undefined;
  const editRequest =
    _.find(
      _.flattenDeep(data?.dirSportingEvent?.changes?.map((change) => change?.requests)),
      (request: any) => request?.type?.id === 2 && ![2, 8].includes(_.get(request, 'statuses[0].value.id')),
    ) !== undefined;
  const canAddChangeRequest = request && !editRequest && data?.dirSportingEvent?.status?.value === 6;

  const handleCreateRequest = async (typeId: number) => {
    try {
      const nRequest = await createRequest({
        variables: {
          eventId: id,
          typeId,
        },
      });
      nRequest?.data?.createDirSportingEventRequest &&
        (await createRequestStatus({
          variables: {
            requestId: nRequest?.data?.createDirSportingEventRequest?.id,
            data: [
              {
                value: { connect: { id: 1 } },
              },
            ],
          },
        }));
      if (typeId === 1)
        await updateEvent({
          variables: {
            data: {
              status: {
                connect: {
                  id: 1,
                },
              },
            },
            id,
          },
        });

      handleSnackBar(
        'success',
        typeId === 1
          ? 'Заявка успешно создана на включение в ЕКП'
          : typeId === 3
          ? 'Заявка успешно создана на исключена из ЕКП'
          : 'Заявка успешно создана на внесение изменений',
      );
      refetch();
      onStatusUpdated();
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const onHandleCreateChangeRequest = async () => {
    try {
      cloneEvent({ variables: { id } }).then(async (resp) => {
        if (resp?.data?.cloneDirSportingEvent !== undefined) {
          const newRequest = await createRequest({
            variables: {
              eventId: resp?.data?.cloneDirSportingEvent?.id,
              typeId: 2,
            },
          });
          newRequest?.data?.createDirSportingEventRequest &&
            (await createRequestStatus({
              variables: {
                requestId: newRequest?.data?.createDirSportingEventRequest?.id,
                data: [
                  {
                    value: { connect: { id: 1 } },
                  },
                ],
              },
            }));
          newRequest &&
            window.open(
              `/request/${newRequest?.data?.createDirSportingEventRequest?.id}`,
              '_blank',
              'noopener,noreferrer',
            );
        }
      });
      refetch();
      handleSnackBar('success', 'Заявка успешно создана на внесение изменений');
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  if (loading) return <LinearProgress color="primary" variant="query" />;

  const dChanges = detectedChanges(data?.dirSportingEvent).changesArray;

  return (
    <>
      <Grid container>
        <Grid item lg={12} md={12} xs={12}>
          {data?.dirSportingEvent && (
            <React.Fragment>
              <Grid container spacing={2} className={classes.verticalSpace}>
                {!isRequestPage && (
                  <>
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<PostAddIcon />}
                        disabled={
                          hasUnFulfilledRequests ||
                          !canAddMoreEkpRequest ||
                          (!canChangeEkpSportingEventStatus && readonly)
                        }
                        onClick={() => handleCreateRequest(1)}
                      >
                        Создать заявку
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        color="secondary"
                        startIcon={<SpeakerNotesOffIcon />}
                        onClick={() => handleCreateRequest(3)}
                        disabled={
                          hasUnFulfilledRequests ||
                          !(request && !excludedRequest && data?.dirSportingEvent?.status?.value === 6) ||
                          (!canChangeEkpSportingEventStatus && readonly)
                        }
                      >
                        Исключение из ЕКП
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="outlined"
                        {...(canAddChangeRequest &&
                          !(canChangeEkpSportingEventStatus && !readonly) && {
                            style: { color: 'green', borderColor: 'green' },
                          })}
                        startIcon={<QueuePlayNextIcon />}
                        onClick={onHandleCreateChangeRequest}
                        disabled={
                          hasUnFulfilledRequests ||
                          !canAddChangeRequest ||
                          (!canChangeEkpSportingEventStatus && readonly)
                        }
                      >
                        Внесение изменений
                      </Button>
                    </Grid>
                  </>
                )}
                <Grid item>
                  <Typography style={{ fontWeight: 600 }}>
                    <Button
                      style={{ fontWeight: 600 }}
                      color={'primary'}
                      variant={'text'}
                      onClick={() => setIsOpenStatusWindow(true)}
                    >
                      Статус мероприятия:{' '}
                    </Button>{' '}
                    {data?.dirSportingEvent?.status?.label}
                    {dChanges?.includes('status') && (
                      <span
                        style={{
                          marginLeft: 10,
                          display: 'inline-block',
                          verticalAlign: 'middle',
                        }}
                      >
                        <EditLocationIcon color="secondary" />
                      </span>
                    )}
                  </Typography>
                </Grid>
              </Grid>
              <Divider style={{ marginBottom: 10 }} />
              <Dialog open={isOpenStatusWindow} onClose={() => setIsOpenStatusWindow(false)} maxWidth={'md'}>
                <DialogTitle>
                  <Typography style={{ fontWeight: 600 }}>История изменения статуса</Typography>
                </DialogTitle>
                <DialogContent>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        {data?.dirSportingEvent?.statusHistory?.map((status) => (
                          <TableRow key={status.id}>
                            <TableCell component="th" scope="row">
                              {status.status && status.status.name}
                            </TableCell>
                            <TableCell> {status.date && moment(status.date).format('DD.MM.YY HH:mm')}</TableCell>
                            <TableCell>
                              <Link className={classes.link}> {status.user && status.user?.email}</Link>
                            </TableCell>
                            <TableCell>
                              {`${status?.user?.updatedBy?.regular?.lastname || ''} ${
                                status?.user?.updatedBy?.regular?.firstname || ''
                              } ${status?.user?.updatedBy?.regular?.patronymic || ''}`}
                            </TableCell>
                            <TableCell>{status?.user?.updatedBy?.regular?.phone || ''}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </DialogContent>
              </Dialog>
            </React.Fragment>
          )}
          <Info
            dirSportingEvent={data?.dirSportingEvent}
            dChanges={dChanges}
            classes={classes}
            infoUpdated={(value: boolean) => {
              if (value) {
                refetch();
                onStatusUpdated();
                handleSnackBar('success', 'Информация успешно обновлена');
              }
            }}
            newCreated={(value: Scalars['UUID']) => {
              if (value) {
                handleSnackBar('success', 'новое мероприятие ЕКП успешно создано');
                push(`/minsports/${value}`);
              }
            }}
            onError={(error: any) => {
              handleSnackBar('error', getMessage(error.message));
            }}
            readonly={readonly}
          />
          {data?.dirSportingEvent && (
            <React.Fragment>
              <Structure
                classes={classes}
                dChanges={dChanges}
                dirSportingEvent={data?.dirSportingEvent}
                onError={(error: any) => {
                  handleSnackBar('error', getMessage(error.message));
                }}
                commentUpdated={() => {
                  handleSnackBar('success', 'комментарии успешно обновлена');
                  refetch();
                }}
                parentAdded={() => {
                  handleSnackBar('success', 'Основное мероприятие успешно обновлено');
                  refetch();
                }}
                parentRemoved={() => {
                  handleSnackBar('success', 'Основное мероприятие успешно удалено');
                  refetch();
                }}
                childAdded={() => {
                  handleSnackBar('success', 'Зависимое мероприятие успешно добавлено');
                  refetch();
                }}
                childRemoved={() => {
                  handleSnackBar('success', 'Зависимое мероприятие успешно удалено');
                  refetch();
                }}
                readOnly={readonly}
              />
            </React.Fragment>
          )}
        </Grid>
        {id && id !== 'new' && !readonly && (
          <Paper elevation={3} style={{ width: '100%', padding: 15, margin: '10px 0' }}>
            <Grid item lg={6} md={12} xs={12}>
              <ArchiveWidget
                archive={data?.dirSportingEvent?.archive || null}
                id={id}
                refetch={refetch}
                onArchive={archive}
                onUnarchive={unarchive}
              />
            </Grid>
          </Paper>
        )}
      </Grid>
    </>
  );
};

export default MainInfo;
