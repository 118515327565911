import React from 'react';
import CalendarSports from '../../module/CalendarSports';
import { TComponentNames } from '../BtnReport/types';
import Checkbox from './Checkbox';
import DatePicker from './DatePicker';
import DatePickerRange from './DatePickerRange';
import Input from './Input';
import Radio from './Radio';
import Select from './Select';
import Switch from './Switch';
import TimePicker from './TimePicker';

export type TIC = any & {
  component: TComponentNames;
};

export default function IC(props: TIC) {
  const { component, ...rest } = props;

  switch (component) {
    case 'Checkbox':
      return <Checkbox {...rest} />;
    case 'Radio':
      return <Radio {...rest} />;
    case 'Input':
      return <Input {...rest} />;
    case 'Select':
      return <Select {...rest} />;
    case 'DatePicker':
      return <DatePicker {...rest} />;
    case 'TimePicker':
      return <TimePicker {...rest} />;
    case 'DatePickerRange':
      return <DatePickerRange {...rest} />;
    case 'Switch':
      return <Switch {...rest} />;
    case 'CalendarSports':
      return <CalendarSports {...rest} />;
    default:
      return null;
  }
}
