import React, { FC } from 'react';
import { useClsDocumentTypesQuery } from '../api';
import { Select } from '../components/Inputs';
import { ControllerProps } from 'react-hook-form/dist/types/props';

interface IDocTypes extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  error: boolean | undefined;
  disabled?: boolean;
}

const DocumentTypes: FC<IDocTypes> = ({ label, error, control, name, rules, disabled, ...other }) => {
  const { data } = useClsDocumentTypesQuery();
  return (
    <Select
      label={label}
      data={(data && data.classifierValues) || []}
      control={control}
      error={error}
      name={name}
      rules={rules}
      disabled={disabled}
      {...other}
    />
  );
};

export default DocumentTypes;
