import {
  SportingEventArchiveMutationHookResult,
  SportingEventUnArchiveMutationHookResult,
  useSportingEventArchiveMutation,
  useSportingEventUnArchiveMutation,
  Scalars,
  useSportingEventMainInfoQuery,
  useMainInfoEventDirRegionUpdateMutation,
  MainInfoEventDirRegionUpdateMutationHookResult,
  useMainInfoEventDirRegionFederalDistinctAddMutation,
  MainInfoEventDirRegionFederalDistinctAddMutationHookResult,
  useMainInfoEventDirRegionFederalDistinctRemoveMutation,
} from '../../../api';
import React, { FC, useEffect } from 'react';
import { Grid, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Info, Sports, Structure, Status } from './components';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import ArchiveWidget from '../Shared/Archive';
import getMessage from '../../../messages';
import Regions from '../../../module/Regions';
import { useForm } from 'react-hook-form';
import { FederalDistricts } from '../../../module';

const useStyles = makeStyles({
  statusBox: {
    maxHeight: 150,
    overflowY: 'auto',
    scrollBehavior: 'smooth',
  },
  title: {
    fontWeight: 500,
    marginRight: '0.5rem',
  },
  link: {
    cursor: 'pointer',
  },
  verticalSpace: {
    paddingBottom: 15,
  },
  verticalSpaceMarge: {
    marginBottom: 35,
  },
  divider: {
    height: 1,
    width: '100%',
    marginBottom: '1rem',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  tinyIcon: {
    fontSize: 18,
  },
  icon: {
    fontSize: 20,
  },
});

const MainInfo: FC<{ readonly?: boolean; id?: string; onStatusUpdated: Function }> = ({
  readonly,
  id,
  onStatusUpdated,
}) => {
  const classes = useStyles();
  const { loading, data, refetch } = useSportingEventMainInfoQuery({ returnPartialData: true, variables: { id } });

  const { push } = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const { handleSubmit, control, errors, reset, setValue, getValues } = useForm({
    defaultValues: {
      dirRegion: data?.dirSportingEvent?.dirRegion,
      dirFederalDistricts: data?.dirSportingEvent?.dirFederalDistricts,
    },
  });

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const [archive]: SportingEventArchiveMutationHookResult = useSportingEventArchiveMutation();
  const [unarchive]: SportingEventUnArchiveMutationHookResult = useSportingEventUnArchiveMutation();

  const [updateRegion]: MainInfoEventDirRegionUpdateMutationHookResult = useMainInfoEventDirRegionUpdateMutation();
  const [
    addDistinctRegion,
  ]: MainInfoEventDirRegionFederalDistinctAddMutationHookResult = useMainInfoEventDirRegionFederalDistinctAddMutation();
  const [
    removeDistinctRegion,
  ]: MainInfoEventDirRegionFederalDistinctAddMutationHookResult = useMainInfoEventDirRegionFederalDistinctRemoveMutation();

  useEffect(() => {
    reset({
      dirRegion: data?.dirSportingEvent?.dirRegion,
      dirFederalDistricts: data?.dirSportingEvent?.dirFederalDistricts,
    });
  }, [data]);

  if (loading) return <LinearProgress color="primary" variant="query" />;

  return (
    <>
      <Grid container>
        <Grid item lg={12} md={12} xs={12}>
          {data?.dirSportingEvent && id && (
            <Status
              id={id}
              statuses={data?.dirSportingEvent?.statuses}
              classes={classes}
              statusUpdated={async () => {
                await refetch();
                await onStatusUpdated();
              }}
              readOnly={readonly}
            />
          )}
          <Info
            readOnly={readonly}
            dirSportingEvent={data?.dirSportingEvent}
            classes={classes}
            infoUpdated={(value: boolean) => {
              if (value) {
                handleSnackBar('success', 'Информация успешно обновлена');
                refetch();
              }
            }}
            newCreated={(value: Scalars['UUID']) => {
              if (value) {
                handleSnackBar('success', 'Новое мероприятие успешно создано');
                push(`/sporting_event/${value}`);
              }
            }}
            onError={(error: any) => {
              handleSnackBar('error', getMessage(error.message));
            }}
          />
          {id && id !== 'new' && (
            <Grid container style={{ marginBottom: '1.5rem' }} spacing={2}>
              <Grid item xs={5}>
                <Regions
                  label="Регион РФ"
                  control={control}
                  disabled={readonly}
                  error={!!errors['dirRegion']}
                  name="dirRegion"
                  onSuperChange={async (event: any, data: any) => {
                    if (data?.value) {
                      await updateRegion({ variables: { id, idRegion: data?.value } }).then((resp) => {
                        setValue('dirRegion', data);
                        handleSnackBar('success', 'Регион РФ успешно обновлен');
                      });
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FederalDistricts
                  label="Федеральный округ"
                  control={control}
                  error={!!errors['dirFederalDistricts']}
                  name="dirFederalDistricts"
                  multiple
                  disabled={readonly}
                  onSuperChange={async (event: any, data: any) => {
                    const { dirFederalDistricts = [] } = getValues();
                    const removeItem = dirFederalDistricts?.filter((x) => !data.includes(x));
                    const addItem = data?.filter((x: any) => !dirFederalDistricts?.includes(x));
                    if (removeItem && removeItem.length > 0) {
                      await removeDistinctRegion({ variables: { id, idFederalRegion: removeItem[0].value } }).then(
                        (resp) => {
                          setValue('dirFederalDistricts', data);
                          handleSnackBar('success', `Федеральный округ "${removeItem[0].label}" успешно удален`);
                        },
                      );
                    }
                    if (addItem && addItem.length > 0) {
                      await addDistinctRegion({ variables: { id, idFederalRegion: addItem[0].value } }).then((resp) => {
                        setValue('dirFederalDistricts', data);
                        handleSnackBar('success', `Федеральный округ "${addItem[0].label}" успешно добавлен`);
                      });
                    }
                  }}
                />
              </Grid>
            </Grid>
          )}
          {data?.dirSportingEvent && (
            <React.Fragment>
              <Sports
                readOnly={readonly}
                id={data?.dirSportingEvent?.id}
                classes={classes}
                SportUpdated={() => {
                  handleSnackBar('success', 'вид спорта и группы дисциплин успешно обновлена');
                }}
                onError={(error: any) => {
                  handleSnackBar('error', getMessage(error.message));
                }}
              />
              <Structure
                readOnly={readonly}
                classes={classes}
                dirSportingEvent={data?.dirSportingEvent}
                onError={(error: any) => {
                  handleSnackBar('error', getMessage(error.message));
                }}
                commentUpdated={() => {
                  handleSnackBar('success', 'комментарии успешно обновлена');
                  refetch();
                }}
                parentAdded={() => {
                  handleSnackBar('success', 'Основное мероприятие успешно обновлено');
                  refetch();
                }}
                parentRemoved={() => {
                  handleSnackBar('success', 'Основное мероприятие успешно удалено');
                  refetch();
                }}
                childAdded={() => {
                  handleSnackBar('success', 'Зависимое мероприятие успешно добавлено');
                  refetch();
                }}
                childRemoved={() => {
                  handleSnackBar('success', 'Зависимое мероприятие успешно удалено');
                  refetch();
                }}
              />
            </React.Fragment>
          )}
        </Grid>
        {id && id !== 'new' && !readonly && (
          <Grid item lg={6} md={12} xs={12}>
            <ArchiveWidget
              readonly={readonly}
              archive={data?.dirSportingEvent?.archive || null}
              id={id}
              refetch={refetch}
              onArchive={archive}
              onUnarchive={unarchive}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default MainInfo;
