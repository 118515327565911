import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  LinearProgress,
  makeStyles,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { FC, useMemo, useState } from 'react';

import {
  CreateSportingEventMutationHookResult,
  useCreateSportingEventMutation,
  useCalendarsSportsWithEventsQuery,
} from '../../../../api';
import { useSnackbar } from 'notistack';
import AddNewEventFom, { TForm } from '../AddNewEventFom/AddNewEventFom';
import AddBoxIcon from '@material-ui/icons/AddBox';
import getMessage from '../../../../messages';
import Content from './Content';
import _ from 'lodash';
import userRights from '../../../../config/userRights';
import { RequestApplication } from './components';

const useStyles = makeStyles({
  head: {
    fontWeight: 600,
    fontSize: 15,
    letterSpacing: 1.2,
  },
  listItem: {
    fontSize: 15,
    letterSpacing: 1,
  },
});

const SecondEtape: FC<{ id?: string; readonly?: boolean }> = ({ id, readonly }) => {
  const classes = useStyles();
  const { isRoiv } = userRights();

  const { enqueueSnackbar } = useSnackbar();
  const [openAddEventFom, setOpenAddEventForm] = useState(false);
  const [active, setActive] = useState('');

  const [createNewEvent]: CreateSportingEventMutationHookResult = useCreateSportingEventMutation();

  const { data, loading, refetch } = useCalendarsSportsWithEventsQuery({
    variables: {
      id,
      stageRegistryNumber: 100002922,
    },
    fetchPolicy: 'no-cache',
  });

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const sportIds: string[] = useMemo(
    () => _.flattenDeep(data?.dirSportingEvents?.map((event) => event?.sports?.map((sport) => sport?.dirSport?.id))),
    [data],
  );

  const handleOpenAddEventForm = (evt: React.MouseEvent<HTMLElement>) => {
    evt.stopPropagation();
    setOpenAddEventForm(true);
  };

  const handleSubmitEventForm = async (value: TForm) => {
    const { name, venue, startDate, endDate, membersQuota, ekp, dirSport, dirFederalDistricts } = value;

    try {
      await createNewEvent({
        variables: {
          data: {
            name,
            venue,
            startDate,
            endDate,
            membersQuota: parseInt(membersQuota, 10),
            ekp,
            dirCalendar: {
              connect: {
                id,
              },
            },
            clsEventStages: {
              connect: [
                {
                  registryNumber: 100002922,
                },
              ],
            },
            statuses: {
              create: [
                {
                  value: { connect: { id: 1 } },
                },
              ],
            },
            status: {
              connect: {
                id: 1,
              },
            },
            sports: {
              create: [
                {
                  dirSport: {
                    connect: {
                      id: dirSport?.value,
                    },
                  },
                },
              ],
            },
            dirFederalDistricts: {
              connect: dirFederalDistricts?.map((district) => ({ id: district.value })),
            },
          },
        },
      });
      handleSnackBar('success', 'Новое мероприятие успешно создано');
      setOpenAddEventForm(false);
      refetch();
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };
  const { hasAdditionalFilter } = userRights();
  const sportFilter = hasAdditionalFilter?.sportsFilter || [];

  const sportsFiltered = useMemo(
    () =>
      data?.dirCalendar?.dirSports
        ?.filter((sport) => sportIds.includes(sport.id))
        ?.filter((sp) => sportFilter.length === 0 || (sportFilter.length > 0 && sportFilter.includes(sp.id))),
    [data, sportFilter, sportIds],
  );

  if (loading) return <LinearProgress />;

  if (isRoiv) {
    return (
      <div style={{ width: '100%' }}>
        <RequestApplication id={id} calendarName={data?.dirCalendar?.fullName} sportsFiltered={sportsFiltered} />
      </div>
    );
  }

  return (
    <div style={{ width: '100%' }}>
      <RequestApplication
        id={id}
        calendarName={data?.dirCalendar?.fullName}
        calendarDateAcceptQuotasAndFilesStageTwo={data?.dirCalendar?.dateAcceptQuotasAndFilesStageTwo}
        sportsFiltered={sportsFiltered}
      />
      {openAddEventFom && (
        <AddNewEventFom
          ekp={data?.dirCalendar?.ekpMinsportNumber}
          etape={2}
          id={id}
          handleClose={() => setOpenAddEventForm(false)}
          onSubmit={handleSubmitEventForm}
          secondEtape
        />
      )}
      {!readonly && (
        <IconButton style={{ padding: 0, marginBottom: 10 }} color="primary" onClick={handleOpenAddEventForm}>
          <AddBoxIcon />
        </IconButton>
      )}
      {sportsFiltered.map((sport) => (
        <Accordion
          key={sport.id}
          expanded={active === sport.fullName}
          onChange={() => setActive(active === sport.fullName ? '' : sport.fullName)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${sport.fullName}-content`}
            id={`sport1-header`}
          >
            <Typography className={classes.head} color="primary">
              {sport.fullName}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {active === sport.fullName && <Content id={id} sportId={sport.id} readonly={readonly} />}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default SecondEtape;
