import React, { FC } from 'react';
import { SpartkiadsCategory } from './components/SpartkiadsCategory';
import { UniversityCategory } from './components/UniversityCategory';
import { PervenstvoCategory } from './components/PervenstvoCategory';
import { StudentCompetitionCategory } from './components/StudentCompetitionCategory';

const Analitics: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const calendarYearList = [2022, 2023, 2024, 2025];
  // const { loading, data } = useAnalyticsSportingEventsByRegionIdQuery({
  //   fetchPolicy: 'no-cache',
  //   variables: {
  //     filter: {
  //       dirNationalTeams_some: {
  //         dirRegion: {
  //           id,
  //         },
  //       },
  //     },
  //   },
  // });

  // if (loading) return <LinearProgress color="primary" variant="query" />;
  if (!id) return null;
  return (
    <div style={{ width: '100%' }}>
      {/* {data?.dirSportingEvents?.map((event) => (
        <Grid item container key={event?.id}>
          <Link href={`/sporting_event/${event?.id}`} target="_blank">
            <Typography style={{ fontSize: 16, fontWeight: 600 }}>{event?.name}</Typography>
          </Link>
        </Grid>
      ))} */}
      <SpartkiadsCategory regionId={id} calendarYearList={calendarYearList} />
      <UniversityCategory regionId={id} calendarYearList={calendarYearList} />
      <PervenstvoCategory regionId={id} calendarYearList={calendarYearList} />
      <StudentCompetitionCategory regionId={id} calendarYearList={calendarYearList} />
    </div>
  );
};

export default Analitics;
