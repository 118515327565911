import React, { FC, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import logoOne from '../../../static/img/Logo/minsport.png';
import logoTwo from '../../../static/img/Logo/fcpsr.jpg';
import logout from '../../../static/img/Main/icons/logout.svg';
import { Menu } from './Menu';
import useStyles from './style';
import {
  CssBaseline,
  Grid,
  Button,
  MenuList,
  Drawer,
  Divider,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
} from '@material-ui/core';

import { useChangePasswordUserMutation, useValidationTokenQuery } from '../../../api';
import DialogRequest from '../../DialogRequest/DialogRequest';
import { Helmet } from 'react-helmet';
import Radios from '../../Inputs/Radio';
import PersonsModule from '../../../module/Persons';
import { DatePicker, Input } from '../../Inputs';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

const MainTemplate: FC<{ pageName: string; url: string; title?: string; children?: React.ReactNode }> = ({
  pageName,
  url,
  title,
  children,
}) => {
  const { push } = useHistory();
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [submenu, setSubmenu] = useState(false);
  const anchorRef = useRef(null);
  const [modalChangePassword, showChangePassword] = useState(false);
  const [changePassword] = useChangePasswordUserMutation();

  const { handleSubmit, control, errors, setError, getValues } = useForm();
  const { enqueueSnackbar } = useSnackbar();

  const sentRequest = ({ oldPassword, newPassword }: Record<string, string>) => {
    try {
      changePassword({ variables: { oldPassword, newPassword } })
        .then(() => {
          enqueueSnackbar(`Пароль успешно изменен`, {
            variant: 'success',
          });
          showChangePassword(false);
        })
        .catch((e) => {
          setError('oldPassword', { message: 'Неверный старый пароль' });
          enqueueSnackbar(`Старый пароль указан не верно`, {
            variant: 'error',
          });
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handlerSubmenuToggle = () => {
    setSubmenu(!submenu);
  };

  const onExit = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userRole');
    push('/login');
  };

  const { error } = useValidationTokenQuery();
  if (error) {
    if (error.message === 'Not Authorised!') {
      localStorage.removeItem('token');
      push('/login');
    }
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      {title && (
        <Helmet>
          <title>{title}</title>
        </Helmet>
      )}
      {/*<AppBar*/}
      {/*  position="fixed"*/}
      {/*  className={classNames(classes.appBar, {*/}
      {/*    [classes.appBarShift]: open,*/}
      {/*  })}*/}
      {/*  style={{ backgroundColor: '#fff', color: '#000' }}*/}
      {/*>*/}
      {/*  <Toolbar disableGutters={!open}>*/}
      {/*    <IconButton*/}
      {/*      color="inherit"*/}
      {/*      aria-label="Open drawer"*/}
      {/*      onClick={handleDrawerOpen}*/}
      {/*      className={classNames(classes.menuButton, open && classes.hide)}*/}
      {/*    >*/}
      {/*      <MenuIcon />*/}
      {/*    </IconButton>*/}
      {/*    <Typography variant="h6" color="inherit" noWrap>*/}
      {/*      <div className={classes.pageNamestyle}>{pageName}</div>*/}
      {/*    </Typography>*/}
      {/*  </Toolbar>*/}
      {/*</AppBar>*/}

      <form>
        <Dialog open={modalChangePassword} aria-labelledby="form-dialog-title">
          <DialogContent style={{ width: 400, height: 250 }}>
            <Grid container direction="column" spacing={1}>
              <Grid item md={12}>
                <Typography variant="h4" style={{ fontWeight: 'bold' }}>
                  Форма изменения пароля
                </Typography>
              </Grid>
              <Grid item md={12}>
                <Input
                  label="Старый пароль"
                  control={control}
                  error={!!errors['oldPassword']}
                  name="oldPassword"
                  errorMessage={errors['oldPassword']?.message}
                  type="password"
                  rules={{ required: 'Обязательное поле' }}
                />
              </Grid>
              <Grid item md={12}>
                <Input
                  label="Новый пароль"
                  control={control}
                  error={!!errors['newPassword']}
                  name="newPassword"
                  errorMessage={errors['newPassword']?.message}
                  type="password"
                  rules={{
                    required: 'Обязательное поле',
                    pattern: {
                      value: /(?=.*[!@#$%^&()*])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&()*]{6,}/g,
                      message:
                        'Минимальная длина пароля 6 символов, наличие прописных, строчные буквы а также спец. символа. Разрешенные спец. символы !@#$%^&()*',
                    },
                  }}
                />
              </Grid>
              <Grid item md={12}>
                <Input
                  label="Подтверждение нового пароля"
                  control={control}
                  error={!!errors['newPasswordRetype']}
                  errorMessage={errors['newPasswordRetype']?.message}
                  name="newPasswordRetype"
                  type="password"
                  rules={{
                    validate: (value) =>
                      value === getValues('newPassword') || 'Подтверждение пароля не совпадает с новым паролем',
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button size="small" onClick={() => showChangePassword(false)} type="button">
              Закрыть
            </Button>
            <Button size="small" variant="outlined" onClick={handleSubmit(sentRequest)} color="primary">
              Сменить пароль
            </Button>
          </DialogActions>
        </Dialog>
      </form>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader} style={{ minHeight: 'auto' }}>
          <React.Fragment>
            <Grid
              container
              style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
              <Link to={'/'}>
                <img src={logoOne} alt="" style={{ height: 61, marginTop: 10 }} />
                <img src={logoTwo} alt="" style={{ height: 61, marginTop: 10 }} />
              </Link>
              <MenuList
                onClick={onExit}
                style={{ position: 'absolute', top: 0, right: 0, padding: 10, cursor: 'pointer' }}
              >
                <img src={logout} alt="" />
              </MenuList>
            </Grid>

            <div>
              <Button onClick={handlerSubmenuToggle} className={classes.buttonStyleright} ref={anchorRef}>
                <span>
                  {localStorage.getItem('userDescription')}
                  <span style={{ fontWeight: 600, display: 'block' }}>{localStorage.getItem('userName')}</span>
                </span>
              </Button>
            </div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                showChangePassword(true);
              }}
            >
              Сменить пароль
            </Button>
            <br />
          </React.Fragment>
          {/* <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton> */}
        </div>
        <Divider />

        <Menu url={url} />

        <Divider />
      </Drawer>
      <main
        className={classNames(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        {children}
        <DialogRequest />
      </main>
    </div>
  );
};

export default MainTemplate;
