import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Typography,
} from '@material-ui/core';
import {
  CreateTeamStatusMutationHookResult,
  Scalars,
  SendNotificationUpdateStatusMutationHookResult,
  UploadFileMutationHookResult,
  useCreateTeamStatusMutation,
  useSendNotificationUpdateStatusMutation,
  useTeamStatusesQuery,
  useUploadFileMutation,
} from '../../../../api';
import React, { FC, useEffect, useMemo, useState } from 'react';

import StatusBoard from './StatusBoard';
import StatusForm from './StatusForm';
import getMessage from '../../../../messages';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import userRights from '../../../../config/userRights';

const useStyles = makeStyles({
  row: {
    display: 'flex',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  tinyIcon: {
    fontSize: 18,
  },
  icon: {
    fontSize: 20,
  },
  tableHead: {
    fontWeight: 'bold',
  },
  header: {
    fontWeight: 600,
    fontSize: 15,
    letterSpacing: 1.2,
  },
});

interface formState {
  status?: { label: string; value: Scalars['UUID'] };
  comment?: string;
  file?: File | { id: Scalars['UUID']; name: string; path: string };
}

const Status: FC<{
  readonly?: boolean;
  team?: any;
  id?: any;
  onUpdate: Function;
}> = ({ readonly: read_only, id, team, onUpdate }) => {
  const classes = useStyles();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    canChangeNationalTeamStatus,
    isSportingEventOperatorFspcr,
    isRoivFspcr,
    isRegionalSchool,
    canSendNationalTeamBackToStart,
  } = userRights();
  const { data: statusData } = useTeamStatusesQuery();
  const status = team?.statuses && team?.statuses.length ? team?.statuses[0] : null;
  const readonly =
    (isSportingEventOperatorFspcr && ![2].includes(status?.value?.id)) ||
    ((isRoivFspcr || isRegionalSchool) && ![5, 6].includes(status?.value?.id)) ||
    (read_only && !canChangeNationalTeamStatus);

  const statusArray = useMemo(() => team?.statuses || [], [team]);
  const DOWNLOAD_LINK = process.env.REACT_APP_BACKEND_URI?.replace('/graphql', '');

  const [newStatus, setNewStatus] = useState<
    ({ __typename?: 'DirNationalTeamStatus' | undefined } & { value: number; label: string }) | null | undefined
  >(null);
  const [IsReturning, setIsReturning] = useState(false);

  const [uploadDocumentFile]: UploadFileMutationHookResult = useUploadFileMutation();
  const [create]: CreateTeamStatusMutationHookResult = useCreateTeamStatusMutation();
  const [sendNotification]: SendNotificationUpdateStatusMutationHookResult = useSendNotificationUpdateStatusMutation();

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const handleSetNewStatus = (statusId: number) => {
    const valueArray = statusData?.dirNationalTeamStatuses;
    const nStatus = valueArray?.find((s) => s?.value === statusId);
    setNewStatus(nStatus);
  };

  const handleReturn = () => {
    setIsReturning(true);
    handleSetNewStatus(4);
  };

  useEffect(() => {
    const lastStatus = team?.dirNationalTeam?.statuses?.[0];

    if (lastStatus) {
      if (canChangeNationalTeamStatus) {
        sendNotification({
          variables: {
            id: lastStatus.id,
          },
        });
      }
    }
  }, [team, canChangeNationalTeamStatus, sendNotification]);

  const onCreate = async (values: formState) => {
    const { comment, file } = values;
    const v = IsReturning ? 5 : newStatus?.value;

    try {
      setIsSubmitting(true);
      if (file) {
        uploadDocumentFile({
          variables: {
            file,
          },
        }).then(async (resp: any) => {
          const {
            uploadFile: { id: fileId },
          } = resp.data;
          await create({
            variables: {
              id: team?.id,
              data: [
                {
                  ...(comment && { comment: comment as string }),
                  value: { connect: { id: v } },
                  file: { connect: { id: fileId } },
                },
              ],
            },
          });
          handleSnackBar('success', `статус успешно обновлен`);
          setNewStatus(null);
          setIsSubmitting(false);
          IsReturning && setIsReturning(false);
          await onUpdate();
        });
      } else {
        await create({
          variables: {
            id: team?.id,
            data: [
              {
                ...(comment && { comment: comment as string }),
                value: { connect: { id: v } },
              },
            ],
          },
        });
        handleSnackBar('success', `статус успешно обновлен`);
        setNewStatus(null);
        setIsSubmitting(false);
        IsReturning && setIsReturning(false);
        await onUpdate();
      }
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
      setIsSubmitting(false);
    }
  };

  return (
    <Accordion expanded={true} style={{ width: '100%', margin: 0, marginBottom: 15 }}>
      <AccordionSummary aria-controls={`team-status-content`} id={`team-status-header`}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item md={4} xs={12}>
            <Typography className={classes.header}>Статус сборной: {status?.value?.name || '~'}</Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: 'column' }}>
        <Dialog open={!!newStatus} onClose={() => setNewStatus(null)}>
          <DialogTitle>
            <Typography style={{ fontSize: 20, fontWeight: 600 }}>{newStatus?.label}</Typography>
          </DialogTitle>
          <DialogContent>
            <StatusForm
              isSubmitting={isSubmitting}
              IsReturning={IsReturning}
              onEdit={(values: formState) => onCreate(values)}
              onCancel={() => {
                setNewStatus(null);
                IsReturning && setIsReturning(false);
              }}
            />
          </DialogContent>
        </Dialog>
        <Grid container spacing={2} style={{ marginBottom: 10 }}>
          <Grid item container md={6} xs={12} spacing={1}>
            <Grid item>
              <Typography style={{ fontWeight: 600 }}> Документ:</Typography>
            </Grid>
            <Grid item>
              {status?.file ? (
                <Link href={`${DOWNLOAD_LINK}${status?.file?.path}`}>
                  <Typography style={{ wordBreak: 'break-all' }}> {status?.file?.name}</Typography>
                </Link>
              ) : (
                '~'
              )}
            </Grid>
          </Grid>
          <Grid item container md={6} xs={12} spacing={1}>
            <Grid item>
              <Typography style={{ fontWeight: 600 }}> Комментарий:</Typography>
            </Grid>
            <Grid item>
              <Typography> {status?.comment || '~'}</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={1} style={{ marginBottom: 30 }}>
          <Grid item>
            <Button
              variant={'contained'}
              color={'primary'}
              size={'small'}
              onClick={() => handleSetNewStatus(5)}
              disabled={readonly || [5, 4].includes(status?.value?.id)}
            >
              проект
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant={'contained'}
              color={'primary'}
              size={'small'}
              onClick={() => handleSetNewStatus(2)}
              disabled={readonly || ![5].includes(status?.value?.id)}
            >
              на согласование
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant={'contained'}
              color={'primary'}
              size={'small'}
              onClick={() => handleSetNewStatus(3)}
              disabled={readonly || ![2].includes(status?.value?.id)}
            >
              согласовать
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant={'contained'}
              color={'primary'}
              size={'small'}
              onClick={() => handleSetNewStatus(4)}
              disabled={readonly || ![3].includes(status?.value?.id)}
            >
              утвердить
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant={'contained'}
              color={'secondary'}
              size={'small'}
              onClick={() => handleSetNewStatus(6)}
              disabled={readonly || ![3].includes(status?.value?.id)}
            >
              отклонить
            </Button>
          </Grid>
          {canSendNationalTeamBackToStart && (
            <Grid item>
              <Button
                variant={'contained'}
                color={'primary'}
                size={'small'}
                disabled={![4].includes(status?.value?.id)}
                onClick={handleReturn}
              >
                Редактировать участников
              </Button>
            </Grid>
          )}
        </Grid>

        <StatusBoard statusArray={statusArray} />
      </AccordionDetails>
    </Accordion>
  );
};

export default Status;
