import {
  Accordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Typography,
  withStyles,
} from '@material-ui/core';
import { ContentWrapperWidget, MainTemplate } from '../../components/layouts';
import React, { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';

import ChangesHistory from '../../components/widgets/NationalTeams/ChangesHistory';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MainInfo from '../../components/widgets/NationalTeams/MainInfo';
import TeamInfo from '../../components/widgets/NationalTeams/TeamInfo';
import { blue } from '@material-ui/core/colors';
import moment from 'moment';
import onExpand from '../../utils/onExpand';
import { useNationalTeamLazyQuery } from '../../api';
import { usePermission } from '../../hooks/usePermission';
import { useParams } from 'react-router-dom';
import userRights from '../../config/userRights';
import LoadingWall from '../../components/LoadingWall/LoadingWall';

type AccordionTypes = 'MainInfo' | 'TypeSport' | 'TeamInfo' | 'ChangesHistory' | undefined;

const AccordionSummary = withStyles({
  root: {
    backgroundColor: blue[700],
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    color: 'white',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary) as any;

const Single: FC<{}> = () => {
  const { id }: { id?: string } = useParams();

  const { hasLimAccess, canChangeNationalTeamStatus, hasAdditionalFilter, isRegionalSchool } = userRights();

  const access = usePermission('directory', 'national_teams');

  // const [page, setPage] = useState<number>(0);
  const [expanded, setExpanded] = useState<AccordionTypes>('MainInfo');

  // const access = usePermission('directory', 'person');
  // const style = useStyle();
  // const canAdd = true;

  const [fetch, { data, loading, refetch }] = useNationalTeamLazyQuery({
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (id) {
      fetch({
        variables: { id, ...hasAdditionalFilter?.national_teams },
      });
    }
  }, [id]);
  const handleChangeAccordion = (panel: AccordionTypes) => (event: ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : undefined);
  };

  const status = useMemo(
    () =>
      data?.dirNationalTeam?.statuses && data?.dirNationalTeam?.statuses.length
        ? data?.dirNationalTeam?.statuses[0]
        : null,
    [data],
  );

  const readOnly = isRegionalSchool ? false : status?.value?.id !== 5 ? true : !access.includes(2);
  const OptionTitleArchive = data?.dirNationalTeam?.archive
    ? ` (В архиве с ${moment(data?.dirNationalTeam?.archive?.date).format('DD.MM.YYYY')})`
    : '';

  return (
    <MainTemplate
      pageName=""
      url=""
      title={id === 'new' ? '' : `Сборная команда: ${data?.dirNationalTeam?.dirRegion?.fullName}`}
    >
      <ContentWrapperWidget>
        <h2>Сборная команда {OptionTitleArchive}</h2>
        <Accordion expanded={expanded === 'MainInfo'} onChange={handleChangeAccordion('MainInfo')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Общая информация</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {onExpand(expanded, 'MainInfo') && !loading && (
              <MainInfo id={data?.dirNationalTeam?.id} readonly={readOnly} data={data} refetch={fetch} />
            )}
            {onExpand(expanded, 'MainInfo') && loading && <LoadingWall />}
          </AccordionDetails>
        </Accordion>

        <Accordion expanded={expanded === 'TeamInfo'} onChange={handleChangeAccordion('TeamInfo')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Состав сборной команды</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {onExpand(expanded, 'TeamInfo') && !loading && (
              <TeamInfo id={data?.dirNationalTeam?.id} readonly={readOnly} />
            )}
            {onExpand(expanded, 'TeamInfo') && loading && <LoadingWall />}
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'ChangesHistory'} onChange={handleChangeAccordion('ChangesHistory')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>История изменений</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {onExpand(expanded, 'ChangesHistory') && (
              <ChangesHistory id={data?.dirNationalTeam?.id} readonly={readOnly} />
            )}
          </AccordionDetails>
        </Accordion>
      </ContentWrapperWidget>
    </MainTemplate>
  );
};

export default Single;
