import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Link,
  LinearProgress,
  TableCell,
  TableRow,
  TableHead,
  TableBody,
  TableContainer,
  Table,
  Paper,
  Box,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import { useAggregateEventAthletesByPointsQuery } from '../../../../api';
import _ from 'lodash';
import { fetchSportingEventMedalReport } from '../../../BtnReport/services/fetchSportingEventMedalReport';

const useStyles = makeStyles({
  row: {
    display: 'flex',
  },
  container: {
    maxHeight: 440,
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  tinyIcon: {
    fontSize: 18,
  },
  icon: {
    fontSize: 20,
  },
  tableHead: {
    fontWeight: 'bold',
  },
  header: {
    fontWeight: 600,
    fontSize: 15,
    letterSpacing: 1.2,
  },
});

const columnEvent = [
  {
    name: 'name',
    label: 'Регион РФ',
  },
  {
    name: 'athletesNumber',
    label: 'Кол-во спортсменов в сборной',
  },
  {
    name: 'totalGold',
    label: 'кол-во золотых медалей',
  },
  {
    name: 'totalSilver',
    label: 'кол-во серебряных медалей',
  },
  {
    name: 'totalBronze',
    label: 'кол-во бронзовых медалей',
  },
  {
    name: 'total_4_10',
    label: 'кол-во занятых мест с 4 по 10',
  },
  {
    name: 'total_11_20',
    label: 'кол-во занятых мест с 11 по 20',
  },
];

const MedalStandings: FC<{ readonly?: boolean; id?: string; eventName?: string }> = ({ readonly, id, eventName }) => {
  const classes = useStyles();

  // @ts-ignore
  const { data, isLoading } = useAggregateEventAthletesByPointsQuery({
    fetchPolicy: 'no-cache',
    variables: { id },
    skip: !id,
  });

  const items = useMemo(
    () =>
      _.sortBy(data?.aggregateEventAthletesByPoints?.regions || [], [
        'totalGold',
        'totalSilver',
        'totalBronze',
        'total_4_10',
        'total_11_20',
      ]).reverse(),
    [data],
  );

  const handleMedalReport = () => {
    fetchSportingEventMedalReport({
      data: items,
      fileName: eventName || '',
    });
  };

  console.log('items ==================>', items);

  return (
    <Accordion key="Медальный зачет" style={{ width: '100%', marginBottom: 15 }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`Медальный зачет-content`}
        id={`Медальный зачет-header`}
      >
        <Grid container spacing={2} justify="space-between" alignItems="center">
          <Grid item>
            <Typography className={classes.header} color="primary">
              Медальный зачет
            </Typography>
          </Grid>
          <Grid item onClick={(e) => e.stopPropagation()}></Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: 'column' }}>
        <Box>
          <Button onClick={handleMedalReport} size="small" variant="outlined" color="primary" endIcon={<SaveIcon />}>
            Сохранить
          </Button>
        </Box>

        <Box pt={3}>
          {isLoading ? (
            <LinearProgress />
          ) : (
            <TableContainer className={classes.container} component={Paper}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columnEvent.map((column) => (
                      <TableCell key={column.name}>{column.label}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {items.map((item) => {
                    return (
                      <TableRow>
                        {columnEvent.map((column) => {
                          const value = item[column.name];
                          return <TableCell key={column.name}>{value}</TableCell>;
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>

            // <MUIDataTable
            //   title={''}
            //   data={items}
            //   columns={columnEvent}
            //   options={{
            //     filter: false,
            //     search: false,
            //     pagination: false,
            //     selectableRowsHideCheckboxes: true,
            //     selectableRows: 'none',
            //     selectableRowsHeader: false,
            //     print: false,
            //     download: data?.length > 0,
            //     viewColumns: false,
            //     textLabels: {
            //       body: {
            //         noMatch: 'Извините, данные отсутствуют',
            //       },
            //     },
            //   }}
            // />
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default MedalStandings;
