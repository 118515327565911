import {
  Archive,
  DirSportDiscipline,
  GroupTypesSportAndDisciplinesDocument,
  Maybe,
  TypeSportDisciplinesArchiveMutationHookResult,
  TypeSportDisciplinesArchiveRemoveMutationHookResult,
  TypeSportDisciplinesCreateMutationHookResult,
  TypeSportDisciplinesUpdateMutationHookResult,
  useTypeSportDisciplinesArchiveMutation,
  useTypeSportDisciplinesArchiveRemoveMutation,
  useTypeSportDisciplinesCreateMutation,
  useTypeSportDisciplinesQuery,
  useTypeSportDisciplinesUpdateMutation,
} from '../../../api';
import { Button, Grid, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import React, { FC, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import ArchiveComponent from '../../Dialogs/Archive/Archive';
import EditIcon from '@material-ui/icons/Edit';
import { Input } from '../../Inputs';
import SaveIcon from '@material-ui/icons/Save';
import getMessage from '../../../messages';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

type Discipline = { __typename?: 'DirSportDiscipline' } & Pick<DirSportDiscipline, 'id' | 'name' | 'code'> & {
    archive?: Maybe<{ __typename?: 'Archive' } & Pick<Archive, 'id' | 'comment' | 'reason'>>;
  };

const Disciplines: FC<{ readonly?: boolean; id: string }> = ({ readonly, id }) => {
  const { data, refetch } = useTypeSportDisciplinesQuery({ variables: { id }, fetchPolicy: 'no-cache' });
  const { handleSubmit, register, control, errors, reset, formState } = useForm({
    defaultValues: { code: '', name: '', id: null } as Discipline,
  });
  const [mode, setMode] = useState<'list' | 'form'>('list');
  const [edit, setEdit] = useState<null | Discipline>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [create]: TypeSportDisciplinesCreateMutationHookResult = useTypeSportDisciplinesCreateMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: GroupTypesSportAndDisciplinesDocument,
        data: {
          ...data?.updateDirSport,
        },
      });
    },
  });

  const [archive]: TypeSportDisciplinesArchiveMutationHookResult = useTypeSportDisciplinesArchiveMutation();
  const [
    unarchive,
  ]: TypeSportDisciplinesArchiveRemoveMutationHookResult = useTypeSportDisciplinesArchiveRemoveMutation();
  const [update]: TypeSportDisciplinesUpdateMutationHookResult = useTypeSportDisciplinesUpdateMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: GroupTypesSportAndDisciplinesDocument,
        data: {
          ...data?.updateDirSport,
        },
      });
    },
  });

  const onSubmit = handleSubmit(async (values: Discipline) => {
    try {
      if (edit) {
        await update({
          variables: {
            id,
            name: values?.name,
            code: values?.code,
            idDiscipline: values?.id,
          },
        });
        setEdit(null);
        enqueueSnackbar(`Дисциплина "${values?.name}" успешно обновлена`, { variant: 'success' });
      } else {
        await create({
          variables: {
            id,
            name: values?.name,
            code: values?.code,
          },
        });
        enqueueSnackbar(`Дисциплина "${values?.name}" успешно добавлена`, { variant: 'success' });
      }
      reset({ code: '', name: '' });
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
    setMode('list');
  });

  return (
    <Grid container>
      {!readonly && !(mode === 'form' || edit) && (
        <Grid item md={12}>
          <IconButton
            onClick={() => {
              reset({ code: '', name: '' });
              setMode('form');
            }}
            edge="end"
            aria-label="add"
            type="button"
          >
            <AddIcon color="primary" />
          </IconButton>
        </Grid>
      )}
      {(mode === 'form' || edit) && (
        <form onSubmit={onSubmit} style={{ minWidth: '100%' }}>
          <Grid item md={12} container spacing={2} direction="row">
            <input type="hidden" name="id" ref={register()} />
            <Grid item md={4}>
              <Input label="Название дисциплины" control={control} error={!!errors['name']} name="name" />
            </Grid>
            <Grid item md={4}>
              <Input label="Код дисциплины" control={control} error={!!errors['code']} name="code" />
            </Grid>
            <Grid item md={12} container spacing={1}>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  type="button"
                  onClick={() => {
                    setMode('list');
                    setEdit(null);
                  }}
                  size="small"
                >
                  Отмена
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  type="submit"
                  size="small"
                  startIcon={<SaveIcon color="primary" />}
                  disabled={!formState.isDirty}
                >
                  Сохранить
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
      {mode === 'list' && !edit && (
        <Grid item md={6}>
          <List>
            {data?.dirSport?.disciplines?.map((item) => (
              <ListItem key={item.id} alignItems="flex-start">
                <ListItemText primary={item.name} secondary={item.code} />
                {!readonly && (
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="редактировать"
                      onClick={() => {
                        reset(item);
                        setEdit(item);
                      }}
                    >
                      <EditIcon color="primary" />
                    </IconButton>
                    <ArchiveComponent
                      id={item.id}
                      archiveStatus={item.archive}
                      canUnArchive
                      unarchive={unarchive}
                      refetch={refetch}
                      archive={archive}
                    />
                  </ListItemSecondaryAction>
                )}
              </ListItem>
            ))}
          </List>
        </Grid>
      )}
    </Grid>
  );
};

export default Disciplines;
