import React, { FC } from 'react';
import MultiSelect from '../components/Inputs/MultiSelect';
import { ControllerProps } from 'react-hook-form/dist/types/props';
import { useClsRefereeCategoriesQuery } from '../api';

interface RefereesCategory extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  error: boolean | undefined;
}

const RefereesCategories: FC<RefereesCategory> = ({ label, error, control, name, rules }) => {
  const { data } = useClsRefereeCategoriesQuery();

  return (
    <MultiSelect
      label={label}
      data={(data && data.classifierValues) || []}
      control={control}
      error={error}
      name={name}
      rules={rules}
    />
  );
};

export default RefereesCategories;
