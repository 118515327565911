import { Box, Button, Grid } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { useGenerateXlsFileLazyQuery, useTypeSportsCountLazyQuery } from '../../api';

import ClearIcon from '@material-ui/icons/Clear';
import ContentWrapper from '../layouts/ContentWrapper/ContentWrapper';
import { Count } from '../Count';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Input } from '../Inputs';
import SearchIcon from '@material-ui/icons/Search';
import TuneIcon from '@material-ui/icons/Tune';
import { TypeSportsFilterAdditional } from './index';
import { makeStyles } from '@material-ui/core/styles';
import { onSetTypeSportsFilter } from './helpers';
import { useForm } from 'react-hook-form';
import useGenerateXlsx from '../../hooks/useGenerateXlsx';

const useStyle = makeStyles((theme) => ({
  btn: {
    marginRight: theme.spacing(2),
  },
}));

const TypeSports: FC<{
  onSubmit?: (T: any, E: any) => void;
  value?: any;
  defaultFilter?: any;
  first: number;
}> = ({ onSubmit = () => {}, value = {}, defaultFilter = {}, first }) => {
  const style = useStyle();
  const { handleSubmit, control, errors, reset, formState, setValue, getValues } = useForm({ defaultValues: value });
  const [additional, setAdditional] = useState<boolean>(Object.keys(value)?.length > 0);
  const [filter, setFilter] = useState<any>({
    ...defaultFilter,
    archive: null,
  });

  const { onDownloadFile } = useGenerateXlsx({
    lazyQuery: useGenerateXlsFileLazyQuery,
    getValues,
    setFilter: onSetTypeSportsFilter,
    fileName: 'type_sports',
    first,
  });

  const handleSearchBox = (value: any, event?: React.BaseSyntheticEvent) => {
    const filters: any = onSetTypeSportsFilter(value);

    setFilter(filters);
    onSubmit(filters, getValues());
  };

  return (
    <ContentWrapper>
      <form
        onSubmit={handleSubmit(handleSearchBox)}
        onReset={(e?) => {
          setValue('registryNumber', '');
          reset({});
          setFilter({
            archive: null,
          });
          onSubmit(
            {
              archive: null,
            },
            {},
          );
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <Input
              label="Идентификатор вида спорта"
              control={control}
              error={!!errors['registryNumber']}
              name="registryNumber"
            />
          </Grid>
          <Grid item xs={3}>
            <Input label="Номер-код ВРВС" control={control} error={!!errors['evsk']} name="evsk" />
          </Grid>
          <Grid item xs={3}>
            <Input label="Название" control={control} error={!!errors['fullName']} name="fullName" />
          </Grid>
          {additional && <TypeSportsFilterAdditional control={control} errors={errors} />}
          <Grid item container alignItems={'center'}>
            <Button
              variant="outlined"
              className={style.btn}
              color="primary"
              type="reset"
              onClick={() => {
                reset({
                  registryNumber: '',
                  evsk: '',
                  fullName: '',
                  archive: { value: false, label: 'Нет' },
                  clsSportAndDisciplineGroups: null,
                  ageGroups: null,
                  clsRanksAndAwards: null,
                  disciplines: '',
                  disciplineGroups: '',
                });
              }}
              size="small"
              startIcon={<ClearIcon />}
            >
              Сброс
            </Button>
            <Button
              variant="outlined"
              color="primary"
              type="submit"
              size="small"
              className={style.btn}
              startIcon={<SearchIcon />}
              disabled={!formState.isDirty}
            >
              Поиск
            </Button>
            <Button
              onClick={() => setAdditional(!additional)}
              type="button"
              className={style.btn}
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<TuneIcon />}
            >
              Фильтр
            </Button>
            <Box pl={4}>
              <Button
                variant="outlined"
                color="primary"
                disabled={!formState.isDirty}
                onClick={onDownloadFile}
                size={'small'}
                startIcon={<GetAppIcon />}
              >
                XLS
              </Button>
              <Count key={JSON.stringify(filter)} query={useTypeSportsCountLazyQuery} filter={filter} />
            </Box>
          </Grid>
        </Grid>
      </form>
    </ContentWrapper>
  );
};

export default TypeSports;
