import { Grid, IconButton, LinearProgress } from '@material-ui/core';
import {
  OrganizationCreateTypeSportMutationHookResult,
  OrganizationTypeSportsDocument,
  useOrganizationCreateTypeSportMutation,
  useOrganizationTypeSportsQuery,
} from '../../../api';
import React, { FC } from 'react';

import AddIcon from '@material-ui/icons/Add';
import SportAccordion from './components/SportAccordion';
import _get from 'lodash/get';
import getMessage from '../../../messages';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import TypeSportsSelect from '../../../module/TypeSportsSelect';

const OrganizationTypeSports: FC<{ readonly?: boolean; id: string; regionId?: string }> = ({
  readonly,
  id,
  regionId,
}) => {
  const { data, refetch, loading } = useOrganizationTypeSportsQuery({ fetchPolicy: 'no-cache', variables: { id } });
  const { handleSubmit, control, errors, reset } = useForm({
    defaultValues: { item: null },
  });
  const { enqueueSnackbar } = useSnackbar();

  const [create]: OrganizationCreateTypeSportMutationHookResult = useOrganizationCreateTypeSportMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: OrganizationTypeSportsDocument,
        data: {
          ...data?.updateDirOrganization,
        },
      });
    },
  });

  const onSubmit = handleSubmit(async (value) => {
    try {
      await create({
        variables: {
          id,
          idSport: _get(value, 'item.value'),
        },
      });
      enqueueSnackbar(`Вид спорта "${_get(value, 'item.label')}" успешно добавлен`, { variant: 'success' });
      reset({ item: null });
      refetch();
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  });

  if (loading) return <LinearProgress />;

  return (
    <Grid container>
      {!readonly && (
        <form onSubmit={onSubmit} style={{ width: '100%', marginBottom: 20 }}>
          <Grid item container md={6}>
            <Grid item md={10}>
              <TypeSportsSelect
                label="Вид спорта"
                error={!!errors['item']}
                name="item"
                control={control}
                rules={{
                  required: true,
                  validate: async (val) => (val?.value ? undefined : 'Поле обязательное для заполнения'),
                }}
              />
            </Grid>
            <Grid item md={2}>
              <IconButton aria-label="add" type="submit">
                <AddIcon color="primary" />
              </IconButton>
            </Grid>
          </Grid>
        </form>
      )}
      {data?.dirOrganizationSports?.map((item) => (
        <Grid key={item?.id} item container>
          <SportAccordion sport={item} orgId={id} regionId={regionId} readonly={readonly} onUpdate={() => refetch()} />
        </Grid>
      ))}
    </Grid>
  );
};

export default OrganizationTypeSports;
