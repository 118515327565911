import { Button, Grid } from '@material-ui/core';
import { DatePicker, Input } from '../Inputs';
import React, { FC, useEffect, useState } from 'react';
import { Scalars, useDocumentsCountLazyQuery } from '../../api';

import ClearIcon from '@material-ui/icons/Clear';
import ContentWrapper from '../layouts/ContentWrapper/ContentWrapper';
import { Count } from '../Count';
import { DocumentTypes } from '../../module';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';

interface Filter {
  title_contains?: string;
  number_contains?: string;
  date?: Date;
  clsType?: {
    id?: Scalars['UUID'];
  };
}

interface Value {
  title?: string;
  number?: string;
  date?: Date;
  clsType?: { label: string; value: Scalars['UUID'] };
}

const useStyle = makeStyles((theme) => ({
  btn: {
    marginRight: theme.spacing(2),
  },
}));

const Documents: FC<{ onSubmit?: (T: any, E: any) => void; value?: any; defaultFilter?: any }> = ({
  onSubmit = () => {},
  value,
  defaultFilter = {},
}) => {
  const [filter, setFilter] = useState<any>({ ...defaultFilter });

  const style = useStyle();
  const { handleSubmit, control, errors, reset, formState, getValues } = useForm({
    defaultValues: value,
  });

  const handleSearchBox = (value: Value) => {
    const filters: Filter = {};

    if (value.title !== '') {
      filters.title_contains = value.title;
    }
    if (value.number !== '') {
      filters.number_contains = value.number;
    }
    if (value.date) {
      filters.date = value.date;
    }
    if (value.clsType) {
      filters.clsType = { id: value.clsType?.value };
    }

    setFilter(filters);
    onSubmit(filters, getValues());
  };

  return (
    <ContentWrapper>
      <form onSubmit={handleSubmit(handleSearchBox)}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <Input label="Название документа" control={control} error={!!errors['title']} name="title" />
          </Grid>
          <Grid item xs={3}>
            <Input label="Номер" control={control} error={!!errors['number']} name="number" />
          </Grid>
          <Grid item xs={3}>
            <DocumentTypes label="Тип документа" error={!!errors['clsType']} control={control} name="clsType" />
          </Grid>

          <Grid item xs={3}>
            <DatePicker label="Дата" control={control} error={!!errors['date']} name="date" />
          </Grid>
          <Grid item container alignItems={'center'}>
            <Button
              variant="outlined"
              className={style.btn}
              color="primary"
              type="button"
              onClick={() => {
                onSubmit({}, {});
                setFilter({});
                reset();
              }}
              size="small"
              startIcon={<ClearIcon />}
            >
              Сброс
            </Button>
            <Button
              variant="outlined"
              color="primary"
              type="submit"
              size="small"
              className={style.btn}
              startIcon={<SearchIcon />}
              disabled={!formState.isDirty}
            >
              Поиск
            </Button>
            <Count key={JSON.stringify(filter)} query={useDocumentsCountLazyQuery} filter={filter} />
          </Grid>
        </Grid>
      </form>
    </ContentWrapper>
  );
};

export default Documents;
