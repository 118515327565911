import React, { FC, MouseEventHandler } from 'react';
import { Controller } from 'react-hook-form';
import { Radio, FormControl, FormLabel, RadioGroup, FormControlLabel } from '@material-ui/core';
import { ControllerProps } from 'react-hook-form/dist/types/props';

interface ReactHookFormInput extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  errorMessage?: string;
  error: boolean | undefined;
  data: DataInput[];
  disabled?: boolean;
  defaultValue?: any;
  onClick?: MouseEventHandler;
}

interface DataInput {
  value: any;
  label: string;
}

const Radios: FC<ReactHookFormInput> = ({
  data,
  name,
  error,
  label,
  errorMessage = '',
  control,
  rules,
  defaultValue,
  disabled,
  onClick,
}) => {
  return (
    <FormControl component="fieldset" error={error}>
      <FormLabel component="legend">
        {label} {rules?.required === true && <span style={{ color: 'red' }}>*</span>}
      </FormLabel>
      <Controller
        as={
          <RadioGroup style={{ flexDirection: 'row' }}>
            {data.map((value) => (
              <FormControlLabel
                key={value.value}
                value={value.value}
                control={<Radio />}
                label={value.label}
                disabled={disabled}
                onClick={onClick}
              />
            ))}
          </RadioGroup>
        }
        control={control}
        error={errorMessage}
        name={name}
        rules={rules}
        defaultValue={defaultValue || ''}
      />
    </FormControl>
  );
};

export default Radios;
