import { Button, Grid, Paper, Typography } from '@material-ui/core';
import { DatePicker, Input, MaskedInput } from '../../../Inputs';
import React, { FC } from 'react';

import AgeGroupsWidget from '../../TypeSportsEKP/AgeGroups';
import Calendars from '../../../../module/Calendars';
import CategorySportingEvents from '../../../../module/CategorySportingEvents';
import Directionality from '../../../../module/Directionality';
import Divisions from '../../../../module/Divisions';
import EditLocationIcon from '@material-ui/icons/EditLocation';
import EventStages from '../../../../module/EventStages';
import EventTags from '../../../../module/EventTags';
import { KeyboardIcon } from '@material-ui/pickers/_shared/icons/KeyboardIcon';
import Organizations from '../../../../module/Organizations';
import Radios from '../../../Inputs/Radio';
import RegionsBox from './RegionsBox';
import { Sports } from './index';
import SummarizingType from '../../../../module/SummarizingType';
import getMessage from '../../../../messages';
import moment from 'moment';

interface IInfoForm {
  classes?: any;
  handleGenerateOrg: any;
  handleGenerateName: any;
  onAgeGroupChanged: any;
  control: any;
  endDate: any;
  startDate: any;
  dirSportingEvent?: any;
  onSportsBlockChanged: any;
  handleSnackBar: any;
  errors?: any;
  readonly?: boolean;
  dChanges?: string[];
  sports?: string[];
  isDateError: boolean;
  isRussia: any;
  onRegionsBoxChange: any;
}

const InfoForm: FC<IInfoForm> = ({
  classes,
  control,
  dirSportingEvent,
  onSportsBlockChanged,
  onAgeGroupChanged,
  errors,
  readonly,
  dChanges,
  handleSnackBar,
  sports,
  endDate,
  startDate,
  isDateError,
  isRussia,
  onRegionsBoxChange,
  handleGenerateOrg,
  handleGenerateName,
}) => (
  <>
    <Grid item container>
      <Grid item container xs={12} justify="space-between">
        <Paper elevation={3} style={{ width: '100%', padding: 15, margin: '10px 0' }}>
          <Grid item container alignItems="flex-end">
            <Grid item xs>
              <Directionality
                label="Направленность"
                control={control}
                error={!!errors['clsDirectionality']}
                name="clsDirectionality"
                rules={{ required: true }}
                disabled={readonly}
              />
            </Grid>
            {dChanges?.includes('clsDirectionality') && (
              <Grid item>
                <EditLocationIcon color="secondary" />
              </Grid>
            )}
          </Grid>
          <Grid item container alignItems="flex-end">
            <Grid item xs>
              <Calendars
                label="Календарь"
                control={control}
                error={!!errors['dirCalendar']}
                name="dirCalendar"
                isEkp
                useFullName
                rules={{ required: true }}
                disabled={readonly}
              />
            </Grid>
            {dChanges?.includes('dirCalendar') && (
              <Grid item>
                <EditLocationIcon color="secondary" />
              </Grid>
            )}
          </Grid>
          <Grid item container alignItems="flex-end">
            <Grid item xs>
              <MaskedInput
                label="Старый реестровый номер"
                control={control}
                error={!!errors['oldRegistryNumber']}
                name="oldRegistryNumber"
                disabled={readonly}
                isNotTextMask
                mask="onlyNumber"
              />
            </Grid>
            {dChanges?.includes('oldRegistryNumber') && (
              <Grid item>
                <EditLocationIcon color="secondary" />
              </Grid>
            )}
          </Grid>
        </Paper>

        <Paper elevation={3} style={{ width: '100%', padding: 15, margin: '10px 0' }}>
          <Grid item container alignItems="flex-end">
            <Grid item xs>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Radios
                  label="Тип"
                  data={[
                    {
                      value: 'false',
                      label: 'Проведение',
                    },
                    {
                      value: 'true',
                      label: 'Участие',
                    },
                  ]}
                  control={control}
                  error={!!errors['isOrganizerOrParticipant']}
                  name="isOrganizerOrParticipant"
                  rules={{ required: false }}
                  disabled={readonly}
                />
              </div>
            </Grid>
            {dChanges?.includes('isOrganizerOrParticipant') && (
              <Grid item>
                <EditLocationIcon color="secondary" />
              </Grid>
            )}
          </Grid>

          <Grid item container alignItems="flex-end">
            <Grid item xs>
              <CategorySportingEvents
                label="Категории мероприятия"
                control={control}
                error={!!errors['clsEventCategories']}
                name="clsEventCategories"
                multiple={true}
                rules={{ required: true }}
                disabled={readonly}
              />
            </Grid>
            {dChanges?.includes('clsEventCategories') && (
              <Grid item>
                <EditLocationIcon color="secondary" />
              </Grid>
            )}
          </Grid>
          <Grid item container alignItems="flex-end">
            <Grid item xs>
              <Input
                label="Имя собственное"
                control={control}
                error={!!errors['properNoun']}
                name="properNoun"
                rules={{ required: false }}
                disabled={readonly}
              />
            </Grid>
            {dChanges?.includes('properNoun') && (
              <Grid item>
                <EditLocationIcon color="secondary" />
              </Grid>
            )}
          </Grid>
          <Grid item container alignItems="flex-end">
            <Grid item xs>
              <EventStages
                label="Этапы"
                control={control}
                error={!!errors['clsEventStages']}
                name="clsEventStages"
                rules={{ required: false }}
                disabled={readonly}
              />
            </Grid>
            {dChanges?.includes('clsEventStages') && (
              <Grid item>
                <EditLocationIcon color="secondary" />
              </Grid>
            )}
          </Grid>
          <Grid item container alignItems="flex-end">
            <Grid item xs>
              <Divisions
                label="Дивизионы и лиги"
                control={control}
                error={!!errors['clsDivisions']}
                name="clsDivisions"
                rules={{ required: false }}
                disabled={readonly}
              />
            </Grid>
            {dChanges?.includes('clsDivisions') && (
              <Grid item>
                <EditLocationIcon color="secondary" />
              </Grid>
            )}
          </Grid>
          <Grid item container alignItems="flex-end">
            <Grid item xs>
              <SummarizingType
                label="Характер подведения итогов"
                control={control}
                error={!!errors['clsSummarizingType']}
                name="clsSummarizingType"
                disabled={readonly}
              />
            </Grid>
            {dChanges?.includes('clsSummarizingType') && (
              <Grid item>
                <EditLocationIcon color="secondary" />
              </Grid>
            )}
          </Grid>
          <Grid item container alignItems="flex-end">
            <Grid item xs>
              <Input
                label="Наименование мероприятия"
                control={control}
                error={!!errors['name']}
                name="name"
                rules={{ required: true }}
                disabled={readonly}
              />
              <Grid item style={{ paddingTop: 15 }}>
                <Button
                  size="small"
                  variant="outlined"
                  //onClick={handleSubmit(onGenerateName)}
                  onClick={handleGenerateName}
                  color="primary"
                  startIcon={<KeyboardIcon />}
                  disabled={readonly}
                >
                  Сгенерировать
                </Button>
              </Grid>
            </Grid>
            {dChanges?.includes('name') && (
              <Grid item>
                <EditLocationIcon color="secondary" />
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>
      <Grid item container>
        <Paper elevation={3} style={{ width: '100%', padding: 15, margin: '10px 0' }}>
          <Grid container alignItems="flex-end">
            <Grid item xs>
              <EventTags
                label="Тэги"
                control={control}
                error={!!errors['tags']}
                name="tags"
                multiple
                isTag
                disabled={readonly}
              />
            </Grid>
            {dChanges?.includes('tags') && (
              <Grid item>
                <EditLocationIcon color="secondary" />
              </Grid>
            )}
          </Grid>
        </Paper>
      </Grid>

      <Grid item container justify="space-between">
        <Paper elevation={3} style={{ width: '100%', padding: 15, margin: '10px 0' }}>
          <Grid item container alignItems="flex-end">
            <Sports
              id={dirSportingEvent?.id}
              dChanges={dChanges}
              classes={classes}
              onSportsBlockChanged={onSportsBlockChanged}
              SportUpdated={() => {
                handleSnackBar('success', 'вид спорта и группа дисциплин успешно обновлена');
              }}
              onError={(error: any) => {
                handleSnackBar('error', getMessage(error.message));
              }}
              readOnly={readonly}
            />
          </Grid>
        </Paper>

        <Paper elevation={3} style={{ width: '100%', padding: 15, margin: '10px 0' }}>
          <Grid container alignItems="center">
            <Grid item xs>
              <AgeGroupsWidget
                id={dirSportingEvent?.id}
                readonly={readonly}
                sports={sports}
                onAgeGroupChanged={onAgeGroupChanged}
              />
            </Grid>
            {dChanges?.includes('ageGroups') && (
              <Grid item>
                <EditLocationIcon color="secondary" />
              </Grid>
            )}
          </Grid>
        </Paper>
        <Paper elevation={3} style={{ width: '100%', padding: 15, margin: '10px 0' }}>
          <Grid item container alignItems="flex-end">
            <Grid item xs>
              <MaskedInput
                label="количество спортсменов, тренеров, специалистов и судей"
                control={control}
                error={!!errors['athletesTrainersRefereesSpecialistsCount']}
                name="athletesTrainersRefereesSpecialistsCount"
                disabled={readonly}
                isNotTextMask
                mask="onlyNumber"
              />
            </Grid>
            {dChanges?.includes('athletesTrainersRefereesSpecialistsCount') && (
              <Grid item>
                <EditLocationIcon color="secondary" />
              </Grid>
            )}
          </Grid>
        </Paper>

        <Paper elevation={3} style={{ width: '100%', padding: 15, margin: '10px 0' }}>
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12}>
              <Typography>Период проведения :</Typography>
            </Grid>
            <Grid item container lg={3} md={3} xs={12} alignItems="flex-end">
              <Grid item xs>
                <DatePicker
                  label="с"
                  control={control}
                  error={!!errors['startDate']}
                  name="startDate"
                  rules={{ required: true }}
                  maxDate={endDate || undefined}
                  disabled={readonly}
                />
              </Grid>
              {dChanges?.includes('startDate') && (
                <Grid item>
                  <EditLocationIcon color="secondary" />
                </Grid>
              )}
            </Grid>
            <Grid item container lg={3} md={3} xs={12} spacing={2} alignItems="flex-end">
              <Grid item xs>
                <DatePicker
                  label="по"
                  control={control}
                  error={!!errors['endDate']}
                  name="endDate"
                  rules={{ required: true }}
                  minDate={startDate || undefined}
                  disabled={readonly}
                />
              </Grid>
              {dChanges?.includes('endDate') && (
                <Grid item>
                  <EditLocationIcon color="secondary" />
                </Grid>
              )}
            </Grid>
            <Grid item container lg={3} md={3} xs={12} alignItems={'flex-end'}>
              {isDateError ? (
                <Typography style={{ color: 'red' }}>
                  <strong>Дата "По" не может быть меньше даты "С"</strong>
                </Typography>
              ) : (
                <Typography>
                  <strong>Количество дней: </strong>
                  {` ${
                    (startDate &&
                      endDate &&
                      moment(isRussia === 'true' ? moment(endDate).add(1, 'day') : endDate).diff(
                        moment(startDate),
                        'days',
                      )) ||
                    '~'
                  }`}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Paper>
        <Paper elevation={3} style={{ width: '100%', padding: 15, margin: '10px 0' }}>
          <Grid container style={{ marginBottom: 20 }} xs={12}>
            <Grid item xs>
              <RegionsBox id={dirSportingEvent?.id} onRegionsBoxChange={onRegionsBoxChange} readonly={readonly} />
            </Grid>
            {dChanges?.includes('dirRegions') && (
              <Grid item>
                <EditLocationIcon color="secondary" />
              </Grid>
            )}
          </Grid>
          <Grid container alignItems="flex-end">
            <Grid item xs>
              <Input
                label="Место проведения"
                control={control}
                error={!!errors['venue']}
                name="venue"
                defaultValue="по назначению"
                disabled={readonly}
              />
            </Grid>
            {dChanges?.includes('venue') && (
              <Grid item>
                <EditLocationIcon color="secondary" />
              </Grid>
            )}
          </Grid>
        </Paper>

        <Paper elevation={3} style={{ width: '100%', padding: 15, margin: '10px 0' }}>
          <Grid item container alignItems="flex-end" justify={'space-between'} className={classes.verticalSpace}>
            <Grid item xs>
              <Organizations
                label="Организатор"
                control={control}
                error={!!errors['organizers']}
                name="organizers"
                disabled={readonly}
              />
            </Grid>

            {dChanges?.includes('organizers') && (
              <Grid item>
                <EditLocationIcon color="secondary" />
              </Grid>
            )}
          </Grid>
          <Grid item container>
            <Button
              size="small"
              variant="outlined"
              onClick={handleGenerateOrg}
              color="primary"
              startIcon={<KeyboardIcon />}
              disabled={readonly}
            >
              автопоиск Всероссийской Федерации
            </Button>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  </>
);

export default InfoForm;
