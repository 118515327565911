import { IconButton, LinearProgress, Link, makeStyles, Typography } from '@material-ui/core';

import AddBoxIcon from '@material-ui/icons/AddBox';
import React, { FC, useState } from 'react';

import {
  EventDistrictFragment,
  CreateSportingEventMutationHookResult,
  useCalendarEventDistrictsQuery,
  useCreateSportingEventMutation,
} from '../../../../api';
import { useSnackbar } from 'notistack';
import AddNewEventFom, { TForm } from '../AddNewEventFom/AddNewEventFom';
import getMessage from '../../../../messages';

const useStyles = makeStyles({
  head: {
    fontWeight: 600,
    fontSize: 15,
    letterSpacing: 1.2,
  },
  listItem: {
    fontSize: 15,
    letterSpacing: 1,
  },
});

const Content: FC<{ id?: string; sportId: string; readonly?: boolean }> = ({ id, sportId, readonly }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const [createNewEvent]: CreateSportingEventMutationHookResult = useCreateSportingEventMutation();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const { data, loading, refetch } = useCalendarEventDistrictsQuery({
    variables: {
      id,
      stageRegistryNumber: 100002922,
      sportId,
    },
    fetchPolicy: 'no-cache',
    skip: !id,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const getFederalName = (event: EventDistrictFragment) =>
    event.dirFederalDistricts?.map((district) => district.fullName).join(', ');

  const handleSubmitEventForm = async (value: TForm) => {
    const { name, venue, startDate, endDate, membersQuota, ekp, dirFederalDistricts } = value;

    try {
      await createNewEvent({
        variables: {
          data: {
            name,
            venue,
            startDate,
            endDate,
            membersQuota: parseInt(membersQuota, 10),
            ekp,
            dirCalendar: {
              connect: {
                id,
              },
            },
            clsEventStages: {
              connect: [
                {
                  registryNumber: 100002922,
                },
              ],
            },
            sports: {
              create: [
                {
                  dirSport: {
                    connect: {
                      id: sportId,
                    },
                  },
                },
              ],
            },
            statuses: {
              create: [
                {
                  value: { connect: { id: 1 } },
                },
              ],
            },
            status: {
              connect: {
                id: 1,
              },
            },
            dirFederalDistricts: {
              connect: dirFederalDistricts?.map((district) => ({ id: district.value })),
            },
          },
        },
      });
      handleSnackBar('success', 'Новое мероприятие успешно создано');
      refetch();
      setOpen(false);
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  if (loading) return <LinearProgress color="primary" />;

  return (
    <div style={{ width: '100%' }}>
      {open && (
        <AddNewEventFom
          ekp={data?.dirCalendar?.ekpMinsportNumber}
          etape={2}
          id={id}
          handleClose={handleClose}
          onSubmit={handleSubmitEventForm}
        />
      )}
      {!readonly && (
        <IconButton color="primary" onClick={() => setOpen(true)}>
          <AddBoxIcon />
        </IconButton>
      )}
      <div style={{ paddingLeft: 15, marginTop: 10 }}>
        {data?.dirSportingEvents?.map((event) => (
          <Link
            key={event.id}
            style={{ fontWeight: 600, cursor: 'pointer' }}
            target="_blank"
            href={`/sporting_event/${event.id}`}
          >
            <Typography className={classes.head} color="primary">
              {getFederalName(event)}
            </Typography>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default Content;
