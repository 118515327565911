import { Button, Grid } from '@material-ui/core';
import { DatePickerRange, Input } from '../Inputs';
import React, { FC, useEffect, useState } from 'react';

import ClearIcon from '@material-ui/icons/Clear';
import ContentWrapper from '../layouts/ContentWrapper/ContentWrapper';
import { Count } from '../Count';
import MergeTaskStatus from '../../module/MergeTaskStatus';
import SearchIcon from '@material-ui/icons/Search';
import TuneIcon from '@material-ui/icons/Tune';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { useMergeTasksCountLazyQuery } from '../../api';

const useStyle = makeStyles((theme) => ({
  btn: {
    marginRight: theme.spacing(2),
  },
}));

const UnificationTasks: FC<{
  onSubmit?: (T: any, E: any) => void;
  value?: any;
  defaultFilter?: any;
  showDownloadButton?: boolean;
}> = ({ onSubmit = () => {}, value = {}, defaultFilter = {}, showDownloadButton }) => {
  const style = useStyle();
  const { handleSubmit, control, errors, reset, formState, getValues, setValue } = useForm({
    defaultValues: value,
  });
  const [additional, setAdditional] = useState<boolean>(Object.keys(value)?.length > 0);
  const [filter, setFilter] = useState<any>({ ...defaultFilter });

  const handleSearchBox = (value: any) => {
    const OR = [];
    let taskFilter: any = {};
    let primary: any = {};
    let duplicate: any = {};

    for (let item in value) {
      if (item === 'createdAt_gte' && value[item]) {
        taskFilter[item] = value[item];
      }
      if (item === 'status' && value[item]) {
        taskFilter[item] = {
          id: value?.status?.value,
        };
      }
      if (item === 'createdAt_lt' && value[item]) {
        taskFilter[item] = value[item];
      }

      if (item === 'registryNumber' && value[item]) {
        primary[item] = Number(value[item]);
        duplicate[item] = Number(value[item]);
      }
      if ((item === 'lastname' || item === 'firstname' || item === 'patronymic') && value[item]) {
        primary[item + '_contains'] = value[item];
        duplicate[item + '_contains'] = value[item];
      }
    }
    if (Object.keys(primary).length > 0) OR.push({ primary });
    if (Object.keys(duplicate).length > 0) OR.push({ duplicate });

    setFilter({ ...taskFilter, ...(OR.length > 0 && { OR }) });
    onSubmit({ ...taskFilter, ...(OR.length > 0 && { OR }) }, getValues());
  };

  return (
    <ContentWrapper>
      <form
        onSubmit={handleSubmit(handleSearchBox)}
        onReset={(e?) => {
          setValue('registryNumber', '');
          reset();
          setFilter({});
          onSubmit({}, {});
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <Input label="Фамилия" control={control} error={!!errors['lastname']} name="lastname" />
          </Grid>
          <Grid item xs={3}>
            <Input label="Имя" control={control} error={!!errors['firstname']} name="firstname" />
          </Grid>
          <Grid item xs={3}>
            <Input label="Отчество" control={control} error={!!errors['patronymic']} name="patronymic" />
          </Grid>
          <Grid item xs={3}>
            <Input
              label="Реестровый номер"
              control={control}
              error={!!errors['registryNumber']}
              name="registryNumber"
            />
          </Grid>
          {additional && (
            <>
              <Grid item xs={7}>
                <DatePickerRange
                  fullWidth
                  start={{
                    label: 'дата-время создания задания от',
                    control,
                    error: !!errors['createdAt_gte'],
                    name: 'createdAt_gte',
                  }}
                  end={{ label: 'до', control, error: !!errors['createdAt_lt'], name: 'createdAt_lt' }}
                />
              </Grid>
              <Grid item xs={3}>
                <MergeTaskStatus label="Статусы задач" control={control} error={!!errors['status']} name="status" />
              </Grid>
            </>
          )}

          <Grid item xs={12}>
            <Button
              variant="outlined"
              className={style.btn}
              color="primary"
              type="reset"
              onClick={() =>
                reset({
                  registryNumber: '',
                  lastname: '',
                  firstname: '',
                  patronymic: '',
                  birthday_gte: '',
                  birthday_lt: '',
                })
              }
              size="small"
              startIcon={<ClearIcon />}
            >
              Сброс
            </Button>
            <Button
              variant="outlined"
              color="primary"
              type="submit"
              size="small"
              className={style.btn}
              startIcon={<SearchIcon />}
              disabled={!formState.isDirty}
            >
              Поиск
            </Button>
            <Button
              onClick={() => setAdditional(!additional)}
              type="button"
              className={style.btn}
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<TuneIcon />}
            >
              Фильтр
            </Button>
            <Count key={JSON.stringify(filter)} query={useMergeTasksCountLazyQuery} filter={filter} />
          </Grid>
        </Grid>
      </form>
    </ContentWrapper>
  );
};

export default UnificationTasks;
