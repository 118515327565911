import { Button, Grid } from '@material-ui/core';
import React, { FC, useEffect } from 'react';

import { DatePicker } from '../../../Inputs';
import RefereesCategories from '../../../../module/RefereesCategories';
import SaveIcon from '@material-ui/icons/Save';
import { getValidUntilDate } from './helpers';
import { useForm } from 'react-hook-form';

interface formState {
  assignmentDate: null | Date;
  confirmationDate: null | Date;
  validUntil: null | Date;
  id: null | string;
  clsRefereeCategory: null | { value: string; label: string };
}

const CategoryForm: FC<{
  action: string;
  defaultValue: any;
  onSubmit: any;
  onCancel: Function;
}> = ({ action, defaultValue, onSubmit, onCancel }) => {
  const defaultState: formState = defaultValue || {
    assignmentDate: null,
    validUntil: null,
    clsRefereeCategory: null,
    confirmationDate: null,
    id: null,
  };

  const onHandleCancel = () => {
    reset();

    onCancel();
  };

  const { handleSubmit, control, errors, reset, setValue, watch, register, formState } = useForm({
    defaultValues: defaultState,
  });

  let watchCategory = watch('clsRefereeCategory', undefined);
  let watchAssignmentDate = watch('assignmentDate', undefined);
  let watchConfirmationDate = watch('confirmationDate', undefined);

  useEffect(() => {
    if (watchCategory && watchConfirmationDate)
      setValue('validUntil', getValidUntilDate(watchCategory, watchConfirmationDate));
    else if (watchAssignmentDate && watchCategory)
      setValue('validUntil', getValidUntilDate(watchCategory, watchAssignmentDate));
  }, [watchCategory, watchAssignmentDate, watchConfirmationDate, setValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <Grid md={12} container spacing={2} style={{ margin: 0 }} direction="row">
        <input type="hidden" name="id" ref={register()} />
        <Grid item container spacing={1}>
          <Grid item md={4}>
            <RefereesCategories
              label="Категория"
              control={control}
              error={!!errors['clsRefereeCategory']}
              name="clsRefereeCategory"
              rules={{ required: true }}
            />
          </Grid>
          <Grid item md={3}>
            <DatePicker
              label="Дата присвоения"
              control={control}
              error={!!errors['assignmentDate']}
              name="assignmentDate"
              rules={{ required: true }}
            />
          </Grid>

          <Grid item md={3}>
            <DatePicker
              label="Действует до"
              control={control}
              error={!!errors['validUntil']}
              name="validUntil"
              disabled
            />
          </Grid>

          {action === 'edit' && (
            <Grid md={3} item>
              <DatePicker
                label="Дата подтверждения"
                control={control}
                error={!!errors['confirmationDate']}
                name="confirmationDate"
              />
            </Grid>
          )}
        </Grid>
        <Grid item md={12} container style={{ margin: 0 }} spacing={1}>
          <Grid item>
            <Button variant="outlined" color="secondary" type="button" onClick={onHandleCancel} size="small">
              Отмена
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              type="submit"
              size="small"
              startIcon={<SaveIcon color="primary" />}
              disabled={!formState.isDirty}
            >
              Сохранить
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default CategoryForm;
