import _ from 'lodash';

export const downloadB64File = (binary: string) => {
  let a = document.createElement('a');
  a.href = `data:application/xlsx;base64,${binary}`;
  a.download = 'trainersReport.xlsx';
  a.click();
};

export const getOrganizations = (sportsArray: Array<any>) =>
  _.uniq(
    _.flattenDeep(sportsArray.map((v: any) => v.organizations))
      .filter((o: any) => !o.dismissalDate)
      .map((o: any) => o.dirOrganization.name),
  ).join(', ');
