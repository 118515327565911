import React, { FC, Fragment, useState } from 'react';
import {
  Grid,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
  useOpenMergeTaskMutation,
  useMergeTasksQuery,
  DeleteMergeTaskMutationHookResult,
  useDeleteMergeTaskMutation,
  OpenMergeTaskMutationHookResult,
  CloseMergeTaskMutationHookResult,
  useCloseMergeTaskMutation,
} from '../../../api';
import { useSnackbar } from 'notistack';
import getMessage from '../../../messages';
import CustomRow from './CustomTableRow';
import moment from 'moment';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 15,
  },
}))(TableCell);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const Board: FC<{ filter?: any; readonly?: boolean }> = ({ filter, readonly }) => {
  const classes = useStyles();

  const [action, setAction] = useState('');
  const [item, setItem] = useState('');

  const { data, loading, refetch } = useMergeTasksQuery({
    variables: { filter },
    fetchPolicy: 'no-cache',
  });

  const { enqueueSnackbar } = useSnackbar();

  const [openTask]: OpenMergeTaskMutationHookResult = useOpenMergeTaskMutation();
  const [merge]: CloseMergeTaskMutationHookResult = useCloseMergeTaskMutation();
  const [deleteTask]: DeleteMergeTaskMutationHookResult = useDeleteMergeTaskMutation();

  const handleMergeTask = async (taskId: string) => {
    try {
      await merge({
        variables: {
          taskId,
        },
      });
      enqueueSnackbar(`Задание успешно завершено`, { variant: 'success' });
      await deleteTask({
        variables: {
          taskId,
        },
      });
      setItem('');
      setAction('');
      refetch();
    } catch (e) {
      enqueueSnackbar(
        e.message === 'Invalid task'
          ? 'Объединение выполнено. Возможен обмен местами Оригинала и Дубликата с последующим Объединением.'
          : getMessage(e.message),
        { variant: 'error' },
      );
    }
  };

  const handleSwitchTask = async (taskId: string, primaryId: string, duplicateId: string) => {
    try {
      await deleteTask({
        variables: {
          taskId,
        },
      });
      await openTask({
        variables: {
          primaryId,
          duplicateId,
        },
      });
      enqueueSnackbar(`Обмен проведен успешно`, { variant: 'success' });
      refetch();
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  const handleDeleteTask = async (taskId: string) => {
    try {
      await deleteTask({
        variables: {
          taskId,
        },
      });
      enqueueSnackbar(`Задание успешно удалено`, { variant: 'success' });
      setItem('');
      setAction('');
      refetch();
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  if (loading)
    return (
      <Grid container justify={'center'} alignItems={'center'} style={{ width: '100%', height: '70vh' }}>
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );

  return (
    <Grid container>
      <TableContainer component={Paper} style={{ maxHeight: '80vh', margin: 10 }}>
        <Table className={classes.table} stickyHeader aria-label="similar-table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Дата добавление задачи</StyledTableCell>
              <StyledTableCell>{``}</StyledTableCell>
              <StyledTableCell>Реестровый номер</StyledTableCell>
              <StyledTableCell align={'center'}>ФИО</StyledTableCell>
              <StyledTableCell>ДР</StyledTableCell>
              <StyledTableCell>Пол</StyledTableCell>
              <StyledTableCell>Регион</StyledTableCell>
              <StyledTableCell>Дата создания</StyledTableCell>
              <StyledTableCell>Дата последнего обновления</StyledTableCell>
              <StyledTableCell align={'center'}>Роли</StyledTableCell>
              {!readonly && <StyledTableCell align={'center'}>Действия</StyledTableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.dirPersonMergeTasks.length === 0 && (
              <TableRow style={{ fontWeight: 600, fontSize: 15, textAlign: 'center', width: '100%' }}>
                Нет данных
              </TableRow>
            )}
            {data?.dirPersonMergeTasks?.map((element: any) => (
              <Fragment key={element.id}>
                <TableRow>
                  <StyledTableCell rowSpan={2}>
                    <Typography style={{ fontWeight: 600, fontSize: 15 }}>
                      {(element?.createdAt && moment(element?.createdAt).format('DD.MM.YYYY HH:mm')) || ''}
                    </Typography>
                    <Typography style={{ fontWeight: 600, fontSize: 15 }}>{element?.status?.description}</Typography>
                  </StyledTableCell>
                  <CustomRow title={'Выживает :'} person={element?.primary} />
                  {!readonly && (
                    <StyledTableCell rowSpan={2}>
                      <Grid container spacing={1}>
                        <Grid item>
                          {' '}
                          <Button
                            variant={'outlined'}
                            color={'primary'}
                            size={'small'}
                            onClick={() => {
                              setAction('merge');
                              setItem(element?.id);
                            }}
                          >
                            Объединить
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            variant={'outlined'}
                            color={'primary'}
                            size={'small'}
                            onClick={() => handleSwitchTask(element?.id, element?.duplicate?.id, element?.primary?.id)}
                          >
                            Поменять местами
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            variant={'outlined'}
                            color={'primary'}
                            size={'small'}
                            onClick={() => {
                              setAction('delete');
                              setItem(element?.id);
                            }}
                          >
                            Удалить
                          </Button>
                        </Grid>
                      </Grid>
                    </StyledTableCell>
                  )}
                </TableRow>
                <TableRow>
                  <CustomRow title={'Дубликат :'} person={element?.duplicate} />
                </TableRow>
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={action !== ''}
        onClose={() => {
          setAction('');
          setItem('');
        }}
      >
        <DialogTitle>Вы уверены, что хотите {action === 'merge' ? 'объединить' : 'удалить'} объект ?</DialogTitle>
        <DialogActions>
          <Grid container justify="space-between">
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setAction('');
                  setItem('');
                }}
              >
                Отменить
              </Button>
            </Grid>
            <Grid item>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => (action === 'merge' ? handleMergeTask(item) : handleDeleteTask(item))}
              >
                {action === 'merge' ? 'Объединить' : 'Удалить '}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default Board;
