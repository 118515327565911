import { usePermissionMenu } from '../../../../hooks/usePermission';
import React, { FC, Fragment, MouseEvent } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { NavLink, useLocation } from 'react-router-dom';
import { topPoints, bottomPoints, underMenuPoints } from '../__data__/menuPoint';
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@material-ui/core';
import userRights from '../../../../config/userRights';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Instructions from '../../../../components/widgets/SportingEvents/Instructions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 600,
      letterSpacing: 1.2,
    },
    link: {
      cursor: 'pointer',
      textDecoration: 'none',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    activeLink: {
      '& .MuiTypography-root': {
        fontWeight: 'bold',
        color: '#3f51b5',
        letterSpacing: 1,
      },
      '& .MuiSvgIcon-root': {
        color: '#3f51b5',
      },
    },
  }),
);

const Menu: FC<{ url: any }> = ({ url }) => {
  const { pathname } = useLocation();
  const classes = useStyles();
  const permission: any = usePermissionMenu();
  const { who } = userRights();
  let hasAccess = false;
  if (who === 'ADMIN' || who === 'FCPSR_ADMINISTRATOR' || who === 'FCPSR_SPECIALIST') {
    hasAccess = !!who;
  }

  const accordionExpanded = bottomPoints.find((p) => p.link === pathname) !== undefined;

  return (
    <Fragment>
      <List>
        {topPoints.map((item) => {
          if (
            permission &&
            !permission[item.id] &&
            !(permission.directory && permission.directory.all) &&
            !(permission.directory && permission.directory[item.id])
          )
            return null;

          return (
            <NavLink
              key={item.title}
              className={`${classes.link} ${
                (pathname.startsWith(item.link?.slice(0, -1)) && classes.activeLink) || ''
              }`}
              activeClassName={classes.activeLink}
              to={item.link}
              aria-label={item.title}
            >
              <ListItem button key={item.id}>
                <ListItemIcon>
                  <img src={item.srcGray} height="30" />
                </ListItemIcon>
                <ListItemText primary={item.title} />
              </ListItem>
            </NavLink>
          );
        })}
      </List>
      {bottomPoints.filter(
        (item) =>
          !(
            permission &&
            !permission[item.id] &&
            !(permission.directory && permission.directory.all) &&
            !(permission.directory && permission.directory[item.id])
          ),
      ).length > 0 && (
        <Accordion style={{ borderRadius: 0, margin: 0 }} defaultExpanded={accordionExpanded}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="menu-content" id="menu-header">
            <Typography className={classes.title}>Справочники</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0 }}>
            <List>
              {bottomPoints.map((item) => {
                if (
                  permission &&
                  !permission[item.id] &&
                  !(permission.directory && permission.directory.all) &&
                  !(permission.directory && permission.directory[item.id])
                )
                  return null;
                return (
                  <NavLink
                    key={item.title}
                    className={`${classes.link} ${
                      (pathname.startsWith(item.link?.slice(0, -1)) && classes.activeLink) || ''
                    }`}
                    activeClassName={classes.activeLink}
                    to={item.link}
                    aria-label={item.title}
                  >
                    <ListItem button key={item.id}>
                      <ListItemIcon>
                        <img src={item.srcGray} height="30" />
                      </ListItemIcon>
                      <ListItemText primary={item.title} />
                    </ListItem>
                  </NavLink>
                );
              })}
            </List>
          </AccordionDetails>
        </Accordion>
      )}
      <List>
        {underMenuPoints.map((item) => {
          if (
            permission &&
            !permission[item.id] &&
            !(permission.directory && permission.directory.all) &&
            !(permission.directory && permission.directory[item.id])
          )
            return null;

          return (
            <NavLink
              key={item.title}
              className={`${classes.link} ${
                (pathname.startsWith(item.link?.slice(0, -1)) && classes.activeLink) || ''
              }`}
              activeClassName={classes.activeLink}
              to={item.link}
              aria-label={item.title}
            >
              <ListItem button key={item.id}>
                <ListItemIcon>
                  <img src={item.srcGray} height="30" />
                </ListItemIcon>
                <ListItemText primary={item.title} />
              </ListItem>
            </NavLink>
          );
        })}
      </List>
      {hasAccess && (
        <Accordion style={{ borderRadius: 0, margin: 0 }} defaultExpanded={accordionExpanded}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="menu-content" id="menu-header">
            <Typography className={classes.title}>Инструкции</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ padding: 0 }}>
            <Instructions />
          </AccordionDetails>
        </Accordion>
      )}
    </Fragment>
  );
};

export default Menu;
