import { Button, Grid } from '@material-ui/core';
import React, { FC, useEffect, useMemo } from 'react';
import {
  Scalars,
  SportingEventDocument,
  UpdateSportingEventMutationHookResult,
  useSportingEventQuery,
  useUpdateSportingEventMutation,
} from '../../../api';

import { Input } from '../../Inputs';
import LinearProgress from '@material-ui/core/LinearProgress';
import RanksAndTitle from '../../../module/RanksAndTitle';
import SaveIcon from '@material-ui/icons/Save';
import _ from 'lodash';
import getMessage from '../../../messages';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

import { autoFillRules, getValue } from './helpers';

const useStyles = makeStyles({
  alignCenter: {
    alignItems: 'center',
  },
  title: {
    fontWeight: 500,
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  tinyIcon: {
    fontSize: 18,
  },
  icon: {
    fontSize: 20,
  },
});

interface formState {
  minManAge?: Scalars['Int'] | null;
  maxManAge?: Scalars['Int'] | null;
  minManBirthYear?: Scalars['Int'] | null;
  maxManBirthYear?: Scalars['Int'] | null;
  minWomanAge?: Scalars['Int'] | null;
  maxWomanAge?: Scalars['Int'] | null;
  minWomanBirthYear?: Scalars['Int'] | null;
  maxWomanBirthYear?: Scalars['Int'] | null;
  athletesQuotaNotCorrespondingByAge?: Scalars['Int'] | null;
  athletesQuotaNotCorrespondingByQualification?: Scalars['Int'] | null;
  sportQualificationMaleNotLower?: { label: string; value: Scalars['UUID']; order: number } | null;
  sportQualificationFemaleNotLower?: { label: string; value: Scalars['UUID']; order: number } | null;
  clsMinRang?: { label: string; value: Scalars['UUID']; order: number } | null;
  clsSportingQualificationNotGreater?: { label: string; value: Scalars['UUID']; order: number } | null;
}

const Rules: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const classes = useStyles();

  const { loading, data } = useSportingEventQuery({ returnPartialData: true, variables: { id } });
  const [update]: UpdateSportingEventMutationHookResult = useUpdateSportingEventMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: SportingEventDocument,
        data: {
          ...data?.updateDirSportingEvent,
        },
      });
    },
  });

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const { handleSubmit, control, errors, reset, formState, watch, setValue } = useForm({
    defaultValues: {
      ...data?.dirSportingEvent,
    } as formState,
    mode: 'onSubmit',
  });

  const watchsportQualificationMaleNotLower = watch('sportQualificationMaleNotLower');
  const watchsportQualificationFemaleNotLower = watch('sportQualificationFemaleNotLower');
  const watchclsMinRang = watch('clsMinRang');
  const watchclsSportingQualificationNotGreater = watch('clsSportingQualificationNotGreater');
  const watchMinManAge = watch('minManAge');
  const watchMaxManAge = watch('maxManAge');
  const watchMinManBirthYear = watch('minManBirthYear');
  const watchMaxManBirthYear = watch('maxManBirthYear');
  const watchMinWomanAge = watch('minWomanAge');
  const watchMaxWomanAge = watch('maxWomanAge');
  const watchMinWomanBirthYear = watch('minWomanBirthYear');
  const watchMaxWomanBirthYear = watch('maxWomanBirthYear');

  const canAutoFillRules = useMemo(
    () => (!loading && data && data?.dirSportingEvent ? autoFillRules(data?.dirSportingEvent).bool : false),
    [data, loading],
  );

  const handleAutoFillFromEvsk = () => {
    if (data?.dirSportingEvent) {
      const { maleAge, femaleAge, minWomanRang, minManRang, bool } = autoFillRules(data?.dirSportingEvent);

      if (bool) {
        const autoFillObject = {
          minManAge: maleAge?.min,
          maxManAge: maleAge?.max,

          minWomanAge: femaleAge?.min,
          maxWomanAge: femaleAge?.max,

          ...getValue(minManRang, { sportQualificationMaleNotLower: minManRang }),
          ...getValue(minWomanRang, { sportQualificationFemaleNotLower: minWomanRang }),
        };

        for (const key in autoFillObject) {
          setValue(key, _.get(autoFillObject, key), {
            shouldDirty: true,
          });
        }
      }
    }
  };

  const onSubmit = async (values: formState) => {
    const {
      minManAge,
      maxManAge,
      minManBirthYear,
      maxManBirthYear,
      minWomanAge,
      maxWomanAge,
      minWomanBirthYear,
      maxWomanBirthYear,
      sportQualificationMaleNotLower,
      sportQualificationFemaleNotLower,
      athletesQuotaNotCorrespondingByAge,
      clsSportingQualificationNotGreater,
      athletesQuotaNotCorrespondingByQualification,
      clsMinRang,
    } = values;

    let sportQualificationMaleNotLowerValue = null;
    if (sportQualificationMaleNotLower?.value) {
      sportQualificationMaleNotLowerValue = {
        connect: {
          id: sportQualificationMaleNotLower?.value,
        },
      };
    } else {
      if (data?.dirSportingEvent?.sportQualificationMaleNotLower) {
        sportQualificationMaleNotLowerValue = {
          disconnect: true,
        };
      }
    }

    let sportQualificationFemaleNotLowerValue = null;
    if (sportQualificationFemaleNotLower?.value) {
      sportQualificationFemaleNotLowerValue = {
        connect: {
          id: sportQualificationFemaleNotLower?.value,
        },
      };
    } else {
      if (data?.dirSportingEvent?.sportQualificationFemaleNotLower) {
        sportQualificationFemaleNotLowerValue = {
          disconnect: true,
        };
      }
    }

    let clsMinRangValue = null;
    if (clsMinRang?.value) {
      clsMinRangValue = {
        connect: {
          id: clsMinRang?.value,
        },
      };
    } else {
      if (data?.dirSportingEvent?.clsMinRang) {
        clsMinRangValue = {
          disconnect: true,
        };
      }
    }

    let clsSportingQualificationNotGreaterValue = null;
    if (clsSportingQualificationNotGreater?.value) {
      clsSportingQualificationNotGreaterValue = {
        connect: {
          id: clsSportingQualificationNotGreater?.value,
        },
      };
    } else {
      if (data?.dirSportingEvent?.clsSportingQualificationNotGreater) {
        clsSportingQualificationNotGreaterValue = {
          disconnect: true,
        };
      }
    }

    try {
      await update({
        variables: {
          data: {
            ...{ minManAge: Number(minManAge) || null },
            ...{ maxManAge: Number(maxManAge) || null },
            ...{ minManBirthYear: Number(minManBirthYear) || null },
            ...{ maxManBirthYear: Number(maxManBirthYear) || null },
            ...{ minWomanAge: Number(minWomanAge) || null },
            ...{ maxWomanAge: Number(maxWomanAge) || null },
            ...{ minWomanBirthYear: Number(minWomanBirthYear) || null },
            ...{ maxWomanBirthYear: Number(maxWomanBirthYear) || null },
            ...{ athletesQuotaNotCorrespondingByAge: Number(athletesQuotaNotCorrespondingByAge) || null },
            ...{
              athletesQuotaNotCorrespondingByQualification:
                Number(athletesQuotaNotCorrespondingByQualification) || null,
            },
            sportQualificationMaleNotLower: sportQualificationMaleNotLowerValue,
            sportQualificationFemaleNotLower: sportQualificationFemaleNotLowerValue,
            clsMinRang: clsMinRangValue,
            clsSportingQualificationNotGreater: clsSportingQualificationNotGreaterValue,
          },
          id,
        },
      });

      handleSnackBar('success', 'Требование успешно обновлено');
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  useEffect(() => {
    reset({ ...data?.dirSportingEvent });
  }, [data, reset]);

  if (loading) return <LinearProgress />;

  // @ts-ignore
  // @ts-ignore
  return (
    <div style={{ paddingTop: '2rem' }}>
      <Grid container style={{ marginBottom: 15 }}>
        <Button disabled={!canAutoFillRules} variant="outlined" color="primary" onClick={handleAutoFillFromEvsk}>
          Заполнить автоматически по ЕВСК
        </Button>
      </Grid>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Grid container spacing={2} className={classes.alignCenter}>
          <Grid item lg={6} md={6} xs={12}>
            <Input
              label="Возраст спортсменов муж. от"
              control={control}
              type={'number'}
              error={!!errors['minManAge']}
              name="minManAge"
              rules={{
                validate: (val) => {
                  return val
                    ? val <= (watchMaxManAge || 9999999) ||
                        'Возраст спортсменов муж. от не может быть больше Возраст спортсменов муж. до'
                    : true;
                },
              }}
              disabled={readonly}
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Input
              label="Возраст спортсменов муж. до"
              control={control}
              type={'number'}
              error={!!errors['minManAge'] ? false : !!errors['maxManAge']}
              name="maxManAge"
              rules={{
                validate: (val) =>
                  val
                    ? val >= watchMinManAge! ||
                      'Возраст спортсменов муж. до не может быть меньше Возраст спортсменов муж. от'
                    : true,
              }}
              disabled={readonly}
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Input
              label="Год рождения муж. от"
              control={control}
              type={'number'}
              error={!!errors['minManBirthYear']}
              rules={{
                validate: (val) =>
                  val
                    ? val <= (watchMaxManBirthYear || 9999999) ||
                      'Год рождения муж. от не может быть больше Год рождения муж. до'
                    : true,
              }}
              name="minManBirthYear"
              disabled={readonly}
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Input
              label="Год рождения муж. до"
              type={'number'}
              control={control}
              error={!!errors['minManBirthYear'] ? false : !!errors['maxManBirthYear']}
              rules={{
                validate: (val) =>
                  val
                    ? val >= watchMinManBirthYear! || 'Год рождения муж. до не может быть меньше Год рождения муж. от'
                    : true,
              }}
              name="maxManBirthYear"
              disabled={readonly}
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Input
              label="Возраст спортсменов жен. от"
              control={control}
              type={'number'}
              error={!!errors['minWomanAge']}
              rules={{
                validate: (val) =>
                  val
                    ? val <= (watchMaxWomanAge || 9999999) ||
                      'Возраст спортсменов жен. от не может быть больше Возраст спортсменов жен. до'
                    : true,
              }}
              name="minWomanAge"
              disabled={readonly}
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Input
              label="Возраст спортсменов жен. до"
              control={control}
              type={'number'}
              error={!!errors['minWomanAge'] ? false : !!errors['maxWomanAge']}
              rules={{
                validate: (val) =>
                  val
                    ? val >= watchMinWomanAge! ||
                      'Возраст спортсменов жен. до не может быть меньше Возраст спортсменов жен. от'
                    : true,
              }}
              name="maxWomanAge"
              disabled={readonly}
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Input
              label="Год рождения жен. от"
              control={control}
              type={'number'}
              error={!!errors['minWomanBirthYear']}
              rules={{
                validate: (val) =>
                  val
                    ? val <= (watchMaxWomanBirthYear || 9999999) ||
                      'Год рождения жен. от не может быть больше Год рождения жен. до'
                    : true,
              }}
              name="minWomanBirthYear"
              disabled={readonly}
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Input
              label="Год рождения жен. до"
              control={control}
              type={'number'}
              error={!!errors['minWomanBirthYear'] ? false : !!errors['maxWomanBirthYear']}
              rules={{
                validate: (val) =>
                  val
                    ? val >= watchMinWomanBirthYear! || 'Год рождения жен. до не может быть меньше Год рождения жен. от'
                    : true,
              }}
              name="maxWomanBirthYear"
              disabled={readonly}
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <RanksAndTitle
              label="Спорт. квалификация муж. не ниже"
              control={control}
              error={!!errors['sportQualificationMaleNotLower']}
              rules={{
                validate: (val) => {
                  return val && watchclsMinRang
                    ? val.order <= watchclsMinRang?.order ||
                        '"Спорт. квалификация муж. не ниже" не должно быть больше чем в "Спорт. квалификация муж. не выше"'
                    : true;
                },
              }}
              name="sportQualificationMaleNotLower"
              disabled={readonly}
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <RanksAndTitle
              label="Спорт. квалификация жен. не ниже"
              control={control}
              error={!!errors['sportQualificationFemaleNotLower']}
              rules={{
                validate: (val) => {
                  return val && watchclsSportingQualificationNotGreater
                    ? val.order <= watchclsSportingQualificationNotGreater?.order ||
                        '"Спорт. квалификация жен. не ниже" не должно быть больше чем в "Спорт. квалификация жен. не выше"'
                    : true;
                },
              }}
              name="sportQualificationFemaleNotLower"
              disabled={readonly}
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <RanksAndTitle
              label="Спорт. квалификация муж. не выше"
              control={control}
              error={!!errors['clsMinRang']}
              name="clsMinRang"
              disabled={readonly}
              rules={{
                validate: (val) => {
                  return val && watchsportQualificationMaleNotLower
                    ? val.order >= watchsportQualificationMaleNotLower?.order ||
                        '"Спорт. квалификация муж. не выше" не должно быть меньше чем в "Спорт. квалификация муж. не ниже"'
                    : true;
                },
              }}
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <RanksAndTitle
              label="Спорт. квалификация жен. не выше"
              rules={{
                validate: (val) => {
                  return val && watchsportQualificationFemaleNotLower
                    ? val.order >= watchsportQualificationFemaleNotLower?.order ||
                        '"Спорт. квалификация жен. не выше" не должно быть меньше чем в "Спорт. квалификация жен. не ниже"'
                    : true;
                },
              }}
              control={control}
              error={!!errors['clsSportingQualificationNotGreater']}
              name="clsSportingQualificationNotGreater"
              disabled={readonly}
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Input
              label="Квота на спортсменов, не соответствующих возрасту участников"
              control={control}
              type={'number'}
              error={!!errors['athletesQuotaNotCorrespondingByAge']}
              name="athletesQuotaNotCorrespondingByAge"
              disabled={readonly}
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Input
              label="Квота на спортсменов не соответствующих спортквалификации"
              control={control}
              type={'number'}
              error={!!errors['athletesQuotaNotCorrespondingByQualification']}
              name="athletesQuotaNotCorrespondingByQualification"
              disabled={readonly}
            />
          </Grid>

          <Grid item xs={12}>
            {errors &&
              Object.keys(errors).map((error) => {
                if (
                  ['maxManAge', 'maxManBirthYear', 'maxWomanAge', 'maxWomanBirthYear'].includes(error) &&
                  errors[error.replace('max', 'min') as never]
                )
                  return null;
                if (['sportQualificationFemaleNotLower'].includes(error) && errors['sportQualificationMaleNotLower'])
                  return null;
                return <div style={{ color: 'red' }}>{errors[error as keyof formState]?.message}</div>;
              })}
          </Grid>
          <Grid item container lg={6} md={6} xs={12} justify="flex-end">
            <Button
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<SaveIcon />}
              type="submit"
              disabled={!formState.isDirty}
            >
              Сохранить
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default Rules;
