import React, { useState, useCallback, FC } from 'react';
import {
  Grid,
  Tooltip,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  TextField,
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  MenuItem,
  InputAdornment,
  DialogTitle,
  Divider,
  DialogActions,
  Button,
  Dialog,
} from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import AddIcon from '@material-ui/icons/AddBox';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import * as Validation from '../../../utils/validation';
import {
  AddPersonPhoneMutationHookResult,
  RemovePersonPhoneMutationHookResult,
  UpdatePersonPhoneMutationHookResult,
  useAddPersonPhoneMutation,
  useClsTypeContactQuery,
  useRemovePersonPhoneMutation,
  useUpdatePersonPhoneMutation,
  Scalars,
  usePersonByIdQuery,
  usePhonesLookUpQuery,
} from '../../../api';
import ConfirmDialog from '../../ConfirmDialog';
import _ from 'lodash';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  link: {
    cursor: 'pointer',
  },
  icon: {
    fontSize: 15,
  },
});

const ContactInfo: FC<{ readonly?: boolean; id: Scalars['UUID'] }> = ({ id, readonly }) => {
  const classes = useStyles();
  const defaultPhoneState: {
    id: Scalars['UUID'];
    number: string;
    type: string;
    operatorCode: string;
    countryCode: string;
    additionalNumber: string;
  } = {
    id: '',
    number: '',
    type: '',
    operatorCode: '',
    countryCode: '7',
    additionalNumber: '',
  };
  const [isAddingNewPhone, setIsAddingNewPhone] = useState(false);
  const [isEditingPhone, setIsEditingPhone] = useState('');
  const [phone, setPhone] = useState(defaultPhoneState);
  const [deleteItem, setDeleteItem] = useState<any>(null);
  const [open, setOpen] = useState(false);

  const handleChangePhone = useCallback(
    (event: any) => setPhone({ ...phone, [event.target.name]: event.target.value }),
    [phone],
  );

  const numerizeIt = useCallback(
    (e) => Validation.onlyNumber(e.currentTarget.value) === undefined && handleChangePhone(e),
    [handleChangePhone],
  );

  const { data: { classifierValues: clsContactsTypes = [] } = {} } = useClsTypeContactQuery();

  let skip = true;

  const { data, refetch, loading } = usePersonByIdQuery({ fetchPolicy: 'no-cache', variables: { id } });
  const { refetch: doublesRefetch } = usePhonesLookUpQuery({
    fetchPolicy: 'no-cache',
    skip,
  });

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (
    variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined,
    message: string,
  ) => {
    enqueueSnackbar(message, { variant });
  };

  const [addPhone]: AddPersonPhoneMutationHookResult = useAddPersonPhoneMutation();
  const [updatePhone]: UpdatePersonPhoneMutationHookResult = useUpdatePersonPhoneMutation();
  const [deletePhones]: RemovePersonPhoneMutationHookResult = useRemovePersonPhoneMutation();

  const lookUpForDoubles = async () => {
    const { countryCode, number, operatorCode } = phone;
    skip = false;
    doublesRefetch({
      countryCode: countryCode,
      operatorCode: operatorCode,
      number: number,
    }).then((response) => {
      if (_.get(response, 'data.dirPersonPhones', []).length > 0) {
        setOpen(true);
      } else {
        addNewPhone();
      }
      skip = true;
    });
  };

  const addNewPhone = () => {
    const { countryCode, number, type, operatorCode, additionalNumber } = phone;
    if (
      phone.id &&
      isEditingPhone === phone.id &&
      countryCode &&
      number &&
      type &&
      Validation.number(operatorCode) === undefined &&
      Validation.number(number) === undefined &&
      Validation.number(countryCode) === undefined
    ) {
      updatePhone({
        variables: {
          id: phone.id,
          countryCode,
          number,
          operatorCode,
          additionalNumber,
          clsContactType: type,
          idPerson: id,
        },
      }).then((resp) => {
        setIsEditingPhone('');
        setPhone(defaultPhoneState);
        handleSnackBar('success', 'Телефон успешно обновлен');
        refetch();
      });
    } else if (
      countryCode &&
      number &&
      type &&
      Validation.number(operatorCode) === undefined &&
      Validation.number(number) === undefined &&
      Validation.number(countryCode) === undefined
    ) {
      addPhone({
        variables: {
          countryCode,
          operatorCode,
          number,
          additionalNumber,
          clsContactType: type,
          idPerson: id,
        },
      }).then((resp) => {
        setIsAddingNewPhone(false);
        setPhone(defaultPhoneState);
        refetch();
        handleSnackBar('success', 'Телефон успешно добавлен');
      });
    }
  };
  const deletePhone = (phoneId: Scalars['UUID']) => {
    deletePhones({
      variables: {
        id: phoneId,
        date: new Date(),
      },
    }).then((resp) => {
      setIsAddingNewPhone(false);
      setPhone(defaultPhoneState);
      refetch();
      handleSnackBar('success', 'Телефон успешно удален');
    });
  };

  if (loading) return <LinearProgress />;

  const PhoneForm = (
    <Grid container spacing={1} alignItems="center" style={{ margin: '1rem 0' }}>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>
          Данный телефон закреплен за другой персоной,
          <br /> уверены, что хотите сохранить?
        </DialogTitle>
        <Divider />
        <DialogActions>
          <Grid container justify="center">
            <Button
              variant="contained"
              color="secondary"
              style={{ margin: '0 5px' }}
              onClick={() => {
                setOpen(false);
              }}
            >
              Отменить
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ margin: '0 5px' }}
              onClick={() => {
                setOpen(false);
                addNewPhone();
              }}
            >
              Сохранить
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
      <Grid item lg={2}>
        <TextField
          id="countryCode"
          fullWidth
          InputProps={{
            startAdornment: <InputAdornment position="start">+</InputAdornment>,
          }}
          helperText={(phone.countryCode && Validation.number(phone.countryCode)) || ''}
          label="Код страны"
          name="countryCode"
          size="small"
          value={phone.countryCode}
          onChange={numerizeIt}
        />
      </Grid>
      <Grid item lg={2}>
        <TextField
          id="operatorCode"
          fullWidth
          helperText={(phone.operatorCode && Validation.number(phone.operatorCode)) || ''}
          label="Код оператора/ региона"
          name="operatorCode"
          size="small"
          value={phone.operatorCode}
          onChange={numerizeIt}
        />
      </Grid>
      <Grid item>
        <TextField
          id="place"
          fullWidth
          label="номер"
          name="number"
          size="small"
          value={phone.number}
          onChange={numerizeIt}
        />
      </Grid>

      <Grid item>
        <TextField
          id="additionalNumber"
          fullWidth
          helperText={(phone.additionalNumber && Validation.number(phone.additionalNumber)) || ''}
          label="Добавочный"
          name="additionalNumber"
          size="small"
          value={phone.additionalNumber}
          onChange={numerizeIt}
        />
      </Grid>

      <Grid item>
        <FormControl size="small" style={{ minWidth: '20ch' }}>
          <InputLabel id="phone-type-label">Тип контакта</InputLabel>
          <Select
            labelId="phone-type-label"
            label="Тип контакта"
            name="type"
            value={phone.type}
            onChange={handleChangePhone}
          >
            {clsContactsTypes.length > 0 &&
              clsContactsTypes.map((p) => (
                <MenuItem key={p.value} value={p.value}>
                  {p.label}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Grid>
      {!readonly && (
        <React.Fragment>
          <Grid item>
            <Tooltip title="сохранить" placement="top-start">
              <IconButton
                color={
                  (phone.number && phone.type && phone.operatorCode && phone.countryCode && 'primary') || 'default'
                }
                style={{ marginRight: '0.5rem' }}
                onClick={lookUpForDoubles}
              >
                <SaveIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title="отменить" placement="top-start">
              <IconButton
                color="secondary"
                onClick={() => {
                  setIsAddingNewPhone(false);
                  setPhone(defaultPhoneState);
                }}
              >
                <CancelIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </React.Fragment>
      )}
    </Grid>
  );

  return (
    <>
      <div>
        <span style={{ fontSize: '15px' }}>Телефоны</span>
        {!isAddingNewPhone && !readonly && (
          <Tooltip title="Добавить" placement="top-start">
            <IconButton
              color="primary"
              onClick={() => {
                setIsEditingPhone('');
                setPhone(defaultPhoneState);
                setIsAddingNewPhone(true);
              }}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
        )}
      </div>
      {isAddingNewPhone && <React.Fragment>{PhoneForm}</React.Fragment>}

      {data?.dirPerson?.phones && data?.dirPerson?.phones.length > 0 && (
        <TableContainer>
          <Table className={classes.table} aria-label="phone table">
            <TableHead>
              <TableRow>
                <TableCell>номер</TableCell>
                <TableCell>доб</TableCell>
                <TableCell>тип</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.dirPerson?.phones?.map((p) =>
                isEditingPhone === p.id ? (
                  <TableRow key={p.id}>
                    <TableCell>
                      <TextField
                        style={{ width: '100%' }}
                        label="номер"
                        name="number"
                        helperText={(phone.number && Validation.number(phone.number)) || ''}
                        size="small"
                        value={phone.number}
                        onChange={numerizeIt}
                      />
                    </TableCell>

                    <TableCell>
                      <TextField
                        style={{ width: '24ch' }}
                        helperText={(phone.operatorCode && Validation.number(phone.operatorCode)) || ''}
                        label="Код оператора/ региона"
                        name="operatorCode"
                        size="small"
                        value={phone.operatorCode}
                        onChange={numerizeIt}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        style={{ width: '20ch' }}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">+</InputAdornment>,
                        }}
                        helperText={(phone.countryCode && Validation.number(phone.countryCode)) || ''}
                        label="Код страны"
                        name="countryCode"
                        size="small"
                        value={phone.countryCode}
                        onChange={numerizeIt}
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        style={{ width: '20ch' }}
                        helperText={(phone.additionalNumber && Validation.number(phone.additionalNumber)) || ''}
                        label="Добавочный"
                        name="additionalNumber"
                        size="small"
                        value={phone.additionalNumber}
                        onChange={numerizeIt}
                      />
                    </TableCell>

                    <TableCell>
                      <FormControl size="small" style={{ minWidth: '20ch' }}>
                        <InputLabel id="phone-type-label">Тип контакта</InputLabel>
                        <Select
                          labelId="phone-type-label"
                          label="Тип контакта"
                          name="type"
                          value={phone.type}
                          onChange={handleChangePhone}
                        >
                          {clsContactsTypes.length > 0 &&
                            clsContactsTypes.map((p) => (
                              <MenuItem key={p.value} value={p.value}>
                                {p.label}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      {!readonly && (
                        <React.Fragment>
                          <Tooltip title="сохранить" placement="top-start">
                            <IconButton
                              className={classes.btn}
                              color="primary"
                              style={{ marginRight: '0.5rem' }}
                              onClick={lookUpForDoubles}
                            >
                              <SaveIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="отменить" placement="top-start">
                            <IconButton
                              color="secondary"
                              onClick={() => {
                                setIsAddingNewPhone(false);
                                setIsEditingPhone('');
                                setPhone(defaultPhoneState);
                              }}
                            >
                              <CancelIcon />
                            </IconButton>
                          </Tooltip>
                        </React.Fragment>
                      )}
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow key={p.id}>
                    <TableCell>
                      +{p.countryCode} ({p.operatorCode}){p.number}
                    </TableCell>
                    <TableCell>{p.additionalNumber}</TableCell>
                    <TableCell>{p.clsContactType.label}</TableCell>
                    <TableCell align="right">
                      {!readonly && (
                        <React.Fragment>
                          <Tooltip title="изменить" placement="top-start">
                            <IconButton
                              className={classes.btn}
                              color="primary"
                              style={{ marginRight: '0.5rem' }}
                              onClick={() => {
                                setPhone({
                                  id: p.id,
                                  number: p.number,
                                  operatorCode: p.operatorCode,
                                  countryCode: p.countryCode,
                                  additionalNumber: p.additionalNumber || '',
                                  type: p.clsContactType.value,
                                });
                                setIsEditingPhone(p.id);
                                setIsAddingNewPhone(false);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="удалить" placement="top-start">
                            <IconButton className={classes.btn} color="secondary" onClick={() => setDeleteItem(p.id)}>
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                          <ConfirmDialog
                            title={`Удаление телефона`}
                            text={`Вы уверены что хотите удалить телефон?`}
                            btnVariant="outlined"
                            open={deleteItem === p.id}
                            saveBtnText="удалить"
                            cancelBtnText="отмена"
                            saveBtnColor="secondary"
                            cancelBtnColor="primary"
                            onCancel={() => setDeleteItem(null)}
                            onSave={() => {
                              deletePhone(p.id);
                              setDeleteItem(null);
                            }}
                          />
                        </React.Fragment>
                      )}
                    </TableCell>
                  </TableRow>
                ),
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default ContactInfo;
