import {
  CreateReqPartFromStageTwoMutationHookResult,
  UpdateReqPartFromStageTwoMutationHookResult,
  UploadFileMutationHookResult,
  useCreateReqPartFromStageTwoMutation,
  useGetDataForQuoteByDirRegionQuery,
  useGetReqPartFromStageTwoesQuery,
  useUpdateReqPartFromStageTwoMutation,
  useUploadFileMutation,
  useUsersRoivLazyQuery,
} from '../../../../../api';
import {
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import userRights from '../../../../../config/userRights';
import { RequestApplicationModalQuotesRegion } from './RequestApplicationModalQuotesRegion';
import _ from 'lodash';
import moment from 'moment/moment';
import { Select } from '../../../../Inputs';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { DropzoneDialog } from 'material-ui-dropzone';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import LoadingWall from '../../../../LoadingWall/LoadingWall';
export const DOWNLOAD_LINK = process.env.REACT_APP_BACKEND_URI?.replace('/graphql', '');

export const RequestApplicationRegionDetail = ({ listSports, id, idRegion, date, listSportsValue }: any) => {
  const { isRoiv, userRegion } = userRights();
  const { data: dataRegionRoiv, refetch: refetchRoiv, loading: loadingRoiv } = useGetReqPartFromStageTwoesQuery({
    variables: { where: { region: { id: idRegion }, dirCalendar: { id: id } } },
  });

  const [uploadDocumentFile]: UploadFileMutationHookResult = useUploadFileMutation();
  const [create]: CreateReqPartFromStageTwoMutationHookResult = useCreateReqPartFromStageTwoMutation();
  const [update]: UpdateReqPartFromStageTwoMutationHookResult = useUpdateReqPartFromStageTwoMutation();
  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });
  const [fetch, { data: usersRoiv, loading }] = useUsersRoivLazyQuery({
    variables: { regionId: idRegion },
  });
  const [parsed, setParsed] = useState<any[]>([]);

  const [openDialog, setOpenDialog] = useState(false);

  const { control, errors, watch, reset } = useForm();

  let readOnly = false;
  if (isRoiv && idRegion !== userRegion) {
    readOnly = true;
  }
  if (date && isRoiv) {
    const diff = moment(date).add(1, 'day').diff(new Date(), 'days');
    if (diff <= 0) {
      readOnly = true;
    }
  }
  const [active, setActive] = useState();
  const [open, setOpen] = useState<boolean | string>(false);
  const [parsedListSports, setParsedListSports] = useState([]);
  const { data, refetch } = useGetDataForQuoteByDirRegionQuery({
    variables: {
      idRegion,
      idCalendar: id,
    },
  });

  useEffect(() => {
    fetch({ variables: { regionId: idRegion } });
  }, [fetch, idRegion]);

  useEffect(() => {
    if (usersRoiv && !loading) {
      const dt: any[] = [];
      usersRoiv?.users?.forEach((u) => {
        dt.push({
          value: u.id,
          label: `${u.regular?.lastname || ''} ${u.regular?.firstname || ''} ${u.regular?.patronymic || ''} | ${
            u.value || ''
          }`,
        });
      });
      setParsed(dt);
    }
  }, [usersRoiv, loading]);

  useEffect(() => {
    if (data?.quantyRequestStageTwoes?.length) {
      const params = _.keyBy(data?.quantyRequestStageTwoes, 'dirSport.id');
      const list = [];
      Array.from(listSports).reduce((acc, val) => {
        const current = { fullName: val.fullName, id: val.id };
        current.form = params?.[current.id] || {};
        acc.push(current);
        return acc;
      }, list);
      setParsedListSports(list);
    } else {
      setParsedListSports(listSports);
    }
  }, [data, listSports]);

  useEffect(() => {
    if (dataRegionRoiv?.reqPartFromStageTwoes?.length && parsed.length) {
      const user = parsed?.find((i) => {
        return i.value === dataRegionRoiv?.reqPartFromStageTwoes?.[0]?.roivSpecialistsForNotification?.[0]?.id;
      });
      reset({ roivUser: user });
    }
  }, [parsed, reset, dataRegionRoiv]);

  const onChange = async (value: any) => {
    if (!value?.value) return;
    if (!dataRegionRoiv?.reqPartFromStageTwoes?.length) {
      await create({
        variables: {
          data: {
            dirCalendar: { connect: { id: id } },
            region: { connect: { id: idRegion } },
            roivSpecialistsForNotification: { connect: [{ id: value?.value }] },
          },
        },
      });
    } else {
      const remove = dataRegionRoiv?.reqPartFromStageTwoes?.[0]?.roivSpecialistsForNotification?.[0]?.id
        ? { disconnect: [{ id: dataRegionRoiv?.reqPartFromStageTwoes?.[0]?.roivSpecialistsForNotification?.[0]?.id }] }
        : {};
      await update({
        variables: {
          data: {
            roivSpecialistsForNotification: { ...remove, connect: [{ id: value?.value }] },
          },
          where: {
            id: dataRegionRoiv?.reqPartFromStageTwoes?.[0]?.id,
          },
        },
      });
    }
    handleSnackBar('success', 'Специалист успешно обновлен');
    await refetchRoiv({ where: { region: { id: idRegion }, dirCalendar: { id: id } } });
    return;
  };

  const onDeleteFile = async (idFile: string) => {
    if (idFile) {
      await update({
        variables: {
          data: {
            filesROIVStageTwo: {
              disconnect: [{ id: idFile }],
            },
          },
          where: {
            id: dataRegionRoiv?.reqPartFromStageTwoes?.[0]?.id,
          },
        },
      });
      enqueueSnackbar(`Файл успешно удален`, { variant: 'success' });
      await refetchRoiv({ where: { region: { id: idRegion }, dirCalendar: { id: id } } });
    }
  };

  const onUploadFile = async (files: []) => {
    if (files.length > 0) {
      const fileIds: string[] = [];
      await Promise.all(
        files.map(async (item) => {
          const fileResp = await uploadDocumentFile({
            variables: {
              file: item,
            },
          });
          if (fileResp?.data?.uploadFile?.id) {
            fileIds.push(fileResp.data?.uploadFile?.id);
          }
        }),
      );

      if (fileIds.length > 0) {
        if (dataRegionRoiv?.reqPartFromStageTwoes?.length) {
          await update({
            variables: {
              data: {
                filesROIVStageTwo: {
                  connect: fileIds.map((fId) => ({ id: fId })),
                },
              },
              where: {
                id: dataRegionRoiv?.reqPartFromStageTwoes?.[0]?.id,
              },
            },
          });
        } else {
          await create({
            variables: {
              data: {
                dirCalendar: { connect: { id: id } },
                region: { connect: { id: idRegion } },
                filesROIVStageTwo: {
                  connect: fileIds.map((fId) => ({ id: fId })),
                },
              },
            },
          });
        }

        setOpenDialog(false);
        enqueueSnackbar(`Файл(ы) успешно добавлен(ы)`, { variant: 'success' });
      }
    }
    await refetchRoiv({ where: { region: { id: idRegion }, dirCalendar: { id: id } } });
  };

  if (loadingRoiv) {
    return <LoadingWall />;
  }

  return (
    <div>
      <form style={{ maxWidth: 600 }}>
        <Select
          label="Специалист РОИВ для уведомления о добавлении количественных заявок"
          data={parsed}
          control={control}
          error={errors['roivUser']}
          name="roivUser"
          multiple={false}
          loading={loading}
          onSuperChange={(event, value) => onChange(value)}
          disabled={isRoiv}
        />
      </form>
      <Grid item md={9}>
        <div style={{ margin: '10px 0' }}>
          <Typography style={{ display: 'inline-block' }}>Количественная заявка на второй этап:&nbsp;</Typography>
          <Button
            variant="contained"
            disabled={readOnly}
            size="small"
            color="primary"
            onClick={() => setOpenDialog(true)}
          >
            Загрузить файлы
          </Button>
        </div>

        <DropzoneDialog
          showFileNames
          open={openDialog}
          alertSnackbarProps={{
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          }}
          showAlerts={['error']}
          getFileAddedMessage={(fileName: string) => `Файл ${fileName} успешно загружен`}
          getDropRejectMessage={(file: File) =>
            `Файл ${file.name} отклонен. Тип файла не поддерживается. Файл слишком большой. Максимальный размер - 10 мегабайт.`
          }
          getFileLimitExceedMessage={(filesLimit: number) =>
            `За один раз возможно загрузить максимально ${filesLimit} файлов`
          }
          cancelButtonText={'закрыть'}
          submitButtonText={'Загрузить'}
          dialogTitle={'Загрузить файл'}
          onClose={() => setOpenDialog(false)}
          filesLimit={10}
          maxFileSize={10485760}
          previewText={'Предпросмотр: '}
          clearOnUnmount
          initialFiles={[]}
          onSave={(files: any) => {
            return onUploadFile(files);
          }}
          dropzoneText="Максимальное количество файлов за раз: 10"
        />
        {dataRegionRoiv?.reqPartFromStageTwoes?.length && (
          <Grid container style={{ marginTop: 15 }} spacing={1}>
            {dataRegionRoiv?.reqPartFromStageTwoes?.[0]?.filesROIVStageTwo?.map((file, index) => (
              <Grid item container alignItems="center" spacing={1}>
                <Grid item>
                  <a href={DOWNLOAD_LINK + file.path}>{file.name}</a>
                </Grid>
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                  <a style={{ height: '24px', color: 'black' }} href={DOWNLOAD_LINK + file.path}>
                    <DownloadIcon />
                  </a>
                </Grid>
                {!readOnly && (
                  <Grid item>
                    <IconButton
                      style={{ padding: 0 }}
                      edge="end"
                      aria-label="delete"
                      onClick={() => onDeleteFile(file.id)}
                    >
                      <DeleteIcon color="error" />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>

      <TableContainer style={{ width: 650 }} key={id}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="right">Квота М</TableCell>
              <TableCell align="right">Квота Ж</TableCell>
              <TableCell align="right">Квота Сопр</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {parsedListSports.map((row) => (
              <TableRow key={`${row?.id}-${id}`}>
                <TableCell component="th" scope="row">
                  {row?.fullName}
                </TableCell>
                <TableCell align="right">{row?.form?.regionQuotaMaleAthlStageTwo || 0}</TableCell>
                <TableCell align="right">{row?.form?.regionQuotaWomenAthlStageTwo || 0}</TableCell>
                <TableCell align="right">{row?.form?.regionQuotaAccompStageTwo || 0}</TableCell>
                <TableCell align="right">
                  {!readOnly && (
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setActive(row);
                      }}
                    >
                      <EditIcon color="primary" />
                    </IconButton>
                  )}{' '}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <RequestApplicationModalQuotesRegion
        onUpdate={() => {
          refetch();
        }}
        onClose={() => {
          setOpen(false);
        }}
        listSportsValue={listSportsValue}
        idSport={active?.id}
        row={active || {}}
        sportName={active?.fullName}
        idCalendar={id}
        idRegion={idRegion}
        open={open}
      />
    </div>
  );
};
