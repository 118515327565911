import React, { FC } from 'react';
import { useClsSportingEventsRequestStatusQuery } from '../api';
import userRights from '../config/userRights';
import { Select } from '../components/Inputs';
import { ControllerProps } from 'react-hook-form/dist/types/props';

interface SportingEventsRequestStatus extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  error: boolean | undefined;
  disabled?: boolean;
  filter?: any;
}

const SportingEventsRequestStatus: FC<SportingEventsRequestStatus> = ({
  label,
  error,
  control,
  name,
  rules,
  filter,
  disabled,
}) => {
  const { data } = useClsSportingEventsRequestStatusQuery();
  const { canChangeSportingEventStatus } = userRights();
  return (
    <Select
      label={label}
      data={(data && data.dirSportingEventRequestStatusValues) || []}
      control={control}
      error={error}
      name={name}
      rules={rules}
      disabled={disabled}
    />
  );
};

export default SportingEventsRequestStatus;
