import React, { FC, useEffect, useState } from 'react';
import { useFindOrganizationsLazyQuery, useOrganizationByOgrnLazyQuery } from '../api';
import { Select } from '../components/Inputs';
import useDebouncedSearch from '../hooks/useDebouncedSearch';
import { ReactHookFormSelect } from '../components/Inputs/Select';

interface Organizations extends Pick<ReactHookFormSelect, 'control' | 'name' | 'rules' | 'disabled'> {
  label: string;
  error: boolean | undefined;
  filter?: any;
}

const Organizations: FC<Organizations> = ({ disabled, label, error, control, name, rules, filter }) => {
  const [fetch, { data, loading, refetch }] = useFindOrganizationsLazyQuery({ variables: { search: '', filter } });
  const [
    organizationsFetch,
    { data: organizationsData, loading: organizationsLoading },
  ] = useOrganizationByOgrnLazyQuery({
    variables: { filter },
  });
  const [parsed, setParsed] = useState<any>([]);
  const [shouldNtFilter, setShouldNtFilter] = useState(false);

  const refetchOrganizations = async (value: string) => {
    if (value?.length > 2) {
      const isNotANumber = isNaN(Number(value));
      setShouldNtFilter(!isNotANumber);

      if (!isNotANumber) {
        await organizationsFetch({
          variables: { filter: { ...filter, ogrn_contains: value } },
        });
      } else {
        await fetch({
          variables: { search: value, filter },
        });
      }

      // console.log(
      //   data,
      //   data?.data.map((item) => item.organization),
      // );
    }
  };
  const useSearchOrganisations = () => useDebouncedSearch((text) => refetchOrganizations(text));

  const { setInputValue: setValue } = useSearchOrganisations();

  useEffect(() => {
    if (!loading || !organizationsLoading) {
      const prepare = shouldNtFilter
        ? organizationsData?.dirOrganizations
        : data?.data.map((item) => item.organization);
      setParsed(prepare || []);
    }
  }, [data, loading, organizationsLoading]);
  var multiple = false;
  if (name == 'organizers') multiple = true;
  return (
    <Select
      shouldNtFilter={shouldNtFilter}
      label={label}
      data={parsed}
      multiple={multiple}
      control={control}
      disabled={disabled}
      error={error}
      name={name}
      rules={rules}
      onChangeInput={setValue}
      loading={loading || organizationsLoading}
    />
  );
};

export default Organizations;
