import {
  Accordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Typography,
  withStyles,
} from '@material-ui/core';
import { ContentWrapperWidget, MainTemplate } from '../../components/layouts';
import { CreateRefereeMutationHookResult, useCreateRefereeMutation, useRefereeByIdLazyQuery } from '../../api';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';

import AdditionalInfo from '../../components/widgets/Persons/AdditionalInfo';
import Duplicates from '../../components/widgets/Persons/Duplicates';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MainInfo from '../../components/widgets/Persons/MainInfo';
import RefereeCategory from '../../components/widgets/Referees/RefereeCategory';
import _get from 'lodash/get';
import { blue } from '@material-ui/core/colors';
import moment from 'moment';
import onExpand from '../../utils/onExpand';
import { useHistory } from 'react-router';
import { usePermission } from '../../hooks/usePermission';
import { useRouteMatch } from 'react-router-dom';
import userRights from '../../config/userRights';

type AccordionTypes =
  | 'MainInfo'
  | 'Citizenship'
  | 'AdditionalInfo'
  | 'Duplicates'
  | 'RefereeCategory'
  | 'RefereePassport'
  | 'RefereeBankInfo'
  | undefined;

const AccordionSummary = withStyles({
  root: {
    backgroundColor: blue[700],
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    color: 'white',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary) as any;

// @TODO: Update View for Dialog request to Archive

const Single: FC<{}> = () => {
  const { hasAdditionalFilter } = userRights();
  const {
    params: { id },
  } = useRouteMatch();
  const { push } = useHistory();
  const [expanded, setExpanded] = useState<AccordionTypes>('MainInfo');
  const [create]: CreateRefereeMutationHookResult = useCreateRefereeMutation();

  const access = usePermission('directory', 'referees');

  const [fetch, { data, loading }] = useRefereeByIdLazyQuery({ variables: { id, ...hasAdditionalFilter?.referees } });

  const handleChangeAccordion = (panel: AccordionTypes) => (event: ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : undefined);
  };
  const OptionTitleArchive = data?.dirReferee?.archive
    ? ` (В архиве с ${moment(data?.dirReferee?.archive?.date).format('DD.MM.YYYY')})`
    : '';

  useEffect(() => {
    if (id !== 'new' && id !== '') {
      fetch({ variables: { id } });
    }
  }, [fetch, id]);

  return (
    <MainTemplate
      pageName=""
      url=""
      title={
        id === 'new'
          ? 'Добавить нового судью'
          : `Судья: ${data?.dirReferee?.dirPerson?.lastname} ${data?.dirReferee?.dirPerson?.firstname} ${
              data?.dirReferee?.dirPerson?.patronymic || ''
            }`
      }
    >
      <ContentWrapperWidget>
        {id === 'new' ? (
          <h2>Добавить нового судью</h2>
        ) : (
          <h2>
            {`Реестровый номер: ${data?.dirReferee?.dirPerson?.registryNumber || ''} , `}
            Судья: {data?.dirReferee?.dirPerson?.lastname} {data?.dirReferee?.dirPerson?.firstname}
            {` ${_get(data?.dirReferee?.dirPerson, 'patronymic', '') || ''} `}
            {moment(data?.dirReferee?.dirPerson?.birthday).format('DD.MM.YYYY')}
            {OptionTitleArchive}
          </h2>
        )}

        <Accordion expanded={expanded === 'MainInfo'} onChange={handleChangeAccordion('MainInfo')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Общая информация</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {onExpand(expanded, 'MainInfo') && (
              <MainInfo
                id={data?.dirReferee?.dirPerson?.id || 'new'}
                isReferee
                onUpdate={async (idPerson) => {
                  if (id === 'new') {
                    const referee = await create({ variables: { idPerson } });
                    push(`/referee/${referee?.data?.createDirReferee?.id}`);
                  }
                }}
                readonly={!access.includes(2)}
              />
            )}
          </AccordionDetails>
        </Accordion>

        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'AdditionalInfo'}
          onChange={handleChangeAccordion('AdditionalInfo')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Контактная информация</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {onExpand(expanded, 'AdditionalInfo') && data?.dirReferee?.dirPerson?.id && (
              <AdditionalInfo id={data?.dirReferee?.dirPerson.id} readonly={!access.includes(2)} />
            )}
          </AccordionDetails>
        </Accordion>

        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'Duplicates'}
          onChange={handleChangeAccordion('Duplicates')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Дубликаты</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {onExpand(expanded, 'Duplicates') && data?.dirReferee?.dirPerson?.id && (
              <Duplicates id={data?.dirReferee?.dirPerson?.id} readonly={!access.includes(2)} />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'RefereeCategory'}
          onChange={handleChangeAccordion('RefereeCategory')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Судейская категория</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {onExpand(expanded, 'RefereeCategory') && <RefereeCategory id={id} readonly={!access.includes(2)} />}
          </AccordionDetails>
        </Accordion>
      </ContentWrapperWidget>
    </MainTemplate>
  );
};

export default Single;
