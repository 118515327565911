import React, { FC, useState } from 'react';
import { Grid, Button, IconButton } from '@material-ui/core';
import { DropzoneDialog } from 'material-ui-dropzone';
import {
  AgreementVenueCompetitionNotifyMutationHookResult,
  PositionVenueCompetitionNotifyMutationHookResult,
  UploadFileMutationHookResult,
  useAgreementVenueCompetitionNotifyMutation,
  useDeleteVenueCompApplicationMutation,
  useGetVenueApplicationByIdQuery,
  usePositionVenueCompetitionNotifyMutation,
  useUpdateVenueCompApplicationMutation,
  useUploadFileMutation,
} from '../../../api';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import { Email } from '@material-ui/icons';
import userRights from '../../../config/userRights';
import ConfirmDialog from '../../ConfirmDialog';
import { useSnackbar } from 'notistack';

export const CompetitionVenueRegionItem: FC<{
  readonly?: boolean;
  id?: string;
  notValidDate: boolean;
  refetchMain: any;
  refetchMainLoading: boolean;
  email?: string;
}> = ({ refetchMainLoading, refetchMain, readonly, id, notValidDate, email }) => {
  const { isRoiv } = userRights();

  readonly = isRoiv ? notValidDate : readonly;
  const { data, refetch } = useGetVenueApplicationByIdQuery({
    skip: !id || id === 'new',
    variables: { id: id },
    fetchPolicy: 'no-cache',
  });
  const [confirmSentAgreement, setConfirmSentAgreement] = useState(false);
  const [
    agreementVenueCompetitionNotify,
  ]: AgreementVenueCompetitionNotifyMutationHookResult = useAgreementVenueCompetitionNotifyMutation();
  const { enqueueSnackbar } = useSnackbar();

  const [active, setActive] = useState(false);
  const [open, setOpen] = useState(false);
  const [updateApplication] = useUpdateVenueCompApplicationMutation();
  const [deleteApplication, { loading: deleteLoading }] = useDeleteVenueCompApplicationMutation();
  const [uploadDocumentFile]: UploadFileMutationHookResult = useUploadFileMutation();
  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });
  const onDeleteVenueCompRegulationDocAdds = async (fileId) => {
    await updateApplication({
      variables: {
        compAppId: id,
        data: {
          applicationFiles: { disconnect: [{ id: fileId }] },
          notificationSentToFCPSR: false,
        },
      },
    });
    await refetch();
  };

  const onAddVenueCompRegulationDocAdds = async (files) => {
    await Promise.all(
      files?.map(async (f) => {
        return new Promise(async (resolve, reject) => {
          await uploadDocumentFile({
            variables: {
              file: f,
            },
          }).then(async (resp: any) => {
            await updateApplication({
              variables: {
                compAppId: id,
                data: {
                  applicationFiles: {
                    connect: { id: resp?.data?.uploadFile?.id },
                  },
                  notificationSentToFCPSR: false,
                },
              },
            });
            resolve();
          });
        });
      }),
    );
    await refetch();
    setOpen(false);
  };

  const sentDocumentToAgree = async () => {
    await updateApplication({
      variables: {
        compAppId: id,
        data: {
          notificationSentToFCPSR: true,
        },
      },
    });
    await refetch();
  };

  const onDelete = async () => {
    await deleteApplication({
      variables: {
        compAppId: id,
      },
    });
    // await updateApplication({
    //     variables: {
    //         compAppId: id, data: {
    //             notificationSentToFCPSR: false
    //         }
    //     }
    // })
    await refetchMain();
  };

  const onSentAgreement = async () => {
    if (!data?.venueCompApplication?.id) return setConfirmSentAgreement(false);

    const res = await agreementVenueCompetitionNotify({
      variables: { venueCompApplicationId: data?.venueCompApplication?.id },
    });
    if (res?.data?.agreementVenueCompetitionNotify) {
      handleSnackBar('success', 'Уведомление специалисту ФЦПСР отправлено');
      await updateApplication({
        variables: {
          compAppId: id,
          data: {
            notificationSentToFCPSR: true,
          },
        },
      });
      await refetch();
    }
    setConfirmSentAgreement(false);
  };

  const DOWNLOAD_LINK = process.env.REACT_APP_BACKEND_URI?.replace('/graphql', '');
  return (
    <Grid container justify="space-between" direction="row">
      {/*<Grid item>*/}
      {/*  <Button variant="contained" color="primary" onClick={onCreateStub}>Новая заявка</Button>*/}
      {/*</Grid>*/}
      <ConfirmDialog
        title={`Подтвердите отправку`}
        text={`Подтвердите отправку уведомления специалисту ФЦПСР`}
        btnVariant="contained"
        open={confirmSentAgreement}
        saveBtnText="Подтвердить"
        cancelBtnText="Отмена"
        saveBtnColor="primary"
        cancelBtnColor="primary"
        onCancel={() => setConfirmSentAgreement(false)}
        onSave={onSentAgreement}
      />
      <Grid item md={6}>
        <Button variant="contained" disabled={readonly} size="small" color="primary" onClick={() => setOpen(true)}>
          Загрузить файлы
        </Button>
        <DropzoneDialog
          showFileNames
          open={open}
          alertSnackbarProps={{
            anchorOrigin: { vertical: 'top', horizontal: 'right' },
          }}
          showAlerts={['error']}
          getFileAddedMessage={(fileName: string) => `Файл ${fileName} успешно загружен`}
          getDropRejectMessage={(file: File) =>
            `Файл ${file.name} отклонен. Тип файла не поддерживается. Файл слишком большой. Максимальный размер - 10 мегабайт.`
          }
          getFileLimitExceedMessage={(filesLimit: number) =>
            `За один раз возможно загрузить максимально ${filesLimit} файлов`
          }
          cancelButtonText={'закрыть'}
          submitButtonText={'Загрузить'}
          dialogTitle={'Загрузить файл'}
          onClose={() => setOpen(false)}
          filesLimit={10}
          maxFileSize={10485760}
          previewText={'Предпросмотр: '}
          clearOnUnmount
          initialFiles={[]}
          onSave={(files: any) => {
            return onAddVenueCompRegulationDocAdds(files);
          }}
          dropzoneText="Максимальное количество файлов за раз: 10"
        />
        {data?.venueCompApplication?.applicationFiles?.length > 0 && (
          <Grid container style={{ marginTop: 15 }} spacing={1}>
            {data?.venueCompApplication?.applicationFiles?.map((file, index) => (
              <Grid item container alignItems="center" spacing={1}>
                <Grid item>
                  <a href={DOWNLOAD_LINK + file.path}>{file.name}</a>
                </Grid>
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                  <a style={{ height: '24px', color: 'black' }} href={DOWNLOAD_LINK + file.path}>
                    <DownloadIcon />
                  </a>
                </Grid>
                {!readonly && (
                  <Grid item>
                    <IconButton
                      style={{ padding: 0 }}
                      edge="end"
                      aria-label="delete"
                      onClick={() => onDeleteVenueCompRegulationDocAdds(file.id)}
                    >
                      <DeleteIcon color="error" />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
      <Grid direction="column" spacing={2} alignItems="flex-end" container item md={6}>
        <Grid item>
          <Button
            disabled={
              readonly ||
              !email ||
              data?.venueCompApplication?.notificationSentToFCPSR ||
              (!data?.venueCompApplication?.notificationSentToFCPSR &&
                data?.venueCompApplication?.applicationFiles?.length === 0)
            }
            style={{ maxHeight: 30 }}
            variant="outlined"
            startIcon={<Email color="primary" />}
            onClick={() => setConfirmSentAgreement(true)}
          >
            Отправить документы на согласование
          </Button>
        </Grid>
        {!isRoiv && (
          <Grid item>
            <Button
              variant="contained"
              disabled={readonly || deleteLoading || refetchMainLoading}
              size="small"
              style={{
                backgroundColor: readonly || deleteLoading || refetchMainLoading ? 'rgba(0, 0, 0, 0.12)' : 'red',
                color: readonly || deleteLoading || refetchMainLoading ? 'rgba(0, 0, 0, 0.26)' : 'white',
              }}
              onClick={onDelete}
            >
              Удалить заявку
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
