import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  Switch,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  DirNationalTeamTrainer,
  DirSportingEventSport,
  NationalTeamAddDelegationHeadMutationHookResult,
  NationalTeamAddMedicMutationHookResult,
  NationalTeamAddSpecialistMutationHookResult,
  NationalTeamAddTrainerMutationHookResult,
  NationalTeamDeleteDelegationHeadMutationHookResult,
  NationalTeamDeleteMedicMutationHookResult,
  NationalTeamDeleteSpecialistMutationHookResult,
  NationalTeamDeleteTrainerMutationHookResult,
  Scalars,
  SwitchTrainerPrimaryMutationHookResult,
  UpdateDirNationalTeamFilesMutationHookResult,
  UpdateDirNationalTeamFilesRemoveMutationHookResult,
  UploadFileMutationHookResult,
  useDirTrainerSimilarQuery,
  useNationalTeamAddDelegationHeadMutation,
  useNationalTeamAddMedicMutation,
  useNationalTeamAddSpecialistMutation,
  useNationalTeamAddTrainerMutation,
  useNationalTeamDeleteDelegationHeadMutation,
  useNationalTeamDeleteMedicMutation,
  useNationalTeamDeleteSpecialistMutation,
  useNationalTeamDeleteTrainerMutation,
  useNationalTeamQuery,
  useSwitchTrainerPrimaryMutation,
  useUpdateDirNationalTeamFilesMutation,
  useUpdateDirNationalTeamFilesRemoveMutation,
  useUploadFileMutation,
} from '../../../api';
import { DropzoneDialog } from 'material-ui-dropzone';
import React, { FC, useEffect, useState, useMemo, useCallback } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import AddBoxIcon from '@material-ui/icons/AddBox';
import AthletesGroupsComponent from './components/AthletesGroupsComponent';
import ConfirmDialog from '../../ConfirmDialog';
import { ContentWrapperWidget } from '../../layouts';
import CustomSuperSelect from '../../../module/CustomSuperSelect';
import DeleteIcon from '@material-ui/icons/Delete';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RemoveIcon from '@material-ui/icons/Delete';
import SupportMemberComponent from './components/SupportMemberComponent';
import _, { at, forEach } from 'lodash';
import getMessage from '../../../messages';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { checkDate } from './components/helpers';
import NationalTeamDocuments from './NationalTeamDocuments';
import NationalTeamDocumentsArrived from './NationalTeamDocumentsArrived';
import NationalTeamDocumentsQuantitativeOrder from './NationalTeamDocumentsQuantitativeOrder';
import { getAthleteFormHint } from '../../../utils/getAthleteFormHint';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 600,
    },
    link: {
      cursor: 'pointer',
    },
    ahtleteGroupsText: {
      paddingBottom: 15,
      textAlign: 'end',
      [theme.breakpoints.down('md')]: {
        textAlign: 'center',
      },
    },
    verticalSpace: {
      paddingBottom: 15,
    },
    verticalSpaceMarge: {
      marginBottom: 30,
    },
    divider: {
      height: 1,
      width: '100%',
      marginBottom: '1rem',
    },
    btn: {
      padding: '5px 5px',
      minWidth: '15px',
    },
  }),
);

const TeamInfo: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const classes = useStyles();

  const [canAdd, setCanAdd] = useState(false);
  const [canAddAthlete, setCanAddAthlete] = useState(false);
  const [additionalParams, setAdditionalParams] = useState({});
  const [newTrainer, setNewTrainer] = useState<String>('');
  const { loading, data, refetch } = useNationalTeamQuery({
    skip: !id,
    variables: { id },
    fetchPolicy: 'no-cache',
  });

  const collectSportIds = useMemo(() => {
    return data?.dirNationalTeam?.dirSportingEvent?.sports?.map((sport) => sport?.dirSport?.id);
  }, [data]);

  const {
    minManBirthYear,
    maxManBirthYear,
    minWomanBirthYear,
    maxWomanBirthYear,
    athletesQuotaNotCorrespondingByAge,
    athletesQuotaNotCorrespondingByQualification,
  } = data?.dirNationalTeam?.dirSportingEvent || {};
  const isBetweenDates = (date: string | number, isMale = false) => {
    return isMale
      ? checkDate(date, minManBirthYear || 0, maxManBirthYear || 0)
      : checkDate(date, minWomanBirthYear || 0, maxWomanBirthYear || 0);
  };

  const athleteIsInRange = useMemo(
    () =>
      (data?.dirNationalTeam?.athleteGroups?.reduce(
        (acc: number, current: any) =>
          acc +
          current.athletes.filter((athlete: any) =>
            isBetweenDates(
              _.get(athlete, 'dirAthlete.dirPerson.birthday', ''),
              _.get(athlete, 'dirAthlete.dirPerson.isMale', false),
            ),
          ).length,
        0,
      ) || 0) < (athletesQuotaNotCorrespondingByAge || 0),
    [data, athletesQuotaNotCorrespondingByAge, isBetweenDates],
  );

  const athleteIsInRangeRank = useMemo(() => {
    const countOutside =
      data?.dirNationalTeam?.athleteGroups?.reduce((acc: number, current: any) => {
        return (
          acc +
          current.athletes.filter((athlete: any) => {
            const athleteSports = athlete?.dirAthlete?.sports?.filter((sport) => {
              const validateAthlete = getAthleteFormHint(
                athlete,
                sport?.dirSport?.id,
                data?.dirNationalTeam?.dirSportingEvent || {},
              );
              return validateAthlete === 'Отсутствует разряд/звание, соответствующий регламенту';
            });
            return athleteSports?.length > 0;
          }).length
        );
      }, 0) || 0;
    return countOutside < (athletesQuotaNotCorrespondingByQualification || 0);
  }, [data, athletesQuotaNotCorrespondingByQualification]);

  const [open, setOpen] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  const [addTrainer]: NationalTeamAddTrainerMutationHookResult = useNationalTeamAddTrainerMutation();
  const [deleteTrainer]: NationalTeamDeleteTrainerMutationHookResult = useNationalTeamDeleteTrainerMutation();
  const [switchTrainerPrimary]: SwitchTrainerPrimaryMutationHookResult = useSwitchTrainerPrimaryMutation();
  const [addSpecialist]: NationalTeamAddSpecialistMutationHookResult = useNationalTeamAddSpecialistMutation();
  const [deleteSpecialist]: NationalTeamDeleteSpecialistMutationHookResult = useNationalTeamDeleteSpecialistMutation();
  const [addMedic]: NationalTeamAddMedicMutationHookResult = useNationalTeamAddMedicMutation();
  const [
    addDelegationHead,
  ]: NationalTeamAddDelegationHeadMutationHookResult = useNationalTeamAddDelegationHeadMutation();
  const [addFiles]: UpdateDirNationalTeamFilesMutationHookResult = useUpdateDirNationalTeamFilesMutation();
  const [
    removeFiles,
  ]: UpdateDirNationalTeamFilesRemoveMutationHookResult = useUpdateDirNationalTeamFilesRemoveMutation();
  const [deleteMedic]: NationalTeamDeleteMedicMutationHookResult = useNationalTeamDeleteMedicMutation();
  const [
    deleteDelegationHead,
  ]: NationalTeamDeleteDelegationHeadMutationHookResult = useNationalTeamDeleteDelegationHeadMutation();
  const [uploadDocumentFile]: UploadFileMutationHookResult = useUploadFileMutation();

  const [deleteItem, setDeleteItem] = useState<any>(null);

  const onDeleteTrainer = async (trainerId: string) => {
    try {
      await deleteTrainer({
        variables: {
          id,
          trainerId,
        },
      });
      enqueueSnackbar(`Тренер успешно удален`, { variant: 'success' });
      refetch();
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  const onAddTrainer = async () => {
    if (canAdd && newTrainer) {
      try {
        await addTrainer({
          variables: {
            id,
            trainerId: newTrainer,
          },
        });
        enqueueSnackbar(`Тренер успешно добавлен`, { variant: 'success' });
        setNewTrainer('');
        refetch();
      } catch (e) {
        enqueueSnackbar(getMessage(e.message), { variant: 'error' });
      }
    }
  };

  const onSwitchTrainerPrimary = async (event: any, trainerId: string) => {
    try {
      await switchTrainerPrimary({
        variables: {
          id: trainerId,
          isPrimary: event.target.checked,
        },
      });
      enqueueSnackbar(`Тренер успешно добавлен`, { variant: 'success' });
      refetch();
    } catch (error) {
      enqueueSnackbar(getMessage(error.message), { variant: 'error' });
    }
  };

  const onAddNewDocument = async (files: File[]) => {
    if (files.length > 0) {
      await Promise.all(
        files.map(async (item) => {
          const fileResp = await uploadDocumentFile({
            variables: {
              file: item,
            },
          });
          if (fileResp?.data?.uploadFile?.id) {
            // @ts-ignore
            await addFiles({
              variables: {
                id,
                files: fileResp?.data?.uploadFile?.id,
              },
            });
          }
        }),
      );
      refetch();
      setOpen(false);
      enqueueSnackbar(`Файл(ы) успешно добавлен(ы)`, { variant: 'success' });
      return;
    }
  };
  const onRemoveDocument = async (idFile: any) => {
    // @ts-ignore
    await removeFiles({
      variables: {
        id,
        files: idFile,
      },
    });
    enqueueSnackbar(`Файл успешно удален`, { variant: 'success' });
    refetch();
  };

  const onAddNewSpecialist = async (values: any) => {
    if (canAdd) {
      try {
        await addSpecialist({
          variables: {
            id,
            firstname: values.firstname,
            lastname: values.lastname,
            patronymic: values.patronymic,
            birthday: values.birthday,
          },
        });
        enqueueSnackbar(`Специалист успешно добавлен`, { variant: 'success' });
        refetch();
      } catch (e) {
        enqueueSnackbar(getMessage(e.message), { variant: 'error' });
      }
    }
  };

  const onDeleteSpecialist = async (specId: string) => {
    try {
      await deleteSpecialist({
        variables: {
          id,
          specId,
        },
      });
      enqueueSnackbar(`Специалист  успешно удален`, { variant: 'success' });
      refetch();
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  const onAddNewMedic = async (values: any) => {
    if (canAdd) {
      try {
        await addMedic({
          variables: {
            id,
            firstname: values.firstname,
            lastname: values.lastname,
            patronymic: values.patronymic,
            birthday: values.birthday,
          },
        });
        enqueueSnackbar(`Сотрудник медперсонала успешно добавлен`, { variant: 'success' });
        refetch();
      } catch (e) {
        enqueueSnackbar(getMessage(e.message), { variant: 'error' });
      }
    }
  };

  const onAddNewDelegationHead = async (values: any) => {
    if (canAdd) {
      try {
        await addDelegationHead({
          variables: {
            id,
            firstname: values.firstname,
            lastname: values.lastname,
            patronymic: values.patronymic,
            birthday: values.birthday,
          },
        });
        enqueueSnackbar(`Руководитель делегации успешно добавлен`, { variant: 'success' });
        refetch();
      } catch (e) {
        enqueueSnackbar(getMessage(e.message), { variant: 'error' });
      }
    }
  };

  const onDeleteDelegationHead = async (headId: string) => {
    try {
      await deleteDelegationHead({
        variables: {
          id,
          headId,
        },
      });
      enqueueSnackbar(`Специалист  успешно удален`, { variant: 'success' });
      refetch();
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };
  const onDeleteMedic = async (medId: string) => {
    try {
      await deleteMedic({
        variables: {
          id,
          medId,
        },
      });
      enqueueSnackbar(`Сотрудник медперсонала успешно удален`, { variant: 'success' });
      refetch();
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  useEffect(() => {
    if (data && data.dirNationalTeam) {
      const actualFact =
        _.get(data, 'dirNationalTeam.specialists.length', 0) +
        _.get(data, 'dirNationalTeam.medics.length', 0) +
        _.get(data, 'dirNationalTeam.trainers.length', 0) +
        _.get(data, 'dirNationalTeam.delegationHeads.length', 0);
      const athleteQuotaByGender = _.get(data, 'dirNationalTeam.athleteQuotaByGender', false);
      const athleteTotalQuota = _.get(data, 'dirNationalTeam.athleteTotalQuota', 0);

      const actualAthletesQuota =
        _.get(data, 'dirNationalTeam.manTeamQuota', 0) + _.get(data, 'dirNationalTeam.womanTeamQuota', 0);
      // _.get(data, 'dirNationalTeam.athleteGroups', []).reduce(
      //   (acc: number, current: any) => {
      //     console.log(current.athletes)
      //   },
      //   0,
      // );
      let factMan = 0;
      let factWoman = 0;
      const actualAthletesFact = _.get(data, 'dirNationalTeam.athleteGroups', []).reduce(
        (acc: number, current: any) => {
          current.athletes?.forEach((athlete) => {
            if (athlete.dirAthlete.dirPerson.isMale) {
              factMan++;
            } else {
              factWoman++;
            }
          });
          return acc + current.athletes.length;
        },
        0,
      );
      setCanAdd(actualFact < _.get(data, 'dirNationalTeam.supportTeamQuota', 0));
      setCanAddAthlete((athleteQuotaByGender ? actualAthletesQuota : athleteTotalQuota) > actualAthletesFact);
      setAdditionalParams({
        athleteQuotaByGender,
        manQuota: _.get(data, 'dirNationalTeam.manTeamQuota', 0),
        manFact: factMan,
        womanQuota: _.get(data, 'dirNationalTeam.womanTeamQuota', 0),
        womanFact: factWoman,
      });
    }
  }, [data, loading, athletesQuotaNotCorrespondingByAge]);
  const DOWNLOAD_LINK = process.env.REACT_APP_BACKEND_URI?.replace('/graphql', '');

  if (loading) return <LinearProgress color="primary" variant="query" />;
  return (
    <ContentWrapperWidget>
      <Grid item xs={12}>
        <Link href={`${DOWNLOAD_LINK}/reports/sporting-event-participants-application/${id}`}>
          <Button size="small" variant="outlined" color="primary">
            Официальная заявка субъекта РФ
          </Button>
        </Link>
      </Grid>
      <Grid container>
        <SupportMemberComponent
          classes={classes}
          title={`Руководители делегации: ${data?.dirNationalTeam?.delegationHeads?.length || 0}`}
          onSubmit={onAddNewDelegationHead}
          canAdd={canAdd}
          memberArray={data?.dirNationalTeam?.delegationHeads}
          onDelete={(memberId: Scalars['UUID']) => onDeleteDelegationHead(memberId)}
          readonly={readonly}
        />
      </Grid>
      <Accordion style={{ width: '100%' }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="trainer-acc-content" id="trainers-acc-header">
          <Grid container alignItems="flex-end" spacing={2} onClick={(e) => e.stopPropagation()}>
            <Grid item xs={2}>
              <Typography className={classes.title}>
                Тренеры: {_.get(data, 'dirNationalTeam.trainers', [])?.length || 0}&nbsp;
              </Typography>
            </Grid>
            {!readonly && (
              <>
                <Grid item>
                  <CustomSuperSelect
                    queryHook={useDirTrainerSimilarQuery}
                    baseOptions={{
                      bannedTrainers: _.get(data, 'dirNationalTeam.trainers', [])?.map(
                        (trainer: DirNationalTeamTrainer) => trainer.dirTrainer.id,
                      ),
                      sportId: _.get(data, 'dirNationalTeam.dirSportingEvent.sports')?.map((s: DirSportingEventSport) =>
                        _.get(s, 'dirSport.id', ''),
                      ),
                    }}
                    needFilter={false}
                    minWidthValue="45ch"
                    name="trainer"
                    fieldValue={newTrainer}
                    onChange={(event: any) => setNewTrainer(event.target.value)}
                    label="поиск"
                    getOptionLabelFunc={(option) =>
                      ` ${option.person.lastname} ${option.person.firstname} ${option.person.patronymic || ''} ${moment(
                        option.person.birthday,
                      ).format('DD.MM.YY')} ${option.person.registryNumber}`
                    }
                    neededObjValue={`person.trainerProfile.id`}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Tooltip title={(canAdd && 'добавить') || 'Исчерпан лимит на сопровождающих'} placement="top-start">
                    <IconButton
                      className={classes.btn}
                      color={canAdd && newTrainer ? 'primary' : 'default'}
                      onClick={onAddTrainer}
                    >
                      <AddBoxIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </>
            )}
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container direction="row">
            {_.get(data, 'dirNationalTeam.trainers', [])?.map((trainer: DirNationalTeamTrainer, index: number) => (
              <Grid key={trainer.id} item container xs={12}>
                <Grid item xs={4}>
                  {index + 1}{' '}
                  <Link
                    style={{ fontWeight: 500, cursor: 'pointer' }}
                    target="_blank"
                    href={`/trainer/${trainer?.dirTrainer.id}`}
                  >
                    {`${_.get(trainer, 'dirTrainer.dirPerson.lastname', '')} ${_.get(
                      trainer,
                      'dirTrainer.dirPerson.firstname',
                    )} ${_.get(trainer, 'dirTrainer.dirPerson.patronymic', '') || ''}`}
                  </Link>
                </Grid>
                <Grid item container alignItems="center" xs={3}>
                  <Grid item>
                    <Typography style={{ fontWeight: 600, fontSize: 14 }}>Главный тренер:</Typography>
                  </Grid>
                  <Grid item>
                    <Switch
                      checked={trainer?.isPrimary === true}
                      onChange={(_) => onSwitchTrainerPrimary(_, trainer?.id)}
                      inputProps={{ 'aria-label': 'Trainer Switch' }}
                      color="primary"
                      disabled={readonly}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={2}>
                  {moment(_.get(trainer, 'dirTrainer.dirPerson.birthday')).format('DD.MM.YY')}
                </Grid>
                <Grid item xs={2}>
                  <span className={classes.title}>{_.get(trainer, 'dirTrainer.dirPerson.registryNumber', '')}</span>
                </Grid>
                {!readonly && (
                  <Grid item>
                    <Tooltip title="удалить" placement="top-start">
                      <IconButton className={classes.btn} color="secondary" onClick={() => setDeleteItem(trainer.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                    <ConfirmDialog
                      title={`Удаление тренера`}
                      text={`Вы уверены что хотите удалить тренера?`}
                      btnVariant="outlined"
                      open={deleteItem === trainer.id}
                      saveBtnText="да"
                      cancelBtnText="отмена"
                      saveBtnColor="secondary"
                      cancelBtnColor="primary"
                      onCancel={() => setDeleteItem(null)}
                      onSave={() => {
                        onDeleteTrainer(trainer.id).then(() => setDeleteItem(null));
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Grid container>
        <Divider className={classes.divider} />
      </Grid>
      <Grid container>
        <SupportMemberComponent
          classes={classes}
          title={`Специалисты: ${data?.dirNationalTeam?.specialists?.length || 0}`}
          onSubmit={onAddNewSpecialist}
          canAdd={canAdd}
          memberArray={data?.dirNationalTeam?.specialists}
          onDelete={(memberId: Scalars['UUID']) => onDeleteSpecialist(memberId)}
          readonly={readonly}
        />
      </Grid>
      <Grid container>
        <Divider className={classes.divider} />
      </Grid>
      <Grid container>
        <SupportMemberComponent
          classes={classes}
          title={`Медперсонал: ${data?.dirNationalTeam?.medics?.length || 0}`}
          onSubmit={onAddNewMedic}
          canAdd={canAdd}
          memberArray={data?.dirNationalTeam?.medics}
          onDelete={(memberId: Scalars['UUID']) => onDeleteMedic(memberId)}
          readonly={readonly}
        />
      </Grid>
      <Grid item container>
        <Divider className={classes.divider} />
      </Grid>
      <AthletesGroupsComponent
        id={id}
        canAddAthlete={canAddAthlete}
        additionalParams={additionalParams}
        region={data?.dirNationalTeam?.dirRegion?.id}
        university={data?.dirNationalTeam?.university?.id}
        classes={classes}
        athleteIsInRange={athleteIsInRange}
        athleteIsInRangeRank={athleteIsInRangeRank}
        groupsArray={data?.dirNationalTeam?.athleteGroups}
        event={data?.dirNationalTeam?.dirSportingEvent}
        sports={data?.dirNationalTeam?.dirSportingEvent.sports}
        programTypes={data?.dirNationalTeam?.dirSportingEvent?.programTypes}
        onAthleteGroupsUpdated={() => refetch()}
        readonly={readonly}
        startDate={data?.dirNationalTeam?.dirSportingEvent?.startDate}
      />
      <Typography variant="h4" style={{ fontWeight: 'bold' }}>
        Мед. справки: &nbsp;&nbsp;
        <Button variant="contained" size="small" color="primary" onClick={() => setOpen(true)} disabled={readonly}>
          Загрузить файлы
        </Button>
      </Typography>

      <DropzoneDialog
        showFileNames
        open={open}
        alertSnackbarProps={{
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }}
        showAlerts={['error']}
        getFileAddedMessage={(fileName: string) => `Файл ${fileName} успешно загружен`}
        getDropRejectMessage={(file: File) =>
          `Файл ${file.name} отклонен. Тип файла не поддерживается. Файл слишком большой. Максимальный размер - 10 мегабайт.`
        }
        getFileLimitExceedMessage={(filesLimit: number) =>
          `За один раз возможно загрузить максимально ${filesLimit} файлов`
        }
        cancelButtonText={'закрыть'}
        submitButtonText={'Загрузить'}
        dialogTitle={'Загрузить файл'}
        onClose={() => setOpen(false)}
        filesLimit={10}
        maxFileSize={10485760}
        previewText={'Предпросмотр: '}
        clearOnUnmount
        initialFiles={[]}
        onSave={(files) => {
          return onAddNewDocument(files);
        }}
        dropzoneText="Максимальное количество файлов за раз: 10"
      />
      <Grid container alignItems="flex-start" direction="column" justify="center">
        {data?.dirNationalTeam?.files?.map((item, index) => {
          return (
            <Grid item id={item.path} style={{ display: 'flex', alignItems: 'center', margin: '6px 0' }}>
              {index + 1}.&nbsp;&nbsp;&nbsp;<a href={`${DOWNLOAD_LINK}${item.path}`}>{item.name}</a>&nbsp;&nbsp;&nbsp;
              <DownloadIcon onClick={() => {}} />
              &nbsp;&nbsp;
              {!readonly && <RemoveIcon color="error" onClick={() => onRemoveDocument(item.id)} />}
            </Grid>
          );
        })}
      </Grid>
      <Accordion style={{ width: '100%' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="trainer-acc-content2"
          id="trainers-acc-header2"
        >
          <Grid container alignItems="flex-end" spacing={2}>
            <Grid item>
              <Typography className={classes.title}>Согласия участников на обработку персональных данных</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <NationalTeamDocuments id={id} readonly={readonly} />
        </AccordionDetails>
      </Accordion>
      <Accordion style={{ width: '100%' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="trainer-acc-content33"
          id="trainers-acc-header33"
        >
          <Grid container alignItems="flex-end" spacing={2}>
            <Grid item>
              <Typography className={classes.title}>План приезда-отъезда</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <NationalTeamDocumentsArrived id={id} readonly={readonly} />
        </AccordionDetails>
      </Accordion>
      <Accordion style={{ width: '100%' }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="trainer-acc-content34"
          id="trainers-acc-header34"
        >
          <Grid container alignItems="flex-end" spacing={2}>
            <Grid item>
              <Typography className={classes.title}>Предварительная количественная заявка</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <NationalTeamDocumentsQuantitativeOrder id={id} readonly={readonly} />
        </AccordionDetails>
      </Accordion>
    </ContentWrapperWidget>
  );
};

export default TeamInfo;
