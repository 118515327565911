import { Button, Dialog, DialogTitle, Grid, IconButton, Link, Tooltip, Typography } from '@material-ui/core';
import { DatePicker, Input } from '../../../Inputs';
import {
  DocumentFragment,
  Scalars,
  UpdateRefereeSportMutationHookResult,
  UploadFileMutationHookResult,
  useUpdateRefereeSportMutation,
  useUploadFileMutation,
} from '../../../../api';
import React, { FC, useState } from 'react';

import ConfirmDialog from '../../../ConfirmDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import DocumentSelect from '../../../../module/Documents';
import DocumentTypes from '../../../../module/DocumentTypes';
import { DropzoneArea } from 'material-ui-dropzone';
import SaveIcon from '@material-ui/icons/Save';
import getMessage from '../../../../messages';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';
import { useStyles } from './styles';

interface formState {
  title?: string;
  date?: Date;
  number?: string;
  clsType?: { label: string; value: string };
}

interface lightFormState {
  dirDocument?: { label: string; value: Scalars['UUID'] };
}

interface DocComponent {
  currentCat: Scalars['UUID'];
  documentUpdated: () => void;
  dirDocuments: DocumentFragment[];
  id: Scalars['UUID'];
  readOnly?: boolean;
}

const DocumentComponent: FC<DocComponent> = ({ currentCat, dirDocuments, id, documentUpdated, readOnly }) => {
  const classes = useStyles();

  const DOWNLOAD_LINK = process.env.REACT_APP_BACKEND_URI?.replace('/graphql', '');

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const [deleteItem, setDeleteItem] = useState<any>(null);

  const defaultNewDocumentState: formState = {
    title: undefined,
    date: undefined,
    number: undefined,
    clsType: undefined,
  };

  const { handleSubmit, control, errors, reset, formState } = useForm({
    defaultValues: defaultNewDocumentState,
  });

  const {
    handleSubmit: documentFormHandleSubmit,
    control: documentFormControl,
    errors: documentFormErrors,
    reset: documentFormReset,
    formState: documentFormState,
  } = useForm({
    defaultValues: {
      dirDocument: undefined,
    },
  });
  const [updateSportData]: UpdateRefereeSportMutationHookResult = useUpdateRefereeSportMutation();
  const [uploadDocumentFile]: UploadFileMutationHookResult = useUploadFileMutation();

  const [isAddingNewDocument, setIsAddingNewDocument] = useState(false);
  const [isAddingNEwDocumentFromSystem, setIsAddingNEwDocumentFromSystem] = useState(false);
  const [file, setFile] = useState<Array<File>>([]);

  const addNewDocument = async (values: lightFormState) => {
    const { dirDocument } = values;

    try {
      if (isAddingNewDocument && dirDocument) {
        updateSportData({
          variables: {
            data: {
              categories: {
                update: [
                  {
                    where: {
                      id: currentCat,
                    },
                    data: {
                      dirDocuments: {
                        connect: [
                          {
                            id: dirDocument?.value,
                          },
                        ],
                      },
                    },
                  },
                ],
              },
            },
            id,
          },
        });
        documentUpdated();
        setIsAddingNewDocument(false);
        documentFormReset();
      }
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const handleAddNewDocumentFromSystem = async (values: formState) => {
    const { title, date, number, clsType } = values;
    try {
      if (isAddingNEwDocumentFromSystem && title && date && file) {
        uploadDocumentFile({
          variables: {
            file: file[0],
          },
        }).then((resp: any) => {
          const {
            uploadFile: { id: fileId },
          } = resp.data;
          updateSportData({
            variables: {
              data: {
                categories: {
                  update: [
                    {
                      where: {
                        id: currentCat,
                      },
                      data: {
                        dirDocuments: {
                          create: [
                            {
                              title,
                              date,
                              number,
                              clsType: clsType && { connect: { id: clsType?.value } },
                              file: {
                                connect: { id: fileId },
                              },
                            },
                          ],
                        },
                      },
                    },
                  ],
                },
              },
              id,
            },
          });
          documentUpdated();
          setIsAddingNEwDocumentFromSystem(false);
          reset(defaultNewDocumentState);
        });
      }
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const deleteDocument = async (idFile: string) => {
    try {
      await updateSportData({
        variables: {
          data: {
            categories: {
              update: [
                {
                  where: {
                    id: currentCat,
                  },
                  data: {
                    dirDocuments: { disconnect: [{ id: idFile }] },
                  },
                },
              ],
            },
          },
          id,
        },
      });
      documentUpdated();
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  return (
    <React.Fragment>
      {
        <React.Fragment>
          {isAddingNewDocument ? (
            <React.Fragment>
              <form onSubmit={documentFormHandleSubmit(addNewDocument)} style={{ width: '100%' }}>
                <Grid item container spacing={2} alignItems="flex-end">
                  <Grid item md={12}>
                    <DocumentSelect
                      label="Документ"
                      control={documentFormControl}
                      error={!!documentFormErrors['dirDocument']}
                      name="dirDocument"
                      rules={{ required: true }}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      style={{ marginRight: '0.5rem' }}
                      startIcon={<SaveIcon />}
                      type="submit"
                      disabled={!documentFormState.isDirty}
                    >
                      Сохранить
                    </Button>

                    <Button
                      variant="outlined"
                      color="secondary"
                      style={{ marginRight: '0.5rem' }}
                      size="small"
                      onClick={() => {
                        setIsAddingNewDocument(false);
                        reset();
                      }}
                    >
                      Отменить
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Grid item container alignItems="center">
                {!readOnly && (
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ marginRight: '0.5rem' }}
                      size="small"
                      type="button"
                      onClick={() => setIsAddingNewDocument(true)}
                    >
                      Из базы
                    </Button>
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ marginRight: '0.5rem' }}
                      size="small"
                      type="button"
                      onClick={() => {
                        setIsAddingNEwDocumentFromSystem(true);
                      }}
                    >
                      Новый
                    </Button>
                  </Grid>
                )}
              </Grid>
            </React.Fragment>
          )}
        </React.Fragment>
      }
      {dirDocuments?.map((dirDocument) => (
        <Grid
          key={dirDocument?.id}
          container
          alignItems="center"
          spacing={2}
          style={{ marginTop: '0.8rem', flexWrap: 'nowrap' }}
        >
          <Grid item>
            <Link className={classes.link} target="_blank" href={`${DOWNLOAD_LINK}${dirDocument.file.path}`}>
              {dirDocument.title}
            </Link>
          </Grid>
          <Grid item>
            <Typography>{`${dirDocument?.number} ${
              (dirDocument?.date && moment(dirDocument?.date).format('DD.MM.YYYY')) || ''
            } ${dirDocument?.clsType?.label}`}</Typography>
          </Grid>
          {!readOnly && (
            <Grid item>
              <Tooltip title="удалить" placement="top">
                <IconButton color="secondary" onClick={() => setDeleteItem(dirDocument?.id)}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
              <ConfirmDialog
                title={`Удаление документа категории`}
                text={`Вы уверены что хотите удалить этот документ?`}
                btnVariant="outlined"
                open={deleteItem === dirDocument?.id}
                saveBtnText="да"
                cancelBtnText="отмена"
                saveBtnColor="secondary"
                cancelBtnColor="primary"
                onCancel={() => setDeleteItem(null)}
                onSave={() => {
                  deleteDocument(dirDocument?.id).then(() => setDeleteItem(null));
                }}
              />
            </Grid>
          )}
        </Grid>
      ))}
      <Dialog
        open={isAddingNEwDocumentFromSystem}
        onClose={() => {
          setIsAddingNEwDocumentFromSystem(false);
          reset(defaultNewDocumentState);
        }}
        maxWidth="xs"
      >
        <DialogTitle id="add-document-dialog">Новый Документ</DialogTitle>
        <div style={{ padding: '1.5rem' }}>
          <form onSubmit={handleSubmit(handleAddNewDocumentFromSystem)} style={{ width: '100%' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Input
                  label="Название документа"
                  control={control}
                  error={!!errors['title']}
                  name="title"
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <DocumentTypes
                  label="Тип документа"
                  error={!!errors['clsType']}
                  control={control}
                  rules={{ required: true }}
                  name="clsType"
                />
              </Grid>
              <Grid item xs={12}>
                <DatePicker
                  fullWidth
                  label="Дата"
                  control={control}
                  error={!!errors['date']}
                  name="date"
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <Input label="Номер документа" control={control} error={!!errors['number']} name="number" />
              </Grid>
              <Grid item xs={12}>
                <DropzoneArea
                  showFileNames
                  alertSnackbarProps={{
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                  }}
                  dropzoneClass={classes.dropZone}
                  showAlerts={['error']}
                  getFileAddedMessage={(fileName: string) => `Файл ${fileName} успешно загружено`}
                  getDropRejectMessage={(file: File) =>
                    `Файл ${file.name} отклонен. Тип файла не поддерживается. Файл слишком большой. Максимальный размер - 10 мегабайт.`
                  }
                  filesLimit={1}
                  maxFileSize={10485760}
                  clearOnUnmount
                  initialFiles={file}
                  onChange={(fileArray: File[]) => setFile(fileArray)}
                  dropzoneText="Файл"
                />
              </Grid>
              <Grid item container justify="flex-end">
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  startIcon={<SaveIcon />}
                  type="submit"
                  disabled={!formState.isDirty}
                >
                  Добавить
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Dialog>
    </React.Fragment>
  );
};

export default DocumentComponent;
