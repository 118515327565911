import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  LinearProgress,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { FC, useMemo, useState } from 'react';
import { useClsCalendarCategoryQuery } from '../../../../api';
import React from 'react';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { EventListByCalendarCategoryAccordion } from './EventListByCalendarCategoryAccordion';

interface SpartkiadsCategoryProps {
  regionId?: string;
  calendarYearList?: number[];
}

const useStyles = makeStyles({
  row: {
    display: 'flex',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  tinyIcon: {
    fontSize: 18,
  },
  icon: {
    fontSize: 20,
  },
  tableHead: {
    fontWeight: 'bold',
  },
  header: {
    fontWeight: 600,
    fontSize: 16,
    letterSpacing: 1.2,
  },
  verticalSpace: {
    marginTop: 5,
    marginBottom: 5,
  },
});

export const SpartkiadsCategory: FC<SpartkiadsCategoryProps> = ({ regionId, calendarYearList }) => {
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();

  const canLoad = !!(regionId && calendarYearList);
  const { loading, data } = useClsCalendarCategoryQuery({
    fetchPolicy: 'no-cache',
    skip: !canLoad,
  });

  const categories = useMemo(() => {
    if (data) {
      const result = data?.classifierValues
        ?.filter((c) => c?.label?.toLowerCase().startsWith('спартакиада'))
        ?.sort((a, b) => b?.label?.toLocaleLowerCase()?.localeCompare(a?.label?.toLocaleLowerCase()));

      [result[0], result[1]] = [result[1], result[0]];
      return result;
    }
    return [];
  }, [data]);

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  console.log('categories ===>', categories);

  if (!canLoad) return null;

  if (loading) return <LinearProgress color="primary" variant="query" />;
  return (
    <div style={{ margin: '10px 0' }}>
      <Accordion expanded={expanded} onChange={handleExpand} key={'Спартакиада'}>
        <AccordionSummary expandIcon={<ExpandMore />} aria-controls={`Спартакиада-content`} id={`Спартакиада-header`}>
          <div>
            <Typography className={classes.header} color="primary">
              Спартакиада
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ width: '100%' }}>
            {categories?.map((category) => (
              <EventListByCalendarCategoryAccordion
                key={category?.label}
                calendarCategory={category}
                regionId={regionId}
                calendarYearList={calendarYearList}
              />
            ))}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
