import { Button, Grid } from '@material-ui/core';
import React, { FC } from 'react';

import ClearIcon from '@material-ui/icons/Clear';
import ContentWrapper from '../layouts/ContentWrapper/ContentWrapper';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Input, Select } from '../Inputs';
import SearchIcon from '@material-ui/icons/Search';
import { makeStyles } from '@material-ui/core/styles';
import { onSetCalendarFilter } from './helpers';
import { useForm } from 'react-hook-form';
import { useGenerateCalendarXlsFileLazyQuery } from '../../api';
import useGenerateXlsx from '../../hooks/useGenerateXlsx';
import Years from '../../module/Years';
import CalendarCategory from '../../module/CalendarCategory';
import PartEKP from '../../module/PartEKP';

interface Filter {
  OR: Array<object>;
}
interface Value {
  fullName: string;
}

const useStyle = makeStyles((theme) => ({
  btn: {
    marginRight: theme.spacing(2),
  },
}));

const Calendars: FC<{
  onSubmit?: (T: any, E: any) => void;
  value?: any;
  first: number;
}> = ({ onSubmit = () => {}, value, first }) => {
  const style = useStyle();
  const { handleSubmit, control, errors, reset, formState, getValues } = useForm({
    defaultValues: value,
  });

  const { onDownloadFile } = useGenerateXlsx({
    lazyQuery: useGenerateCalendarXlsFileLazyQuery,
    getValues,
    setFilter: onSetCalendarFilter,
    fileName: 'calendars',
    first,
  });

  const handleSearchBox = ({ fullName, ...other }: { fullName?: string }) => {
    const filters: Filter = onSetCalendarFilter({ fullName, ...other });

    onSubmit(filters, getValues());
  };

  return (
    <ContentWrapper>
      <form onSubmit={handleSubmit(handleSearchBox)}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Input label="Название Календаря" control={control} error={!!errors['fullName']} name="fullName" />
          </Grid>
          <Grid item xs={2}>
            <Years name="year" label="Год" error={!!errors['year']} control={control} />
          </Grid>
          {/*<Grid item md={2}>*/}
          {/*  <Season label="Сезон" error={!!errors['clsSeason']} control={control} name="clsSeason" />*/}
          {/*</Grid>*/}
          <Grid item md={4}>
            <PartEKP label="Часть ЕКП" error={!!errors['clsEkpPart']} control={control} name="clsEkpPart" />
          </Grid>
          <Grid item md={4}>
            <CalendarCategory
              label="Категория календаря"
              error={!!errors['clsCalendarCategory']}
              control={control}
              name="clsCalendarCategory"
            />
          </Grid>
          <Grid item md={4}>
            <Input
              label="Номер ЕКП Минспорта России"
              control={control}
              error={!!errors['ekpMinsportNumber']}
              name="ekpMinsportNumber"
              type="number"
            />
          </Grid>
          <Grid item xs={3}>
            <Select
              label="В архиве"
              data={[
                { value: '', label: ' ' },
                { value: false, label: 'Нет' },
                { value: true, label: 'Да' },
              ]}
              control={control}
              error={!!errors['archive']}
              name="archive"
              defaultValue={{ value: false, label: 'Нет' }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="outlined"
              className={style.btn}
              color="primary"
              type="button"
              onClick={() => {
                onSubmit({ archive: null }, {});
                reset({
                  archive: { value: false, label: 'Нет' },
                });
              }}
              size="small"
              startIcon={<ClearIcon />}
            >
              Сброс
            </Button>
            <Button
              variant="outlined"
              color="primary"
              type="submit"
              size="small"
              className={style.btn}
              startIcon={<SearchIcon />}
              disabled={!formState.isDirty}
            >
              Поиск
            </Button>
            <Button
              variant="outlined"
              color="primary"
              disabled={!formState.isDirty}
              onClick={onDownloadFile}
              size={'small'}
              startIcon={<GetAppIcon />}
            >
              XLS
            </Button>
          </Grid>
        </Grid>
      </form>
    </ContentWrapper>
  );
};

export default Calendars;
