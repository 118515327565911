import {
  CalendarArchiveMutationHookResult,
  CalendarFragment,
  CalendarUnArchiveMutationHookResult,
  CreateCalendarMutationHookResult,
  UpdateCalendarMutationHookResult,
  useCalendarArchiveMutation,
  useCalendarQuery,
  useCalendarUnArchiveMutation,
  useCreateCalendarMutation,
  useUpdateCalendarMutation,
} from '../../../api';
import { useForm } from 'react-hook-form';
import React, { FC, useEffect } from 'react';
import { Button, Divider, Grid, Link } from '@material-ui/core';
import { Input } from '../../Inputs';
import SaveIcon from '@material-ui/icons/Save';
import { ContentWrapperWidget } from '../../layouts';
import { useSnackbar } from 'notistack';
import getMessage from '../../../messages';
import { useHistory } from 'react-router';
import ArchiveWidget from '../Shared/Archive';
import DatePicker from '../../Inputs/DatePicker';
import CalendarTypes from '../../../module/CalendarTypes';
import moment from 'moment';

const MainInfo: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const DOWNLOAD_LINK = process.env.REACT_APP_BACKEND_URI?.replace('/graphql', '');

  const { data, refetch } = useCalendarQuery({ skip: !id || id === 'new', variables: { id }, fetchPolicy: 'no-cache' });
  const { handleSubmit, control, errors, reset, formState } = useForm({
    defaultValues: data?.dirCalendar as CalendarFragment,
  });
  const { push } = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [create]: CreateCalendarMutationHookResult = useCreateCalendarMutation();
  const [update]: UpdateCalendarMutationHookResult = useUpdateCalendarMutation();
  const [archive]: CalendarArchiveMutationHookResult = useCalendarArchiveMutation();
  const [unarchive]: CalendarUnArchiveMutationHookResult = useCalendarUnArchiveMutation();

  const onSubmit = async (values: CalendarFragment) => {
    try {
      if (id !== 'new') {
        await update({
          variables: {
            id,
            data: {
              fullName: values.fullName,
              shortName: values.shortName,
              type: {
                connect: {
                  id: values?.type?.value,
                },
              },
            },
          },
        });
        enqueueSnackbar(`Календарь "${values?.fullName}" успешно обновлен`, { variant: 'success' });
      } else {
        const { data } = await create({
          variables: {
            data: {
              fullName: values.fullName as string,
              shortName: values.shortName as string,
              type: {
                connect: {
                  id: values?.type?.value,
                },
              },
            },
          },
        });
        if (data?.createDirCalendar && data?.createDirCalendar?.id) {
          enqueueSnackbar(`Календарь "${values?.fullName}" успешно добавлен`, { variant: 'success' });
          push(`/calendar/${data?.createDirCalendar?.id}`);
        }
      }
    } catch (e) {
      if (e.message === 'A unique constraint would be violated on DirCalendar. Details: Field name = fullName') {
        enqueueSnackbar(getMessage('Неуникальное название'), { variant: 'error' });
      } else {
        enqueueSnackbar(getMessage(e.message), { variant: 'error' });
      }
    }
  };

  useEffect(() => {
    if (data?.dirCalendar) {
      reset(data?.dirCalendar);
    }
  }, [data, reset]);

  if (readonly)
    return (
      <Grid container spacing={1}>
        <Grid item container>
          <strong>Название :{` ${data?.dirCalendar?.fullName || ''}`}</strong>
        </Grid>
        <Grid item container>
          <strong>Краткое название: {` ${data?.dirCalendar?.shortName || '~'}`}</strong>
        </Grid>
        <Grid item container>
          <strong>Тип календаря: {` ${data?.dirCalendar?.type?.label || '~'}`}</strong>
        </Grid>
        <Grid item>
          <Button variant="outlined" color="primary" type="button" size="small">
            Перейти к списку мероприятий
          </Button>
        </Grid>
      </Grid>
    );

  return (
    <>
      <Grid container>
        <Grid item lg={6} md={12} xs={12}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <ContentWrapperWidget>
              <Grid container spacing={1}>
                <Grid item md={6}>
                  <Input
                    label="Название"
                    control={control}
                    error={!!errors['fullName']}
                    name="fullName"
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item md={6}>
                  <Input
                    label="Краткое название"
                    control={control}
                    error={!!errors['shortName']}
                    name="shortName"
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item md={4}>
                  <CalendarTypes
                    label="Тип календаря"
                    error={!!errors['type']}
                    control={control}
                    name="type"
                    defaultValue={{
                      value: 1,
                      label: 'Минспорт',
                    }}
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item md={12}>
                  <Button variant="outlined" color="primary" type="button" size="small">
                    Перейти к списку мероприятий
                  </Button>
                </Grid>
                <Grid item md={12}>
                  <Button
                    variant="outlined"
                    color="primary"
                    type="submit"
                    size="small"
                    startIcon={<SaveIcon color="primary" />}
                    disabled={!formState.isDirty}
                  >
                    Сохранить
                  </Button>
                </Grid>
              </Grid>
            </ContentWrapperWidget>
          </form>
          {id && id !== 'new' && (
            <Grid item container>
              <Link href={`${DOWNLOAD_LINK}/reports/sporting-event-sports-by-ekp-calendar/${id}`}>
                <Button color="primary" variant="contained">
                  Выгрузка ЕКП
                </Button>
              </Link>
            </Grid>
          )}
          <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        </Grid>
        {id && id !== 'new' && !readonly && (
          <Grid item lg={12} md={12} xs={12}>
            <ArchiveWidget
              archive={data?.dirCalendar?.archive || null}
              id={id}
              refetch={refetch}
              onArchive={archive}
              onUnarchive={unarchive}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default MainInfo;
