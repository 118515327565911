import { Button, Grid, IconButton, Typography } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import { Scalars, UpdateSportingEventMutationHookResult, useUpdateSportingEventMutation } from '../../../../api';

import { Checkbox } from '../../../Inputs';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import RefereesPositions from '../../../../module/RefereesPositions';
import SaveIcon from '@material-ui/icons/Save';
import getMessage from '../../../../messages';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

interface formState {
  referee?: any;
  position: { value: Scalars['UUID']; label: string };
  isNonresident?: boolean;
}

const RefereeForm: FC<{
  onUpdate: Function;
  isUpdatingReferee?: boolean;
  id?: string;
  referee: any;
  readonly?: boolean;
  onCancel: Function;
}> = ({ referee, onUpdate, id, readonly, isUpdatingReferee, onCancel }) => {
  const [isEditing, setIsEditing] = useState(isUpdatingReferee);

  const [updateEvent]: UpdateSportingEventMutationHookResult = useUpdateSportingEventMutation();

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const defaultState: formState = {
    referee,
    position: referee?.clsRefereePosition,
    isNonresident: referee?.isNonresident,
  };

  const onHandleCancel = () => {
    setIsEditing(false);
    reset({});
    onCancel();
  };

  const { handleSubmit, control, errors, reset, formState } = useForm({
    defaultValues: defaultState,
  });

  useEffect(() => {
    if (isUpdatingReferee !== isEditing) setIsEditing(isUpdatingReferee);
    if (isUpdatingReferee === true)
      reset({
        referee,
        position: referee?.clsRefereePosition,
        isNonresident: referee?.isNonresident,
      });
  }, [isEditing, isUpdatingReferee, referee, reset]);

  const onSubmit = async (values: formState) => {
    const { position, isNonresident } = values;

    try {
      await updateEvent({
        variables: {
          data: {
            referees: {
              update: [
                {
                  where: {
                    id: referee?.id,
                  },
                  data: {
                    clsRefereePosition: {
                      connect: {
                        id: position?.value,
                      },
                    },
                    isNonresident: isNonresident || false,
                  },
                },
              ],
            },
          },
          id,
        },
      });
      handleSnackBar('success', 'Судь успешно обновлена');

      setIsEditing(false);
      reset({});
      onUpdate();
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  return (
    <form onSubmit={handleSubmit((values) => onSubmit(values))} style={{ width: '100%' }}>
      {!readonly && isEditing ? (
        <Grid container style={{ marginBottom: 15 }} alignItems="flex-end" spacing={2}>
          <Grid item xs={12}>
            <RefereesPositions
              label="Должность"
              control={control}
              error={!!errors['position']}
              name="position"
              rules={{ required: true }}
            />
          </Grid>
          <Grid item xs={12}>
            <Checkbox
              type="checkbox"
              label="Иногородний"
              control={control}
              error={!!errors['isNonresident']}
              name="isNonresident"
            />
          </Grid>

          <Grid item>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              startIcon={<ClearIcon />}
              type="button"
              onClick={onHandleCancel}
            >
              Отменить
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<SaveIcon />}
              type="submit"
              disabled={!formState.isDirty}
            >
              Сохранить
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid container style={{ marginBottom: 10 }} alignItems="center" spacing={2}>
          <Grid item>
            <Typography>{referee?.clsRefereePosition?.shortName}</Typography>
          </Grid>
        </Grid>
      )}
    </form>
  );
};

export default RefereeForm;
