import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  makeStyles,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { FC, useEffect, useState } from 'react';

import {
  CreateDirRefusedRegionMutationHookResult,
  CreateDirReturnedRegionMutationHookResult,
  DeleteDirRefusedRegionMutationHookResult,
  DeleteDirReturnedRegionMutationHookResult,
  DirRegionWhereInput,
  UpdateDirRefusedRegionMutationHookResult,
  UpdateDirReturnedRegionMutationHookResult,
  UploadFileMutationHookResult,
  useCalendarReportEventsLazyQuery,
  useCreateDirRefusedRegionMutation,
  useCreateDirReturnedRegionMutation,
  useDeleteDirRefusedRegionMutation,
  useDeleteDirReturnedRegionMutation,
  useRefusedRegionsByCalendarQuery,
  useRegionsListQuery,
  useUpdateDirRefusedRegionMutation,
  useUpdateDirReturnedRegionMutation,
  useUploadFileMutation,
  useValidateRegionByExistEventLazyQuery,
} from '../../../../api';
import BtnReport from '../../../BtnReport/BtnReport';

import SignedReports from '../SignedReports/SignedReports';
import SportAccordion from '../SportAccordion/SportAccordion';
import { Input } from '../../../Inputs';
import SaveIcon from '@material-ui/icons/Save';
import { useForm } from 'react-hook-form';
import { keyBy } from 'lodash';
import { Alert } from '@material-ui/lab';
import EditIcon from '@material-ui/icons/Edit';
import { DropzoneArea } from 'material-ui-dropzone';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import LoadingWall from '../../../LoadingWall/LoadingWall';

const dropZoneStyle = {
  minHeight: 30,
  border: 0,
  borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
  borderRadius: 0,

  '& > .MuiDropzonePreviewList-root': {
    margin: '0 !important',
    width: '100%',
    '& > .MuiDropzonePreviewList-imageContainer': {
      flexBasis: 'unset',
      width: '100%',
      maxWidth: '100%',
      display: 'flex',
      alignItems: 'center',
      '& > svg': {
        height: '40px',
      },
      '& > p': {
        marginLeft: 15,
        marginRight: 15,
      },
      '& > button': {
        top: 'unset',
        right: 0,
        color: '#d32f2f',
        boxShadow: 'unset',
      },
    },
  },
  '& > .MuiDropzoneArea-textContainer': {
    display: 'flex',

    '& > .MuiDropzoneArea-text': {
      margin: 0,
      fontSize: 15,
    },
    '& > .MuiDropzoneArea-icon': {
      width: 20,
      height: 20,
      marginLeft: 'auto',
    },
  },
  '&:focus': {
    outline: 0,
    border: 0,
    borderBotom: '2px solid #3f51b5',
  },
};

const useStyles = makeStyles({
  head: {
    fontWeight: 600,
    fontSize: 15,
    letterSpacing: 1.2,
  },
  dropZone: { ...dropZoneStyle },
  requiredDropZone: {
    ...dropZoneStyle,
    '& > .MuiDropzoneArea-textContainer': {
      display: 'flex',

      '& > .MuiDropzoneArea-text': {
        margin: 0,
        fontSize: 15,
        '&:after': {
          content: '"*"',
          color: 'red',
          verticalAlign: 'middle',
          marginLeft: 4,
        },
      },
      '& > .MuiDropzoneArea-icon': {
        width: 20,
        height: 20,
        marginLeft: 'auto',
      },
    },
  },
  notRequiredDropZone: {
    ...dropZoneStyle,
    '& > .MuiDropzoneArea-textContainer': {
      display: 'flex',

      '& > .MuiDropzoneArea-text': {
        margin: 0,
        fontSize: 15,
      },
      '& > .MuiDropzoneArea-icon': {
        width: 20,
        height: 20,
        marginLeft: 'auto',
      },
    },
  },
});

const RegionsAccordionList: FC<{
  id?: string;
  readonly?: boolean;
  regionFilters?: DirRegionWhereInput;
  isRoiv?: boolean;
  calendarName?: string;
  extendedReadonly?: boolean;
  firstStep?: boolean;
}> = ({ id, readonly, regionFilters = {}, isRoiv = false, calendarName, extendedReadonly, firstStep }) => {
  const classes = useStyles();
  const [active, setActive] = useState('');
  const [refusedRegionsSort, setRefusedRegionsSort] = useState({});
  const [returnedRegionsSort, setReturnedRegionsSort] = useState({});
  const [modal, setModal] = useState(false);
  const [cantRemove, setCantRemove] = useState<string | null>(null);
  const [openCancelRevert, setOpenCancelRevert] = useState(false);
  const [file, setFile] = useState<null | File>(null);
  const [loader, setLoader] = useState(false);

  const { handleSubmit, control, errors } = useForm();
  const [fetch, { data: dataValidate }] = useValidateRegionByExistEventLazyQuery({
    fetchPolicy: 'no-cache',
  });
  const [uploadDocumentFile]: UploadFileMutationHookResult = useUploadFileMutation();
  const [refusedRegion]: CreateDirRefusedRegionMutationHookResult = useCreateDirRefusedRegionMutation();
  const [returnedRegion]: CreateDirReturnedRegionMutationHookResult = useCreateDirReturnedRegionMutation();
  const [refusedRegionUpdate]: UpdateDirRefusedRegionMutationHookResult = useUpdateDirRefusedRegionMutation();
  const [returnedRegionUpdate]: UpdateDirReturnedRegionMutationHookResult = useUpdateDirReturnedRegionMutation();
  const [refusedRegionDelete]: DeleteDirRefusedRegionMutationHookResult = useDeleteDirRefusedRegionMutation();
  const [returnedRegionDelete]: DeleteDirReturnedRegionMutationHookResult = useDeleteDirReturnedRegionMutation();
  const { data: refusedRegions, refetch, loading: loadingRefusedRegions } = useRefusedRegionsByCalendarQuery({
    variables: { id },
  });
  const role = localStorage.getItem('userRole');

  const deleteAttachmentReturned = async (v) => {
    setLoader(true);
    await returnedRegionUpdate({
      variables: {
        id: returnedRegionsSort[v]?.id,
        data: {
          statement: { disconnect: true },
        },
      },
    });
    refetch();
    setLoader(false);
  };

  const onSubmit = async (values: any) => {
    setLoader(true);
    if (openCancelRevert) {
      const data = {};
      let newFile = null;

      if (file) {
        newFile = await uploadDocumentFile({
          variables: {
            file,
          },
        });
        data.statement = { connect: { id: newFile.data?.uploadFile?.id } };
      }
      if (returnedRegionsSort[openCancelRevert]?.id && newFile) {
        await returnedRegionUpdate({
          variables: {
            id: returnedRegionsSort[openCancelRevert]?.id,
            data: data,
          },
        });
        if (refusedRegionsSort[openCancelRevert]?.id) {
          await refusedRegionDelete({
            variables: {
              id: refusedRegionsSort[openCancelRevert]?.id,
            },
          });
        }
      } else {
        await returnedRegion({
          variables: {
            data: {
              dirRegion: { connect: { id: openCancelRevert } },
              dirCalendar: { connect: { id } },
              ...data,
            },
          },
        });
        if (refusedRegionsSort[openCancelRevert]?.id) {
          await refusedRegionDelete({
            variables: {
              id: refusedRegionsSort[openCancelRevert]?.id,
            },
          });
        }
      }

      refetch();
      setFile(null);
      setOpenCancelRevert(false);
      setLoader(false);
      return;
    }
    if (!id) return;
    let newFile = null;
    const dt = {
      comment: values?.comment,
    };
    if (file) {
      newFile = await uploadDocumentFile({
        variables: {
          file,
        },
      });
      dt.statement = { connect: { id: newFile.data?.uploadFile?.id } };
    }
    if (modal && refusedRegionsSort[modal]) {
      const data = await refusedRegionUpdate({
        variables: {
          id: refusedRegionsSort[modal]?.id,
          data: dt,
        },
      });
      if (returnedRegionsSort[modal]?.id) {
        await returnedRegionDelete({
          variables: {
            id: returnedRegionsSort[modal]?.id,
          },
        });
      }
      refetch();
      setFile(null);
      setModal(false);
      setLoader(false);
    } else {
      const data = await refusedRegion({
        variables: {
          data: {
            dirRegion: { connect: { id: modal } },
            dirCalendar: { connect: { id } },
            ...dt,
          },
        },
      });
      if (returnedRegionsSort[modal]?.id) {
        await returnedRegionDelete({
          variables: {
            id: returnedRegionsSort[modal]?.id,
          },
        });
      }

      refetch();
      setFile(null);
      setModal(false);
    }
    setLoader(false);
  };

  useEffect(() => {
    if (modal) {
      fetch({
        variables: {
          idRegion: modal,
          idCalendar: id,
        },
      });
    }
  }, [modal]);

  useEffect(() => {
    if (dataValidate && dataValidate?.dirSportingEvents?.length > 0) {
      setCantRemove(
        'В данном регионе имеются активные спортивные мероприятия, перед отказом необходимо убрать данные мероприятия в архив',
      );
    } else {
      setCantRemove(null);
    }
  }, [dataValidate]);

  const { data, loading, refetch: refetchList } = useRegionsListQuery({
    variables: {
      filter: {
        archive: null,
        ...regionFilters,
      },
    },
  });

  useEffect(() => {
    if (refusedRegions) {
      setRefusedRegionsSort(
        keyBy(refusedRegions?.dirRefusedRegions, (o) => {
          return o?.dirRegion?.value;
        }),
      );
      setReturnedRegionsSort(
        keyBy(refusedRegions?.dirReturnedRegions, (o) => {
          return o?.dirRegion?.value;
        }),
      );
    }
  }, [refusedRegions]);

  if (loading || loadingRefusedRegions || loader) return <LoadingWall />;
  const DOWNLOAD_LINK = process.env.REACT_APP_BACKEND_URI?.replace('/graphql', '');
  return (
    <div style={{ width: '100%' }}>
      {!isRoiv && (
        <Grid container justify="flex-end" style={{ marginBottom: 10 }}>
          <BtnReport
            lazyQuery={useCalendarReportEventsLazyQuery}
            mergeCellsPosition={[
              ['B', 'B'],
              ['C', 'C'],
            ]}
            lastTableColumnLetter={'G'}
            label="Выгрузить отчет (Приложение 2)"
            serviceApi="calendarReport"
            blancUrl="/blancReport2.xlsx"
            reportNumber={2}
            mergeStart={9}
            calendarName={calendarName}
            CalendarNamePosition="A5:G5"
            filter={{
              api: {
                dirCalendar: { id },
                dirRegion: { id_in: data?.dirRegions.map((r) => r.value) },
                clsEventStages_some: { registryNumber: 100002914 },
                archive: null,
              },
              service: {
                regions: data?.dirRegions,
              },
            }}
            fileName="Приложение № 2_Отчет о проведении I этапа Соревнований календаря.xlsx"
          />
        </Grid>
      )}
      {data?.dirRegions?.map((region) => (
        <Accordion
          key={region.value}
          expanded={active === region.label}
          onChange={() => {
            setActive(active === region.label ? '' : region.label);
          }}
          style={{ backgroundColor: refusedRegionsSort[region.value] ? '#cecece' : '' }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`${region.label}-content`}
            id={`${region.label}-header`}
          >
            <Typography className={classes.head} color="primary">
              {region.label} {refusedRegionsSort[region.value] ? '(отказ от участия)' : ''}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {active === region.label && (
              <div style={{ width: '100%' }}>
                {!refusedRegionsSort[region.value] && role !== 'FCPSR_SPORTING_EVENT_OPERATOR' && (
                  <div
                    style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}
                  >
                    {(returnedRegionsSort[region.value] && (
                      <div>
                        <div>
                          Документ об отмене решения об отказе участия в мероприятии:
                          {returnedRegionsSort[region.value]?.statement === null && (
                            <IconButton onClick={() => setOpenCancelRevert(region.value)}>
                              <AddIcon />
                            </IconButton>
                          )}
                        </div>
                        <Link
                          href={`${DOWNLOAD_LINK}${returnedRegionsSort[region.value]?.statement?.path}`}
                          style={{ wordBreak: 'break-all' }}
                        >
                          {returnedRegionsSort[region.value]?.statement?.name}
                        </Link>
                        {returnedRegionsSort[region.value]?.statement && (
                          <IconButton onClick={() => deleteAttachmentReturned(region.value)}>
                            <DeleteIcon />
                          </IconButton>
                        )}

                        <br />
                        <br />
                      </div>
                    )) || <div></div>}
                    <div>
                      <Button variant="outlined" size="small" color="primary" onClick={() => setModal(region.value)}>
                        Отказ от участия
                      </Button>
                    </div>
                  </div>
                )}
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <div style={{ width: '100%' }}>
                    {refusedRegionsSort[region.value] && (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          width: '100%',
                        }}
                      >
                        <div>
                          <Link
                            href={`${DOWNLOAD_LINK}${refusedRegionsSort[region.value]?.statement?.path}`}
                            style={{ wordBreak: 'break-all' }}
                          >
                            {refusedRegionsSort[region.value]?.statement?.name}
                          </Link>
                          <IconButton onClick={() => setModal(region.value)}>
                            <EditIcon />
                          </IconButton>
                          <div>{refusedRegionsSort[region.value]?.comment}</div>
                          <br />
                          <br />
                        </div>
                        {role !== 'FCPSR_SPORTING_EVENT_OPERATOR' && (
                          <div>
                            <Button
                              onClick={() => setOpenCancelRevert(region.value)}
                              variant="contained"
                              color="primary"
                            >
                              Возврат участия
                            </Button>
                          </div>
                        )}
                      </div>
                    )}
                    <BtnReport
                      lazyQuery={useCalendarReportEventsLazyQuery}
                      label="Выгрузить отчет (Приложение 4)"
                      reportNumber={1}
                      serviceApi="calendarReport"
                      fileName="Приложение № 4_Отчет о проведении I этапа Соревнований календаря.xlsx"
                      calendarName={calendarName}
                      CalendarNamePosition="A7:F7"
                      filter={{
                        api: {
                          dirCalendar: { id },
                          dirRegion: { id: region.value },
                          clsEventStages_some: { registryNumber: 100002914 },
                          archive: null,
                        },
                        service: {
                          regions: [region],
                        },
                      }}
                    />

                    <SignedReports id={id} regionId={region.value} />
                  </div>
                </div>

                {modal && (
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Dialog open={modal} aria-labelledby="form-dialog-title">
                      <DialogTitle id="form-dialog-title">Отказ от участия: "{region.label}"</DialogTitle>
                      <DialogContent style={{ minWidth: 400 }}>
                        <Grid container direction="column" spacing={1}>
                          <Grid item container>
                            <Input
                              label="Комментарий"
                              error={errors['comment']}
                              name="comment"
                              control={control}
                              defaultValue={refusedRegionsSort[modal]?.comment}
                            />
                          </Grid>
                          <Grid item>
                            {refusedRegionsSort[region.value]?.statement?.path && (
                              <>
                                Прикрепленный Файл: <br />
                                <Link
                                  href={`${DOWNLOAD_LINK}${refusedRegionsSort[region.value]?.statement?.path}`}
                                  style={{ wordBreak: 'break-all' }}
                                >
                                  {refusedRegionsSort[region.value]?.statement?.name}
                                </Link>
                                <br />
                                <br />
                              </>
                            )}

                            <DropzoneArea
                              showFileNames
                              alertSnackbarProps={{
                                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                              }}
                              dropzoneClass={classes.requiredDropZone}
                              showAlerts={['error']}
                              getFileAddedMessage={(fileName) => `Файл ${fileName} успешно загружено`}
                              getDropRejectMessage={(file) =>
                                `Файл ${file.name} отклонен. Тип файла не поддерживается. Файл слишком большой. Максимальный размер - 10 мегабайт.`
                              }
                              filesLimit={1}
                              maxFileSize={10485760}
                              onChange={(fileArray) => {
                                if (fileArray.length > 0) {
                                  setFile(fileArray[0]);
                                }
                              }}
                              dropzoneText="Файл"
                            />
                          </Grid>
                          {cantRemove && (
                            <Grid item>
                              <Alert severity="warning">{cantRemove}</Alert>
                            </Grid>
                          )}
                        </Grid>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          size="small"
                          onClick={() => {
                            setModal(false);
                            setFile(null);
                          }}
                        >
                          Закрыть
                        </Button>
                        <Button
                          size="small"
                          variant="outlined"
                          onClick={handleSubmit(onSubmit)}
                          color="primary"
                          startIcon={<SaveIcon />}
                          disabled={!refusedRegionsSort[modal] ? !file || !!cantRemove : !!cantRemove}
                        >
                          Сохранить
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </form>
                )}
                {openCancelRevert && (
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Dialog open={openCancelRevert} aria-labelledby="form-dialog-title">
                      <DialogTitle id="form-dialog-title">Возврат участия: "{region.label}"</DialogTitle>
                      <DialogContent style={{ minWidth: 400 }}>
                        <Grid container direction="column" spacing={1}>
                          <Grid item>
                            <DropzoneArea
                              showFileNames
                              alertSnackbarProps={{
                                anchorOrigin: { vertical: 'top', horizontal: 'right' },
                              }}
                              dropzoneClass={classes.notRequiredDropZone}
                              showAlerts={['error']}
                              getFileAddedMessage={(fileName) => `Файл ${fileName} успешно загружено`}
                              getDropRejectMessage={(file) =>
                                `Файл ${file.name} отклонен. Тип файла не поддерживается. Файл слишком большой. Максимальный размер - 10 мегабайт.`
                              }
                              filesLimit={1}
                              maxFileSize={10485760}
                              onChange={(fileArray) => {
                                if (fileArray.length > 0) {
                                  setFile(fileArray[0]);
                                }
                              }}
                              dropzoneText="Файл"
                            />
                          </Grid>
                        </Grid>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          size="small"
                          onClick={() => {
                            setOpenCancelRevert(false);
                            setFile(null);
                          }}
                        >
                          Закрыть
                        </Button>
                        <Button
                          size="small"
                          variant="outlined"
                          onClick={handleSubmit(onSubmit)}
                          color="primary"
                          startIcon={<SaveIcon />}
                        >
                          Сохранить
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </form>
                )}
                <SportAccordion
                  hideBtnReport
                  stageRegistryNumber={100002914}
                  regionId={region.value}
                  id={id}
                  readonly={readonly}
                  extendedReadonly={extendedReadonly}
                  firstStep={firstStep}
                />
              </div>
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default RegionsAccordionList;
