import { Chip, Grid, TextField } from '@material-ui/core';
import { FC } from 'react';
import BackspaceIcon from '@material-ui/icons/Backspace';
import { Autocomplete } from '@material-ui/lab';
import { Controller } from 'react-hook-form';
import { ControllerProps } from 'react-hook-form/dist/types/props';
import React from 'react';

export interface ReactHookFormSelect extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  data: any[];
  error: boolean | undefined;
  defaultValue?: any;
  multiple?: boolean;
  loading?: boolean;
  enablePortal?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  shouldNtFilter?: boolean;
  onChangeInput?: (value: string) => void;
  isTag?: boolean;
  useLabelElement?: boolean;
  existElements?: any[];
  placeholder?: string;
  disableFilterOptions?: boolean;
  onSuperChange?: any;
}

export interface IValue {
  value: any;
  label: string;
  registryNumber?: Number;
}

const Select: FC<ReactHookFormSelect> = ({
  loading,
  onChangeInput,
  multiple,
  data,
  defaultValue,
  label,
  error,
  errorMessage,
  control,
  name,
  rules,
  enablePortal = true,
  disabled,
  shouldNtFilter,
  isTag,
  useLabelElement,
  existElements = [],
  placeholder,
  disableFilterOptions,
  onSuperChange,
}) => {
  return (
    <Controller
      render={({ onChange, ...props }) => {
        const { value } = props;
        return (
          <Autocomplete
            {...props}
            loading={loading}
            multiple={multiple}
            fullWidth
            id={name}
            disabled={disabled}
            options={data}
            disablePortal={!enablePortal}
            getOptionSelected={(option: IValue, value: IValue) => {
              return option.value === value.value;
            }}
            {...(shouldNtFilter && { filterOptions: (options, state) => options })}
            renderTags={(value: { value: any; label: string }[], getTagProps) =>
              value.map((option: { value: any; label: string }, index: number) => (
                <Chip
                  {...(isTag ? { color: 'primary' } : { variant: 'outlined' })}
                  label={option.label}
                  {...getTagProps({ index })}
                />
              ))
            }
            getOptionLabel={(option: IValue) => {
              return option?.value || option?.value === false ? option.label : '';
            }}
            renderOption={({ value, label }: IValue) => (
              <React.Fragment>
                {useLabelElement ? (
                  <Grid container justify="space-between" alignItems="center">
                    <Grid item>{label}</Grid>
                    {existElements.includes(value) && (
                      <Grid item>
                        <BackspaceIcon color="secondary" />
                      </Grid>
                    )}
                  </Grid>
                ) : (
                  label
                )}
              </React.Fragment>
            )}
            {...(disableFilterOptions && { filterOptions: (x) => x })}
            onChange={(event, data) => {
              if (onSuperChange) {
                onSuperChange(event, data);
              } else {
                onChange(data);
              }
            }}
            renderInput={(params) => {
              return (
                <TextField
                  {...params}
                  error={error}
                  helperText={errorMessage}
                  fullWidth
                  label={
                    <>
                      {label} {rules?.required && <span style={{ color: 'red' }}>*</span>}
                    </>
                  }
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'off',
                  }}
                  onChange={(event) => {
                    onChangeInput?.(event.target.value);
                  }}
                  placeholder={placeholder}
                />
              );
            }}
          />
        );
      }}
      rules={rules}
      control={control}
      name={name}
      defaultValue={defaultValue ? defaultValue : multiple ? [] : null}
    />
  );
};

export default Select;
