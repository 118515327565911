import { Grid, Tooltip } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { useOrganizations2ndLazyQuery, useOrganizationsLazyQuery } from '../../api';
import React, { FC, useCallback, useState } from 'react';
import { getSettingsUserFilter, getSettingsUserFilterFormValue } from '../../store/selectors/settings';
import { useDispatch, useSelector } from 'react-redux';

import { AppStore } from '../../store/reducers';
import { BtnNew } from '../../components/BtnNew';
import { DataTable } from '../../components/DataTable/NewTable';
import { DictionaryWrapper } from '../../components/DictionaryWrapper';
import { MUIDataTableColumn } from 'mui-datatables';
import { MainTemplate } from '../../components/layouts';
import { OrganizationsFilter } from '../../components/Filters';
import { SET_USER_SETTINGS_FILTER } from '../../store/constants';
import _ from 'lodash';
import { usePermission } from '../../hooks/usePermission';
import userRights from '../../config/userRights';

const Page: FC<{}> = () => {
  const { hasAdditionalFilter } = userRights();

  const dispatch = useDispatch();
  const filter: any = useSelector((state: AppStore) => getSettingsUserFilter(state, 'organizations'), _.isEqual);
  const formValue: any = useSelector(
    (state: AppStore) => getSettingsUserFilterFormValue(state, 'organizations'),
    _.isEqual,
  );

  const [first, setFirst] = useState(0);
  const { push } = useHistory();
  const access = usePermission('directory', 'organizations');

  const setFilter = useCallback(
    (filter, form) => {
      dispatch({
        type: SET_USER_SETTINGS_FILTER,
        payload: {
          name: 'organizations',
          filter: filter,
          form,
        },
      });
    },
    [dispatch],
  );

  const columns: MUIDataTableColumn[] = [
    {
      name: 'name',
      label: 'Название',
      options: {
        filter: false,
        sort: false,
        viewColumns: false,
        customBodyRender: (value: string, meta: any) => {
          return (
            <React.Fragment>
              <Tooltip title="Перейти в карточку" placement="top-start">
                <Link
                  style={{
                    fontWeight: 500,
                    cursor: 'pointer',
                    textDecoration: 'none',
                  }}
                  to={`/organization/${meta.rowData[1]}`}
                  target={'_blank'}
                >
                  {value}
                </Link>
              </Tooltip>
            </React.Fragment>
          );
        },
      },
    },
    {
      name: 'id',
      label: '',
      options: {
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'ogrn',
      label: 'ОГРН',
      options: {
        sort: false,
        display: true,
      },
    },
    {
      name: 'isUniversity',
      label: 'ВУЗ',
      options: {
        sort: false,
        display: true,
        customBodyRender: (value) => <React.Fragment>{value ? 'Да' : ''}</React.Fragment>,
      },
    },
    {
      name: 'dirRegion',
      label: 'Регион',
      options: {
        sort: false,
        display: true,

        customBodyRender: (value) => <React.Fragment>{value?.label || ''}</React.Fragment>,
      },
    },
    {
      name: 'clsOrganizationCategories',
      label: 'Категория',
      options: {
        sort: false,
        display: true,

        customBodyRender: (label) => <React.Fragment>{label?.map((s: any) => s?.label)?.join(', ')}</React.Fragment>,
      },
    },
    {
      name: 'sports',
      label: 'Вид спорта',
      options: {
        sort: false,
        display: true,

        customBodyRender: (value) => (
          <React.Fragment>{value?.map((s: any) => s?.dirSport?.label)?.join(', ')}</React.Fragment>
        ),
      },
    },
    {
      name: 'registryNumber',
      label: 'Реестровый номер',
      options: {
        sort: false,
        display: true,
      },
    },
  ];

  return (
    <MainTemplate pageName="Спортивные организации" url="" title="Спортивные организации">
      <OrganizationsFilter
        onSubmit={(filters: any, form: any) => setFilter({ ...filters }, form)}
        value={formValue}
        defaultFilter={{ ...hasAdditionalFilter?.organizations }}
        first={first}
      />
      <DictionaryWrapper>
        <DataTable
          tableName="organizations"
          title={
            <React.Fragment>
              <Grid container spacing={2} alignItems={'center'}>
                <Grid item>
                  <h2>Спортивные организации</h2>
                </Grid>
                {access.includes(2) && <Grid item>{<BtnNew onClick={() => push('/organization/new')} />}</Grid>}
              </Grid>
            </React.Fragment>
          }
          hasDoubleQuery
          lazyQuery={filter?.name ? useOrganizations2ndLazyQuery : useOrganizationsLazyQuery}
          revealFunctionName={filter?.name ? 'organizations' : undefined}
          filter={{ ...filter, ...hasAdditionalFilter?.organizations }}
          columns={columns}
        />
      </DictionaryWrapper>
    </MainTemplate>
  );
};

export default Page;
