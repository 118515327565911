import exceljs from 'exceljs';
import { CalendarReportEventFragment } from '../../../api';
import { saveAs } from 'file-saver';

export type TRegionL = {
  label: string;
  value: string;
  __typename?: string;
};

export type FetchCalendarReport = {
  data: Data;
  reportNumber?: number;
  lastTableColumnLetter?: string;
  calendarName?: string;
  CalendarNamePosition?: string;
  filter?: {
    service: {
      regions?: Array<TRegionL>;
    };
  };
  mergeCellsPosition?: Array<[string, string]>;
  blancUrl?: string;
  fileName: string;
  mergeStart?: number;
  fullReport?: boolean;
};

export type TRightDataResult = {
  [key: string]: {
    events: Data;
  };
};

export type Data = Array<CalendarReportEventFragment>;

export const fetchCalendarReportUniversiad: (props: FetchCalendarReport) => void = async (props) => {
  try {
    const {
      data = [],
      lastTableColumnLetter = 'F',
      CalendarNamePosition = 'A5:F5',
      blancUrl = '/blancReportUniversiad.xlsx',
      calendarName = '',
      fileName,
      mergeStart = 9,
    } = props;

    const workbook = new exceljs.Workbook();

    const saveFile = async () => {
      const buffer = await workbook.xlsx.writeBuffer({
        useStyles: true,
      });
      const blob = new Blob([buffer], { type: 'applicationi/xlsx' });
      saveAs(blob, fileName);
    };
    const blanc = await (await fetch(blancUrl)).arrayBuffer();
    await workbook.xlsx.load(blanc);
    const sheet = workbook.worksheets[0];

    const setCalendarName = () => {
      sheet.getCell(CalendarNamePosition).value = calendarName;
    };

    const drawCurrentLastRowBorder = (lastColNumber: number) => {
      const lastTableRow = sheet.getRow(lastColNumber);

      lastTableRow.eachCell((cell) => {
        cell.style = {
          ...cell.style,
          border: {
            bottom: {
              style: 'thick',
              color: {
                argb: '000000',
              },
            },
          },
          alignment: {
            vertical: 'middle',
            horizontal: 'center',
            wrapText: true,
            shrinkToFit: true,
          },
        };
      });
    };

    // const mergeCells = (cellsPosition: string) => {
    //   sheet.unMergeCells(cellsPosition);
    //   sheet.mergeCells(cellsPosition);
    // };

    const drawLastColumnBorder = () => {
      for (let index = 0; index < tableTrack.length; index++) {
        const cellName = `${lastTableColumnLetter}${index + mergeStart}`;
        const cell = sheet.getCell(cellName);

        sheet.getCell(cellName).style = {
          ...cell.style,
          border: {
            ...cell.style.border,
            right: {
              style: 'thick',
              color: {
                argb: '000000',
              },
            },
          },
        };
      }
    };

    const tableTrack: number[] = [];
    for (const event of data) {
      if (event?.sports && event?.sports?.length > 0) {
        for (let index = 0; index < event.sports.length; index++) {
          tableTrack.push(index + 1);
          const sport = event.sports[index].dirSport;
          const rowIndex = mergeStart + tableTrack.length - 1;
          sheet.insertRow(rowIndex, [
            tableTrack.length,
            sport.label,
            event.name,
            event.ekp || '',
            Number(event.membersQuota || ''),
            event.venue || '',
          ]);
          sheet.getRow(rowIndex).eachCell((cell) => {
            cell.alignment = {
              vertical: 'middle',
              horizontal: 'center',
            };
          });
        }
      }
    }
    drawCurrentLastRowBorder(mergeStart + tableTrack.length - 1);
    drawLastColumnBorder();
    setCalendarName();

    await saveFile();
  } catch (error) {
    console.log('error', error);
  }
};
