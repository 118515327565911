import { IconButton, LinearProgress, Link, makeStyles, Typography } from '@material-ui/core';
import AddBoxIcon from '@material-ui/icons/AddBox';
import React, { FC, useState } from 'react';

import {
  CalendarEventSportFragment,
  CreateSportingEventMutationHookResult,
  useCalendarEventSportsQuery,
  useCreateSportingEventMutation,
} from '../../../../api';
import { useSnackbar } from 'notistack';
import AddNewEventFom, { TForm } from '../AddNewEventFom/AddNewEventFom';
import getMessage from '../../../../messages';
import userRights from '../../../../config/userRights';

const useStyles = makeStyles({
  head: {
    fontWeight: 600,
    fontSize: 15,
    letterSpacing: 1.2,
  },
  listItem: {
    fontSize: 15,
    letterSpacing: 1,
  },
});

const FinalEtape: FC<{ id?: string; stageRegistryNumber: number; readonly?: boolean }> = ({
  id,
  stageRegistryNumber,
  readonly,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const [createNewEvent]: CreateSportingEventMutationHookResult = useCreateSportingEventMutation();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const { data, loading, refetch } = useCalendarEventSportsQuery({
    variables: {
      id,
      stageRegistryNumber,
    },
    skip: !id,
  });
  const handleClose = () => {
    setOpen(false);
  };

  const getSportName = (event: CalendarEventSportFragment) => event.sports?.map((s) => s.dirSport.fullName).join(', ');

  const handleSubmitEventForm = async (value: TForm) => {
    const { name, venue, startDate, endDate, membersQuota, ekp, dirSport, daysBeforeStartToAddProtocols } = value;

    try {
      const defVal = {
        name,
        venue,
        startDate,
        endDate,
        membersQuota: parseInt(membersQuota, 10),
        ekp,
        dirCalendar: {
          connect: {
            id,
          },
        },
        clsEventStages: {
          connect: [
            {
              registryNumber: stageRegistryNumber,
            },
          ],
        },
        statuses: {
          create: [
            {
              value: { connect: { id: 1 } },
            },
          ],
        },
        status: {
          connect: {
            id: 1,
          },
        },
        sports: {
          create: [
            {
              dirSport: {
                connect: {
                  id: dirSport?.value,
                },
              },
            },
          ],
        },
      };
      if (daysBeforeStartToAddProtocols) {
        defVal.daysBeforeStartToAddProtocols = Number(daysBeforeStartToAddProtocols);
      }
      await createNewEvent({
        variables: {
          data: defVal,
        },
      });
      handleSnackBar('success', 'Новое мероприятие успешно создано');
      refetch();
      setOpen(false);
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };
  const { hasAdditionalFilter } = userRights();
  const sportFilter = hasAdditionalFilter?.sportsFilter || [];
  if (loading) return <LinearProgress color="primary" />;

  return (
    <div style={{ width: '100%' }}>
      {open && (
        <AddNewEventFom
          ekp={data?.dirCalendar?.ekpMinsportNumber}
          etape={3}
          id={id}
          handleClose={handleClose}
          onSubmit={handleSubmitEventForm}
        />
      )}
      {!readonly && (
        <IconButton color="primary" onClick={() => setOpen(true)}>
          <AddBoxIcon />
        </IconButton>
      )}
      <div style={{ paddingLeft: 15, marginTop: 10 }}>
        {data?.dirSportingEvents?.map((event) => {
          const companionSports = event.sports
            ?.reduce((acc, i) => {
              return [...acc, i.dirSport.id];
            }, [])
            .filter((sp) => sportFilter.length === 0 || (sportFilter.length > 0 && sportFilter.includes(sp)));
          if (companionSports.length === 0) return null;
          return (
            <Link
              key={event.id}
              style={{ fontWeight: 600, cursor: 'pointer' }}
              target="_blank"
              href={`/sporting_event/${event.id}`}
            >
              <Typography className={classes.head} color="primary">
                {getSportName(event)}
              </Typography>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export default FinalEtape;
