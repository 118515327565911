import React, { FC, useEffect, useState } from 'react';
import {
  CreateNotificationProtocolMutationHookResult,
  DeleteNotificationProtocolMutationHookResult,
  UpdateNotificationProtocolMutationHookResult,
  useCreateNotificationProtocolMutation,
  useDeleteNotificationProtocolMutation,
  useNotificationProtocolQuery,
  useUpdateNotificationProtocolMutation,
  useUsersRoivLazyQuery,
} from '../api';
import { Select } from '../components/Inputs';
import { useForm } from 'react-hook-form';

const SearchUsersRoiv: FC<any> = ({ idRegion, idCalendar, idSport }) => {
  const [fetch, { data, loading }] = useUsersRoivLazyQuery({
    variables: { regionId: idRegion },
  });
  const { control, errors, watch, reset } = useForm();

  const who = localStorage.getItem('userRole');
  const readonly = ['FCPSR_ADMINISTRATOR', 'FCPSR_SPECIALIST', 'ADMIN'].indexOf(who) === -1;

  const { data: dataNotification, loading: loadingNotification, refetch } = useNotificationProtocolQuery({
    variables: {
      dirCalendar: idCalendar,
      dirRegion: idRegion,
      dirSport: idSport,
    },
  });

  const [createNotification]: CreateNotificationProtocolMutationHookResult = useCreateNotificationProtocolMutation();
  const [updateNotification]: UpdateNotificationProtocolMutationHookResult = useUpdateNotificationProtocolMutation();
  const [deleteNotification]: DeleteNotificationProtocolMutationHookResult = useDeleteNotificationProtocolMutation();

  const [parsed, setParsed] = useState<any[]>([]);

  useEffect(() => {
    if (data && !loading) {
      const dt: any[] = [];
      data?.users?.forEach((u) => {
        dt.push({
          value: u.id,
          label: `${u.regular?.lastname || ''} ${u.regular?.firstname || ''} ${u.regular?.patronymic || ''} | ${
            u.value || ''
          }`,
        });
      });
      setParsed(dt);
    }
  }, [data, loading]);

  useEffect(() => {
    fetch({ variables: { regionId: idRegion } });
  }, [fetch, idRegion]);

  useEffect(() => {
    if (!loadingNotification && dataNotification?.notificationProtocols) {
      const u = dataNotification?.notificationProtocols?.[0]?.forNotificationROIVSpecialists?.[0];
      if (!u) return;
      reset({
        roivUser: {
          value: u?.id,
          label: `${u.regular?.lastname || ''} ${u.regular?.firstname || ''} ${u.regular?.patronymic || ''} | ${
            u.email || ''
          }`,
        },
      });
    }
  }, [dataNotification, loadingNotification]);

  const onChange = (value) => {
    const u = dataNotification?.notificationProtocols?.[0]?.forNotificationROIVSpecialists?.[0];
    if (value?.value && value?.value !== u?.id) {
      if (dataNotification?.notificationProtocols?.[0]?.id) {
        const s = u?.id ? { disconnect: { id: u?.id } } : {};
        updateNotification({
          variables: {
            id: dataNotification?.notificationProtocols?.[0]?.id,
            data: {
              forNotificationROIVSpecialists: {
                ...s,
                connect: { id: value?.value },
              },
            },
          },
        }).then(() =>
          refetch({
            dirCalendar: idCalendar,
            dirRegion: idRegion,
            dirSport: idSport,
          }),
        );
      } else {
        createNotification({
          variables: {
            data: {
              dirCalendar: { connect: { id: idCalendar } },
              dirRegion: { connect: { id: idRegion } },
              dirSport: { connect: { id: idSport } },
              forNotificationROIVSpecialists: {
                connect: { id: value?.value },
              },
            },
          },
        }).then(() =>
          refetch({
            dirCalendar: idCalendar,
            dirRegion: idRegion,
            dirSport: idSport,
          }),
        );
      }
    } else if (!value?.value && u?.id) {
      deleteNotification({
        variables: {
          id: dataNotification?.notificationProtocols?.[0]?.id,
        },
      }).then(() => {
        refetch({
          dirCalendar: idCalendar,
          dirRegion: idRegion,
          dirSport: idSport,
        });
        reset({ roivUser: {} });
      });
    }
  };

  return (
    <form style={{ width: 600 }}>
      <Select
        label="Специалист РОИВ для уведомления о добавлении протокола по Этапу 1"
        data={parsed}
        control={control}
        error={errors['roivUser']}
        name="roivUser"
        loading={loading}
        onSuperChange={(event, value) => onChange(value)}
        disabled={readonly}
      />
    </form>
  );
};

export default SearchUsersRoiv;
