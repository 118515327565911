import moment from 'moment';

export const getValidUntilDate = (category: { label: string; value: string }, date: Date) => {
  if (category.label === 'спортивный судья всероссийской категории' && date)
    return moment(date).subtract(1, 'day').add(4, 'years');
  else if (
    (category.label === 'спортивный судья первой категории' ||
      category.label === 'спортивный судья второй категории') &&
    date
  )
    return moment(date).subtract(1, 'day').add(2, 'years');
  else if (
    (category.label === 'спортивный судья третьей категории' || category.label === 'юный спортивный судья') &&
    date
  )
    return moment(date).subtract(1, 'day').add(1, 'years');
};
