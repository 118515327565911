import {
  Box,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import {
  ClassifierValue,
  DirSportAgeGroup,
  GroupTypesSportAndDisciplinesDocument,
  Scalars,
  SportTrainingStageFragment,
  TypeSportStagePrepareCreateMutationHookResult,
  TypeSportStagePrepareRemoveMutationHookResult,
  TypeSportStagePrepareUpdateMutationHookResult,
  TypeSportsDocument,
  useTypeSportStagePrepareCreateMutation,
  useTypeSportStagePrepareRemoveMutation,
  useTypeSportStagePrepareUpdateMutation,
  useTypeSportStagePreparingQuery,
} from '../../../api';
import { RanksAndTitle, StagePreparing } from '../../../module';
import React, { FC, useState } from 'react';

import AddIcon from '@material-ui/icons/Add';
import ConfirmDialog from '../../ConfirmDialog';
import EditIcon from '@material-ui/icons/Edit';
import { Input } from '../../Inputs';
import RemoveIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import getMessage from '../../../messages';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

const StagePreparingWidget: FC<{ readonly?: boolean; id: string }> = ({ readonly, id }) => {
  const { data } = useTypeSportStagePreparingQuery({ variables: { id }, fetchPolicy: 'no-cache' });
  const [deleteItem, setDeleteItem] = useState<any>(null);
  const { handleSubmit, control, errors, register, reset, formState } = useForm({
    defaultValues: {
      id: null,
    } as SportTrainingStageFragment,
  });
  const [mode, setMode] = useState<'list' | 'form'>('list');
  const [edit, setEdit] = useState<null | SportTrainingStageFragment>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [create]: TypeSportStagePrepareCreateMutationHookResult = useTypeSportStagePrepareCreateMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: TypeSportsDocument,
        data: {
          ...data?.updateDirSport,
        },
      });
    },
  });

  const [remove]: TypeSportStagePrepareRemoveMutationHookResult = useTypeSportStagePrepareRemoveMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: TypeSportsDocument,
        data: {
          ...data?.updateDirSport,
        },
      });
    },
  });

  const [update]: TypeSportStagePrepareUpdateMutationHookResult = useTypeSportStagePrepareUpdateMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: TypeSportsDocument,
        data: {
          ...data?.updateDirSportTrainingStage,
        },
      });
    },
  });

  const onRemove = async (value: string, label: string) => {
    try {
      await remove({
        variables: {
          id,
          idItem: value,
        },
      });
      enqueueSnackbar(`Половозрастная группа "${label}" успешно удалена`, { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  const onSubmit = async (values: SportTrainingStageFragment) => {
    try {
      if (edit) {
        await update({
          variables: {
            id: values?.id,
            maxWomanAge: Number(values?.maxWomanAge),
            minGroupSize: Number(values?.minGroupSize),
            maxGroupSize: Number(values?.maxGroupSize),
            minWomanAge: Number(values?.minWomanAge),
            maxManAge: Number(values?.maxManAge),
            minManAge: Number(values?.minManAge),
            idStep: values?.clsTrainingStage?.value,
            idRank: values?.clsRanksAndAwards?.map((rank) => ({ id: rank.value })),
          },
        });
        setEdit(null);
        enqueueSnackbar(`Этап подготовки "${values?.clsTrainingStage?.label}" успешно обновлен`, {
          variant: 'success',
        });
      } else {
        await create({
          variables: {
            id,
            maxWomanAge: Number(values?.maxWomanAge),
            minGroupSize: Number(values?.minGroupSize),
            maxGroupSize: Number(values?.maxGroupSize),
            minWomanAge: Number(values?.minWomanAge),
            maxManAge: Number(values?.maxManAge),
            minManAge: Number(values?.minManAge),
            idStep: values?.clsTrainingStage?.value,
            idRank: values?.clsRanksAndAwards?.map((rank) => ({ id: rank.value })),
          },
        });
        enqueueSnackbar(`Этап подготовки "${values?.clsTrainingStage?.label}" успешно добавлен`, {
          variant: 'success',
        });
      }
      reset();
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
    setMode('list');
  };

  return (
    <Grid container>
      {!readonly && !(mode === 'form' || edit) && (
        <Grid item md={12}>
          <IconButton
            onClick={() => {
              setMode('form');
              reset({ id: null });
            }}
            edge="end"
            aria-label="add"
            type="button"
          >
            <AddIcon color="primary" />
          </IconButton>
        </Grid>
      )}
      {(mode === 'form' || edit) && (
        <form onSubmit={handleSubmit(onSubmit)} style={{ minWidth: '100%' }}>
          <Grid item md={12} container spacing={2}>
            <input type="hidden" name="id" ref={register()} />
            <Grid item md={6}>
              <StagePreparing
                label="Этап подготовки"
                error={!!errors['clsTrainingStage']}
                excludeStages={data?.dirSport?.trainingStages?.map((stage) => stage?.clsTrainingStage?.value)}
                name="clsTrainingStage"
                control={control}
                rules={{ required: true }}
              />
            </Grid>
            <Grid item md={6}>
              <RanksAndTitle
                label="Разряды/звания"
                error={!!errors['clsRanksAndAwards']}
                name="clsRanksAndAwards"
                control={control}
                rules={{ required: true }}
                multiple
              />
            </Grid>
            <Grid item md={3}>
              <Input
                label="Мин. возраст у мужчин"
                type="number"
                control={control}
                error={!!errors['minManAge']}
                name="minManAge"
              />
            </Grid>
            <Grid item md={3}>
              <Input
                label="Макс. возраст у мужчин"
                type="number"
                control={control}
                error={!!errors['maxManAge']}
                name="maxManAge"
              />
            </Grid>
            <Grid item md={3}>
              <Input
                label="Мин. возраст у женщин"
                type="number"
                control={control}
                error={!!errors['minWomanAge']}
                name="minWomanAge"
              />
            </Grid>
            <Grid item md={3}>
              <Input
                label="Макс. возраст у женщин"
                type="number"
                control={control}
                error={!!errors['maxWomanAge']}
                name="maxWomanAge"
              />
            </Grid>
            <Grid item md={3}>
              <Input
                label="Мин. наполняемость группы"
                type="number"
                control={control}
                error={!!errors['minGroupSize']}
                name="minGroupSize"
              />
            </Grid>
            <Grid item md={3}>
              <Input
                label="Макс. наполняемость группы"
                type="number"
                control={control}
                error={!!errors['maxGroupSize']}
                name="maxGroupSize"
              />
            </Grid>
            <Grid item md={12} container spacing={1}>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  type="button"
                  onClick={() => {
                    setMode('list');
                    setEdit(null);
                  }}
                  size="small"
                >
                  Отмена
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  type="submit"
                  size="small"
                  startIcon={<SaveIcon color="primary" />}
                  disabled={!formState.isDirty}
                >
                  Сохранить
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
      {mode === 'list' && !edit && (
        <Grid item md={12}>
          <TableContainer component={Paper}>
            <Table stickyHeader aria-label="stage table">
              <TableHead>
                <TableRow>
                  <TableCell>Этап подготовки</TableCell>
                  <TableCell>Мин. возраст у мужчины</TableCell>
                  <TableCell>Макс. возраст у мужчины</TableCell>
                  <TableCell>Мин. возраст у женщины</TableCell>
                  <TableCell>Макс. возраст у женщины </TableCell>
                  <TableCell>Мин. наполняемость группы</TableCell>
                  <TableCell>Макс. наполняемость группы</TableCell>
                  <TableCell>Разряды/Звания</TableCell>
                  <TableCell>{``}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.dirSport?.trainingStages?.map((item) => (
                  <TableRow key={item?.id}>
                    <TableCell component="th" scope="row">
                      {item.clsTrainingStage.label}
                    </TableCell>
                    <TableCell>{item.minManAge}</TableCell>
                    <TableCell>{item.maxManAge}</TableCell>
                    <TableCell>{item.minWomanAge}</TableCell>
                    <TableCell>{item.maxWomanAge}</TableCell>
                    <TableCell>{item.minGroupSize}</TableCell>
                    <TableCell>{item.maxGroupSize}</TableCell>
                    <TableCell>{item.clsRanksAndAwards?.map((rank) => rank?.label).join(', ')}</TableCell>
                    <TableCell>
                      {!readonly && (
                        <Box display={'flex'}>
                          <IconButton
                            aria-label="редактировать"
                            size={'small'}
                            onClick={() => {
                              reset(item);
                              setEdit(item);
                            }}
                          >
                            <EditIcon color="primary" />
                          </IconButton>
                          <IconButton aria-label="редактировать" size={'small'} onClick={() => setDeleteItem(item.id)}>
                            <RemoveIcon color="error" />
                          </IconButton>
                        </Box>
                      )}
                      <ConfirmDialog
                        title={`Удаление этапа подготовки`}
                        text={`Вы уверены что хотите удалить : ${item.clsTrainingStage.label} `}
                        btnVariant="outlined"
                        open={deleteItem === item.id}
                        saveBtnText="удалить"
                        cancelBtnText="отмена"
                        saveBtnColor="secondary"
                        cancelBtnColor="primary"
                        onCancel={() => setDeleteItem(null)}
                        onSave={() => {
                          onRemove(item.id, item.clsTrainingStage?.label).then(() => setDeleteItem(null));
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
    </Grid>
  );
};

export default StagePreparingWidget;
