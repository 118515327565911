import React, { FC, useEffect } from 'react';
import { Grid, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SaveIcon from '@material-ui/icons/Save';
import { useSnackbar } from 'notistack';
import RanksAndTitle from '../../../module/RanksAndTitle';
import { useForm } from 'react-hook-form';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Input } from '../../Inputs';
import getMessage from '../../../messages';
import TeamComposition from '../../../module/TeamComposition';
import _ from 'lodash';
import {
  Scalars,
  useSportingEventQuery,
  UpdateSportingEventMutationHookResult,
  useUpdateSportingEventMutation,
  SportingEventDocument,
  ClassifierValue,
  Maybe,
} from '../../../api';
import Radios from '../../Inputs/Radio';
import CategoryOrganizations from '../../../module/CategoryOrganizations';

const useStyles = makeStyles({
  alignCenter: {
    alignItems: 'center',
  },
  title: {
    fontWeight: 500,
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  tinyIcon: {
    fontSize: 18,
  },
  icon: {
    fontSize: 20,
  },
});

interface formState {
  athletesMan?: Scalars['Int'] | null;
  athletesWoman?: Scalars['Int'] | null;
  trainers?: Scalars['Int'] | null;
  referees?: Scalars['Int'] | null;
  administration?: Scalars['Int'] | null;
  medicalStaff?: Scalars['Int'] | null;
  specialists?: Scalars['Int'] | null;
  teams?: Scalars['Int'] | null;
  clsTeamComposition?: any;
  participantAthletesTotal: Scalars['Int'] | null;
  refereesTotal: Scalars['Int'] | null;
  staffTotal: Scalars['Int'] | null;
  audienceTotal: Scalars['Int'] | null;
  isSportingQualificationRangeOrSpecificValues?: string;
  clsAvailableSportingQualifications: Maybe<Array<ClassifierValue>>;
  clsMinRang: any;
  clsSportingQualificationNotGreater: any;
  clsParticipatingOrgCategories: Maybe<Array<ClassifierValue>>;
}

const RulesFull: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const classes = useStyles();

  const { loading, data } = useSportingEventQuery({ fetchPolicy: 'no-cache', variables: { id } });
  const [update]: UpdateSportingEventMutationHookResult = useUpdateSportingEventMutation({
    update: (proxy, { data }) => {
      proxy.writeQuery({
        query: SportingEventDocument,
        data: {
          ...data?.updateDirSportingEvent,
        },
      });
    },
  });

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });
  const { handleSubmit, register, control, errors, watch, reset, formState } = useForm({
    defaultValues: {
      ...data?.dirSportingEvent,
      isSportingQualificationRangeOrSpecificValues:
        data?.dirSportingEvent?.isSportingQualificationRangeOrSpecificValues === undefined
          ? 'false'
          : data?.dirSportingEvent?.isSportingQualificationRangeOrSpecificValues
          ? 'true'
          : 'false',
    } as formState,
  });
  let isSportingQualificationRangeOrSpecificValues = watch('isSportingQualificationRangeOrSpecificValues');
  const onSubmit = async (values: formState) => {
    const {
      athletesMan,
      athletesWoman,
      trainers,
      referees,
      administration,
      medicalStaff,
      specialists,
      teams,
      clsTeamComposition,
      participantAthletesTotal,
      refereesTotal,
      staffTotal,
      audienceTotal,
      isSportingQualificationRangeOrSpecificValues,
      clsParticipatingOrgCategories,
      clsMinRang,
      clsSportingQualificationNotGreater,
    } = values;
    const clsTeamCompositionValue =
      (values.clsTeamComposition && {
        connect: {
          id: _.get(values, 'clsTeamComposition.value'),
        },
      }) ||
      (data?.dirSportingEvent?.quota?.clsTeamComposition?.value && {
        connect: {
          id: data?.dirSportingEvent?.quota?.clsTeamComposition?.value,
        },
      });
    try {
      await update({
        variables: {
          data: {
            isSportingQualificationRangeOrSpecificValues:
              isSportingQualificationRangeOrSpecificValues + '' === 'false' ? false : true,
            clsMinRang: clsMinRang?.value && {
              connect: {
                id: clsMinRang?.value,
              },
            },
            clsSportingQualificationNotGreater: clsSportingQualificationNotGreater?.value && {
              connect: {
                id: clsSportingQualificationNotGreater?.value,
              },
            },
            clsAvailableSportingQualifications: {
              set: values?.clsAvailableSportingQualifications?.map((v) => ({ id: _.get(v, 'value') })),
            },
            quantity: {
              [data?.dirSportingEvent?.quantity?.id != undefined ? 'update' : 'create']: {
                ...(participantAthletesTotal && { participantAthletesTotal: Number(participantAthletesTotal) || 0 }),
                ...(refereesTotal && { refereesTotal: Number(refereesTotal) || 0 }),
                ...(staffTotal && { staffTotal: Number(staffTotal) || 0 }),
                ...(audienceTotal && { audienceTotal: Number(audienceTotal) || 0 }),
              },
            },
            quota: {
              [data?.dirSportingEvent?.quantity?.id != undefined ? 'update' : 'create']: {
                clsTeamComposition: clsTeamCompositionValue,
                ...(athletesMan && { athletesMan: Number(athletesMan) || 0 }),
                ...(athletesWoman && { athletesWoman: Number(athletesWoman) || 0 }),
                ...(trainers && { trainers: Number(trainers) || 0 }),
                ...(referees && { referees: Number(referees) || 0 }),
                ...(administration && { administration: Number(administration) || 0 }),
                ...(medicalStaff && { medicalStaff: Number(medicalStaff) || 0 }),
                ...(specialists && { specialists: Number(specialists) || 0 }),
                ...(teams && { teams: Number(teams) || 0 }),
              },
            },
          },
          id,
        },
      });
      if (values?.clsParticipatingOrgCategories != undefined && values?.clsParticipatingOrgCategories.length > 0) {
        await update({
          variables: {
            data: {
              quota: {
                [data?.dirSportingEvent?.quantity?.id != undefined ? 'update' : 'create']: {
                  clsParticipatingOrgCategories: {
                    set: values?.clsParticipatingOrgCategories?.map((v) => ({ id: _.get(v, 'value') })),
                  },
                },
              },
            },
            id,
          },
        });
      }
      handleSnackBar('success', 'Требование успешно обновлена');
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  useEffect(() => {
    reset({
      ...data?.dirSportingEvent?.quota,
      ...data?.dirSportingEvent?.quantity,
      clsMinRang: data?.dirSportingEvent?.clsMinRang,
      clsAvailableSportingQualifications: data?.dirSportingEvent?.clsAvailableSportingQualifications,
      clsSportingQualificationNotGreater: data?.dirSportingEvent?.clsSportingQualificationNotGreater,
      isSportingQualificationRangeOrSpecificValues:
        data?.dirSportingEvent?.isSportingQualificationRangeOrSpecificValues === undefined
          ? 'false'
          : data?.dirSportingEvent?.isSportingQualificationRangeOrSpecificValues
          ? 'true'
          : 'false',
    });
  }, [data, reset]);

  if (loading) return <LinearProgress />;

  return (
    <div style={{ paddingTop: '2rem' }}>
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
        <Grid container spacing={2} className={classes.alignCenter}>
          <Grid item lg={6} md={6} xs={12}>
            <h2>Количественные показатели:</h2>
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.alignCenter}>
          <Grid item lg={6} md={6} xs={12}>
            <Input
              label="Кол-во участников-спортсменов"
              control={control}
              error={!!errors['participantAthletesTotal']}
              name="participantAthletesTotal"
              type="number"
              disabled={readonly}
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Input
              label="Кол-во судей"
              control={control}
              error={!!errors['refereesTotal']}
              name="refereesTotal"
              type="number"
              disabled={readonly}
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Input
              label="Кол-во обслуж. персонала"
              control={control}
              error={!!errors['staffTotal']}
              name="staffTotal"
              type="number"
              disabled={readonly}
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Input
              label="Кол-во организаторов итого"
              control={control}
              error={!!errors['refereesTotal']}
              name="еуьз"
              type="number"
              disabled={readonly}
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Input
              label="Кол-во зрителей"
              control={control}
              error={!!errors['audienceTotal']}
              name="audienceTotal"
              type="number"
              disabled={readonly}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.alignCenter}>
          <Grid item lg={6} md={6} xs={12}>
            <h2>Требования к составу участников:</h2>
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.alignCenter}>
          <Grid item lg={12} md={12} xs={12}>
            <Grid item md={12}>
              <CategoryOrganizations
                name="clsParticipatingOrgCategories"
                multiple
                label="Категории организаций"
                error={!!errors['clsParticipatingOrgCategories']}
                control={control}
                rules={{
                  required: true,
                }}
                disabled={readonly}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.alignCenter}>
          <Grid item lg={6} md={6} xs={12}>
            <h3>Состав сборной команды:</h3>
          </Grid>
        </Grid>
        <Grid container spacing={2} className={classes.alignCenter}>
          <Grid item lg={6} md={6} xs={12}>
            <Input
              label="Возраст спортсменов муж."
              control={control}
              error={!!errors['athletesMan']}
              name="athletesMan"
              type="number"
              disabled={readonly}
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Input
              label="Возраст спортсменов жен."
              control={control}
              error={!!errors['athletesWoman']}
              name="athletesWoman"
              disabled={readonly}
              type="number"
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Input
              label="Тренеры"
              control={control}
              error={!!errors['trainers']}
              name="trainers"
              disabled={readonly}
              type="number"
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Input
              label="Судьи"
              control={control}
              error={!!errors['referees']}
              name="referees"
              disabled={readonly}
              type="number"
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Input
              label="Администрация"
              control={control}
              error={!!errors['administration']}
              name="administration"
              disabled={readonly}
              type="number"
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Input
              label="Медперсонал"
              control={control}
              error={!!errors['medicalStaff']}
              name="medicalStaff"
              disabled={readonly}
              type="number"
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Input
              label="Специалисты"
              control={control}
              error={!!errors['specialists']}
              name="specialists"
              type="number"
              disabled={readonly}
            />
          </Grid>
          <Grid item lg={6} md={6} xs={12}>
            <Input
              label="Команды"
              control={control}
              error={!!errors['teams']}
              name="teams"
              disabled={readonly}
              type="number"
            />
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <Radios
              label="Допустимая квалификация"
              data={[
                {
                  value: 'false',
                  label: 'Диапазон',
                },
                {
                  value: 'true',
                  label: 'Конкретные значения',
                },
              ]}
              control={control}
              error={!!errors['isSportingQualificationRangeOrSpecificValues']}
              name="isSportingQualificationRangeOrSpecificValues"
              rules={{ required: false }}
              disabled={readonly}
            />
          </Grid>
          {isSportingQualificationRangeOrSpecificValues == 'true' && (
            <Grid item lg={12} md={12} xs={12}>
              <RanksAndTitle
                label="Разряд и звание"
                error={!!errors['clsAvailableSportingQualifications']}
                name="clsAvailableSportingQualifications"
                multiple={true}
                control={control}
                rules={{ required: false }}
                disabled={readonly}
              />
            </Grid>
          )}
          {isSportingQualificationRangeOrSpecificValues == 'false' && (
            <Grid item lg={6} md={6} xs={12}>
              <RanksAndTitle
                label="Разряд и звание от"
                error={!!errors['clsMinRang']}
                name="clsMinRang"
                multiple={false}
                control={control}
                rules={{ required: false }}
                disabled={readonly}
              />
            </Grid>
          )}
          {isSportingQualificationRangeOrSpecificValues == 'false' && (
            <Grid item lg={6} md={6} xs={12}>
              <RanksAndTitle
                label="До"
                error={!!errors['clsSportingQualificationNotGreater']}
                name="clsSportingQualificationNotGreater"
                multiple={false}
                control={control}
                rules={{ required: false }}
                disabled={readonly}
              />
            </Grid>
          )}
          <Grid item container lg={6} md={6} xs={12} justify="flex-end">
            <Button
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<SaveIcon />}
              type="submit"
              disabled={!formState.isDirty}
            >
              Сохранить
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default RulesFull;
