import { Box, Button, Grid } from '@material-ui/core';
import React, { FC, useEffect, useState } from 'react';
import {
  useGeneratePersonsXlsFileLazyQuery,
  usePersonSimilarCountLazyQuery,
  usePersonsCountLazyQuery,
} from '../../api';

import ClearIcon from '@material-ui/icons/Clear';
import ContentWrapper from '../layouts/ContentWrapper/ContentWrapper';
import { Count } from '../Count';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Input } from '../Inputs';
import { PersonsFilterAdditional } from './index';
import SearchIcon from '@material-ui/icons/Search';
import TuneIcon from '@material-ui/icons/Tune';
import { makeStyles } from '@material-ui/core/styles';
import { onSetPersonFilter } from './helpers';
import { useForm } from 'react-hook-form';
import useGenerateXlsx from '../../hooks/useGenerateXlsx';

const useStyle = makeStyles((theme) => ({
  btn: {
    marginRight: theme.spacing(2),
  },
}));

const Persons: FC<{
  onSubmit?: (T: any, E: any) => void;
  value?: any;
  defaultFilter?: any;
  first: number;
}> = ({ onSubmit = () => {}, value = {}, defaultFilter = {}, first }) => {
  const style = useStyle();
  const { handleSubmit, control, errors, reset, watch, formState, setValue, getValues } = useForm({
    defaultValues: value,
  });
  const [additional, setAdditional] = useState<boolean>(Object.keys(value)?.length > 0);
  const [filter, setFilter] = useState<any>({
    ...defaultFilter,
    archive: null,
  });

  const { onDownloadFile } = useGenerateXlsx({
    lazyQuery: useGeneratePersonsXlsFileLazyQuery,
    getValues,
    setFilter: onSetPersonFilter,
    fileName: 'persons',
    first,
  });

  const handleSearchBox = (value: any) => {
    const filter: any = onSetPersonFilter(value);
    setFilter(filter);
    onSubmit(filter, getValues());
  };

  return (
    <ContentWrapper>
      <form
        onSubmit={handleSubmit(handleSearchBox)}
        onReset={(e?) => {
          setValue('registryNumber', '');
          reset();
          setFilter({
            archive: null,
          });
          onSubmit(
            {
              archive: null,
            },
            {},
          );
        }}
      >
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <Input
              label="Идентификатор персоны"
              control={control}
              error={!!errors['registryNumber']}
              name="registryNumber"
            />
          </Grid>
          <Grid item xs={3}>
            <Input label="фамилия имя отчество" control={control} error={!!errors['name']} name="name" />
          </Grid>
          {additional && <PersonsFilterAdditional watch={watch} control={control} errors={errors} />}
          <Grid item container alignItems={'center'}>
            <Grid item>
              <Button
                variant="outlined"
                className={style.btn}
                color="primary"
                type="reset"
                onClick={() =>
                  reset({
                    registryNumber: '',
                    name: '',
                    previousLastname: '',
                    email: '',
                    dirRegion: null,
                    dirCountry: null,
                    dirForeignCity: null,
                    clsStatusPerson: null,
                    isRussia: null,
                    isValid: null,
                    isDuplicated: null,
                    archive: { value: false, label: 'Нет' },
                    birthday_gte: null,
                    birthday_lte: null,
                  })
                }
                size="small"
                startIcon={<ClearIcon />}
              >
                Сброс
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                type="submit"
                size="small"
                className={style.btn}
                startIcon={<SearchIcon />}
                disabled={!formState.isDirty}
              >
                Поиск
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => setAdditional(!additional)}
                type="button"
                className={style.btn}
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<TuneIcon />}
              >
                Фильтр
              </Button>
            </Grid>
            <Box pl={4}>
              <Button
                variant="outlined"
                color="primary"
                disabled={!formState.isDirty}
                onClick={onDownloadFile}
                size={'small'}
                startIcon={<GetAppIcon />}
              >
                XLS
              </Button>
              <Count
                key={JSON.stringify(filter)}
                hasSimilarQuery
                query={filter?.name ? usePersonSimilarCountLazyQuery : usePersonsCountLazyQuery}
                filter={filter}
              />
            </Box>
          </Grid>
        </Grid>
      </form>
    </ContentWrapper>
  );
};

export default Persons;
