import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import AddressBox from '../Shared/AddressBox';
import ContactInfo from './ContactInfo';

const AdditionalInfo: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  return (
    <>
      <Grid container>
        {/*<Grid item container style={{ marginBottom: 15 }}>*/}
        {/*  <SnilsBox id={id || ''} readonly={readonly} />*/}
        {/*</Grid>*/}

        <Grid item xs={12}>
          <ContactInfo id={id || ''} readonly={readonly} />
        </Grid>
        <Grid item xs={12}>
          <AddressBox id={id || ''} person readonly={readonly} />
        </Grid>
      </Grid>
    </>
  );
};

export default AdditionalInfo;
