import React, { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { Input } from '../../../../Inputs';
import SaveIcon from '@material-ui/icons/Save';
import { useForm } from 'react-hook-form';
import {
  CreateQuoteByRegionMutationHookResult,
  UpdateQuoteByRegionMutationHookResult,
  useCreateQuoteByRegionMutation,
  useUpdateQuoteByRegionMutation,
} from '../../../../../api';

export const RequestApplicationModalQuotesRegion = ({
  idRegion,
  idCalendar,
  open,
  sportName,
  onClose,
  idSport,
  row,
  onUpdate,
  listSportsValue,
}: any) => {
  const { regionQuotaAccompStageTwo = '', regionQuotaWomenAthlStageTwo = '', regionQuotaMaleAthlStageTwo = '' } =
    row?.form || {};
  const { handleSubmit, control, errors, reset } = useForm({
    defaultValues: {
      regionQuotaAccompStageTwo,
      regionQuotaWomenAthlStageTwo,
      regionQuotaMaleAthlStageTwo,
    },
  });

  const [create]: CreateQuoteByRegionMutationHookResult = useCreateQuoteByRegionMutation();
  const [update]: UpdateQuoteByRegionMutationHookResult = useUpdateQuoteByRegionMutation();

  useEffect(() => {
    reset({
      regionQuotaAccompStageTwo,
      regionQuotaWomenAthlStageTwo,
      regionQuotaMaleAthlStageTwo,
    });
  }, [regionQuotaAccompStageTwo, regionQuotaWomenAthlStageTwo, regionQuotaMaleAthlStageTwo, reset]);
  const limit = listSportsValue.find((item) => {
    return item.id === idSport;
  });
  const handleClose = () => {
    onClose?.();
    return;
  };
  const onSubmit = async ({ regionQuotaAccompStageTwo, regionQuotaWomenAthlStageTwo, regionQuotaMaleAthlStageTwo }) => {
    if (row?.form?.id) {
      await update({
        variables: {
          id: row?.form?.id,
          data: {
            regionQuotaAccompStageTwo: parseInt(regionQuotaAccompStageTwo),
            regionQuotaWomenAthlStageTwo: parseInt(regionQuotaWomenAthlStageTwo),
            regionQuotaMaleAthlStageTwo: parseInt(regionQuotaMaleAthlStageTwo),
          },
        },
      });
    } else {
      await create({
        variables: {
          data: {
            dirCalendar: {
              connect: { id: idCalendar },
            },
            dirRegion: {
              connect: { id: idRegion },
            },
            regionQuotaAccompStageTwo: parseInt(regionQuotaAccompStageTwo),
            regionQuotaWomenAthlStageTwo: parseInt(regionQuotaWomenAthlStageTwo),
            regionQuotaMaleAthlStageTwo: parseInt(regionQuotaMaleAthlStageTwo),
            dirSport: { connect: { id: idSport } },
          },
        },
      });
    }
    onUpdate?.();
    onClose?.();
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Квоты по виду спорта: {sportName}</DialogTitle>
      <DialogContent style={{ width: 400 }}>
        <Grid container direction="column" spacing={1}>
          <Grid item container>
            <Input
              label="Квота по спорсменам Муж"
              error={!!errors['regionQuotaMaleAthlStageTwo']}
              name="regionQuotaMaleAthlStageTwo"
              control={control}
              rules={{ max: limit?.form?.quotaMaleAthlStageTwo || 0 }}
            />
          </Grid>
          <Grid item container>
            <Input
              label="Квота по спорсменам Жен"
              error={!!errors['regionQuotaWomenAthlStageTwo']}
              name="regionQuotaWomenAthlStageTwo"
              control={control}
              rules={{ max: limit?.form?.quotaWomenAthlStageTwo || 0 }}
            />
          </Grid>
          <Grid item container>
            <Input
              label="Квота по сопровождающим"
              error={!!errors['regionQuotaAccompStageTwo']}
              name="regionQuotaAccompStageTwo"
              control={control}
              rules={{ max: limit?.form?.quotaAccompStageTwo || 0 }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={handleClose}>
          Отменить
        </Button>
        <Button
          size="small"
          variant="outlined"
          onClick={handleSubmit(onSubmit)}
          color="primary"
          startIcon={<SaveIcon />}
        >
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  );
};
