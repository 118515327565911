import React, { FC, Fragment, useState } from 'react';
import {
  IconButton,
  Tooltip,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Link,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import AddBoxIcon from '@material-ui/icons/AddBox';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import getMessage from '../../../../messages';
import OrgForm from './OrgForm';
import FireTrainer from '../../../FireBox/FireTrainer';
import {
  Scalars,
  CreateTrainerOrganizationMutationHookResult,
  useCreateTrainerOrganizationMutation,
  UpdateTrainerOrganizationMutationHookResult,
  useUpdateTrainerOrganizationMutation,
} from '../../../../api';

import moment from 'moment';
import _ from 'lodash';

const useStyles = makeStyles({
  row: {
    display: 'flex',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  tinyIcon: {
    fontSize: 18,
  },
  icon: {
    fontSize: 20,
  },
  tableHead: {
    fontWeight: 'bold',
  },
  header: {
    fontWeight: 600,
    fontSize: 15,
    letterSpacing: 1.2,
  },
});

interface formState {
  organization?: { label: string; value: any };
  clsPosition?: { label: any; value: Scalars['UUID'] };
  employmentDate?: Date;
  dismissalDate?: Date;
  orgId?: Scalars['UUID'];
}

const OrgAccordion: FC<{
  id?: string;
  readonly?: boolean;
  sport: any;
  onUpdate: Function;
}> = ({ id, readonly, sport, onUpdate }) => {
  const classes = useStyles();

  const [action, setAction] = useState('');
  const [orgId, setOrgId] = useState('');
  const [oldPosition, setOldPosition] = useState('');
  const [modalFire, setModalFire] = useState(false);
  const [defaultFormValue, setDefaultFormValue] = useState<any>(null);

  const [add]: CreateTrainerOrganizationMutationHookResult = useCreateTrainerOrganizationMutation();
  const [update]: UpdateTrainerOrganizationMutationHookResult = useUpdateTrainerOrganizationMutation();

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const updateDefaultFormValue = (org: any) =>
    setDefaultFormValue({
      clsPosition: {
        ...org?.clsPosition,
      },
      organization: {
        ...org?.dirOrganization,
      },
      employmentDate: org?.employmentDate,
      dismissalDate: org?.dismissalDate,
    });

  const onAdd = async (values: formState, idSport: Scalars['UUID']) => {
    const { organization, clsPosition, employmentDate } = values;

    try {
      await add({
        variables: {
          id: idSport,
          idDirOrganization: organization?.value,
          employmentDate,
          clsPosition: clsPosition?.value,
        },
      });
      onUpdate();
      handleSnackBar('success', 'организация успешно добавлено');
      setAction('');
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const onEdit = async (values: formState, idSport: Scalars['UUID']) => {
    const { organization, clsPosition, employmentDate, dismissalDate } = values;

    try {
      await update({
        variables: {
          id: orgId,
          idDirOrganization: organization?.value,
          clsPosition: oldPosition,
          employmentDate,
          dismissalDate,
        },
      });
      if (action === 'transfer') {
        await add({
          variables: {
            id: idSport,
            idDirOrganization: organization?.value,
            employmentDate: dismissalDate,
            clsPosition: clsPosition?.value,
          },
        });
      }
      onUpdate();
      handleSnackBar('success', 'организация успешно обновлено');
      setOrgId('');
      setAction('');
      setOldPosition('');
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const onFireTrainer = async () => {
    onUpdate();
    setModalFire(false);
    enqueueSnackbar(`Тренер  успешно уволен`, { variant: 'success' });
  };

  return (
    <Accordion key={sport.id}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${sport.dirSport?.label}-content`}
        id={`${sport.dirSport?.label}-header`}
      >
        <Typography className={classes.header} color="primary">
          {sport.dirSport.label}
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: 'column' }}>
        {action && (
          <OrgForm
            defaultValue={defaultFormValue}
            sport={sport}
            action={action}
            onAdd={(values: formState) => onAdd(values, sport.id)}
            onEdit={(values: formState) => onEdit(values, sport.id)}
            onCancel={() => {
              setAction('');
              setDefaultFormValue(null);
            }}
          />
        )}

        <TableContainer component={Paper}>
          <Table aria-label={`${sport.dirSport?.label} table`}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHead}>
                  {action !== 'add' && !readonly && (
                    <Tooltip title="добавить" placement="top-start">
                      <IconButton className={classes.btn} color="primary" onClick={() => setAction('add')}>
                        <AddBoxIcon className={classes.icon} />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell className={classes.tableHead}>Должность</TableCell>
                <TableCell className={classes.tableHead}>Дата найма</TableCell>
                <TableCell className={classes.tableHead}>Дата увольнения</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sport.organizations &&
                sport.organizations.length > 0 &&
                _.map(_.values(_.groupBy(sport.organizations, (o) => o.dirOrganization.label)), (v) =>
                  v.map((org, index) => (
                    <TableRow key={org.dirOrganization && org.dirOrganization.value + index}>
                      {index === 0 ? (
                        <TableCell component="th" scope="row">
                          {readonly ? (
                            <span>{org.dirOrganization.label}</span>
                          ) : (
                            <Link
                              style={{ fontWeight: 500, cursor: 'pointer' }}
                              target="_blank"
                              href={`/organization/${org.dirOrganization.value}`}
                            >
                              {org.dirOrganization.label}
                            </Link>
                          )}
                        </TableCell>
                      ) : (
                        <TableCell component="th" scope="row"></TableCell>
                      )}

                      <TableCell>{org.clsPosition.label && org.clsPosition.label}</TableCell>

                      <TableCell>{org.employmentDate && moment(org.employmentDate).format('DD.MM.YYYY')}</TableCell>
                      <TableCell>{org.dismissalDate && moment(org.dismissalDate).format('DD.MM.YYYY')}</TableCell>

                      {
                        <TableCell>
                          <div className={classes.row}>
                            {!org.dismissalDate && !readonly && action !== 'edit' && (
                              <Fragment>
                                <Button
                                  size="small"
                                  color="primary"
                                  variant="contained"
                                  style={{ marginRight: 10 }}
                                  onClick={() => {
                                    updateDefaultFormValue(org);
                                    setAction('transfer');
                                    setOrgId(org.id);
                                    setOldPosition(org.clsPosition.value);
                                  }}
                                >
                                  Перевод
                                </Button>
                                <Tooltip title="удалить" placement="top-start">
                                  <Button
                                    className={classes.btn}
                                    color="secondary"
                                    variant="contained"
                                    style={{ marginRight: 10 }}
                                    onClick={() => {
                                      setOrgId(org.id);
                                      setModalFire(true);
                                    }}
                                  >
                                    <ClearIcon className={classes.icon} />
                                  </Button>
                                </Tooltip>
                              </Fragment>
                            )}

                            {modalFire && (
                              <FireTrainer
                                sportOrgId={orgId}
                                orgId={org.dirOrganization.value}
                                onSubmit={onFireTrainer}
                                trainerId={id as string}
                                idSport={sport.dirSport.value}
                                onClose={() => setModalFire(false)}
                              />
                            )}

                            {action !== 'edit' && !readonly && (
                              <Tooltip title="изменить" placement="top-start">
                                <Button
                                  className={classes.btn}
                                  color="primary"
                                  variant="contained"
                                  onClick={() => {
                                    updateDefaultFormValue(org);
                                    setOrgId(org.id);
                                    setOldPosition(org.clsPosition.value);
                                    setAction('edit');
                                  }}
                                >
                                  <EditIcon className={classes.icon} />
                                </Button>
                              </Tooltip>
                            )}
                          </div>
                        </TableCell>
                      }
                    </TableRow>
                  )),
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default OrgAccordion;
