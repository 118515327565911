import {
  ClassifierValue,
  DirAthleteSport,
  DirAthleteSportOrganization,
  DirAthleteSportRankAndAward,
  DirNationalTeamAthlete,
  DirSportDiscipline,
} from '../../../../api';

import _ from 'lodash';
import moment from 'moment';

export const revealAthletesInfo = (athlete: DirNationalTeamAthlete, group: any) => {
  const foundResults = athlete?.dirAthlete?.competetionResult?.map((item, index) => ({
    id: item.id,
    age: `${item.sportingEventProgramType.minAge || '~'} - ${item.sportingEventProgramType.maxAge || '~'}`,
    disciplineName: item.sportingEventProgramType?.discipline.name,
    start: item.sportingEventProgramType.start,
    point: item.point,
    result: item.result,
    bar: index < group?.disciplineGroups?.length - 1 && ' | ',
    noResultReason: item.noResultReason,
  }));
  // ?.map((disciplineGroup: DirSportDisciplineGroup & { label: string; value: Scalars['UUID'] }, index: number) => {
  //   const foundResult = athlete?.dirAthlete?.competetionResult?.find((result: DirAthleteCompetitionResult) =>
  //     disciplineGroup?.disciplines?.filter(
  //       (discipline) => result.sportingEventProgramType.discipline.id === discipline?.id,
  //     ),
  //   );
  //   return foundResult
  //     ? {
  //         id: foundResult.id,
  //         age: `${foundResult.sportingEventProgramType.minAge || '~'} - ${
  //           foundResult.sportingEventProgramType.maxAge || '~'
  //         }`,
  //         disciplineName: disciplineGroup.label,
  //         start: foundResult.sportingEventProgramType.start,
  //         point: foundResult.point,
  //         result: foundResult.result,
  //         bar: index < group?.disciplineGroups?.length - 1 && ' | ',
  //       }
  //     : undefined;
  // })
  // .filter((n: any) => n);

  return {
    ...athlete,
    name: `${athlete?.dirAthlete?.dirPerson?.lastname} ${athlete?.dirAthlete?.dirPerson?.firstname} ${
      athlete?.dirAthlete?.dirPerson?.patronymic || ''
    } `,
    registryNumber: athlete?.dirAthlete?.dirPerson?.registryNumber,
    birthday: moment(athlete?.dirAthlete?.dirPerson?.birthday).format('DD.MM.YY'),
    b_y: athlete?.dirAthlete?.dirPerson?.birthday,
    rankAndAwards: athlete?.dirAthlete?.sports
      ?.filter((s: DirAthleteSport) => s?.dirSport?.id === group?.disciplineGroups[0]?.dirSport.id)[0]
      ?.ranksAndAwards?.filter((r: DirAthleteSportRankAndAward) =>
        r.validUntil ? moment().isBefore(moment(r.validUntil)) : r,
      )
      .map((r: DirAthleteSportRankAndAward) => r.clsRankAndAward.fullName)
      .join(', '),
    organizations: _.uniqBy(
      athlete?.dirAthlete?.sports?.filter(
        (s: DirAthleteSport) => s?.dirSport?.id === group?.disciplineGroups[0]?.dirSport.id,
      )[0]?.organizations,
      (org: DirAthleteSportOrganization) => org.dirOrganization.id,
    ),
    isMale: athlete?.dirAthlete?.dirPerson.isMale === true,
    results: foundResults,
  };
};

export const revealGroups = (groupArray: Array<any>, dateStartEvent?: string) => ({
  bannedAthletesFromSearch:
    _.flattenDeep(
      groupArray?.map((group: any) =>
        group?.athletes?.map((athlete: DirNationalTeamAthlete) => athlete?.dirAthlete?.id),
      ),
    ) || [],
  array: groupArray?.map((group) => ({
    ...group,
    title: `${group?.disciplineGroups[0]?.dirSport.fullName || ''} [ 
                ${
                  (group?.disciplineGroups?.length > 0 &&
                    `(${group?.disciplineGroups
                      ?.map((disciplineGroup: DirSportDiscipline & { label: string }) => disciplineGroup.label)
                      .join(' , ')}) ;`) ||
                  ''
                } 
                ${
                  (group?.clsAgeGroups?.length > 0 &&
                    `(${group?.clsAgeGroups?.map((age: ClassifierValue) => age.fullName).join(' , ')}) `) ||
                  ''
                }]: ${group?.athletes?.length || 0} `,
    ageGroup:
      (group?.clsAgeGroups?.length > 0 &&
        group?.clsAgeGroups?.map((age: ClassifierValue) => age.fullName).join(' , ')) ||
      '',
    athletesInfo: _.orderBy(
      group.athletes.map((athlete: DirNationalTeamAthlete) => revealAthletesInfo(athlete, group)),
      ['dirAthlete.dirPerson.lastname'],
      ['asc'],
    ),
    maxBday: moment(dateStartEvent).subtract(group.maxAge, 'year').subtract(1, 'year').endOf('day'),
    minBday: moment(dateStartEvent).subtract(group.minAge, 'year').startOf('day'),
    sportId: group?.disciplineGroups[0]?.dirSport.id,
  })),
});

export const setAthleteYearInfo = (
  minManBirthYear: any,
  maxManBirthYear: any,
  minWomanBirthYear: any,
  maxWomanBirthYear: any,
) => {
  const getInfo = (genre: string, minYear: any, maxYear: any) => {
    if (maxYear && minYear) {
      return `Год рождения спортсменов ${genre}: от ${minYear} до ${maxYear}`;
    } else if (maxYear && !minYear) {
      return `Год рождения спортсменов ${genre}: до ${maxYear}`;
    } else if (minYear && !maxYear) {
      return `Год рождения спортсменов ${genre}: от ${minYear}`;
    } else if (!(maxYear && minYear)) {
      return `Год рождения спортсменов ${genre}: не участвуют`;
    }
  };

  return {
    manYearInfo: getInfo('муж', minManBirthYear, maxManBirthYear),
    womanYearInfo: getInfo('жен', minWomanBirthYear, maxWomanBirthYear),
  };
};

export const checkDate = (date: string | number, minYear = 0, maxYear = 0) => {
  const d = date ? Number(moment(date).format('YYYY')) : 0;
  if (!date || (!minYear && !maxYear)) return false;
  // console.log({ date, minYear, maxYear, d, bool: d <= minYear });
  if (minYear && maxYear) return !(d >= minYear && d <= maxYear);
  if (minYear && !maxYear) return d <= minYear;
  if (maxYear && !minYear) return d >= maxYear;
};
