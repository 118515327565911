import _ from 'lodash';
import { TeamInfoFragmentFragment } from './../../../../api/index';
export const teamCount = (team: TeamInfoFragmentFragment) => {
  const countMen = _.get(team, 'athleteGroups', []).reduce(
    (acc: number, current: any) =>
      acc +
      _.get(current, 'athletes', []).filter(
        (athlete) => athlete?.entranceStatus !== false && _.get(athlete, 'dirAthlete.dirPerson.isMale') === true,
      ).length,
    0,
  );

  const countWomen = _.get(team, 'athleteGroups', []).reduce(
    (acc: number, current: any) =>
      acc +
      _.get(current, 'athletes', []).filter(
        (athlete) => athlete?.entranceStatus !== false && _.get(athlete, 'dirAthlete.dirPerson.isMale') === false,
      ).length,
    0,
  );

  const specialistsCount = _.get(team, 'specialists', []).filter((specialist) => specialist?.entranceStatus !== false)
    .length;

  const medicsCount = _.get(team, 'medics', []).filter((medic) => medic?.entranceStatus !== false).length;

  const delegationHeadsCount = _.get(team, 'delegationHeads', []).filter(
    (delegationHead) => delegationHead?.entranceStatus !== false,
  ).length;

  const trainersCount = _.get(team, 'trainers', []).filter((trainer) => trainer?.entranceStatus !== false).length;

  return {
    countMen,
    countWomen,
    specialistsCount,
    medicsCount,
    delegationHeadsCount,
    trainersCount,
    supportTotal: specialistsCount + medicsCount + delegationHeadsCount + trainersCount,
    total: countMen + countWomen + specialistsCount + medicsCount + delegationHeadsCount + trainersCount,
  };
};
