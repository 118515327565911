import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Typography,
} from '@material-ui/core';
import {
  CreateEventStatusMutationHookResult,
  RefeeresEventFragmentFragment,
  Scalars,
  UploadFileMutationHookResult,
  useCreateEventRefereeStatusMutation,
  useRefereeStatusesExtendedQuery,
  useUploadFileMutation,
} from '../../../../api';
import React, { FC, useMemo, useState } from 'react';

import StatusBoard from './StatusBoard';
import StatusForm from './StatusForm';
import getMessage from '../../../../messages';
import { useSnackbar } from 'notistack';
import userRights from '../../../../config/userRights';

interface formState {
  status?: { label: string; value: Scalars['UUID'] };
  comment?: string;
  file?: File | { id: Scalars['UUID']; name: string; path: string };
}
interface EventInfo {
  classes?: any;
  statusUpdated: Function;
  idEvent: RefeeresEventFragmentFragment['id'];
  statuses: RefeeresEventFragmentFragment['statuses'];
  refereeStatuses: RefeeresEventFragmentFragment['refereeStatuses'];
  readOnly?: boolean;
}

const RefereeStatus: FC<EventInfo> = ({
  classes,
  statuses,
  idEvent,
  refereeStatuses,
  statusUpdated,
  readOnly: read_only,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { canChangeSportingEventStatus, isRoiv, hasSportEventLimAccess } = userRights();

  const readonly = isRoiv || (read_only && !canChangeSportingEventStatus && !hasSportEventLimAccess);

  const { data: statusData } = useRefereeStatusesExtendedQuery();

  const status = useMemo(() => (statuses && refereeStatuses?.length ? refereeStatuses[0] : null), [
    refereeStatuses,
    statuses,
  ]);
  const DOWNLOAD_LINK = process.env.REACT_APP_BACKEND_URI?.replace('/graphql', '');
  const [newStatus, setNewStatus] = useState<
    | ({ __typename?: 'DirSportingEventRefereeStatusValue' | undefined } & { value: number; label: string })
    | null
    | undefined
  >(null);

  const [uploadDocumentFile]: UploadFileMutationHookResult = useUploadFileMutation();
  const [create]: CreateEventStatusMutationHookResult = useCreateEventRefereeStatusMutation();

  const handleSetNewStatus = (statusId: number) => {
    const valueArray = statusData?.dirSportingEventRefereeStatusValues;
    const nStatus = valueArray?.find((s) => s?.value === statusId);
    setNewStatus(nStatus);
  };

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const onCreate = async (values: formState) => {
    const { comment, file } = values;

    try {
      setIsSubmitting(true);
      if (file) {
        uploadDocumentFile({
          variables: {
            file,
          },
        }).then(async (resp: any) => {
          const {
            uploadFile: { id: fileId },
          } = resp.data;
          await create({
            variables: {
              id: idEvent,
              data: [
                {
                  ...(comment && { comment: comment as string }),
                  value: { connect: { id: newStatus?.value } },
                  file: { connect: { id: fileId } },
                },
              ],
            },
          });
          handleSnackBar('success', `статус успешно обновлен`);
          setIsSubmitting(false);
          setNewStatus(null);
        });
        await statusUpdated();
      } else {
        await create({
          variables: {
            id: idEvent,
            data: [
              {
                ...(comment && { comment: comment as string }),
                value: { connect: { id: newStatus?.value } },
              },
            ],
          },
        });
        handleSnackBar('success', `статус успешно обновлен`);
        setIsSubmitting(false);
        setNewStatus(null);
      }
      await statusUpdated();
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
      setIsSubmitting(false);
    }
  };

  return (
    <Accordion expanded={true} style={{ width: '100%', margin: 0, marginBottom: 15 }}>
      <AccordionSummary aria-controls={`event-status-content`} id={`event-status-header`}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item md={6} xs={12}>
            <Typography className={classes.header}>Статус судейской коллегии: {status?.value?.name || '~'}</Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: 'column' }}>
        <Dialog open={!!newStatus} onClose={() => setNewStatus(null)}>
          <DialogTitle>
            <Typography style={{ fontSize: 20, fontWeight: 600 }}>{newStatus?.label}</Typography>
          </DialogTitle>
          <DialogContent>
            <StatusForm
              isSubmitting={isSubmitting}
              onEdit={(values: formState) => onCreate(values)}
              onCancel={() => {
                setNewStatus(null);
              }}
              isRefereeNdFileRequired={newStatus?.value === 4}
            />
          </DialogContent>
        </Dialog>
        <Grid container spacing={2} style={{ marginBottom: 10 }}>
          <Grid item container md={6} xs={12} spacing={1}>
            <Grid item>
              <Typography style={{ fontWeight: 600 }}> Документ:</Typography>
            </Grid>
            <Grid item>
              {status?.file ? (
                <Link href={`${DOWNLOAD_LINK}${status?.file?.path}`}>
                  <Typography style={{ wordBreak: 'break-all' }}> {status?.file?.name}</Typography>
                </Link>
              ) : (
                '~'
              )}
            </Grid>
          </Grid>
          <Grid item container md={6} xs={12} spacing={1}>
            <Grid item>
              <Typography style={{ fontWeight: 600 }}> Комментарий:</Typography>
            </Grid>
            <Grid item>
              <Typography> {status?.comment || '~'}</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={1} style={{ marginBottom: 30 }}>
          <Grid item>
            <Button
              variant={'contained'}
              color={'primary'}
              size={'small'}
              onClick={() => handleSetNewStatus(1)}
              disabled={readonly || [1].includes(status?.value?.id || -1)}
            >
              проект
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant={'contained'}
              color={'primary'}
              size={'small'}
              onClick={() => handleSetNewStatus(2)}
              disabled={readonly || [2].includes(status?.value?.id || -1)}
            >
              На согласование
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant={'contained'}
              color={'primary'}
              size={'small'}
              onClick={() => handleSetNewStatus(3)}
              disabled={readonly || hasSportEventLimAccess || [3].includes(status?.value?.id || -1)}
            >
              согласовать
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant={'contained'}
              color={'primary'}
              size={'small'}
              onClick={() => handleSetNewStatus(4)}
              disabled={
                (hasSportEventLimAccess &&
                  ([5].includes(status?.value?.id || -1) || ![2].includes(status?.value?.id || -1))) ||
                readonly ||
                hasSportEventLimAccess ||
                [4].includes(status?.value?.id || -1)
              }
            >
              Утвердить
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant={'contained'}
              color={'secondary'}
              size={'small'}
              onClick={() => handleSetNewStatus(5)}
              disabled={
                (hasSportEventLimAccess &&
                  ([5].includes(status?.value?.id || -1) || ![2].includes(status?.value?.id || -1))) ||
                readonly ||
                hasSportEventLimAccess ||
                [5].includes(status?.value?.id || -1)
              }
            >
              Отклонить
            </Button>
          </Grid>
        </Grid>

        <StatusBoard statusArray={refereeStatuses} />
      </AccordionDetails>
    </Accordion>
  );
};

export default RefereeStatus;
