import { usePermissionMenu, usePermission } from '../../../../hooks/usePermission';
import React, { FC, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { NavLink, useLocation } from 'react-router-dom';
import userRights from '../../../../config/userRights';
import './menuGrid.css';
import { useClassifiersQuery } from '../../../../api';
import { gridPoints } from '../__data__/menuGridPoint';
import {
  Grid,
  Container,
  Card,
  CardActions,
  CardContent,
  ListItemText,
  Typography,
  useMediaQuery,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({}));

const MenuGrid: FC<{ url: any }> = ({ url }) => {
  const { pathname } = useLocation();
  const clasifiers = useClassifiersQuery({
    variables: { skip: 0, first: 50, filter: { archive: null } },
    fetchPolicy: 'no-cache',
  });
  const minWidth1200 = useMediaQuery('(min-width:1200px)');
  //const classes = useStyles();
  const access = usePermission('directory', 'home');
  const { who } = userRights();
  const isAdmin = who === 'ADMIN' ? true : false;
  const permission: any = usePermissionMenu();

  function MenuGrid() {
    return (
      <React.Fragment>
        {gridPoints.map((item) => {
          if (
            permission &&
            !permission[item.id] &&
            !(permission.directory && permission.directory.all) &&
            !(permission.directory && permission.directory[item.id])
          )
            return null;
          return (
            <div style={{ position: 'relative' }}>
              <Link
                className={isAdmin ? 'card_plus' : 'card_plus_disabled'}
                to={isAdmin ? `${item.createNew}` : ''}
                style={{ position: 'absolute', right: 25, top: 35 }}
                title={isAdmin ? `Добавить ${item.title}` : 'Данная функция недоступна этому типу пользователя'}
              >
                <span className="plus"></span>
              </Link>
              <Grid item xs={12} className={'grid_cell'}>
                <NavLink
                  className={`link ${(pathname.startsWith(item.link?.slice(0, -1)) && 'active_link') || ''}`}
                  activeClassName={'activeLink'}
                  to={item.link}
                  title={`Перейти в раздел ${item.title}`}
                >
                  <Card className={minWidth1200 ? 'card1' : 'card2'}>
                    <CardContent>
                      <div className={minWidth1200 ? 'card_icons1' : 'card_icons2'}>
                        <span className="card_icons_title">
                          <img src={item.srcBlue} height={minWidth1200 ? 110 : 65} />
                          <img src={item.srcRed} height={minWidth1200 ? 110 : 65} />
                        </span>
                      </div>
                    </CardContent>
                    <CardActions>
                      <Typography>
                        <ListItemText primary={item.title} className={minWidth1200 ? 'title1' : 'title2'} />
                      </Typography>
                    </CardActions>
                  </Card>
                </NavLink>
              </Grid>
            </div>
          );
        })}
      </React.Fragment>
    );
  }

  function ClasifierGrid() {
    return (
      <React.Fragment>
        {['A - Н', 'О - Я'].map((clasifier) => {
          return (
            <Grid item xs={6} className={'card_clasifier_actions_grid_cell'}>
              <div className={'card_clasifier_actions_grid_titles'}>
                {clasifier}
                <svg
                  style={{ marginLeft: 15 }}
                  width="20"
                  height="14"
                  viewBox="0 0 20 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 3.43404L9.99997 14L20 3.43404L18.4287 -1.26315e-05L10 8.90571L1.57134 -1.26315e-05L0 3.43404Z"
                    fill="#123150"
                  />
                </svg>
              </div>
              <div className={'card_clasifier_actions_grid_list'}>
                {clasifiers.data?.classifiers
                  .filter((itemFilter) => {
                    if (clasifier === 'A - Н') {
                      return itemFilter.name.split('').shift()?.match(/[А-Н]/);
                    }
                    if (clasifier === 'О - Я') {
                      return itemFilter.name.split('').shift()?.match(/[О-Я]/);
                    }
                    return itemFilter;
                  })
                  .map((itemMap) => {
                    return (
                      <Link className={'card_clasifier_actions_anchor'} to={`/classifier/${itemMap.id}`}>
                        <Typography>{itemMap.name}</Typography>
                      </Link>
                    );
                  })}
              </div>
            </Grid>
          );
        })}
      </React.Fragment>
    );
  }

  return (
    <Fragment>
      <Container maxWidth={minWidth1200 ? 'lg' : 'md'}>
        <div className={'root'}>
          <Grid container style={{ justifyContent: 'center' }}>
            <Grid container item xs={12} spacing={2} className={'grid_wrapper'}>
              <MenuGrid />
            </Grid>
          </Grid>
          <Card className={'card_clasifier'}>
            <CardContent>
              <Typography className={'card_clasifier_title'}>Класификаторы</Typography>
            </CardContent>
            <CardActions className={'card_clasifier_actions_block'}>
              <React.Fragment>
                <Grid container className={'card_clasifier_actions_grid'}>
                  <ClasifierGrid />
                </Grid>
              </React.Fragment>
            </CardActions>
          </Card>
        </div>
      </Container>
    </Fragment>
  );
};

export default MenuGrid;
