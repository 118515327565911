import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Collapse,
  Grid,
  IconButton,
  LinearProgress,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  DirNationalTeam,
  UpdateDirNationalTeamAthleteMutationHookResult,
  UpdateDirNationalTeamMemberMutationHookResult,
  UpdateDirNationalTeamTrainerMutationHookResult,
  useNationalTeamPeopleLazyQuery,
  useUpdateDirNationalTeamAthleteMutation,
  useUpdateDirNationalTeamMemberMutation,
  useUpdateDirNationalTeamTrainerMutation,
} from '../../../../api';
import React, { FC, useEffect, useMemo, useState } from 'react';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import getMessage from '../../../../messages';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import userRights from '../../../../config/userRights';
import { MDatePicker } from '../../../Inputs/DatePicker';
import moment from 'moment';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const useStyles = makeStyles({
  row: {
    display: 'flex',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  grayRow: {
    '& > th': {
      color: 'gray',
      backgroundColor: '#e0e0e0',
    },
    '& > td': {
      color: 'gray',
      backgroundColor: '#e0e0e0',
    },
  },
  tinyIcon: {
    fontSize: 18,
  },
  icon: {
    fontSize: 20,
  },
  tableHead: {
    fontWeight: 'bold',
  },
  header: {
    fontWeight: 600,
    fontSize: 15,
    letterSpacing: 1.2,
  },
  tableSize: {
    maxWidth: 100,
    width: 65,
    padding: 8,
  },
  tableSizeTh: {
    maxWidth: 125,
    width: 'auto',
    padding: 8,
  },
  tableEmail: {
    maxWidth: 150,
    width: 'auto',
    padding: 8,
    wordBreak: 'break-all',
  },
  accSumary: {
    width: '100%',
    margin: '0px !important',
    paddingLeft: 0,
    '& .MuiAccordionSummary-content': {
      margin: '0px !important',
    },
  },
  defaultColor: {
    color: 'black',
  },
  lightGray: {
    color: 'lightgrey',
  },
});

const TeamPeopleAccordion: FC<{
  readonly?: boolean;
  team: DirNationalTeam;
  hasHead?: boolean;
  isNotVerified?: boolean;
  status?: any;
  children?: React.ReactNode;
}> = ({ readonly, team, children, hasHead, isNotVerified, status }) => {
  const { isGskReferee, canEditSportingEventTeamMember, isSportingEventOperatorFspcr } = userRights();
  const [open, setOpen] = useState(false);
  const [
    updateDirNationalTeamAthlete,
  ]: UpdateDirNationalTeamAthleteMutationHookResult = useUpdateDirNationalTeamAthleteMutation();
  const [
    updateDirNationalTeamTrainer,
  ]: UpdateDirNationalTeamTrainerMutationHookResult = useUpdateDirNationalTeamTrainerMutation();
  const [
    updateDirNationalTeamMember,
  ]: UpdateDirNationalTeamMemberMutationHookResult = useUpdateDirNationalTeamMemberMutation();

  const f_mutations = {
    updateDirNationalTeamAthlete,
    updateDirNationalTeamTrainer,
    updateDirNationalTeamMember,
  };

  const { id } = team;
  const { enqueueSnackbar } = useSnackbar();
  const [fetch, { data, loading, refetch }] = useNationalTeamPeopleLazyQuery({
    variables: { id },
    partialRefetch: true,
  });

  useEffect(() => {
    if (open && id) {
      fetch();
    }
  }, [fetch, id, open]);
  const classes = useStyles();
  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const r_only = useMemo(
    () =>
      (isGskReferee && ![4, 5].includes(status?.value?.value)) ||
      canEditSportingEventTeamMember ||
      isSportingEventOperatorFspcr
        ? isNotVerified
        : readonly,
    [isGskReferee, status, canEditSportingEventTeamMember, isSportingEventOperatorFspcr, isNotVerified, readonly],
  );
  const getDisabledClass = (row: any) =>
    isNotVerified ? classes.lightGray : row?.entranceStatus ? classes.defaultColor : classes.lightGray;

  const onUpdateTeamMemberParams = async (
    entranceStatus: boolean,
    admissionDate: Date,
    mutationInfo: string,
    isDateUpdating?: boolean,
  ) => {
    const [mutationName, memberId] = mutationInfo.split(' ');
    const date = entranceStatus ? admissionDate : null;
    let message = isDateUpdating ? 'Дата допуска успешно обновлена' : 'Статус допуска успешно обновлен';

    if (mutationName && memberId) {
      try {
        await f_mutations[mutationName]({
          variables: {
            id: memberId,
            entranceStatus,
            admissionDate: date,
          },
        });
        handleSnackBar('success', message);
        refetch?.();
      } catch (error) {
        handleSnackBar('error', getMessage(error.message));
      }
    }
  };

  return (
    <>
      <TableRow className={team?.archive && classes.grayRow} onClick={() => setOpen((opn) => !opn)} key={team.id}>
        {children}
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              setOpen((opn) => !opn);
            }}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={18}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <TableContainer>
                <Table aria-label="team specialists table">
                  <TableHead>
                    <TableRow>
                      <TableCell>№ п/п</TableCell>
                      <TableCell>Допуск</TableCell>
                      <TableCell>Дата допуска</TableCell>
                      <TableCell>ФИО</TableCell>
                      <TableCell>Статус (руководитель, спортсмен, тренер, иное)</TableCell>
                      <TableCell>Дата рождения&nbsp;</TableCell>
                      <TableCell>Спортивная квалификация&nbsp;</TableCell>
                      <TableCell>Принадлежность к организации&nbsp;</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data?.aggregateParticipantsApplicationByTeamEvents?.rows?.map((row: any) => {
                      return (
                        <TableRow key={row.n}>
                          {/*className={team?.archive && classes.grayRow}*/}
                          <TableCell component="th" scope="row">
                            {row?.n || '~'}
                          </TableCell>
                          <TableCell>
                            <Switch
                              defaultChecked={isNotVerified ? false : row?.entranceStatus === true}
                              onChange={(_, newStatus) =>
                                onUpdateTeamMemberParams(
                                  newStatus,
                                  row?.admissionDate || new Date(),
                                  row?.mutateEntranceStatus,
                                )
                              }
                              disabled={r_only}
                              inputProps={{ 'aria-label': 'Trainer Switch' }}
                              color="primary"
                            />
                          </TableCell>
                          <TableCell className={getDisabledClass(row)}>
                            {row?.entranceStatus === true ? (
                              <Box maxWidth={150}>
                                <MDatePicker
                                  value={row?.admissionDate}
                                  onChange={(date) => {
                                    date &&
                                      onUpdateTeamMemberParams(
                                        row?.entranceStatus,
                                        date?.toDate(),
                                        row?.mutateEntranceStatus,
                                        true,
                                      );
                                  }}
                                  disabled={r_only}
                                />
                              </Box>
                            ) : null}
                          </TableCell>
                          <TableCell className={getDisabledClass(row)}>{row?.name || '~'}</TableCell>
                          <TableCell className={getDisabledClass(row)}>{row?.status || '~'}</TableCell>
                          <TableCell className={getDisabledClass(row)}>{row?.birthday || '~'}</TableCell>
                          <TableCell className={getDisabledClass(row)}>{row?.qualification || '~'}</TableCell>
                          <TableCell className={getDisabledClass(row)}>{row?.organization || '~'}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export default TeamPeopleAccordion;
