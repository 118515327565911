import moment from 'moment';
import { CalendarEventByMonthFragment } from '../../api';

export const sortByPeriod = (a: any, b: any) => {
  const [a1, b1, c1] = moment(a?.startDate)
    .format('DD.MM.YYYY')
    .split('.')
    .map((n) => Number(n));
  const [a2, b2, c2] = moment(b?.startDate)
    .format('DD.MM.YYYY')
    .split('.')
    .map((n) => Number(n));
  const [a3, b3, c3] = moment(a?.endDate)
    .format('DD.MM.YYYY')
    .split('.')
    .map((n) => Number(n));
  const [a4, b4, c4] = moment(b?.endDate)
    .format('DD.MM.YYYY')
    .split('.')
    .map((n) => Number(n));

  const result = a1 + b1 + c1 - a2 - b2 - c2 || a3 + b3 + c3 - a4 - b4 - c4;
  return result;
};

export const buildMonthEventsTree = (eventMonthData: CalendarEventByMonthFragment[] = []) => {
  if (eventMonthData.length === 0) return {};

  const zeroDay = moment(eventMonthData[0]?.startDate).format('YYYY-MM');
  const result = {};

  for (const day of Array.from(Array(moment(zeroDay).daysInMonth()), (_, i) => i + 1)) {
    const items = eventMonthData.filter((event) => Number(moment(event?.startDate).format('DD')) === Number(day));
    const isBetweenSomeDate = eventMonthData.filter(
      (event) =>
        Number(moment(event?.startDate).format('DD')) <= Number(day) &&
        Number(day) <= Number(moment(event?.startDate).format('DD')),
    );
    // console.log(isBetweenSomeDate);
    result[day] = items.length > 0 ? items : isBetweenSomeDate;
  }

  return result;
};
