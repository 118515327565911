import { Button, Grid } from '@material-ui/core';
import {
  CreateOrganizationMutationHookResult,
  OrganizationArchiveMutationHookResult,
  OrganizationFrFragment,
  OrganizationUnArchiveMutationHookResult,
  UpdateOrganizationMutationHookResult,
  useCreateOrganizationMutation,
  useOrganizationArchiveMutation,
  useOrganizationByIdQuery,
  useOrganizationUnArchiveMutation,
  useUpdateOrganizationMutation,
} from '../../../api';
import React, { FC, useEffect } from 'react';

import ArchiveWidget from '../Shared/Archive';
import CategoryOrganizations from '../../../module/CategoryOrganizations';
import { ContentWrapperWidget } from '../../layouts';
import { Input, Switch } from '../../Inputs';
import Regions from '../../../module/Regions';
import SaveIcon from '@material-ui/icons/Save';
import getMessage from '../../../messages';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';

const MainInfo: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const { data, refetch } = useOrganizationByIdQuery({ variables: { id }, fetchPolicy: 'no-cache' });
  const { handleSubmit, control, errors, reset, formState } = useForm({
    defaultValues: {
      ...data?.dirOrganization,
    } as OrganizationFrFragment,
  });
  const { push } = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [create]: CreateOrganizationMutationHookResult = useCreateOrganizationMutation();
  const [archive]: OrganizationArchiveMutationHookResult = useOrganizationArchiveMutation();
  const [unarchive]: OrganizationUnArchiveMutationHookResult = useOrganizationUnArchiveMutation();
  const [update]: UpdateOrganizationMutationHookResult = useUpdateOrganizationMutation();

  const onSubmit = async (values: OrganizationFrFragment) => {
    const { name, clsOrganizationCategories, dirRegion, isUniversity } = values;
    debugger;
    try {
      if (id !== 'new') {
        await update({
          variables: {
            id,
            data: {
              name,
              dirRegion: {
                connect: {
                  id: dirRegion?.value,
                },
              },
              clsOrganizationCategories: {
                set: clsOrganizationCategories?.map((category) => ({ id: category?.value })),
              },
              isUniversity: isUniversity,
            },
          },
        });
        enqueueSnackbar(`Организация "${values?.name}" успешно обновлена`, { variant: 'success' });
      } else {
        const { data } = await create({
          variables: {
            data: {
              name,
              clsOrganizationCategories: {
                connect: clsOrganizationCategories?.map((category) => ({ id: category?.value })),
              },
              dirRegion: {
                connect: {
                  id: dirRegion?.value,
                },
              },
              isUniversity: isUniversity,
            },
          },
        });
        if (data?.createDirOrganization && data?.createDirOrganization?.id) {
          enqueueSnackbar(`Организация "${data?.createDirOrganization?.name}" успешно добавлена`, {
            variant: 'success',
          });
          push(`/organization/${data?.createDirOrganization?.id}`);
        }
      }
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  useEffect(() => {
    if (data?.dirOrganization) {
      reset({
        ...data?.dirOrganization,
      });
    }
  }, [data, reset]);

  if (readonly)
    return (
      <Grid container>
        <Grid item container>
          <strong>Альтернативное название : {`${data?.dirOrganization?.name || ''}`}</strong>
        </Grid>
        <Grid item container>
          <strong>Регион: {` ${data?.dirOrganization?.dirRegion?.label || ''}`}</strong>
        </Grid>
        <Grid item container>
          <strong>
            Категории организации:{' '}
            {` ${data?.dirOrganization?.clsOrganizationCategories?.map((c) => c?.label).join(' , ') || ''}`}
          </strong>
        </Grid>
      </Grid>
    );
  return (
    <>
      <Grid container>
        <Grid item lg={6} md={12} xs={12}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <ContentWrapperWidget>
              <Grid container spacing={1}>
                <Grid item md={12}>
                  <Input
                    label="Альтернативное название"
                    control={control}
                    error={!!errors['name']}
                    name="name"
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item md={12}>
                  <Regions
                    label="Регион"
                    error={!!errors['dirRegion']}
                    name="dirRegion"
                    control={control}
                    rules={{
                      required: true,
                      validate: async (val) => (val?.value ? undefined : 'Поле обязательное для заполнения'),
                    }}
                  />
                </Grid>
                <Grid item md={12}>
                  <Switch label="ВУЗ" control={control} error={!!errors['isUniversity']} name="isUniversity" />
                </Grid>
                <Grid item md={12}>
                  <CategoryOrganizations
                    name="clsOrganizationCategories"
                    multiple
                    label="Категории организации"
                    error={!!errors['clsOrganizationCategories']}
                    control={control}
                    rules={{
                      required: true,
                    }}
                  />
                </Grid>

                <Grid item md={12}>
                  <Button
                    variant="outlined"
                    color="primary"
                    type="submit"
                    size="small"
                    startIcon={<SaveIcon color="primary" />}
                    disabled={!formState.isDirty}
                  >
                    Сохранить
                  </Button>
                </Grid>
              </Grid>
            </ContentWrapperWidget>
          </form>
        </Grid>
        {id && id !== 'new' && (
          <Grid item lg={12} md={12} xs={12}>
            <ArchiveWidget
              archive={data?.dirOrganization?.archive || null}
              id={id}
              refetch={refetch}
              onArchive={archive}
              onUnarchive={unarchive}
              caption="При архивации Организации ее ОГРН будет удален"
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default MainInfo;
