import {
  Accordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Typography,
  withStyles,
} from '@material-ui/core';
import { ContentWrapperWidget, MainTemplate } from '../../components/layouts';
import React, { ChangeEvent, FC, useState } from 'react';

import AdditionalInfo from '../../components/widgets/Persons/AdditionalInfo';
import Citizenship from '../../components/widgets/Persons/Citizenship';
import Duplicates from '../../components/widgets/Persons/Duplicates';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MainInfo from '../../components/widgets/Persons/MainInfo';
import Roles from '../../components/widgets/Persons/Roles';
import _get from 'lodash/get';
import { blue } from '@material-ui/core/colors';
import moment from 'moment';
import onExpand from '../../utils/onExpand';
import { usePersonByIdQuery } from '../../api';
import { useRouteMatch } from 'react-router-dom';

type AccordionTypes = 'MainInfo' | 'Citizenship' | 'AdditionalInfo' | 'Roles' | 'Duplicates' | undefined;

const AccordionSummary = withStyles({
  root: {
    backgroundColor: blue[700],
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    color: 'white',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary) as any;

// @TODO: Update View for Dialog request to Archive

const Single: FC<{}> = () => {
  const {
    params: { id },
  } = useRouteMatch();
  // const [page, setPage] = useState<number>(0);
  const [expanded, setExpanded] = useState<AccordionTypes>('MainInfo');

  // const access = usePermission('directory', 'person');
  // const style = useStyle();
  // const canAdd = true;
  const { data } = usePersonByIdQuery({ variables: { id } });

  const handleChangeAccordion = (panel: AccordionTypes) => (event: ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : undefined);
  };
  const OptionTitleArchive = data?.dirPerson?.archive
    ? ` (В архиве с ${moment(data?.dirPerson?.archive?.date).format('DD.MM.YYYY')})`
    : '';

  return (
    <MainTemplate
      pageName=""
      url=""
      title={
        id === 'new'
          ? 'Добавить новую персону'
          : `${data?.dirPerson?.lastname} ${data?.dirPerson?.firstname} ${data?.dirPerson?.patronymic || ''}`
      }
    >
      <ContentWrapperWidget>
        {id === 'new' ? (
          <h2>Добавить новую персону</h2>
        ) : (
          <h2>
            {`Реестровый номер: ${data?.dirPerson?.registryNumber || ''} , `}
            Персона: {data?.dirPerson?.lastname} {data?.dirPerson?.firstname}{' '}
            {`${_get(data?.dirPerson, 'patronymic', '') || ''} `}
            {moment(data?.dirPerson?.birthday).format('DD.MM.YYYY')}
            {OptionTitleArchive}
          </h2>
        )}
        <Accordion expanded={expanded === 'MainInfo'} onChange={handleChangeAccordion('MainInfo')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Общая информация</Typography>
          </AccordionSummary>
          <AccordionDetails>{onExpand(expanded, 'MainInfo') && <MainInfo id={id} />}</AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'AdditionalInfo'}
          onChange={handleChangeAccordion('AdditionalInfo')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Контактная информация</Typography>
          </AccordionSummary>
          <AccordionDetails>{onExpand(expanded, 'AdditionalInfo') && <AdditionalInfo id={id} />}</AccordionDetails>
        </Accordion>
        <Accordion disabled={id === 'new'} expanded={expanded === 'Roles'} onChange={handleChangeAccordion('Roles')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Роли</Typography>
          </AccordionSummary>
          <AccordionDetails>{onExpand(expanded, 'Roles') && <Roles id={id} />}</AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'Duplicates'}
          onChange={handleChangeAccordion('Duplicates')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Дубликаты</Typography>
          </AccordionSummary>
          <AccordionDetails>{onExpand(expanded, 'Duplicates') && <Duplicates id={id} />}</AccordionDetails>
        </Accordion>
      </ContentWrapperWidget>
    </MainTemplate>
  );
};

export default Single;
