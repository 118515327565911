import {
  Accordion,
  AccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Divider,
  Grid,
  Typography,
  withStyles,
} from '@material-ui/core';
import { ContentWrapperWidget, MainTemplate } from '../../components/layouts';
import { useCalendarQuery } from '../../api';
import React, { ChangeEvent, FC, useMemo, useState } from 'react';

import Analytics from '../../components/widgets/Events/Analytics';
import CalendarSportingEventList from '../../components/CalendarSportingEventList';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MainInfo from '../../components/widgets/Calendars/MainInfo';
import { blue } from '@material-ui/core/colors';
import moment from 'moment';
import onExpand from '../../utils/onExpand';
import { usePermission } from '../../hooks/usePermission';
import { useRouteMatch } from 'react-router-dom';
import { Regulations } from '../../components/widgets/Calendars/Regulations';
import { CompetitionVenue } from '../../components/widgets/Calendars/CompetitionVenue';
import { CompetitionVenueContinue } from '../../components/widgets/Calendars/CompetitionVenueContinue';

type AccordionTypes = 'MainInfo' | 'SportingEventList' | 'Analytics' | 'Regulations' | 'CompetitionVenue' | undefined;

const AccordionSummary = withStyles({
  root: {
    backgroundColor: blue[700],
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    color: 'white',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary) as any;

// @TODO: Update View for Dialog request to Archive

const Single: FC<{}> = () => {
  const {
    params: { id },
  } = useRouteMatch();
  // const [page, setPage] = useState<number>(0);
  const [expanded, setExpanded] = useState<AccordionTypes>('MainInfo');

  // const access = usePermission('directory', 'person');
  // const style = useStyle();
  // const canAdd = true;
  const { data } = useCalendarQuery({ variables: { id }, fetchPolicy: 'no-cache' });

  const handleChangeAccordion = (panel: AccordionTypes) => (event: ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : undefined);
  };
  const OptionTitleArchive = data?.dirCalendar?.archive
    ? ` (В архиве с ${moment(data?.dirCalendar?.archive?.date).format('DD.MM.YYYY')})`
    : '';

  const isUniverciad = useMemo(
    () => data?.dirCalendar?.clsCalendarCategory && data?.dirCalendar?.clsCalendarCategory?.value === 100003375,
    [data],
  );

  const access = usePermission('directory', 'calendars');

  return (
    <MainTemplate
      pageName=""
      url=""
      title={id === 'new' ? 'Добавить новый календарь' : `Календарь: ${data?.dirCalendar?.fullName}`}
    >
      <ContentWrapperWidget>
        {id === 'new' ? (
          <h2>Добавить новый календарь</h2>
        ) : (
          <h2>
            Календарь: {data?.dirCalendar?.fullName} {OptionTitleArchive}
          </h2>
        )}
        <Accordion expanded={expanded === 'MainInfo'} onChange={handleChangeAccordion('MainInfo')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Общая информация</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {onExpand(expanded, 'MainInfo') && (
              <MainInfo id={data?.dirCalendar?.id || 'new'} readonly={!access.includes(2)} />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'Analytics'}
          onChange={handleChangeAccordion('Analytics')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Статистика</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ flexDirection: 'column' }}>
            {onExpand(expanded, 'Analytics') && id && <Analytics id={id} />}
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'SportingEventList'}
          onChange={handleChangeAccordion('SportingEventList')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>{isUniverciad ? 'Список мероприятий Универсиады' : 'Список мероприятий'}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {onExpand(expanded, 'SportingEventList') && id && (
              <CalendarSportingEventList
                id={data?.dirCalendar?.id}
                isUniverciad={isUniverciad}
                readonly={!access.includes(2)}
              />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'Regulations'}
          onChange={handleChangeAccordion('Regulations')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Согласование регламента</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {onExpand(expanded, 'Regulations') && id && (
              <Regulations id={data?.dirCalendar?.id} readonly={!access.includes(2)} />
            )}
          </AccordionDetails>
        </Accordion>
        <Accordion
          disabled={id === 'new'}
          expanded={expanded === 'CompetitionVenue'}
          onChange={handleChangeAccordion('CompetitionVenue')}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon htmlColor="white" />}>
            <Typography>Конкурс на место проведения</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {onExpand(expanded, 'CompetitionVenue') && id && (
              <Grid container flexDirection="column">
                <CompetitionVenue id={data?.dirCalendar?.id} readonly={!access.includes(2)} />
              </Grid>
            )}
          </AccordionDetails>
        </Accordion>
      </ContentWrapperWidget>
    </MainTemplate>
  );
};

export default Single;
