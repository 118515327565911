import {
  ClassifierValue,
  DirAthleteSportRankAndAward,
  AthleteRankAndAwardUnArchiveMutationHookResult,
  AthleteRankAndAwardArchiveMutationHookResult,
  useAthleteRankAndAwardArchiveMutation,
  useAthleteRankAndAwardUnArchiveMutation,
  useUpdateAthleteSportMutation,
} from '../../../../api';
import { useForm } from 'react-hook-form';
import React, { FC, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useSnackbar } from 'notistack';
import getMessage from '../../../../messages';
import { Maybe } from '../../../../api';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { RanksAndTitle } from '../../../../module';
import moment from 'moment';
import { DatePicker } from '../../../Inputs';
import SaveIcon from '@material-ui/icons/Save';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ClearIcon from '@material-ui/icons/Clear';
import { getValidUntilDate } from './helpers';
import ArchiveComponent from '../../../Dialogs/Archive/Archive';
import _ from 'lodash';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  header: {
    fontWeight: 600,
    fontSize: 15,
    letterSpacing: 1.2,
  },
});

interface FormProps {
  assignmentDate: null | Date;
  confirmDate: null | Date;
  validUntil: null | Date;
  id: null | string;
  clsRankAndAward: null | { value: string; label: string };
}

const RankAwards: FC<{
  data?: Maybe<
    Array<
      { __typename?: 'DirAthleteSportRankAndAward' } & Pick<
        DirAthleteSportRankAndAward,
        'assignmentDate' | 'confirmDate' | 'validUntil' | 'id' | 'archive'
      > & {
          clsRankAndAward: { __typename?: 'ClassifierValue' } & {
            value: ClassifierValue['id'];
            label: ClassifierValue['fullName'];
          };
        }
    >
  >;
  id: string;
  idSport: string;
  refetch: any;
  readonly?: boolean;
}> = ({ data, id, refetch, idSport, readonly }) => {
  const classes = useStyles();

  const { handleSubmit, control, watch, formState, errors, setValue, reset } = useForm({
    defaultValues: {
      assignmentDate: null,
      validUntil: null,
      clsRankAndAward: null,
      confirmDate: null,
      id: null,
    } as FormProps,
  });

  const [edit, setEdit] = useState<
    | null
    | ({ __typename?: 'DirAthleteSportRankAndAward' } & Pick<
        DirAthleteSportRankAndAward,
        'assignmentDate' | 'confirmDate' | 'validUntil' | 'id'
      > & {
          clsRankAndAward: { __typename?: 'ClassifierValue' } & {
            value: ClassifierValue['id'];
            label: ClassifierValue['fullName'];
          };
        })
  >(null);
  const { enqueueSnackbar } = useSnackbar();
  const [update]: any = useUpdateAthleteSportMutation();
  const [archive]: AthleteRankAndAwardArchiveMutationHookResult = useAthleteRankAndAwardArchiveMutation();
  const [unarchive]: AthleteRankAndAwardUnArchiveMutationHookResult = useAthleteRankAndAwardUnArchiveMutation();

  const watchStartDate = watch('assignmentDate');
  const watchConfirmationDate = watch('confirmDate');
  const watchRankAndAward = watch('clsRankAndAward');

  const onSubmit = handleSubmit(async (values) => {
    try {
      if (!edit) {
        await update({
          variables: {
            data: {
              ranksAndAwards: {
                create: {
                  clsRankAndAward: {
                    connect: {
                      id: values?.clsRankAndAward?.value,
                    },
                  },
                  assignmentDate: values?.assignmentDate,
                  validUntil: values?.validUntil || null,
                },
              },
            },
            id,
          },
        });
      } else {
        await update({
          variables: {
            data: {
              ranksAndAwards: {
                update: {
                  where: {
                    id: edit?.id,
                  },
                  data: {
                    clsRankAndAward: {
                      connect: {
                        id: values?.clsRankAndAward?.value,
                      },
                    },
                    assignmentDate: values?.assignmentDate,
                    validUntil: values?.validUntil || null,
                    confirmDate: values?.confirmDate || null,
                  },
                },
              },
            },
            id,
          },
        });
      }
      reset({});
      setEdit(null);
      enqueueSnackbar(`Разряд/звание "${values?.clsRankAndAward?.label}" успешно добавлено`, { variant: 'success' });
      refetch();
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  });

  useEffect(() => {
    if (watchRankAndAward && watchStartDate && watchConfirmationDate)
      setValue('validUntil', getValidUntilDate(watchRankAndAward, watchConfirmationDate));
    else if (watchRankAndAward && watchStartDate)
      setValue('validUntil', getValidUntilDate(watchRankAndAward, watchStartDate));
  }, [watchRankAndAward, watchConfirmationDate, watchStartDate, setValue]);

  return (
    <Accordion style={{ width: '100%' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`ranks-content`} id={`ranks-header`}>
        <Typography className={classes.header} color="primary">
          Разряды и звания
        </Typography>
      </AccordionSummary>

      <AccordionDetails style={{ flexDirection: 'column' }}>
        <Grid container>
          {!readonly && (
            <form onSubmit={onSubmit} style={{ minWidth: '100%', marginBottom: 30 }}>
              <Grid container spacing={2} alignItems={'flex-end'}>
                <Grid item md={4} xs={12}>
                  <RanksAndTitle
                    label="Разряды и звания"
                    error={!!errors['clsRankAndAward']}
                    name="clsRankAndAward"
                    multiple={false}
                    control={control}
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item>
                  <DatePicker
                    label={'Дата вручения'}
                    control={control}
                    error={!!errors['assignmentDate']}
                    name={'assignmentDate'}
                    rules={{
                      required: true,
                    }}
                  />
                </Grid>
                {watchRankAndAward?.label !== 'Мастер спорта России' &&
                  watchRankAndAward?.label !== 'Гроссмейстер России' &&
                  watchRankAndAward?.label !== 'Заслуженный мастер спорта' &&
                  watchRankAndAward?.label !== 'Мастер спорта России международного класса' && (
                    <>
                      <Grid item>
                        <DatePicker
                          label={'Действует до'}
                          control={control}
                          error={!!errors['validUntil']}
                          name={'validUntil'}
                          rules={{
                            required: true,
                          }}
                          disabled
                        />
                      </Grid>
                      <Grid item>
                        <DatePicker
                          label="Дата подтверждения"
                          control={control}
                          error={!!errors['confirmDate']}
                          name="confirmDate"
                          disabled={
                            watchRankAndAward?.label === 'Мастер спорта России' ||
                            watchRankAndAward?.label === 'Мастер спорта России международного класса' ||
                            watchRankAndAward?.label === 'Гроссмейстер России' ||
                            watchRankAndAward?.label === 'Заслуженный мастер спорта'
                          }
                        />
                      </Grid>
                    </>
                  )}
                <Grid item container spacing={1}>
                  {edit && (
                    <Grid item>
                      <Button
                        aria-label="delete"
                        type="button"
                        size={'small'}
                        color={'secondary'}
                        variant={'outlined'}
                        startIcon={<ClearIcon />}
                        onClick={() => {
                          reset({});
                          setEdit(null);
                        }}
                      >
                        Отменить
                      </Button>
                    </Grid>
                  )}
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      type="submit"
                      size="small"
                      startIcon={<SaveIcon color="primary" />}
                      disabled={!formState.isDirty}
                    >
                      Сохранить
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
        </Grid>
        <Grid item md={6}>
          <List>
            {_.sortBy(data, [(o) => o.archive !== null])?.map((item) => (
              <ListItem key={item.id}>
                <ListItemText
                  {...(item?.archive !== null && {
                    style: {
                      color: 'gray',
                    },
                  })}
                  primary={item.clsRankAndAward.label}
                  secondary={`Дата присвоения ${moment(item.assignmentDate).format('DD.MM.YYYY')}${
                    item.confirmDate ? ` / Дата подтверждения ${moment(item.confirmDate).format('DD.MM.YYYY')}` : ''
                  }${item.validUntil ? ` / Действителен до ${moment(item.validUntil).format('DD.MM.YYYY')}` : ''}`}
                />
                <ListItemSecondaryAction>
                  {!readonly && edit?.id !== item?.id && (
                    <>
                      {!item?.archive && (
                        <IconButton
                          edge="end"
                          aria-label="delete"
                          onClick={() => {
                            reset(item);
                            setEdit(item);
                          }}
                        >
                          <EditIcon color="primary" />
                        </IconButton>
                      )}
                      <ArchiveComponent
                        id={item?.id}
                        archiveStatus={item.archive}
                        canUnArchive
                        unarchive={unarchive}
                        refetch={refetch}
                        archive={archive}
                      />
                    </>
                  )}
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default RankAwards;
