import React, { FC } from 'react';
import BgImage from '../../../static/img/BAuth.jpg';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    width: '100vw',
    background: `url(${BgImage}) no-repeat center center fixed`,
    backgroundSize: 'cover',
  },
}));

const AuthTemplate: FC<{ children: any }> = ({ children }) => {
  const classes = useStyle();
  return (
    <Grid container className={classes.root}>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        style={{ position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}
      >
        {children}
      </Grid>
    </Grid>
  );
};

export default AuthTemplate;
