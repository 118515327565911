import React, { FC } from 'react';
import { useEventTeamsListQuery } from '../api';
import { Select } from '../components/Inputs';
import { ControllerProps } from 'react-hook-form/dist/types/props';

interface EventTeamsLists extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  error: boolean | undefined;
  disabled?: boolean;
  id: string;
}

const EventTeamsList: FC<EventTeamsLists> = ({ label, error, control, name, rules, disabled, id }) => {
  const { data } = useEventTeamsListQuery({
    variables: {
      filter: {
        dirRegion: {
          archive: null,
        },
        dirSportingEvent: {
          id,
        },
        statuses_some: {
          value: {
            id: 4,
          },
        },
        archive: null,
      },
    },
    fetchPolicy: 'no-cache',
  });
  return (
    <Select
      label={label}
      data={
        (data &&
          data.dirNationalTeams?.map((team) => ({
            label: team.dirRegion.fullName,
            value: team.id,
          }))) ||
        []
      }
      control={control}
      error={error}
      name={name}
      rules={rules}
      disabled={disabled}
    />
  );
};

export default EventTeamsList;
