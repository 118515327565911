import { CalendarFragment } from '../../../../api';

type Calendar = {
  registryNumber: number;
  id: string;
  info: { registryNumber: number; id: string };
};

export const revealCalendar = (array: Array<{ __typename?: 'DirCalendar' } & CalendarFragment>) => {
  const calendarArray: Calendar[] =
    array &&
    array.map((calendar: { registryNumber: number; id: string }) => {
      const { registryNumber, id } = calendar;
      return {
        ...calendar,
        info: {
          registryNumber,
          id,
        },
      };
    });
  return calendarArray || [];
};
