import React, { useEffect, useState } from 'react';
import { Backdrop, LinearProgress, makeStyles, Paper, Typography } from '@material-ui/core';

const useStyles = makeStyles({
  wrapper: {
    zIndex: 2000,
    opacity: 0.6,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    height: 40,
    minWidth: 350,
    padding: 5,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column',
  },
  heading: {
    fontWeight: 600,
    letterSpacing: 1.2,
  },
});

export default function LoadingWall({ label = '' }) {
  const classes = useStyles();

  const [progress, setProgress] = useState(0);
  const [buffer, setBuffer] = useState(10);

  const progressRef = React.useRef(() => {});
  useEffect(() => {
    progressRef.current = () => {
      if (progress > 100) {
        setProgress(0);
        setBuffer(10);
      } else {
        const diff = Math.random() * 10;
        const diff2 = Math.random() * 10;
        setProgress(progress + diff);
        setBuffer(progress + diff + diff2);
      }
    };
  });

  useEffect(() => {
    const timer = setInterval(() => {
      progressRef.current();
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Backdrop className={classes.wrapper} style={{ zIndex: 2000, opacity: 0.9 }} open={true}>
      <Paper elevation={4} className={classes.paper}>
        <Typography color="primary" className={classes.heading}>
          {label}
        </Typography>
        <LinearProgress variant="buffer" value={progress} valueBuffer={buffer} />
      </Paper>
    </Backdrop>
  );
}
