import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import { AgeGroups, GroupsTypesSportAndDisciplines, RanksAndTitle } from '../../module';
import { FieldErrors } from 'react-hook-form';
import { ControllerProps } from 'react-hook-form/dist/types/props';
import { Input, Select } from '../Inputs';

interface TypeSportsAdditionalProps extends Pick<ControllerProps<'input'>, 'control'> {
  errors: FieldErrors;
}

const TypeSportsAdditional: FC<TypeSportsAdditionalProps> = ({ errors, control }) => {
  return (
    <>
      <Grid item xs={3}>
        <Select
          label="В архиве"
          data={[
            { value: '', label: ' ' },
            { value: false, label: 'Нет' },
            { value: true, label: 'Да' },
          ]}
          control={control}
          error={errors['archive']}
          name="archive"
          defaultValue={{ value: false, label: 'Нет' }}
        />
      </Grid>
      <Grid item xs={3}>
        <GroupsTypesSportAndDisciplines
          label="Группы видов спорта и дисциплин"
          error={errors['clsSportAndDisciplineGroups']}
          control={control}
          name="clsSportAndDisciplineGroups"
        />
      </Grid>
      <Grid item xs={3}>
        <Input label="Дисциплины" control={control} error={errors['disciplines']} name="disciplines" />
      </Grid>
      <Grid item xs={3}>
        <Input label="Группы дисциплин" control={control} error={errors['disciplineGroups']} name="disciplineGroups" />
      </Grid>
      <Grid item xs={3}>
        <AgeGroups label="Возрастные группы" error={errors['ageGroups']} control={control} name="ageGroups" />
      </Grid>
      <Grid item xs={3}>
        <RanksAndTitle
          label="Разряды и звания"
          error={errors['clsRanksAndAwards']}
          control={control}
          name="clsRanksAndAwards"
        />
      </Grid>
    </>
  );
};

export default TypeSportsAdditional;
