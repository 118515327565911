import React, { FC } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import DescriptionIcon from '@material-ui/icons/Description';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import userRights from '../../../config/userRights';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 600,
      letterSpacing: 1.2,
    },
    link: {
      cursor: 'pointer',
      textDecoration: 'none',
      color: 'rgba(0, 0, 0, 0.54)',
    },
    activeLink: {
      '& .MuiTypography-root': {
        fontWeight: 'bold',
        color: '#3f51b5',
        letterSpacing: 1,
      },
      '& .MuiSvgIcon-root': {
        color: '#3f51b5',
      },
    },
  }),
);

const Instructions: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const classes = useStyles();
  const { pathname } = useLocation();

  const { who } = userRights();
  let hasLimAccess = who === 'FCPSR_ADMINISTRATOR' || who === 'FCPSR_SPECIALIST';

  const infoPoints: any = hasLimAccess
    ? [
        {
          icon: DescriptionIcon,
          link: 'https://docs.google.com/document/d/13ROl6yPkgBRkYUFiCMzwCjH6peQ_QMCBXqFoCc-zmzU/edit?usp=sharing',
          id: 'ИнструкцииФЦПСР',
          title: 'Инструкции ФЦПСР',
        },
      ]
    : [
        {
          icon: DescriptionIcon,
          link: 'https://docs.google.com/document/d/13ROl6yPkgBRkYUFiCMzwCjH6peQ_QMCBXqFoCc-zmzU/edit?usp=sharing',
          id: 'ИнструкцииФЦПСР',
          title: 'Инструкции ФЦПСР',
        },
        {
          icon: DescriptionIcon,
          link: 'https://docs.google.com/document/d/1NkgoGkSFaC444dVvYGjXOmnMJjFB0h6sas8qsfdTVeA/edit?usp=sharing',
          id: 'ИнструкцииЕКП',
          title: 'Инструкции ЕКП',
        },
      ];

  return (
    <List style={{ width: '100%' }}>
      {infoPoints.map((item: any) => {
        const Icon = item.icon;

        return (
          <a
            key={`${item.link}-${item.title}`}
            className={`${classes.link} ${(pathname.startsWith(item.link?.slice(0, -1)) && classes.activeLink) || ''}`}
            href={item.link}
            target={'_blank'}
            aria-label={item.title}
          >
            <ListItem button key={item.id}>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItem>
          </a>
        );
      })}
    </List>
  );
};

export default Instructions;
