import {
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { Input, Select } from '../../Inputs';
import React, { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import {
  Scalars,
  UpdateSportingEventMutationHookResult,
  useSportingEventLazyQuery,
  useSportingEventProgramTypesLazyQuery,
  useUpdateSportingEventMutation,
} from '../../../api';

import AddBoxIcon from '@material-ui/icons/AddBox';
import ClearIcon from '@material-ui/icons/Clear';
import ConfirmDialog from '../../ConfirmDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import Documents from './components/ProgramTypeDocument';
import EditIcon from '@material-ui/icons/Edit';
import { EventItogProtocol } from './components';
import SaveIcon from '@material-ui/icons/Save';
import SearchIcon from '@material-ui/icons/Search';
import _ from 'lodash';
import getMessage from '../../../messages';
import { makeStyles } from '@material-ui/core/styles';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles({
  row: {
    display: 'flex',
  },
  link: {
    cursor: 'pointer',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  icon: {
    fontSize: 20,
  },
  loadContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 350,
  },
});

interface formState {
  minAge?: Scalars['Int'] | null;
  maxAge?: Scalars['Int'] | null;
  start?: string | null;
  discipline?: { label: string; value: Scalars['UUID'] } | null;
  clsAgeGroups?: {
    label: string;
    value: Scalars['UUID'];
    labelElement: ReactNode;
  } | null;
}

interface searchBoxState {
  discipline?: { label: string; value: Scalars['UUID'] } | null;
}

const ProgramTypes: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const classes = useStyles();

  const defaultState: formState = {
    minAge: undefined,
    maxAge: undefined,
    start: undefined,
    discipline: undefined,
    clsAgeGroups: undefined,
  };

  const [openDialog, setOpenDialog] = useState(false);
  const [currentRequest, setCurrentRequest] = useState('');
  const [programTypeId, setProgramTypeId] = useState<Scalars['UUID']>('');
  const [selectedAgeGroupInfo, setSelectedAgeGroupInfo] = useState<{
    minAge: Scalars['Int'] | null | undefined;
    maxAge?: Scalars['Int'] | null | undefined;
  } | null>(null);

  const [deleteItem, setDeleteItem] = useState<any>(null);

  const { handleSubmit, control, errors, reset, formState, setValue } = useForm({
    defaultValues: defaultState,
  });

  const {
    handleSubmit: searchBoxHandleSubmit,
    control: searchBoxControl,
    errors: searchBoxErrors,
    reset: SearchBoxReset,
    formState: searchBoxState,
  } = useForm({
    defaultValues: {
      discipline: undefined,
    },
  });

  const [fetch, { refetch, loading: programLoading, data }] = useSportingEventProgramTypesLazyQuery({
    fetchPolicy: 'no-cache',
    variables: { filter: { event: { id } } },
  });

  const [eventFetch, { loading, data: eventData }] = useSportingEventLazyQuery({
    fetchPolicy: 'no-cache',
    variables: { id },
  });

  const [updateEvent]: UpdateSportingEventMutationHookResult = useUpdateSportingEventMutation();

  const [sports, programTypes] = [eventData?.dirSportingEvent?.sports, data?.dirSportingEventProgramTypes];
  // sort programTypes by alphabet
  const programTypesMemoizedValue = useMemo(
    () =>
      programTypes?.sort((a, b) => {
        const labelA = a?.discipline?.label.toLowerCase(),
          labelB = b?.discipline?.label.toLowerCase();
        if (labelA < labelB) return -1;
        if (labelA > labelB) return 1;
        return 0;
      }),
    [programTypes],
  );
  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  useEffect(() => {
    if (id && id !== 'new') {
      eventFetch({ variables: { id } });
      fetch({ variables: { filter: { event: { id } } } });
    }
  }, [eventFetch, fetch, id]);

  useEffect(() => {
    setValue('minAge', selectedAgeGroupInfo?.minAge);
    setValue('maxAge', selectedAgeGroupInfo?.maxAge);
  }, [selectedAgeGroupInfo, setValue]);

  const disciplinesArray = useMemo(
    () =>
      _.flattenDeep(sports?.map((sport) => sport?.disciplineGroups?.map((group) => group?.disciplines))).length
        ? _.flattenDeep(sports?.map((sport) => sport?.disciplineGroups?.map((group) => group?.disciplines)))
        : _.flattenDeep(sports?.map((sport) => sport?.dirSport?.disciplines)),
    [sports],
  );

  const onSearch = async (value: searchBoxState) => {
    const { discipline } = value;
    fetch({ variables: { filter: { event: { id }, discipline: { id: discipline?.value } } } });
  };

  const onSubmit = async (values: formState) => {
    const { minAge, maxAge, start, clsAgeGroups, discipline } = values;
    try {
      if (currentRequest === 'update') {
        await updateEvent({
          variables: {
            data: {
              programTypes: {
                update: [
                  {
                    where: {
                      id: programTypeId,
                    },
                    data: {
                      minAge: Number(minAge) || 0,
                      maxAge: Number(maxAge) || 0,
                      start,
                      clsAgeGroups: {
                        set: [
                          {
                            id: clsAgeGroups?.value,
                          },
                        ],
                      },
                      discipline: {
                        connect: {
                          id: discipline?.value,
                        },
                      },
                    },
                  },
                ],
              },
            },
            id,
          },
        });

        handleSnackBar('success', 'Вид програм успешно обновлена');
        setOpenDialog(false);
        setCurrentRequest('');
        setProgramTypeId('');
        setSelectedAgeGroupInfo(null);
        reset(defaultState);
        refetch && refetch();

        return;
      }
      await updateEvent({
        variables: {
          data: {
            programTypes: {
              create: [
                {
                  minAge: Number(minAge) || 0,
                  maxAge: Number(maxAge) || 0,
                  start,
                  clsAgeGroups: {
                    connect: [
                      {
                        id: clsAgeGroups?.value,
                      },
                    ],
                  },
                  discipline: {
                    connect: {
                      id: discipline?.value,
                    },
                  },
                },
              ],
            },
          },
          id,
        },
      });
      handleSnackBar('success', 'Вид програм успешно создано');
      setOpenDialog(false);
      setSelectedAgeGroupInfo(null);
      reset(defaultState);
      refetch && refetch();
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const deleteProgram = async (programId: Scalars['UUID']) => {
    try {
      await updateEvent({
        variables: {
          data: {
            programTypes: {
              delete: [
                {
                  id: programId,
                },
              ],
            },
          },
          id,
        },
      });

      handleSnackBar('success', 'Вид програм успешно удаленно');
      refetch && refetch();
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  if (loading) return <LinearProgress />;

  return (
    <div>
      {sports && sports.length > 0 && !readonly && (
        <Grid container spacing={2} style={{ marginBottom: 10 }}>
          <Grid container style={{ paddingTop: 10 }} justify="flex-end">
            <Grid item md={6}>
              <EventItogProtocol readonly={readonly} id={id} />
            </Grid>
          </Grid>
          <Grid item>
            <Tooltip title="добавить" placement="top-start">
              <IconButton
                color="primary"
                onClick={() => {
                  setOpenDialog(true);
                }}
              >
                <AddBoxIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item container md={9}>
            <form onSubmit={searchBoxHandleSubmit(onSearch)} style={{ width: '100%' }}>
              <Grid item container spacing={2} alignItems="flex-end" justify="flex-end">
                <Grid item md={6}>
                  <Select
                    data={disciplinesArray}
                    label="Дисциплина"
                    control={searchBoxControl}
                    error={!!searchBoxErrors['discipline']}
                    name="discipline"
                  />
                </Grid>

                <Grid item container spacing={2} md={4}>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      type="reset"
                      onClick={() => {
                        SearchBoxReset({
                          discipline: undefined,
                        });
                        fetch({ variables: { filter: { event: { id } } } });
                      }}
                      size="small"
                      startIcon={<ClearIcon />}
                      disabled={!searchBoxState.isDirty}
                    >
                      Сброс
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      color="primary"
                      type="submit"
                      size="small"
                      startIcon={<SearchIcon />}
                      disabled={!searchBoxState.isDirty}
                    >
                      Поиск
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      )}
      {(programLoading && (
        <div className={classes.loadContainer}>
          <CircularProgress />
        </div>
      )) ||
        (programTypesMemoizedValue && programTypesMemoizedValue?.length > 0 && (
          <TableContainer component={Paper}>
            <Table aria-label="program types table">
              <TableHead>
                <TableRow>
                  <TableCell>Дисциплина&nbsp;</TableCell>
                  <TableCell>Возрастные группы</TableCell>
                  <TableCell>Возраст с</TableCell>
                  <TableCell>Возраст по&nbsp;</TableCell>
                  <TableCell>Старт&nbsp;</TableCell>
                  <TableCell>Документы&nbsp;</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {programTypesMemoizedValue.map((program) => (
                  <TableRow key={program.id}>
                    <TableCell component="th" scope="row">
                      {program.discipline?.label}
                    </TableCell>
                    <TableCell>{program.clsAgeGroups && program.clsAgeGroups[0]?.label}</TableCell>
                    <TableCell>{program.minAge}</TableCell>
                    <TableCell>{program.maxAge}</TableCell>
                    <TableCell>{program.start} </TableCell>
                    <TableCell>
                      <Documents
                        id={id}
                        readonly={readonly}
                        programId={program.id}
                        documents={program.resultDocuments}
                        onUpdateDocument={() => {
                          refetch && refetch();
                        }}
                      />
                    </TableCell>
                    <TableCell>
                      {!readonly && (
                        <div className={classes.row}>
                          <Tooltip title="изменить" placement="top-start">
                            <IconButton
                              className={classes.btn}
                              color="primary"
                              onClick={() => {
                                reset({ ...program, clsAgeGroups: program.clsAgeGroups && program.clsAgeGroups[0] });
                                setProgramTypeId(program?.id);
                                setCurrentRequest('update');
                                setOpenDialog(true);
                              }}
                            >
                              <EditIcon className={classes.icon} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="подробности" placement="top-start">
                            <IconButton
                              className={classes.btn}
                              color="secondary"
                              onClick={() => setDeleteItem(program?.id)}
                            >
                              <DeleteIcon className={classes.icon} />
                            </IconButton>
                          </Tooltip>
                          <ConfirmDialog
                            title={`Удаление дисциплины`}
                            text={`Вы уверены что хотите удалить эту дисциплину?`}
                            btnVariant="outlined"
                            open={deleteItem === program?.id}
                            saveBtnText="да"
                            cancelBtnText="отмена"
                            saveBtnColor="secondary"
                            cancelBtnColor="primary"
                            onCancel={() => setDeleteItem(null)}
                            onSave={() => {
                              deleteProgram(program?.id).then(() => setDeleteItem(null));
                            }}
                          />
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ))}
      <Dialog
        onClose={() => {
          setOpenDialog(false);
          setProgramTypeId('');
          setSelectedAgeGroupInfo(null);
          reset(defaultState);
        }}
        aria-labelledby="add-programType-dialog"
        open={openDialog}
      >
        <DialogTitle id="add-programType-dialog">Вид программы</DialogTitle>
        <div style={{ padding: '1.5rem' }}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Select
                  data={_.flattenDeep(
                    sports?.map((sport) =>
                      sport?.dirSport?.ageGroups?.map((a) => ({
                        ...a?.clsAgeGroup,
                        labelElement: (
                          <Grid
                            container
                            spacing={2}
                            onClick={() => setSelectedAgeGroupInfo({ minAge: a?.minAge, maxAge: a?.maxAge })}
                          >
                            <Grid item xs={6}>
                              {a?.clsAgeGroup?.label}
                            </Grid>{' '}
                            <Grid item xs={6} style={{ color: 'grey' }}>
                              {(a?.minAge && `От ${a?.minAge}`) || ''} {(a?.minAge && `до ${a?.maxAge}`) || ''}
                            </Grid>
                          </Grid>
                        ),
                      })),
                    ),
                  )}
                  label="Возрастные группы"
                  control={control}
                  error={!!errors['clsAgeGroups']}
                  name="clsAgeGroups"
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  data={disciplinesArray}
                  label="Дисциплина"
                  control={control}
                  error={!!errors['discipline']}
                  name="discipline"
                  rules={{ required: true }}
                />
              </Grid>

              <Grid item xs={12}>
                <Input label="Старт" control={control} error={!!errors['start']} name="start" />
              </Grid>
              <Grid item container justify="space-between">
                <Grid item xs={4}>
                  <Input label="Возраст с" control={control} error={!!errors['minAge']} name="minAge" />
                </Grid>
                <Grid item xs={4}>
                  <Input
                    label="Возраст по"
                    control={control}
                    error={!!errors['maxAge']}
                    name="maxAge"
                    rules={{ required: true }}
                  />
                </Grid>
              </Grid>
              <Grid item container justify="flex-end">
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  startIcon={<SaveIcon />}
                  type="submit"
                  disabled={!formState.isDirty}
                >
                  Сохранить
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Dialog>
    </div>
  );
};

export default ProgramTypes;
