import React, { FC } from 'react';
import { useSportingEventDocumentsListQuery } from '../../../../api';
import { DocumentComponent } from '../../../widgets/SportingEvents/Documents';

const Documents: FC<{ readonly?: boolean; id?: string }> = ({ id, readonly }) => {
  const { loading, data, refetch } = useSportingEventDocumentsListQuery({ returnPartialData: true, variables: { id } });

  return (
    <DocumentComponent
      readonly={readonly}
      id={id}
      loading={loading}
      data={data}
      refetch={refetch}
      docKey={'competitionProtocols'}
      docName={'Протоколы соревнований'}
      docRNumber={100003151}
      isProtocol={true}
    />
  );
};

export default Documents;
