import React, { FC } from 'react';
import { ClassifierValueFragment, useClsCalendarCategoryIdQuery, useClsCalendarCategoryQuery } from '../api';
import { Select } from '../components/Inputs';
import { ControllerProps } from 'react-hook-form/dist/types/props';

interface SelectCls extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  error: boolean | undefined;
  multiple?: boolean;
  usedValues?: any;
  disabled?: boolean;
}

const ClsCalendarCategoryId: FC<SelectCls> = ({
  label,
  error,
  control,
  name,
  rules,
  multiple = false,
  disabled,
  usedValues,
}) => {
  const { data } = useClsCalendarCategoryIdQuery();
  const classifierValues = data?.classifierValues || [];
  return (
    <Select
      label={label}
      data={
        (usedValues &&
          classifierValues.filter((cv: ClassifierValueFragment) => usedValues?.indexOf(cv.value) === -1)) ||
        classifierValues
      }
      control={control}
      error={error}
      name={name}
      multiple={multiple}
      rules={rules}
      disabled={disabled}
    />
  );
};

export default ClsCalendarCategoryId;
