import { Grid, Tooltip } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import React, { FC, useCallback } from 'react';
import { getSettingsUserFilter, getSettingsUserFilterFormValue } from '../../store/selectors/settings';
import { useDispatch, useSelector } from 'react-redux';
import { useDocumentsLazyQuery, useDocumentsQuery } from '../../api';
import { AppStore } from '../../store/reducers';
import { BtnNew } from '../../components/BtnNew';
import { DictionaryWrapper } from '../../components/DictionaryWrapper';
import { DocumentsFilter } from '../../components/Filters';
import { MUIDataTableColumn } from 'mui-datatables';
import { MainTemplate } from '../../components/layouts';
import { SET_USER_SETTINGS_FILTER } from '../../store/constants';
import _ from 'lodash';
import moment from 'moment';
import { usePermission } from '../../hooks/usePermission';
import { DataTable } from '../../components/DataTable/NewTable';

const Page: FC<{}> = () => {
  const dispatch = useDispatch();
  const filter: any = useSelector((state: AppStore) => getSettingsUserFilter(state, 'documents'), _.isEqual);
  const formValue: any = useSelector(
    (state: AppStore) => getSettingsUserFilterFormValue(state, 'documents'),
    _.isEqual,
  );

  const { push } = useHistory();
  const access = usePermission('directory', 'documents');

  const setFilter = useCallback(
    (filter, form) => {
      dispatch({
        type: SET_USER_SETTINGS_FILTER,
        payload: {
          name: 'documents',
          filter: filter,
          form,
        },
      });
    },
    [dispatch],
  );

  const columns: MUIDataTableColumn[] = [
    {
      name: 'info',
      label: 'Название документа',
      options: {
        filter: false,
        viewColumns: false,
        sort: false,
        customBodyRender: (info: any, tableMeta: any) => {
          const { id, title } = info || {};
          return (
            <React.Fragment>
              <Tooltip title="Перейти в карточку" placement="top-start">
                <Link
                  style={{
                    fontWeight: 500,
                    cursor: 'pointer',
                    textDecoration: 'none',
                  }}
                  to={`/document/${id}`}
                  target={'_blank'}
                >
                  {title}
                </Link>
              </Tooltip>
            </React.Fragment>
          );
        },
      },
    },
    {
      name: 'date',
      label: 'Дата',
      options: {
        filter: false,
        display: true,
        sort: false,
        customBodyRender: (date) => <React.Fragment>{moment(date).format('DD.MM.YYYY')}</React.Fragment>,
      },
    },
    {
      name: 'number',
      label: 'Номер',
      options: {
        filter: false,
        display: true,

        sort: false,
      },
    },
    {
      name: 'clsType',
      label: 'Тип документа',
      options: {
        filter: false,
        display: true,
        sort: false,
        customBodyRender: (type) => <React.Fragment>{type?.label}</React.Fragment>,
      },
    },
  ];

  return (
    <MainTemplate pageName="Документов" url="" title="Документы">
      <DocumentsFilter
        onSubmit={(filters: any, form: any) => setFilter({ ...filters }, form)}
        value={formValue}
        defaultFilter={filter}
      />
      <DictionaryWrapper>
        <DataTable
          tableName="documents"
          title={
            <React.Fragment>
              <Grid container spacing={2} alignItems={'center'}>
                <Grid item>
                  <h2>Справочник Документов</h2>
                </Grid>
                {access.includes(2) && <Grid item>{<BtnNew onClick={() => push('/document/new')} />}</Grid>}
              </Grid>
            </React.Fragment>
          }
          lazyQuery={useDocumentsLazyQuery}
          filter={filter}
          columns={columns}
          revealFunctionName="documents"
        />
      </DictionaryWrapper>
    </MainTemplate>
  );
};

export default Page;
