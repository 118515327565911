import React, { FC } from 'react';
import { ClassifierValueFragment, DirSport, useClsGroupTypeSportsAndDisciplinesQuery } from '../api';
import { Select } from '../components/Inputs';
import { ReactHookFormSelect } from '../components/Inputs/Select';

interface SelectCls
  extends Pick<ReactHookFormSelect, 'control' | 'name' | 'rules' | 'disabled' | 'label' | 'error' | 'errorMessage'> {
  usedValue?: DirSport['id'][];
}

const GroupsTypesSportAndDisciplines: FC<SelectCls> = ({ usedValue, label, error, control, name, rules }) => {
  const { data } = useClsGroupTypeSportsAndDisciplinesQuery();
  const classifierValues: any = data?.classifierValues || [];
  return (
    <Select
      label={label}
      control={control}
      data={
        (usedValue && classifierValues.filter((cv: ClassifierValueFragment) => usedValue?.indexOf(cv.value) === -1)) ||
        classifierValues
      }
      error={error}
      name={name}
      rules={rules}
    />
  );
};

export default GroupsTypesSportAndDisciplines;
