import React, { FC } from 'react';
import { Select } from '../components/Inputs';
import { ControllerProps } from 'react-hook-form/dist/types/props';

interface GenderSelectProps extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  error: boolean | undefined;
}

const Data = [
  { label: 'Муж', value: true },
  { label: 'Жен', value: false },
];

const GenderSelect: FC<GenderSelectProps> = ({ label, error, control, name, rules }) => {
  return <Select label={label} data={Data} control={control} error={error} name={name} rules={rules} />;
};

export default GenderSelect;
