import React, { FC } from 'react';
import { Select } from '../components/Inputs';
import { ControllerProps } from 'react-hook-form/dist/types/props';

interface SelectCls extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  error: boolean | undefined;
  disabled?: boolean;
  multiple?: boolean;
  isTag?: boolean;
}

const EventTags: FC<SelectCls> = ({ label, error, control, name, rules, disabled, multiple, isTag }) => {
  const data = [
    { label: 'Особо значимое', value: 'isImportant' },
    { label: 'Подготовка к ОИ', value: 'isOlympicGamesPreparation' },
    { label: 'Включая Тренировочные мероприятия', value: 'includingTrainingActivities' },
    { label: 'Финансирование за счет федерального бюджета', value: 'federalBudgetFinancing' },
  ];
  return (
    <Select
      label={label}
      data={data}
      control={control}
      error={error}
      name={name}
      rules={rules}
      isTag={isTag}
      multiple={multiple}
      disabled={disabled}
    />
  );
};

export default EventTags;
