import moment from 'moment';
export const shuffleMedicValidDate = (item: any, onMainInfo?: boolean) => {
  if (item.isValid && item.validUntil && !item.deletedAt && moment().isAfter(item.validUntil))
    return { color: 'red', text: `Истёк [${moment(item.validUntil).format('DD.MM.YYYY')}]` };
  else if (item.isValid && item.validUntil && !item.deletedAt && moment().isSameOrBefore(item.validUntil))
    return {
      color: 'black',
      text: onMainInfo ? `Допущен до ${moment(item.validUntil).format('DD.MM.YYYY')}` : 'Допущен',
    };
  else if (!item.isValid) return { color: 'black', text: 'Не допущен' };
};
