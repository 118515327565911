import _ from 'lodash';
import { ClassifierValue, DirAthlete, DirPerson, DirSport, DirTrainer, Maybe } from '../api';
const unifyTrainerAndAthlete = (
  trainers: Array<
    { __typename?: 'DirTrainer' } & Pick<DirTrainer, 'id'> & {
        dirPerson: { __typename?: 'DirPerson' } & Pick<DirPerson, 'id' | 'firstname' | 'lastname' | 'patronymic'>;
        sports?: Maybe<
          Array<
            { __typename?: 'DirTrainerSport' } & {
              dirSport: { __typename?: 'DirSport' } & Pick<DirSport, 'id'> & {
                  clsTrainingStages?: Maybe<
                    Array<{ __typename?: 'ClassifierValue' } & Pick<ClassifierValue, 'id' | 'fullName'>>
                  >;
                };
            }
          >
        >;
      }
  >,
  trainingStages: Array<
    { __typename?: 'ClassifierValue' } & {
      label: ClassifierValue['fullName'];
      shortLabel: ClassifierValue['shortName'];
      value: ClassifierValue['id'];
    }
  >,
  id: string,
  athletes?: Array<
    { __typename?: 'DirAthlete' } & Pick<DirAthlete, 'id'> & {
        sports?: Maybe<
          Array<
            { __typename?: 'DirAthleteSport' } & {
              dirSport: { __typename?: 'DirSport' } & Pick<DirSport, 'id'>;
              organizations?: Maybe<
                Array<
                  { __typename?: 'DirAthleteSportOrganization' } & {
                    dirTrainer?: Maybe<{ __typename?: 'DirTrainer' } & Pick<DirTrainer, 'id'>>;
                    clsTrainingStage: { __typename?: 'ClassifierValue' } & Pick<ClassifierValue, 'id' | 'fullName'>;
                    isSelfTraining?: Boolean;
                  }
                >
              >;
            }
          >
        >;
      }
  >,
) => {
  const dataObject: { [k: string]: any } = {};
  let total: { [k: string]: { id: string; athletes: number } } = {};
  trainingStages?.forEach((stage) => {
    total[stage.label] = { id: stage.value, athletes: 0 };
  });

  const setTotalSportmen = (count: number, stage: string) => {
    const currentCount = total[stage].athletes;
    total[stage].athletes = currentCount + count;
  };
  if (trainingStages) {
    _.forEach(trainers, (trainer) => {
      const trainerFio = `${trainer.dirPerson.lastname} ${trainer.dirPerson.firstname} ${
        trainer.dirPerson.patronymic || ''
      }`;

      _.each(trainingStages, (stage) => {
        const athletesCount = _.filter(
          athletes,
          (man) =>
            man.sports &&
            man.sports.filter(
              (sport) =>
                sport.organizations &&
                sport.organizations?.length > 0 &&
                sport.organizations.find((o) => _.get(o, 'dirTrainer.id') === trainer.id) &&
                _.get(
                  sport.organizations.find((o) => _.get(o, 'dirTrainer.id') === trainer.id),
                  'clsTrainingStage.id',
                ) === stage.value,
            ).length,
        ).length;

        dataObject[trainerFio] = {
          ...dataObject[trainerFio],
          [stage.label]: {
            trainerId: trainer.id,
            trainingStageId: stage.value,
            orgId: id,
            athletes: athletesCount,
          },
        };
      });
    });
    _.each(trainingStages, (stage) => {
      dataObject.sportmenWithoutTrainer = {
        ...dataObject.sportmenWithoutTrainer,
        [stage.label]: {
          trainingStageId: stage.value,
          orgId: id,
          athletes: _.filter(
            athletes,
            (man) =>
              man.sports &&
              man.sports.filter(
                (sport) =>
                  sport.organizations &&
                  sport.organizations.length > 0 &&
                  sport.organizations.find(
                    (o) => o.isSelfTraining === true && _.get(o, 'clsTrainingStage.id') === stage.value,
                  ),
              ).length,
          ).length,
        },
      };
    });
  }

  Object.values(dataObject).forEach((o) => {
    _.forIn(o, (value, key) => {
      setTotalSportmen(value.sportmen, key);
    });
  });

  return {
    data: dataObject,
    totalData: total,
  };
};

export default unifyTrainerAndAthlete;
