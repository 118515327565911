import _ from 'lodash';
import moment from 'moment';
import { AthleteListFrFragment } from '../api';

interface Athlete {
  info: {
    id: string;
    registryNumber: number;
  };
  fio: string;
  birthday: string;
  isMale: boolean;
  region?: string;
}

const revealAthletesInfo = (array: Array<{ __typename?: 'DirAthlete' } & AthleteListFrFragment> = []) => {
  const unifiedArray: any = [];
  for (const item of array) {
    const sports = item.sports || [];
    let athlete: Athlete = {
      ...item,
      info: {
        id: item.id,
        registryNumber: item.dirPerson.registryNumber,
      },
      fio: `${item.dirPerson.lastname} ${item.dirPerson.firstname} ${item.dirPerson.patronymic || ''}`,
      birthday: item.dirPerson.birthday,
      isMale: item.dirPerson.isMale,
      region:
        item?.dirPerson?.dirRegion?.label ||
        item?.dirPerson.dirCountry?.label ||
        (item?.dirPerson?.dirForeignCity &&
          `${item?.dirPerson?.dirForeignCity?.label}, ${item?.dirPerson?.dirCountry?.label}`) ||
        '',
    };
    if (sports.length > 0) {
      for (const sport of sports) {
        const organizations =
          _.groupBy(sport?.organizations?.filter((o) => !o.exclusionDate) || [], (o) => o.dirOrganization.label) || {};
        if (Object.keys(organizations).length > 0) {
          for (const [key, value] of Object.entries(organizations)) {
            const trainers = _.uniq(
              value
                ?.filter(
                  (v) =>
                    _.get(v, 'dirTrainer.sports', []).filter(
                      (s: any) =>
                        _.get(s, 'organizations', []).filter(
                          (o: any) => o.dirOrganization.id === _.get(v, 'dirOrganization.value') && !o.dismissalDate,
                        ).length,
                    ).length,
                )
                .map(
                  (v) =>
                    `${v?.dirTrainer?.dirPerson?.lastname || ''} ${v?.dirTrainer?.dirPerson?.firstname[0] || ''}${
                      v?.dirTrainer?.dirPerson?.patronymic ? `.${v?.dirTrainer?.dirPerson?.patronymic[0]} ` : ''
                    }`,
                ),
            ).join(', ');
            const trainingStages = value.map((v) => v?.clsTrainingStage?.shortName).join(', ');
            unifiedArray.push({
              ...athlete,
              trainers,
              trainingStages,
              organization: key,
              sport: {
                label: sport?.dirSport?.fullName,
                ranks: sport?.ranksAndAwards
                  ?.filter((rank) => !rank.validUntil || moment().isSameOrBefore(rank.validUntil))
                  .map((r) => r?.clsRankAndAward?.label),
              },
            });
          }
        } else {
          unifiedArray.push({
            ...athlete,
            sport: {
              label: sport.dirSport.fullName,
              ranks: sport?.ranksAndAwards
                ?.filter((rank) => !rank.validUntil || moment().isSameOrBefore(rank.validUntil))
                .map((r) => r?.clsRankAndAward?.label),
            },
          });
        }
      }
    }
  }
  return unifiedArray;
};

export default revealAthletesInfo;
