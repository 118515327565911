import React, { FC, useEffect, useState } from 'react';
import {
  Button,
  IconButton,
  Grid,
  Typography,
  Paper,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useForm } from 'react-hook-form';
import { Input } from '../../Inputs';
import SaveIcon from '@material-ui/icons/Save';
import { useSnackbar } from 'notistack';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import {
  useUpdateOrganizationSitesMutation,
  useOrganizationSitesQuery,
  UpdateOrganizationSitesMutationHookResult,
} from '../../../api';

interface formState {
  site?: string;
}

const useStyles = makeStyles({
  addressAccordion: {
    margin: '15px 0',
    '&:before': {
      backgroundColor: 'unset',
    },
  },
  row: {
    display: 'flex',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  tinyIcon: {
    fontSize: 18,
  },
  icon: {
    fontSize: 20,
  },
  tableHead: {
    fontWeight: 'bold',
  },
  header: {
    fontWeight: 600,
    fontSize: 15,
    letterSpacing: 1.2,
  },
  container: {
    maxHeight: 440,
    marginTop: 10,
  },
});

const ControlSites: FC<{
  readonly?: boolean;
  id: string;
}> = ({ readonly, id }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [sites, setSites] = useState<string[]>([]);
  const [mode, setMode] = useState<number | null>(null);
  const [deleteItem, setDeleteItem] = useState<number | null>(null);

  const { data } = useOrganizationSitesQuery({
    variables: {
      id,
    },
  });

  useEffect(() => {
    setSites(data?.dirOrganization?.websites || []);
  }, [data]);

  const [updateSites]: UpdateOrganizationSitesMutationHookResult = useUpdateOrganizationSitesMutation();

  const defaultState: formState = {
    site: undefined,
  };

  const { handleSubmit, control, errors, reset, formState } = useForm({
    defaultValues: defaultState,
  });

  const onDelete = async (idx: number | null) => {
    if (idx === null) return;
    const newListSites = sites.filter((val, index) => {
      return idx !== index;
    });
    setSites(newListSites);
    await updateSites({ variables: { id, sites: newListSites } });
    enqueueSnackbar(`Сайт успешно удален`, {
      variant: 'success',
    });
    setDeleteItem(null);
  };

  const onSubmit = async (values: any) => {
    if (values?.site) {
      setSites((prevState) => {
        return [...prevState, values?.site];
      });
      await updateSites({ variables: { id, sites: [...sites, values?.site] } });
      enqueueSnackbar(`Сайт успешно добавлен`, {
        variant: 'success',
      });
    }
  };

  return (
    <Accordion className={classes.addressAccordion}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="adress-content" id="address-header">
        <Typography className={classes.header} color="primary">
          Сайты
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ display: 'block' }}>
        {mode ? (
          <form onSubmit={handleSubmit((values) => onSubmit(values))} style={{ width: '50%' }}>
            <Paper elevation={3} style={{ padding: 10, margin: '15px 0' }}>
              <Grid container style={{ marginBottom: 20 }} alignItems="flex-end" spacing={2}>
                <Grid item container>
                  <Input
                    name="site"
                    label="Сайт"
                    control={control}
                    error={!!errors['site']}
                    rules={{ required: true }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} justify={'flex-end'}>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="small"
                    startIcon={<ClearIcon />}
                    type="button"
                    onClick={() => {
                      reset({});
                      setMode(null);
                    }}
                  >
                    Отменить
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    startIcon={<SaveIcon />}
                    type="submit"
                    disabled={!formState.isDirty}
                  >
                    Сохранить
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </form>
        ) : (
          <Button color="primary" variant="contained" size="small" onClick={() => setMode(999)}>
            <AddIcon />
          </Button>
        )}
        {sites?.length > 0 && (
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="address table">
              <TableHead>
                <TableRow>
                  <TableCell className={classes.tableHead}>URL сайта</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {sites?.map((item: string, index: number) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                      <TableCell>{item}</TableCell>
                      <TableCell>
                        {mode !== index && (
                          <>
                            {/*<IconButton*/}
                            {/*  onClick={() => {*/}
                            {/*    setMode(index);*/}
                            {/*    reset({site: item});*/}
                            {/*  }}*/}
                            {/*>*/}
                            {/*  <EditIcon color="primary" />*/}
                            {/*</IconButton>*/}
                            <IconButton onClick={() => setDeleteItem(index)}>
                              <DeleteIcon color="secondary" />
                            </IconButton>
                          </>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <Dialog open={deleteItem !== null} onClose={() => setDeleteItem(null)}>
          <DialogTitle>Вы уверены, что хотите удалить адрес этого сайта?</DialogTitle>
          <DialogActions>
            <Grid container justify="space-between">
              <Grid item>
                <Button variant="contained" color="primary" onClick={() => setDeleteItem(null)}>
                  Отменить
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="secondary" onClick={() => onDelete(deleteItem)}>
                  Удалить
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      </AccordionDetails>
    </Accordion>
  );
};

export default ControlSites;
