import React, { FC, ReactNode } from 'react';
import { Controller } from 'react-hook-form';
import { Checkbox as MuiCheckbox, FormControlLabel } from '@material-ui/core';
import { ControllerProps } from 'react-hook-form/dist/types/props';

interface ReactHookFormCheckbox extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules' | 'type'> {
  label: string | ReactNode;
  errorMessage?: string;
  error: boolean | undefined;
  disabled?: boolean;
  color?: 'primary' | 'secondary' | 'default';
}

const Checkbox: FC<ReactHookFormCheckbox> = ({
  name,
  error,
  type = 'text',
  label,
  errorMessage = '',
  control,
  rules,
  disabled,
  color = 'secondary',
}) => {
  return (
    <FormControlLabel
      control={
        <Controller
          name={name}
          control={control}
          render={(props) => (
            <MuiCheckbox
              {...props}
              disabled={disabled}
              color={color}
              checked={props.value}
              onChange={(e) => props.onChange(e.target.checked)}
            />
          )}
        />
      }
      label={label}
    />
  );
};

export default Checkbox;
