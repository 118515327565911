import React, { FC, useEffect, useState } from 'react';
import { Typography, Accordion, AccordionSummary, AccordionDetails, Button, Link } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import SaveIcon from '@material-ui/icons/Save';
import { useAnalyticsEventAgeUsersLazyQuery } from '../../../../api';

const useStyles = makeStyles({
  row: {
    display: 'flex',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  tinyIcon: {
    fontSize: 18,
  },
  icon: {
    fontSize: 20,
  },
  tableHead: {
    fontWeight: 'bold',
  },
  header: {
    fontWeight: 600,
    fontSize: 15,
    letterSpacing: 1.2,
  },
});

const columnMain = [
  {
    name: 'calendarName',
    label: 'Название календаря',
    options: {
      filter: false,
      display: true,
      sort: false,
    },
  },
  {
    name: 'totalAmount',
    label: 'Итого кол-во участников',
    options: {
      filter: false,
      display: true,
      sort: false,
    },
  },
];

const columnYear = [
  {
    name: 'year',
    label: 'Год рождения участников',
    options: {
      filter: false,
      display: true,
      sort: false,
    },
  },
  {
    name: 'amount',
    label: 'Количество участников',
    options: {
      filter: false,
      display: true,
      sort: false,
    },
  },
];

const columnEvent = [
  {
    name: 'eventName',
    label: 'Название спортивного мероприятия',
    options: {
      filter: false,
      display: true,
      sort: false,
    },
  },
  {
    name: 'sports',
    label: 'Виды спорта мероприятия',
    options: {
      filter: false,
      display: true,
      sort: false,
      customBodyRender: (data = []) => {
        return <>{data.join(', ')}</>;
      },
    },
  },
  {
    name: 'disciplineGroups',
    label: 'Группы дисциплин мероприятия',
    options: {
      filter: false,
      display: true,
      sort: false,
      customBodyRender: (data = []) => {
        return <>{data.join(', ')}</>;
      },
    },
  },
  {
    name: 'byYear',
    label: ' ',
    options: {
      filter: false,
      display: false,
      sort: false,
    },
  },
];

const AgeUsers: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const classes = useStyles();

  const [dataTable, setDataTable] = useState();
  // @ts-ignore
  const [fetch, { data, loading, refetch }]: any = useAnalyticsEventAgeUsersLazyQuery({
    fetchPolicy: 'no-cache',
    variables: { id },
  });
  const DOWNLOAD_LINK = process.env.REACT_APP_BACKEND_URI?.replace('/graphql', '');
  useEffect(() => {
    if (id) {
      fetch();
    }
  }, [fetch, id]);
  return (
    <Accordion key="Возраст участников мероприятий" style={{ width: '100%', marginBottom: 15 }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`Возраст участников мероприятий-content`}
        id={`Возраст участников мероприятий-header`}
      >
        <Grid container spacing={2} justify="space-between" alignItems="center">
          <Grid item>
            <Typography className={classes.header} color="primary">
              Возраст участников мероприятий
            </Typography>
          </Grid>
          <Grid item onClick={(e) => e.stopPropagation()}></Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: 'column' }}>
        <Link href={`${DOWNLOAD_LINK}/reports/sporting-event-participants-by-age/${id}`}>
          <Button size="small" variant="outlined" color="primary">
            <SaveIcon />
          </Button>
        </Link>
        <MUIDataTable
          title={''}
          data={[data?.aggregateParticipantsAgeByCalendar] || []}
          columns={columnMain}
          options={{
            filter: false,
            search: false,
            pagination: false,
            selectableRowsHideCheckboxes: true,
            selectableRows: 'none',
            selectableRowsHeader: false,
            print: false,
            download: data?.length > 0,
            viewColumns: false,
            textLabels: {
              body: {
                noMatch: 'Извините, данные отсутствуют',
              },
            },
          }}
        />
        <br />
        <MUIDataTable
          title={''}
          data={data?.aggregateParticipantsAgeByCalendar?.byEvent || []}
          columns={columnEvent}
          options={{
            filter: false,
            search: false,
            pagination: false,
            selectableRowsHideCheckboxes: true,
            selectableRows: 'none',
            selectableRowsHeader: false,
            print: false,
            download: data?.length > 0,
            viewColumns: false,
            textLabels: {
              body: {
                noMatch: 'Извините, данные отсутствуют',
              },
            },
            expandableRows: true,
            renderExpandableRow: (rowData: any, rowMeta) => {
              if (rowData[3]?.length === 0) {
                return null;
              }
              return (
                <td style={{ width: '100%' }} colSpan={4}>
                  <MUIDataTable
                    title={''}
                    data={rowData[3] || []}
                    columns={columnYear}
                    options={{
                      filter: false,
                      search: false,
                      pagination: false,
                      selectableRowsHideCheckboxes: true,
                      selectableRows: 'none',
                      selectableRowsHeader: false,
                      print: false,
                      download: data?.length > 0,
                      viewColumns: false,
                      textLabels: {
                        body: {
                          noMatch: 'Извините, данные отсутствуют',
                        },
                      },
                    }}
                  />
                </td>
              );
            },
          }}
        />

        <br />
        <MUIDataTable
          title={''}
          data={data?.aggregateParticipantsAgeByCalendar?.byYear || []}
          columns={columnYear}
          options={{
            filter: false,
            search: false,
            pagination: false,
            selectableRowsHideCheckboxes: true,
            selectableRows: 'none',
            selectableRowsHeader: false,
            print: false,
            download: data?.length > 0,
            viewColumns: false,
            textLabels: {
              body: {
                noMatch: 'Извините, данные отсутствуют',
              },
            },
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default AgeUsers;
