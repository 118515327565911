import React, { FC, useState, useEffect, useMemo } from 'react';
import { IconButton, Grid, Tooltip, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useForm } from 'react-hook-form';
import getMessage from '../../../../messages';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { useSnackbar } from 'notistack';
import {
  useUpdateSportingEventMutation,
  UpdateSportingEventMutationHookResult,
  Scalars,
  useSportingEventQuery,
} from '../../../../api';
import ConfirmDialog from '../../../ConfirmDialog';
import Radios from '../../../Inputs/Radio';
import Regions from '../../../../module/Regions';
import { Countries } from '../../../../module';
import ForeignCities from '../../../../module/ForeignCities';
import _ from 'lodash';

interface formState {
  dirRegions?: Array<{ label: string; value: Scalars['UUID'] }>;
  dirCountry?: any;
  dirForeignCities?: Array<ICities>;
  isRussia: string;
}

interface ICities {
  value: Scalars['UUID'];
  label: string;
  country: {
    id: Scalars['UUID'];
  };
}

interface ICountry {
  value: Scalars['UUID'];
  label: string;
}

const RegionsBox: FC<{
  readonly?: boolean;
  onSuccess?(): void;
  id?: string;
  onRegionsBoxChange?: ({
    dirRegions,
    dirForeignCities,
    dirCountry,
    isRussia,
  }: {
    dirRegions?: Array<{ label: string; value: Scalars['UUID'] }>;
    dirForeignCities?: Array<ICities>;
    dirCountry?: Array<ICountry>;
    isRussia: string;
  }) => void;
}> = ({ readonly, id, onRegionsBoxChange, onSuccess }) => {
  const { loading, data, refetch } = useSportingEventQuery({ skip: !id, fetchPolicy: 'no-cache', variables: { id } });

  const countries = useMemo(() => data?.dirSportingEvent?.countries || [], [data]);
  const regions = useMemo(() => data?.dirSportingEvent?.dirRegions || [], [data]);

  const [deleteItem, setDeleteItem] = useState<any>(null);
  const [update]: UpdateSportingEventMutationHookResult = useUpdateSportingEventMutation();

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const defaultState: formState = {
    dirRegions: undefined,
    dirCountry: undefined,
    dirForeignCities: undefined,
    isRussia: 'true',
  };

  const onHandleCancel = () => {
    setDeleteItem(null);
  };

  const { handleSubmit, control, errors, reset, watch, formState, getValues, setValue } = useForm({
    defaultValues: defaultState,
  });

  const isRussia = watch('isRussia');
  const watchRegions = watch('dirRegions');
  const watchCountry = watch('dirCountry');
  const watchForeignCity = watch('dirForeignCities');

  const onAddCountries = async (values: formState) => {
    const { dirCountry, dirForeignCities } = values;

    try {
      await update({
        variables: {
          data: {
            countries: {
              create: [
                {
                  dirCountry: {
                    connect: { id: dirCountry?.value },
                  },
                  ...(dirForeignCities &&
                    dirForeignCities?.length > 0 && {
                      dirForeignCities: {
                        connect: dirForeignCities.map((city) => ({ id: city?.value })),
                      },
                    }),
                },
              ],
            },
          },
          id,
        },
      });
      await refetch();
      handleSnackBar('success', 'Зарубежный город и стран успешно добавлен');
      setValue('dirCountry', null);
      setValue('dirForeignCities', undefined);
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const onUpdateRegion = async (dirRegions: Array<{ label: string; value: Scalars['UUID'] }>) => {
    try {
      await update({
        variables: {
          data: {
            dirRegions: {
              set: dirRegions?.map((region) => ({ id: region?.value })),
            },
          },
          id,
        },
      });
      handleSnackBar('success', 'Регионы РФ успешно обновлены');
      await refetch();
    } catch (e) {
      handleSnackBar('error', getMessage(e.message));
    }
  };

  const onDeleteCountry = async (countryId: Scalars['UUID']) => {
    try {
      await update({
        variables: {
          data: {
            countries: {
              delete: [
                {
                  id: countryId,
                },
              ],
            },
          },
          id: id,
        },
      });
      onHandleCancel();
      handleSnackBar('success', 'Зарубежный город и стран успешно удален');
      refetch();
    } catch (e) {
      handleSnackBar('error', getMessage(e.message));
    }
  };

  const onIsRussiaChange = async (values: formState) => {
    const isRussiaBool = values?.isRussia === 'true';
    try {
      await update({
        variables: {
          data: {
            isRussia: isRussiaBool,
            dirRegions: {
              set: [],
            },
            countries: {
              set: [],
            },
          },
          id,
        },
      });
      handleSnackBar('success', 'Информация успешно обновлена');
      await refetch();
    } catch (e) {
      handleSnackBar('error', getMessage(e.message));
    }
  };

  useEffect(() => {
    if (!loading && data?.dirSportingEvent) {
      reset({
        isRussia: data?.dirSportingEvent?.isRussia === true ? 'true' : 'false',
        dirRegions: regions,
      });
    }
  }, [loading, data]);

  useEffect(() => {
    if (onRegionsBoxChange)
      onRegionsBoxChange({
        dirRegions: watchRegions,
        dirCountry: watchCountry as any,
        dirForeignCities: watchForeignCity,
        isRussia,
      });
  }, [watchRegions, watchForeignCity, watchCountry, isRussia]);

  useEffect(() => {
    if (!loading && id && watchRegions !== undefined && !_.isEqual(regions, watchRegions)) {
      onUpdateRegion(watchRegions);
    }
  }, [watchRegions]);

  useEffect(() => {
    if (!loading && id && isRussia !== `${data?.dirSportingEvent?.isRussia}`) {
      const values = getValues();
      onIsRussiaChange(values);
    }
  }, [isRussia]);

  return (
    <>
      <form
        style={{ width: '100%' }}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Grid container spacing={2}>
          <Grid item container>
            <Radios
              label="Страна"
              data={[
                {
                  value: 'true',
                  label: 'Россия',
                },
                {
                  value: 'false',
                  label: 'Зарубеж',
                },
              ]}
              control={control}
              error={!!errors['isRussia']}
              name="isRussia"
              rules={{ required: true }}
              disabled={readonly}
            />
          </Grid>
          {isRussia === 'true' && (
            <Grid item container xs={12} spacing={1} alignItems={'flex-end'}>
              <Grid item xs>
                <Regions
                  label="Регион РФ"
                  control={control}
                  error={!!errors['dirRegions']}
                  name="dirRegions"
                  disabled={readonly}
                  enablePortal
                  multiple
                  rules={{ required: true }}
                  {...(id && {
                    filter: { id_not_in: data?.dirSportingEvent?.dirRegions?.map((region) => region?.value) },
                  })}
                />
              </Grid>
            </Grid>
          )}
          {isRussia === 'false' && (
            <Grid item container alignItems="flex-end" spacing={2} style={{ marginBottom: 20 }}>
              <Grid item md={5} xs={12}>
                <Countries
                  label="Название страны"
                  control={control}
                  error={!!errors['dirCountry']}
                  name="dirCountry"
                  multiple={!id}
                  disabled={readonly}
                  {...(id && {
                    filter: { id_not_in: countries?.map((country) => country?.dirCountry?.value) },
                  })}
                />
              </Grid>
              {(id ? watchCountry : watchCountry && watchCountry?.length > 0) && (
                <Grid item md={5} xs={12}>
                  <ForeignCities
                    label="Название города"
                    control={control}
                    filter={{
                      dirCountry: id ? { id: watchCountry?.value } : { id_in: watchCountry?.map((c: any) => c?.value) },
                    }}
                    error={!!errors['dirForeignCities']}
                    name="dirForeignCities"
                    multiple
                    disabled={readonly}
                  />
                </Grid>
              )}
              {id && (
                <Grid item>
                  <IconButton
                    color="primary"
                    size="small"
                    type="button"
                    onClick={handleSubmit((formState) => onAddCountries(formState))}
                    disabled={!formState.isDirty || !watchCountry}
                  >
                    <AddIcon />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </form>

      <Grid container>
        {readonly && _.get(data, 'dirSportingEvent.countries.length') > 0 && (
          <Grid item container>
            <Typography style={{ fontWeight: 600, marginBottom: 10 }}>Зарубежные города и стран:</Typography>
          </Grid>
        )}
        <Grid
          item
          md={6}
          xs={12}
          container
          style={{ marginBottom: 10, maxHeight: 250, overflowY: 'auto' }}
          alignItems="center"
          spacing={1}
        >
          {isRussia === 'false' &&
            countries?.map((country: any) => (
              <Grid item container key={country?.id} justify={'space-between'} alignItems={'center'}>
                <Grid item>
                  {country?.dirCountry?.label}{' '}
                  {`${
                    country?.dirForeignCities?.length > 0
                      ? `[${country?.dirForeignCities?.map((city: any) => city?.label).join(', ')}]`
                      : ''
                  }`}
                </Grid>
                {!readonly && (
                  <Grid item>
                    <Tooltip title="удалить" placement="top">
                      <IconButton
                        color="secondary"
                        type="button"
                        onClick={() => setDeleteItem({ key: 'country', item: country })}
                      >
                        <DeleteForeverIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
              </Grid>
            ))}
        </Grid>
      </Grid>
      <ConfirmDialog
        title={(deleteItem?.key === 'country' && `Удаление зарубежного города и страны`) || ''}
        text={`Вы уверены что хотите удалить : ${
          (deleteItem?.key === 'country' && deleteItem?.item?.dirCountry?.label) || ''
        } ?`}
        btnVariant="outlined"
        open={!!deleteItem}
        saveBtnText="удалить"
        cancelBtnText="отмена"
        saveBtnColor="secondary"
        cancelBtnColor="primary"
        onCancel={() => setDeleteItem(null)}
        onSave={() => deleteItem?.key === 'country' && onDeleteCountry(deleteItem?.item?.id)}
      />
    </>
  );
};

export default RegionsBox;
