import React, { FC } from 'react';
import { Grid, Button, TableCell, TableRow, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/Person';
import { Link } from 'react-router-dom';
import moment from 'moment';
import TimelineRoundedIcon from '@material-ui/icons/TimelineRounded';
import SportsHandballRoundedIcon from '@material-ui/icons/SportsHandballRounded';
import GavelIcon from '@material-ui/icons/Gavel';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 15,
  },
}))(TableCell);

const CustomRow: FC<{ person: any; title?: string }> = ({ person, title }) => {
  return (
    <>
      <StyledTableCell>
        <Typography style={{ fontWeight: 600, fontSize: 15, width: 'max-content' }}>{title}</Typography>
      </StyledTableCell>
      <StyledTableCell>{person?.registryNumber}</StyledTableCell>
      <StyledTableCell>
        {`${person?.lastname} ${person?.firstname} ${person?.patronymic ? person.patronymic : ''}`}
      </StyledTableCell>

      <StyledTableCell> {(person?.birthday && moment(person?.birthday).format('DD.MM.YYYY')) || ''}</StyledTableCell>
      <StyledTableCell>{person?.isMale ? 'Муж' : 'Жен'}</StyledTableCell>
      <StyledTableCell>{`${person?.dirRegion?.fullName || person?.dirForeignCity?.fullName || ''}`}</StyledTableCell>
      <StyledTableCell> {(person?.birthday && moment(person?.createdAt).format('DD.MM.YYYY')) || ''}</StyledTableCell>
      <StyledTableCell> {(person?.birthday && moment(person.updatedAt).format('DD.MM.YYYY')) || ''}</StyledTableCell>
      <StyledTableCell>
        <Grid container spacing={1}>
          {person?.id && (
            <Grid item xs={12}>
              <Link
                style={{
                  fontWeight: 500,
                  cursor: 'pointer',
                  textDecoration: 'none',
                }}
                target="_blank"
                to={`/person/${person?.id}`}
              >
                <Button startIcon={<PersonIcon />} variant={'outlined'} color={'primary'} size={'small'}>
                  Персона
                </Button>
              </Link>
            </Grid>
          )}
          {person?.trainerProfile?.id && (
            <Grid item>
              <Link
                style={{
                  fontWeight: 500,
                  cursor: 'pointer',
                  textDecoration: 'none',
                }}
                target="_blank"
                to={`/trainer/${person?.trainerProfile?.id}`}
              >
                <Button startIcon={<TimelineRoundedIcon />} variant={'outlined'} color={'primary'} size={'small'}>
                  Тренер
                </Button>
              </Link>
            </Grid>
          )}
          {person?.athleteProfile?.id && (
            <Grid item>
              <Link
                style={{
                  fontWeight: 500,
                  cursor: 'pointer',
                  textDecoration: 'none',
                }}
                target="_blank"
                to={`/athlete/${person?.athleteProfile?.id}`}
              >
                <Button startIcon={<SportsHandballRoundedIcon />} variant={'outlined'} color={'primary'} size={'small'}>
                  Атлет
                </Button>
              </Link>
            </Grid>
          )}
          {person?.refereeProfile?.id && (
            <Grid item>
              <Link
                style={{
                  fontWeight: 500,
                  cursor: 'pointer',
                  textDecoration: 'none',
                }}
                target="_blank"
                to={`/referee/${person?.refereeProfile?.id}`}
              >
                <Button startIcon={<GavelIcon />} variant={'outlined'} color={'primary'} size={'small'}>
                  Судья
                </Button>
              </Link>
            </Grid>
          )}
        </Grid>
      </StyledTableCell>
    </>
  );
};

export default CustomRow;
