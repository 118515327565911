import { Button, Divider, Grid, LinearProgress } from '@material-ui/core';
import {
  NationalTeamArchiveMutationHookResult,
  NationalTeamFragment,
  NationalTeamUnArchiveMutationHookResult,
  UpdateNationalTeamMutationHookResult,
  useNationalTeamArchiveMutation,
  useNationalTeamQuery,
  useNationalTeamUnArchiveMutation,
  useUpdateNationalTeamMutation,
} from '../../../api';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import ArchiveWidget from '../Shared/Archive';
import { ContentWrapperWidget } from '../../layouts';
import { Input } from '../../Inputs';
import SaveIcon from '@material-ui/icons/Save';
import Status from './components/Status';
import getMessage from '../../../messages';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import { checkDate } from './components/helpers';
import _ from 'lodash';
import CompetitionRegulationChanges from './components/CompetitionRegulationChanges';
import { getAthleteFormHint } from '../../../utils/getAthleteFormHint';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 600,
    },
    link: {
      cursor: 'pointer',
    },
    verticalSpace: {
      paddingBottom: 15,
    },
    verticalSpaceMarge: {
      marginBottom: 30,
    },
    historyBox: {
      marginBottom: 30,
      maxHeight: 150,
      overflowY: 'auto',
      scrollBehavior: 'smooth',
    },
    divider: {
      height: 1,
      width: '100%',
      marginBottom: '1rem',
    },
  }),
);

const MainInfo: FC<{ readonly?: boolean; id?: string; refetch: Function; data: any }> = ({
  readonly,
  id,
  refetch,
  data,
}) => {
  const DOWNLOAD_LINK = process.env.REACT_APP_BACKEND_URI?.replace('/graphql', '');

  const classes = useStyles();
  const [disciplineGroupsString, setDisciplineGroupsString] = useState<string>('');
  const { handleSubmit, control, errors, reset, formState } = useForm({
    defaultValues: data?.dirNationalTeam as NationalTeamFragment,
  });
  const { minManBirthYear = 0, maxManBirthYear = 0, minWomanBirthYear = 0, maxWomanBirthYear = 0 } =
    data?.dirNationalTeam?.dirSportingEvent || {};
  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const [update]: UpdateNationalTeamMutationHookResult = useUpdateNationalTeamMutation();
  const [archive]: NationalTeamArchiveMutationHookResult = useNationalTeamArchiveMutation();
  const [unarchive]: NationalTeamUnArchiveMutationHookResult = useNationalTeamUnArchiveMutation();

  const onSubmit = async (values: NationalTeamFragment) => {
    const { comment, fullName } = values;
    try {
      await update({
        variables: {
          id,
          data: {
            comment: comment || '',
            fullName: fullName || '',
          },
        },
      });
      handleSnackBar('success', 'Общая информация успешно обновлена');
    } catch (e) {
      handleSnackBar('error', getMessage(e.message));
    }
  };

  const isBetweenDates = (date: string | number, isMale = false) =>
    isMale
      ? checkDate(date, minManBirthYear || 0, maxManBirthYear || 0)
      : checkDate(date, minWomanBirthYear || 0, maxWomanBirthYear || 0);

  const athleteIsInRangeRank = useMemo(() => {
    const countOutside =
      data?.dirNationalTeam?.athleteGroups?.reduce((acc: number, current: any) => {
        return (
          acc +
          current.athletes.filter((athlete: any) => {
            const athleteSports = athlete?.dirAthlete?.sports?.filter((sport) => {
              const validateAthlete = getAthleteFormHint(
                athlete,
                sport?.dirSport?.id,
                data?.dirNationalTeam?.dirSportingEvent || {},
              );
              return validateAthlete === 'Отсутствует разряд/звание, соответствующий регламенту';
            });
            return athleteSports?.length > 0;
          }).length
        );
      }, 0) || 0;
    return countOutside;
  }, [data]);

  useEffect(() => {
    if (data?.dirNationalTeam) {
      reset(data?.dirNationalTeam);
      if (data?.dirNationalTeam?.dirSportingEvent?.sports) {
        let anonymousString: string = '';
        data?.dirNationalTeam?.dirSportingEvent?.sports?.forEach((sport) => {
          const disciplineGroupsJoined = sport.disciplineGroups?.map((d) => d.label).join(' , ');
          anonymousString += ` ${disciplineGroupsJoined}`;
        });
        setDisciplineGroupsString(anonymousString);
      }
    }
  }, [data, reset]);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
            <ContentWrapperWidget>
              <Grid container spacing={1}>
                <Status
                  team={data?.dirNationalTeam}
                  id={id}
                  readonly={readonly}
                  onUpdate={() => {
                    refetch({variables: {id}});
                  }}
                />
              </Grid>
              <Grid item container>
                <Divider className={classes.divider} />
              </Grid>
              <Grid item xs={12} className={classes.verticalSpaceMarge}>
                <span className={classes.title}>Регион:&nbsp;</span>
                {data?.dirNationalTeam?.dirRegion?.fullName}
              </Grid>
              <Grid item xs={12} className={classes.verticalSpaceMarge}>
                <span className={classes.title}>ВУЗ:&nbsp;</span>
                {data?.dirNationalTeam?.university?.name || '~'}
              </Grid>
              <Grid item container>
                <Grid item container>
                  <Grid item xs={3} className={classes.verticalSpace}>
                    <span className={classes.title}>Кол-во&nbsp;</span>
                  </Grid>
                  <Grid item xs={1}>
                    <span className={classes.title}>Квота&nbsp;</span>
                  </Grid>
                  <Grid item>
                    <span className={classes.title}>Факт&nbsp;</span>
                  </Grid>
                </Grid>
                <Grid item container className={classes.verticalSpace}>
                  <Grid item xs={3}>
                    <span>Квота общая:</span>
                  </Grid>
                  <Grid item xs={1}>
                    <span>{data?.dirNationalTeam?.athleteTotalQuota || '~'}</span>
                  </Grid>
                  <Grid item>
                    <span>
                      {data?.dirNationalTeam?.athleteGroups?.reduce(
                        (acc: number, current: any) => acc + current.athletes.length,
                        0,
                      )}
                    </span>
                  </Grid>
                </Grid>
                <Grid item container className={classes.verticalSpace}>
                  <Grid item xs={3}>
                    <span>кол-во спортсменов муж:</span>
                  </Grid>
                  <Grid item xs={1}>
                    <span>{data?.dirNationalTeam?.manTeamQuota || '~'}</span>
                  </Grid>
                  <Grid item>
                    <span>
                      {data?.dirNationalTeam?.athleteGroups?.reduce(
                        (acc: number, current: any) =>
                          acc +
                          current.athletes.filter((athlete) => athlete?.dirAthlete?.dirPerson?.isMale === true).length,
                        0,
                      )}
                    </span>
                  </Grid>
                </Grid>
                <Grid item container className={classes.verticalSpace}>
                  <Grid item xs={3}>
                    <span>кол-во спортсменов жен:</span>
                  </Grid>
                  <Grid item xs={1}>
                    <span>{data?.dirNationalTeam?.womanTeamQuota || '~'}</span>
                  </Grid>
                  <Grid item>
                    <span>
                      {data?.dirNationalTeam?.athleteGroups?.reduce(
                        (acc: number, current: any) =>
                          acc +
                          current.athletes.filter((athlete) => athlete?.dirAthlete?.dirPerson?.isMale !== true).length,
                        0,
                      )}
                    </span>
                  </Grid>
                </Grid>
                <Grid item container alignItems="center" className={classes.verticalSpace}>
                  <Grid item xs={3}>
                    <span>в т.ч. спортсменов, не соответствующих возрастным требованиям:</span>
                  </Grid>
                  <Grid item xs={1}>
                    <span>{data?.dirNationalTeam?.dirSportingEvent?.athletesQuotaNotCorrespondingByAge || '~'}</span>
                  </Grid>
                  <Grid item>
                    <span>
                      {data?.dirNationalTeam?.athleteGroups?.reduce(
                        (acc: number, current: any) =>
                          acc +
                          current.athletes.filter((athlete: any) =>
                            isBetweenDates(
                              _.get(athlete, 'dirAthlete.dirPerson.birthday', ''),
                              _.get(athlete, 'dirAthlete.dirPerson.isMale', false),
                            ),
                          ).length,
                        0,
                      )}
                    </span>
                  </Grid>
                </Grid>
                <Grid item container alignItems="center" className={classes.verticalSpace}>
                  <Grid item xs={3}>
                    <span>в т.ч. спортсменов, не соответствующих требованиям спортквалификации:</span>
                  </Grid>
                  <Grid item xs={1}>
                    <span>
                      {data?.dirNationalTeam?.dirSportingEvent?.athletesQuotaNotCorrespondingByQualification || '~'}
                    </span>
                  </Grid>
                  <Grid item>
                    <span>{athleteIsInRangeRank}</span>
                  </Grid>
                </Grid>
                <Grid item container className={classes.verticalSpace}>
                  <Grid item xs={3}>
                    <span>сопровождающих:</span>
                  </Grid>
                  <Grid item xs={1}>
                    <span>{data?.dirNationalTeam?.supportTeamQuota}</span>
                  </Grid>
                  <Grid item>
                    <span>
                      {(data?.dirNationalTeam?.specialists?.length || 0) +
                        (data?.dirNationalTeam?.medics?.length || 0) +
                        (data?.dirNationalTeam?.trainers?.length || 0) +
                        (data?.dirNationalTeam?.delegationHeads?.length || 0)}
                    </span>
                  </Grid>
                </Grid>
                <Grid item xs={12} className={classes.verticalSpace}>
                  <span className={classes.title}>Название мероприятия:&nbsp;</span>
                  {data?.dirNationalTeam?.dirSportingEvent?.name}
                  <span className={classes.title} style={{ paddingLeft: 15 }}>
                    {data?.dirNationalTeam?.dirSportingEvent?.registryNumber}
                  </span>
                </Grid>
                <Grid item xs={12} className={classes.verticalSpace}>
                  <span className={classes.title}>Период проведения:&nbsp;</span>
                  {`${moment(data?.dirNationalTeam?.dirSportingEvent?.startDate).format('DD.MM.YY') || '~'} - ${
                    moment(data?.dirNationalTeam?.dirSportingEvent?.endDate).format('DD.MM.YY') || '~'
                  }`}
                </Grid>
                <Grid item xs={12} className={classes.verticalSpace}>
                  <span className={classes.title}>Вид спорта и группы дисциплин:&nbsp;</span>
                  {data?.dirNationalTeam?.dirSportingEvent?.sports
                    ?.map((s) => s.dirSport && s.dirSport.fullName)
                    .join(' , ') || '~'}
                  &nbsp; | {disciplineGroupsString || '~'}
                </Grid>

                <Grid className={classes.verticalSpace} container alignItems="center" spacing={2}>
                  <Grid item>
                    <span className={classes.title}>Регламент спортивных соревнований:</span>
                  </Grid>
                  {data?.dirNationalTeam?.dirSportingEvent?.dirCalendar?.sportCompetitionRegulationDocument ? (
                    <>
                      <Grid item>
                        <Button
                          href={`${DOWNLOAD_LINK}${data?.dirNationalTeam?.dirSportingEvent?.dirCalendar?.sportCompetitionRegulationDocument?.path}`}
                          color="primary"
                          size="small"
                          variant="contained"
                        >
                          скачать
                        </Button>
                      </Grid>
                    </>
                  ) : (
                    <span>~</span>
                  )}
                </Grid>

                <Grid className={classes.verticalSpace} container direction="column" spacing={2}>
                  <Grid item>
                    <span className={classes.title}>Изменение к регламенту:</span>
                  </Grid>
                  <Grid item>
                    <CompetitionRegulationChanges dirCalendar={data?.dirNationalTeam?.dirSportingEvent?.dirCalendar} />
                  </Grid>
                </Grid>
                <Grid item xs={12} className={classes.verticalSpaceMarge}>
                  <Divider className={classes.divider} />
                </Grid>
                <Grid item xs={12} className={classes.verticalSpace}>
                  <Input
                    label="Наименование Сборной команды"
                    control={control}
                    error={!!errors['fullName']}
                    name="fullName"
                    disabled={readonly}
                  />
                </Grid>
                <Grid item xs={12} className={classes.verticalSpace}>
                  <Input
                    label="Комментарии"
                    control={control}
                    error={!!errors['comment']}
                    name="comment"
                    disabled={readonly}
                  />
                </Grid>
                <Grid item md={12}>
                  <Button
                    variant="outlined"
                    color="primary"
                    type="submit"
                    size="small"
                    startIcon={<SaveIcon color="primary" />}
                    disabled={!formState.isDirty}
                  >
                    Сохранить
                  </Button>
                </Grid>
              </Grid>
            </ContentWrapperWidget>
          </form>
        </Grid>
        {id && id !== 'new' && !readonly && (
          <Grid item lg={12} md={12} xs={12}>
            <ArchiveWidget
              readonly={readonly}
              archive={data?.dirNationalTeam?.archive || null}
              id={id}
              refetch={refetch}
              onArchive={archive}
              onUnarchive={unarchive}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default MainInfo;
