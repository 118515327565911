import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import { Countries, GenderSelect } from '../../module';
import { ControllerProps } from 'react-hook-form/dist/types/props';
import { DatePickerRange, Input, Select } from '../Inputs';
import Regions from '../../module/Regions';
import StatusConfirm from '../../module/StatusConfirm';
import ForeignCities from '../../module/ForeignCities';
import { FieldError } from 'react-hook-form';

interface PersonsAdditionalProps extends Pick<ControllerProps<'input'>, 'control'> {
  errors: { [key: string]: FieldError };
  watch: any;
}

const PersonsAdditional: FC<PersonsAdditionalProps> = ({ watch, errors, control }) => {
  const watchIsRussia: { label: string; value: string } | undefined = watch('isRussia');
  const watchCountry: { label: string; value: string } | undefined = watch('dirCountry');
  return (
    <>
      <Grid item xs={3}>
        <Input
          label="Предыдущая фамилия"
          control={control}
          error={!!errors['previousLastname']}
          name="previousLastname"
        />
      </Grid>
      <Grid item xs={4}>
        <DatePickerRange
          start={{ label: 'Дата рождения с', control, error: !!errors['birthday_gte'], name: 'birthday_gte' }}
          end={{ label: 'по', control, error: !!errors['birthday_lte'], name: 'birthday_lte' }}
        />
      </Grid>
      <Grid item xs={2}>
        <GenderSelect label="Пол" control={control} error={!!errors['isMale']} name="isMale" />
      </Grid>
      <Grid item xs={3}>
        <Input label="Email" control={control} error={!!errors['email']} name="email" />
      </Grid>
      <Grid item xs={3}>
        <Select
          label="Россия/Зарубеж"
          data={[
            { value: '', label: ' ' },
            { value: true, label: 'Россия' },
            { value: false, label: 'Зарубеж' },
          ]}
          control={control}
          error={!!errors['isRussia']}
          name="isRussia"
        />
      </Grid>
      {watchIsRussia?.value && (
        <Grid item xs={4}>
          <Regions label="Регион РФ" control={control} error={!!errors['dirRegion']} name="dirRegion" />
        </Grid>
      )}
      {watchIsRussia && !watchIsRussia?.value && (
        <>
          <Grid item xs={4}>
            <Countries label="Название страны" control={control} error={!!errors['dirCountry']} name="dirCountry" />
          </Grid>
          {watchCountry?.value && (
            <Grid item xs={4}>
              <ForeignCities
                label="Название города"
                control={control}
                filter={{ dirCountry: { id: watchCountry?.value } }}
                error={!!errors['dirForeignCity']}
                name="dirForeignCity"
              />
            </Grid>
          )}
        </>
      )}
      <Grid item xs={3}>
        <StatusConfirm
          control={control}
          label="Статус персоны"
          error={!!errors['clsStatusPerson']}
          name="clsStatusPerson"
        />
      </Grid>
      <Grid item xs={3}>
        <Select
          label="В архиве"
          data={[
            { value: '', label: ' ' },
            { value: false, label: 'Нет' },
            { value: true, label: 'Да' },
          ]}
          control={control}
          error={!!errors['archive']}
          name="archive"
          defaultValue={{ value: false, label: 'Нет' }}
        />
      </Grid>
      <Grid item xs={3}>
        <Select
          label="Является дубликатом"
          data={[
            { value: '', label: ' ' },
            { value: false, label: 'Нет' },
            { value: true, label: 'Да' },
          ]}
          control={control}
          error={!!errors['isDuplicated']}
          name="isDuplicated"
        />
      </Grid>
    </>
  );
};

export default PersonsAdditional;
