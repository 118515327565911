import { Button, Grid, Link } from '@material-ui/core';
import React, { FC, Fragment } from 'react';

import ClearIcon from '@material-ui/icons/Clear';
import { DatePicker } from '../../../Inputs';
import OrganizationsSelect from '../../../../module/Organizations';
import Positions from '../../../../module/Positions';
import SaveIcon from '@material-ui/icons/Save';
import { Scalars } from '../../../../api';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import userRights from '../../../../config/userRights';

interface formState {
  organization?: { label: string; value: any };
  clsPosition?: { label: any; value: any };
  employmentDate?: Date;
  dismissalDate?: Date;
  orgId?: Scalars['UUID'];
}

const OrgForm: FC<{
  action: string;
  defaultValue: any;
  sport: any;
  onAdd: Function;
  onEdit: Function;
  onCancel: Function;
}> = ({ action, defaultValue, sport, onAdd, onEdit, onCancel }) => {
  const { hasAdditionalFilter } = userRights();
  const defaultState: formState = defaultValue || {
    organization: undefined,
    clsPosition: undefined,
    employmentDate: undefined,
    dismissalDate: undefined,
  };

  const onHandleCancel = () => {
    (action === 'edit' && reset({})) || reset({});

    onCancel();
  };

  const { handleSubmit, control, errors, watch, reset, formState } = useForm({
    defaultValues: defaultState,
  });

  return (
    <form
      onSubmit={handleSubmit((values) => (action === 'add' ? onAdd(values) : onEdit(values)))}
      style={{ width: '100%' }}
    >
      <Grid container style={{ marginBottom: 15 }} spacing={2}>
        {action !== 'fire' && (
          <Fragment>
            <Grid item xs={12}>
              <OrganizationsSelect
                filter={{
                  ...(hasAdditionalFilter.organizations || {}),
                  sports_some: {
                    dirSport: { id: sport.dirSport.value },
                  },
                  archive: null,
                }}
                label={(action === 'add' && 'Название или огрн Организации') || 'Организация'}
                control={control}
                error={!!errors['organization']}
                name="organization"
                disabled={action !== 'add'}
                rules={{ required: true }}
              />
            </Grid>

            <Grid item md={3} xs={12}>
              <Positions
                label="Должность"
                error={!!errors['clsPosition']}
                name="clsPosition"
                control={control}
                rules={{ required: true }}
              />
            </Grid>

            <Grid item md={3} xs={12}>
              <DatePicker
                name="employmentDate"
                label="Дата найма"
                control={control}
                error={!!errors['employmentDate']}
                disabled={action === 'transfer'}
                rules={{ required: true }}
              />
            </Grid>
          </Fragment>
        )}

        {(action === 'transfer' || (action === 'edit' && defaultValue.dismissalDate)) && (
          <Grid item md={3} xs={12}>
            <DatePicker
              name="dismissalDate"
              label={(action === 'transfer' && 'Дата перевода') || 'Дата увольнения'}
              control={control}
              error={!!errors['dismissalDate']}
              rules={{ required: true }}
            />
          </Grid>
        )}
        <Grid item container justify="flex-end" spacing={2}>
          <Grid item>
            <Button
              variant="outlined"
              color="secondary"
              size="small"
              startIcon={<ClearIcon />}
              type="button"
              onClick={onHandleCancel}
            >
              Отменить
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              startIcon={<SaveIcon />}
              type="submit"
              disabled={!formState.isDirty}
            >
              Сохранить
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};

export default OrgForm;
