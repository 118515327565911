import moment from 'moment';
import { CalendarEventByPeriodFragment } from '../../api';
import _ from 'lodash';

export const filterTreeByMonth = (data: CalendarEventByPeriodFragment[], year: string) => {
  moment.locale('ru');
  const months = moment.months();
  const monthObj = {};
  for (const month of months) {
    const events = data?.filter(
      (item) =>
        moment(item?.startDate).format('MMMM').toLowerCase() === month.toLowerCase() &&
        moment(item?.startDate).format('YYYY') === year,
    );
    if (events?.length > 0) {
      monthObj[month] = events;
    }
  }
  return monthObj;
};
export const buildTreeByYear = (data: CalendarEventByPeriodFragment[]) => {
  const yearArray = _.uniq(data?.map((item) => moment(item?.startDate).format('YYYY')));
  const result: any = {};
  for (const year of yearArray) {
    result[year] = filterTreeByMonth(data, year);
  }
  return result;
};
