import React, { FC } from 'react';

import { ControllerProps } from 'react-hook-form/dist/types/props';
import { Select } from '../components/Inputs';
import { useDisciplineGroupsQuery } from '../api';

interface GroupDisciplines extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  error: boolean | undefined;
  filter?: any;
  multiple?: boolean;
  disabled?: boolean;
  onSuperChange?: any;
}

const GroupDisciplines: FC<GroupDisciplines> = ({
  filter = {},
  label,
  error,
  control,
  name,
  rules,
  multiple,
  disabled,
  onSuperChange,
}) => {
  const { data, loading } = useDisciplineGroupsQuery({ variables: { filter } });
  return (
    <Select
      label={label}
      data={(data && data.dirSportDisciplineGroups) || []}
      control={control}
      error={error}
      loading={loading}
      onSuperChange={onSuperChange}
      name={name}
      rules={rules}
      multiple={multiple}
      disabled={disabled}
    />
  );
};

export default GroupDisciplines;
