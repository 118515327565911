import {
  AddOrganizationChildrenMutationHookResult,
  RemoveOrganizationChildrenMutationHookResult,
  RemoveOrganizationParentMutationHookResult,
  UpdateOrganizationParentMutationHookResult,
  useAddOrganizationChildrenMutation,
  useOrganizationByIdQuery,
  useRemoveOrganizationChildrenMutation,
  useRemoveOrganizationParentMutation,
  useUpdateOrganizationParentMutation,
} from '../../../api';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Link,
  ListItem,
  Tooltip,
} from '@material-ui/core';
import { DirOrganization, Scalars } from '../../../types/graphql';
import React, { FC, useEffect, useState } from 'react';

import AddIcon from '@material-ui/icons/AddBox';
import ConfirmDialog from '../../ConfirmDialog';
import EditIcon from '@material-ui/icons/Edit';
import Organizations from '../../../module/Organizations';
import RemoveIcon from '@material-ui/icons/Delete';
import _ from 'lodash';
import { useForm } from 'react-hook-form';

const OrganizationStructure: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const [besideArray, setBesideArray] = useState<Array<Scalars['UUID']>>([]);

  const { handleSubmit, control, errors, reset, formState } = useForm({
    defaultValues: {
      dirOrganization: undefined,
    },
  });

  const {
    handleSubmit: childFormHandleSubmit,
    control: childFormControl,
    errors: childFormError,
    reset: childFormReset,
    formState: childFormState,
  } = useForm({
    defaultValues: {
      dirOrganization: undefined,
    },
  });

  const [open, setOpen] = useState('');

  const { refetch, data } = useOrganizationByIdQuery({
    variables: {
      id,
    },
    fetchPolicy: 'no-cache',
  });
  const [deleteItem, setDeleteItem] = useState<any>(null);
  const [updateParentOrganization]: UpdateOrganizationParentMutationHookResult = useUpdateOrganizationParentMutation();
  const [addChildrenOrganization]: AddOrganizationChildrenMutationHookResult = useAddOrganizationChildrenMutation();
  const [removeParentOrganization]: RemoveOrganizationParentMutationHookResult = useRemoveOrganizationParentMutation();
  const [
    removeChildrenOrganization,
  ]: RemoveOrganizationChildrenMutationHookResult = useRemoveOrganizationChildrenMutation();

  const [parent, children] = [_.get(data, 'dirOrganization.parent'), _.get(data, 'dirOrganization.children')];

  const handleClickOpen = (name: string) => {
    setOpen(name);
  };

  const handleClose = () => {
    setOpen('');
  };
  const updateBesideArray = (data: any) => {
    let availableChildArray = [id];

    if (data && data.parent) {
      availableChildArray = [...availableChildArray, data.parent.id];
    }
    if (data && data.children && data.children.length > 0) {
      availableChildArray = [...availableChildArray, ...data.children.map((child: DirOrganization) => child.id)];
    }

    setBesideArray(_.sortedUniq(availableChildArray));
  };

  const handleUpdateParentOrganization = async (values: any) => {
    updateParentOrganization({
      variables: {
        id,
        parentId: values.dirOrganization.value,
      },
    }).then((resp) => {
      if (resp && resp.data) {
        const updateDirOrganization = Object.keys(resp.data)[0];
        refetch();
        updateBesideArray(updateDirOrganization);
        setOpen('');
        reset();
      }
    });
  };

  const handleRemoveParentOrganization = () => {
    removeParentOrganization({
      variables: {
        id,
      },
    }).then((resp) => {
      if (resp && resp.data) {
        const updateDirOrganization = Object.keys(resp.data)[0];
        refetch();
        updateBesideArray(updateDirOrganization);
      }
    });
  };

  const handleAddChildOrganization = async (values: any) => {
    addChildrenOrganization({
      variables: {
        id,
        childID: values.dirOrganization.value,
      },
    }).then((resp) => {
      if (resp && resp.data) {
        const updateDirOrganization = Object.keys(resp.data)[0];
        refetch();
        updateBesideArray(updateDirOrganization);
        setOpen('');
        childFormReset();
      }
    });
  };

  const handleRemoveChildren = (childID: Scalars['UUID']) => {
    removeChildrenOrganization({
      variables: {
        id,
        childID,
      },
    }).then((resp) => {
      if (resp && resp.data) {
        const updateDirOrganization = Object.keys(resp.data)[0];
        updateBesideArray(updateDirOrganization);
        refetch();
      }
    });
  };

  useEffect(() => {
    updateBesideArray({
      parent,
      children,
    });
  }, [data]);

  return (
    <React.Fragment>
      <Grid container>
        <Grid container>
          <Grid container alignItems="center" justify="space-between">
            <Grid item>
              <h3 style={{ margin: 0 }}>Вышестоящая организация:</h3>
            </Grid>
            <Grid item>
              {!parent && !readonly && (
                <Tooltip title="Добавить" placement="top-start">
                  <IconButton onClick={() => handleClickOpen('parentDialog')} color="primary">
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          </Grid>

          {parent && (
            <Grid container alignItems="center" justify="space-between">
              <Grid item>
                <Link
                  style={{ fontWeight: 500, cursor: 'pointer' }}
                  target="_blank"
                  href={`/organization/${parent.id}`}
                >
                  {parent.name}
                </Link>
                <span style={{ marginLeft: '2rem', fontWeight: 500 }}>{parent.ogrn}</span>
              </Grid>
              {!readonly && (
                <Grid item>
                  <Tooltip title="Изменить" placement="top-start">
                    <IconButton color="primary" onClick={() => handleClickOpen('parentDialog')}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Удалить" placement="top-start">
                    <IconButton color="secondary" onClick={() => setDeleteItem(parent.id)}>
                      <RemoveIcon />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
              <ConfirmDialog
                title={`Удаление`}
                text={`Вы уверены что хотите удалить : ${parent.name} `}
                btnVariant="outlined"
                open={deleteItem === parent.id}
                saveBtnText="удалить"
                cancelBtnText="отмена"
                saveBtnColor="secondary"
                cancelBtnColor="primary"
                onCancel={() => setDeleteItem(null)}
                onSave={() => {
                  handleRemoveParentOrganization();
                  setDeleteItem(null);
                }}
              />
            </Grid>
          )}
          <Divider />
        </Grid>

        <Grid container>
          <Grid container alignItems="center" justify="space-between">
            <Grid item>
              <h3 style={{ margin: 0 }}>Дочерние организации:</h3>
            </Grid>
            {!readonly && (
              <Grid item>
                <Tooltip title="Добавить" placement="top-start">
                  <IconButton color="primary" onClick={() => handleClickOpen('childrenDialog')}>
                    <AddIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            )}
          </Grid>

          {children &&
            children.length > 0 &&
            children.map((child: DirOrganization) => (
              <Grid container alignItems="center" justify="space-between" key={child.id}>
                <Grid item>
                  <Link
                    style={{ fontWeight: 500, cursor: 'pointer' }}
                    target="_blank"
                    href={`/organization/${child.id}`}
                  >
                    {child.name}
                  </Link>
                  <span style={{ marginLeft: '2rem', fontWeight: 500 }}>{child.ogrn}</span>
                </Grid>
                {!readonly && (
                  <Grid item>
                    <Tooltip title="Удалить" placement="top-start">
                      <IconButton color="secondary" onClick={() => setDeleteItem(child.id)}>
                        <RemoveIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                )}
                <ConfirmDialog
                  title={`Удаление`}
                  text={`Вы уверены что хотите удалить : ${child.name} `}
                  btnVariant="outlined"
                  open={deleteItem === child.id}
                  saveBtnText="удалить"
                  cancelBtnText="отмена"
                  saveBtnColor="secondary"
                  cancelBtnColor="primary"
                  onCancel={() => setDeleteItem(null)}
                  onSave={() => {
                    handleRemoveChildren(child.id);
                    setDeleteItem(null);
                  }}
                />
              </Grid>
            ))}
        </Grid>
      </Grid>
      <Dialog
        open={open === 'parentDialog'}
        onClose={handleClose}
        aria-labelledby="parent-dialog-title"
        aria-describedby="parent-dialog-description"
        PaperProps={{
          style: { overflowY: 'unset' },
        }}
      >
        <DialogTitle id="parent-dialog-title">Выбирать организацию</DialogTitle>
        <DialogContent style={{ padding: '1rem' }}>
          <form onSubmit={handleSubmit(handleUpdateParentOrganization)} style={{ width: '40ch' }}>
            <Organizations
              label="организация"
              name="dirOrganization"
              filter={{ id_not_in: besideArray }}
              control={control}
              error={!!errors['dirOrganization']}
              rules={{ required: true }}
            />
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              style={{ width: '100%', marginTop: '1rem' }}
              disabled={!formState.isDirty}
            >
              Сохранить
            </Button>
          </form>
        </DialogContent>
      </Dialog>
      <Dialog
        open={open === 'childrenDialog'}
        onClose={handleClose}
        aria-labelledby="children-dialog-title"
        aria-describedby="children-dialog-description"
        PaperProps={{
          style: { overflowY: 'unset' },
        }}
      >
        <DialogTitle id="children-dialog-title">Выбирать организации</DialogTitle>
        <DialogContent style={{ padding: '1rem' }}>
          <form onSubmit={childFormHandleSubmit(handleAddChildOrganization)} style={{ width: '40ch' }}>
            <Organizations
              label="организация"
              name="dirOrganization"
              control={childFormControl}
              filter={{ id_not_in: besideArray }}
              error={!!childFormError['dirOrganization']}
              rules={{ required: true }}
            />
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              style={{ width: '100%', marginTop: '1rem' }}
              disabled={!childFormState.isDirty}
            >
              Сохранить
            </Button>
          </form>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default OrganizationStructure;
