import React, { FC, Fragment, useState, useMemo } from 'react';
import {
  Button,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
  Link,
  DialogContent,
  Dialog,
  DialogTitle,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import getMessage from '../../../../messages';
import SportingEventRequestForm from './SportingEventRequestForm';
import RequestStatusBoard from './RequestStatusBoard';
import {
  Scalars,
  CreateSportingEventRequestStatusMutationHookResult,
  useCreateSportingEventRequestStatusMutation,
  UploadFileMutationHookResult,
  useUploadFileMutation,
  useClsSportingEventsRequestStatusQuery,
  UpdateSportingEventMutationHookResult,
  useUpdateSportingEventMutation,
  MergeSportingEventMutationHookResult,
  useMergeSportingEventMutation,
} from '../../../../api';
import _ from 'lodash';
import { compareFields } from '../../../../utils/compareFields';
import userRights from '../../../../config/userRights';
import moment from 'moment';

const useStyles = makeStyles({
  row: {
    display: 'flex',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  tinyIcon: {
    fontSize: 18,
  },
  icon: {
    fontSize: 20,
  },
  tableHead: {
    fontWeight: 'bold',
  },
  header: {
    fontWeight: 600,
    fontSize: 15,
    letterSpacing: 1.2,
  },
});

interface formState {
  status?: { label: string; value: Scalars['UUID'] };
  comment?: string;
  file?: File | { id: Scalars['UUID']; name: string; path: string };
}

const SportingEventRequestAccordion: FC<{
  readonly?: boolean;
  sportingEventRequest?: any;
  isRequestPage?: boolean;
  onUpdate: Function;
}> = ({ readonly, isRequestPage, sportingEventRequest, onUpdate }) => {
  const classes = useStyles();
  const { data: statusData, refetch } = useClsSportingEventsRequestStatusQuery();
  const status =
    sportingEventRequest?.statuses && sportingEventRequest?.statuses.length ? sportingEventRequest?.statuses[0] : null;
  const DOWNLOAD_LINK = process.env.REACT_APP_BACKEND_URI?.replace('/graphql', '');
  const currentChange = sportingEventRequest?.event?.changes?.find((event: any) =>
    event?.requests?.find((r: any) => r?.type?.value === 2),
  );

  const isExcludedRequest = sportingEventRequest?.type?.value === 3;
  const shouldSkipRoivAgreement: boolean = useMemo(() => {
    const event = sportingEventRequest?.event;
    const proxy = sportingEventRequest?.event?.proxy;
    if (isRequestPage) return compareFields(event, proxy);
    else if (currentChange) return compareFields(event, currentChange);
    else return false;
  }, [sportingEventRequest, currentChange, isRequestPage]);

  const [newStatus, setNewStatus] = useState<
    | ({ __typename?: 'DirSportingEventRequestStatusValue' | undefined } & { value: number; label: string })
    | null
    | undefined
  >(null);

  const [uploadDocumentFile]: UploadFileMutationHookResult = useUploadFileMutation();
  const [create]: CreateSportingEventRequestStatusMutationHookResult = useCreateSportingEventRequestStatusMutation();
  const [updateEvent]: UpdateSportingEventMutationHookResult = useUpdateSportingEventMutation();
  const [mergeEvent]: MergeSportingEventMutationHookResult = useMergeSportingEventMutation();

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const handleSetNewStatus = (statusId: number) => {
    const valueArray = statusData?.dirSportingEventRequestStatusValues;
    const nStatus = valueArray?.find((s) => s?.value === statusId);
    setNewStatus(nStatus);
  };

  const onEdit = async (values: formState) => {
    const { comment, file } = values;

    try {
      if (file) {
        uploadDocumentFile({
          variables: {
            file,
          },
        }).then(async (resp: any) => {
          const {
            uploadFile: { id: fileId },
          } = resp.data;
          await create({
            variables: {
              requestId: sportingEventRequest?.id,
              data: [
                {
                  ...(comment && { comment: comment as string }),
                  value: { connect: { id: newStatus?.value } },
                  file: { connect: { id: fileId } },
                },
              ],
            },
          });
          handleSnackBar('success', `заявка успешно обновлен`);
          setNewStatus(null);
        });
        onUpdate();
      } else {
        await create({
          variables: {
            requestId: sportingEventRequest?.id,
            data: [
              {
                ...(comment && { comment: comment as string }),
                value: { connect: { id: newStatus?.value } },
              },
            ],
          },
        });
        handleSnackBar('success', `заявка успешно обновлен`);
        setNewStatus(null);
      }
      if (
        (sportingEventRequest?.type?.value === 1 || sportingEventRequest?.type?.value === 2) &&
        (newStatus?.value === 1 || newStatus?.value === 2)
      ) {
        await updateEvent({
          variables: {
            data: {
              status: {
                connect: {
                  id: 1,
                },
              },
            },
            id: sportingEventRequest?.event?.id,
          },
        });
      } else if (
        (sportingEventRequest?.type?.value === 1 || sportingEventRequest?.type?.value === 2) &&
        newStatus?.value === 8
      ) {
        await updateEvent({
          variables: {
            data: {
              status: {
                connect: {
                  id: 6,
                },
              },
            },
            id: sportingEventRequest?.event?.id,
          },
        });
        if (sportingEventRequest?.type?.value === 2) {
          await mergeEvent({
            variables: {
              from: sportingEventRequest?.event?.id,
              to: sportingEventRequest?.event?.proxy?.id,
            },
          });
          handleSnackBar('success', `мероприятие успешно смержано`);
        }
      } else if (sportingEventRequest?.type?.value === 1 || sportingEventRequest?.type?.value === 2) {
        await updateEvent({
          variables: {
            data: {
              status: {
                connect: {
                  id: 3,
                },
              },
            },
            id: sportingEventRequest?.event?.id,
          },
        });
      }
      if (sportingEventRequest?.type?.value === 3 && newStatus?.value === 8) {
        await updateEvent({
          variables: {
            data: {
              status: {
                connect: {
                  id: 8,
                },
              },
            },
            id: sportingEventRequest?.event?.id,
          },
        });
      } else if (sportingEventRequest?.type?.value === 3) {
        await updateEvent({
          variables: {
            data: {
              status: {
                connect: {
                  id: 6,
                },
              },
            },
            id: sportingEventRequest?.event?.id,
          },
        });
      }
      onUpdate();
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const eventRegionsArray = useMemo(
    () => sportingEventRequest?.event?.dirRegions?.map((region: any) => region?.value),
    [sportingEventRequest],
  );
  const { who, userRegion, isRoiv } = userRights();

  const canRoivChangeRequestStatus: boolean = useMemo(() => eventRegionsArray.includes(userRegion), [
    eventRegionsArray,
    userRegion,
  ]);

  return (
    <Accordion expanded={isRequestPage}>
      <AccordionSummary
        {...(!isRequestPage && { expandIcon: <ExpandMoreIcon /> })}
        aria-controls={`${sportingEventRequest?.type?.label}-content`}
        id={`${sportingEventRequest?.type?.label}-header`}
      >
        <Grid container justify="space-between" alignItems="center">
          <Grid item md={4} xs={12}>
            <Typography className={classes.header} color="primary">
              {isRequestPage ? (
                `Тип заявки: ${sportingEventRequest?.type?.label}`
              ) : (
                <Link
                  href={`${`/request/${sportingEventRequest?.id}`}`}
                  target="_blank"
                >{`Тип заявки: ${sportingEventRequest?.type?.label}`}</Link>
              )}
            </Typography>
          </Grid>
          <Grid item md={3} xs={12}>
            <Typography>
              <strong style={{ marginRight: 5 }}>Статус:</strong> {status?.value?.label || '~'}
            </Typography>
          </Grid>
          <Grid item md={2} xs={12}>
            <Typography>
              <strong style={{ marginRight: 5 }}>Открыта:</strong>{' '}
              {sportingEventRequest?.event?.changes?.length > 0
                ? moment(currentChange?.requests?.find((r: any) => r?.type?.id === 2)?.createdAt).format('DD.MM.YYYY')
                : moment(sportingEventRequest?.createdAt).format('DD.MM.YYYY')}
            </Typography>
          </Grid>
          <Grid item md={2} xs={12}>
            {sportingEventRequest?.statuses?.find(
              (status: any) => status?.value?.value === 8 || status?.value?.value === 2,
            ) !== undefined && (
              <Typography>
                <strong style={{ marginRight: 5 }}>Закрыта:</strong>{' '}
                {moment(sportingEventRequest?.updatedAt).format('DD.MM.YYYY')}
              </Typography>
            )}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: 'column' }}>
        <Dialog open={!!newStatus} onClose={() => setNewStatus(null)}>
          <DialogTitle>
            <Typography style={{ fontSize: 20, fontWeight: 600 }}>{newStatus?.label}</Typography>
          </DialogTitle>
          <DialogContent>
            <SportingEventRequestForm
              onEdit={(values: formState) => onEdit(values)}
              onCancel={() => {
                setNewStatus(null);
              }}
            />
          </DialogContent>
        </Dialog>

        <Grid container spacing={1} style={{ marginBottom: 30 }}>
          <Grid item>
            <Button
              variant={'contained'}
              color={'primary'}
              size={'small'}
              onClick={() => handleSetNewStatus(1)}
              disabled={
                !(
                  (who === 'ADMIN' &&
                    (!status?.value ||
                      status?.value?.value === 3 ||
                      status?.value?.value === 6 ||
                      status?.value?.value === 3)) ||
                  (who === 'REGIONAL_SCHOOL' &&
                    (!status?.value || status?.value?.value === 3 || status?.value?.value === 6))
                )
              }
            >
              проект
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant={'contained'}
              color={'secondary'}
              size={'small'}
              onClick={() => handleSetNewStatus(3)}
              disabled={
                (isRoiv && !canRoivChangeRequestStatus) ||
                !(
                  (who === 'REGIONAL_SCHOOL' || who === 'ADMIN') &&
                  (status?.value?.value === 4 ||
                    status?.value?.value === 5 ||
                    status?.value?.value === 7 ||
                    ((isExcludedRequest || shouldSkipRoivAgreement) &&
                      (status?.value?.value === 1 || status?.value?.value === 8)))
                )
              }
            >
              Отозвать
            </Button>
          </Grid>
          {!isExcludedRequest && (
            <>
              <Grid item>
                <Button
                  variant={'contained'}
                  color={'primary'}
                  size={'small'}
                  onClick={() => handleSetNewStatus(4)}
                  disabled={
                    shouldSkipRoivAgreement ||
                    !(
                      (who === 'ADMIN' && status?.value?.value === 1) ||
                      (who === 'REGIONAL_SCHOOL' && status?.value?.value === 1)
                    )
                  }
                >
                  На согл. субъекту РФ
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant={'contained'}
                  color={'primary'}
                  size={'small'}
                  onClick={() => handleSetNewStatus(5)}
                  disabled={
                    shouldSkipRoivAgreement ||
                    (isRoiv && !canRoivChangeRequestStatus) ||
                    !((who === 'ADMIN' || who === 'ROIV_SPECIALIST') && status?.value?.value === 4)
                  }
                >
                  Согласована субъектом РФ
                </Button>
              </Grid>
            </>
          )}
          <Grid item>
            <Button
              variant={'contained'}
              color={'primary'}
              size={'small'}
              onClick={() => handleSetNewStatus(7)}
              disabled={
                (isRoiv && !canRoivChangeRequestStatus) ||
                !(
                  (who === 'ADMIN' || who === 'ROIV_SPECIALIST') &&
                  (status?.value?.value === 5 ||
                    ((isExcludedRequest || shouldSkipRoivAgreement) && status?.value?.value === 1))
                )
              }
            >
              На согл. в Минспорт
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant={'contained'}
              color={'primary'}
              size={'small'}
              onClick={() => handleSetNewStatus(8)}
              disabled={!((who === 'ADMIN' || who === 'MINSPORT') && status?.value?.value === 7)}
            >
              Согласована Минспортом
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant={'contained'}
              color={'secondary'}
              size={'small'}
              onClick={() => handleSetNewStatus(6)}
              disabled={
                (isRoiv && !canRoivChangeRequestStatus) ||
                !(
                  (who === 'ADMIN' || who === 'ROIV_SPECIALIST' || who === 'MINSPORT') &&
                  (status?.value?.value === 7 || status?.value?.value === 4)
                ) ||
                (who === 'ROIV_SPECIALIST' && status?.value?.value === 7)
              }
            >
              Отклонить
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant={'contained'}
              color={'secondary'}
              size={'small'}
              onClick={() => handleSetNewStatus(2)}
              disabled={
                (isRoiv && !canRoivChangeRequestStatus) ||
                !(
                  (who === 'ADMIN' && (status?.value?.value === 7 || status?.value?.value === 4)) ||
                  (who === 'ROIV_SPECIALIST' && status?.value?.value === 4) ||
                  (who === 'MINSPORT' && status?.value?.value === 5)
                )
              }
            >
              Отказать
            </Button>
          </Grid>
        </Grid>

        <Grid container spacing={2} style={{ marginBottom: 10 }}>
          <Grid item container md={6} xs={12} spacing={1}>
            <Grid item>
              <Typography style={{ fontWeight: 600 }}> Документ:</Typography>
            </Grid>
            <Grid item>
              {status?.file ? (
                <Link href={`${DOWNLOAD_LINK}${status?.file?.path}`}>
                  <Typography style={{ wordBreak: 'break-all' }}> {status?.file?.name}</Typography>
                </Link>
              ) : (
                '~'
              )}
            </Grid>
          </Grid>
          <Grid item container md={6} xs={12} spacing={1}>
            <Grid item>
              <Typography style={{ fontWeight: 600 }}> Комментарий:</Typography>
            </Grid>
            <Grid item>
              <Typography> {status?.comment || '~'}</Typography>
            </Grid>
          </Grid>
        </Grid>

        <RequestStatusBoard statusArray={sportingEventRequest?.statuses || []} />
      </AccordionDetails>
    </Accordion>
  );
};

export default SportingEventRequestAccordion;
