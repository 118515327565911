import PersonAddRoundedIcon from '@material-ui/icons/PersonAddRounded';
import SportsHandballRoundedIcon from '@material-ui/icons/SportsHandballRounded';
import TimelineRoundedIcon from '@material-ui/icons/TimelineRounded';
import SportsGolfRoundedIcon from '@material-ui/icons/SportsGolfRounded';
import HomeWorkRoundedIcon from '@material-ui/icons/HomeWorkRounded';
import PeopleRoundedIcon from '@material-ui/icons/PeopleRounded';
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';
import MapIcon from '@material-ui/icons/Map';
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';
import RoomIcon from '@material-ui/icons/Room';
import TodayIcon from '@material-ui/icons/Today';
import SportsKabaddiRoundedIcon from '@material-ui/icons/SportsKabaddiRounded';
import LocalActivityIcon from '@material-ui/icons/LocalActivity';
import DescriptionIcon from '@material-ui/icons/Description';
import GavelIcon from '@material-ui/icons/Gavel';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ListAltIcon from '@material-ui/icons/ListAlt';
import organizationsGray from '../../../../static/img/Sidebar/svg/org.svg';
import personsGray from '../../../../static/img/Sidebar/svg/useradd.svg';
import athletesGray from '../../../../static/img/Sidebar/svg/athlete.svg';
import trainersGray from '../../../../static/img/Sidebar/svg/trainer.svg';
import refereesGray from '../../../../static/img/Sidebar/svg/referee.svg';
import national_teamsGray from '../../../../static/img/Sidebar/svg/team.svg';
import sporting_eventsGray from '../../../../static/img/Sidebar/svg/fcpsr.svg';
import documentsGray from '../../../../static/img/Sidebar/svg/doc.svg';
import minsportsGray from '../../../../static/img/Sidebar/svg/ecp.svg';
import evscGray from '../../../../static/img/Sidebar/svg/evsc.svg';
import FCPSRcalendarsGray from '../../../../static/img/Sidebar/svg/FCPSRcalendars.svg';
import FederalDistrictsGray from '../../../../static/img/Sidebar/svg/FederalDistricts.svg';
import ForeignCitiesGray from '../../../../static/img/Sidebar/svg/ForeignCities.svg';
import MinsportCalendarsGray from '../../../../static/img/Sidebar/svg/MinsportCalendars.svg';
import regionsGray from '../../../../static/img/Sidebar/svg/regions.svg';
import GroupGray from '../../../../static/img/Sidebar/svg/Group.svg';
import classifiersGray from '../../../../static/img/Sidebar/svg/classifiers.svg';
import unification_tasksGray from '../../../../static/img/Sidebar/svg/task.svg';
import { MenuPoint } from '../../../../types/types';

const topPoints: MenuPoint[] = [
  {
    icon: HomeWorkRoundedIcon,
    title: 'Спортивные организации',
    id: 'organizations',
    link: '/organizations',
    srcGray: organizationsGray,
  },
  {
    icon: PersonAddRoundedIcon,
    title: 'Персоны',
    id: 'person',
    link: '/persons',
    srcGray: personsGray,
  },

  {
    icon: SportsHandballRoundedIcon,
    title: 'Спортсмены',
    id: 'athletes',
    link: '/athletes',
    srcGray: athletesGray,
  },
  {
    icon: TimelineRoundedIcon,
    title: 'Тренеры',
    id: 'trainers',
    link: '/trainers',
    srcGray: trainersGray,
  },
  {
    icon: GavelIcon,
    title: 'Судьи',
    id: 'referees',
    link: '/referees',
    srcGray: refereesGray,
  },
  {
    icon: SportsKabaddiRoundedIcon,
    title: 'Сборные команды',
    id: 'national_teams',
    link: '/national_teams',
    srcGray: national_teamsGray,
  },
  {
    icon: LocalActivityIcon,
    title: 'Спортмероприятия ФЦПСР',
    id: 'sporting_events',
    link: '/sporting_events',
    srcGray: sporting_eventsGray,
  },
  {
    icon: DescriptionIcon,
    title: 'Документы',
    id: 'documents',
    link: '/documents',
    srcGray: documentsGray,
  },
  {
    icon: ListAltIcon,
    title: 'Задания на объединение',
    id: 'unificationtasks',
    link: '/unification_tasks',
    srcGray: unification_tasksGray,
  },
];

const bottomPoints: MenuPoint[] = [
  {
    icon: SportsGolfRoundedIcon,
    title: 'ЕВСК',
    id: 'type_sports',
    link: '/type_sports',
    srcGray: evscGray,
  },
  {
    icon: TodayIcon,
    title: 'Календари Минспорт',
    id: 'eventscalendar',
    link: '/events',
    srcGray: MinsportCalendarsGray,
  },
  {
    icon: DateRangeIcon,
    title: 'Календари ФЦПСР',
    id: 'calendars',
    link: '/calendars',
    srcGray: FCPSRcalendarsGray,
  },
  {
    icon: AssignmentRoundedIcon,
    title: 'Классификаторы',
    id: 'classifiers',
    link: '/classifiers',
    srcGray: classifiersGray,
  },
  {
    icon: MapIcon,
    title: 'Регионы',
    id: 'region',
    link: '/regions',
    srcGray: regionsGray,
  },
  {
    icon: RoomIcon,
    title: 'Федеральные округа',
    id: 'federal_district',
    link: '/federal_districts',
    srcGray: FederalDistrictsGray,
  },
  {
    icon: NotListedLocationIcon,
    title: 'Зарубежные города',
    id: 'foreign_cities',
    link: '/foreign_cities',
    srcGray: ForeignCitiesGray,
  },
  {
    divider: true,
    icon: PeopleRoundedIcon,
    title: 'Администрирование пользователей',
    id: 'users',
    link: '/users',
    srcGray: GroupGray,
  },
];

const underMenuPoints: MenuPoint[] = [
  {
    icon: NotListedLocationIcon,
    title: 'ЕКП Минспорт',
    id: 'minsports',
    link: '/minsports',
    srcGray: minsportsGray,
  },
];

export { topPoints, bottomPoints, underMenuPoints };
