import _ from 'lodash';
import { isArrayEqual } from './isArraysEqual';

export const availableTags = [
  'isImportant',
  'isOlympicGamesPreparation',
  'includingTrainingActivities',
  'federalBudgetFinancing',
];

export const detectedChanges = (childEvent: any) => {
  const changesArray: string[] = [];
  const eventsCalendarArrayChange: string[] = [];
  const eventsQuotaArrayChange: string[] = [];
  const teamArrayChange: string[] = [];
  const programTypeArrayChange: string[] = [];
  const documentsArrayChange: string[] = [];

  if (childEvent) {
    const { proxy, ...childEventProps } = childEvent;
    _.forEach(proxy, (value, key) => {
      const childValue = childEventProps[key];
      if (key === 'sports') {
        if (
          !isArrayEqual(
            value?.map((v: any) => v?.dirSport?.label),
            childValue?.map((v: any) => v?.dirSport?.label),
          )
        )
          changesArray.push('dirSport');
        if (
          !isArrayEqual(
            _.flattenDeep(value?.map((v: any) => v?.disciplineGroups?.map((group: any) => group?.label))),
            _.flattenDeep(childValue?.map((v: any) => v?.disciplineGroups?.map((group: any) => group?.label))),
          )
        )
          changesArray.push('disciplineGroups');
        if (
          !isArrayEqual(
            _.flattenDeep(value?.map((v: any) => v?.disciplines?.map((discipline: any) => discipline?.label))),
            _.flattenDeep(childValue?.map((v: any) => v?.disciplines?.map((discipline: any) => discipline?.label))),
          )
        )
          changesArray.push('disciplines');
      } else if (key === 'ageGroups') {
        if (
          !isArrayEqual(
            value?.map((v: any) => v?.clsAgeGroup?.label),
            childEventProps[key]?.map((v: any) => v?.clsAgeGroup?.label),
          )
        ) {
          changesArray.push(key);
        }
      } else if (!_.isEqual(value, childEventProps[key])) changesArray.push(key);
    });
    _.forEach(availableTags, (item: string) => {
      if (proxy && proxy[item] !== childEventProps[item]) {
        changesArray.push('tags');
      }
    });
    _.forEach(childEventProps.competitionCalendar, (element: any) => {
      if (
        !_.isEqual(
          element,
          proxy?.competitionCalendar.find((e: any) => e.id === element.id),
        )
      )
        eventsCalendarArrayChange.push(element.id);
      else if (proxy?.competitionCalendar?.length === 0 && childEventProps?.competitionCalendar?.length !== 0)
        eventsCalendarArrayChange.push(element.id);
    });

    _.forEach(childEventProps.dirNationalTeams, (element: any) => {
      if (
        !_.isEqual(
          element,
          proxy?.dirNationalTeams.find((e: any) => e.id === element.id),
        )
      )
        teamArrayChange.push(element.id);
      else if (proxy?.dirNationalTeams?.length === 0 && childEventProps?.dirNationalTeams?.length !== 0)
        teamArrayChange.push(element.id);
    });

    _.forEach(childEventProps.programTypes, (element: any) => {
      if (
        !_.isEqual(
          element,
          proxy?.programTypes.find((e: any) => e.id === element.id),
        )
      )
        programTypeArrayChange.push(element.id);
      else if (proxy?.programTypes?.length === 0 && childEventProps?.programTypes?.length !== 0)
        programTypeArrayChange.push(element.id);
    });

    _.forEach(childEventProps.quota, (value, key) => {
      if (value && proxy?.quota && !_.isEqual(value, proxy?.quota[key])) eventsQuotaArrayChange.push(key);
      else if ((value && !proxy?.quota) || (value && !proxy?.quota[key])) eventsQuotaArrayChange.push(key);
    });

    _.forEach(childEventProps.competitionReglaments, (element: any) => {
      if (
        !_.isEqual(
          element,
          proxy?.competitionReglaments.find((e: any) => e.id === element.id),
        )
      )
        documentsArrayChange.push(element.id);
      else if (proxy?.competitionReglaments?.length === 0 && childEventProps?.competitionReglaments?.length !== 0)
        documentsArrayChange.push(element.id);
    });

    _.forEach(childEventProps.competitionProtocols, (element: any) => {
      if (
        !_.isEqual(
          element,
          proxy?.competitionProtocols.find((e: any) => e.id === element.id),
        )
      )
        documentsArrayChange.push(element.id);
      else if (proxy?.competitionProtocols?.length === 0 && childEventProps?.competitionProtocols?.length !== 0)
        documentsArrayChange.push(element.id);
    });

    _.forEach(childEventProps.refereeCollegiumDocuments, (element: any) => {
      if (
        !_.isEqual(
          element,
          proxy?.refereeCollegiumDocuments.find((e: any) => e.id === element.id),
        )
      )
        documentsArrayChange.push(element.id);
      else if (
        proxy?.refereeCollegiumDocuments?.length === 0 &&
        childEventProps?.refereeCollegiumDocuments?.length !== 0
      )
        documentsArrayChange.push(element.id);
    });
  }
  return {
    changesArray,
    eventsCalendarArrayChange,
    eventsQuotaArrayChange,
    teamArrayChange,
    programTypeArrayChange,
    documentsArrayChange,
  };
};
