import React, { useState } from 'react';
import { Button, Grid, IconButton, Typography } from '@material-ui/core';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import { DropzoneDialog as DropzoneDialogOld } from 'material-ui-dropzone';
import {
  UpdateCalendarMutationHookResult,
  UploadFileMutationHookResult,
  useCalendarSignedReportsQuery,
  useUpdateCalendarMutation,
  useUploadFileMutation,
} from '../../../../api';
import { useSnackbar } from 'notistack';
import DeleteIcon from '@material-ui/icons/Delete';
import getMessage from '../../../../messages';

const SignedReports = ({ id, regionId }: { id?: string; regionId: string }) => {
  const DropzoneDialog = DropzoneDialogOld as any;
  const [open, setOpen] = useState(false);
  const [uploadDocumentFile]: UploadFileMutationHookResult = useUploadFileMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { data, refetch } = useCalendarSignedReportsQuery({
    variables: {
      id,
      regionId,
    },
    skip: !(id && regionId),
  });
  const [update]: UpdateCalendarMutationHookResult = useUpdateCalendarMutation();

  const onAddNewDocument = async (files: File[]) => {
    if (files.length > 0) {
      const fileIds: string[] = [];
      await Promise.all(
        files.map(async (item) => {
          const fileResp = await uploadDocumentFile({
            variables: {
              file: item,
            },
          });
          if (fileResp?.data?.uploadFile?.id) {
            fileIds.push(fileResp.data?.uploadFile?.id);
          }
        }),
      );
      if (fileIds.length > 0) {
        await update({
          variables: {
            id,
            data: {
              signedReports: {
                create: [
                  {
                    dirRegion: {
                      connect: {
                        id: regionId,
                      },
                    },
                    dirCalendar: {
                      connect: {
                        id,
                      },
                    },
                    files: {
                      connect: fileIds.map((fId) => ({ id: fId })),
                    },
                  },
                ],
              },
            },
          },
        });
        refetch();
        setOpen(false);
        enqueueSnackbar(`Файл(ы) успешно добавлен(ы)`, { variant: 'success' });
      }
      return;
    }
  };

  const onDeleteFile = async (reportId: string, fileId: string) => {
    try {
      await update({
        variables: {
          id,
          data: {
            signedReports: {
              update: [
                {
                  where: { id: reportId },
                  data: {
                    files: {
                      delete: [{ id: fileId }],
                    },
                  },
                },
              ],
            },
          },
        },
      });
      enqueueSnackbar(`Файл успешно удален`, { variant: 'success' });
      refetch();
    } catch (error) {
      enqueueSnackbar(getMessage(error.message), { variant: 'error' });
    }
  };

  const DOWNLOAD_LINK = process.env.REACT_APP_BACKEND_URI?.replace('/graphql', '');

  return (
    <div style={{ margin: '15px 0' }}>
      <Button variant="outlined" size="small" color="primary" onClick={() => setOpen(true)}>
        Загрузить подписанный отчет
      </Button>
      <DropzoneDialog
        showFileNames
        open={open}
        alertSnackbarProps={{
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        }}
        showAlerts={['error']}
        getFileAddedMessage={(fileName: string) => `Файл ${fileName} успешно загружен`}
        getDropRejectMessage={(file: File) =>
          `Файл ${file.name} отклонен. Тип файла не поддерживается. Файл слишком большой. Максимальный размер - 10 мегабайт.`
        }
        getFileLimitExceedMessage={(filesLimit: number) =>
          `За один раз возможно загрузить максимально ${filesLimit} файлов`
        }
        cancelButtonText={'закрыть'}
        submitButtonText={'Загрузить'}
        dialogTitle={'Загрузить файл'}
        onClose={() => setOpen(false)}
        filesLimit={10}
        maxFileSize={10485760}
        previewText={'Предпросмотр: '}
        clearOnUnmount
        initialFiles={[]}
        onSave={(files: any) => {
          return onAddNewDocument(files);
        }}
        dropzoneText="Максимальное количество файлов за раз: 10"
      />
      {data?.dirCalendar?.signedReports && data?.dirCalendar?.signedReports?.length > 0 && (
        <Grid container style={{ marginTop: 15 }} spacing={1}>
          {data?.dirCalendar?.signedReports?.map((report, index) =>
            report.files?.map((file, fileIndex) => (
              <Grid item container alignItems="center" spacing={1}>
                <Grid item>
                  <a href={DOWNLOAD_LINK + file.path}>{file.name}</a>
                </Grid>
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                  <a style={{ height: '24px', color: 'black' }} href={DOWNLOAD_LINK + file.path}>
                    <DownloadIcon />
                  </a>
                </Grid>
                <Grid item>
                  <IconButton
                    style={{ padding: 0 }}
                    edge="end"
                    aria-label="delete"
                    onClick={() => onDeleteFile(report.id, file.id)}
                  >
                    <DeleteIcon color="error" />
                  </IconButton>
                </Grid>
              </Grid>
            )),
          )}
        </Grid>
      )}
    </div>
  );
};

export default SignedReports;
