import React, { useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { Input } from '../../../../Inputs';
import SaveIcon from '@material-ui/icons/Save';
import { useForm } from 'react-hook-form';
import {
  AddQuotaForStageTwoBySportsMutationHookResult,
  UpdateQuotaForStageTwoBySportsMutationHookResult,
  useAddQuotaForStageTwoBySportsMutation,
  useUpdateQuotaForStageTwoBySportsMutation,
} from '../../../../../api';

export const RequestApplicationModalQuotes = ({ idCalendar, open, sportName, onClose, idSport, row, onUpdate }) => {
  const { quotaMaleAthlStageTwo = '', quotaWomenAthlStageTwo = '', quotaAccompStageTwo = '' } = row?.form || {};
  const { handleSubmit, control, errors, reset } = useForm({
    defaultValues: { quotaMaleAthlStageTwo, quotaWomenAthlStageTwo, quotaAccompStageTwo },
  });

  const [create]: AddQuotaForStageTwoBySportsMutationHookResult = useAddQuotaForStageTwoBySportsMutation();
  const [update]: UpdateQuotaForStageTwoBySportsMutationHookResult = useUpdateQuotaForStageTwoBySportsMutation();

  useEffect(() => {
    reset({
      quotaMaleAthlStageTwo,
      quotaWomenAthlStageTwo,
      quotaAccompStageTwo,
    });
  }, [quotaMaleAthlStageTwo, quotaWomenAthlStageTwo, quotaAccompStageTwo, reset]);

  const handleClose = () => {
    onClose?.();
    return;
  };
  const onSubmit = async ({ quotaAccompStageTwo, quotaWomenAthlStageTwo, quotaMaleAthlStageTwo }) => {
    if (row?.form?.id) {
      await update({
        variables: {
          idQuota: row?.form?.id,
          data: {
            quotaAccompStageTwo: parseInt(quotaAccompStageTwo),
            quotaWomenAthlStageTwo: parseInt(quotaWomenAthlStageTwo),
            quotaMaleAthlStageTwo: parseInt(quotaMaleAthlStageTwo),
          },
        },
      });
    } else {
      await create({
        variables: {
          data: {
            dirCalendar: {
              connect: { id: idCalendar },
            },
            quotaAccompStageTwo: parseInt(quotaAccompStageTwo),
            quotaWomenAthlStageTwo: parseInt(quotaWomenAthlStageTwo),
            quotaMaleAthlStageTwo: parseInt(quotaMaleAthlStageTwo),
            dirSport: { connect: { id: idSport } },
          },
        },
      });
    }
    onUpdate?.();
    onClose?.();
  };

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Квоты по виду спорта: {sportName}</DialogTitle>
      <DialogContent style={{ width: 400 }}>
        <Grid container direction="column" spacing={1}>
          <Grid item container>
            <Input
              label="Квота по спорсменам Муж"
              error={!!errors['quotaMaleAthlStageTwo']}
              name="quotaMaleAthlStageTwo"
              control={control}
            />
          </Grid>
          <Grid item container>
            <Input
              label="Квота по спорсменам Жен"
              error={!!errors['quotaWomenAthlStageTwo']}
              name="quotaWomenAthlStageTwo"
              control={control}
            />
          </Grid>
          <Grid item container>
            <Input
              label="Квота по сопровождающим"
              error={!!errors['quotaAccompStageTwo']}
              name="quotaAccompStageTwo"
              control={control}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button size="small" onClick={handleClose}>
          Отменить
        </Button>
        <Button
          size="small"
          variant="outlined"
          onClick={handleSubmit(onSubmit)}
          color="primary"
          startIcon={<SaveIcon />}
        >
          Сохранить
        </Button>
      </DialogActions>
    </Dialog>
  );
};
