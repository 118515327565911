import React, { FC } from 'react';
import { useClsCalendarStagesQuery } from '../api';
import { Select } from '../components/Inputs';
import { ControllerProps } from 'react-hook-form/dist/types/props';

interface ICalendarStage extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  error: boolean | undefined;
  disabled?: boolean;
  defaultValue?: { label: string; value: string };
  registerNumber?: number;
}

const CalendarStage: FC<ICalendarStage> = ({
  label,
  error,
  control,
  name,
  rules,
  defaultValue,
  disabled,
  registerNumber,
}) => {
  const { data, loading } = useClsCalendarStagesQuery();

  return (
    <Select
      label={label}
      data={
        data?.classifierValues.filter((cls) => (registerNumber ? cls.registryNumber === registerNumber : true)) || []
      }
      control={control}
      error={error}
      name={name}
      rules={rules}
      loading={loading}
      defaultValue={defaultValue || null}
      disabled={disabled}
    />
  );
};

export default CalendarStage;
