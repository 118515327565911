import {
  Scalars,
  AthleteConnectDisciplineGroupToSportMutationHookResult,
  AthleteDisconnectDisciplineGroupFromSportMutationHookResult,
  AthleteDocument,
  useAthleteConnectDisciplineGroupToSportMutation,
  useAthleteDisconnectDisciplineGroupFromSportMutation,
} from '../../../../api';
import { useForm } from 'react-hook-form';
import React, { FC, useState } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { useSnackbar } from 'notistack';
import getMessage from '../../../../messages';
import { DirSportDiscipline, Maybe } from '../../../../api';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import GroupDisciplines from '../../../../module/GroupDisciplines';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const Disciplines: FC<{
  data?: Maybe<
    Array<
      { __typename?: 'DirSportDisciplineGroup' } & {
        value: Scalars['UUID'];
        label: string;
      }
    >
  >;
  id: string;
  idSport: string;
  refetch: any;
  readonly?: boolean;
}> = ({ data, id, refetch, idSport, readonly }) => {
  const classes = useStyles();

  const { handleSubmit, control, errors, reset, formState } = useForm({
    defaultValues: { item: null },
  });

  const { enqueueSnackbar } = useSnackbar();
  const [
    connect,
  ]: AthleteConnectDisciplineGroupToSportMutationHookResult = useAthleteConnectDisciplineGroupToSportMutation({});

  const [
    disconnect,
  ]: AthleteDisconnectDisciplineGroupFromSportMutationHookResult = useAthleteDisconnectDisciplineGroupFromSportMutation(
    {},
  );

  const onRemove = async (value: string, label: string) => {
    try {
      await disconnect({
        variables: {
          idSport: id,
          idDisciplineGroup: value,
        },
      });
      enqueueSnackbar(`Группа дисциплин "${label}" успешно удалена`, { variant: 'success' });
      refetch();
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  const onSubmit = handleSubmit(async (values?: { item?: { label: string; value: any } | null }) => {
    if (!values?.item) return;
    try {
      await connect({
        variables: {
          idSport: id,
          idDisciplineGroup: values?.item?.value,
        },
      });
      reset({ item: null });
      enqueueSnackbar(`Группа дисциплин "${values?.item?.label}" успешно добавлена`, { variant: 'success' });
      refetch();
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  });

  return (
    <Grid container>
      {!readonly && (
        <form onSubmit={onSubmit} style={{ width: '100%' }}>
          <Grid container alignItems={'flex-end'} spacing={2}>
            <Grid item md={9}>
              <GroupDisciplines
                label="Группа дисциплин"
                error={!!errors['item']}
                name="item"
                filter={{ dirSport: { id: idSport }, archive: null, id_not_in: data?.map((val) => val.value) }}
                control={control}
                rules={{ required: true }}
              />
            </Grid>
            <Grid item>
              <Button
                aria-label="add"
                type="submit"
                size={'small'}
                color={'primary'}
                variant={'outlined'}
                disabled={!formState.isDirty}
                startIcon={<SaveIcon />}
              >
                Сохранить
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
      <Grid item xs={12}>
        <List>
          {data?.map((item) => (
            <ListItem key={item.value}>
              <ListItemText primary={item.label} />
              <ListItemSecondaryAction>
                <IconButton edge="end" aria-label="delete" onClick={() => onRemove(item.value, item.label)}>
                  <DeleteIcon color="error" />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default Disciplines;
