import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  LinearProgress,
  Link,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { FC, useState } from 'react';
import { useAnalyticsSportingEventsByRegionIdQuery } from '../../../../api';
import React from 'react';
import ExpandMore from '@material-ui/icons/ExpandMore';

interface EventListByYearAndCalendarCategoryProps {
  calendarCategoryRegistryNumber?: number;
  regionId?: string;
  calendarYear?: number;
}

const useStyles = makeStyles({
  row: {
    display: 'flex',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  tinyIcon: {
    fontSize: 18,
  },
  icon: {
    fontSize: 20,
  },
  tableHead: {
    fontWeight: 'bold',
  },
  header: {
    fontWeight: 600,
    fontSize: 16,
    letterSpacing: 1.2,
  },
  verticalSpace: {
    marginTop: 5,
    marginBottom: 5,
  },
});

export const EventListByYearAndCalendarCategory: FC<EventListByYearAndCalendarCategoryProps> = ({
  calendarCategoryRegistryNumber,
  regionId,
  calendarYear,
}) => {
  const canLoad = !!(calendarCategoryRegistryNumber && regionId);
  const { loading, data } = useAnalyticsSportingEventsByRegionIdQuery({
    fetchPolicy: 'no-cache',
    skip: !canLoad,
    variables: {
      filter: {
        dirNationalTeams_some: {
          dirRegion: {
            id: regionId,
          },
        },
        dirCalendar: {
          clsCalendarCategory: {
            registryNumber: calendarCategoryRegistryNumber,
          },
          year: calendarYear,
        },
      },
    },
  });

  const hasNoData = data && data?.dirSportingEvents?.length === 0;

  if (!canLoad) return null;

  if (loading) return <LinearProgress color="primary" variant="query" />;
  return (
    <Grid container spacing={2} style={{ marginTop: 20 }}>
      {data?.dirSportingEvents?.map((event) => (
        <Grid item container key={event?.id}>
          <Link href={`/sporting_event/${event?.id}`} target="_blank">
            <Typography style={{ fontSize: 14, fontWeight: 600 }}>{event?.name}</Typography>
          </Link>
        </Grid>
      ))}
      {hasNoData && <Typography>нет данных</Typography>}
    </Grid>
  );
};

export const EventListByYearAndCalendarCategoryAccordion: FC<EventListByYearAndCalendarCategoryProps> = ({
  calendarCategoryRegistryNumber,
  regionId,
  calendarYear,
}) => {
  const [expanded, setExpanded] = useState(false);
  const classes = useStyles();

  const canLoad = !!(calendarCategoryRegistryNumber && regionId && calendarYear);
  if (!canLoad) return null;

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div style={{ margin: '10px 0' }}>
      <Accordion expanded={expanded} onChange={handleExpand} key={calendarYear}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls={`${calendarYear}-content`}
          id={`${calendarYear}-header`}
        >
          <div>
            <Typography className={classes.header} color="primary">
              {calendarYear}
            </Typography>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            {expanded && (
              <EventListByYearAndCalendarCategory
                calendarYear={calendarYear}
                regionId={regionId}
                calendarCategoryRegistryNumber={calendarCategoryRegistryNumber}
              />
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};
