import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Typography,
} from '@material-ui/core';
import {
  Scalars,
  UploadFileMutationHookResult,
  useCalendarRegulationStatusValuesQuery,
  useUploadFileMutation,
  useCreateCalendarRegulationMutation,
  CreateCalendarRegulationMutationHookResult,
  UpdateCalendarRegulationMutationHookResult,
  useUpdateCalendarRegulationMutation,
} from '../../../../api';
import React, { FC, useMemo, useState } from 'react';

import StatusBoard, { TStatus } from './StatusBoard';
import StatusForm from './StatusForm';
import getMessage from '../../../../messages';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import userRights from '../../../../config/userRights';

const useStyles = makeStyles({
  row: {
    display: 'flex',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  tinyIcon: {
    fontSize: 18,
  },
  icon: {
    fontSize: 20,
  },
  tableHead: {
    fontWeight: 'bold',
  },
  header: {
    fontWeight: 600,
    fontSize: 15,
    letterSpacing: 1.2,
  },
});

interface formState {
  status?: { label: string; value: Scalars['UUID'] };
  comment?: string;
  file?: File | { id: Scalars['UUID']; name: string; path: string };
}

const Status: FC<{
  readonly?: boolean;
  regulation?: any;
  Header?: React.ReactNode;
  id?: string;
  sportId?: string;
  onUpdate: Function;
  onlyWord?: boolean;
}> = ({ onlyWord, readonly, id, sportId, regulation, onUpdate, Header }) => {
  const classes = useStyles();
  const { isSportingEventOperatorFspcr } = userRights();
  const { data: statusData } = useCalendarRegulationStatusValuesQuery();

  const status = useMemo(() => regulation?.status[0] || null, [regulation]);

  const statusValue = useMemo(() => status?.value?.value || -1, [status]);

  const r_only = isSportingEventOperatorFspcr ? false : readonly;

  const statusArray = useMemo(() => regulation?.status || [], [regulation]);
  const DOWNLOAD_LINK = process.env.REACT_APP_BACKEND_URI?.replace('/graphql', '');

  const [newStatus, setNewStatus] = useState<any>(null);
  const [IsReturning, setIsReturning] = useState(false);

  const hasRegulationsDocument = useMemo(() => regulation && regulation?.regulationsDocument, [regulation]);
  const hasRegulationsAmendedDocument = useMemo(() => regulation && regulation?.regulationsAmendedDocument, [
    regulation,
  ]);
  const hasRegulationsAgreedDocument = useMemo(() => regulation && regulation?.regulationsAgreedDocument, [regulation]);

  const [uploadDocumentFile]: UploadFileMutationHookResult = useUploadFileMutation();
  const [create]: CreateCalendarRegulationMutationHookResult = useCreateCalendarRegulationMutation();
  const [update]: UpdateCalendarRegulationMutationHookResult = useUpdateCalendarRegulationMutation();

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const handleSetNewStatus = (statusId: number) => {
    const valueArray = statusData?.dirSportingEventRegulationStatusValues;
    const nStatus = valueArray?.find((s) => s?.value === statusId);
    setNewStatus(nStatus);
  };

  const onCreate = async (values: formState) => {
    const { comment, file } = values;
    const v = newStatus?.value;

    try {
      if (file) {
        uploadDocumentFile({
          variables: {
            file,
          },
        }).then(async (resp: any) => {
          const {
            uploadFile: { id: fileId },
          } = resp.data;
          regulation && regulation?.id
            ? await update({
                variables: {
                  id: regulation?.id,
                  data: {
                    status: {
                      create: [
                        {
                          ...(comment && { comment: comment as string }),
                          value: { connect: { id: v } },
                          file: { connect: { id: fileId } },
                        },
                      ],
                    },
                  },
                },
              })
            : await create({
                variables: {
                  data: {
                    dirCalendar: {
                      connect: {
                        id,
                      },
                    },
                    dirSport: {
                      connect: {
                        id: sportId,
                      },
                    },
                    status: {
                      create: [
                        {
                          ...(comment && { comment: comment as string }),
                          value: { connect: { id: v } },
                          file: { connect: { id: fileId } },
                        },
                      ],
                    },
                  },
                },
              });
          handleSnackBar('success', `статус успешно обновлен`);
          setNewStatus(null);
          IsReturning && setIsReturning(false);
          await onUpdate();
        });
      } else {
        regulation && regulation?.id
          ? await update({
              variables: {
                id: regulation?.id,
                data: {
                  status: {
                    create: [
                      {
                        ...(comment && { comment: comment as string }),
                        value: { connect: { id: v } },
                      },
                    ],
                  },
                },
              },
            })
          : await create({
              variables: {
                data: {
                  dirCalendar: {
                    connect: {
                      id,
                    },
                  },
                  dirSport: {
                    connect: {
                      id: sportId,
                    },
                  },
                  status: {
                    create: [
                      {
                        ...(comment && { comment: comment as string }),
                        value: { connect: { id: v } },
                      },
                    ],
                  },
                },
              },
            });
        handleSnackBar('success', `статус успешно обновлен`);
        setNewStatus(null);
        IsReturning && setIsReturning(false);
        await onUpdate();
      }
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  return (
    <Accordion expanded={true} style={{ width: '100%', margin: 0, marginBottom: 15 }}>
      <AccordionSummary aria-controls={`team-status-content`} id={`team-status-header`}>
        <Box display={'block'} width={'100%'}>
          <Box>
            <Typography className={classes.header}>Статус регламента: {status?.value?.label || '~'}</Typography>
          </Box>
          {Header}
        </Box>
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: 'column' }}>
        <Dialog open={!!newStatus} onClose={() => setNewStatus(null)}>
          <DialogTitle>
            <Typography style={{ fontSize: 20, fontWeight: 600 }}>{newStatus?.label}</Typography>
          </DialogTitle>
          <DialogContent>
            <StatusForm
              onlyWord={onlyWord}
              IsReturning={IsReturning}
              onEdit={(values: formState) => onCreate(values)}
              onCancel={() => {
                setNewStatus(null);
                IsReturning && setIsReturning(false);
              }}
            />
          </DialogContent>
        </Dialog>
        <Grid container spacing={2} style={{ marginBottom: 10 }}>
          <Grid item container md={6} xs={12} spacing={1}>
            <Grid item>
              <Typography style={{ fontWeight: 600 }}> Документ:</Typography>
            </Grid>
            <Grid item>
              {status?.file ? (
                <Link href={`${DOWNLOAD_LINK}${status?.file?.path}`}>
                  <Typography style={{ wordBreak: 'break-all' }}> {status?.file?.name}</Typography>
                </Link>
              ) : (
                '~'
              )}
            </Grid>
          </Grid>
          <Grid item container md={6} xs={12} spacing={1}>
            <Grid item>
              <Typography style={{ fontWeight: 600 }}> Комментарий:</Typography>
            </Grid>
            <Grid item>
              <Typography> {status?.comment || '~'}</Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={1} style={{ marginBottom: 30 }}>
          <Grid item>
            <Button
              variant={'contained'}
              color={'primary'}
              size={'small'}
              onClick={() => handleSetNewStatus(1)}
              disabled={readonly || !hasRegulationsDocument || (statusValue > 0 && ![5, 6].includes(statusValue))}
            >
              проект
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant={'contained'}
              color={'primary'}
              size={'small'}
              onClick={() => handleSetNewStatus(2)}
              disabled={r_only || !(hasRegulationsDocument && [1].includes(statusValue))}
            >
              на согласование
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant={'contained'}
              color={'primary'}
              size={'small'}
              onClick={() => handleSetNewStatus(3)}
              disabled={readonly || !(hasRegulationsAmendedDocument && [2].includes(statusValue))}
            >
              Согласовано
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant={'contained'}
              color={'primary'}
              size={'small'}
              onClick={() => handleSetNewStatus(4)}
              disabled={r_only || !(hasRegulationsAgreedDocument && [3].includes(statusValue))}
            >
              Подписано
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant={'contained'}
              color={'primary'}
              size={'small'}
              onClick={() => handleSetNewStatus(5)}
              disabled={readonly || !(hasRegulationsAgreedDocument && [4].includes(statusValue))}
            >
              Утверждено
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant={'contained'}
              color={'secondary'}
              size={'small'}
              onClick={() => handleSetNewStatus(6)}
              disabled={r_only || ![2, 3, 4].includes(statusValue)}
            >
              Отклонено
            </Button>
          </Grid>
        </Grid>

        {statusArray.length > 0 && <StatusBoard statusArray={(statusArray as unknown) as TStatus[]} />}
      </AccordionDetails>
    </Accordion>
  );
};

export default Status;
