import { Button, Dialog, DialogTitle, Grid, IconButton, Link, Tooltip } from '@material-ui/core';
import { DatePicker, Input } from '../../../Inputs';
import React, { FC, useState } from 'react';
import {
  Scalars,
  UpdateSportingEventMutationHookResult,
  UploadFileMutationHookResult,
  useUpdateSportingEventMutation,
  useUploadFileMutation,
} from '../../../../api';

import ConfirmDialog from '../../../ConfirmDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import DocumentSelect from '../../../../module/Documents';
import DocumentTypes from '../../../../module/DocumentTypes';
import { DropzoneArea } from 'material-ui-dropzone';
import SaveIcon from '@material-ui/icons/Save';
import getMessage from '../../../../messages';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles({
  title: {
    fontWeight: 500,
  },
  link: {
    cursor: 'pointer',
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  icon: {
    fontSize: 20,
  },
  verticalSpaceMarge: {
    marginBottom: 35,
    padding: 10,
  },
  dropZone: {
    minHeight: 30,
    border: 0,
    borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    borderRadius: 0,

    '& > .MuiDropzonePreviewList-root': {
      margin: '0 !important',
      width: '100%',
      '& > .MuiDropzonePreviewList-imageContainer': {
        flexBasis: 'unset',
        width: '100%',
        maxWidth: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 15,
        '& > svg': {
          height: '40px',
        },
        '& > p': {
          marginLeft: 15,
          marginRight: 15,
        },
        '& > button': {
          top: 'unset',
          right: 0,
          position: 'unset',
          marginLeft: 30,

          color: '#d32f2f',
          boxShadow: 'unset',
        },
      },
    },
    '& > .MuiDropzoneArea-textContainer': {
      display: 'flex',

      '& > .MuiDropzoneArea-text': {
        margin: 0,
        fontSize: 15,
        '&:after': {
          content: '"*"',
          color: 'red',
          verticalAlign: 'middle',
          marginLeft: 4,
        },
      },
      '& > .MuiDropzoneArea-icon': {
        width: 20,
        height: 20,
        marginLeft: 'auto',
      },
    },
    '&:focus': {
      outline: 0,
      border: 0,
      borderBotom: '2px solid #3f51b5',
    },
  },
});

interface formState {
  title?: string;
  date?: Date;
  number?: string;
  clsType?: { label: string; value: string };
}

interface lightFormState {
  dirDocument?: { label: string; value: Scalars['UUID'] };
}

const Documents: FC<{
  readonly?: boolean;
  id?: string;
  programId?: string;
  documents?: any[] | null;
  onUpdateDocument: Function;
}> = ({ readonly, id, programId, documents = [], onUpdateDocument }) => {
  const DOWNLOAD_LINK = process.env.REACT_APP_BACKEND_URI?.replace('/graphql', '');

  const defaultNewDocumentState: formState = {
    title: undefined,
    date: undefined,
    number: undefined,
    clsType: undefined,
  };

  const { handleSubmit, control, errors, reset, formState } = useForm({
    defaultValues: defaultNewDocumentState,
  });

  const {
    handleSubmit: documentFormHandleSubmit,
    control: documentFormControl,
    errors: documentFormErrors,
    reset: documentFormReset,
    formState: documentFormState,
  } = useForm({
    defaultValues: {
      dirDocument: undefined,
    },
  });

  const [updateEventData]: UpdateSportingEventMutationHookResult = useUpdateSportingEventMutation();

  const [uploadDocumentFile]: UploadFileMutationHookResult = useUploadFileMutation();

  const [deleteItem, setDeleteItem] = useState<any>(null);

  const [isAddingNewDocument, setIsAddingNewDocument] = useState(false);
  const [isAddingNEwDocumentFromSystem, setIsAddingNEwDocumentFromSystem] = useState(false);
  const [file, setFile] = useState<Array<File>>([]);

  const { enqueueSnackbar } = useSnackbar();

  const handleSnackBar = (variant: 'default' | 'error' | 'success' | 'warning' | 'info' | undefined, message: string) =>
    enqueueSnackbar(message, { variant });

  const addNewDocument = async (values: lightFormState) => {
    const { dirDocument } = values;
    try {
      if (isAddingNewDocument && dirDocument) {
        await updateEventData({
          variables: {
            data: {
              programTypes: {
                update: [
                  {
                    where: { id: programId },
                    data: {
                      resultDocuments: {
                        connect: [
                          {
                            id: dirDocument?.value,
                          },
                        ],
                      },
                    },
                  },
                ],
              },
            },
            id,
          },
        });
        handleSnackBar('success', 'документ успешно добавлен');
        onUpdateDocument();
        setIsAddingNewDocument(false);
      }
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const handleAddNewDocumentFromSystem = async (values: formState) => {
    const { title, date, number, clsType } = values;
    try {
      if (isAddingNEwDocumentFromSystem && title && date && file) {
        uploadDocumentFile({
          variables: {
            file: file[0],
          },
        }).then(async (resp: any) => {
          const {
            uploadFile: { id: fileId },
          } = resp.data;
          await updateEventData({
            variables: {
              data: {
                programTypes: {
                  update: [
                    {
                      where: { id: programId },
                      data: {
                        resultDocuments: {
                          create: [
                            {
                              title,
                              date,
                              number,
                              clsType: clsType && { connect: { id: clsType?.value } },
                              file: {
                                connect: { id: fileId },
                              },
                            },
                          ],
                        },
                      },
                    },
                  ],
                },
              },
              id,
            },
          });
          handleSnackBar('success', 'документ успешно добавлен');
          setIsAddingNEwDocumentFromSystem(false);
          onUpdateDocument();
          reset(defaultNewDocumentState);
        });
      }
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const deleteDocument = async (docId: Scalars['UUID']) => {
    try {
      await updateEventData({
        variables: {
          data: {
            programTypes: {
              update: [
                {
                  where: { id: programId },
                  data: {
                    resultDocuments: {
                      disconnect: [{ id: docId }],
                    },
                  },
                },
              ],
            },
          },
          id,
        },
      });
      handleSnackBar('success', 'документ успешно удалён');
      onUpdateDocument();
    } catch (error) {
      handleSnackBar('error', getMessage(error.message));
    }
  };

  const classes = useStyles();

  return (
    <div>
      <Grid container spacing={2} alignItems="center">
        {readonly ? null : isAddingNewDocument ? (
          <React.Fragment>
            <form onSubmit={documentFormHandleSubmit(addNewDocument)} style={{ width: '100%' }}>
              <Grid item container spacing={2} alignItems="flex-end">
                <Grid item md={6} xs={12}>
                  <DocumentSelect
                    label="Документ"
                    control={documentFormControl}
                    error={!!documentFormErrors['dirDocument']}
                    name="dirDocument"
                    rules={{ required: true }}
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    style={{ marginRight: '0.5rem' }}
                    startIcon={<SaveIcon />}
                    type="submit"
                    disabled={!documentFormState.isDirty}
                  >
                    Сохранить
                  </Button>

                  <Button
                    variant="outlined"
                    color="secondary"
                    style={{ marginRight: '0.5rem' }}
                    size="small"
                    onClick={() => {
                      setIsAddingNewDocument(false);
                      documentFormReset();
                    }}
                  >
                    Отменить
                  </Button>
                </Grid>
              </Grid>
            </form>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Grid item>
              <span style={{ marginRight: '0.5rem' }}>Добавить протокол</span>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                style={{ marginRight: '0.5rem' }}
                size="small"
                type="button"
                onClick={() => setIsAddingNewDocument(true)}
              >
                Из базы
              </Button>
              <Button
                variant="outlined"
                color="primary"
                style={{ marginRight: '0.5rem' }}
                size="small"
                type="button"
                onClick={() => {
                  setIsAddingNEwDocumentFromSystem(true);
                }}
                disabled={isAddingNewDocument}
              >
                Новый
              </Button>
            </Grid>
          </React.Fragment>
        )}
      </Grid>
      <Grid container>
        <Grid item container spacing={2} alignItems="center">
          {documents?.map((document) => (
            <Grid item container alignItems="center" spacing={2}>
              <Grid item>
                <Link href={`${DOWNLOAD_LINK}${document?.file.path}`} className={classes.link}>
                  {document?.title}
                </Link>
              </Grid>
              <Grid item>
                <span>{document?.date && moment(document?.date).format('DD.MM.YYYY')}</span>
              </Grid>
              <Grid item>{document.number}</Grid>
              <Grid item>{document?.clsType?.label}</Grid>
              {!readonly && (
                <Grid item>
                  <Tooltip title="удалить" placement="top-start">
                    <IconButton
                      className={classes.btn}
                      color="secondary"
                      onClick={() => setDeleteItem(document.number)}
                    >
                      <DeleteIcon className={classes.icon} />
                    </IconButton>
                  </Tooltip>
                  <ConfirmDialog
                    title={`Удаление документа дисциплины`}
                    text={`Вы уверены что хотите удалить этот документ?`}
                    btnVariant="outlined"
                    open={deleteItem === document.number}
                    saveBtnText="да"
                    cancelBtnText="отмена"
                    saveBtnColor="secondary"
                    cancelBtnColor="primary"
                    onCancel={() => setDeleteItem(null)}
                    onSave={() => {
                      deleteDocument(document?.id).then(() => setDeleteItem(null));
                    }}
                  />
                </Grid>
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>

      <Dialog
        open={isAddingNEwDocumentFromSystem}
        onClose={() => {
          setIsAddingNEwDocumentFromSystem(false);
          reset(defaultNewDocumentState);
        }}
        maxWidth="xs"
      >
        <DialogTitle id="add-document-dialog">Новый Документ</DialogTitle>
        <div style={{ padding: '1.5rem' }}>
          <form onSubmit={handleSubmit(handleAddNewDocumentFromSystem)} style={{ width: '100%' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Input
                  label="Название документа"
                  control={control}
                  error={!!errors['title']}
                  name="title"
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <DocumentTypes
                  label="Тип документа"
                  error={!!errors['clsType']}
                  control={control}
                  rules={{ required: true }}
                  name="clsType"
                />
              </Grid>
              <Grid item xs={12}>
                <DatePicker
                  fullWidth
                  label="Дата"
                  control={control}
                  error={!!errors['date']}
                  name="date"
                  rules={{ required: true }}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  label="Номер документа"
                  control={control}
                  error={!!errors['number']}
                  name="number"
                />
              </Grid>
              <Grid item xs={12}>
                <DropzoneArea
                  showFileNames
                  alertSnackbarProps={{
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                  }}
                  dropzoneClass={classes.dropZone}
                  showAlerts={['error']}
                  getFileAddedMessage={(fileName: string) => `Файл ${fileName} успешно загружено`}
                  getDropRejectMessage={(file: File) =>
                    `Файл ${file.name} отклонен. Тип файла не поддерживается. Файл слишком большой. Максимальный размер - 10 мегабайт.`
                  }
                  filesLimit={1}
                  maxFileSize={10485760}
                  clearOnUnmount
                  initialFiles={file}
                  onChange={(fileArray: File[]) => setFile(fileArray)}
                  dropzoneText="Файл"
                />
              </Grid>
              <Grid item container justify="flex-end">
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  startIcon={<SaveIcon />}
                  type="submit"
                  disabled={!(formState.isDirty && file?.length > 0)}
                >
                  Добавить
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </Dialog>
    </div>
  );
};

export default Documents;
