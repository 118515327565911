import React, { FC } from 'react';
import { Select } from '../components/Inputs';
import { ControllerProps } from 'react-hook-form/dist/types/props';
import { useForeignCitiesListQuery } from '../api';

interface ForeignCities extends Pick<ControllerProps<'input'>, 'control' | 'name' | 'rules'> {
  label: string;
  error: boolean | undefined;
  filter?: any;
  disabled?: boolean;
  enablePortal?: boolean;
  multiple?: boolean;
}

const ForeignCities: FC<ForeignCities> = ({
  filter,
  multiple,
  label,
  error,
  control,
  name,
  rules,
  disabled,
  enablePortal,
}) => {
  const { data } = useForeignCitiesListQuery({ variables: { filter } });
  return (
    <Select
      label={label}
      data={(data && data.dirForeignCities) || []}
      control={control}
      error={error}
      name={name}
      rules={rules}
      multiple={multiple}
      disabled={disabled}
      enablePortal={enablePortal}
    />
  );
};

export default ForeignCities;
