import { Box, Button, Grid } from '@material-ui/core';
import { Input, Select } from '../Inputs';
import React, { FC, useState } from 'react';
import { useGenerateOrganizationXlsFileLazyQuery, useOrganizationsCountLazyQuery } from '../../api';

import CategoryOrganizations from '../../module/CategoryOrganizations';
import ClearIcon from '@material-ui/icons/Clear';
import ContentWrapper from '../layouts/ContentWrapper/ContentWrapper';
import { Count } from '../Count';
import GetAppIcon from '@material-ui/icons/GetApp';
import Regions from '../../module/Regions';
import SearchIcon from '@material-ui/icons/Search';
import TuneIcon from '@material-ui/icons/Tune';
import TypeSportsSelect from '../../module/TypeSportsSelect';
import { makeStyles } from '@material-ui/core/styles';
import { onSetOrgFilter } from './helpers';
import { useForm } from 'react-hook-form';
import useGenerateXlsx from '../../hooks/useGenerateXlsx';

interface Filter {
  registryNumber?: number;
  dirRegion?: {
    id?: string;
  };
  sports_some?: {
    dirSport?: {
      id?: string;
    };
  };
  clsOrganizationCategories_some?: {
    id?: string;
  };
  ogrn_contains?: string;
  name?: string;
  isLegalEntity?: boolean;
  isUniversity?: boolean;
}
interface Value {
  registryNumber?: number;
  dirRegion?: {
    label: string;
    value: string;
  };
  sportType?: {
    label: string;
    value: string;
  };
  organizationCategory?: {
    label: string;
    value: string;
  };
  ogrn?: string;
  name?: string;
  isLegalEntity?: { value: string; label: string };
  isUniversity?: { value: string; label: string };
}

const useStyle = makeStyles((theme) => ({
  btn: {
    marginRight: theme.spacing(2),
  },
}));

const OrganizationsFilter: FC<{
  onSubmit?: (T: any, E: any) => void;
  value?: any;
  defaultFilter?: any;
  first: number;
}> = ({ onSubmit = () => {}, value = {}, first, defaultFilter = {} }) => {
  const style = useStyle();

  const [additional, setAdditional] = useState<boolean>(Object.keys(value)?.length > 0);
  const [filter, setFilter] = useState<any>({
    ...defaultFilter,
    archive: null,
  });

  const { handleSubmit, control, errors, reset, formState, getValues } = useForm({
    defaultValues: value,
  });

  const { onDownloadFile } = useGenerateXlsx({
    lazyQuery: useGenerateOrganizationXlsFileLazyQuery,
    getValues,
    setFilter: onSetOrgFilter,
    fileName: 'organizations',
    first,
  });

  const handleSearchBox = (value: Value) => {
    const filters: Filter = onSetOrgFilter(value);

    setFilter(filters);
    onSubmit(filters, getValues());
  };

  return (
    <ContentWrapper>
      <form onSubmit={handleSubmit(handleSearchBox)}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={3}>
            <Input
              label="Реестровый номер"
              control={control}
              error={!!errors['registryNumber']}
              name="registryNumber"
            />
          </Grid>
          <Grid item xs={3}>
            <Input label="Название" control={control} error={!!errors['name']} name="name" />
          </Grid>
          <Grid item xs={3}>
            <Input label="ОРГН" control={control} error={!!errors['ogrn']} name="ogrn" />
          </Grid>
          <Grid item xs={3}>
            <Regions label="Регион РФ" error={!!errors['dirRegion']} control={control} name="dirRegion" />
          </Grid>

          {additional && (
            <React.Fragment>
              <Grid item xs={3}>
                <CategoryOrganizations
                  label="Категория организации"
                  error={!!errors['organizationCategory']}
                  control={control}
                  name="organizationCategory"
                />
              </Grid>
              <Grid item xs={3}>
                <TypeSportsSelect label="Вид спорта" error={!!errors['sportType']} control={control} name="sportType" />
              </Grid>
              <Grid item xs={3}>
                <Select
                  label="Является юр.лицом"
                  data={[
                    { value: '', label: ' ' },
                    { value: 'Нет', label: 'Нет' },
                    { value: 'Да', label: 'Да' },
                  ]}
                  control={control}
                  error={!!errors['isLegalEntity']}
                  name="isLegalEntity"
                />
              </Grid>
              <Grid item xs={3}>
                <Select
                  label="ВУЗ"
                  data={[
                    { value: '', label: ' ' },
                    { value: 'false', label: 'Нет' },
                    { value: 'true', label: 'Да' },
                  ]}
                  control={control}
                  error={!!errors['isUniversity']}
                  name="isUniversity"
                />
              </Grid>
              <Grid item xs={3}>
                <Select
                  label="В архиве"
                  data={[
                    { value: '', label: ' ' },
                    { value: false, label: 'Нет' },
                    { value: true, label: 'Да' },
                  ]}
                  control={control}
                  error={!!errors['archive']}
                  name="archive"
                  defaultValue={{ value: false, label: 'Нет' }}
                />
              </Grid>
            </React.Fragment>
          )}

          <Grid item container alignItems={'center'}>
            <Grid item>
              <Button
                variant="outlined"
                className={style.btn}
                color="primary"
                type="button"
                onClick={() => {
                  onSubmit(
                    {
                      archive: null,
                    },
                    {},
                  );
                  setFilter({
                    archive: null,
                  });
                  reset({
                    registryNumber: '',
                    dirRegion: null,
                    sportType: null,
                    organizationCategory: null,
                    ogrn: '',
                    name: '',
                    archive: { value: false, label: 'Нет' },
                    isLegalEntity: undefined,
                    isUniversity: { value: '', label: ' ' },
                  });
                }}
                size="small"
                startIcon={<ClearIcon />}
              >
                Сброс
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                type="submit"
                size="small"
                className={style.btn}
                startIcon={<SearchIcon />}
                disabled={!formState.isDirty}
              >
                Поиск
              </Button>
            </Grid>
            <Grid item>
              <Button
                onClick={() => setAdditional(!additional)}
                type="button"
                className={style.btn}
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<TuneIcon />}
              >
                Фильтр
              </Button>
            </Grid>
            <Box pl={4}>
              <Button
                variant="outlined"
                color="primary"
                size={'small'}
                disabled={!formState.isDirty}
                onClick={onDownloadFile}
                startIcon={<GetAppIcon />}
              >
                XLS
              </Button>
              <Count key={JSON.stringify(filter)} query={useOrganizationsCountLazyQuery} filter={filter} />
            </Box>
          </Grid>
        </Grid>
      </form>
    </ContentWrapper>
  );
};

export default OrganizationsFilter;
