import _ from 'lodash';
import moment from 'moment';
import { SportingEventFragment } from '../../../../api';
import { russianYear } from '../../../../utils/rYear';

export const revealEventsMinsports = (array: Array<{ __typename?: 'DirSportingEvent' } & SportingEventFragment>) => {
  const unifiedArray = array.map((activity) => {
    const {
      id,
      name,
      registryNumber,
      startDate,
      endDate,
      sports,
      dirNationalTeams,
      dirCalendar,
      status,
      organizers,
      requests,
      changes,
      venue,
      oldRegistryNumber,
    } = activity;
    const change = changes?.find((event: any) => event?.requests?.find((r: any) => r?.type?.id === 2));
    const changeRequest = change?.requests?.find((r: any) => r?.type?.id === 2);
    const disciplinesArray = _.flattenDeep(sports?.map((sport) => sport.disciplines?.map((d) => d.label)));
    const disciplinesGroupsArray = _.flattenDeep(sports?.map((sport) => sport.disciplineGroups?.map((d) => d.label)));
    const sportString = sports && sports.map((s) => s.dirSport && s.dirSport.label).join(', ');
    const disciplinesString = `${disciplinesGroupsArray?.join(', ')}${
      disciplinesGroupsArray?.length > 0 && disciplinesArray?.length > 0
        ? ` / ${disciplinesArray.join(', ')}`
        : disciplinesArray.join(', ')
    }`;

    const ageGroups = _.chain(activity?.ageGroups)
      .groupBy('minAge')
      .map((value, key) =>
        value?.length
          ? `${_.uniq(value?.map((v) => v.clsAgeGroup?.shortName)).join(', ')} - ${
              (value[0]?.minAge &&
                value[0]?.maxAge &&
                value[0]?.minAge === value[0]?.maxAge &&
                `${value[0]?.minAge} ${russianYear(value[0]?.minAge)}`) ||
              (value[0]?.minAge &&
                !value[0]?.maxAge &&
                `${value[0]?.minAge} ${russianYear(value[0]?.minAge)} и старше`) ||
              (value[0]?.minAge &&
                value[0]?.maxAge &&
                `${value[0]?.minAge}-${value[0]?.maxAge} ${russianYear(value[0]?.maxAge)}`) ||
              (!value[0]?.minAge && value[0]?.maxAge && `до ${value[0]?.maxAge} ${russianYear(value[0]?.maxAge)}`)
            }`
          : '',
      )
      .sortBy('minAge')
      .value();

    let statusString = '';
    if (status != null) {
      statusString = status.label;
    }
    return {
      info: { id, registryNumber },
      venue,
      period: `${startDate && moment(startDate).format('DD.MM.YY')} - ${endDate && moment(endDate).format('DD.MM.YY')}`,
      sportsString: `${sportString}`,
      disciplinesString: `${disciplinesString}`,
      organizers,
      oldRegistryNumber,
      ticket: statusString,
      dirCalendar: dirCalendar?.shortName,
      name: name,
      ageGroups: activity.isAgeRestricted ? 'Без ограничения' : ageGroups?.join('; '),
      id: id,
      status: `${statusString}`,
      athletesCount: dirNationalTeams?.reduce(
        (acc, curr) =>
          acc +
          (curr.athleteGroups
            ? curr.athleteGroups.reduce(
                (accGroup, currGroup) => accGroup + (currGroup.athletes ? currGroup.athletes.length : 0),
                0,
              )
            : 0),
        0,
      ),
      athletesCompany: dirNationalTeams?.reduce(
        (acc, curr) => acc + (curr.specialists ? curr.specialists.length : 0),
        0,
      ),
      request:
        requests?.length && changeRequest && changeRequest?.createdAt >= requests[0]?.createdAt
          ? _.get(changeRequest, 'statuses[0].value.name', '')
          : requests?.length
          ? _.get(requests[0], 'statuses[0].value.name', '')
          : '',
      // ...activity,
    };
  });
  return unifiedArray || [];
};

export const revealEventsMinsportsMultiple = (data: any) =>
  revealEventsMinsports(data?.map((event: any) => event?.sportingEvent) || []);
