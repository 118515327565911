import {
  UpdateSportingEventMutationHookResult,
  useSportAgeGroupsQuery,
  useSportingEventAgeGroupsQuery,
  useUpdateSportingEventMutation,
  Scalars,
} from '../../../api';
import React, { FC, useEffect, useMemo, useState, useCallback } from 'react';
import {
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  DialogActions,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import getMessage from '../../../messages';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import { russianYear } from '../../../utils/rYear';

const AgeGroupsWidget: FC<{
  readonly?: boolean;
  id: string;
  sports?: Array<Scalars['UUID']>;
  onAgeGroupChanged?: ({
    groups,
    isAgeRestricted,
    ageGroup,
  }: {
    groups: any;
    isAgeRestricted: boolean;
    ageGroup: string;
  }) => void;
}> = ({ readonly, id, sports, onAgeGroupChanged }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { loading, data, refetch } = useSportingEventAgeGroupsQuery({
    skip: !id,
    fetchPolicy: 'no-cache',
    variables: { id },
  });
  const { data: ageGroupsData } = useSportAgeGroupsQuery({ skip: !sports?.length, variables: { id: sports } });

  const [update]: UpdateSportingEventMutationHookResult = useUpdateSportingEventMutation();

  const [open, setOpen] = useState(false);
  const [autoGen, setAutoGen] = useState(false);
  const [checked, setChecked] = useState<Array<any>>([]);
  const [isAgeRestricted, setIsAgeRestricted] = useState(false);
  const [ageGroup, setAgeGroup] = useState('');

  const ageGroupsRegistryNumberArray = useMemo(
    () => (id ? data?.dirSportingEvent?.ageGroups : checked)?.map((group) => group?.clsAgeGroup?.registryNumber),
    [data, checked],
  );

  const ageGroups = useMemo(
    () =>
      _.chain(id ? data?.dirSportingEvent?.ageGroups : checked)
        .groupBy('minAge')
        .map((value, key) => ({
          key,
          label: _.uniq(value?.map((v) => v.clsAgeGroup?.shortName)).join(', '),
          minAge: value[0] ? value[0]?.minAge : 0,
          maxAge: value[0] ? value[0]?.maxAge : 0,
        }))
        .sortBy('minAge')
        .value(),
    [data, checked],
  );

  const handleUpdateAgeGroup = useCallback(() => {
    const hasMan = checked?.find((c) => c?.clsAgeGroup?.registryNumber === 100000744) !== undefined;
    const hasWoman = checked?.find((c) => c?.clsAgeGroup?.registryNumber === 100000835) !== undefined;
    const checkedArrayFiltered = checked?.filter(
      (c) => ![100000835, 100000744].includes(c?.clsAgeGroup?.registryNumber),
    );
    const ageArray: Array<number> = _.flattenDeep(checkedArrayFiltered?.map((c) => [c.minAge, c.maxAge])).filter(
      (n) => n,
    );
    const groupLabel = _.uniq(checkedArrayFiltered?.map((c) => c?.clsAgeGroup?.shortName)).join(', ');
    const group =
      ageArray.length > 0 || hasWoman || hasMan
        ? `${ageArray.length > 0 ? `${groupLabel} ${Math.min(...ageArray)} - ${Math.max(...ageArray)}` : ''}${
            hasMan && ageArray.length > 0 ? ', муж.' : hasMan && ageArray.length === 0 ? 'муж.' : ''
          }${
            (hasWoman && hasMan && ageArray.length > 0) ||
            (hasWoman && hasMan) ||
            (hasWoman && !hasMan && ageArray.length > 0)
              ? ', жен.'
              : hasWoman && !hasMan && ageArray.length === 0
              ? 'жен.'
              : ''
          }${!(hasMan || hasWoman) ? '.' : ''}`
        : '';
    setAgeGroup(group);
  }, [checked]);

  const possibleAgeGroups = useMemo(
    () => _.sortBy(_.flattenDeep(ageGroupsData?.dirSports?.map((dirSport) => dirSport?.ageGroups)), 'minAge'),
    [ageGroupsData],
  );

  const handleUpdateAgeGroups = async () => {
    if (isAgeRestricted || ageGroup !== '') {
      if (!id && onAgeGroupChanged) {
        onAgeGroupChanged({ groups: checked, isAgeRestricted, ageGroup });
        setOpen(false);
        return;
      }
      if (
        id &&
        (!_.isEqual(checked, data?.dirSportingEvent?.ageGroups) ||
          !_.isEqual(ageGroup, data?.dirSportingEvent?.ageGroup))
      ) {
        try {
          if (isAgeRestricted) {
            await update({
              variables: {
                id,
                data: {
                  isAgeRestricted: true,
                  ageGroup: '',
                  ageGroups: {
                    set: [],
                  },
                },
              },
            });
          } else {
            const delGroups = data?.dirSportingEvent?.ageGroups;

            await update({
              variables: {
                id,
                data: {
                  isAgeRestricted: false,
                  ageGroup,
                  ageGroups: {
                    create: checked?.map((item) => ({
                      maxAge: item?.maxAge,
                      minAge: item?.minAge,
                      clsAgeGroup: {
                        connect: {
                          id: item?.clsAgeGroup?.value,
                        },
                      },
                    })),
                    delete: delGroups?.map((item) => ({ id: item?.id })),
                  },
                },
              },
            });
          }

          enqueueSnackbar('Возрастные группы успешно обновлен', { variant: 'success' });
          refetch();
        } catch (error) {
          enqueueSnackbar(getMessage(error.message), { variant: 'error' });
        }
      }
      setOpen(false);
    }
  };

  const handleToggle = (value: any) => () => {
    if (!isAgeRestricted) {
      const currentItem = checked.find(
        (group) =>
          group?.clsAgeGroup?.value === value?.clsAgeGroup?.value &&
          group?.minAge === value?.minAge &&
          group?.maxAge === value?.maxAge,
      );
      const currentIndex = checked.indexOf(currentItem);
      const newChecked = [...checked];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      setChecked(newChecked);
    }
  };
  const handleToggleIsAgeRestricted = () => {
    if (isAgeRestricted) {
      setIsAgeRestricted(!isAgeRestricted);
    } else {
      setChecked([]);
      setAgeGroup('');
      setIsAgeRestricted(!isAgeRestricted);
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!loading && data?.dirSportingEvent?.ageGroups) {
      setIsAgeRestricted(data?.dirSportingEvent?.isAgeRestricted || false);
      setChecked([...data?.dirSportingEvent?.ageGroups]);
      setAgeGroup(data?.dirSportingEvent?.ageGroup || '');
    }
  }, [data, loading, open]);

  return (
    <Grid container>
      <Typography style={{ fontWeight: 600 }}>Возрастная группа:</Typography>
      <Grid container justify={'space-between'} spacing={2}>
        <Grid item md={11}>
          {(id && data?.dirSportingEvent?.isAgeRestricted === true) || isAgeRestricted ? (
            <Typography style={{ fontWeight: 600 }}>Без ограничения возраста</Typography>
          ) : ageGroups.length ? (
            <Box fontSize={17} color={'gray'}>
              {ageGroups.map((group, index) => (
                <span key={group?.key}>
                  {group?.label} -{' '}
                  {`${
                    (group?.minAge &&
                      group?.maxAge &&
                      group?.minAge === group?.maxAge &&
                      `${group?.minAge} ${russianYear(group?.minAge)}`) ||
                    (group?.minAge && !group?.maxAge && `${group?.minAge} ${russianYear(group?.minAge)} и старше`) ||
                    (group?.minAge &&
                      group?.maxAge &&
                      `${group?.minAge}-${group?.maxAge} ${russianYear(group?.maxAge)}`) ||
                    (!group?.minAge && group?.maxAge && `до ${group?.maxAge} ${russianYear(group?.maxAge)}`)
                  }`}
                  {index < ageGroups?.length - 1 ? '; ' : '.'}
                </span>
              ))}
              <Box fontSize={17} color={'black'} mt={1}>
                <strong>Состав: </strong>{' '}
                {_.intersection(ageGroupsRegistryNumberArray, [100000835, 100000744]).length > 0
                  ? 'основной'
                  : 'молодежный'}{' '}
              </Box>
            </Box>
          ) : (
            <Typography style={{ fontWeight: 600 }}>нет выбранных групп</Typography>
          )}
        </Grid>
        {!readonly && (
          <Grid item xs>
            <IconButton
              color={'primary'}
              onClick={() => {
                setAutoGen(false);
                setOpen(true);
              }}
            >
              <EditIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
      <Dialog open={open} maxWidth={'md'} onClose={handleUpdateAgeGroups}>
        <DialogTitle>
          <Typography style={{ fontWeight: 600 }}>Возрастные группы</Typography>
        </DialogTitle>
        <DialogContent>
          <Box width={800}>
            {/*{id !== undefined && (*/}
            {/*  <Grid container alignItems={'center'} style={{ paddingLeft: 16 }}>*/}
            {/*    <FormControlLabel*/}
            {/*      style={{ fontWeight: 600 }}*/}
            {/*      control={*/}
            {/*        <Checkbox*/}
            {/*          checked={autoGen}*/}
            {/*          color={'primary'}*/}
            {/*          disabled={isAgeRestricted}*/}
            {/*          onClick={() => setAutoGen(!autoGen)}*/}
            {/*        />*/}
            {/*      }*/}
            {/*      label={<Typography style={{ fontWeight: 600 }}>Автоподбор</Typography>}*/}
            {/*    />*/}
            {/*  </Grid>*/}
            {/*)}*/}

            <List style={{ width: '100%' }}>
              <ListItem role={undefined} dense button onClick={handleToggleIsAgeRestricted}>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={isAgeRestricted}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{ 'aria-labelledby': 'checkbox-list-label-Без-ограничения-возраста' }}
                    color={'primary'}
                  />
                </ListItemIcon>
                <ListItemText
                  primaryTypographyProps={{
                    style: { fontWeight: 500, fontSize: 18 },
                  }}
                  id={'checkbox-list-label-Без-ограничения-возраста'}
                  primary={'Без ограничения возраста'}
                />
              </ListItem>
              {possibleAgeGroups?.map((item) => {
                const labelId = `checkbox-list-label-${item}`;
                return (
                  <ListItem key={item?.id} role={undefined} dense button onClick={handleToggle(item)}>
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={
                          checked.find(
                            (group) =>
                              group?.clsAgeGroup?.value === item?.clsAgeGroup?.value &&
                              group?.minAge === item?.minAge &&
                              group?.maxAge === item?.maxAge,
                          ) !== undefined
                        }
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': labelId }}
                        color={'primary'}
                        disabled={isAgeRestricted}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primaryTypographyProps={{
                        style: { fontWeight: 500, fontSize: 18 },
                      }}
                      id={labelId}
                      primary={item?.clsAgeGroup?.label}
                    />
                    <ListItemSecondaryAction>
                      <Grid container spacing={2}>
                        <Grid item xs>
                          <TextField label={'C'} value={item?.minAge} />
                        </Grid>

                        <Grid item xs>
                          <TextField label={'По'} value={item?.maxAge || 'без ограничения'} />
                        </Grid>
                      </Grid>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })}
              <ListItem role={undefined} dense>
                <ListItemText
                  primaryTypographyProps={{
                    style: { fontWeight: 600, fontSize: 18, marginTop: 15 },
                  }}
                  primary="Половозрастная группа (текст):"
                />
                <ListItemSecondaryAction style={{ width: '52%' }}>
                  <Grid container>
                    <TextField fullWidth label={''} value={ageGroup} />
                  </Grid>
                </ListItemSecondaryAction>
              </ListItem>
            </List>
            <Grid item container justify="flex-end" style={{ paddingRight: 15 }}>
              <Button variant={'outlined'} color={'primary'} onClick={handleUpdateAgeGroup}>
                сгенерировать
              </Button>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant={'contained'} color={'secondary'} onClick={onClose}>
            отменить
          </Button>
          <Button
            variant={'contained'}
            color={'primary'}
            disabled={!ageGroup && !isAgeRestricted}
            onClick={handleUpdateAgeGroups}
          >
            сохранить
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

export default AgeGroupsWidget;
