import { Grid, LinearProgress } from '@material-ui/core';
import React, { FC, useEffect } from 'react';

import { Link } from 'react-router-dom';
import { usePersonByIdLazyQuery } from '../../../api';

const Roles: FC<{
  readonly?: boolean;
  id?: string;
}> = ({ readonly, id }) => {
  const [fetchPerson, { data, refetch, loading }] = usePersonByIdLazyQuery({
    variables: { id: id === 'new' ? '' : id },
    fetchPolicy: 'no-cache',
  });

  useEffect(() => {
    if (id !== '' && id !== 'new') {
      fetchPerson({
        variables: { id },
      });
    }
  }, [id]);

  if (loading) return <LinearProgress />;

  return (
    <>
      <Grid container>
        {data?.dirPerson?.athleteProfile && (
          <Grid item container>
            <Link
              style={{
                fontWeight: 500,
                cursor: 'pointer',
                textDecoration: 'none',
              }}
              target="_blank"
              to={`/athlete/${data?.dirPerson?.athleteProfile?.id}`}
            >
              Спортсмен
            </Link>
          </Grid>
        )}
        {data?.dirPerson?.trainerProfile && (
          <Grid item container>
            <Link
              style={{
                fontWeight: 500,
                cursor: 'pointer',
                textDecoration: 'none',
              }}
              target="_blank"
              to={`/trainer/${data?.dirPerson?.trainerProfile?.id}`}
            >
              Тренер
            </Link>
          </Grid>
        )}
        {data?.dirPerson?.refereeProfile && (
          <Grid item container>
            <Link
              style={{
                fontWeight: 500,
                cursor: 'pointer',
                textDecoration: 'none',
              }}
              target="_blank"
              to={`/referee/${data?.dirPerson?.refereeProfile?.id}`}
            >
              Судья
            </Link>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default Roles;
