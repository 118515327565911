import { Grid, Tooltip } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import React, { FC, useCallback, useState } from 'react';
import { getSettingsUserFilter, getSettingsUserFilterFormValue } from '../../store/selectors/settings';
import { useDispatch, useSelector } from 'react-redux';
import { useRefereesLazyQuery, useRefereesSimilarLazyQuery } from '../../api';

import { AppStore } from '../../store/reducers';
import { BtnNew } from '../../components/BtnNew';
import { DataTable } from '../../components/DataTable/NewTable';
import { DictionaryWrapper } from '../../components/DictionaryWrapper';
import { MUIDataTableColumn } from 'mui-datatables';
import { MainTemplate } from '../../components/layouts';
import { RefereesFilter } from '../../components/Filters';
import { SET_USER_SETTINGS_FILTER } from '../../store/constants';
import UniBox from '../../components/DupliBox';
import _ from 'lodash';
import moment from 'moment';
import { usePermission } from '../../hooks/usePermission';
import userRights from '../../config/userRights';

const Page: FC<{}> = () => {
  const { hasAdditionalFilter } = userRights();

  const dispatch = useDispatch();
  const filter: any = useSelector((state: AppStore) => getSettingsUserFilter(state, 'referees'), _.isEqual);
  const formValue: any = useSelector((state: AppStore) => getSettingsUserFilterFormValue(state, 'referees'), _.isEqual);
  const { push } = useHistory();
  const access = usePermission('directory', 'referees');
  const [first, setFirst] = useState(0);

  const setFilter = useCallback(
    (filter, form) => {
      dispatch({
        type: SET_USER_SETTINGS_FILTER,
        payload: {
          name: 'referees',
          filter: filter,
          form,
        },
      });
    },
    [dispatch],
  );

  const columns: MUIDataTableColumn[] = [
    {
      name: 'dirPerson',
      label: 'ФИО',
      options: {
        filter: false,
        sort: false,
        viewColumns: false,
        customBodyRender: (person: any, meta: any) => {
          const { rowIndex } = meta;
          const fio = [person?.lastname || '', person?.firstname || '', person?.patronymic || ''].join(' ');
          return (
            <Tooltip title="Перейти в карточку" placement="top-start">
              <Link
                style={{
                  fontWeight: 500,
                  cursor: 'pointer',
                  textDecoration: 'none',
                }}
                to={`/referee/${meta.rowData[1]}`}
                target={'_blank'}
              >
                {fio}
              </Link>
            </Tooltip>
          );
        },
      },
    },
    {
      name: 'id',
      label: '',
      options: {
        sort: false,
        viewColumns: false,
        display: false,
      },
    },
    {
      name: 'birthday',
      label: 'ДР',
      options: {
        sort: false,
        filter: false,
        display: true,

        customBodyRender: (person, { rowData }) => moment(rowData[7]?.birthday).format('DD.MM.YYYY'),
      },
    },
    {
      name: 'isMale',
      label: 'Пол',
      options: {
        sort: false,
        filter: false,
        display: true,

        customBodyRender: (person, { rowData }) => <>{(rowData[7]?.isMale && 'Муж') || 'Жен'}</>,
      },
    },
    {
      name: 'sports',
      label: 'Виды спорта',
      options: {
        filter: false,
        display: true,
        sort: false,
        customBodyRender: (sports) => <>{sports?.map((sport: any) => sport.dirSport.label).join(', ')}</>,
      },
    },
    {
      name: 'dirPerson',
      label: 'Регион основной (страна/ зарубежный город)',
      options: {
        filter: false,
        display: true,
        sort: false,
        customBodyRender: (person) => {
          const region =
            (person?.isRussia && person?.dirRegion?.fullName) ||
            (!person?.isRussia && person?.dirForeignCity?.fullName) ||
            (!person?.isRussia && person?.dirCountry?.fullName);
          return person ? region : '';
        },
      },
    },
    {
      name: 'dirPerson',
      label: 'Реестровый номер',
      options: {
        filter: false,
        display: true,
        sort: false,
        customBodyRender: (person: any) => person?.registryNumber,
      },
    },
    {
      name: 'dirPerson',
      label: ' ',
      options: {
        filter: false,
        display: true,
        sort: false,
        viewColumns: false,

        customBodyRender: (value: any, meta: any) => {
          return value?.archive ? null : (
            <UniBox
              fio={[value?.lastname || '', value?.firstname || '', value?.patronymic || ''].join(' ')}
              idPerson={value?.id}
            />
          );
        },
      },
    },
  ];

  return (
    <MainTemplate pageName="Судьи" url="" title="Судьи">
      <RefereesFilter
        defaultFilter={{ ...hasAdditionalFilter?.referees }}
        onSubmit={(filters: any, form: any) => setFilter({ ...filters }, form)}
        first={first}
        value={formValue}
      />
      <DictionaryWrapper>
        <DataTable
          tableName="referees"
          title={
            <>
              <Grid container spacing={2} alignItems={'center'}>
                <Grid item>
                  <h2>Справочник Судьи</h2>
                </Grid>
                {access.includes(2) && <Grid item>{<BtnNew onClick={() => push('/referee/new')} />}</Grid>}
              </Grid>
            </>
          }
          columns={columns}
          hasDoubleQuery
          lazyQuery={filter?.name ? useRefereesSimilarLazyQuery : useRefereesLazyQuery}
          filter={{ ...filter, ...hasAdditionalFilter?.referees }}
          revealFunctionName={filter?.name ? 'referees' : undefined}
        />
      </DictionaryWrapper>
    </MainTemplate>
  );
};

export default Page;
