import {
  OrganizationLegalInfoFrFragment,
  UpdateOrganizationLegalInfoMutationHookResult,
  useOrganizationLegalInfoQuery,
  useUpdateOrganizationLegalInfoMutation,
  useSuggestOrganizationsQuery,
} from '../../../api';
import Radios from '../../Inputs/Radio';
import { useForm } from 'react-hook-form';
import React, { FC, useEffect, useState } from 'react';
import { Button, Divider, Grid } from '@material-ui/core';
import { DatePicker, Input } from '../../Inputs';
import SaveIcon from '@material-ui/icons/Save';
import SearchIcon from '@material-ui/icons/Search';
import { ContentWrapperWidget } from '../../layouts';
import { useSnackbar } from 'notistack';
import getMessage from '../../../messages';
import ContactInfo from './ContactInfo';
import moment from 'moment';
import AddressBox from '../Shared/AddressBox';
import ControlSites from './ControlSites';

interface OrganizationLegalInfo
  extends Pick<
    OrganizationLegalInfoFrFragment,
    | 'id'
    | 'name'
    | 'postcode'
    | 'shortName'
    | 'fullName'
    | 'ogrn'
    | 'registrationDate'
    | 'ogrn'
    | 'kpp'
    | 'inn'
    | 'legalAddress'
    | 'headPosition'
    | 'headFullName'
  > {
  isLegalEntity: string;
}
const LegalInfo: FC<{ readonly?: boolean; id?: string }> = ({ readonly, id }) => {
  const [checkOgrn, setCheckOgrn] = useState(true);
  const [ogrn, setOgrn] = useState('');
  const [setCheckSave] = useState<any>(false);
  const { data, refetch } = useOrganizationLegalInfoQuery({ variables: { id } });

  const { handleSubmit, control, watch, errors, reset, setValue, formState } = useForm({
    defaultValues: {
      ...data?.dirOrganization,
      registrationDate:
        (data?.dirOrganization?.registrationDate && moment.utc(data?.dirOrganization?.registrationDate)) || null,
      isLegalEntity: data?.dirOrganization?.isLegalEntity ? 'true' : 'false',
    } as OrganizationLegalInfo,
  });

  const { refetch: refetchSuggestOrganization, data: suggestOrganizations = [] } = useSuggestOrganizationsQuery({
    skip: checkOgrn,
    variables: {
      ogrn,
    },
    onCompleted: (data: any) => {
      if (data && data.suggestOrganizations && data.suggestOrganizations[0]) {
        Object.keys(data.suggestOrganizations[0]).forEach((key) =>
          setValue(key, `${data.suggestOrganizations[0][key]}`, { shouldDirty: true }),
        );
        setCheckOgrn(true);
      } else if (data) {
        enqueueSnackbar(`Не удалось найти данные по ОГРН ${ogrn}`, { variant: 'warning' });
      }
    },
  });

  const { enqueueSnackbar } = useSnackbar();
  let watchOgrn = watch('isLegalEntity');

  const [update]: UpdateOrganizationLegalInfoMutationHookResult = useUpdateOrganizationLegalInfoMutation();

  const onSubmit = async (values: OrganizationLegalInfo) => {
    try {
      const { data } = await update({
        variables: {
          id,
          ...(values.isLegalEntity === 'true'
            ? {
                postcode: values.postcode,
                shortName: values.shortName,
                ogrn: values.ogrn,
                registrationDate: values.registrationDate,
                kpp: values.kpp,
                inn: values.inn,
                fullName: values.fullName,
                legalAddress: values.legalAddress,
                headPosition: values.headPosition,
                headFullName: values.headFullName,
              }
            : {
                postcode: null,
                shortName: null,
                ogrn: null,
                registrationDate: null,
                kpp: null,
                inn: null,
                fullName: null,
                legalAddress: null,
                headPosition: null,
                headFullName: null,
              }),

          isLegacy: values.isLegalEntity === 'true',
        },
      });
      if (data?.updateDirOrganization && data?.updateDirOrganization?.id) {
        enqueueSnackbar(`Организация "${data?.updateDirOrganization?.name}" успешно обновлена`, { variant: 'success' });
        refetch();
      }
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
  };

  useEffect(() => {
    if (data?.dirOrganization) {
      reset({
        ...data?.dirOrganization,
        registrationDate:
          (data?.dirOrganization?.registrationDate && moment.utc(data?.dirOrganization?.registrationDate)) || null,
        isLegalEntity: data?.dirOrganization?.isLegalEntity ? 'true' : 'false',
      });
      if (data?.dirOrganization?.isLegalEntity) setOgrn(data?.dirOrganization.ogrn || '');
    }
  }, [data, reset]);

  return (
    <>
      {!readonly ? (
        <Grid container>
          <Grid item lg={12} md={12} xs={12}>
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
              <ContentWrapperWidget>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <Radios
                      label="Является юр. лицом"
                      data={[
                        {
                          value: 'true',
                          label: 'Да',
                        },
                        {
                          value: 'false',
                          label: 'Нет',
                        },
                      ]}
                      control={control}
                      error={!!errors['isLegalEntity']}
                      name="isLegalEntity"
                      rules={{ required: true }}
                      onClick={(event: any) => {
                        setCheckSave(true);
                      }}
                    />
                  </Grid>
                  {watchOgrn === 'true' && (
                    <>
                      <Grid item md={6}>
                        <Input
                          label="ОГРН"
                          control={control}
                          error={!!errors['ogrn']}
                          errorMessage={errors['ogrn'] && 'Некорректное значение, должно быть 13-15 цифр'}
                          name="ogrn"
                          rules={{ required: true, minLength: 13, maxLength: 15, pattern: /^(\d{13,15})?$/ }}
                        />
                      </Grid>
                      <Grid item md={6}>
                        <Input
                          label="Полное наименование"
                          control={control}
                          error={!!errors['fullName']}
                          name="fullName"
                        />
                      </Grid>
                      <Grid item md={6}>
                        <Input
                          label="Краткое наименование"
                          control={control}
                          error={!!errors['shortName']}
                          name="shortName"
                        />
                      </Grid>
                      <Grid item md={6}>
                        <DatePicker
                          fullWidth
                          label="Дата регистрации"
                          control={control}
                          error={!!errors['registrationDate']}
                          name="registrationDate"
                        />
                      </Grid>
                      <Grid item md={6}>
                        <Input label="ИНН" control={control} error={!!errors['inn']} name="inn" />
                      </Grid>
                      <Grid item md={6}>
                        <Input label="КПП" control={control} error={!!errors['kpp']} name="kpp" />
                      </Grid>
                      <Grid item md={6}>
                        <Input
                          label="Юридический адрес"
                          control={control}
                          error={!!errors['legalAddress']}
                          name="legalAddress"
                        />
                      </Grid>
                      <Grid item md={6}>
                        <Input label="Индекс" control={control} error={!!errors['postcode']} name="postcode" />
                      </Grid>
                      <Grid item md={6}>
                        <Input
                          label="Должность руководителя"
                          control={control}
                          error={!!errors['headPosition']}
                          name="headPosition"
                        />
                      </Grid>
                      <Grid item md={6}>
                        <Input
                          label="ФИО руководителя"
                          control={control}
                          error={!!errors['headFullName']}
                          name="headFullName"
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item md={6}></Grid>
                  <Grid item md={12}>
                    {ogrn && ogrn !== '' && watchOgrn === 'true' && (
                      <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => {
                          setCheckOgrn(false);
                          refetchSuggestOrganization({ ogrn });
                        }}
                        startIcon={<SearchIcon />}
                        style={{ marginRight: '1rem' }}
                      >
                        Поиск реквизитов в ЕГРЮЛ
                      </Button>
                    )}
                    <Button
                      variant="outlined"
                      color="primary"
                      type="submit"
                      size="small"
                      startIcon={<SaveIcon color="primary" />}
                      disabled={!formState.isDirty}
                    >
                      Сохранить
                    </Button>
                  </Grid>
                </Grid>
              </ContentWrapperWidget>
            </form>
          </Grid>
        </Grid>
      ) : (
        <Grid container>
          <Grid item container>
            <strong>ОГРН: {`${data?.dirOrganization?.ogrn || ''}`}</strong>
          </Grid>
          <Grid item container>
            <strong>Полное наименование: {` ${data?.dirOrganization?.fullName || ''}`}</strong>
          </Grid>
          <Grid item container>
            <strong>
              Краткое наименование:
              {` ${data?.dirOrganization?.shortName || ''}`}
            </strong>
          </Grid>
          <Grid item container>
            <strong>
              Дата регистрации:
              {` ${moment(data?.dirOrganization?.registrationDate).format('DD.MM.YY') || ''}`}
            </strong>
          </Grid>
          <Grid item container>
            <strong>
              ИНН:
              {` ${data?.dirOrganization?.inn || ''}`}
            </strong>
          </Grid>
          <Grid item container>
            <strong>
              КПП:
              {` ${data?.dirOrganization?.kpp || ''}`}
            </strong>
          </Grid>
          <Grid item container>
            <strong>
              Юридический адрес:
              {` ${data?.dirOrganization?.legalAddress || ''}`}
            </strong>
          </Grid>
          <Grid item container>
            <strong>
              Индекс:
              {` ${data?.dirOrganization?.postcode || ''}`}
            </strong>
          </Grid>
          <Grid item container>
            <strong>
              Должность руководителя:
              {` ${data?.dirOrganization?.headPosition || ''}`}
            </strong>
          </Grid>
          <Grid item container>
            <strong>
              ФИО руководителя:
              {` ${data?.dirOrganization?.headFullName || ''}`}
            </strong>
          </Grid>
        </Grid>
      )}
      {id !== 'new' && (
        <>
          <Divider />
          <AddressBox id={id || ''} organization readonly={readonly} />
          <ControlSites id={id || ''} />
          <ContactInfo readonly={readonly} id={id} />
        </>
      )}
    </>
  );
};

export default LegalInfo;
