import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import {
  ClassifierValue,
  DirRefereeSportCategory,
  DocumentFragment,
  Scalars,
  UpdateRefereeSportMutationHookResult,
  useUpdateRefereeSportMutation,
} from '../../../../api';
import React, { FC, useState } from 'react';

import AddBoxIcon from '@material-ui/icons/AddBox';
import CategoryForm from './CategoryForm';
import ClearIcon from '@material-ui/icons/Clear';
import ConfirmDialog from '../../../ConfirmDialog';
import DocumentComponent from './DocumentComponent';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Maybe } from '../../../../api';
import getMessage from '../../../../messages';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  row: {
    display: 'flex',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  tinyIcon: {
    fontSize: 18,
  },
  btn: {
    padding: '5px 5px',
    minWidth: '15px',
  },
  icon: {
    fontSize: 20,
  },
  tableHead: {
    fontWeight: 'bold',
  },
  header: {
    fontWeight: 600,
    fontSize: 15,
    letterSpacing: 1.2,
  },
  verticalSpace: {
    marginTop: 10,
    marginBottom: 10,
  },
});

interface FormProps {
  assignmentDate: null | Date;
  confirmationDate: null | Date;
  validUntil: null | Date;
  id: null | string;
  clsRefereeCategory: null | { value: string; label: string };
}

const RefereeCategory: FC<{
  data?: Maybe<
    Array<
      { __typename?: 'DirRefereeSportCategory' } & Pick<
        DirRefereeSportCategory,
        'assignmentDate' | 'confirmationDate' | 'validUntil' | 'id'
      > & {
          clsRefereeCategory: { __typename?: 'ClassifierValue' } & {
            value: ClassifierValue['id'];
            label: ClassifierValue['fullName'];
          };
          dirDocuments: DocumentFragment[];
        }
    >
  >;
  id: string;
  idSport: string;
  onUpdate: Function;
  readOnly?: boolean;
}> = ({ data, id, onUpdate, idSport, readOnly }) => {
  const classes = useStyles();

  const [defaultFormValue, setDefaultFormValue] = useState<any>(null);
  const [deleteItem, setDeleteItem] = useState<any>(null);
  const [action, setAction] = useState<'' | 'edit' | 'add'>('');
  const [edit, setEdit] = useState<
    | null
    | ({ __typename?: 'DirRefereeSportCategory' } & Pick<
        DirRefereeSportCategory,
        'assignmentDate' | 'confirmationDate' | 'validUntil' | 'id'
      > & {
          clsRefereeCategory: { __typename?: 'ClassifierValue' } & {
            value: ClassifierValue['id'];
            label: ClassifierValue['fullName'];
          };
        })
  >(null);

  const { enqueueSnackbar } = useSnackbar();
  const [updateSportData]: UpdateRefereeSportMutationHookResult = useUpdateRefereeSportMutation();

  const onSubmit = async (values: FormProps) => {
    const { clsRefereeCategory, assignmentDate, confirmationDate, validUntil } = values;
    try {
      if (!values.id) {
        await updateSportData({
          variables: {
            data: {
              categories: {
                create: [
                  {
                    clsRefereeCategory: {
                      connect: {
                        id: clsRefereeCategory?.value,
                      },
                    },
                    assignmentDate,
                    validUntil,
                  },
                ],
              },
            },
            id,
          },
        });
        enqueueSnackbar('категория  успешно добавлена', { variant: 'success' });
      } else {
        await updateSportData({
          variables: {
            data: {
              categories: {
                update: [
                  {
                    where: {
                      id: values.id,
                    },
                    data: {
                      assignmentDate,
                      confirmationDate: confirmationDate || null,
                      validUntil,
                      clsRefereeCategory: {
                        connect: {
                          id: clsRefereeCategory?.value,
                        },
                      },
                    },
                  },
                ],
              },
            },
            id,
          },
        });
        // reset({ item: null });
        setEdit(null);
        enqueueSnackbar('категория  успешно обновлена', { variant: 'success' });
      }
      onUpdate();
    } catch (e) {
      enqueueSnackbar(getMessage(e.message), { variant: 'error' });
    }
    setAction('');
  };

  const deleteCategory = async (catId: Scalars['UUID']) => {
    try {
      await updateSportData({
        variables: {
          data: {
            categories: {
              delete: [
                {
                  id: catId,
                },
              ],
            },
          },
          id,
        },
      });
      enqueueSnackbar('категория  успешно удаленна', { variant: 'success' });
      onUpdate();
    } catch (error) {
      enqueueSnackbar(getMessage(error.message), { variant: 'error' });
    }
  };

  return (
    <Accordion style={{ width: '100%' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`referee-category-content`} id={`org-header`}>
        <Typography className={classes.header} color="primary">
          Судейская категория
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ flexDirection: 'column' }}>
        {action && (
          <Box className={classes.verticalSpace}>
            <CategoryForm
              onSubmit={onSubmit}
              defaultValue={defaultFormValue}
              action={action}
              onCancel={() => {
                setAction('');
                setDefaultFormValue(null);
              }}
            />
          </Box>
        )}

        <TableContainer component={Paper}>
          <Table aria-label={`referee category table`}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHead}>
                  {action !== 'add' && !readOnly && (
                    <Tooltip title="добавить" placement="top-start">
                      <IconButton className={classes.btn} color="primary" onClick={() => setAction('add')}>
                        <AddBoxIcon className={classes.icon} />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell className={classes.tableHead}>Дата присвоения</TableCell>
                <TableCell className={classes.tableHead}>Дата подтверждения</TableCell>
                <TableCell className={classes.tableHead}>Действует до</TableCell>
                <TableCell colSpan={2} className={classes.tableHead}>
                  Документ
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map((item) => (
                <TableRow key={item.id}>
                  <TableCell component="th" scope="row">
                    {item.clsRefereeCategory?.label}
                  </TableCell>

                  <TableCell>{moment(item.assignmentDate).format('DD.MM.YYYY')}</TableCell>
                  <TableCell>
                    {(item.confirmationDate && moment(item.confirmationDate).format('DD.MM.YYYY')) || ''}
                  </TableCell>
                  <TableCell>{(item.validUntil && moment(item.validUntil).format('DD.MM.YYYY')) || ''}</TableCell>
                  <TableCell colSpan={2}>
                    {' '}
                    <DocumentComponent
                      currentCat={item?.id}
                      documentUpdated={() => {
                        enqueueSnackbar('Документы успешно обновлена', { variant: 'success' });
                        onUpdate();
                      }}
                      dirDocuments={item.dirDocuments}
                      id={id}
                      readOnly={readOnly}
                    />
                  </TableCell>

                  {
                    <TableCell>
                      <div className={classes.row}>
                        {!readOnly && (
                          <>
                            <Tooltip title="удалить" placement="top-start">
                              <Button
                                className={classes.btn}
                                color="secondary"
                                variant="contained"
                                style={{ marginRight: 10 }}
                                onClick={() => setDeleteItem(item.id)}
                              >
                                <ClearIcon className={classes.icon} />
                              </Button>
                            </Tooltip>
                            <Tooltip title="изменить" placement="top-start">
                              <Button
                                className={classes.btn}
                                color="primary"
                                variant="contained"
                                onClick={() => {
                                  setDefaultFormValue(item);
                                  setAction('edit');
                                }}
                              >
                                <EditIcon className={classes.icon} />
                              </Button>
                            </Tooltip>
                          </>
                        )}
                      </div>
                    </TableCell>
                  }
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <ConfirmDialog
          title={`Удаление суд категории`}
          text={`Вы уверены что хотите удалить эту категорию?`}
          btnVariant="outlined"
          open={deleteItem}
          saveBtnText="да"
          cancelBtnText="отмена"
          saveBtnColor="secondary"
          cancelBtnColor="primary"
          onCancel={() => setDeleteItem(null)}
          onSave={() => {
            deleteCategory(deleteItem).then(() => setDeleteItem(null));
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default RefereeCategory;
